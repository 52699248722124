import PropTypes from "prop-types";
import { Card, CardContent, Grid, Typography } from "@mui/material";

const DataCard = (props) => {
  const { title, icon, mainValue, body } = props;

  return (
    <Card elevation={2}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          {icon && <Grid item>{icon}</Grid>}
          <Grid item flexGrow={1} textAlign="end">
            <Typography variant="h4" fontWeight={600}>
              {mainValue}
            </Typography>
          </Grid>
        </Grid>
          {body}
      </CardContent>
    </Card>
  );
};

DataCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  mainValue: PropTypes.string,
  body: PropTypes.element,
};

export default DataCard;
