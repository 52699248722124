import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  DialogTitle,
  Chip,
  Box,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useReducer, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import ErrorIcon from "@mui/icons-material/Error";
import GetAppIcon from "@mui/icons-material/GetApp";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { CSVLink } from "react-csv";
import { CustomTable } from "../../CustomTable";
import { downloadFile, openFile } from "../../../utils/file";
import { localeFormat } from "../../../utils/format";
import { today } from "../../../utils/date";
import AddMerchantableDialog from "../../Inputs/AddMerchantableDialog";
import AppContext from "../../../context/AppContext";
import ButtonLink from "../../Inputs/ButtonLink";
import ConfirmDialog from "../../ConfirmDialog";
import CreateNonPaymentForm from "../NonPayments/CreateNonPaymentForm";
import CreditCardForm from "../../CreditCardForm";
import CustomButton from "../../Inputs/CustomButton";
import CustomSelect from "../../Inputs/CustomSelect";
import InvoiceItems from "./InvoiceItems";
import InvoiceStateChip from "../../InvoiceStateChip";
import ItemsSummary from "../../ItemsSummary";
import ReceiptStateChip from "../../ReceiptStateChip";
import RectifyingInvoiceForm from "./RectifyingInvoiceForm";
import TextInput from "../../Inputs/TextInput";
import { formatDate } from "../../../utils/chartUtils";
import {
  BILLING_ERROR_STATE_ID,
  C_INVOICE_SERIES_ID,
  CASH_PAYMENT_METHOD_ID,
  CUSTOMER_ROLE_ID,
  F_INVOICE_SERIES_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  R_INVOICE_SERIES_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  TRANSFER_PAYMENT_METHOD_ID,
  UNPAID_INVOICE_STATE_ID,
  VIRTUAL_PAYCARD_ID,
} from "../../../data/constants";

const initialState = {
  form: {
    accountedAt: "",
    accountedBy: "",
    billingAddress: "",
    billingError: "",
    customer: "",
    customerId: "",
    customerName: "",
    customerNif: "",
    customerAddress: "",
    dueDate: "",
    issueDate: "",
    issueId: "",
    issuerName: "",
    issuerNif: "",
    issuerAddress: "",
    items: [],
    ibanId: "",
    issuerId: "",
    isRectified: false,
    nonPayments: [],
    number: "",
    paidAt: "",
    paymentMethodId: "",
    paycardId: "",
    serieId: "0",
    comments: "",
    baseAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
  },
  inputError: {
    number: false,
    serieId: false,
    issueDate: false,
    dueDate: false,
    customer: false,
    customerName: false,
    customerNif: false,
    customerAddress: false,
    issuingName: false,
    issuingNif: false,
    issuingAddress: false,
    billingAddress: false,
  },
  boxes: [],
  billingAddresses: [],
  cards: [],
  cardModalIsOpen: false,
  centers: [],
  contracts: [],
  ibans: [],
  id: null,
  issuers: [],

  merchDialog: {
    addButtonDisabled: false,
    boxes: [],
    merchantables: [],
    isOpen: false,
    loading: false,
    filterBox: [],
    filterCenter: [],
    filterConcept: "",
    filterContract: "",
    filterCustomer: "",
    filterFromDate: "",
    filterInvoiced: "",
    filterUntilDate: "",
    filterType: "",
    types: [],
  },

  newIban: "",
  createNonPaymentLoading: false,
  nonPaymentDialogIsOpen: false,
  rectifyingDialogIsOpen: false,
  recuperationDialogIsOpen: false,
  recuperateLoading: false,
  recuperate: {
    ibanId: "",
    paycardId: "",
    recuperationMethod: "",
    recuperationComment: "",
  },

  paymentMethods: [],
  nonPaymentReasons: [],

  addIbanLoading: false,

  confirmDialog: {
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },

  incorrectlyIssuedDialog: {
    isOpen: false,
  },

  loading: {
    all: false,
    downloadPdf: false,
    emailPdf: false,
    printPdf: false,
    seePdf: false,
    submit: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_ITEMS":
      return {
        ...state,
        form: {
          ...state.form,
          items: action.payload.items,
        },
      };
    case "SET_INVOICE":
      return { ...state, form: action.payload };
    case "SET_CONTRACTS":
      return { ...state, contracts: action.payload };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_ISSUER":
      return {
        ...state,
        form: {
          ...state.form,
          issuerName: action.payload.issuerName,
          issuerNif: action.payload.issuerNif,
          issuerAddress: action.payload.issuerAddress,
        },
      };
    case "SET_ISSUERS":
      return { ...state, issuers: action.payload };
    case "OPEN_PAYCARD_MODAL":
      return { ...state, cardModalIsOpen: true };
    case "CLOSE_PAYCARD_MODAL":
      return { ...state, cardModalIsOpen: false };
    case "SET_FORMS_OF_PAY":
      return {
        ...state,
        ibans: action.payload.ibans,
        cards: action.payload.cards,
      };
    case "SET_ADD_IBAN_LOADING_TRUE":
      return { ...state, addIbanLoading: true };
    case "SET_ADD_IBAN_LOADING_FALSE":
      return { ...state, addIbanLoading: false };
    case "SET_NEW_IBAN":
      return { ...state, newIban: action.payload };
    case "OPEN_RECTIFYING_DIALOG":
      return { ...state, rectifyingDialogIsOpen: true };
    case "CLOSE_RECTIFYING_DIALOG":
      return { ...state, rectifyingDialogIsOpen: false };
    case "OPEN_NONPAYMENT_DIALOG":
      return { ...state, nonPaymentDialogIsOpen: true };
    case "CLOSE_NONPAYMENT_DIALOG":
      return { ...state, nonPaymentDialogIsOpen: false };
    case "OPEN_RECUPERATION_DIALOG":
      return { ...state, recuperationDialogIsOpen: true };
    case "CLOSE_RECUPERATION_DIALOG":
      return { ...state, recuperationDialogIsOpen: false };
    case "OPEN_MERCHANTABLE_DIALOG":
      return {
        ...state,
        merchDialog: { ...state.merchDialog, isOpen: true },
      };
    case "CLOSE_MERCHANTABLE_DIALOG":
      return {
        ...state,
        merchDialog: { ...state.merchDialog, isOpen: false },
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload]: !state.loading[action.payload],
          all: !state.loading.all,
        },
      };
    case "RESET_MERCHANTABLE_DIALOG":
      return {
        ...state,
        merchDialog: initialState.merchDialog,
      };
    case "SET_INCORRECTLY_ISSUED_DIALOG":
      return {
        ...state,
        incorrectlyIssuedDialog: {
          ...state.incorrectlyIssuedDialog,
          isOpen: action.payload,
        },
      };
    case "SET_MERCHANTABLE_LOADING_TRUE":
      return { ...state, merchDialog: { ...state.merchDialog, loading: true } };
    case "SET_MERCHANTABLE_LOADING_FALSE":
      return {
        ...state,
        merchDialog: { ...state.merchDialog, loading: false },
      };
    case "SET_MERCHANTABLE_ADD_BUTTON_DISABLED_TRUE":
      return {
        ...state,
        merchDialog: { ...state.merchDialog, addButtonDisabled: true },
      };
    case "SET_MERCHANTABLE_ADD_BUTTON_DISABLED_FALSE":
      return {
        ...state,
        merchDialog: { ...state.merchDialog, addButtonDisabled: false },
      };
    case "SET_MERCHANTABLE_DIALOG_FILTER":
      return {
        ...state,
        merchDialog: {
          ...state.merchDialog,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_MERCHANTABLE_TYPES":
      return {
        ...state,
        merchDialog: { ...state.merchDialog, types: action.payload },
      };
    case "SET_MERCHANTABLES":
      return {
        ...state,
        merchDialog: { ...state.merchDialog, merchantables: action.payload },
      };
    case "SET_INPUT_RECUPERATE":
      return {
        ...state,
        recuperate: {
          ...state.recuperate,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_CREATE_NON_PAYMENT_LOADING":
      return {
        ...state,
        createNonPaymentLoading: action.payload,
      };
    case "SET_RECUPERATE_LOADING":
      return {
        ...state,
        recuperateLoading: action.payload,
      };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_NONPAYMENT_REASONS":
      return { ...state, nonPaymentReasons: action.payload };
    case "RESET_RECUPERATE_MODAL":
      return { ...state, recuperate: initialState.recuperate };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    default:
      throw new Error("Action not found in reducer");
  }
}

export default function InvoicePage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  let { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("invoices");
  const [tErrors] = useTranslation("errors");

  const downloadCSVRef = useRef(null);

  const CSV_COLUMNS = [
    {
      label: t("concept"),
      key: "concept",
    },
    {
      label: t("units"),
      key: "units",
    },
    {
      label: t("pricePerUnit"),
      key: "pricePerUnit",
    },
    {
      label: t("taxPercentage"),
      key: "taxPercentage",
    },
  ];

  const MERCHANTABLE_DIALOG_COLUMNS = [
    { key: "startDate", label: t("startDate"), sortType: "string" },
    { key: "endDate", label: t("endDate") },
    { key: "concept", label: t("concept") },
    { key: "MerchantableType.name", label: t("type"), sortType: "string" },
    {
      key: "units",
      label: t("units"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value),
    },
    {
      key: "pricePerUnit",
      label: t("pricePerUnit"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "baseAmount",
      label: t("baseAmount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { key: "vatPercentage", label: t("vatPercentage"), sortType: "number" },
    {
      key: "totalAmount",
      label: t("totalAmount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "InvoiceItems.length",
      label: t("invoiced"),
      sortType: "other",
      renderFunction: (value) => value > 0 && t("invoiced"),
    },
    {
      key: "actions",
      label: t("actions"),
      sortType: "other",
      renderFunction: (action, merchantable) => {
        return (
          <CustomButton
            loading={state.merchDialog.addButtonDisabled}
            loadingText=""
            onClick={() => {
              addMerchantableToInvoice(merchantable.id);
            }}
            icon={<AddIcon />}
          >
            {t("add")}
          </CustomButton>
        );
      },
    },
  ];

  const NON_PAYMENT_COLUMNS = [
    { key: "nonPaymentDate", label: t("nonPaymentDate") },
    {
      key: "reason",
      label: t("reason"),
      renderFunction: (value) =>
        state.nonPaymentReasons.find((reason) => reason.id === value)?.name,
    },
    { key: "comments", label: t("comments") },
    { key: "recuperationDate", label: t("recuperationDate") },
    {
      key: "recuperationMethod",
      label: t("recuperationMethod"),
      renderFunction: (value) =>
        state.paymentMethods.find((method) => method.id === value)?.name,
    },
    {
      key: "actions",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) =>
        !item.recuperationDate && (
          <Button onClick={handleOpenNonPaymentModal}>{t("recuperate")}</Button>
        ),
    },
  ];

  const RECEIPTS_COLUMNS = [
    {
      key: "Remittance.name",
      label: t("remittance"),
      renderFunction: (value, item) => (
        <ButtonLink
          to={"/app/remittance/" + item.Remittance?.id}
          size="small"
          sx={{ padding: 0 }}
        >
          {value}
        </ButtonLink>
      ),
    },
    { key: "dueDate", label: t("dueDate") },
    { key: "concept", label: t("concept") },
    { key: "amount", label: t("amount") },
    {
      label: t("state"),
      key: "state",
      sortType: "number",
      renderFunction: (value) => <ReceiptStateChip state={value} />,
    },
  ];

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("invoice");

    getIssuers();
    getMerchantableTypes();
    getPaymentMethods();
    getInvoice();
    getNonPaymentReasons();
  }, []);

  useEffect(() => {
    getContracts();
  }, [state.form.customerId]);

  const getInvoice = () => {
    let params = {
      include: [
        "Customer",
        "NonPayment",
        "Receipt",
        "Merchantable",
        "InvoiceItem",
        "Invoice",
      ],
    };

    api
      .get("/invoices/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_FORMS_OF_PAY",
            payload: {
              cards: response.data.Customer.PayCards,
              ibans: response.data.Customer.IBANs,
            },
          });

          const invoice = response.data;
          let isRectified = false;
          response.data.items.forEach((item) => {
            item.Merchantable?.InvoiceItems.forEach((merchantableItem) => {
              merchantableItem.Invoice.serieId === R_INVOICE_SERIES_ID &&
                (isRectified = true);
            });
          });
          invoice.isRectified = isRectified;
          dispatch({ type: "SET_INVOICE", payload: invoice });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getMerchantableTypes = () => {
    api
      .get("/merchantables/types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_MERCHANTABLE_TYPES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getIssuers = () => {
    api
      .get("/issuers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_ISSUERS", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getContracts = () => {
    if (!state.form.customerId) return;
    let params = {
      customerId: state.form.customerId,
      include: ["Box", "Center"],
    };

    api
      .get("/contracts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_CONTRACTS", payload: response.data });

          // TODO: Check why this is necessary. Shouldn't centers already be distinct?
          //Get distinct centers
          const allCenters = response.data.map(
            (contract) => contract.Box.Center
          );
          let centers = [];

          let isDuplicate;
          allCenters.forEach((center) => {
            isDuplicate = false;
            centers.forEach((item) => {
              if (item.id === center.id) {
                isDuplicate = true;
              }
            });
            if (!isDuplicate) {
              centers.push(center);
            }
          });
          dispatch({ type: "SET_CENTERS", payload: centers });

          // TODO: Check why this is necessary. Shouldn't boxes already be distinct?
          //Get distinct boxes
          const allBoxes = response.data.map((contract) => contract.Box);
          let boxes = [];
          // Check duplicates
          let boxIsDuplicate;
          allBoxes.forEach((box) => {
            boxIsDuplicate = false;
            boxes.forEach((item) => {
              if (item.id === box.id) {
                boxIsDuplicate = true;
              }
            });
            if (!boxIsDuplicate) {
              boxes.push(box);
            }
          });
          dispatch({ type: "SET_BOXES", payload: boxes });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getNonPaymentReasons = () => {
    api
      .get("/non-payments/reasons")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_NONPAYMENT_REASONS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const editInvoice = () => {
    if (validateForm()) {
      let data = {};
      state.form.serieId !== "" && (data.serieId = state.form.serieId);
      state.form.issueDate !== "" && (data.issueDate = state.form.issueDate);
      state.form.dueDate !== "" && (data.dueDate = state.form.dueDate);
      state.form.customerNif !== "" &&
        (data.customerNif = state.form.customerNif);
      state.form.customerAddress !== "" &&
        (data.customerAddress = state.form.customerAddress);
      state.form.ibanId !== "" && (data.ibanId = state.form.ibanId);
      state.form.issuerId !== "" && (data.issuerId = state.form.issuerId);
      state.form.issuerName !== "" &&
        (data.issuingName = state.form.issuerName);
      state.form.issuerNif !== "" && (data.issuingNif = state.form.issuerNif);
      state.form.issuerAddress !== "" &&
        (data.issuingAddress = state.form.issuerAddress);
      state.form.items?.length && (data.items = state.form.items);
      state.form.billingAddress !== "" &&
        (data.billingAddress = state.form.billingAddress);
      state.form.paycardId !== "" && (data.paycardId = state.form.paycardId);
      state.form.paymentMethodId !== "" &&
        (data.paymentMethodId = state.form.paymentMethodId);
      state.form.accountedBy !== "" &&
        (data.accountedBy = state.form.accountedBy);
      state.form.accountedBy == null
        ? (data.accountedAt = null)
        : (data.accountedAt = new Date());
      state.form.paidAt !== "" && (data.paidAt = state.form.paidAt);
      state.form.billingError !== "" &&
        (data.billingError = state.form.billingError);
      data.comments = state.form.comments;

      dispatch({ type: "SET_LOADING", payload: "submit" });
      api
        .post("/invoices/edit/" + id, data)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("invoiceEditSuccess"), { variant: "success" });
            history.goBack();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => {
          dispatch({ type: "SET_LOADING", payload: "submit" });
        });
    }
  };

  const setItems = (items) => {
    dispatch({
      type: "SET_ITEMS",
      payload: {
        items: items,
      },
    });
  };

  const validateForm = () => {
    let isValid = true;

    const FIELDS = [
      "serieId",
      "issueDate",
      "issuingNif",
      "issuingAddress",
      "issuingName",
      "customerNif",
      // "customerAddress",
      "customerName",
      "customer",
      "dueDate",
    ];

    FIELDS.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        isValid = false;
      }
    });

    //TODO: Check why "0"?
    if (state.form.serieId === "0") {
      setInputErrorTrue("serieId");
    }
    return isValid;
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
    enqueueSnackbar(t("thereCannotBeAnInvoiceWithout") + " " + t(name), {
      variant: "error",
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const handleInputChangeIssuer = (e) => {
    handleInputChange(e);

    if (e.target.value !== "") {
      const issuer = state.issuers.find((item) => item.id === e.target.value);

      dispatch({
        type: "SET_ISSUER",
        payload: {
          issuerName: issuer.issuerName,
          issuerNif: issuer.nif,
          issuerAddress: issuer.address,
        },
      });
    } else {
      dispatch({
        type: "SET_ISSUER",
        payload: {
          issuerName: "",
          issuerNif: "",
          issuerAddress: "",
        },
      });
    }
  };

  const seePdf = (id) => {
    dispatch({ type: "SET_LOADING", payload: "seePdf" });
    api
      .get("/invoices/" + id + "/pdf", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          openFile(response.data, "pdf");
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", payload: "seePdf" });
      });
  };

  const downloadPdf = (id) => {
    dispatch({ type: "SET_LOADING", payload: "downloadPdf" });
    api
      .get("/invoices/" + id + "/pdf", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const fileName = state.form.InvoiceSerie?.name + state.form.number;
          downloadFile(response.data, fileName);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", payload: "downloadPdf" });
      });
  };

  const emailPdf = (id) => {
    dispatch({ type: "SET_LOADING", payload: "emailPdf" });
    api
      .get("/invoices/" + id + "/email")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("emailSentSuccessfully"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", payload: "emailPdf" });
      });
  };

  const handleOpenCardModal = () => {
    dispatch({ type: "OPEN_PAYCARD_MODAL" });
  };

  const handleCloseCardModal = () => {
    dispatch({ type: "CLOSE_PAYCARD_MODAL" });
  };

  const submitCardForm = (card) => {
    let form = {
      name: card.name,
      number: card.number,
      cvv: card.cvc,
      expirationMonth: card.expiry.substring(0, 2),
      expirationYear: card.expiry.substring(3),
      customerId: state.form.customerId,
    };

    api
      .post("/pay-cards/create", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("cardCreated"), { variant: "success" });

          dispatch({
            type: "SET_FORMS_OF_PAY",
            payload: {
              cards: [...state.cards, response.data],
              ibans: state.ibans,
            },
          });

          handleCloseCardModal();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleInputChangeIban = (e) => {
    dispatch({ type: "SET_NEW_IBAN", payload: e.target.value });
  };

  const createIban = (number) => {
    const ibanRepeated = state.ibans.some((iban) => iban.number === number);

    if (!ibanRepeated) {
      dispatch({ type: "SET_ADD_IBAN_LOADING_TRUE" });
      let data = {};
      data.number = number;
      data.customerId = state.form.customerId;

      api
        .post("/ibans/create", data)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("ibanCreated"), { variant: "success" });
            dispatch({
              type: "SET_FORMS_OF_PAY",
              payload: {
                cards: state.cards,
                ibans: [...state.ibans, response.data.iban],
              },
            });
            dispatch({ type: "SET_NEW_IBAN", payload: "" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => {
          dispatch({ type: "SET_ADD_IBAN_LOADING_FALSE" });
        });
    } else {
      enqueueSnackbar(t("ibanRepeated"), { variant: "warning" });
    }
  };

  const openMerchDialog = () => {
    dispatch({ type: "OPEN_MERCHANTABLE_DIALOG" });
  };

  const closeMerchDialog = () => {
    dispatch({ type: "CLOSE_MERCHANTABLE_DIALOG" });
    dispatch({ type: "RESET_MERCHANTABLE_DIALOG" });
  };

  const setMerchantableFilter = (e) => {
    dispatch({
      type: "SET_MERCHANTABLE_DIALOG_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const getMerchantables = () => {
    dispatch({ type: "SET_MERCHANTABLE_LOADING_TRUE" });

    let filters = {
      include: ["Box", "Customer", "Contract", "InvoiceItem"],
      customer: state.form.customerId,
    };

    state.merchDialog.filterType !== "" &&
      (filters.merchType = state.merchDialog.filterType);
    state.merchDialog.filterContract !== "" &&
      (filters.contract = state.merchDialog.filterContract);
    state.merchDialog.filterCenter !== "" &&
      (filters.center = state.merchDialog.filterCenter);
    state.merchDialog.filterBox !== "" &&
      (filters.box = state.merchDialog.filterBox);
    state.merchDialog.filterFromDate !== "" &&
      (filters.dateFrom = state.merchDialog.filterFromDate);
    state.merchDialog.filterUntilDate !== "" &&
      (filters.dateUntil = state.merchDialog.filterUntilDate);
    state.merchDialog.filterConcept !== "" &&
      (filters.concept = state.merchDialog.filterConcept);

    api
      .get("/merchantables", { params: filters })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_MERCHANTABLES", payload: response.data });
          if (response.data.length === 0)
            enqueueSnackbar(t("noMerchantables"), { variant: "warning" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_MERCHANTABLE_LOADING_FALSE" });
      });
  };

  const deleteInvoiceItem = (item) => {
    api
      .delete("/invoices/delete-item/" + item.id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          setItems(response.data.result);
          enqueueSnackbar(t("invoiceItemDeleted"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleOpenNonPaymentModal = () => {
    if (
      state.form.NonPayments.length !== 0 &&
      state.form.NonPayments[state.form.NonPayments.length - 1]
        .recuperationDate == null
    ) {
      state.form.reason = state.nonPaymentReasons.find(
        (reason) =>
          reason.id ===
          state.form.NonPayments[state.form.NonPayments.length - 1].reason
      ).name;
      state.form.nonPaymentDate =
        state.form.NonPayments[
          state.form.NonPayments.length - 1
        ].nonPaymentDate;
      state.form.comments =
        state.form.NonPayments[state.form.NonPayments.length - 1].comments;
      dispatch({ type: "OPEN_RECUPERATION_DIALOG" });
    } else {
      dispatch({ type: "OPEN_NONPAYMENT_DIALOG" });
    }
  };

  const handleCloseNonPaymentModal = () => {
    dispatch({ type: "CLOSE_NONPAYMENT_DIALOG" });
  };

  const handleCloseRecuperationModal = () => {
    dispatch({ type: "CLOSE_RECUPERATION_DIALOG" });
  };

  const handleRecuperateChange = (e) => {
    dispatch({
      type: "SET_INPUT_RECUPERATE",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleIncorrectlyIssuedDialog = (e) => {
    if (!e) {
      dispatch({ type: "SET_INCORRECTLY_ISSUED_DIALOG", payload: false });
      return;
    }
    api
      .post("/invoices/edit/" + state.form.id, { billingError: true })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          handleInputChange({
            target: {
              name: "billingError",
              value: true,
            },
          });
          handleInputChange({
            target: {
              name: "state",
              value: BILLING_ERROR_STATE_ID,
            },
          });
          enqueueSnackbar(t("invoiceMarkedAsIncorrectlyIssued"), {
            variant: "success",
          });
          dispatch({ type: "SET_INCORRECTLY_ISSUED_DIALOG", payload: false });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const createNonPayment = (form, receipts) => {
    dispatch({ type: "SET_CREATE_NON_PAYMENT_LOADING", payload: true });
    const data = {
      nonPayment: {
        nonPaymentDate: form.date,
        reason: form.reason,
        comments: form.comment,
        invoiceId: id,
      },
      receipts: receipts,
    };
    api
      .post("/non-payments/create", data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("nonPaymentCreated"), { variant: "success" });
          handleCloseNonPaymentModal();
          getInvoice();
          addComment(
            `${t("theUser")} ${user.name} ${t(
              "createdANonPayment"
            ).toLowerCase()} ${t("forTheInvoice").toLowerCase()} ${
              state.form.number
            }.
            ${form.comment !== "" ? t("comment") + ": " + form.comment : ""}`
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_CREATE_NON_PAYMENT_LOADING", payload: false });
      });
  };

  const addComment = (comment) => {
    if (comment !== "") {
      let form = {
        text: comment,
        customerId: state.form.customerId,
        authorId: user.id,
        authorName: user.name,
      };
      api
        .post("/customers/" + state.form.customerId + "/comment", form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("commentAddedSuccessfully"), {
              variant: "success",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else {
      enqueueSnackbar(t("commentCannotBeEmpty"), { variant: "warning" });
    }
  };

  const addMerchantableToInvoice = (merchantableId) => {
    dispatch({ type: "SET_MERCHANTABLE_ADD_BUTTON_DISABLED_TRUE" });

    api
      .post("/invoices/add-item", {
        merchantableId,
        invoiceId: state.form.id,
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("merchantableAdded"), { variant: "success" });
          setItems(state.form.items.concat(response.data));
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_MERCHANTABLE_ADD_BUTTON_DISABLED_FALSE" });
      });
  };

  const recuperate = () => {
    dispatch({ type: "SET_RECUPERATE_LOADING", payload: true });
    let data = {};

    Number(state.recuperate.recuperationMethod) === RECEIPT_PAYMENT_METHOD_ID &&
      state.recuperate.ibanId !== "" &&
      (data.ibanId = state.recuperate.ibanId);
    Number(state.recuperate.recuperationMethod) === PAYCARD_PAYMENT_METHOD_ID &&
      state.recuperate.paycardId !== "" &&
      (data.paycardId = state.recuperate.paycardId);
    state.recuperate.recuperationComment !== "" &&
      (data.recuperationComment = state.recuperate.recuperationComment);
    state.recuperate.recuperationMethod !== "" &&
      (data.recuperationMethod = state.recuperate.recuperationMethod);

    api
      .post(
        "/non-payments/" +
          state.form.NonPayments.find(
            (nonPayment) => !nonPayment.recuperationDate
          ).id +
          "/edit",
        data
      )
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("nonPaymentRecuperated"), { variant: "success" });
          handleCloseRecuperationModal();
          getInvoice();
          addComment(
            `${t("theUser")} ${user.name} ${t(
              "hasRecuperatedANonPayment"
            ).toLowerCase()} ${t("fromTheInvoice").toLowerCase()} ${
              state.form.number
            }.
            ${
              state.recuperate.recuperationComment !== ""
                ? t("comment") + ": " + state.recuperate.recuperationComment
                : ""
            }`
          );
          dispatch({ type: "RESET_RECUPERATE_MODAL" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_RECUPERATE_LOADING", payload: false });
      });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const setIncorrectlyIssuedDialogState = (state) => {
    dispatch({ type: "SET_INCORRECTLY_ISSUED_DIALOG", payload: state });
  };

  const openConfirmDeleteInvoiceItem = (item) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        childrenText: item.concept,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteInvoiceItem(item);
          resetConfirmDialog();
        },
      },
    });
  };

  //Form updated useEffect
  useEffect(() => {
    const box = state.boxes.filter(
      (box) => state.merchDialog.filterCenter.indexOf(box.centerId) !== -1
    );

    dispatch({
      type: "SET_MERCHANTABLE_DIALOG_FILTER",
      payload: {
        inputname: "boxes",
        value: box,
      },
    });
  }, [state.merchDialog.filterCenter]);

  useEffect(() => {
    if (Number(state.form.paymentMethodId) !== PAYCARD_PAYMENT_METHOD_ID)
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "paidAt",
          value: null,
        },
      });
  }, [state.form.paymentMethodId]);

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="h4">{t("invoice")}</Typography>
            </Grid>
            <Grid item>
              <InvoiceStateChip state={state.form.state} />
              {state.form.uncollectible === true && (
                <Tooltip title={t("uncollectibleInvoice")}>
                  <span>
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <ErrorIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
            {state.form.isRectified && (
              <Grid item>
                <Chip color="secondary" label={t("rectified")} size="small" />
              </Grid>
            )}
            <Grid item flex={1} justifyContent="flex-end" display="flex">
              {state.form?.InvoiceSerie?.id !== C_INVOICE_SERIES_ID && (
                <Tooltip title={t("viewInvoice")} placement="top">
                  <span>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        seePdf(state.form.id);
                      }}
                      disabled={!state.form.id}
                    >
                      {state.loading.seePdf ? (
                        <Fade in={state.loading.seePdf}>
                          <CircularProgress size="1.5rem" />
                        </Fade>
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {user.Role.id !== CUSTOMER_ROLE_ID && (
                <Tooltip title={t("downloadCSV")} placement="top">
                  <span>
                    <IconButton
                      color="primary"
                      disabled={!state.form.id}
                      onClick={() => {
                        downloadCSVRef.current.link.click();
                      }}
                    >
                      <ArticleIcon />
                      <CSVLink
                        data={state.form.items || []}
                        headers={CSV_COLUMNS}
                        filename={"invoice.csv"}
                        ref={downloadCSVRef}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {state.form?.InvoiceSerie?.id !== C_INVOICE_SERIES_ID && (
                <Tooltip title={t("downloadPdf")} placement="top">
                  <span>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        downloadPdf(state.form.id);
                      }}
                      disabled={!state.form.id}
                    >
                      {state.loading.downloadPdf ? (
                        <Fade in={state.loading.downloadPdf}>
                          <CircularProgress size="1.5rem" />
                        </Fade>
                      ) : (
                        <GetAppIcon />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {user.Role.id !== CUSTOMER_ROLE_ID &&
                state.form?.InvoiceSerie?.id !== C_INVOICE_SERIES_ID && (
                  <Tooltip title={t("emailPdf")} placement="top">
                    <span>
                      <IconButton
                        color="primary"
                        disabled={!state.form.id}
                        onClick={(e) => {
                          e.preventDefault();
                          emailPdf(state.form.id);
                        }}
                      >
                        {state.loading.emailPdf ? (
                          <Fade in={state.loading.emailPdf}>
                            <CircularProgress size="1.5rem" />
                          </Fade>
                        ) : (
                          <EmailIcon />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                )}

              {user.Role.id !== CUSTOMER_ROLE_ID &&
                user.hasAction("EDIT_INVOICES") && (
                  <Tooltip
                    title={
                      Number(state.form.state) === UNPAID_INVOICE_STATE_ID
                        ? t("seeNonPayment")
                        : t("createNonPayment")
                    }
                    placement="top"
                  >
                    <span>
                      <IconButton
                        color="primary"
                        disabled={!state.form.id}
                        onClick={handleOpenNonPaymentModal}
                      >
                        <MoneyOffIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}

              {user.Role.id !== CUSTOMER_ROLE_ID &&
                state.form.serieId === F_INVOICE_SERIES_ID && (
                  <Tooltip title={t("createRectification")} placement="top">
                    <span>
                      <IconButton
                        color="primary"
                        disabled={!state.form.id}
                        onClick={() =>
                          dispatch({ type: "OPEN_RECTIFYING_DIALOG" })
                        }
                        sx={{ fontWeight: "bold" }}
                      >
                        R
                      </IconButton>
                    </span>
                  </Tooltip>
                )}

              {user.Role.id !== CUSTOMER_ROLE_ID &&
                user.hasAction("EDIT_INVOICES") &&
                !state.form.billingError && (
                  <Tooltip
                    title={t("markInvoiceAsIssuedIncorrectly")}
                    placement="top"
                  >
                    <span>
                      <IconButton
                        color="error"
                        disabled={!state.form.id}
                        onClick={() =>
                          dispatch({
                            type: "SET_INCORRECTLY_ISSUED_DIALOG",
                            payload: true,
                          })
                        }
                      >
                        <ReportGmailerrorredIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ItemsSummary
              gridItems={[
                {
                  translatedText: t("invoiceNumber"),
                  value:
                    (state.form.InvoiceSerie?.name || "") + state.form.number,
                },
                {
                  translatedText: t("issueDate"),
                  value: state.form.issueDate,
                },
                {
                  translatedText: t("dueDate"),
                  value: user.hasAction("EDIT_INVOICES") ? (
                    <TextInput
                      error={state.inputError.dueDate}
                      helperText={
                        state.inputError.dueDate ? t("dueDateHelperBlank") : ""
                      }
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={state.form.dueDate}
                      onChange={(e) => {
                        setInputErrorFalse("dueDate");
                        handleInputChange(e);
                      }}
                      name="dueDate"
                      sx={{ width: "150px" }}
                    />
                  ) : (
                    state.form.dueDate
                  ),
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <Grid item>
                    <Typography variant="h6" component="span">
                      {t("customerInfo")}
                    </Typography>
                  </Grid>
                  {user.hasPage("CUSTOMERS") && state.form.Customer?.id && (
                    <Grid item>
                      <ButtonLink
                        to={"/app/customer/" + state.form.Customer?.id}
                      >
                        {t("seeCustomer")}
                      </ButtonLink>
                    </Grid>
                  )}
                </Grid>
                <Grid container item spacing={2}>
                  {user.hasAction("EDIT_INVOICES") && (
                    <Grid item xs={12} lg={6}>
                      <CustomSelect
                        error={state.inputError.billingAddress}
                        helperText={
                          state.inputError.billingAddress
                            ? t("billingAddressHelperBlank")
                            : ""
                        }
                        value={state.form.billingAddress || ""}
                        name="billingAddress"
                        onChange={(e) => {
                          setInputErrorFalse("billingAddress");
                          handleInputChange(e);
                        }}
                        label={t("billingAddress")}
                        options={[
                          { value: "0", label: t("none") },
                          ...state.billingAddresses?.map((address) => ({
                            value: address.id,
                            label: address.address,
                          })),
                        ]}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {t("customerName") + ": "}
                      <Typography variant="body1" component="span">
                        {state.form.customerName}
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {t("customerNif") + ": "}
                      <Typography variant="body1" component="span">
                        {state.form.customerNif}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {t("customerAddress") + ": "}
                      <Typography variant="body1" component="span">
                        {state.form.customerAddress}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t("issuerInfo")}</Typography>
                </Grid>

                <Grid container item spacing={2}>
                  {user.hasAction("EDIT_INVOICES") && (
                    <Grid item xs={12}>
                      <CustomSelect
                        name="issuerId"
                        value={state.form.issuerId || ""}
                        onChange={handleInputChangeIssuer}
                        label={t("issuer")}
                        options={[
                          { value: "", label: t("none") },
                          ...state.issuers.map((issuer) => ({
                            value: issuer.id,
                            label: issuer.name,
                          })),
                        ]}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {t("issuerName") + ": "}
                      <Typography variant="body1" component="span">
                        {state.form.issuerName}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {t("issuerNif") + ": "}
                      <Typography variant="body1" component="span">
                        {state.form.issuerNif}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {t("issuerAddress") + ": "}
                      <Typography variant="body1" component="span">
                        {state.form.issuerAddress}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            {user.hasAction("EDIT_INVOICES") ? (
              <>
                <FormControlLabel
                  label={t("accounted")}
                  labelPlacement="end"
                  control={
                    <Switch
                      onChange={(e) => {
                        //TODO: Improve this. Custom hook?
                        if (e.target.checked === true) {
                          handleInputChange({
                            target: { name: "accountedBy", value: user.id },
                          });
                          handleInputChange({
                            target: {
                              name: "accountedAt",
                              value: today(),
                            },
                          });
                        } else {
                          handleInputChange({
                            target: { name: "accountedBy", value: null },
                          });
                          handleInputChange({
                            target: { name: "accountedAt", value: null },
                          });
                        }
                      }}
                      name="accountedBy"
                      checked={Boolean(
                        state.form.accountedBy && state.form.accountedBy !== ""
                      )}
                    />
                  }
                />
                {state.form.accountedBy && (
                  <Typography variant="body1" display="inline">
                    {t("by") +
                      " " +
                      (state.form.accountant
                        ? state.form.accountant.name
                        : user.name) +
                      (state.form.accountedAt
                        ? " " + t("at") + " " + state.form.accountedAt
                        : "")}
                  </Typography>
                )}
              </>
            ) : (
              <>
                {state.form.accountedBy ? (
                  <Typography variant="body1" display="inline">
                    {t("accounted") +
                      " " +
                      t("by") +
                      " " +
                      (state.form.accountant
                        ? state.form.accountant.name
                        : user.name) +
                      (state.form.accountedAt
                        ? " " + t("at") + " " + state.form.accountedAt
                        : "")}
                  </Typography>
                ) : (
                  <Typography variant="body1" display="inline">
                    {t("notAccounted")}
                  </Typography>
                )}
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">{t("paymentMethod")}</Typography>
          </Grid>

          <Grid item xs={12}>
            {user.hasAction("EDIT_INVOICES") ? (
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="paymentMethodId"
                  value={Number(state.form.paymentMethodId)}
                  onChange={(e) => {
                    e.target.value = Number(e.target.value);
                    handleInputChange(e);
                  }}
                >
                  <FormControlLabel
                    value={CASH_PAYMENT_METHOD_ID}
                    control={<Radio />}
                    label={t("counted")}
                  />
                  <FormControlLabel
                    value={RECEIPT_PAYMENT_METHOD_ID}
                    control={<Radio />}
                    label={t("receipt")}
                  />
                  <FormControlLabel
                    value={PAYCARD_PAYMENT_METHOD_ID}
                    control={<Radio />}
                    label={t("card")}
                  />
                  <FormControlLabel
                    value={TRANSFER_PAYMENT_METHOD_ID}
                    control={<Radio />}
                    label={t("transfer")}
                  />
                </RadioGroup>
              </FormControl>
            ) : (
              <Box>
                <Typography variant="body1" component="span">
                  {t("paymentMethod")}:{" "}
                </Typography>
                <Typography variant="body1" component="span" fontWeight="bold">
                  {t(
                    state.paymentMethods.find(
                      (p) => p.id === state.form.paymentMethodId
                    )?.name
                  )}
                </Typography>
              </Box>
            )}
          </Grid>

          {Number(state.form.paymentMethodId) === RECEIPT_PAYMENT_METHOD_ID &&
            (user.hasAction("EDIT_INVOICES") ? (
              <Grid container spacing={2} item xs={12}>
                <Grid item>
                  <CustomSelect
                    value={state.form.ibanId}
                    name="ibanId"
                    onChange={handleInputChange}
                    label={t("IBANs")}
                    options={[
                      { value: "", label: t("none") },
                      ...state.ibans.map((iban) => ({
                        value: iban.id,
                        label: iban.number,
                      })),
                    ]}
                  />
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item>
                  <Grid container item alignItems="center" spacing={2}>
                    <Grid item xs={12} sm="auto">
                      <TextInput
                        label={t("newIban")}
                        name="newIban"
                        value={state.newIban}
                        onChange={handleInputChangeIban}
                      />
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <CustomButton
                        loading={state.addIbanLoading}
                        loadingText=""
                        onClick={() => {
                          createIban(state.newIban);
                        }}
                        fullWidth
                      >
                        {t("add")}
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1" component="span">
                  IBAN:{" "}
                </Typography>
                <Typography variant="body1" component="span" fontWeight="bold">
                  {
                    state.ibans.find((iban) => iban.id === state.form.ibanId)
                      ?.number
                  }
                </Typography>
              </Grid>
            ))}

          {Number(state.form.paymentMethodId) === PAYCARD_PAYMENT_METHOD_ID &&
            (user.hasAction("EDIT_INVOICES") ? (
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    value={state.form.paycardId}
                    name="paycardId"
                    onChange={handleInputChange}
                    label={t("card")}
                    options={[
                      { value: "", label: t("none") },
                      {
                        value: VIRTUAL_PAYCARD_ID,
                        label: t("paymentByLink"),
                        disabled: true,
                      },
                      ...state.cards.map((card) => ({
                        value: card.id,
                        label: card.number,
                      })),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button variant="contained" onClick={handleOpenCardModal}>
                    {t("addCreditCard")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label={t("paid")}
                    control={
                      <Switch
                        onChange={(e) => {
                          //TODO: Put logic elsewhere
                          if (e.target.checked === true) {
                            handleInputChange({
                              target: {
                                name: "paidAt",
                                value: today(),
                              },
                            });
                          } else {
                            handleInputChange({
                              target: { name: "paidAt", value: null },
                            });
                          }
                        }}
                        checked={state.form.paidAt && state.form.paidAt !== ""}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                  />

                  {state.form.paidAt && state.form.paidAt !== "" && (
                    <TextInput
                      fullWidth={false}
                      error={state.inputError.paidAt}
                      helperText={
                        state.inputError.paidAt
                          ? t("paidAt") + " " + t("mustNotBeBlank")
                          : ""
                      }
                      label={t("paidAt")}
                      type="date"
                      name="paidAt"
                      onChange={handleInputChange}
                      value={state.form.paidAt}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </Grid>

                {/* Credit card modal */}
                <Dialog
                  open={state.cardModalIsOpen}
                  onClose={handleCloseCardModal}
                >
                  <DialogTitle>
                    <Grid container justifyContent="space-between">
                      <Grid item>{t("addCreditCard")}</Grid>
                      <Grid item>
                        <IconButton onClick={handleCloseCardModal}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <CreditCardForm onSubmit={submitCardForm} />
                  </DialogContent>
                </Dialog>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1" component="span">
                  {t("payCard") + ": "}
                </Typography>
                <Typography variant="body1" component="span" fontWeight="bold">
                  {
                    state.cards.find((card) => card.id === state.form.paycardId)
                      ?.number
                  }
                </Typography>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {(user.Role.id !== CUSTOMER_ROLE_ID ||
            state.form.items.length > 0) && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">{t("items")}</Typography>
              </Grid>

              <Grid container item xs={12} spacing={3}>
                <Grid item xs={12}>
                  <ItemsSummary
                    gridItems={[
                      {
                        translatedText: t("items"),
                        value: state.form.items?.length,
                      },
                      {
                        translatedText: t("baseAmount"),
                        value: localeFormat(state.form.baseAmount) + "€",
                      },
                      {
                        translatedText: t("taxAmount"),
                        value: localeFormat(state.form.taxAmount) + "€",
                      },
                      {
                        translatedText: t("total"),
                        value: localeFormat(state.form.totalAmount) + "€",
                      },
                    ]}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <InvoiceItems
                  addMerchantable={openMerchDialog}
                  deleteMerchantable={openConfirmDeleteInvoiceItem}
                  invoiceItems={state.form.items}
                  showAddButton={
                    user.hasAction("EDIT_INVOICES") &&
                    Boolean(
                      formatDate(new Date(state.form.issueDate)) >=
                        formatDate(new Date())
                    )
                  }
                  name="items"
                  editable={user.hasAction("EDIT_INVOICES")}
                  showDeleteButton={Boolean(
                    formatDate(new Date(state.form.issueDate)) >=
                      formatDate(new Date())
                  )}
                />
              </Grid>
            </>
          )}

          {state.form.NonPayments && state.form.NonPayments.length > 0 && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">{t("nonPayments")}</Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <CustomTable
                    columns={NON_PAYMENT_COLUMNS}
                    data={state.form.NonPayments ? state.form.NonPayments : []}
                    //options={{ loaded: !state.merchLoading }}
                  />
                </Grid>
              </Grid>
            </>
          )}

          {Number(state.form.paymentMethodId) === RECEIPT_PAYMENT_METHOD_ID && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">{t("receipts")}</Typography>
              </Grid>

              {state.form?.Receipts?.length !== 0 ? (
                <Grid item xs={12}>
                  <CustomTable
                    columns={RECEIPTS_COLUMNS}
                    data={state.form.Receipts}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography variant="body">{t("noReceipts")}</Typography>
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Typography variant="h5">{t("comments")}</Typography>
          </Grid>
          <Grid item xs={12}>
            {user.hasAction("EDIT_INVOICES") ? (
              <TextInput
                name="comments"
                label={t("comments")}
                multiline
                minRows={4}
                value={state.form.comments}
                onChange={handleInputChange}
              />
            ) : (
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: state.form.comments
                    ? state.form.comments
                    : t("noComents"),
                }}
              />
            )}
          </Grid>

          <Grid item xs={12} container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            {user.hasAction("EDIT_INVOICES") &&
              formatDate(new Date(state.form.issueDate)) >=
                formatDate(new Date()) && (
                <Grid item>
                  <CustomButton
                    color="primary"
                    onClick={editInvoice}
                    loading={state.loading.submit}
                  >
                    {t("save")}
                  </CustomButton>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Paper>

      <AddMerchantableDialog
        closeDialog={closeMerchDialog}
        customerId={state.form.customerId}
        invoiceItems={state.form.items}
        openDialog={state.merchDialog.isOpen}
        tableColumns={MERCHANTABLE_DIALOG_COLUMNS}
      />

      {/* Non Payment dialog */}
      <CreateNonPaymentForm
        open={state.nonPaymentDialogIsOpen}
        onClose={handleCloseNonPaymentModal}
        onSubmit={createNonPayment}
        receipts={state.form?.Receipts || []}
        invoicePaymentMethodId={state.form.paymentMethodId}
        loading={state.createNonPaymentLoading}
      />

      {/* Non Payment info dialog */}
      <Dialog
        open={state.recuperationDialogIsOpen}
        onClose={handleCloseRecuperationModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{t("nonPaymentInformation")}</Grid>
            <Grid item>
              <IconButton onClick={handleCloseRecuperationModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid item container spacing={3} columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("invoiceNumber")}:
              </Typography>
              <Typography variant="body"> {state.form.number}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("amount")}:
              </Typography>
              <Typography variant="body"> {state.form.totalAmount}€</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("reason")}:
              </Typography>
              <Typography variant="body"> {state.form.reason}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("nonPaymentDate")}:
              </Typography>
              <Typography variant="body">
                {" "}
                {state.form.nonPaymentDate}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("comments")}:
              </Typography>
              <Typography variant="body" style={{ wordWrap: "break-word" }}>
                {" "}
                {state.form.comments}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{t("recuperation")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="recuperationMethod"
                  value={Number(state.recuperate.recuperationMethod)}
                  onChange={handleRecuperateChange}
                >
                  {state.paymentMethods.map((method, index) => (
                    <FormControlLabel
                      key={index}
                      value={method.id}
                      control={<Radio />}
                      label={t(method.name)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            {Number(state.recuperate.recuperationMethod) ===
              RECEIPT_PAYMENT_METHOD_ID && (
              <Grid container spacing={1} item xs={12}>
                <Grid item xs={12} sm={5}>
                  <CustomSelect
                    value={state.recuperate.ibanId}
                    name="ibanId"
                    onChange={handleRecuperateChange}
                    label={t("ibans")}
                    options={[
                      { value: "", label: t("none") },
                      ...state.ibans.map((iban) => ({
                        value: iban.id,
                        label: iban.number,
                      })),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    label={t("newIban")}
                    name="newIban"
                    value={state.newIban}
                    onChange={handleInputChangeIban}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <CustomButton
                    loading={state.addIbanLoading}
                    loadingText=""
                    disabled={state.newIban === ""}
                    onClick={() => {
                      createIban(state.newIban);
                    }}
                  >
                    {t("add")}
                  </CustomButton>
                </Grid>
              </Grid>
            )}

            {Number(state.recuperate.recuperationMethod) ===
              PAYCARD_PAYMENT_METHOD_ID && (
              <Grid container item spacing={1} xs={12}>
                <Grid item xs={12} sm={5}>
                  <CustomSelect
                    value={state.recuperate.paycardId}
                    name="paycardId"
                    onChange={handleRecuperateChange}
                    label={t("card")}
                    options={[
                      { value: "", label: t("none") },
                      { value: VIRTUAL_PAYCARD_ID, label: t("paymentByLink") },
                      ...state.cards.map((card) => ({
                        value: card.id,
                        label: card.number,
                      })),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Button variant="contained" onClick={handleOpenCardModal}>
                    {t("addCreditCard")}
                  </Button>
                </Grid>

                <Dialog
                  open={state.cardModalIsOpen}
                  onClose={handleCloseCardModal}
                >
                  <DialogTitle>
                    <Grid container justifyContent="space-between">
                      <Grid item>{t("addCreditCard")}</Grid>
                      <Grid item>
                        <IconButton onClick={handleCloseCardModal}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <CreditCardForm onSubmit={submitCardForm} />
                  </DialogContent>
                </Dialog>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Typography variant="body1">
                {t("recuperationDate") + ": " + String(new Date()).slice(4, 15)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                variant="outlined"
                label={t("recuperationComment")}
                name="recuperationComment"
                value={state.recuperate.recuperationComment}
                onChange={handleRecuperateChange}
                fullWidth
                rows={4}
              />
            </Grid>

            <Grid item container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button onClick={handleCloseRecuperationModal}>
                  {t("back")}
                </Button>
              </Grid>
              <Grid item>
                <CustomButton
                  onClick={recuperate}
                  disabled={
                    state.recuperate.recuperationMethod === "" ||
                    state.recuperate.recuperationDate === "" ||
                    (Number(state.recuperate.recuperationMethod) ===
                      RECEIPT_PAYMENT_METHOD_ID &&
                      state.recuperate.ibanId === "") ||
                    (Number(state.recuperate.recuperationMethod) ===
                      PAYCARD_PAYMENT_METHOD_ID &&
                      state.recuperate.paycardId === "")
                  }
                  loading={state.recuperateLoading}
                >
                  {t("recuperate")}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="lg"
        fullWidth
        open={state.rectifyingDialogIsOpen}
        onClose={() => dispatch({ type: "CLOSE_RECTIFYING_DIALOG" })}
      >
        <DialogTitle textAlign="right">
          <IconButton
            onClick={() => dispatch({ type: "CLOSE_RECTIFYING_DIALOG" })}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <RectifyingInvoiceForm
            invoice={state.form}
            onSubmit={(invoiceId) => {
              dispatch({ type: "CLOSE_RECTIFYING_DIALOG" });
              history.replace("/app/invoice/" + invoiceId);
              window.location.reload();
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Delete merchantable confirm dialog */}
      <ConfirmDialog
        title={t("deleteMechantableQuestion")}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        confirmText={t("confirm")}
        cancelText={t("cancel")}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>

      {/* Incorrectly issued confirm dialog */}
      <ConfirmDialog
        title={t("markInvoiceAsIssuedIncorrectly")}
        open={state.incorrectlyIssuedDialog.isOpen}
        setOpen={setIncorrectlyIssuedDialogState}
        confirmText={t("confirm")}
        cancelText={t("cancel")}
        onConfirm={handleIncorrectlyIssuedDialog}
      >
        <Typography variant="body2" color="initial">
          {t("areYouSureYouWantToMarkTheInvoiceAsAnInvoiceIssuedInError") +
            " ?"}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
}
