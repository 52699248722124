import { Box, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
  const [t] = useTranslation("app");
  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Grid item>
          <Box
            component="img"
            src="/img/NUTWarning.png"
            height={200}
            weight={200}
            sx={{ objectFit: "cover", objectPosition: "50% 43%" }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h5" align="center" color="info">
            {t("error_404")}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
