import { Container, Grid } from "@mui/material";
import { useContext, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";

const reducer = (state, action) => {
  switch (action.type) {
    default:
      throw new Error("Action not found in reducer.");
  }
};

const initialState = {};

const MassEmailsPage = () => {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [t] = useTranslation("boxes");
  const [state, dispatch] = useReducer(reducer, initialState);

  //Initial useEffect
  useEffect(() => {
    document.title = t("massEmailPage");

    getMassEmail();
  }, []);

  const getMassEmail = () => {
    api
      .get("/mass-emails/" + id)
      .then((response) => {
        if (!response.data.error) {
          dispatch({ type: "SET_MASS_EMAILS", payload: response.data });
        } else {
          enqueueSnackbar(response.data.error.toString(), { variant: "error" });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Grid container rowSpacing={3}></Grid>
    </Container>
  );
};

export default MassEmailsPage;
