import React, { useContext, useEffect, useReducer } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Skeleton,
} from "@mui/material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import AppContext from "../../../context/AppContext";
import Page from "../../global/structure/Page";
import DocsTree from "./DocsTree";
import DirectoryForm from "./DirectoryForm";
import FileForm from "./FileForm";
import ConfirmDialog from "../../ConfirmDialog";
import { CUSTOMER_ROLE_ID } from "../../../data/constants";
import TextInput from "../../Inputs/TextInput";
import { filterByName } from "../../../utils/documentsUtils";

const initialState = {
  confirmDialog: {
    title: "",
    isOpen: false,
    childrenText: "",
    callback: () => {},
  },
  dirData: {
    id: "",
    name: "",
    parentId: "",
    Users: [],
    Roles: [],
  },
  fileData: {
    id: "",
    parentId: "",
    name: "",
    file: "",
    Users: [],
    Roles: [],
  },
  isDirectoryFormOpen: false,
  isFileFormOpen: false,
  isNewFile: false,
  isNewDirectory: false,
  isDirectoryPermissionsCollapsed: false,
  isFilePermissionsCollapsed: false,
  rootsLoaded: false,
  roots: [],
  users: [],
  roles: [],
  filters: {
    name: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ROOTS":
      return { ...state, roots: action.payload };
    case "SET_ROOTS_LOADED":
      return { ...state, rootsLoaded: action.payload };

    case "OPEN_DIRECTORY_FORM":
      return { ...state, isDirectoryFormOpen: true };
    case "CLOSE_DIRECTORY_FORM":
      return { ...state, isDirectoryFormOpen: false };

    case "OPEN_FILE_FORM":
      return { ...state, isFileFormOpen: true };
    case "CLOSE_FILE_FORM":
      return { ...state, isFileFormOpen: false };

    case "SET_DIRECTORY_FORM_CREATE":
      return { ...state, isNewDirectory: true };
    case "SET_DIRECTORY_FORM_EDIT":
      return { ...state, isNewDirectory: false };

    case "SET_FILE_FORM_CREATE":
      return { ...state, isNewFile: true };
    case "SET_FILE_FORM_EDIT":
      return { ...state, isNewFile: false };

    case "SET_DIR_DATA":
      return { ...state, dirData: action.payload };
    case "SET_FILE_DATA":
      return { ...state, fileData: action.payload };
    case "SET_USERS":
      return { ...state, users: action.payload };
    case "SET_ROLES":
      return { ...state, roles: action.payload };

    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.value,
        },
      };

    case "TOGGLE_DIRECTORY_PERMISSIONS":
      return {
        ...state,
        isDirectoryPermissionsCollapsed: !state.isDirectoryPermissionsCollapsed,
      };
    case "TOGGLE_FILE_PERMISSIONS":
      return {
        ...state,
        isFilePermissionsCollapsed: !state.isFilePermissionsCollapsed,
      };
    // Confirm Dialog
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          isOpen: action.payload.isOpen,
          childrenText: action.payload.childrenText,
          callback: action.payload.callback,
        },
      };
    default:
      throw new Error("Action not found in reducer");
  }
};

export default function DocumentsPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("documents");
  let filteredRoots = state.roots;

  //Initial useEffect
  useEffect(() => {
    document.title = t("documentsPage");

    getFileStructure();
    getUsers();
    getRoles();
  }, []);

  const getFileStructure = () => {
    api
      .get("/file-structure")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_ROOTS",
            payload: response.data,
          });
          dispatch({
            type: "SET_ROOTS_LOADED",
            payload: true,
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const getUsers = () => {
    const params = {
      excludeRoleIds: [CUSTOMER_ROLE_ID],
    };
    user.hasAction("VIEW_USERS") &&
      api
        .get("/users", { params })
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_USERS", payload: response.data });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        });
  };

  const getRoles = () => {
    user.hasAction("VIEW_ROLES") &&
      api
        .get("/roles")
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_ROLES", payload: response.data });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        });
  };

  const createDirectory = (data, usersPermissions, rolesPermissions) => {
    if (!data.name)
      return enqueueSnackbar(t("nameRequired"), { variant: "warning" });

    if (data.parentId === "") data.parentId = null;
    let create = {
      data: data,
      usersPermissions: usersPermissions,
      rolesPermissions: rolesPermissions,
    };

    api
      .post("/directories/create", create)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            `${t("directory")} ${response.data.name} ${t(
              "created"
            ).toLowerCase()}`,
            {
              variant: "success",
            }
          );
          getFileStructure();
          closeDirectoryForm();
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const editDirectory = (data) => {
    if (data.parentId === "") data.parentId = null;

    api
      .post("/directories/edit/" + data.id, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            `${t("directory")} ${response.data.name} ${t(
              "updated"
            ).toLowerCase()}`,
            {
              variant: "success",
            }
          );
          getFileStructure();
          closeDirectoryForm();
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const deleteDirectory = (id) => {
    api
      .delete(`/directories/${id}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            `${t("directory")} ${response.data.name} ${t(
              "deleted"
            ).toLowerCase()}`,
            { variant: "success" }
          );
          getFileStructure();
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const createFile = (data, usersPermissions, rolesPermissions) => {
    if (!data.name || !data.parentId || !data.file)
      return enqueueSnackbar(t("missingData"), { variant: "warning" });

    let formData = new FormData();

    if (data.file && data.file !== "") {
      formData.append("fileUpload", data.file.item(0));
      formData.append("name", data.name);
      formData.append("parentId", data.parentId);
      formData.append("usersPermissions", JSON.stringify(usersPermissions));
      formData.append("rolesPermissions", JSON.stringify(rolesPermissions));
    }

    api
      .post("/files/create", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            `${t("file")} ${response.data.name} ${t("created").toLowerCase()}`,
            {
              variant: "success",
            }
          );
          getFileStructure();
          closeFileForm();
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const editFile = (data) => {
    let formData = new FormData();

    formData.append("fileForm", JSON.stringify(data));
    if (data.file && data.file !== "") {
      let file = new File(data.file, data.name, { type: data.file[0].type });
      formData.append("file", file);
    }

    api
      .post("/files/edit/" + data.id, formData)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            `${t("file")} ${response.data.name} ${t("updated").toLowerCase()}`,
            {
              variant: "success",
            }
          );
          getFileStructure();
          closeFileForm();
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const deleteFile = (id) => {
    api
      .delete(`/files/${id}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            `${t("file")} ${response.data.name} ${t("deleted").toLowerCase()}`,
            {
              variant: "success",
            }
          );
          getFileStructure();
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const closeDirectoryForm = () => {
    dispatch({ type: "SET_DIR_DATA", payload: { ...initialState.dirData } });
    dispatch({ type: "CLOSE_DIRECTORY_FORM" });
  };

  const closeFileForm = () => {
    dispatch({ type: "SET_FILE_DATA", payload: { ...initialState.fileData } });
    dispatch({ type: "CLOSE_FILE_FORM" });
  };

  const openDirectoryFormCreate = (parentId) => {
    dispatch({ type: "SET_DIRECTORY_FORM_CREATE" });
    parentId &&
      api
        .get("/directories/" + parentId)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            const parentUserPermissions = response.data.Users || [];
            const parentRolePermissions = response.data.Roles || [];
            dispatch({
              type: "SET_DIR_DATA",
              payload: {
                ...state.dirData,
                Users: parentUserPermissions,
                Roles: parentRolePermissions,
                parentId: parentId,
              },
            });
          }
        })
        .catch((error) =>
          enqueueSnackbar(error.toString(), { variant: "error" })
        );
    dispatch({ type: "OPEN_DIRECTORY_FORM" });
  };

  const openDirectoryFormEdit = (directoryId) => {
    api
      .get("/directories/" + directoryId)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_DIRECTORY_FORM_EDIT" });
          dispatch({ type: "SET_DIR_DATA", payload: response.data });
          dispatch({ type: "OPEN_DIRECTORY_FORM" });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const openFileFormCreate = (parentId) => {
    dispatch({ type: "SET_FILE_FORM_CREATE" });
    parentId &&
      api
        .get("/directories/" + parentId)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            const parentUserPermissions = response.data.Users || [];
            const parentRolePermissions = response.data.Roles || [];
            dispatch({
              type: "SET_FILE_DATA",
              payload: {
                ...state.fileData,
                Users: parentUserPermissions,
                Roles: parentRolePermissions,
                parentId: parentId,
              },
            });
          }
        })
        .catch((error) =>
          enqueueSnackbar(error.toString(), { variant: "error" })
        );
    dispatch({ type: "OPEN_FILE_FORM" });
  };

  const openFileFormEdit = (fileId) => {
    api
      .get("/files/" + fileId)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_FILE_FORM_EDIT" });
          dispatch({ type: "SET_FILE_DATA", payload: response.data });
          dispatch({ type: "OPEN_FILE_FORM" });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const openConfirmDeleteDir = (id, name) => {
    openDeleteConfirm(id, "dir", name);
  };

  const openConfirmDeleteFile = (id, name) => {
    openDeleteConfirm(id, "file", name);
  };

  const viewFile = (id) => {
    let type = "";

    api
      .get("/files/" + id, {})
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          type = response.data.type;
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );

    api
      .get("/files/" + id + "/get-file", { responseType: "blob" })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: type });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const downloadFile = (id) => {
    let type = "";
    let name = "";

    api
      .get("/files/" + id, {})
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          type = response.data.type;
          name = response.data.name;
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );

    api
      .get("/files/" + id + "/get-file", { responseType: "blob" })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          // TODO: Use utils download function
          const file = new Blob([response.data], { type: type });
          const fileURL = URL.createObjectURL(file);
          const a = document.createElement("a");
          a.href = fileURL;
          const extension = name.split(".").pop();
          if (extension === type.split("/")[1]) a.download = name;
          else a.download = name + "." + type.split("/")[1];
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  // Confirm Dialog
  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const openDeleteConfirm = (id, type, name) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: type === "file" ? t("confirmDeleteFile") : t("confirmDeleteDir"),
        isOpen: true,
        childrenText: name,
        callback: (confirmed) => {
          confirmed && (type === "file" ? deleteFile(id) : deleteDirectory(id));
          resetConfirmDialog();
        },
      },
    });
  };

  if (state.rootsLoaded && state.roots && state.filters.name !== "")
    filteredRoots = filterByName(state.roots, state.filters.name);

  return (
    <Page browserTitle={t("documents")} title={t("documents")}>
      <Container maxWidth="xl" sx={{ marginY: 3 }}>
        <Grid container spacing={1} marginTop={2}>
          <Grid item xs={3}>
            <TextInput
              name="search"
              label={t("search")}
              value={state.filters.name}
              icon={<ReportProblemIcon />}
              onChange={(e) =>
                dispatch({
                  type: "SET_FILTER",
                  payload: { filterName: "name", value: e.target.value },
                })
              }
            />
          </Grid>
          {user.hasAction("CREATE_DOCUMENTS") && (
            <Grid item xs={8}>
              <Box>
                <Button
                  startIcon={<CreateNewFolderIcon />}
                  onClick={() => openDirectoryFormCreate()}
                >
                  {t("addFolder")}
                </Button>
                <Button
                  startIcon={<NoteAddIcon />}
                  onClick={() => openFileFormCreate()}
                  disabled={state.roots.length === 0}
                >
                  {t("addFile")}
                </Button>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} marginTop={1}>
            {state.rootsLoaded ? (
              filteredRoots.map((root, index) => (
                <DocsTree
                  root={root}
                  onNewDirectory={openDirectoryFormCreate}
                  onNewFile={openFileFormCreate}
                  onEditDirectory={openDirectoryFormEdit}
                  onEditFile={openFileFormEdit}
                  onDeleteDirectory={openConfirmDeleteDir}
                  onDeleteFile={openConfirmDeleteFile}
                  onViewFile={viewFile}
                  onDownloadFile={downloadFile}
                  key={index}
                />
              ))
            ) : (
              <Grid item container spacing={1}>
                {[...Array(15)].map((_, index) => (
                  <Grid item key={index} xs={12}>
                    <Skeleton variant="rounded" width="100%" height={40} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Directory form */}
        {state.isDirectoryFormOpen && (
          <DirectoryForm
            open={state.isDirectoryFormOpen}
            onClose={closeDirectoryForm}
            isNew={state.isNewDirectory}
            onSubmit={state.isNewDirectory ? createDirectory : editDirectory}
            data={state.dirData}
            users={state.users}
            roles={state.roles}
          />
        )}

        {/* File form */}
        {state.isFileFormOpen && (
          <FileForm
            open={state.isFileFormOpen}
            onClose={closeFileForm}
            isNew={state.isNewFile}
            onSubmit={state.isNewFile ? createFile : editFile}
            data={state.fileData}
            users={state.users}
            roles={state.roles}
          />
        )}

        {/* Confirm Dialog */}
        <ConfirmDialog
          title={state.confirmDialog.title}
          open={state.confirmDialog.isOpen}
          setOpen={setConfirmDialogState}
          onConfirm={state.confirmDialog.callback}
        >
          <Typography variant="body2" color="initial">
            {state.confirmDialog.childrenText}
          </Typography>
        </ConfirmDialog>
      </Container>
    </Page>
  );
}
