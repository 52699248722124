export const days = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

export const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const quarters = ["T1", "T2", "T3", "T4"];

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const distinctValues = (array) => {
  return [...new Set(array)];
};

export const count = (array, values) => {
  let c = [];
  values.forEach((v) => {
    c.push(array.filter((a) => a === v).length);
  });
  return c;
};

export const sumArray = (array) => {
  let sum = 0;
  array.forEach((a) => (sum += a));
  return sum;
};

export const percentage = (array, values) => {
  let percentages = [];
  let countValues = count(array, values);

  countValues.forEach((countValue) => {
    let percentageValue = (countValue / array.length) * 100;
    percentages.push(percentageValue.toFixed(2));
  });

  return percentages;
};

// Converts an array of numbers to percentages
export const toPercentage = (array) => {
  let sum = 0;
  array.forEach((item) => (sum += item));

  return array.map((item) => (item / sum) * 100);
};

export const mean = (array) => {
  let sum = 0;
  array.forEach((v) => (sum += v));
  return sum / array.length;
};

export const formatDate = (date) => {
  let month = date.getMonth() + 1;
  if (month < 10) month = "0" + month;

  let day = date.getDate();
  if (day < 10) day = "0" + day;

  return date.getFullYear() + "-" + month + "-" + day;
};

export const getDates = (start, end, period) => {
  let startDate = new Date(start);
  let endDate = new Date(end);

  if (period === "month") startDate.setDate(endDate.getDate());
  let dates = [formatDate(startDate)];

  while (
    (period !== "year" && startDate < endDate) ||
    (period === "year" && startDate.getFullYear() < endDate.getFullYear())
  ) {
    switch (period) {
      case "day":
        startDate.setDate(startDate.getDate() + 1);
        break;
      case "month":
        startDate.setMonth(startDate.getMonth() + 1);
        break;
      case "quarter":
        break;
      case "half":
        break;
      case "year":
        startDate.setFullYear(startDate.getFullYear() + 1);
        break;
      default:
        return undefined;
    }
    dates.push(formatDate(startDate));
  }
  return dates;
};

export const getMonths = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  let months = [];

  startDate.setDate(1);
  endDate.setDate(1);

  // Prevent infinite loop
  if (endDate.getTime() < startDate.getTime()) return months;

  while (startDate.getTime() <= endDate.getTime()) {
    months.push({
      year: startDate.getFullYear(),
      month: startDate.getMonth() + 1,
    });
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return months;
};

/**
 *
 * @param {*} min Min value
 * @param {*} max Max value
 * @param {*} n Number of groups
 *
 * @returns Returns an array of numbers that define the ranges.
 */
export const ranges = (min, max, n) => {
  const range = max - min;
  const divisionRange = range / n;
  let ranges = [];
  for (let i = divisionRange; i < max; i += divisionRange) {
    ranges.push(i);
  }
  return [min, ...ranges, max];
};

export const splitConcat = (array) => {
  return [].concat.apply([], array);
};

export const toCumulativeArray = (array) => {
  let sum;
  return array.map(((sum = 0), (n) => (sum += n)));
};
