import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const ReceiptStateChip = (props) => {
  const { state } = props;
  const [t] = useTranslation("receipts");

  let text, color;
  switch (state) {
    case 0: // Created
      text = t("created");
      color = "warning";
      break;
    case 1: // Issued
      text = t("issued");
      color = "primary";
      break;
    case 2: // Paid
      text = t("paid");
      color = "success";
      break;
    case 3: // Returned
      text = t("returned");
      color = "error";
      break;
    default:
      text = "?";
      color = "default";
  }
  return <Chip color={color} label={text} size="small" {...props} />;
};

export default ReceiptStateChip;
