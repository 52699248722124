import axios from "axios";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import TextInput from "../../Inputs/TextInput";
import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CloseIcon from "@mui/icons-material/Close";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";

//To do import mapbox token
mapboxgl.accessToken =
  "pk.eyJ1IjoiZ25vbWJvIiwiYSI6ImNrNGszYXlxZjBjdGozanFpOGFpZno0bWEifQ.Gchc2G72ohn5xofBIq5agg";

function reducer(state, action) {
  switch (action.type) {
    case "SET_ADDRESS":
      return { ...state, address: action.payload };
    case "SET_ATTRIBUTES":
      return { ...state, attributes: action.payload };
    case "RESET_FORM":
      return { initialState };
    default:
      throw new Error("Action type not found in reducer");
  }
}

const initialState = {
  attributes: [],
};

export default function MapMarkForm({
  mapGroup,
  mapMark,
  onChange,
  addMark,
  removeMark,
}) {
  const [t] = useTranslation("maps");
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    /*  mapGroup.attributes.forEach(attribute => {
 
             if (attribute.type === "boolean") {
                 let data = mapMark.data;
                 data[attribute.name] = false;
                 onChange({ target: { name: "data", value: data } });
             }
 
         }); */
  }, []);

  const handleChangeData = (e) => {
    let data = mapMark.data;

    data[e.target.name] = e.target.value;

    onChange({ target: { name: "data", value: data } });
  };

  // Create endpoint for url api call
  const createURLApiCall = (address) => {
    var route = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
    var addressUrl = address.replaceAll(" ", "%20").toLowerCase() + ".json";

    let autocomplete = "true";
    //let country = "es";
    let language = "es";

    let params =
      "?access_token=" +
      mapboxgl.accessToken +
      "&autocomplete=" +
      autocomplete +
      //"&country=" + country +
      "&language=" +
      language;

    return route + addressUrl + params;
  };

  const getCoordinates = () => {
    if (state.address) {
      let url = createURLApiCall(state.address);
      axios
        .get(url)
        .then((response) => {
          let coordinates = response.data.features[0].center;
          let lon = coordinates[0];
          let lat = coordinates[1];

          onChange({ target: { name: "longitude", value: lon } });
          onChange({ target: { name: "latitude", value: lat } });
        })
        .catch((error) => {
          console.log("get coordinates error: ", error);
        });
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" >
            Marks:
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Grid item>
            <TextInput
              name="name"
              value={mapMark.name}
              onChange={(e) => {
                onChange(e);
              }}
              label={t("name")}
            />
          </Grid>
          <Grid item>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          {mapMark.latitude && mapMark.longitude && (
            <Typography variant="body1"  key={mapMark.id}>
              {"Lat, Lon: " + mapMark.latitude + ", " + mapMark.longitude}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        {mapGroup.attributes?.map((attribute, i) => {
          let input;
          switch (attribute.type) {
            case "boolean":
              input = (
                <Grid item>
                  <FormControlLabel
                    label={attribute.name}
                    name={attribute.name}
                    onChange={(e) => {
                      handleChangeData({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                    control={
                      <Checkbox checked={mapMark.data[attribute.name]} />
                    }
                  />
                </Grid>
              );
              break;
            case "string":
              input = (
                <Grid item>
                  <TextInput
                    label={attribute.name}
                    name={attribute.name}
                    value={mapMark.data[attribute.name]}
                    onChange={(e) => {
                      handleChangeData(e);
                    }}
                  />
                </Grid>
              );
              break;
            case "number":
              input = (
                <Grid item>
                  <TextInput
                    label={attribute.name}
                    name={attribute.name}
                    value={mapMark.data[attribute.name]}
                    onChange={(e) => {
                      handleChangeData(e);
                    }}
                    type="number"
                  />
                </Grid>
              );
              break;
            default:
          }
          return input;
        })}
        <Grid item>
          <Button
            onClick={() => {
              addMark();
              dispatch({ type: "SET_ADDRESS", payload: "" });
            }}
            variant="contained"
            color="primary"
            startIcon={<ControlPointIcon />}
          >
            {t("add")}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <List>
          {mapGroup.mapMarks.map((mark, i) => (
            <ListItem key={i}>
              <Grid container spacing={1}>
                <Grid item>
                  <IconButton>
                    <ArrowCircleUpIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton>
                    <ArrowCircleDownIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="body1" >
                    {mark.name}
                  </Typography>
                </Grid>
                {mapGroup.attributes.map((attribute) => (
                  <Grid item>
                    {attribute.name} : {mark.data[attribute.name]?.toString()}
                  </Grid>
                ))}
                <Grid item>
                  <IconButton>
                    <VisibilityIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="error"
                    onClick={() => {
                      removeMark(i);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
