import { useContext, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button, Card, Menu, MenuItem } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AppContext from "../context/AppContext";
import { useTranslation } from "react-i18next";

const CONTRACT_TEMPLATE_GUIDE = [
  { label: "{{startDate}}", description: "Fecha de inicio del contrato" },
  { label: "{{endDate}}", description: "Fecha de fin del contrato" },
  { label: "{{meters}}", description: "Metros del trastero" },
  { label: "{{publicId}}", description: "Identificador del contrato" },
  { label: "{{pricePerMeter}}", description: "Precio del metro" },
  { label: "{{taxes}}", description: "IVA aplicado al contrato" },
  { label: "{{Box.Center.name}}", description: "Nombre de la delegación" },
  {
    label: "{{Box.Center.businessName}}",
    description: "Nombre fiscal de la delegación",
  },
  { label: "{{Box.Center.cif}}", description: "CIF de la delegación" },
  { label: "{{Periodicity.name}}", description: "Periodo de pago" },
  { label: "{{Customer.name}}", description: "Nombre del cliente" },
  { label: "{{Customer.nif}}", description: "NIF del cliente" },
  { label: "{{Customer.address}}", description: "Dirección del cliente" },
  { label: "{{PaymentMethod.name}}", description: "Forma de pago" },
];

const CustomOption = ({ editorState, onChange }) => {
  const [t] = useTranslation("others");

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    document.title = t("htmlEditor");
  }, []);

  const addVar = (text) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text,
      editorState.getCurrentInlineStyle()
    );

    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  return (
    <>
      <Button
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        Variables
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {CONTRACT_TEMPLATE_GUIDE.map((item) => (
          <MenuItem
            onClick={() => {
              addVar(item.label);
            }}
          >
            {item.label}
            <ArrowForwardIcon />
            {item.description}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const HTMLEditor = ({ html, onChange }) => {
  const { api } = useContext(AppContext);
  const content = ContentState.createFromBlockArray(htmlToDraft(html));
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(content)
  );

  const previewPDFHandler = () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    
    api
      .post("/contracts/types/preview-template", { html: html }, { responseType: "blob" })
      .then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        const url = URL.createObjectURL(file);
        window.open(url);
      });
  };

  return (
    <>
      <Card>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={setEditorState}
          toolbarCustomButtons={[<CustomOption />]}
          onChange={() => {
            onChange(
              draftToHtml(convertToRaw(editorState.getCurrentContent()))
            );
          }}
        />
        <Button onClick={previewPDFHandler} startIcon={<VisibilityIcon />}>
          Preview PDF
        </Button>
      </Card>
    </>
  );
};



export default HTMLEditor;
