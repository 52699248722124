import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import UploadIcon from "@mui/icons-material/Upload";
import { useRef, useState } from "react";
import Button from "../../../Inputs/CustomButton";
import { enqueueSnackbar } from "notistack";
import TextInput from "../../../Inputs/TextInput";

const CreateImageForm = ({ onCreateTile, closeAddTileDialog, layerTiles }) => {
  const [t] = useTranslation("floorPlans");

  const [name, setName] = useState("Tile " + (layerTiles.length + 1));
  const [image, setImage] = useState("");
  const imageRef = useRef(null);

  // Image URL to display preview
  let imageURL = image ? URL.createObjectURL(image) : null;

  const validateForm = () => {
    if (!image || !name) return false;

    return true;
  };

  const handleCreate = () => {
    if (!image) {
      enqueueSnackbar(t("selectImage"), { variant: "warning" });
      return;
    }

    let imageFile = new File([image], image.name);

    let tile = {
      name: name,
      shape: "Image",
      image: imageFile,
      width: imageRef.current?.naturalWidth,
      height: imageRef.current?.naturalHeight,
    };

    onCreateTile(tile);
  };

  return (
    <Box width="100%">
      <Grid container justifyContent="center" spacing={1}>
        <Grid item>
          <TextInput
            label={t("tileName")}
            type={"text"}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item>
          <Button
            startIcon={<UploadIcon />}
            variant="outlined"
            color="primary"
            component="label"
          >
            {t("chooseImage")}
            <input
              type="file"
              hidden
              name="file"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
            />
          </Button>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Grid item>
            <img
              ref={imageRef}
              src={imageURL}
              style={{ maxWidth: "600px", maxHeight: "500px" }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} justifyContent="end" spacing={1}>
          <Grid item>
            <Button onClick={closeAddTileDialog} variant="text">
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="success"
              disabled={!validateForm()}
              onClick={handleCreate}
            >
              {t("add")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateImageForm;
