import { Container, Grid, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import AppContext from "../../../context/AppContext";

const WelcomePage = () => {
  const { user } = useContext(AppContext);

  //Initial useEffect
  useEffect(() => {
    document.title = "NUT";
  }, []);

  return (
    <Container sx={{ height: "100%" }}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Grid item>
          <Typography variant="h3" color="primary.dark" textAlign="center">
            Welcome {user?.name}!
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WelcomePage;
