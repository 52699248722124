import AppContext from "../../../context/AppContext";
import { Container, Grid } from "@mui/material";
import CustomButton from "../../Inputs/CustomButton";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import React, { useEffect, useReducer, useContext } from "react";
import TextInput from "../../Inputs/TextInput";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/chartUtils";

const initialState = {
  cancellationTypes: [],
  inputError: {
    cancellationTypeId: false,
    cancellationDate: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_TYPES":
      return { ...state, cancellationTypes: action.payload };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    default:
      throw new Error("Action not found in reducer");
  }
}

export default function RequestCancellationForm({
  contract,
  request,
  onChange,
  onSubmit,
  loading,
}) {
  const { api } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("contracts");

  useEffect(() => {
    getCancellationTypes();
    setDefaultCancellationDate();
  }, []);

  const getCancellationTypes = () => {
    api
      .get("/contracts/cancellations/types")
      .then((response) => {
        dispatch({ type: "SET_TYPES", payload: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setDefaultCancellationDate = () => {
    let date = new Date(contract.startDate);

    //From the start date to current period
    while (date < new Date()) {
      //Add periodicity days
      date.setDate(date.getDate() + contract.periodicity);
    }

    date = formatDate(date);
    request.cancellationDate = date;
  };

  const validator = () => {
    let isValid = true;
    let fields = ["cancellationTypeId", "cancellationDate"];

    fields.forEach((field) => {
      if (request[field] === "") {
        setInputErrorTrue(field);
        isValid = false;
      }
    });

    return isValid;
  };

  const handleSubmitForm = () => {
    if (validator()) onSubmit();
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item>
          <CustomSelect
            value={request.cancellationTypeId}
            onChange={onChange}
            error={state.inputError.cancellationTypeId}
            helperText={
              state.inputError.cancellationTypeId
                ? "Cancelation type must not be blank"
                : ""
            }
            label={t("cancellationType")}
            name="cancellationTypeId"
            options={[
              ...state.cancellationTypes.map((type) => ({
                value: type.id,
                label: type.name,
              })),
            ]}
          />
        </Grid>
        <Grid item>
          <CustomDate
            value={request.cancellationDate}
            onChange={onChange}
            error={state.inputError.cancellationDate}
            helperText={
              state.inputError.cancellationDate
                ? "Cancelation date must not be blank "
                : ""
            }
            label={t("cancellationDate")}
            name="cancellationDate"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            value={request.comments}
            onChange={onChange}
            label={t("comments")}
            name="comments"
            multiline
            rows={3}
          />
        </Grid>

        <Grid item>
          <CustomButton
            onClick={handleSubmitForm}
            disabled={
              formatDate(new Date()) >
              formatDate(new Date(request.cancellationDate))
                ? true
                : false
            }
            loading={loading}
          >
            {t("sendRequest")}
          </CustomButton>
        </Grid>
      </Grid>
    </Container>
  );
}
