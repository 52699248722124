import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  BLOCKED_BOX_STATE_ID,
  FREE_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,
  BOOKED_BOX_STATE_ID,
} from "../data/constants";

const BoxStateChip = (props) => {
  const { state, endDate, ...rest } = props;
  const [t] = useTranslation("boxes");
  let text, color;
  switch (state) {
    case FREE_BOX_STATE_ID:
      text = t("free");
      color = "primary";
      break;
    case OCCUPIED_BOX_STATE_ID:
      text = endDate
        ? t("occupied") + " " + t("until") + " " + endDate
        : t("occupied");
      color = "error";
      break;
    case UNAVAILABLE_BOX_STATE_ID:
      text = t("unavailable");
      color = "default";
      break;
    case BLOCKED_BOX_STATE_ID:
      text = t("blocked");
      color = "black";
      break;
    case BOOKED_BOX_STATE_ID:
      text = t("booked");
      color = "yellow";
      break;
    default:
      text = "?";
      color = "default";
  }
  return <Chip color={color} label={text} size="small" {...rest} />;
};

export default BoxStateChip;
