import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const ContractStateChip = (props) => {
  const { state, endDate } = props;
  const [t] = useTranslation("contracts");
  let text, color;
  
  switch (state) {
    case 0:
      text = t("pending");
      color = "primary";
      break;
    case 1:
      // Checks if end date is set.
      text = endDate ? t("endsAt") + " " + endDate : t("active");
      color = "success";
      break;
    case 2:
      text = t("ended");
      color = "error";
      break;
    default:
      text = "?";
      color = "default";
  }
  return <Chip color={color} label={text} {...props} />;
};

export default ContractStateChip;
