import EuroSymbolRoundedIcon from "@mui/icons-material/EuroSymbolRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { TreeItem } from "@mui/lab";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useContext } from "react";
import AppContext from "../../../context/AppContext";

const ExpenseTypeTreeItem = (props) => {
  const { user } = useContext(AppContext);

  const {
    expenseType,
    onNewExpenseType,
    onDeleteExpenseType,
    onEditExpenseType,
  } = props;

  const [iconsVisible, setIconsVisible] = useState(false);

  const hasAction = (action) => {
    let isFound = false;
    user?.Role.Actions.forEach((item) => {
      if (item.id === action) {
        isFound = true;
      }
    });

    return isFound;
  };

  const showIcon = (action) => {
    if (
      action === "CREATE_EXPENSE_TYPES" &&
      expenseType.ProviderInvoices?.length === 0
    )
      return hasAction(action);
    else if (
      action === "DELETE_EXPENSE_TYPES" &&
      expenseType.children?.length === 0 &&
      expenseType.ProviderInvoices?.length === 0
    )
      return hasAction(action);
    else if (action === "EDIT_EXPENSE_TYPES" && hasAction(action))
      return hasAction(action);
    return false;
  };

  return (
    <Paper sx={{ margin: 1 }}>
      <TreeItem
        {...props}
        onMouseEnter={() => {
          setIconsVisible(true);
        }}
        onMouseLeave={() => {
          setIconsVisible(false);
        }}
        icon={expenseType?.children?.length === 0 && <EuroSymbolRoundedIcon />}
        label={
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography sx={{ marginY: 1 }}>{expenseType?.name}</Typography>
            </Grid>
            {iconsVisible && (
              <Grid item>
                {showIcon("CREATE_EXPENSE_TYPES") && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onNewExpenseType(expenseType.id);
                    }}
                    size="small"
                  >
                    <AddCircleOutlineRoundedIcon />
                  </IconButton>
                )}
                {showIcon("EDIT_EXPENSE_TYPES") && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditExpenseType(expenseType.id);
                    }}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {showIcon("DELETE_EXPENSE_TYPES") && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteExpenseType(expenseType);
                    }}
                    size="small"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                )}
              </Grid>
            )}
          </Grid>
        }
      >
        {expenseType?.children?.map((child) => (
          <ExpenseTypeTreeItem
            {...props}
            expenseType={child}
            key={child.id}
            parentId={expenseType.id}
            nodeId={"expense-type" + child.id}
          />
        ))}
      </TreeItem>
    </Paper>
  );
};

export default ExpenseTypeTreeItem;
