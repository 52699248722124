import { Box, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import AppContext from "../../../context/AppContext";
import Button from "../../Inputs/CustomButton";
import ConfirmDialog from "../../ConfirmDialog";
import TextInput from "../../Inputs/TextInput";
import { localeFormat } from "../../../utils/format";
import ItemsSummary from "../../ItemsSummary";
import { R_INVOICE_SERIES_ID } from "../../../data/constants";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_ITEMS":
      return { ...state, selectedItems: action.payload };
    case "SET_CREATING":
      return { ...state, creating: action.payload };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "SET_SUMMARY":
      return {
        ...state,
        invoiceSummary: {
          showSummmary: !state.invoiceSummary.showSummmary,
          invoice: action.payload,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    default:
      throw new Error("Action not found in reducer");
  }
};
const initialState = {
  creating: false,
  selectedItems: [],
  confirmDialog: {
    title: "",
    confirmText: "",
    cancelText: "",
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
  invoiceSummary: {
    showSummmary: false,
    invoice: [],
  },
};

const RectifyingInvoiceForm = (props) => {
  const { invoice, onSubmit } = props;
  const [t] = useTranslation("invoices");
  const [state, dispatch] = useReducer(reducer, initialState);
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const TABLE_COLUMNS = [
    {
      field: "concept",
      headerName: t("concept"),
      flex: 1,
    },
    {
      field: "units",
      headerName: t("units"),
    },
    {
      field: "pricePerUnit",
      headerName: t("pricePerUnit"),
      valueFormatter: (params) => localeFormat(params.value) + "€",
    },
    {
      field: "base",
      headerName: t("base"),
      valueFormatter: (params) => localeFormat(params.value) + "€",
    },
    ,
    {
      field: "taxPercentage",
      headerName: t("taxPercentage"),
      valueFormatter: (params) => localeFormat(params.value) + "%",
    },
    ,
    {
      field: "total",
      headerName: t("total"),
      valueFormatter: (params) => localeFormat(params.value) + "€",
    },
  ];

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const openConfirm = (invoiceNumber) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("rectifyConfirmDialog"),
        childrenText:
          t("theInvoice") + " " + invoiceNumber + " " + t("alreadyRectified"),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && openConfirmReleaseMerchantable();
          confirmed === false &&
            dispatch({ type: "SET_CREATING", payload: false });
        },
      },
    });
  };

  const openConfirmReleaseMerchantable = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("releaseMerchantables"),
        childrenText: t("doYouWantToReleaseMerchantablesQuestion"),
        confirmText: t("yes"),
        cancelText: t("no"),
        isOpen: true,
        callback: (confirmed) => {
          createInvoice(confirmed);
          resetConfirmDialog();
        },
      },
    });
  };

  const checkAlreadyRectified = () => {
    if (!validateRectifyInvoiceForm()) return;

    dispatch({ type: "SET_CREATING", payload: true });

    const params = {
      include: ["InvoiceItem", "Merchantable", "Invoice"],
    };

    let isRectified = false;
    api
      .get("/invoices/" + invoice.id, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
          dispatch({ type: "SET_CREATING", payload: false });
        } else {
          response.data.items.forEach((item) => {
            item.Merchantable.InvoiceItems.forEach((merchantableItem) => {
              merchantableItem.Invoice.serieId === R_INVOICE_SERIES_ID &&
                (isRectified = true);
            });
          });
          if (isRectified) openConfirm(invoice.number);
          else openConfirmReleaseMerchantable();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createInvoice = (releaseMerchantables) => {
    const data = {
      invoiceId: invoice.id,
      items: state.selectedItems,
      state: invoice.state,
      releaseMerchantables,
    };

    api
      .post("/invoices/rectify", data)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("invoiceRectificationSuccess"), {
            variant: "success",
          });
          dispatch({ type: "SET_SUMMARY", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_CREATING", payload: false });
      });
  };

  const validateRectifyInvoiceForm = () => {
    // Validate items
    if (
      !Array.isArray(state.selectedItems) ||
      state.selectedItems.length === 0
    ) {
      enqueueSnackbar(t("selectOneItem"), { variant: "warning" });
      return false;
    }

    // Validate invoiceId
    if (typeof invoice.id !== "number") {
      enqueueSnackbar(t("invalidInvoice"), { variant: "error" });
      return false;
    }

    return true;
  };

  return !state.invoiceSummary.showSummmary ? (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("rectifyingInvoice")}</Typography>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item>
            <Typography component="span" fontWeight="bold">
              {t("invoice")}:{" "}
            </Typography>
            <Typography component="span">
              {invoice.InvoiceSerie?.name + invoice.number}
            </Typography>
          </Grid>

          <Grid item>
            <Typography component="span" fontWeight="bold">
              {t("date")}:{" "}
            </Typography>
            <Typography component="span">{invoice.issueDate}</Typography>
          </Grid>

          <Grid item>
            <Typography component="span" fontWeight="bold">
              {t("customer")}:{" "}
            </Typography>
            <Typography component="span">{invoice.customerName}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ItemsSummary
            gridItems={[
              { translatedText: t("lines"), value: state.selectedItems.length },
              {
                translatedText: t("base"),
                value:
                  localeFormat(
                    state.selectedItems.reduce(
                      (total, item) =>
                        total + invoice.items.find((i) => i.id === item).base,
                      0
                    )
                  ) + "€",
              },
              {
                translatedText: t("total"),
                value:
                  localeFormat(
                    state.selectedItems.reduce(
                      (total, item) =>
                        total + invoice.items.find((i) => i.id === item).total,
                      0
                    )
                  ) + "€",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Box style={{ width: "100%" }}>
            <DataGrid
              rows={invoice?.items || []}
              columns={TABLE_COLUMNS}
              checkboxSelection
              onSelectionModelChange={(selectedRows) => {
                dispatch({ type: "SET_SELECTED_ITEMS", payload: selectedRows });
              }}
              rowSelectionModel={state.selectedItems}
              autoHeight
            />
          </Box>
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Grid item>
            <Button
              color="success"
              onClick={checkAlreadyRectified}
              loading={state.creating}
              disabled={state.selectedItems.length === 0}
            >
              {t("create")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        title={state.confirmDialog.title}
        confirmText={state.confirmDialog.confirmText}
        cancelText={state.confirmDialog.cancelText}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Box>
  ) : (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("invoiceSummary")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography component="span" fontWeight="bold">
            {t("number")}:{" "}
          </Typography>
          <Typography component="span">
            <Button
              variant="text"
              onClick={() => {
                onSubmit(state.invoiceSummary.invoice.id);
              }}
            >
              {state.invoiceSummary.invoice.number}
            </Button>
          </Typography>
          <Grid></Grid>
          <Typography component="span" fontWeight="bold">
            {t("customer")}:{" "}
          </Typography>
          <Typography component="span">
            {state.invoiceSummary.invoice.customerName}
          </Typography>
        </Grid>

        <Grid item container xs={12} spacing={3}>
          <Grid item>
            <Typography component="span" fontWeight="bold">
              {t("issueDate")}:{" "}
            </Typography>
            <Typography component="span">
              {state.invoiceSummary.invoice.issueDate}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="span" fontWeight="bold">
              {t("dueDate")}:{" "}
            </Typography>
            <Typography component="span">
              {state.invoiceSummary.invoice.issueDate}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ItemsSummary
            gridItems={[
              {
                translatedText: t("base"),
                value:
                  localeFormat(
                    state.invoiceSummary.invoice.items.reduce(
                      (total, item) => total + item.base,
                      0
                    )
                  ) + "€",
              },
              {
                translatedText: t("total"),
                value:
                  localeFormat(
                    state.invoiceSummary.invoice.items.reduce(
                      (total, item) => total + item.total,
                      0
                    )
                  ) + "€",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Box style={{ maxHeight: 400, width: "100%" }}>
            <DataGrid
              rows={state.invoiceSummary.invoice.items}
              columns={TABLE_COLUMNS}
              autoHeight
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RectifyingInvoiceForm;
