import { faFolder, faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TreeView from "@mui/lab/TreeView";

import DirectoryTreeItem from "./DirectoryTreeItem";

const DocsTree = (props) => {
  const {
    root,
    onNewDirectory,
    onNewFile,
    onEditDirectory,
    onEditFile,
    onDeleteDirectory,
    onDeleteFile,
    onViewFile,
    onDownloadFile,
  } = props;

  return (
    <TreeView
      defaultCollapseIcon={<FontAwesomeIcon icon={faFolderOpen} />}
      defaultExpandIcon={<FontAwesomeIcon icon={faFolder} />}
    >
      <DirectoryTreeItem
        directory={root}
        key={root.id}
        nodeId={"directory" + root.id}
        onNewDirectory={onNewDirectory}
        onNewFile={onNewFile}
        onEditDirectory={onEditDirectory}
        onEditFile={onEditFile}
        onDeleteDirectory={onDeleteDirectory}
        onDeleteFile={onDeleteFile}
        onViewFile={onViewFile}
        onDownloadFile={onDownloadFile}
      />
    </TreeView>
  );
};

export default DocsTree;
