import {
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useSnackbar } from "notistack";

import AppContext from "../../../context/AppContext";
import CenterSelect from "../../Inputs/CenterSelect";
import InvoiceSerieSelect from "../../Inputs/InvoiceSerieSelect";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";

function IssuerDiv({ issuer, deleter, centers, handleChangeIssuersCenters }) {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("settings");

  const hasAction = (action) => {
    return user?.Role.Actions.some((item) => item.id === action) || false;
  };

  let selected = [];
  issuer.InvoiceSeries?.forEach((serie) => {
    selected.push(serie.id);
  });

  const changeCenters = (e) => {
    const targetCenters = e.target.value;
    const issuerCenters = issuer.selectedCenters;

    const value = issuerCenters.length < targetCenters.length;
    let add;
    if (value)
      add = targetCenters.find((value) => issuerCenters.indexOf(value) === -1);
    else
      add = issuerCenters.find((value) => targetCenters.indexOf(value) === -1);
    const center = centers.filter((center) => center.id === add)[0];

    handleChangeIssuersCenters(value, issuer, center);
  };

  const changeInvoiceSeries = (e) => {
    const targetInvoiceSeries = e.target.value;
    const issuerInvoiceSeries = issuer.selectedInvoiceSeries;

    //Remove invoice serie:
    if (issuerInvoiceSeries.length > targetInvoiceSeries.length) {
      const remove = issuerInvoiceSeries.find(
        (value) => targetInvoiceSeries.indexOf(value) === -1
      );
      const data = { id: remove };
      api
        .delete("/issuers/" + issuer.id + "/serie", { data })
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            issuer.selectedInvoiceSeries = issuer.selectedInvoiceSeries.filter(
              (value) => value !== remove
            );
            enqueueSnackbar(t("invoiceSerieRemoved"), { variant: "success" });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
    //Add invoice serie:
    else if (issuerInvoiceSeries.length < targetInvoiceSeries.length) {
      const add = targetInvoiceSeries.find(
        (value) => issuerInvoiceSeries.indexOf(value) === -1
      );
      const data = { id: add };
      api
        .post("/issuers/" + issuer.id + "/serie", data)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            issuer.selectedInvoiceSeries.push(add);
            enqueueSnackbar(t("invoiceSerieAdded"), { variant: "success" });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t("name")}: {issuer.name}
            </Typography>
          </Grid>
          {/* {hasAction("EDIT_ISSUERS") && (
            <Grid item xs={1}>
              <IconButton
                title={t("delete")}
                onClick={() => {
                  deleter(issuer.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          )} */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t("issuerName")}: {issuer.issuerName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {hasAction("EDIT_ISSUERS") ? (
              <InvoiceSerieSelect
                multiple
                name="series"
                onChange={(e) => {
                  changeInvoiceSeries(e);
                }}
                value={issuer.selectedInvoiceSeries}
              ></InvoiceSerieSelect>
            ) : (
              <Typography variant="body">
                {t("issuerSeries")}: {issuer.selectedInvoiceSeries}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            {hasAction("EDIT_ISSUERS") ? (
              <CenterSelect
                multiple
                name="centers"
                onChange={(e) => {
                  changeCenters(e);
                }}
                value={issuer.selectedCenters}
              ></CenterSelect>
            ) : (
              <Typography variant="body">
                {t("totalIssuerCenters")}: {issuer.selectedCenters.length}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default function IssuersList({
  issuers,
  deleter,
  centers,
  handleChangeIssuersCenters,
}) {
  issuers.forEach((issuer) => {
    issuer.selectedCenters = issuer.Centers.map((center) => center.id);
    issuer.selectedInvoiceSeries = issuer.InvoiceSeries.map(
      (invoiceSerie) => invoiceSerie.id
    );
  });

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item>
          <List>
            {issuers.map((issuer) => (
              <ListItem>
                <IssuerDiv
                  issuer={issuer}
                  centers={centers}
                  deleter={deleter}
                  handleChangeIssuersCenters={handleChangeIssuersCenters}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
}
