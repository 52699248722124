import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { generateURL, getParams } from "../../../utils/url";

import AddIcon from "@mui/icons-material/Add";
import AppContext from "../../../context/AppContext";
import ArticleIcon from "@mui/icons-material/Article";
import MailIcon from "@mui/icons-material/Mail";

import BankAccountForm from "./BankAccountForm";
import BankAccountsList from "./BankAccountsList";
import CenterIssuersTable from "./CenterIssuersTable";
import ConfirmDialog from "../../ConfirmDialog";
import Dialog from "../../global/Dialog";
import EmailsList from "./EmailsList";
import EmailTemplateList from "./EmailTemplateList";
import EmailSettingsForm from "./EmailSettingsForm";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TransferAccountForm from "./TransferAccountForm";
import TransferAccountList from "./TransferAccountList";
import HTMLEditor from "../../HTMLEditor";
import InvoiceSeriesList from "./InvoiceSeriesList";
import IssuersList from "./IssuersList";
import TabPanel from "./TabPanel";
import TextInput from "../../Inputs/TextInput";
import UsersList from "./UsersList";
import CustomSelect from "../../Inputs/CustomSelect";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_ISSUERS": //invoice series
      return { ...state, issuers: action.payload };
    case "SET_CENTER_ISSUERS":
      return { ...state, centers: action.payload };
    case "SET_SERIES":
      return { ...state, series: action.payload };
    case "SET_EMAILS":
      return { ...state, emails: action.payload };
    case "SET_BANK_ACCOUNTS":
      return { ...state, bankAccounts: action.payload };
    case "SET_USERS":
      return { ...state, users: action.payload };
    case "SET_INPUT":
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_TRANSFER_ACCOUNTS":
      return { ...state, transferAccounts: action.payload };
    case "SET_PAYMENT_DAY":
      return { ...state, paymentDays: action.payload };
    case "RESET_FORM":
      return { ...state, [action.payload]: initialState[action.payload] };
    case "SET_EMAIL_TEMPLATES_TYPES":
      return {
        ...state,
        emailTemplateTypes: action.payload,
      };
    case "SET_TAB":
      return { ...state, tab: action.payload };
    case "SET_EMAIL_ERROR":
      return {
        ...state,
        emailInputError: {
          ...state.emailInputError,
          [action.payload.input]: action.payload.value,
        },
      };

    case "OPEN_INVOICE_TEMPLATE_MODAL":
      return {
        ...state,
        invoiceTemplateModal: { ...state.invoiceTemplateModal, isOpen: true },
      };
    case "CLOSE_INVOICE_TEMPLATE_MODAL":
      return {
        ...state,
        invoiceTemplateModal: { ...state.invoiceTemplateModal, isOpen: false },
      };
    case "OPEN_ADD_ISSUER_MODAL":
      return {
        ...state,
        addIssuerModal: { ...state.addIssuerModal, isOpen: true },
      };
    case "CLOSE_ADD_ISSUER_MODAL":
      return {
        ...state,
        addIssuerModal: { ...state.addIssuerModal, isOpen: false },
      };
    case "OPEN_ADD_INVOICE_SERIES_MODAL":
      return {
        ...state,
        addInvoiceSeriesModal: { ...state.addInvoiceSeriesModal, isOpen: true },
      };
    case "CLOSE_ADD_INVOICE_SERIES_MODAL":
      return {
        ...state,
        addInvoiceSeriesModal: {
          ...state.addInvoiceSeriesModal,
          isOpen: false,
        },
      };
    case "SET_INVOICE_TEMPLATE":
      return {
        ...state,
        invoiceTemplateModal: {
          ...state.invoiceTemplateModal,
          html: action.payload,
        },
      };
    case "SET_MODAL":
      return {
        ...state,
        modals: {
          ...state.modal,
          [action.payload.modal]: action.payload.value,
        },
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    default:
      throw new Error("Action not found in reducer");
  }
};

const initialState = {
  tab: 0,

  bankAccounts: [],
  bankAccount: {
    iban: "",
    name: "",
  },

  emails: [],
  emailTemplateTypes: [],
  email: {
    id: "",
    user: "",
    password: "",
    repeat: "",
  },
  emailInputError: {
    user: false,
    password: false,
    repeat: false,
  },

  series: [],
  invoiceSerie: {
    name: "",
  },

  issuers: [],
  issuer: {
    address: "",
    issuerName: "",
    name: "",
    nif: "",
  },
  invoiceTemplateModal: {
    isOpen: false,
    html: "",
    title: "addTemplate",
  },
  addIssuerModal: {
    isOpen: false,
    address: "",
    issuerName: "",
    name: "",
    nif: "",
    title: "addIssuer",
  },
  addInvoiceSeriesModal: {
    isOpen: false,
    name: "",
    title: "addInvoiceSerie",
  },
  transferAccounts: [],
  transferAccount: {
    accountName: "",
    IBAN: "",
    debtorName: "",
    NIF: "",
    debtorId: "",
  },

  centers: [],
  users: [],
  paymentDays: [],

  modals: {
    emailModal: false,
    emailEditPasswordModal: false,
  },
  confirmDialog: {
    title: "",
    confirmText: "",
    cancelText: "",
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
};

export default function SettingsPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("settings");
  const [tErrors] = useTranslation("errors");

  //Initial useEffect
  useEffect(() => {
    document.title = t("settingsPage");

    //Get and set tab value from url if necessary:
    if (getParams(query, [{ name: "tab", type: "number" }]).tab)
      dispatch({
        type: "SET_TAB",
        payload: Number(
          getParams(query, [{ name: "tab", type: "number" }]).tab
        ),
      });

    //TODO: Check if all these calls are necessary
    getEmails();
    getBankAccounts();
    getTransferAccounts();
    getIssuers();
    getCenters();
    getInvoiceSeries();
    getEmailTemplateTypes();
    getUsers();
    getPaymentDays();
  }, []);

  const getEmails = () => {
    api
      .get("/emails")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_EMAILS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getBankAccounts = () => {
    api
      .get("/bank-accounts")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_BANK_ACCOUNTS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getTransferAccounts = () => {
    api
      .get("/transfer-accounts")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_TRANSFER_ACCOUNTS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getIssuers = () => {
    api
      .get("/issuers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_ISSUERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getCenters = () => {
    const params = {
      include: ["Issuer"],
    };
    api
      .get("/centers", { params })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noCenters"), { variant: "warning" });
          } else {
            dispatch({
              type: "SET_CENTERS",
              payload: response.data,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoiceSeries = () => {
    api
      .get("/invoice-series")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_SERIES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getEmailTemplateTypes = () => {
    api
      .get("/email-template-types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_EMAIL_TEMPLATES_TYPES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getUsers = () => {
    api
      .get("/users")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const usernames = response.data.map((user) => user.name);
          dispatch({ type: "SET_USERS", payload: usernames });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentDays = () => {
    api
      .get("/payment-days/get")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const days = response.data.map((day) => day.day);
          dispatch({ type: "SET_PAYMENT_DAY", payload: days });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleInputChange = (e, form) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
        form: form,
      },
    });
  };

  const createEmail = () => {
    let form = {
      user: state.email.user,
      password: state.email.password,
      repeat: state.email.repeat,
    };

    if (validateEmailForm(form)) {
      api
        .post("/emails/create", form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("emailCreateSuccess"), { variant: "success" });
            dispatch({ type: "RESET_FORM", payload: "email" });
            let emails = state.emails;
            emails.push(response.data.email);

            dispatch({ type: "SET_EMAILS", payload: emails });
            handleCloseEmailModal();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const editEmailPassword = () => {
    if (state.email.password !== state.email.repeat) {
      enqueueSnackbar(t("passwordsMustMatch"), { variant: "warning" });
      return;
    }
    let form = {
      password: state.email.password,
    };
    api
      .post("/emails/edit-password/" + state.email.id, form)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("emailPasswordChanged"), { variant: "success" });
          handleCloseEmailEditPasswordModal();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const validateEmailForm = (form) => {
    let fields = ["user", "password", "repeat"];
    let isValid = true;

    // Check if any field is not null
    fields.forEach((field) => {
      if (form[field] === "") {
        dispatch({
          type: "SET_EMAIL_ERROR",
          payload: { input: field, value: true },
        });
        isValid = false;
      }
    });

    // Check if passwords match
    if (form.password !== form.repeat) {
      dispatch({
        type: "SET_EMAIL_ERROR",
        payload: { input: "repeat", value: true },
      });
      isValid = false;
    }

    // Check if email is unique
    if (
      state.emails.filter(
        (email) =>
          email.user.substring(0, email.user.indexOf("@")) === form.user
      ).length > 0
    ) {
      dispatch({
        type: "SET_EMAIL_ERROR",
        payload: { input: "user", value: true },
      });
      enqueueSnackbar(t("emailUserTaken"), { variant: "warning" });
      isValid = false;
    }

    return isValid;
  };

  const deleteEmail = (id) => {
    api
      .delete("/emails/" + id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("emailDeleteSuccess"), { variant: "success" });
          const emails = state.emails.filter((email) => email.id !== id);

          dispatch({
            type: "SET_EMAILS",
            payload: emails,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createBankAccount = () => {
    let form = state.bankAccount;
    form.IBAN = { number: form.iban };
    api
      .post("/bank-accounts/create", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("bankAccountCreateSuccess"), {
            variant: "success",
          });

          let bankAccounts = state.bankAccounts;
          bankAccounts.push(response.data.bankAccount);

          dispatch({
            type: "SET_BANK_ACCOUNTS",
            payload: bankAccounts,
          });
          dispatch({ type: "RESET_FORM", payload: "bankAccount" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createIssuer = () => {
    api
      .post("/issuer/create", state.issuer)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("isssuerCreateSuccess"), { variant: "success" });

          let issuers = state.issuers;
          issuers.push(response.data.issuerRes);

          dispatch({
            type: "SET_ISSUERS",
            payload: issuers,
          });
          dispatch({ type: "RESET_FORM", payload: "issuer" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteIssuer = (id) => {
    console.log("Para borrar el", id);
    return;
    api
      .delete(`/issuer/${id}/delete`)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else {
          getIssuers();
          enqueueSnackbar(t("issuerDeletedSucessfully"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const createTransferAccount = () => {
    let form = state.transferAccount;
    api
      .post("/transfer-accounts/create", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.result.BIC == "")
            enqueueSnackbar(t("bicNotFound"), { variant: "warning" });
          enqueueSnackbar(t("transferAccountCreateSuccess"), {
            variant: "success",
          });
          getTransferAccounts();
          dispatch({ type: "RESET_FORM", payload: "transferAccount" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteBankAccount = (id) => {
    api
      .delete("/bank-accounts/" + id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("bankAccountDeleteSuccess"), {
            variant: "success",
          });
          const bankAccounts = state.bankAccounts.filter(
            (bankAccount) => bankAccount.id !== id
          );

          dispatch({
            type: "SET_BANK_ACCOUNTS",
            payload: bankAccounts,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteTransferAccount = (id) => {
    api
      .delete("/transfer-accounts/" + id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("transferAccountDeleteSuccess"), {
            variant: "success",
          });
          getTransferAccounts();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleChangeIssuersCenters = (value, issuer, center) => {
    let data = { id: center.id };

    // If true, add center to issuer, else remove center
    if (value) {
      api
        .post("/issuers/" + issuer.id + "/center", data)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("centerAdded"), { variant: "success" });

            let issuers = state.issuers;
            issuers.forEach((item) => {
              if (item.id === issuer.id) {
                item.Centers.push(center);
                state.centers.forEach((cent) => {
                  if (cent.id === center.id) cent.Issuers.push(item);
                });
                dispatch({
                  type: "SET_CENTER_ISSUERS",
                  payload: state.centers,
                });
              }
            });
            dispatch({ type: "SET_ISSUERS", payload: issuers });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else {
      api
        .delete("/issuers/" + issuer.id + "/center", { data })
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("centerRemoved"), { variant: "success" });
            if (center.defaultIssuer === issuer.id)
              setDefaultIssuer(center.id, "");
            let issuers = state.issuers;
            issuers.forEach((item) => {
              if (item.id === issuer.id) {
                item.Centers = item.Centers.filter((i) => i.id !== center.id);
                state.centers.forEach((cent) => {
                  if (cent.id === center.id)
                    cent.Issuers = cent.Issuers.filter(
                      (is) => is.id !== issuer.id
                    );
                });
                dispatch({
                  type: "SET_CENTER_ISSUERS",
                  payload: state.centers,
                });
              }
            });
            dispatch({ type: "SET_ISSUERS", payload: issuers });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const createInvoiceSerie = () => {
    api
      .post("/invoice-series/create", state.invoiceSerie)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("invoiceSerieCreateSuccess"), {
            variant: "success",
          });
          let serie = response.data.invoiceSerie;
          let series = state.series;
          series.push(serie);
          dispatch({ type: "SET_SERIES", payload: series });
          dispatch({ type: "RESET_FORM", payload: "invoiceSerie" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const setDefaultIssuer = (centerId, issuerId) => {
    api
      .post("/centers/" + centerId + "/default-issuer", {
        defaultIssuer: issuerId,
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("centerEditSuccess"), { variant: "success" });
          let centers = state.centers;
          centers.forEach((center) => {
            if (center.id === centerId) center.defaultIssuer = issuerId;
          });
          dispatch({ type: "SET_CENTERS", payload: centers });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createEmailTemplate = (form) => {
    api
      .post("/email-templates/create", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("createTemplateSuccess"), { variant: "success" });
          getEmailTemplateTypes();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const modifyEmailTemplate = (form) => {
    api
      .post("/email-templates/edit/" + form.id, form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("editTemplateSuccess"), { variant: "success" });
          getEmailTemplateTypes();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteEmailTemplate = (id) => {
    api
      .delete("/email-templates/" + id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("deleteTemplateSuccess"), { variant: "success" });
          getEmailTemplateTypes();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleTabChange = (event, newValue) => {
    dispatch({ type: "SET_TAB", payload: newValue });
    const url = generateURL("/app/settings", { tab: newValue });
    history.push(url);
  };

  //invoice pdf template
  const openInvoiceTemplateModal = () => {
    dispatch({ type: "OPEN_INVOICE_TEMPLATE_MODAL" });
  };

  const closeInvoiceTemplateModal = () => {
    dispatch({ type: "CLOSE_INVOICE_TEMPLATE_MODAL" });
  };

  const openAddIssuerModal = () => {
    dispatch({ type: "OPEN_ADD_ISSUER_MODAL" });
  };

  const closeAddIssuerModal = () => {
    dispatch({ type: "CLOSE_ADD_ISSUER_MODAL" });
  };

  const openAddInvoiceSeriesModal = () => {
    dispatch({ type: "OPEN_ADD_INVOICE_SERIES_MODAL" });
  };

  const closeAddInvoiceSeriesModal = () => {
    dispatch({ type: "CLOSE_ADD_INVOICE_SERIES_MODAL" });
  };

  const saveInvoiceTemplate = () => {
    api
      .post("/invoices/template", { html: state.invoiceTemplateModal.html })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(response.data.msg, { variant: "success" });
          closeInvoiceTemplateModal();
          dispatch({ type: "SET_INVOICE_TEMPLATE", payload: "" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleInvoiceTemplateChange = (html) => {
    dispatch({ type: "SET_INVOICE_TEMPLATE", payload: html });
  };

  const handlePaymentDay = (e) => {
    let toAdd = e.target.value.filter(
      (value) => state.paymentDays.indexOf(value) === -1
    );
    let toDelete = state.paymentDays.filter(
      (value) => e.target.value.indexOf(value) === -1
    );

    if (toAdd.length > 0) {
      api
        .post("/payment-days/create", { day: toAdd })
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_PAYMENT_DAY", payload: e.target.value });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
    if (toDelete.length > 0) {
      api
        .delete("/payment-days/delete/" + toDelete)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_PAYMENT_DAY", payload: e.target.value });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const handleOpenEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: true,
      },
    });
  };

  const handleCloseEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: false,
      },
    });
    dispatch({ type: "RESET_FORM", payload: "email" });
  };

  const handleOpenEmailEditPasswordModal = (email) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "id",
        value: email.id,
        form: "email",
      },
    });
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailEditPasswordModal",
        value: true,
      },
    });
  };

  const handleCloseEmailEditPasswordModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailEditPasswordModal",
        value: false,
      },
    });
    dispatch({ type: "RESET_FORM", payload: "email" });
  };

  const modifyTemplateTypes = (typeId, defaultTemplateId) => {
    const index = state.emailTemplateTypes.findIndex(
      (template) => template.id === typeId
    );
    const templates = state.emailTemplateTypes;
    templates[index].defaultTemplateId = defaultTemplateId;
    dispatch({
      type: "SET_EMAIL_TEMPLATES_TYPES",
      payload: templates,
    });
  };

  const modifySenderEmail = (typeId, senderEmailId) => {
    const templates = state.emailTemplateTypes;
    templates[typeId - 1].senderEmail = senderEmailId;
    dispatch({
      type: "SET_EMAIL_TEMPLATES_TYPES",
      payload: templates,
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const openEmailDeleteDialog = (email) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteEmailQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: email?.user,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteEmail(email.id);
          resetConfirmDialog();
        },
      },
    });
  };

  const openTemplateDeleteDialog = (template) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteTemplateQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: template.name,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteEmailTemplate(template.id);
          resetConfirmDialog();
        },
      },
    });
  };

  const openBankDeleteDialog = (account) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteBankQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: account.name,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteBankAccount(account.id);
          resetConfirmDialog();
        },
      },
    });
  };

  const openTransferDeleteDialog = (account) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteTransferQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: account.accountName,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteTransferAccount(account.id);
          resetConfirmDialog();
        },
      },
    });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ paddingTop: 3 }}>
        <Grid container>
          <Grid item container xs={12} justifyContent="center">
            <Grid item>
              <Typography variant="h4">{t("settings")}</Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={3}
            sm={2}
            lg={1}
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tabs
              orientation="vertical"
              value={state.tab}
              variant="scrollable"
              onChange={handleTabChange}
            >
              <Tab label={t("emails")} />
              <Tab label={t("bankAccounts")} />
              <Tab label={t("invoicing")} />
              <Tab label={t("transferAccounts")} />
              <Tab label={t("providerInvoices")} />
              <Tab label={t("contracts")} />
            </Tabs>
          </Grid>

          <Grid item xs={9} sm={10} lg={11}>
            <TabPanel value={state.tab} index={0}>
              <Grid>
                <Grid item container spacing={2}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      display={"flex"}
                      alignItems={"center"}
                      flexWrap={"wrap"}
                    >
                      <MailIcon />
                      {t("emailsAccounts")}
                    </Typography>
                  </Grid>
                  <Grid item flex={1} justifyContent="flex-end" display="flex">
                    <Button
                      startIcon={<AddIcon />}
                      onClick={handleOpenEmailModal}
                    >
                      {t("addEmail")}
                    </Button>
                  </Grid>
                </Grid>
                <EmailsList
                  emails={state.emails}
                  editEmailPassword={handleOpenEmailEditPasswordModal}
                  deleteEmail={openEmailDeleteDialog}
                />
                <Grid item sx={{ my: 2 }} xs={12}>
                  <Divider />
                </Grid>
                <Grid item>
                  <Typography
                    variant="h6"
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                  >
                    <ArticleIcon />
                    {t("emailTemplateTypes")}
                  </Typography>
                </Grid>
                <EmailTemplateList
                  emailTemplateTypes={state.emailTemplateTypes}
                  createTemplate={createEmailTemplate}
                  modifyTemplate={modifyEmailTemplate}
                  deleteTemplate={openTemplateDeleteDialog}
                  modifyTemplateTypes={modifyTemplateTypes}
                  modifySenderEmail={modifySenderEmail}
                  emailsAccounts={state.emails}
                />
              </Grid>
            </TabPanel>
            <TabPanel value={state.tab} index={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t("bankAccounts")}</Typography>
                </Grid>

                <Grid item>
                  <BankAccountForm
                    form={state.bankAccount}
                    onChange={(e) => {
                      handleInputChange(e, "bankAccount");
                    }}
                    submitForm={createBankAccount}
                  />
                </Grid>

                <Grid item xs={12}>
                  <BankAccountsList
                    bankAccounts={state.bankAccounts}
                    deleteBankAccount={openBankDeleteDialog}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={state.tab} index={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t("invoicing")}</Typography>
                </Grid>

                <Grid item xs={12} marginTop={1} marginBottom={2}>
                  <Button
                    variant="contained"
                    onClick={openInvoiceTemplateModal}
                  >
                    {t("editPdfTemplate")}
                  </Button>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h7">{t("issuers")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {user.hasAction("EDIT_ISSUERS") && (
                        <IconButton
                          style={{
                            position: "absolute",
                            top: "7px",
                            right: "50px",
                          }}
                          title={t("addIssuer")}
                          onClick={openAddIssuerModal}
                        >
                          <PersonAddIcon />
                        </IconButton>
                      )}
                      <IssuersList
                        issuers={state.issuers}
                        centers={state.centers}
                        handleChangeIssuersCenters={handleChangeIssuersCenters}
                        deleter={deleteIssuer}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h7">{t("invoiceSeries")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {user.hasAction("EDIT_INVOICE_SERIES") && (
                        <IconButton
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "45px",
                          }}
                          title={t("addIssuer")}
                          onClick={openAddInvoiceSeriesModal}
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                      <InvoiceSeriesList invoiceSeries={state.series} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>

                <Grid item xs={12} marginBottom={2} marginTop={2}>
                  <Divider />
                </Grid>

                <Grid item xs={12} marginBottom={2}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h7">{t("centerIssuers")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CenterIssuersTable
                        issuers={state.issuers}
                        centers={state.centers}
                        handleChangeCheckbox={handleChangeIssuersCenters}
                        setDefaultIssuer={setDefaultIssuer}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={state.tab} index={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t("transferAccounts")}</Typography>
                </Grid>
                <Grid item>
                  <TransferAccountForm
                    form={state.transferAccount}
                    onChange={(e) => {
                      handleInputChange(e, "transferAccount");
                    }}
                    submitForm={createTransferAccount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TransferAccountList
                    transferAccounts={state.transferAccounts}
                    deleteTransferAccount={openTransferDeleteDialog}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={state.tab} index={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t("providerInvoices")}</Typography>
                </Grid>
                <UsersList leftUsers={state.users} rightUsers={[]} />
              </Grid>
            </TabPanel>
            <TabPanel value={state.tab} index={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t("paymentDay")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CustomSelect
                    label={t("paymentDay")}
                    value={state.paymentDays}
                    name="paymentDay"
                    onChange={(e) => handlePaymentDay(e)}
                    options={Array.from({ length: 31 }, (_, i) => ({
                      value: i + 1,
                      label: i + 1,
                    }))}
                    multiple
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      </Paper>

      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        confirmText={state.confirmDialog.confirmText}
        cancelText={state.confirmDialog.cancelText}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>

      {/* Invoice pdf template modal */}
      <Dialog
        open={state.invoiceTemplateModal.isOpen}
        onClose={closeInvoiceTemplateModal}
        title={t(state.invoiceTemplateModal.title)}
        maxWidth="md"
        children={
          <HTMLEditor
            onChange={handleInvoiceTemplateChange}
            html={state.invoiceTemplateModal.html}
          />
        }
        actions={
          <Button variant="contained" onClick={saveInvoiceTemplate}>
            {t("save")}
          </Button>
        }
      />

      {/* Add issuer modal */}
      <Dialog
        open={state.addIssuerModal.isOpen}
        onClose={closeAddIssuerModal}
        title={t(state.addIssuerModal.title)}
        maxWidth="md"
        children={
          <Grid item container spacing={3} xs={12}>
            <Grid item xs={6}>
              <TextInput
                label={t("name")}
                value={state.issuer.name}
                name="name"
                onChange={(e) => {
                  handleInputChange(e, "issuer");
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label={t("issuerName")}
                value={state.issuer.issuerName}
                name="issuerName"
                onChange={(e) => {
                  handleInputChange(e, "issuer");
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextInput
                label={t("nif")}
                value={state.issuer.nif}
                name="nif"
                onChange={(e) => {
                  handleInputChange(e, "issuer");
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label={t("address")}
                value={state.issuer.address}
                name="address"
                onChange={(e) => {
                  handleInputChange(e, "issuer");
                }}
              />
            </Grid>
          </Grid>
        }
        actions={
          <Button
            style={{ marginLeft: "auto" }}
            variant="contained"
            onClick={createIssuer}
            disabled={
              !state.issuer.name ||
              !state.issuer.issuerName ||
              !state.issuer.address ||
              !state.issuer.nif
            }
          >
            {t("save")}
          </Button>
        }
      />

      {/* Add invoice serie modal */}
      <Dialog
        open={state.addInvoiceSeriesModal.isOpen}
        onClose={closeAddInvoiceSeriesModal}
        title={t(state.addInvoiceSeriesModal.title)}
        children={
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <TextInput
                label={t("name")}
                value={state.invoiceSerie.name}
                name="name"
                onChange={(e) => {
                  handleInputChange(e, "invoiceSerie");
                }}
              />
            </Grid>
          </Grid>
        }
        actions={
          <Button
            style={{ marginLeft: "auto" }}
            variant="contained"
            onClick={createInvoiceSerie}
            disabled={!state.invoiceSerie.name}
          >
            {t("save")}
          </Button>
        }
      />

      {/* Add email dialog :*/}
      <Dialog
        open={state.modals.emailModal}
        onClose={handleCloseEmailModal}
        title={t("addEmail")}
        children={
          <EmailSettingsForm
            form={state.email}
            onChange={(e) => {
              dispatch({
                type: "SET_EMAIL_ERROR",
                payload: { input: e.target.name, value: false },
              });
              handleInputChange(e, "email");
            }}
            submitForm={createEmail}
            inputError={state.emailInputError}
          />
        }
      />

      {/* Edit email password dialog :*/}
      <Dialog
        open={state.modals.emailEditPasswordModal}
        onClose={handleCloseEmailEditPasswordModal}
        title={t("changeEmailPassword")}
        children={
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={5}>
              <TextInput
                label={t("password")}
                value={state.email.password}
                name="password"
                onChange={(e) => {
                  handleInputChange(e, "email");
                }}
                type="password"
              />
            </Grid>
            <Grid item xs={5}>
              <TextInput
                label={t("repeatPassword")}
                value={state.email.repeat}
                name="repeat"
                onChange={(e) => {
                  handleInputChange(e, "email");
                }}
                type="password"
              />
            </Grid>
          </Grid>
        }
        actions={
          <Button
            variant="contained"
            onClick={editEmailPassword}
            disabled={!state.email.password || !state.email.repeat}
          >
            {t("save")}
          </Button>
        }
      />
    </Container>
  );
}
