import { Box, Chip, styled } from "@mui/material";
import PropTypes from "prop-types";
import ContractStateChip from "./ContractStateChip";
import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import { useTranslation } from "react-i18next";

const StyledChip = styled(Chip)({
  backgroundColor: "#4DB9E8",
  color: "white",
  marginLeft: "10px",
});

const ContractStateBox = ({ state, isFrozen, hasFreezePermission }) => {
  const [t] = useTranslation("contracts");

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ContractStateChip size="small" state={state} />
      {isFrozen && hasFreezePermission && (
        <StyledChip
          icon={<AcUnitRoundedIcon style={{ color: "white" }} />}
          size="small"
          label={t("frozen")}
        />
      )}
    </Box>
  );
};

ContractStateBox.propTypes = {
  state: PropTypes.number.isRequired,
  isFrozen: PropTypes.bool.isRequired,
  hasFreezePermission: PropTypes.bool.isRequired,
};

export default ContractStateBox;
