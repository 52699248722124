import { Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { localeFormat } from "../../../utils/format";

const ProviderInvoiceTotals = ({ invoices }) => {
  const [t] = useTranslation("providerInvoices");

  return (
    <Paper sx={{ padding: 2 }}>
      <Grid container spacing={1} justifyContent="space-around">
        <Grid item>
          <Typography>
            {t("numInvoices")} :
            <Typography
              marginLeft={1}
              variant="h5"
              fontWeight={600}
              component="span"
            >
              {invoices.length}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {t("base")} :
            <Typography
              marginLeft={1}
              variant="h5"
              fontWeight={600}
              component="span"
            >
              {localeFormat(
                invoices.reduce((total, cur) => (total += cur.amount), 0)
              )}
              €
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {t("total")} :
            <Typography
              marginLeft={1}
              variant="h5"
              fontWeight={600}
              component="span"
            >
              {localeFormat(
                invoices.reduce((total, cur) => (total += cur.total), 0)
              )}
              €
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {t("totalPayed")} :
            <Typography
              marginLeft={1}
              variant="h5"
              fontWeight={600}
              component="span"
              color="green"
            >
              {localeFormat(
                invoices
                  .filter((invoice) => invoice.paidAt !== null)
                  .reduce((total, cur) => (total += cur.total), 0)
              )}
              €
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            {t("totalPending")} :
            <Typography
              marginLeft={1}
              variant="h5"
              fontWeight={600}
              component="span"
              color="red"
            >
              {localeFormat(
                invoices
                  .filter((invoice) => invoice.paidAt === null)
                  .reduce((total, cur) => (total += cur.total), 0)
              )}
              €
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProviderInvoiceTotals;
