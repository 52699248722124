import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect } from "react";
import { useSnackbar } from "notistack";

import KeyIcon from "@mui/icons-material/Key";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HowToRegIcon from "@mui/icons-material/HowToReg";

import AppContext from "../../../context/AppContext";

export default function EmailsList({ emails, editEmailPassword, deleteEmail }) {
  const { enqueueSnackbar } = useSnackbar();
  const { api } = useContext(AppContext);
  const [t] = useTranslation("settings");

  const verifyEmail = (emailId) => {
    api
      .post("/emails/validate/" + emailId)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          response.data.result
            ? enqueueSnackbar(t("validEmail"), { variant: "success" })
            : enqueueSnackbar(t("noValidEmail"), { variant: "success" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <Box maxHeight="310px" item xs={12} overflow="auto">
      {emails?.map((email) => (
        <List key={email.id}>
          <Card variant="outlined">
            <CardContent>
              <Grid item container xs={12}>
                <Grid item>
                  <Typography variant="body" sx={{ fontWeight: "bold" }}>
                    {t("email") + ": "}
                  </Typography>
                  <Typography variant="body">{email?.user}</Typography>
                </Grid>
                <Grid
                  item
                  flex={1}
                  alignItems="flex-start"
                  justifyContent="flex-end"
                  display="flex"
                >
                  <Tooltip title={t("verifyEmail")} placement="top">
                    <IconButton
                      onClick={() => {
                        verifyEmail(email.id);
                      }}
                    >
                      <HowToRegIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("changePassword")} placement="top">
                    <IconButton onClick={() => editEmailPassword(email)}>
                      <KeyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("deleteEmail")} placement="top">
                    <IconButton
                      color="error"
                      onClick={() => deleteEmail(email)}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </List>
      ))}
    </Box>
  );
}
