import { Button, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import TextInput from "../../Inputs/TextInput";

export default function EmailSettingsForm({
  form,
  inputError,
  onChange,
  submitForm,
}) {
  const [t] = useTranslation("settings");

  return (
    <Grid container spacing={2}>
      <Grid item xs={8} sx={{ mt: 1 }}>
        <TextInput
          error={inputError.user}
          helperText={inputError.user ? t("user") + " " + t("isRequired") : ""}
          label={t("user")}
          name="user"
          value={form.user}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={4} sx={{ mt: 1.5 }}>
        <Typography variant="body">@necesitountrastero.es</Typography>
      </Grid>
      <Grid item xs={5}>
        <TextInput
          type="password"
          error={inputError.password}
          helperText={
            inputError.password ? t("password") + " " + t("isRequired") : ""
          }
          label={t("password")}
          name="password"
          value={form.password}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={5}>
        <TextInput
          type="password"
          error={inputError.repeat}
          helperText={inputError.repeat ? t("passwordsMustMatch") : ""}
          label={t("repeat")}
          name="repeat"
          value={form.repeat}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="success"
          disabled={!form.user || !form.password || !form.repeat}
          onClick={submitForm}
        >
          {t("add")}
        </Button>
      </Grid>
    </Grid>
  );
}
