export const generateURL = (url, params) => {
  let stringParams = [];

  Object.keys(params).forEach((name) => {
    let value = params[name];
    if (
      value !== "" &&
      (value?.constructor !== Array || value?.length > 0) &&
      value !== {}
    ) {
      stringParams.push(name + "=" + value);
    }
  });

  return url + "?" + stringParams.join("&");
};

export const getParams = (query, filters) => {
  let filtersObj = {};

  filters.forEach((filter) => {
    if (query.get(filter.name) !== null) {
      switch (filter.type) {
        case "array":
          let value = query.get(filter.name).split(",");
          value = value.map((item) => {
            if (Number.isNaN(Number(item))) {
              return item;
            } else {
              return Number(item);
            }
          });
          filtersObj[filter.name] = value;
          break;
        case "boolean":
          filtersObj[filter.name] = Boolean(query.get(filter.name) === "true");
          break;
        default:
          filtersObj[filter.name] = query.get(filter.name);
      }
    }
  });

  return filtersObj;
};
