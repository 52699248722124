import { Button, CardMedia, Grid, Link } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

export default function LandingPage() {
  const [t] = useTranslation("landing");
  //const classes = useStyles();

  return (
    <Grid container height="100vh">
      {/* Logo and icons */}
      <Grid container item xs={12} lg={6} spacing={3} justifyContent="center">
        {/* Logo */}
        <Grid item xs={12} display="flex" justifyContent="center" marginX={1}>
          <Box
            display="flex"
            alignItems="flex-end"
            height="100%"
            maxWidth={650}
          >
            <img src="/img/NUT_LOGO_1_black_low.png" width="100%" height="auto" />
          </Box>
        </Grid>

        {/* Icons */}
        <Grid item container xs={12} columnSpacing={4} justifyContent="center">
          <Grid item>
            <Link href="https://es-es.facebook.com/Necesitountrastero">
              <FacebookIcon color="black"></FacebookIcon>
            </Link>
          </Grid>
          <Grid item>
            <Link href="https://www.instagram.com/necesitountrastero/?hl=es">
              <InstagramIcon color="black"></InstagramIcon>
            </Link>
          </Grid>
          <Grid item>
            <Link href="https://twitter.com/nut_trasteros">
              <TwitterIcon color="black"></TwitterIcon>
            </Link>
          </Grid>
          <Grid item>
            <Link href="https://www.tiktok.com/@necesitountrastero?lang=es">
              <MusicNoteIcon color="black"></MusicNoteIcon>
            </Link>
          </Grid>
          <Grid item>
            <Link href="https://necesitountrastero.es/">
              <LanguageIcon color="black"></LanguageIcon>
            </Link>
          </Grid>
          <Grid item>
            <Link href="https://www.linkedin.com/company/necesito-un-trastero/">
              <LinkedInIcon color="black"></LinkedInIcon>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      {/* Login and Register buttons */}
      <Grid
        container
        item
        spacing={3}
        xs={12}
        lg={6}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/login"
          >
            {t("login")}
          </Button>
        </Grid>
        {/*<Grid item>
          <Button variant="outlined" component={RouterLink} to="/register">
            {t("register")}
          </Button>
  </Grid>*/}
      </Grid>
    </Grid>
  );
}
