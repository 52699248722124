import { Button, Container, Grid, Typography, Paper } from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import AppContext from "../../../context/AppContext";
import TextInput from "../../Inputs/TextInput";
import CityInput from "../../Inputs/CityInput";
import CustomSelect from "../../Inputs/CustomSelect";

const initialState = {
  form: {
    name: "",
    city: null,
    address: "",
    source: "",
    priority: "underExclusivity",
  },
  inputError: {
    name: false,
    address: false,
    priority: false,
    grossInternalArea: false,
    lettableArea: false,
    acquisitionPrice: false,
    capexCosts: false,
  },
  sources: [],
  id: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_SOURCES":
      return { ...state, sources: action.payload };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    default:
      throw new Error("Action not found in reducer.");
  }
}

export default function CreateAcquisitionAssetPage() {
  const { api, user } = useContext(AppContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("acquisitions");

  useEffect(() => {
    document.title = t("createAcquisitionAssetPage");
  }, []);

  const submitForm = () => {
    if (validateForm()) {
      let data = {};

      data.name = state.form.name;
      data.address = state.form.address;
      data.cityId = state.form.city?.id;
      data.source = state.form.source;
      data.priority = state.form.priority;
      data.createdBy = user.id;

      api
        .post("/acquisitions/create", data)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("acquisitionAssetCreateSuccess"), {
              variant: "success",
            });
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let fields = ["name", "address"];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(field + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    return isValid;
  };

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper style={{ padding: "1rem" }}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="h4">{t("createNewAsset")}</Typography>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={3}>
              <TextInput
                label={t("name")}
                error={state.inputError.name}
                helperText={
                  state.inputError.name
                    ? t("name") + " " + t("mustNotBeBlank")
                    : ""
                }
                variant="outlined"
                name="name"
                value={state.form.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                label={t("address")}
                error={state.inputError.address}
                helperText={
                  state.inputError.address
                    ? t("address") + " " + t("mustNotBeBlank")
                    : ""
                }
                variant="outlined"
                name="address"
                value={state.form.address}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                label={t("source")}
                name="source"
                value={state.form.source}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <CityInput
                value={state.form.city}
                name="city"
                onChange={handleInputChange}
                label={t("city")}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomSelect
                value={state.form.priority}
                name="priority"
                onChange={handleInputChange}
                label={t("priority")}
                options={[
                  "underExclusivity",
                  "active",
                  "onHold",
                  "declined",
                  "closed",
                ].map((item) => {
                  return { value: item, label: t(item) };
                })}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("cancel")}</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={submitForm}>
                {t("create")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
