import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AppContext from "../../context/AppContext";
//import { Button } from "@mui/material";
import Button from "./CustomButton";
import { Link } from "react-router-dom";
import React, { useContext } from "react";

export default function AddButton({ action, label, link }) {
  const { user } = useContext(AppContext);

  const hasAction = (action) => {
    return user?.Role.Actions.some((item) => item.id === action) || false;
  };

  return (
    hasAction(action) && (
      <Button
        component={Link}
        to={"/app" + link}
        color="success"
        startIcon={<AddCircleOutlineOutlinedIcon />}
      >
        {label}
      </Button>
    )
  );
}
