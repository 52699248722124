import {
  Button,
  ButtonGroup,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import AppContext from "../../../context/AppContext";
import ButtonCSV from "../../Inputs/ButtonCSV";
import CenterSelect from "../../Inputs/CenterSelect";
import CreateButton from "../../Inputs/CreateButton";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import { CustomTable } from "../../CustomTable";
import { getParams, generateURL } from "../../../utils/url";
import TextInput from "../../Inputs/TextInput";
import SearchButton from "../../Inputs/SearchButton";
import { lastMonthDate, today } from "../../../utils/date";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  calls: [],
  centers: [],
  departments: [],
  filters: {
    autoSearch: "false",
    centerId: "",
    dateFrom: lastMonthDate(),
    dateUntil: today(),
    departmentId: [],
    followUp: "",
    text: "",
    orderBy: "",
    order: "asc",
  },
  options: {
    rowlink: "call",
    loaded: true,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CALLS":
      return { ...state, calls: action.payload };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_DEPS":
      return { ...state, departments: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    default:
      throw new Error();
  }
}

export default function CallsPages() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();
  const history = useHistory();
  const [t] = useTranslation("calls");

  const filters = [
    { name: "centerId", type: "number" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "departmentId", type: "array" },
    { name: "followUp", type: "number" },
    { name: "text", type: "string" },
    { name: "orderBy", type: "string" },
    { name: "order", type: "string" },
    { name: "autoSearch", type: "string" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, filters) },
  });
  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const search = () => {
    if (state.filters.autoSearch === "true") getCalls();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  useEffect(() => {
    if (state.filters.autoSearch === "true") getCalls();
  }, [state.filters.autoSearch]);

  const columns = [
    {
      key: "centerName",
      label: t("center"),
      sortType: "string",
    },
    {
      key: "customerName",
      label: t("name"),
      sortType: "string",
    },
    {
      key: "phoneNumber",
      label: t("phoneNumber"),
      sortType: "string",
    },
    {
      key: "customerEmail",
      label: t("email"),
      sortType: "string",
    },
    {
      key: "callDepartmentName",
      label: t("department"),
      sortType: "string",
    },
    {
      key: "date",
      label: t("date"),
      sortType: "string",
    },
    {
      key: "textCallBack",
      label: t("wantCallBack"),
      sortType: "string",
    },
    {
      key: "comments",
      label: t("comments"),
      sortType: "string",
    },
    {
      key: "centerComments",
      label: t("centerComments"),
      sortType: "string",
    },
    {
      key: "createdByUser",
      label: t("createdBy"),
      sortType: "string",
    },
  ];

  const csvHeaders = [
    { key: "Center?.name", label: t("center") },
    { key: "customerName", label: t("name") },
    { key: "phoneNumber", label: t("phoneNumber") },
    { key: "customerEmail", label: t("email") },
    { key: "CallDepartment?.name", label: t("department") },
    { key: "date", label: t("date") },
    { key: "textCallBack", label: t("wantCallBack") },
    { key: "comments", label: t("comments") },
    { key: "centerComments", label: t("centerComments") },
    { key: "User.name", label: t("createdBy") },
  ];

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const getCalls = () => {
    //Change url parameters:
    let url = generateURL("/app/calls", state.filters);
    history.push(url);

    dispatch({ type: "SET_LOADED_FALSE" });
    dispatch({
      type: "SET_CALLS",
      payload: [],
    });
    let params = {
      include: ["Center", "User"],
      text: state.filters.text,
      centerId: state.filters.centerId,
      dateFrom: state.filters.dateFrom,
      dateUntil: state.filters.dateUntil,
      departmentId: state.filters.departmentId,
      followUp: state.filters.followUp,
    };

    api
      .get("/calls", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        response.data.error &&
          enqueueSnackbar(response.data.error, { variant: "error" });
        if (response.data.length > 0) {
          //These parameters are copied into response.data to allow for correct sorting on page refresh
          response.data.forEach((call) => {
            call.centerName = call.Center ? call.Center.name : "";
            call.callDepartmentName = call.CallDepartment
              ? call.CallDepartment.name
              : "";
            call.createdByUser = call.User ? call.User.name : "";
          });
          dispatch({
            type: "SET_CALLS",
            payload: response.data,
          });
        } else {
          enqueueSnackbar(t("noCalls"), { variant: "info" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("callsPage");
    getCenters();
    getCallDepartments();
  }, []);

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        response.data.error &&
          enqueueSnackbar(response.data.error, {
            variant: "error",
          });
        if (response.data.length > 0) {
          dispatch({
            type: "SET_CENTERS",
            payload: response.data,
          });
        } else {
          enqueueSnackbar(t("noCenters"), { variant: "error" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCallDepartments = () => {
    api
      .get("/calls/departments")
      .then((response) => {
        response.data.error &&
          enqueueSnackbar(response.data.error, {
            variant: "error",
          });
        if (response.data.length > 0) {
          dispatch({
            type: "SET_DEPS",
            payload: response.data,
          });
        } else {
          enqueueSnackbar(t("noDeps"), { variant: "error" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_CALLS", payload: [] });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Grid container rowSpacing={3}>
        <Grid item>
          <Typography variant="h4">{t("calls")}</Typography>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item>
            <TextInput
              onChange={handleChangeFilter}
              name="text"
              value={state.filters.text}
              label={t("search")}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
            />
          </Grid>

          <Grid item>
            <CustomDate
              label={t("dateFrom")}
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateFrom}
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              name="dateFrom"
            />
          </Grid>

          <Grid item>
            <CustomDate
              label={t("dateUntil")}
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateUntil}
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              name="dateUntil"
            />
          </Grid>

          <Grid item>
            <CenterSelect
              name="centerId"
              onChange={handleChangeFilter}
              value={state.filters.centerId}
            />
          </Grid>

          <Grid item>
            <CustomSelect
              name="departmentId"
              multiple
              onChange={handleChangeFilter}
              label={t("department")}
              value={state.filters.departmentId}
              options={[
                ...state.departments.map((department) => ({
                  value: department.id,
                  label: department.name + " - " + department.email,
                })),
              ]}
            />
          </Grid>

          <Grid item>
            <CustomSelect
              name="followUp"
              value={state.filters.followUp}
              onChange={handleChangeFilter}
              label={t("wantCallBack")}
              options={[
                { value: "", label: t("all") },
                { value: 1, label: t("yes") },
                { value: 0, label: t("no") },
              ]}
            />
          </Grid>
          <Grid item>
            <ButtonGroup variant="contained">
              <Button onClick={resetFilters}>{t("reset")}</Button>
              <ButtonCSV
                data={state.calls}
                headers={csvHeaders}
                filename={t("calls")}
              />
              <SearchButton
                onClick={search}
                loading={!state.options.loaded}
              ></SearchButton>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <CreateButton
              action={"CREATE_CALLS"}
              label={t("createCall")}
              link={"/call/create"}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            columns={columns}
            data={state.calls.map((call) => {
              call.wantCallBack
                ? (call.textCallBack = t("yes"))
                : (call.textCallBack = t("no"));
              call.date = call.createdAt.substring(0, 10);
              return call;
            })}
            options={state.options}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/calls", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
