import {
  Button,
  Grid,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Card,
} from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { API_ROUTE } from "../../utils/API";
import axios from "axios";
import { decode as base64_decode } from "base-64";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import TextInput from "../Inputs/TextInput";
import Page from "../global/structure/Page";
import "../../index.js";

import theme from "../../theme/themeDark";

const usePasswordInput = () => {
  const [password, setPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  return {
    password,
    showPassword,
    setPass,
    setShowPassword,
  };
};

export default function ResetPasswordLinkPage() {
  //Change body color
  document.body.classList.add("dark");
  document.body.classList.remove("default");

  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("login");
  const [tErrors] = useTranslation("errors");

  const password = usePasswordInput();
  const repeatPassword = usePasswordInput();
  const [isValid, setIsValid] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  //Get params:
  const path = useLocation().pathname.split(";");
  const token = path[1];
  const email = base64_decode(path[0].split("/")[2]);

  useEffect(() => {
    submit();
  }, [email]);

  const submit = () => {
    const params = { code: token, email: email };
    axios
      .post(API_ROUTE + "/auth/forgot-password/verify-code", params)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          setIsValid(true);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  const sendNewPassword = () => {
    axios
      .post(API_ROUTE + "/auth/forgot-password/reset-password", {
        password: password.password,
        code: token,
        email: email,
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("passwordSuccessfullyChanged"), {
            variant: "success",
          });
          setIsChanged(true);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  const handlePasswordInputs = () => {
    return password.password &&
      repeatPassword.password &&
      password.password !== repeatPassword.password
      ? true
      : false;
  };

  const isValidInput = () => {
    if (
      !password.password ||
      !repeatPassword.password ||
      handlePasswordInputs()
    )
      return false;
    if (password.password.length < 8) return false;
    return true;
  };

  const close = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  return (
    <ThemeProvider theme={theme}>
      <Page>
        <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
          <Card sx={{ maxWidth: 345 }}>
            <Grid item>
              <Box
                alignItems="center"
                justifyContent="center"
                height="100%"
                sx={{
                  border: "solid 1px #E8CA4D",
                  padding: 5,
                  borderRadius: 2,
                }}
              >
                {!isValid ? (
                  <>
                    <Typography variant="h5">{t("accessDenied")}</Typography>
                    <Grid
                      item
                      flex={1}
                      justifyContent="flex-end"
                      display="flex"
                      sx={{
                        marginTop: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={close}
                      >
                        {t("finish")}
                      </Button>
                    </Grid>
                  </>
                ) : isChanged ? (
                  <>
                    <Typography variant="h5">
                      {t("changePasswordSuccess")}
                    </Typography>
                    <Grid
                      item
                      flex={1}
                      justifyContent="flex-end"
                      display="flex"
                      sx={{
                        marginTop: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={close}
                      >
                        {t("finish")}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Typography variant="h5">{t("resetPassword")}</Typography>
                    <TextInput
                      label={t("password")}
                      variant="outlined"
                      type={password.showPassword ? "text" : "password"}
                      value={password.password}
                      onChange={(e) => {
                        password.setPass(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                password.setShowPassword(
                                  !password.showPassword
                                );
                              }}
                              edge="end"
                            >
                              {password.showPassword ? (
                                <Visibility color="primary" />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        marginTop: 5,
                      }}
                    />
                    <TextInput
                      label={t("repeatPassword")}
                      type={repeatPassword.showPassword ? "text" : "password"}
                      value={repeatPassword.password}
                      onChange={(e) => {
                        repeatPassword.setPass(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(e) => {
                                repeatPassword.setShowPassword(
                                  !repeatPassword.showPassword
                                );
                              }}
                              edge="end"
                            >
                              {repeatPassword.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        color: "#000",
                      }}
                    />
                    <Grid
                      item
                      flex={1}
                      justifyContent="flex-end"
                      display="flex"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!isValidInput()}
                        onClick={sendNewPassword}
                      >
                        {t("restore")}
                      </Button>
                    </Grid>
                  </>
                )}
              </Box>
            </Grid>
          </Card>
        </Grid>
      </Page>
    </ThemeProvider>
  );
}
