import { Box } from "@mui/material";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useRef, useEffect, useReducer } from "react";

// Import Mapbox token
mapboxgl.accessToken =
  "pk.eyJ1IjoiZ25vbWJvIiwiYSI6ImNrNGszYXlxZjBjdGozanFpOGFpZno0bWEifQ.Gchc2G72ohn5xofBIq5agg";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_MAP":
      const marker = new mapboxgl.Marker()
        .setLngLat(action.payload.coords)
        .addTo(action.payload.map);
      return {
        ...state,
        map: { ...state.map, current: action.payload.map, markers: [marker] },
      };
    case "SET_MARKERS":
      return { ...state, map: { ...state.map, markers: action.payload } };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

export default function CenterMap(props) {
  const { lat, lng } = props;

  const initialState = {
    map: useRef(null),
    mapContainer: useRef(null),
    zoom: 9,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setMap();
  }, [lat, lng]);

  const setMap = () => {
    if (lng !== "" && lat !== "") {
      if (!state.map.current) {
        // initialize map only once

        dispatch({
          type: "SET_MAP",
          payload: {
            map: new mapboxgl.Map({
              container: state.mapContainer.current,
              style: "mapbox://styles/mapbox/streets-v11",
              center: [lng, lat],
              zoom: state.zoom,
            }),
            coords: [lng, lat],
          },
        });
      } else {
        removeAllMarks();

        setMapCenter(lng, lat);
        addMark();
      }
    }
  };

  const addMark = () => {
    let mark = new mapboxgl.Marker()
      .setLngLat([lng, lat])
      .addTo(state.map.current);

    let markers = state.map.markers;
    markers.push(mark);
    dispatch({ type: "SET_MARKERS", payload: markers });
  };

  const removeAllMarks = () => {
    if (state.map.markers != null) {
      for (let currentMarker of state.map.markers) {
        currentMarker.remove();
      }
    }
  };

  const setMapCenter = (lng, lat) => {
    state.map.current.setCenter([lng, lat]);
  };

  return (
    <Box width="100%" height={400} {...props} ref={state.mapContainer}></Box>
  );
}
