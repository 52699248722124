import { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";

// Material UI
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// Components & Utils
import ButtonLink from "../../Inputs/ButtonLink";
import AppContext from "../../../context/AppContext";
import { localeFormat } from "../../../utils/format";
import { useSnackbar } from "notistack";
import { CUSTOMER_ROLE_ID } from "../../../data/constants";

const initialState = {
  deleteButtonDisabled: false,
  invoiceItems: [],
  loaded: true,
  moveButtonDisabled: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INVOICE_ITEMS":
      return { ...state, invoiceItems: action.payload };
    case "SET_MOVE_BUTTON_DISABLED_TRUE":
      return {
        ...state,
        moveButtonDisabled: true,
      };
    case "SET_MOVE_BUTTON_DISABLED_FALSE":
      return {
        ...state,
        moveButtonDisabled: false,
      };
    case "SET_LOADED":
      return {
        ...state,
        loaded: action.payload,
      };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

export default function InvoiceItems({
  addMerchantable,
  deleteMerchantable,
  invoiceItems = [],
  showAddButton = true,
  showDeleteButton = true,
  editable = true,
}) {
  const { api, user } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("invoices");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (invoiceItems.length === 0)
      dispatch({ type: "SET_INVOICE_ITEMS", payload: [] });
    //For edit page:
    else if (invoiceItems[0]?.invoiceId) getInvoiceItems();
    //For create page:
    else if (invoiceItems.length != 0)
      dispatch({ type: "SET_INVOICE_ITEMS", payload: invoiceItems });
  }, [invoiceItems]);

  const handleOrderInvoiceItems = (items) => {
    let orderedInvoiceItems = items.sort((a, b) => a.orderIndex - b.orderIndex);
    dispatch({ type: "SET_INVOICE_ITEMS", payload: orderedInvoiceItems });
  };

  const getInvoiceItems = () => {
    dispatch({ type: "SET_LOADED", payload: false });
    if (invoiceItems.length > 0) {
      const invoiceId = invoiceItems[0].invoiceId;
      api
        .get("/invoices/" + invoiceId)
        .then((response) => {
          dispatch({ type: "SET_LOADED", payload: true });
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            handleOrderInvoiceItems(response.data.items);
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else {
      dispatch({ type: "SET_INVOICE_ITEMS", payload: [] });
    }
  };

  const handleMoveItem = (item, position, movement) => {
    dispatch({ type: "SET_MOVE_BUTTON_DISABLED_TRUE" });
    if (invoiceItems[0]?.invoiceId)
      //For edit page:
      api
        .post("/invoices/edit-order-item", {
          itemId: item.id,
          newPosition: position + movement,
        })
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({
              type: "SET_INVOICE_ITEMS",
              payload: response.data.result,
            });
            dispatch({ type: "SET_MOVE_BUTTON_DISABLED_FALSE" });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    else {
      //For create page:
      let items = state.invoiceItems;
      items.splice(position, 1);
      items.splice(position + movement, 0, item);
      items.forEach((item, index) => {
        item.orderIndex = index + 1;
      });
      dispatch({ type: "SET_INVOICE_ITEMS", payload: items });
      dispatch({ type: "SET_MOVE_BUTTON_DISABLED_FALSE" });
    }
  };

  return state.loaded ? (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{t("concept")}</TableCell>
            <TableCell align="left">{t("units")}</TableCell>
            <TableCell align="left">{t("pricePerUnit")}</TableCell>
            <TableCell align="left">{t("baseImport")}</TableCell>
            <TableCell align="left">{t("tax")}</TableCell>
            {editable && <TableCell align="left">{t("actions")}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {state.invoiceItems &&
            state.invoiceItems.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {user.hasPage("MERCHANTABLES") ? (
                    <ButtonLink to={"/app/merchantable/" + row.merchantableId}>
                      {row.concept}
                    </ButtonLink>
                  ) : (
                    row.concept
                  )}
                </TableCell>
                <TableCell align="left">{row.units}</TableCell>
                <TableCell align="left">
                  {localeFormat(row.pricePerUnit) + "€"}
                </TableCell>
                <TableCell align="left">
                  {localeFormat(row.base) + "€"}
                </TableCell>
                <TableCell align="left">{row.taxPercentage + "%"}</TableCell>
                {editable && (
                  <TableCell>
                    <IconButton
                      disabled={index === 0 || state.moveButtonDisabled}
                      onClick={() => handleMoveItem(row, index, -1)}
                    >
                      <ArrowUpwardIcon/>
                    </IconButton>
                    <IconButton
                      disabled={
                        index === state.invoiceItems.length - 1 ||
                        state.moveButtonDisabled
                      }
                      onClick={() => handleMoveItem(row, index, 1)}
                    >
                      <ArrowDownwardIcon/>
                    </IconButton>
                    {showDeleteButton && (
                      <IconButton
                        disabled={state.deleteButtonDisabled}
                        onClick={() => {
                          deleteMerchantable(row);
                        }}
                        color="error"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}

          {showAddButton && (
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => {
                    addMerchantable();
                  }}
                >
                  {t("addMerchantable")}
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={300}
    >
      <CircularProgress />
    </Box>
  );
}
