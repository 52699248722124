import React, { useContext, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { readString } from "react-papaparse";

// Material UI
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import UploadFileIcon from "@mui/icons-material/UploadFile";

// Components & utils
import { CustomTable } from "../../CustomTable";
import { getParams, generateURL } from "../../../utils/url";
import { localeFormat } from "../../../utils/format";
import AppContext from "../../../context/AppContext";
import BoxClusterChip from "../../BoxClusterChip";
import BoxesSelectedTotals from "./BoxesSelectedTotals";
import BoxStateChip from "../../BoxStateChip";
import ButtonCSV from "../../Inputs/ButtonCSV";
import ButtonLink from "../../Inputs/ButtonLink";
import CenterSelect from "../../Inputs/CenterSelect";
import ClusterSelect from "../../Inputs/ClusterSelect";
import ConfirmDialog from "../../ConfirmDialog";
import CreateButton from "../../Inputs/CreateButton";
import CustomButton from "../../Inputs/CustomButton";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import ItemsSummary from "../../ItemsSummary";
import Page from "../../global/structure/Page";
import Filters from "../../global/structure/Filters";
import BoxStateFilter from "../../Inputs/BoxStateFilter";

// Constants
import {
  BLOCKED_BOX_STATE_ID,
  FREE_BOX_STATE_ID,
  GENERAL_VAT_ES,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,
  BOOKED_BOX_STATE_ID,
} from "../../../data/constants";
import { round } from "../../../utils/math";
import Select from "../../global/inputs/Select";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  boxes: [],
  confirmDialog: {
    title: "",
    isOpen: false,
    childrenText: "",
    callback: () => {},
  },
  fileModal: {
    boxes: [],
    centerId: "",
    columns: [],
    data: [],
    editRowsModel: {},
    file: "",
    isOpen: false,
    selectedIds: [],
  },
  filters: {
    autoSearch: false,
    centers: [],
    clusters: [],
    name: "",
    order: "",
    orderBy: "",
    state: "",
    currentPage: 0,
    rowsPerPage: 10,
  },
  options: {
    rowlink: "box",
    loaded: true,
  },
  pricesModal: {
    boxes: [],
    centers: [],
    columns: [],
    hasIva: false,
    isOpen: false,
    loaded: true,
    maxMeters: "",
    minMeters: "",
    newPrice: "",
    newPricePercentage: "",
    rows: [],
    selectedIds: [],
    boxStateId: "",
    saveButtonDisabled: true,
    usePercentage: false,
  },
  loading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "SET_PAGINATION":
      return {
        ...state,
        filters: {
          ...state.filters,
          currentPage: action.payload.currentPage,
          rowsPerPage: action.payload.rowsPerPage,
        },
      };

    // Upload boxes file modal
    case "OPEN_FILE_MODAL":
      return { ...state, fileModal: { ...state.fileModal, isOpen: true } };
    case "CLOSE_FILE_MODAL":
      return { ...state, fileModal: initialState.fileModal };
    case "SET_FILE_MODAL_INPUT":
      return {
        ...state,
        fileModal: {
          ...state.fileModal,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_FILE_MODAL_FILE":
      return {
        ...state,
        fileModal: { ...state.fileModal, file: action.payload },
      };
    case "SET_FILE_MODAL_COLUMNS":
      return {
        ...state,
        fileModal: { ...state.fileModal, columns: action.payload },
      };
    case "SET_FILE_MODAL_DATA":
      return {
        ...state,
        fileModal: { ...state.fileModal, data: action.payload },
      };
    case "SET_FILE_MODAL_BOXES":
      return {
        ...state,
        fileModal: { ...state.fileModal, boxes: action.payload },
      };
    case "RESET_FILE_MODAL":
      return { ...state, fileModal: initialState.fileModal };

    // Modify prices modal
    case "SET_PRICES_MODAL_BOXES":
      return {
        ...state,
        pricesModal: { ...state.pricesModal, boxes: action.payload },
      };
    case "SET_PRICES_MODAL_LOADED_TRUE":
      return { ...state, pricesModal: { ...state.pricesModal, loaded: true } };
    case "SET_PRICES_MODAL_LOADED_FALSE":
      return { ...state, pricesModal: { ...state.pricesModal, loaded: false } };
    case "OPEN_PRICES_MODAL":
      return { ...state, pricesModal: { ...state.pricesModal, isOpen: true } };
    case "CLOSE_PRICES_MODAL":
      return { ...state, pricesModal: { ...state.pricesModal, isOpen: false } };
    case "SET_PRICES_MODAL_ROWS":
      return {
        ...state,
        pricesModal: { ...state.pricesModal, rows: action.payload },
      };
    case "SET_PRICES_MODAL_COLUMNS":
      return {
        ...state,
        pricesModal: { ...state.pricesModal, columns: action.payload },
      };
    case "SET_PRICES_MODAL_INPUT":
      return {
        ...state,
        pricesModal: {
          ...state.pricesModal,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_PRICES_MODAL_SELECTED":
      return {
        ...state,
        pricesModal: { ...state.pricesModal, selectedIds: action.payload },
      };
    case "ENABLE_PRICES_MODAL_SAVE":
      return {
        ...state,
        pricesModal: { ...state.pricesModal, saveButtonDisabled: false },
      };
    case "DISABLE_PRICES_MODAL_SAVE":
      return {
        ...state,
        pricesModal: { ...state.pricesModal, saveButtonDisabled: true },
      };
    case "RESET_PRICES_MODAL_HAS_IVA":
      return {
        ...state,
        pricesModal: {
          ...state.pricesModal,
          hasIva: initialState.pricesModal.hasIva,
        },
      };
    case "RESET_PRICES_MODAL_PRICE_TEXT":
      return {
        ...state,
        pricesModal: {
          ...state.pricesModal,
          newPrice: initialState.pricesModal.newPrice,
          newPricePercentage: initialState.pricesModal.newPricePercentage,
        },
      };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "RESET_PRICES_MODAL":
      return { ...state, pricesModal: initialState.pricesModal };
    case "RESET_PRICES_MODAL_FILTERS":
      return {
        ...state,
        pricesModal: {
          ...state.pricesModal,
          boxes: initialState.pricesModal.boxes,
          centers: initialState.pricesModal.centers,
          hasIva: initialState.pricesModal.hasIva,
          loaded: initialState.pricesModal.loaded,
          maxMeters: initialState.pricesModal.maxMeters,
          minMeters: initialState.pricesModal.minMeters,
          newPrice: initialState.pricesModal.newPrice,
          newPricePercentage: initialState.pricesModal.newPricePercentage,
          rows: initialState.pricesModal.rows,
          selectedIds: initialState.pricesModal.selectedIds,
          state: initialState.pricesModal.boxStateId,
          usePercentage: initialState.pricesModal.usePercentage,
        },
      };

    //Confirm Dialog
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          isOpen: action.payload.isOpen,
          childrenText: action.payload.childrenText,
          callback: action.payload.callback,
        },
      };
    case "SET_LOADING":
      return { ...state, loading: !state.loading };
    default:
      throw new Error("Action not found in reducer.");
  }
}

export default function BoxesPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("boxes");
  const [tErrors] = useTranslation("errors");

  const BOXES_COLUMNS = [
    {
      key: "centerName",
      label: t("center"),
      sortType: "string",
      renderFunction: (value, item) =>
        hasAction("VIEW_CENTERS") ? (
          <ButtonLink
            to={"/app/center/" + item.centerId}
            size="small"
            sx={{ padding: 0 }}
          >
            {value}
          </ButtonLink>
        ) : (
          value
        ),
    },
    { key: "name", label: t("name"), sortType: "string" },
    {
      key: "meters",
      label: t("meters"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "㎡",
    },
    {
      key: "cluster.price",
      label: t("clusterPriceWithoutVAT"),
      sortType: "number",
      renderFunction: (value) => (value ? localeFormat(value) + "€" : "-"),
    },
    {
      key: "clusterWithVAT",
      label: t("clusterPriceWithVAT"),
      sortType: "number",
      renderFunction: (value) => (value ? localeFormat(value) + "€" : "-"),
    },
    {
      key: "discount",
      label: t("allowedDiscount"),
      sortType: "number",
      renderFunction: (value) => (value ? value + "%" : "-"),
    },
    {
      key: "price",
      label: t("minPrice"),
      sortType: "number",
      color: "green",
      renderFunction: (value) => (
        <Typography color="green" variant="body2">
          {localeFormat(value) + "€"}
        </Typography>
      ),
    },
    {
      key: "priceWithVAT",
      label: t("minPriceWithVAT"),
      sortType: "number",
      renderFunction: (value) => (
        <Typography color="green" variant="body2">
          {localeFormat(value) + "€"}
        </Typography>
      ),
    },
    {
      key: "royalty",
      label: t("royalty"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "%",
    },
    {
      key: "cluster.name",
      label: t("type"),
      sortType: "string",
      renderFunction: (value) => {
        if (!value) return "-";
        return <BoxClusterChip name={String(value)} />;
      },
    },
    {
      key: "state",
      label: t("state"),
      sortType: "number",
      renderFunction: (value, item) => (
        <BoxStateChip
          state={value}
          endDate={value === 1 ? getCurrentBoxContract(item)?.endDate : null}
        />
      ),
    },
    {
      key: "comments",
      label: t("comments"),
      sortType: "string",
    },
  ];

  const CSV_HEADERS = [
    { label: t("center"), key: "Center.name" },
    { label: t("name"), key: "name" },
    { label: t("meters"), key: "meters" },
    { label: t("clusterPriceWithoutVAT"), key: "cluster.price" },
    { label: t("clusterPriceWithVAT"), key: "clusterWithVAT" },
    { label: t("allowedDiscount"), key: "discount" },
    { label: t("minPrice"), key: "price" },
    { label: t("minPriceWithVAT"), key: "priceWithVAT" },
    { label: t("royalty"), key: "royalty" },
    { label: t("type"), key: "cluster.name" },
    { label: t("state"), key: "stateText" },
    { label: t("comments"), key: "comments" },
  ];

  const FILTERS = [
    { name: "autoSearch", type: "boolean" },
    { name: "centers", type: "array" },
    { name: "clusters", type: "array" },
    { name: "currentPage", type: "number" },
    { name: "name", type: "string" },
    { name: "order", type: "number" },
    { name: "orderBy", type: "number" },
    { name: "rowsPerPage", type: "number" },
    { name: "state", type: "array" },
  ];

  //Initialize state and get parameters from URL
  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const hasAction = (action) => {
    let isFound = false;
    user?.Role.Actions.forEach((item) => {
      if (item.id === action) {
        isFound = true;
      }
    });

    return isFound;
  };

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  // Trasteros no disponibles
  // Trasteros bloqueados

  const BOXES_ITEMS_SUMMARY = [
    {
      translatedText: t("totalBoxes"),
      value: localeFormat(state.boxes?.length),
    },
    {
      translatedText: t("totalMeters"),
      value:
        localeFormat(
          state.boxes?.length > 0
            ? state.boxes.reduce((total, element) => total + element.meters, 0)
            : 0
        ) + " ㎡",
    },
    {
      translatedText: t("freeBoxes"),
      value: localeFormat(
        state.boxes?.length > 0
          ? state.boxes.reduce(
              (total, element) =>
                total + (element.state === FREE_BOX_STATE_ID ? 1 : 0),
              0
            )
          : 0
      ),
    },
    {
      translatedText: t("occupiedBoxes"),
      value: localeFormat(
        state.boxes?.length > 0
          ? state.boxes.reduce(
              (total, element) =>
                total + (element.state === OCCUPIED_BOX_STATE_ID ? 1 : 0),
              0
            )
          : 0
      ),
    },
    {
      translatedText: t("unavailableBoxes"),
      value: localeFormat(
        state.boxes?.length > 0
          ? state.boxes.reduce(
              (total, element) =>
                total + (element.state === UNAVAILABLE_BOX_STATE_ID ? 1 : 0),
              0
            )
          : 0
      ),
    },
    {
      translatedText: t("blockedBoxes"),
      value: localeFormat(
        state.boxes?.length > 0
          ? state.boxes.reduce(
              (total, element) =>
                total + (element.state === BLOCKED_BOX_STATE_ID ? 1 : 0),
              0
            )
          : 0
      ),
    },
  ];

  const BOXES_IMPORT_SUMMARY = [
    {
      translatedText: t("totalBoxes"),
      value: localeFormat(state.fileModal.selectedIds?.length),
    },
    {
      translatedText: t("totalMeters"),
      value:
        localeFormat(
          state.fileModal.selectedIds?.length > 0
            ? state.fileModal.data.reduce(
                (total, element) =>
                  state.fileModal.selectedIds.indexOf(element.id) > -1
                    ? total + Number(element.meters)
                    : total + 0,
                0
              )
            : 0
        ) + " ㎡",
    },
    {
      translatedText: t("totalInvoiced"),
      value:
        localeFormat(
          state.fileModal.selectedIds?.length > 0
            ? state.fileModal.data.reduce(
                (total, element) =>
                  state.fileModal.selectedIds.indexOf(element.id) > -1
                    ? total +
                      Number(element.meters) * Number(element.pricePerMeter)
                    : total + 0,
                0
              )
            : 0
        ) + "€",
    },
  ];

  // Auto search useEffect
  useEffect(() => {
    if (state.filters.autoSearch) getBoxes();
  }, [state.filters.autoSearch]);

  /* BACKEND CALLS */

  const getBoxes = () => {
    const url = generateURL("/app/boxes", state.filters);
    history.push(url);

    dispatch({ type: "SET_BOXES", payload: [] });

    let params = {
      include: ["Center", "Contract", "BoxCluster"],
    };

    if (state.filters.clusters?.length > 0)
      params.clusters = state.filters.clusters;
    if (state.filters.centers?.length > 0)
      params.centers = state.filters.centers;
    if (state.filters.name) params.name = state.filters.name;
    if (state.filters.state !== "") params.state = state.filters.state;

    dispatch({ type: "SET_LOADED_FALSE" });

    api
      .get("/boxes", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          response.data.forEach((box) => {
            box.centerName = box.Center?.name;
            box.stateText = getStateText(box.state);
            box.price = box.meters * box.pricePerMeter;
            box.priceWithVAT =
              Math.round(
                box.meters * box.pricePerMeter * (1 + 21 / 100) * 100
              ) / 100;
            box.clusterWithVAT =
              Math.round(box.cluster?.price * (1 + 21 / 100) * 100) / 100 ||
              null;
            box.discount =
              Math.round(
                (1 - (box.meters * box.pricePerMeter) / box.cluster?.price) *
                  100
              ) || null;
            if (!isFinite(box.discount)) box.discount = null;
          });
          dispatch({ type: "SET_BOXES", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_LOADED_TRUE" }));
  };

  // Import boxes
  const createBoxes = (boxesToCreate) => {
    const boxes = boxesToCreate.map((box) => {
      const { id, ...rest } = box;
      return { centerId: state.fileModal.centerId, ...rest };
    });

    api
      .post("/boxes/create-multiple", { boxes })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("boxCreateSuccess"), { variant: "success" });
          dispatch({ type: "RESET_FILE_MODAL" });
          getBoxes();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const validateBoxNames = (boxes) => {
    let boxNames = boxes.map((box) => box.name);

    if (state.fileModal.centerId !== "") {
      api
        .get("/centers/" + state.fileModal.centerId + "/boxes")
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            let isValid = true;
            boxNames.push(...response.data.map((box) => box.name));

            boxNames.forEach((boxName) => {
              let matches = boxNames.filter((name) => name === boxName);
              if (matches.length > 1) {
                enqueueSnackbar(
                  `${t("boxName")} ${matches[0]} ${t("isNotUnique")}`,
                  { variant: "warning" }
                );
                isValid = false;
              }
            });
            if (isValid) {
              createBoxes(boxes);
            }
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else {
      enqueueSnackbar(t("selectACenter"), { variant: "warning" });
    }
  };

  // Modify prices modal
  const getPricesModalBoxes = () => {
    dispatch({ type: "SET_PRICES_MODAL_LOADED_FALSE" });
    dispatch({
      type: "SET_PRICES_MODAL_ROWS",
      payload: initialState.pricesModal.rows,
    });

    let params = {
      include: ["Center", "Contract"],
    };

    if (state.pricesModal.centers.length > 0)
      params.centers = state.pricesModal.centers;
    if (state.pricesModal.boxStateId !== "")
      params.state = state.pricesModal.boxStateId;

    api
      .get("/boxes", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data?.length > 0) {
            response.data.forEach((box) => {
              box.centerName = box.Center?.name;
              box.stateText = getStateText(box.state);
            });
            dispatch({ type: "SET_PRICES_MODAL_ROWS", payload: response.data });
          } else {
            enqueueSnackbar(t("noBoxes"), { variant: "info" });
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_PRICES_MODAL_LOADED_TRUE" }));
  };

  const modifyBoxPrices = () => {
    const taxMultiplier = GENERAL_VAT_ES / 100 + 1;

    let pricePerMeter =
      state.pricesModal.hasIva === true
        ? (Number(state.pricesModal.newPrice) / taxMultiplier).toFixed(2)
        : state.pricesModal.newPrice;

    const boxes = state.pricesModal.rows
      .filter((box) => state.pricesModal.selectedIds.includes(box.id))
      .map((box) => {
        const multiplier =
          1 + Number(state.pricesModal.newPricePercentage) / 100;
        return {
          id: box.id,
          pricePerMeter: state.pricesModal.usePercentage
            ? round(box.pricePerMeter * multiplier)
            : round(pricePerMeter),
        };
      });

    dispatch({ type: "SET_LOADING" });
    api
      .post("/boxes/update-prices", { boxes })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("priceModificationSuccess"), {
            variant: "success",
          });
          resetPricesModal();
          getBoxes();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_LOADING" }));
  };

  /* HANDLERS */

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_BOXES", payload: [] });
  };

  const resetFiltersPricesModal = () => {
    dispatch({ type: "RESET_PRICES_MODAL_FILTERS" });
  };

  const search = () => {
    if (state.filters.autoSearch) getBoxes();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: true },
      });
  };

  const onPaginationCallback = (page, rowsPerPage) => {
    const url = generateURL("/app/boxes", {
      ...state.filters,
      currentPage: page,
      rowsPerPage: rowsPerPage,
    });
    history.push(url);
    dispatch({
      type: "SET_PAGINATION",
      payload: { currentPage: page, rowsPerPage: rowsPerPage },
    });
  };

  // Import boxes
  const closeFileModal = () => {
    dispatch({ type: "CLOSE_FILE_MODAL" });
  };

  const editFileModalRow = (id, field, value) => {
    let boxes = state.fileModal.boxes;

    boxes.forEach((box) => {
      if (box.id === id) {
        box[field] = value;
      }
    });

    dispatch({ type: "SET_FILE_MODAL_BOXES", payload: boxes });
  };

  const handleFileChange = (e) => {
    dispatch({ type: "SET_FILE_MODAL_FILE", payload: e.target.files[0] });
  };

  const handleFileModalInputChange = (e) => {
    dispatch({
      type: "SET_FILE_MODAL_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const openFileConfirm = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("createBoxes"),
        isOpen: true,
        childrenText: t("areYouSureYouWantToCreateBoxes"),
        callback: (confirmed) => {
          confirmed && validateTable();
          resetConfirmDialog();
        },
      },
    });
  };

  const openFileModal = () => {
    dispatch({ type: "OPEN_FILE_MODAL" });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const selectFileModalRows = (ids) => {
    dispatch({
      type: "SET_FILE_MODAL_INPUT",
      payload: {
        inputname: "selectedIds",
        value: ids,
      },
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  // Modify prices modal
  const closePricesModal = () => {
    dispatch({ type: "CLOSE_PRICES_MODAL" });
  };

  const confirmChangePrices = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("modifyPrices"),
        isOpen: true,
        childrenText: t("areYouSureYouWantToModifyPrices"),
        callback: (confirmed) => {
          confirmed && modifyBoxPrices();
          resetConfirmDialog();
        },
      },
    });
  };

  const getStateText = (state) => {
    switch (state) {
      case FREE_BOX_STATE_ID:
        return t("free");
      case OCCUPIED_BOX_STATE_ID:
        return t("occupied");
      case UNAVAILABLE_BOX_STATE_ID:
        return t("unavailable");
      case BLOCKED_BOX_STATE_ID:
        return t("blocked");
      case BOOKED_BOX_STATE_ID:
        return t("booked");
      default:
        return "-";
    }
  };

  const filterPricesModalBoxes = () =>
    state.pricesModal.rows.filter(
      (box) =>
        (state.pricesModal.centers.length === 0 ||
          state.pricesModal.centers
            .map((centerId) => Number(centerId))
            .includes(box.centerId)) &&
        (state.pricesModal.boxStateId === "" ||
          box.state === state.pricesModal.boxStateId) &&
        (state.pricesModal.minMeters === "" ||
          box.meters >= state.pricesModal.minMeters) &&
        (state.pricesModal.maxMeters === "" ||
          box.meters <= state.pricesModal.maxMeters)
    );

  const handlePricesModalInput = (e) => {
    dispatch({
      type: "SET_PRICES_MODAL_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });

    filterPricesModalBoxes();
  };

  const openPricesModal = () => {
    dispatch({ type: "RESET_PRICES_MODAL" });
    dispatch({ type: "OPEN_PRICES_MODAL" });

    const columns = [
      { field: "centerName", headerName: t("center"), flex: 1, minWidth: 75 },
      { field: "name", headerName: t("name"), flex: 1, minWidth: 75 },
      { field: "stateText", headerName: t("state"), flex: 1, minWidth: 75 },
      {
        field: "meters",
        headerName: t("meters"),
        valueFormatter: (params) => localeFormat(params.value) + "m²",
        flex: 1,
        minWidth: 50,
      },
      {
        field: "pricePerMeter",
        headerName: t("pricePerMeter"),
        valueFormatter: (params) => localeFormat(params.value) + "€",
        flex: 1,
        minWidth: 50,
      },
      {
        field: "newPricePerMeter",
        headerName: t("newPricePerMeter"),
        valueFormatter: (params) => {
          if (params.value == null) {
            return " ";
          }
          return localeFormat(params.value) + "€";
        },
        flex: 1,
        minWidth: 50,
      },
    ];
    dispatch({ type: "SET_PRICES_MODAL_COLUMNS", payload: columns });
  };

  const resetPricesModal = () => {
    dispatch({ type: "RESET_PRICES_MODAL" });
  };

  const selectPricesModalRows = (selectedIds) => {
    dispatch({ type: "SET_PRICES_MODAL_SELECTED", payload: selectedIds });
  };

  const setNewPricePerMeterItem = (pricePerMeter) => {
    const rows = state.pricesModal.rows.map((row) => {
      state.pricesModal.hasIva
        ? (row.newPricePerMeter = (pricePerMeter / 1.21).toFixed(2))
        : (row.newPricePerMeter = pricePerMeter);
      return row;
    });
    dispatch({
      type: "SET_PRICES_MODAL_ROWS",
      payload: rows,
    });
  };

  const setNewPricePerMeterIvaSwitch = (hasIva) => {
    const rows = state.pricesModal.rows.map((row) => {
      hasIva
        ? (row.newPricePerMeter = (state.pricesModal.newPrice / 1.21).toFixed(
            2
          ))
        : (row.newPricePerMeter = state.pricesModal.newPrice);
      return row;
    });
    dispatch({
      type: "SET_PRICES_MODAL_ROWS",
      payload: rows,
    });
  };

  const setNewPricePerMeterPercentageItem = (percentage) => {
    state.pricesModal.rows.map((row) => {
      row.newPricePerMeter = Number(
        (1 + percentage / 100) * row.pricePerMeter
      ).toFixed(2);
    });
  };

  /* VALIDATORS */

  const validateRow = (row) => {
    const { meters, pricePerMeter, name, royalty } = row;

    if (!meters) return boxValidationError("metersIsRequired");
    if (!pricePerMeter) return boxValidationError("pricePerMeterIsRequired");
    if (!name) return boxValidationError("nameIsRequired");
    if (typeof name !== "string")
      return boxValidationError("nameMustBeAString");
    if (isNaN(meters)) return boxValidationError("metersMustBeANumber");
    if (isNaN(pricePerMeter))
      return boxValidationError("pricePerMeterMustBeANumber");
    if (royalty && isNaN(royalty))
      return boxValidationError("royaltyMustBeANumber");

    row.meters = Number(meters);
    row.pricePerMeter = Number(pricePerMeter);
    if (royalty) row.royalty = Number(royalty);

    if (meters <= 0) return boxValidationError("metersMustBeGreaterThanZero");
    if (pricePerMeter <= 0)
      return boxValidationError("pricePerMeterMustBeGreaterThanZero");
    if (royalty < 0 || royalty > 100)
      return boxValidationError("royaltyMustBeBetweenZeroAndOneHundred");

    return true;
  };

  const validateTable = () => {
    let selectedRows = state.fileModal.selectedIds.map((id) => {
      return state.fileModal.boxes.filter((row) => row.id === id)[0];
    });

    if (
      selectedRows.length > 0 &&
      selectedRows.every((row) => validateRow(row, selectedRows))
    ) {
      validateBoxNames(selectedRows); // Check center for duplicate names, if none then create
    } else if (selectedRows.length === 0) {
      enqueueSnackbar(t("selectAtLeastOneRow"), { variant: "warning" });
    }
  };

  /* OTHERS */

  const getCurrentBoxContract = (box) => {
    if (!box || !box.Contracts || !box.Contracts.length) return null;
    for (let contract of box.Contracts) {
      if (!contract.endDate) return contract;
    }
    const today = new Date();
    for (let contract of box.Contracts) {
      if (
        new Date(contract.startDate) <= today &&
        new Date(contract.endDate) >= today
      )
        return contract;
    }
    return null;
  };

  const boxValidationError = (message) => {
    enqueueSnackbar(t(message), { variant: "error" });
    return false;
  };

  const processFile = () => {
    const file = state.fileModal.file;
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;
      processCsv(text);
    };

    reader.readAsText(file);
  };

  // Convert csv (with headers) into array of objects
  const processCsv = (str) => {
    const parsedStr = readString(str);

    //Handle parsing errors
    if (parsedStr.errors.length > 0) {
      const { code, row } = parsedStr.errors[0];
      let translatedCode;
      switch (code) {
        case "InvalidQuotes":
          translatedCode = `${t("invalidQuotes")}`;
          break;
        case "UndetectableDelimiter":
          translatedCode = `${t("undetectableDelimiter")}`;
          break;
        default:
          translatedCode = `${t("undefinedError")}`;
          break;
      }
      enqueueSnackbar(
        `${t("fileError")}: ${translatedCode} ${t(
          "onRow"
        ).toLowerCase()} ${row}`,
        {
          variant: "error",
        }
      );
      return;
    }

    const headerLength = parsedStr.data[0].length;
    let headers = ["name", "meters", "pricePerMeter"];
    if (headerLength === 4) headers.push("royalty");
    else if (headerLength < 3 || headerLength > 4) {
      enqueueSnackbar(t("wrongNumberOfColumnsInFile"), { variant: "warning" });
      return;
    }
    const rows = parsedStr.data.slice(1);

    const data = rows.map((row, i) => {
      const object = headers.reduce((obj, header, i) => {
        obj[header] = row[i];
        return obj;
      }, {});

      object.id = i + 1; // Id's are required for datagrid

      return object;
    });

    const columns = headers.map((header, index) => {
      let dataGridRow = {
        field: header,
        headerName: header,
        editable: true,
        flex: 1,
        minWidth: 75,
      };
      if (index === 2) {
        dataGridRow.valueFormatter = (params) => params.value + "€";
      } else if (index === 3) {
        dataGridRow.valueFormatter = (params) => params.value + "%";
      }
      return dataGridRow;
    });

    dispatch({ type: "SET_FILE_MODAL_DATA", payload: data });
    dispatch({ type: "SET_FILE_MODAL_BOXES", payload: data });

    dispatch({ type: "SET_FILE_MODAL_COLUMNS", payload: columns });
  };

  return (
    <Page browserTitle={t("boxesPage")} title={t("boxes")}>
      <Grid container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                id="nameFilter"
                label={t("search")}
                value={state.filters.name}
                name="name"
                onChange={handleFilterChange}
                onKeyPress={(event) => {
                  if (event.key === "Enter") search();
                }}
              />,
              <CenterSelect
                multiple
                name="centers"
                value={state.filters.centers}
                onChange={handleFilterChange}
              />,
              <ClusterSelect
                multiple={true}
                value={state.filters.clusters}
                name="clusters"
                onChange={handleFilterChange}
              />,
              <BoxStateFilter
                state={state.filters.state}
                setState={handleFilterChange}
              />,
            ]}
          />
        </Grid>

        <Grid item>
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={resetFilters}>{t("reset")}</Button>
            <ButtonCSV
              data={state.boxes}
              headers={CSV_HEADERS}
              filename={t("boxes")}
            />
            <SearchButton onClick={search} loading={!state.options.loaded} />
          </ButtonGroup>
        </Grid>

        <Grid item xs={12} sm="auto">
          <CreateButton
            action={"CREATE_BOXES"}
            label={t("createBox")}
            link={"/box/create"}
          />
        </Grid>
        {hasAction("CREATE_BOXES") && (
          <Grid item>
            <CustomButton
              onClick={openFileModal}
              color="success"
              startIcon={<UploadFileIcon />}
            >
              {t("importBoxes")}
            </CustomButton>
          </Grid>
        )}
        {hasAction("EDIT_BOXES") && (
          <Grid item>
            <CustomButton
              color="success"
              onClick={openPricesModal}
              startIcon={<PriceChangeIcon />}
            >
              {t("modifyBoxPrices")}
            </CustomButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <ItemsSummary gridItems={BOXES_ITEMS_SUMMARY} />
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            columns={BOXES_COLUMNS}
            currentPage={Number(state.filters.currentPage)}
            data={state.boxes}
            linesPerPage={Number(state.filters.rowsPerPage)}
            onPaginationCallback={onPaginationCallback}
            options={state.options}
            order={state.filters.order}
            orderBy={state.filters.orderBy}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/boxes", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>

      {/* Boxes file modal */}
      <Dialog
        onClose={closeFileModal}
        open={state.fileModal.isOpen}
        maxWidth="lg"
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{t("importBoxes")}</Grid>
            <Grid item>
              <IconButton onClick={closeFileModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={1}>
            <Grid item container spacing={1}>
              <Grid item>
                <Button variant="contained" component="label">
                  {t("selectFile")}
                  <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    accept=".csv"
                  />
                </Button>
              </Grid>
              <Grid item>
                <CenterSelect
                  value={state.fileModal.centerId}
                  onChange={handleFileModalInputChange}
                  name="centerId"
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={processFile}
                  disabled={
                    !state.fileModal.file || state.fileModal.centerId === ""
                  }
                >
                  {t("search")}
                </Button>
              </Grid>
            </Grid>

            <Grid item container alignItems="center">
              <Grid item>
                <Typography variant="body2">
                  {state.fileModal.file !== ""
                    ? state.fileModal?.file?.name
                    : t("noFileSelected")}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={t("importFileInfo")}>
                  <IconButton size="small">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ItemsSummary gridItems={BOXES_IMPORT_SUMMARY} />
            </Grid>
            <Grid item xs={12}>
              <Box style={{ height: 400, width: "100%" }}>
                <DataGrid
                  columns={
                    state.fileModal.columns.length > 0
                      ? state.fileModal.columns
                      : []
                  }
                  rows={
                    state.fileModal.data.length > 0 ? state.fileModal.data : []
                  }
                  checkboxSelection
                  selectionModel={state.fileModal.selectedIds}
                  onSelectionModelChange={(ids) => {
                    selectFileModalRows(ids);
                  }}
                  onCellEditCommit={(params) => {
                    editFileModalRow(params.id, params.field, params.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={openFileConfirm}
            disabled={
              state.fileModal.selectedIds.length === 0 ||
              state.fileModal.centerId === "" ||
              state.loading
            }
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modify prices modal */}
      <Dialog
        onClose={closePricesModal}
        open={state.pricesModal.isOpen}
        maxWidth="lg"
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{t("modifyPrices")}</Grid>
            <Grid item>
              <IconButton onClick={closePricesModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="h6">{t("filters")}</Typography>
              </Grid>
            </Grid>

            <Grid item container spacing={1.5}>
              <Grid item xs={12} sm="auto">
                <CenterSelect
                  multiple
                  name="centers"
                  value={state.pricesModal.centers}
                  onChange={handlePricesModalInput}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Select
                  label={t("state")}
                  name="boxStateId"
                  value={state.pricesModal.boxStateId}
                  onChange={handlePricesModalInput}
                  options={[
                    { value: "", label: t("all") },
                    { value: FREE_BOX_STATE_ID, label: t("free") },
                    { value: OCCUPIED_BOX_STATE_ID, label: t("occupied") },
                    {
                      value: UNAVAILABLE_BOX_STATE_ID,
                      label: t("unavailable"),
                    },
                    {
                      value: BLOCKED_BOX_STATE_ID,
                      label: t("blocked"),
                    },
                    {
                      value: BOOKED_BOX_STATE_ID,
                      label: t("booked"),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <TextInput
                  sx={{ width: 230 }}
                  label={t("minArea")}
                  type="number"
                  name="minMeters"
                  value={state.pricesModal.minMeters}
                  onChange={handlePricesModalInput}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <TextInput
                  sx={{ width: 230 }}
                  label={t("maxArea")}
                  type="number"
                  name="maxMeters"
                  value={state.pricesModal.maxMeters}
                  onChange={handlePricesModalInput}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="h6">{t("newPrice")}</Typography>
              </Grid>
            </Grid>

            <Grid item container spacing={1.5}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      name="usePercentage"
                      checked={state.pricesModal.usePercentage}
                      onChange={(e) => {
                        handlePricesModalInput({
                          target: {
                            name: e.target.name,
                            value: e.target.checked,
                          },
                        });
                        dispatch({ type: "RESET_PRICES_MODAL_HAS_IVA" });
                        dispatch({ type: "RESET_PRICES_MODAL_PRICE_TEXT" });
                        setNewPricePerMeterItem();
                      }}
                    />
                  }
                  label={t("percentage")}
                />
              </Grid>

              {!state.pricesModal.usePercentage ? (
                <Grid item xs={12} sm="auto">
                  <TextInput
                    name="newPrice"
                    value={state.pricesModal.newPrice}
                    label={t("newPrice")}
                    type="number"
                    onChange={(e) => {
                      handlePricesModalInput({
                        target: {
                          name: e.target.name,
                          value: e.target.value,
                        },
                      });
                      setNewPricePerMeterItem(e.target.value);
                      e.target.value.length !== 0
                        ? dispatch({ type: "ENABLE_PRICES_MODAL_SAVE" })
                        : dispatch({ type: "DISABLE_PRICES_MODAL_SAVE" });
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm="auto">
                  <TextInput
                    name="newPricePercentage"
                    value={state.pricesModal.newPricePercentage}
                    label={t("setPercentage")}
                    type="number"
                    onChange={(e) => {
                      handlePricesModalInput({
                        target: {
                          name: e.target.name,
                          value: e.target.value,
                        },
                      });
                      setNewPricePerMeterPercentageItem(e.target.value);
                      e.target.value.length !== 0
                        ? dispatch({ type: "ENABLE_PRICES_MODAL_SAVE" })
                        : dispatch({ type: "DISABLE_PRICES_MODAL_SAVE" });
                    }}
                  />
                </Grid>
              )}
              {!state.pricesModal.usePercentage && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        name="hasIva"
                        checked={state.pricesModal.hasIva}
                        onChange={(e) => {
                          handlePricesModalInput({
                            target: {
                              name: e.target.name,
                              value: e.target.checked,
                            },
                          });
                          setNewPricePerMeterIvaSwitch(e.target.checked);
                        }}
                      />
                    }
                    label={t("hasIva")}
                  />
                </Grid>
              )}

              <Grid item>
                <ButtonGroup variant="contained" color="primary">
                  <Button onClick={resetFiltersPricesModal}>
                    {t("reset")}
                  </Button>
                  <SearchButton
                    onClick={() => {
                      getPricesModalBoxes();
                      dispatch({ type: "RESET_PRICES_MODAL_PRICE_TEXT" });
                    }}
                    loading={!state.pricesModal.loaded}
                  >
                    {t("search")}
                  </SearchButton>
                </ButtonGroup>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <BoxesSelectedTotals
                selectedRows={state.pricesModal.rows.filter((row) =>
                  state.pricesModal.selectedIds.includes(row.id)
                )}
                newPrice={state.pricesModal.newPrice}
                newPricePercentage={state.pricesModal.newPricePercentage}
              />
            </Grid>

            <Grid item xs={12}>
              <Box style={{ height: 400, width: "100%" }}>
                <DataGrid
                  columns={state.pricesModal.columns}
                  rows={filterPricesModalBoxes()}
                  checkboxSelection
                  selectionModel={state.pricesModal.selectedIds}
                  onSelectionModelChange={(selectedIds) => {
                    selectPricesModalRows(selectedIds);
                  }}
                  loading={!state.pricesModal.loaded}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={confirmChangePrices}
            disabled={
              state.pricesModal.selectedIds.length === 0 ||
              state.pricesModal.saveButtonDisabled
            }
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Dialog */}
      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Page>
  );
}
