import { Chip } from "@mui/material";
//import { useTranslation } from "react-i18next";

const TrackerStatusChip = (props) => {
  const { status } = props;
  //const [t] = useTranslation("contracts");
  let text, color;
  
  switch (status) {
    case 0:
      text = "0%";
      color = "error";
      break;
    case 1:
      text = "50%";
      color = "primary";
      break;
    case 2:
      text = "100%";
      color = "success";
      break;
    default:
      text = "?";
      color = "default";
  }
  return <Chip color={color} label={text} {...props} />;
};

export default TrackerStatusChip;
