import { useRef, useState, useEffect } from "react";
import { Stage, Layer, Rect, Image } from "react-konva";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Html } from "react-konva-utils";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const FloorMap = () => {
  const [img, setImg] = useState(null);
  const [cameraImg, setCameraImg] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const videoRef = useRef(null);

  const imgHeight = 936;
  const imgWidth = 1324;

  // Box menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuX, setMenuX] = useState(null);
  const [menuY, setMenuY] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);

    setMenuX(event.currentTarget.attrs.x + event.currentTarget.attrs.width);
    setMenuY(
      event.currentTarget.attrs.y +
        event.currentTarget.attrs.height / 2 -
        imgHeight
    );
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
  const boxes = [
    // sección 1
    { x: 160, y: 185, width: 163, height: 63, color: true },
    { x: 325, y: 185, width: 163, height: 63, color: true },
    { x: 490, y: 185, width: 131, height: 63, color: true },
    { x: 623, y: 185, width: 131, height: 63, color: true },
    { x: 757, y: 185, width: 132, height: 63 },
    { x: 891, y: 185, width: 132, height: 63, color: true },
    // sección 2
    { x: 160, y: 250, width: 70, height: 36, color: true },
    { x: 160, y: 289, width: 70, height: 36, color: true },
    { x: 160, y: 328, width: 70, height: 36 },
    { x: 160, y: 367, width: 70, height: 36 },
    { x: 160, y: 406, width: 70, height: 36, color: true },
    { x: 160, y: 444, width: 70, height: 36, color: true },
    { x: 160, y: 483, width: 70, height: 36, color: true },
    // sección 3
    { x: 272, y: 290, width: 62, height: 55, color: true },
    { x: 272, y: 348, width: 62, height: 55, color: true },
    { x: 272, y: 406, width: 62, height: 55, color: true },
    { x: 272, y: 464, width: 62, height: 55 },
    { x: 337, y: 290, width: 62, height: 55, color: true },
    { x: 337, y: 348, width: 62, height: 55 },
    { x: 337, y: 406, width: 62, height: 55 },
    { x: 337, y: 464, width: 62, height: 55 },
    { x: 272, y: 522, width: 127, height: 55 },
    { x: 272, y: 580, width: 127, height: 55, color: true },
    // sección 4
    { x: 442, y: 290, width: 62, height: 67, color: true },
    { x: 442, y: 360, width: 62, height: 67, color: true },
    { x: 442, y: 429, width: 62, height: 67, color: true },
    { x: 442, y: 499, width: 62, height: 67, color: true },
    { x: 442, y: 569, width: 62, height: 67, color: true },
    { x: 506, y: 290, width: 62, height: 67, color: true },
    { x: 506, y: 360, width: 62, height: 67, color: true },
    { x: 506, y: 429, width: 62, height: 67, color: true },
    { x: 506, y: 499, width: 62, height: 67, color: true },
    { x: 506, y: 569, width: 62, height: 67, color: true },
    // sección 5
    { x: 611, y: 290, width: 62, height: 84 },
    { x: 611, y: 377, width: 62, height: 84, color: true },
    { x: 611, y: 464, width: 62, height: 84 },
    { x: 611, y: 551, width: 62, height: 84 },
    { x: 676, y: 290, width: 62, height: 84, color: true },
    { x: 676, y: 377, width: 62, height: 84 },
    { x: 676, y: 464, width: 62, height: 84, color: true },
    { x: 676, y: 551, width: 62, height: 84 },
    // sección 6
    { x: 781, y: 290, width: 77, height: 84 },
    { x: 781, y: 377, width: 77, height: 84, color: true },
    { x: 781, y: 464, width: 77, height: 84, color: true },
    { x: 781, y: 551, width: 77, height: 84, color: true },
    { x: 862, y: 290, width: 77, height: 84 },
    { x: 862, y: 377, width: 77, height: 84 },
    { x: 862, y: 464, width: 77, height: 84, color: true },
    { x: 862, y: 551, width: 77, height: 84, color: true },
  ];

  useEffect(() => {
    let img = new window.Image();
    img.src = "/img/planosNUT-1.jpg";
    img.height = imgHeight;
    img.width = imgWidth;
    setImg(img);

    let cameraImg = new window.Image();
    cameraImg.src = "/img/camera-icon.png";
    cameraImg.height = 50;
    cameraImg.width = 50;
    setCameraImg(cameraImg);
  }, []);

  const getVideo = () => {
    navigator.mediaDevices
      //getDisplayMedia for screen captura
      .getUserMedia({ video: { width: 800 } })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  const openCameraDialog = () => {
    setIsDialogOpen(true);
    getVideo();
  };

  return (
    <>
      <Stage width={1324} height={936} style={{ position: "relative" }}>
        <Layer>
          <Image x={0} y={0} image={img} />
        </Layer>
        <Layer>
          <Html
            divProps={{
              style: {
                top: 250 + "px",
                left: 1050 + "px",
              },
            }}
          >
            <IconButton onClick={openCameraDialog} color="primary">
              <PhotoCamera />
            </IconButton>
          </Html>
        </Layer>
        <Layer>
          {/*<Rect
            onClick={() => {
              setOpen(true);
            }}
            x={325}
            y={185}
            width={163}
            height={63}
            fill="rgba(0, 0, 200, 0.5)"
          />*/}
          {boxes.map((box) => (
            <Rect
              onClick={openMenu}
              x={box.x}
              y={box.y}
              height={box.height}
              width={box.width}
              fill={
                !box.color ? "rgba(0, 0, 200, 0.5)" : "rgba(200, 0, 0, 0.5)"
              }
            />
          ))}
        </Layer>
      </Stage>

      <Menu
        onClose={closeMenu}
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{
          horizontal: -menuX,
          vertical: -menuY,
        }}
        transitionDuration={0}
      >
        <MenuItem onClick={closeMenu}>Trastero</MenuItem>
        <MenuItem onClick={closeMenu}>Contrato</MenuItem>
        <MenuItem onClick={closeMenu}>Cliente</MenuItem>
      </Menu>
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <DialogTitle>Cámara 1</DialogTitle>
        <DialogContent>
          <video id="camara1" width={500} ref={videoRef} />
          {/* <ReactHlsPlayer
            // crossOrigin="Access-Control-Allow-Origin"
            // src="https://aws-tractor2.tuyaeu.com:8033/hls/1e816bd6f6f547d4bde7a5b6d0d502552b354598f390c934.m3u8"
            autoPlay={true}
            controls={false}
            width="100%"
            height="auto"
          /> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FloorMap;
