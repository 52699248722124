import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import AddIcon from "@mui/icons-material/Add";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import TileMenuItem from "./TileMenuItem";
import Button from "../../../Inputs/CustomButton";

import CreateTileForm from "../TileForms/CreateTileForm";

import { useContext, useReducer, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AppContext from "../../../../context/AppContext";

import { enqueueSnackbar } from "notistack";
import TextInput from "../../../Inputs/TextInput";

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET_NEW_TILE_DATA_VALUES":
      return { ...state, newTileData: { ...initialState.newTileData } };
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_TILES":
      return { ...state, tiles: action.payload };
    case "UPDATE_NEW_TILE_DATA_FIELD":
      return {
        ...state,
        newTileData: {
          ...state.newTileData,
          [action.payload.fieldName]: action.payload.value,
        },
      };
    case "SET_ADD_TILE_ANCHOR_EL":
      return { ...state, addTileAnchorEL: action.payload };
    case "SET_LAYER_MENU_ANCHOR_EL":
      return { ...state, layerMenuAnchorEl: action.payload };
    case "SET_DELETE_DIALOG_OPEN":
      return { ...state, deleteDialogOpen: action.payload };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

const initialState = {
  boxes: [],
  tiles: [],
  addTileAnchorEL: null,
  layerMenuAnchorEl: null,
  deleteDialogOpen: false,
  newTileData: {
    shape: "",
    x: "",
    y: "",
    width: "",
    height: "",
  },
};

const LayerEdit = (props) => {
  const { cameras, data, doors, functions, selectedTileData } = props;
  const { api } = useContext(AppContext);
  const [t] = useTranslation("floorPlans");
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const [edit, setEdit] = useState({ edit: false, name: data.name ?? "" });

  const openDeleteDialog = () => {
    dispatch({ type: "SET_DELETE_DIALOG_OPEN", payload: true });
  };
  const closeDeleteDialog = () => {
    dispatch({ type: "SET_DELETE_DIALOG_OPEN", payload: false });
  };

  useEffect(() => {
    dispatch({ type: "SET_TILES", payload: data?.FloorPlanTiles ?? [] });
  }, [data, data?.FloorPlanTiles]);

  useEffect(() => {
    getBoxes();
  }, [data.id]);

  const getBoxes = () => {
    if (!data) return;
    const params = {
      centers: [data.centerId],
      attributes: ["id", "name", "deletedAt"],
    };
    api
      .get("/boxes", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else
          dispatch({
            type: "SET_BOXES",
            payload: response.data
              .filter((e) => !e.deletedAt)
              .sort((a, b) => a.name.localeCompare(b.name)),
          });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const openAddTile = (e) => {
    dispatch({
      type: "SET_ADD_TILE_ANCHOR_EL",
      payload: e.currentTarget,
    });
  };

  const closeAddTile = () => {
    dispatch({
      type: "SET_ADD_TILE_ANCHOR_EL",
      payload: null,
    });
  };

  const openLayerMenu = (e) => {
    dispatch({
      type: "SET_LAYER_MENU_ANCHOR_EL",
      payload: e.currentTarget,
    });
  };

  const closeLayerMenu = () => {
    dispatch({
      type: "SET_LAYER_MENU_ANCHOR_EL",
      payload: null,
    });
  };

  const onDragEnd = (result) => {
    if (result.destination.index === result.source.index) return;
    const movedTile = state.tiles[result.source.index];
    if (!movedTile || !result.destination) return;
    const color =
      movedTile.shape === "Rect" || "Polygon" ? null : movedTile.color;
    functions.editTile({ ...movedTile, z: result.destination.index + 1, color: color });
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          <IconButton
            disabled={data.z <= 1}
            size="small"
            onClick={() => {
              functions.editLayer({ ...data, z: data.z - 1 });
            }}
          >
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton
            disabled={data.z >= data.numberOfLayers}
            size="small"
            onClick={() => {
              functions.editLayer({ ...data, z: data.z + 1 });
            }}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Card style={{ width: "100%" }} elevation={2}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container justifyContent="space-between">
                  <Grid
                    item
                    container
                    xs={4}
                    marginLeft={1}
                    spacing={1}
                    alignContent={"center"}
                  >
                    {!edit.edit ? (
                      <Typography variant="subtitle1">{data.name}</Typography>
                    ) : (
                      <>
                        <TextInput
                          sx={{ maxWidth: "225px" }}
                          label={t("name")}
                          value={edit.name}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setEdit({ ...edit, name: e.target.value });
                          }}
                        />
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setEdit({ edit: false, name: data.name });
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                        <IconButton
                          disabled={!edit.name || edit.name === data.name}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            functions.editLayer({ ...data, name: edit.name });
                            setEdit({ edit: false, name: edit.name });
                          }}
                        >
                          <DoneIcon />
                        </IconButton>
                      </>
                    )}
                  </Grid>
                  <Grid item container xs={4} justifyContent="flex-end">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        openAddTile(e);
                      }}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        openLayerMenu(e);
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <LayerOptionsMenu
                      open={Boolean(state.layerMenuAnchorEl)}
                      anchor={state.layerMenuAnchorEl}
                      handleClose={() => closeLayerMenu()}
                      handleEdit={() => {
                        closeLayerMenu();
                        setEdit({ ...edit, edit: true });
                      }}
                      handleDelete={(e) => {
                        if (!state.tiles.length) {
                          closeLayerMenu();
                          openDeleteDialog();
                        } else
                          enqueueSnackbar(t("cantDeleteLayerWithBoxes"), {
                            variant: "warning",
                          });
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails sx={{ maxHeight: "400px", overflowY: "auto" }}>
                {state.tiles && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="tiles">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {state.tiles
                            .sort((tile1, tile2) => tile1.z - tile2.z)
                            .map((tile, index) => (
                              <Draggable
                                key={tile.id}
                                draggableId={"tile" + tile.id}
                                index={index}
                                isDragDisabled={functions.selectedTile !== null}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <TileMenuItem
                                      cameras={cameras}
                                      doors={doors}
                                      functions={functions}
                                      key={tile.id}
                                      selectedTileData={selectedTileData}
                                      tileData={tile}
                                      boxes={state.boxes.filter(
                                        (box) =>
                                          !state.tiles.some(
                                            (tile) =>
                                              tile.Boxes !== undefined &&
                                              tile.Boxes.length > 0 &&
                                              Number(tile.Boxes[0].id) ===
                                                Number(box.id)
                                          )
                                      )}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </AccordionDetails>
            </Accordion>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={state.deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>{t("deleteLayer")}</DialogTitle>
        <DialogContent>{t("deleteLayerText")}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDeleteDialog}>
            {t("cancel")}
          </Button>
          <Button
            disabled={Boolean(state.tiles.length)}
            color="error"
            onClick={() => {
              functions.deleteLayer(data.id);
              closeDeleteDialog();
            }}
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={state.addTileAnchorEL}
        onClose={closeAddTile}
        maxWidth={false}
      >
        <DialogContent>
          <CreateTileForm
            closeAddTileDialog={closeAddTile}
            onCreateTile={(tile) => {
              functions.createTile({ ...tile, floorPlanLayerId: data.id });
              closeAddTile();
            }}
            centerId={data.centerId}
            layerTiles={state.tiles}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const LayerOptionsMenu = ({
  open,
  anchor,
  handleClose,
  handleEdit,
  handleDelete,
}) => {
  const [t] = useTranslation("floorPlans");

  const handleClick = (action) => {
    action === "edit" && handleEdit && handleEdit();
    action === "delete" && handleDelete && handleDelete();

    handleClose();
  };

  return (
    <Menu open={open} anchorEl={anchor} onClose={handleClose}>
      <MenuList>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleClick("edit");
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          {t("edit")}
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleClick("delete");
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          {t("delete")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default LayerEdit;
