import { Button, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextInput from "../../Inputs/TextInput";

export default function TransferAccountForm({ form, onChange, submitForm }) {
  const [t] = useTranslation("settings");

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <TextInput
            label={t("accountName")}
            value={form.accountName}
            name="accountName"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label={t("debtorName")}
            value={form.debtorName}
            name="debtorName"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={3}>
          <TextInput
            label={t("nif")}
            value={form.NIF}
            name="NIF"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            label={t("iban")}
            value={form.IBAN}
            name="IBAN"
            onChange={onChange}
          />
        </Grid>
        <Grid item>
          <TextInput
            label={t("debtorId")}
            value={form.debtorId}
            name="debtorId"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" onClick={submitForm}>
            {t("save")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
