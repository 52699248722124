import { useContext, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
  Switch,
  FormGroup,
  Checkbox,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import AppContext from "../../../context/AppContext";

// Components
import Page from "../../global/structure/Page";
import TextInput from "../../Inputs/TextInput";
import CustomButton from "../../Inputs/CustomButton";
import CenterSelect from "../../Inputs/CenterSelect";
import {
  ABSOLUTE_DISCOUNT_TYPE,
  PERCENTAGE_DISCOUNT_TYPE,
  PERMANENT_TEMPORALITY,
  TEMPORAL_TEMPORALITY,
} from "../../../data/constants";

// Constants

const CODE_MIN_LENGTH = 5;
const CODE_MAX_LENGTH = 20;

const CenterListItem = ({ center, handler, selected }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <Checkbox
          edge="start"
          onClick={handler}
          value={center.id}
          checked={selected}
        />
      </ListItemIcon>
      <ListItemText>{center.name}</ListItemText>
    </ListItem>
  );
};

const initialState = {
  centers: [],
  createPromotionLoading: false,
  periodicities: [],
  centersFilter: "",
  form: {
    active: false,
    name: "",
    code: "",
    description: "",
    temporality: "",
    numberOfPeriods: 1,
    discountType: "",
    value: 0,
    validFrom: "",
    validUntil: "",
    maxNumberOfUses: "",
  },
  inputError: {
    name: "",
    code: "",
    description: "",
    temporality: "",
    discountType: "",
    value: 0,
    validFrom: "",
    validUntil: "",
  },
  restrictByPeriodicity: false,
  restrictByCenter: false,
  selectedPeriodicities: [],
  selectedCenters: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PERIODICITIES":
      return { ...state, periodicities: action.payload };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_CENTERS_FILTER":
      return { ...state, centersFilter: action.payload };
    case "SET_CREATE_PROMOTION_LOADING":
      return { ...state, createPromotionLoading: action.payload };
    case "SET_INPUT":
      return {
        ...state,
        form: { ...state.form, [action.payload.name]: action.payload.value },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputName]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputName]: false,
        },
      };
    case "SET_SWITCH":
      return { ...state, [action.payload.name]: action.payload.value };
    case "SET_SELECTED_CENTERS":
      return { ...state, selectedCenters: action.payload };
    case "SET_SELECTED_PERIODICITIES":
      return { ...state, selectedPeriodicities: action.payload };
    default:
      throw new Error("Action not found in reducer");
  }
};

const CreatePromotionPage = () => {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [t] = useTranslation("promotion");
  const [tErrors] = useTranslation("errors");

  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  // Filtered centers by search input
  const filteredCenters = state.centers.filter((center) =>
    center.name.toUpperCase().includes(state.centersFilter.toUpperCase())
  );

  useEffect(() => {
    document.title = t("createPromotion");

    getPeriodicities();
    getCenters();
  }, []);

  const getPeriodicities = () => {
    api
      .get("/periods")
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          dispatch({ type: "SET_PERIODICITIES", payload: response.data });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          dispatch({ type: "SET_CENTERS", payload: response.data });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createPromotion = () => {
    if (!validateForm()) return;
    const data = {
      active: state.form.active,
      name: state.form.name,
      code: state.form.code,
      description: state.form.description,
      discountType: state.form.discountType,
      value: state.form.value,
      temporality: state.form.temporality,
      validFrom: state.form.validFrom !== "" ? state.form.validFrom : undefined,
      validUntil:
        state.form.validUntil !== "" ? state.form.validUntil : undefined,
      numberOfPeriods:
        state.form.temporality === TEMPORAL_TEMPORALITY
          ? state.form.numberOfPeriods
          : null,
      restrictedCenterIds: state.restrictByCenter
        ? state.selectedCenters
        : undefined,
      restrictedPeriodicitiesIds: state.restrictByPeriodicity
        ? state.selectedPeriodicities
        : undefined,
      maxNumberOfUses:
        state.form.maxNumberOfUses !== "" ? state.form.maxNumberOfUses : null,
    };

    dispatch({ type: "SET_CREATE_PROMOTION_LOADING", payload: true });

    api
      .post("/promotions/create", data)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          enqueueSnackbar(t("promotionCreatedSuccessfully"), {
            variant: "success",
          });
          history.push("/app/promotions");
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_CREATE_PROMOTION_LOADING", payload: false });
      });
  };

  const formInputHandler = (event) => {
    if (state.inputError[event.target.name]) {
      dispatch({
        type: "SET_INPUT_ERROR_FALSE",
        payload: {
          inputName: event.target.name,
        },
      });
    }
    dispatch({
      type: "SET_INPUT",
      payload: { name: event.target.name, value: event.target.value },
    });
  };

  const switchHandler = (event) => {
    if (event.target.name === "restrictByPeriodicity" && !event.target.checked)
      dispatch({ type: "SET_SELECTED_PERIODICITIES", payload: [] });
    dispatch({
      type: "SET_SWITCH",
      payload: { name: event.target.name, value: event.target.checked },
    });
  };

  const centerSelectHandler = (event) => {
    dispatch({ type: "SET_SELECTED_CENTERS", payload: event.target.value });
  };

  const handleRestrictByPeriodicity = (event) => {
    if (!event || !event.target?.name) return;
    let periodicities = [...state.selectedPeriodicities];
    if (event.target.checked) {
      periodicities.push(event.target.name);
    } else {
      periodicities = periodicities.filter((p) => p !== event.target.name);
    }
    dispatch({ type: "SET_SELECTED_PERIODICITIES", payload: periodicities });
  };

  const validateForm = () => {
    let isValid = true;
    let fields = [
      "name",
      "code",
      "description",
      "temporality",
      "discountType",
      "value",
      // "validFrom",
      // "validUntil",
    ];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        enqueueSnackbar(t(field) + " " + t("isRequired").toLowerCase(), {
          variant: "error",
        });
        dispatch({
          type: "SET_INPUT_ERROR_TRUE",
          payload: {
            inputName: field,
          },
        });
        isValid = false;
      }
    });

    if (
      state.form.code.length < CODE_MIN_LENGTH ||
      state.form.code.length > CODE_MAX_LENGTH
    ) {
      enqueueSnackbar(
        t("codeLengthErrorMessage", {
          min: CODE_MIN_LENGTH,
          max: CODE_MAX_LENGTH,
        }),
        {
          variant: "error",
        }
      );
      dispatch({
        type: "SET_INPUT_ERROR_TRUE",
        payload: {
          inputName: "code",
        },
      });
      isValid = false;
    }

    return isValid;
  };

  return (
    <Page
      title={t("createPromotion")}
      browserTitle={t("createPromotion")}
      maxWidth={"lg"}
      paper
    >
      <Grid container spacing={3} marginTop={1} padding={1}>
        {/* Promotion info */}
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("basicInfo")}</Typography>
          </Grid>
          <Grid item sm="auto" xs={12}>
            <TextInput
              name="name"
              label={t("name")}
              value={state.form.name}
              error={state.inputError.name}
              onChange={formInputHandler}
            />
          </Grid>
          <Grid item sm="auto" xs={12}>
            <TextInput
              name="code"
              label={t("code")}
              value={state.form.code}
              error={state.inputError.code}
              onChange={(e) => {
                e.target.value = e.target.value
                  .replace(/[^A-Za-z0-9]+/g, "")
                  .toUpperCase();
                formInputHandler(e);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="description"
              label={t("description")}
              value={state.form.description}
              error={state.inputError.description}
              onChange={formInputHandler}
              multiline
              rows={3}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* Temporality */}
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("discount")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{t("temporality")}</Typography>
          </Grid>
          <Grid item>
            <FormControl>
              <RadioGroup
                name="temporality"
                value={state.form.temporality}
                error={state.inputError.temporality}
                onChange={formInputHandler}
              >
                <FormControlLabel
                  value={TEMPORAL_TEMPORALITY}
                  label={t("temporal")}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={PERMANENT_TEMPORALITY}
                  label={t("permanent")}
                  control={<Radio />}
                  disabled={true}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {state.form.temporality === TEMPORAL_TEMPORALITY && (
            <Grid item>
              <TextInput
                sx={{ width: "140px" }}
                value={state.form.numberOfPeriods}
                name="numberOfPeriods"
                label={t("periods")}
                error={state.inputError.numberOfPeriods}
                onChange={formInputHandler}
                type="number"
              />
            </Grid>
          )}
        </Grid>
        {/* Discount type */}
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{t("discountType")}</Typography>
          </Grid>
          <Grid item>
            <FormControl>
              <RadioGroup
                name="discountType"
                value={state.form.discountType}
                error={state.inputError.discountType}
                onChange={formInputHandler}
              >
                <FormControlLabel
                  value={PERCENTAGE_DISCOUNT_TYPE}
                  label={t("percentage")}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={ABSOLUTE_DISCOUNT_TYPE}
                  label={t("absolute")}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Grid item>
              <TextInput
                label={t("discount") + " %"}
                visibility="hidden"
                type="number"
                name="value"
                value={state.form.value}
                error={state.inputError.value}
                onChange={formInputHandler}
                InputLabelProps={{ shrink: true }}
                sx={{
                  visibility:
                    state.form.discountType === PERCENTAGE_DISCOUNT_TYPE
                      ? "inherit"
                      : "hidden",
                }}
              />
            </Grid>
            <Grid item>
              <TextInput
                label={t("discount") + " €"}
                type="number"
                name="value"
                value={state.form.value}
                error={state.inputError.value}
                onChange={formInputHandler}
                InputLabelProps={{ shrink: true }}
                sx={{
                  visibility:
                    state.form.discountType === ABSOLUTE_DISCOUNT_TYPE
                      ? "inherit"
                      : "hidden",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* Availability and restrictions */}
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {t("availabilityAndRestrictions")}
            </Typography>
          </Grid>

          {/* Max number of uses */}
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6">{t("maxNumberOfUses")}</Typography>
            </Grid>
            <Grid item>
              <TextInput
                name="maxNumberOfUses"
                label={t("maxNumberOfUses")}
                value={state.form.maxNumberOfUses}
                onChange={formInputHandler}
                type="number"
              />
            </Grid>
          </Grid>

          {/* Dates */}
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6">{t("dates")}</Typography>
            </Grid>
            <Grid item>
              <TextInput
                name="validFrom"
                label={t("validFrom")}
                onChange={formInputHandler}
                value={state.form.validFrom}
                error={state.inputError.validFrom}
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  max: state.form.validUntil,
                }}
              />
            </Grid>
            <Grid item>
              <TextInput
                name="validUntil"
                label={t("validUntil")}
                onChange={formInputHandler}
                error={state.inputError.validUntil}
                value={state.form.validUntil}
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: state.form.validFrom,
                }}
              />
            </Grid>
          </Grid>

          {/* Periodicities */}
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" component="span">
                {t("restrictByPeriodicity")}
              </Typography>
              <Switch
                name="restrictByPeriodicity"
                checked={state.restrictByPeriodicity}
                onChange={switchHandler}
              />
            </Grid>
            {state.restrictByPeriodicity && (
              <Grid container item xs={12}>
                <FormGroup row>
                  {state.periodicities.map((periodicity) => (
                    <FormControlLabel
                      key={periodicity.id}
                      onClick={handleRestrictByPeriodicity}
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          name={periodicity.id.toString()}
                        />
                      }
                      label={t(periodicity.name)}
                    />
                  ))}
                </FormGroup>
              </Grid>
            )}
          </Grid>

          {/* Centers */}
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" component="span">
                {t("restrictByCenter")}
              </Typography>
              <Switch
                name="restrictByCenter"
                checked={state.restrictByCenter}
                onChange={switchHandler}
              />
            </Grid>
            {state.restrictByCenter && (
              <Grid item xs={12}>
                <CenterSelect
                  multiple
                  value={state.selectedCenters}
                  onChange={centerSelectHandler}
                />
              </Grid>
            )}
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name="active"
                  checked={state.form.active}
                  onClick={(e) => {
                    dispatch({
                      type: "SET_INPUT",
                      payload: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                />
              }
              label={t("promotionActive")}
              //labelPlacement="start"
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={1} justifyContent="flex-end">
          <Grid item>
            <CustomButton onClick={history.goBack}>{t("back")}</CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              color="success"
              loading={state.createPromotionLoading}
              onClick={createPromotion}
            >
              {t("create")}
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default CreatePromotionPage;
