import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useContext, useEffect, useReducer } from "react";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

import AppContext from "../../../context/AppContext";
import ButtonLink from "../../Inputs/ButtonLink";
import CustomButton from "../../Inputs/CustomButton";
import CustomSelect from "../../Inputs/CustomSelect";
import CloseIcon from "@mui/icons-material/Close";
import CreditCardForm from "../../CreditCardForm";
import CreateNonPaymentForm from "../NonPayments/CreateNonPaymentForm";
import InvoiceStateChip from "../../InvoiceStateChip";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import { CustomTable } from "../../CustomTable";
import { getParams, generateURL } from "../../../utils/url";
import { localeFormat } from "../../../utils/format";
import {
  F_INVOICE_SERIES_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  VIRTUAL_PAYCARD_ID,
} from "../../../data/constants";
import ItemsSummary from "../../ItemsSummary";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  customers: [],
  invoices: [],
  filters: {
    autoSearch: "false",
    customer: "",
    dateFrom: "",
    dateUntil: "",
    state: [],
    paymentDay: "",
  },
  loaded: true,
  selectedCustomer: "",
  paymentDays: [],
  paymentMethods: [],
  paycards: [],
  form: {
    newComment: "",
  },
  nonPaymentDialog: {
    isOpen: false,
    createLoading: false,
  },
  recuperateDialog: {
    isOpen: false,
    recuperationLoading: false,
  },
  recuperate: {
    ibanId: "",
    paycardId: "",
    recuperationMethod: "",
    recuperationComment: "",
  },
  selectedInvoice: [],
  nonPaymentReasons: [],
  receipts: [],
  newIban: "",
  addIbanLoading: false,
  cardModalIsOpen: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_CUSTOMER_FILTER":
      return { ...state, selectedCustomer: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_PAID_AT":
      const index = state.invoices.findIndex(
        (post) => post.id === action.payload.id
      );

      return {
        ...state,
        invoices: [
          ...state.invoices.slice(0, index),
          {
            ...state.invoices[index],
            paidAt: action.payload.paidAt,
          },
          ...state.invoices.slice(index + 1),
        ],
      };
    case "SET_PAYMENT_DAYS":
      return { ...state, paymentDays: action.payload };
    case "SET_SELECTED_INVOICE":
      return { ...state, selectedInvoice: action.payload };
    case "OPEN_RECUPERATE_DIALOG":
      return {
        ...state,
        recuperateDialog: { ...state.recuperateDialog, isOpen: true },
      };
    case "CLOSE_RECUPERATE_DIALOG":
      return {
        ...state,
        recuperateDialog: { ...state.recuperateDialog, isOpen: false },
      };
    case "SET_NONPAYMENT_DIALOG_CREATE_LOADING":
      return {
        ...state,
        nonPaymentDialog: {
          ...state.nonPaymentDialog,
          createLoading: action.payload,
        },
      };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_PAYMENT_METHOD_INFO":
      return {
        ...state,
        paycards: action.payload.paycards,
        ibans: action.payload.ibans,
      };
    case "OPEN_NONPAYMENT_DIALOG":
      return {
        ...state,
        nonPaymentDialog: { ...state.nonPaymentDialog, isOpen: true },
      };
    case "CLOSE_NONPAYMENT_DIALOG":
      return {
        ...state,
        nonPaymentDialog: { ...state.nonPaymentDialog, isOpen: false },
      };
    case "SET_NONPAYMENT_REASONS":
      return { ...state, nonPaymentReasons: action.payload };
    case "SET_INPUT_RECUPERATE":
      return {
        ...state,
        recuperate: {
          ...state.recuperate,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_RECEIPTS":
      return { ...state, receipts: action.payload };
    case "SET_ADD_IBAN_LOADING_TRUE":
      return { ...state, addIbanLoading: true };
    case "SET_ADD_IBAN_LOADING_FALSE":
      return { ...state, addIbanLoading: false };
    case "SET_NEW_IBAN":
      return { ...state, newIban: action.payload };
    case "OPEN_PAYCARD_MODAL":
      return { ...state, cardModalIsOpen: true };
    case "CLOSE_PAYCARD_MODAL":
      return { ...state, cardModalIsOpen: false };
    case "RESET_RECUPERATE_MODAL":
      return { ...state, recuperate: initialState.recuperate };
    case "SET_RECUPERATE_DIALOG_RECUPERATION_LOADING":
      return {
        ...state,
        recuperateDialog: {
          ...state.recuperateDialog,
          recuperationLoading: action.payload,
        },
      };
    default:
      throw new Error("Action not found in reducer");
  }
}

export default function PaycardsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { api, user } = useContext(AppContext);
  const [t] = useTranslation("invoices");
  const [tErrors] = useTranslation("errors");
  const history = useHistory();
  const query = useQuery();

  const FILTERS = [
    { name: "autoSearch", type: "string" },
    { name: "customerId", type: "number" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "paymentDay", type: "number" },
    { name: "state", type: "array" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const INVOICES_ITEM_SUMMARY = [
    {
      translatedText: t("totalInvoices"),
      value: localeFormat(state.invoices.length),
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          state.invoices.length > 0
            ? state.invoices.reduce(
                (total, element) => total + element.totalAmount,
                0
              )
            : 0
        ) + " €",
    },
  ];

  const INVOICES_COLUMNS = [
    {
      key: "number",
      label: t("number"),
      sortType: "number",
      renderFunction: (value, item) => (
        <ButtonLink variant="text" to={"/app/invoice/" + item.id}>
          {value}
        </ButtonLink>
      ),
    },
    {
      key: "customerName",
      label: t("customerName"),
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink variant="text" to={"/app/customer/" + item.Customer?.id}>
          {value}
        </ButtonLink>
      ),
    },
    {
      key: "paycardId",
      label: t("payCard"),
      sortType: "number",
      renderFunction: (value, item) => {
        const reg = /[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}/;

        //Search invoice paycard:
        const payCard = item?.Customer?.PayCards?.find(
          (card) => card.id === value
        );

        //Check regex
        if (value === null || !payCard) return "---";
        else if (reg.test(payCard.number))
          return (
            payCard.number +
            " (" +
            payCard.expirationMonth +
            " / " +
            payCard.expirationYear +
            ")"
          );
        else
          return (
            payCard.number.substring(0, 4) +
            " " +
            payCard.number.substring(4, 8) +
            " " +
            payCard.number.substring(8, 12) +
            " " +
            payCard.number.substring(12, 16) +
            " (" +
            payCard.expirationMonth +
            " / " +
            payCard.expirationYear +
            ")"
          );
      },
    },
    { key: "issueDate", label: t("issueDate"), sortType: "string" },
    {
      key: "totalAmount",
      label: t("import"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "state",
      label: t("state"),
      sortType: "number",
      renderFunction: (value) => <InvoiceStateChip state={value} />,
    },
    {
      key: "paidAt",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) => (
        <>
          <Tooltip
            title={value ? t("uncheckPaid") : t("checkPaid")}
            placement="top"
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                editInvoice(item.id, value);
              }}
            >
              {value ? (
                <CreditScoreIcon color="success" />
              ) : (
                <CreditCardIcon color="error" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("goNonPayments")} placement="top">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/app/non-payment/${item.Customer?.id}`);
              }}
            >
              <MoneyOffIcon />
            </IconButton>
          </Tooltip>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={(e) => {
              item.NonPayments.length !== 0 &&
              item.NonPayments.some(
                (nonPayment) => !nonPayment.recuperationDate
              )
                ? showRecuperationDialog(item)
                : showNonPaymentDialog(item);
            }}
          >
            {item.NonPayments.length !== 0 &&
            item.NonPayments.some((nonPayment) => !nonPayment.recuperationDate)
              ? t("showNonPayment")
              : t("nonPayment")}
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    getCustomers();
    getPaymentDays();
    getPaymentMethods();
    getNonPaymentReasons();
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === "true") getInvoices();
  }, [state.filters.autoSearch]);

  const search = () => {
    if (state.filters.autoSearch === "true") getInvoices();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  const getInvoices = () => {
    //Change url parameters:
    const url = generateURL("/app/pay-cards", state.filters);
    history.push(url);

    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      /*attributes: [
        {
          model: "Invoice",
          attributes: [
            "customerName",
            "dueDate",
            "id",
            "number",
            "state",
            "payCardId",
            "total",
          ],
        },
        {
          model: "PaymentMethod",
          attributes: ["id", "name"],
        },
        {
          model: "PayCard",
          attributes: ["id", "number", "expirationMonth", "expirationYear"],
        },
        {
          model: "IBAN",
          attributes: ["id", "number"],
        },
      ],*/
      include: [
        "Contract",
        "Merchantable",
        "InvoiceItem",
        "PayCard",
        "NonPayment",
      ],
      serieId: F_INVOICE_SERIES_ID,
      paymentMethodId: PAYCARD_PAYMENT_METHOD_ID,
    };

    state.filters.customerId !== "" &&
      (params.customerId = state.filters.customerId);
    state.filters.dateFrom !== "" && (params.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil !== "" &&
      (params.dateUntil = state.filters.dateUntil);
    state.filters.state !== "" && (params.state = state.filters.state);
    state.filters.paymentDay !== "" &&
      (params.dayOfPayment = state.filters.paymentDay);
    params.types = [1];

    api
      .get("/invoices", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noInvoices"), { variant: "warning" });
          dispatch({ type: "SET_INVOICES", payload: response.data });
          dispatch({
            type: "SET_PAYMENT_METHOD_INFO",
            payload: {
              paycards: response.data[0]?.Customer?.PayCards || [],
              ibans: response.data[0]?.Customer?.IBANs || [],
            },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  const getCustomers = () => {
    api
      .get("/customers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (state.filters.customerId) {
            const customer = response.data.find(
              (customer) => customer.id === Number(state.filters.customerId)
            );
            dispatch({ type: "SET_CUSTOMER_FILTER", payload: customer });
          }

          dispatch({
            type: "SET_CUSTOMERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentDays = () => {
    api
      .get("/payment-days/get")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const days = response.data.map((day) => day.day);
          dispatch({ type: "SET_PAYMENT_DAYS", payload: days });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getNonPaymentReasons = () => {
    api
      .get("/non-payments/reasons")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_NONPAYMENT_REASONS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const editInvoice = (id, paidAt) => {
    let data = {};

    paidAt ? (data.paidAt = null) : (data.paidAt = new Date());

    api
      .post("/invoices/edit/" + id, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("invoiceEditSuccess"), { variant: "success" });
          dispatch({
            type: "SET_PAID_AT",
            payload: { id: id, paidAt: data.paidAt },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const showNonPaymentDialog = (invoice) => {
    dispatch({ type: "SET_SELECTED_INVOICE", payload: invoice });
    dispatch({ type: "OPEN_NONPAYMENT_DIALOG" });
  };

  const showRecuperationDialog = (invoice) => {
    getReceipts(invoice);

    // Get last non payment
    const lastNonPayment = invoice.NonPayments.find(
      (nonPayment) => !nonPayment.recuperationDate
    );

    invoice.reason = state.nonPaymentReasons.find(
      (reason) => reason.id === lastNonPayment.reason
    ).name;
    invoice.nonPaymentDate = lastNonPayment.nonPaymentDate;
    invoice.comments = lastNonPayment.comments;

    dispatch({ type: "SET_SELECTED_INVOICE", payload: invoice });
    dispatch({ type: "OPEN_RECUPERATE_DIALOG" });
  };

  const closeNonPaymentDialog = () => {
    dispatch({ type: "CLOSE_NONPAYMENT_DIALOG" });
  };

  const closeRecuperationDialog = () => {
    dispatch({ type: "CLOSE_RECUPERATE_DIALOG" });
    dispatch({ type: "RESET_RECUPERATE_MODAL" });
  };

  const handleRecuperateChange = (e) => {
    dispatch({
      type: "SET_INPUT_RECUPERATE",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleInputChangeIban = (e) => {
    dispatch({ type: "SET_NEW_IBAN", payload: e.target.value });
  };

  const handleOpenCardModal = () => {
    dispatch({ type: "OPEN_PAYCARD_MODAL" });
  };

  const handleCloseCardModal = () => {
    dispatch({ type: "CLOSE_PAYCARD_MODAL" });
  };

  const getPaymentMethodTranslation = (paymentMethodName) => {
    const paymentMethodMap = {
      CONTADO: t("cash"),
      RECIBO: t("receipt"),
      TARJETA: t("card"),
      TRANSFERENCIA: t("transfer"),
      CONSOLIDACION: t("consolidation"),
    };
    return paymentMethodMap[paymentMethodName]
      ? paymentMethodMap[paymentMethodName]
      : "PaymentMethod not found";
  };

  const getReceipts = (invoice) => {
    let params = {
      include: ["Remittance"],
      customerId: invoice.Customer.id,
    };
    api
      .get("/receipts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_RECEIPTS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createNonPayment = (form, receipts) => {
    dispatch({ type: "SET_NONPAYMENT_DIALOG_CREATE_LOADING", payload: true });
    let data = {
      nonPayment: {
        nonPaymentDate: form.date,
        reason: form.reason,
        comments: form.comment,
        invoiceId: state.selectedInvoice.id,
      },
      receipts: receipts,
    };

    api
      .post("/non-payments/create", data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("nonPaymentCreatedSuccessfully"), {
            variant: "success",
          });
          closeNonPaymentDialog();
          addComment(
            `${t("theUser")} ${user.name} ${t(
              "createdANonPayment"
            ).toLowerCase()} ${t("forTheInvoice").toLowerCase()} ${
              state.selectedInvoice?.InvoiceSerie?.name
            }${state.selectedInvoice.number}.
            ${form.comment !== "" ? t("comment") + ": " + form.comment : ""}`
          );
          getInvoices();
        }
        dispatch({
          type: "SET_NONPAYMENT_DIALOG_CREATE_LOADING",
          payload: false,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createIban = (number) => {
    const ibanRepeated = state.ibans.some((iban) => iban.number === number);

    if (!ibanRepeated) {
      dispatch({ type: "SET_ADD_IBAN_LOADING_TRUE" });
      let data = {};
      data.number = number;
      data.customerId = state.selectedInvoice.Customer.id;

      api
        .post("/ibans/create", data)
        .then((response) => {
          dispatch({ type: "SET_ADD_IBAN_LOADING_FALSE" });
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("ibanCreatedSuccessfully"), {
              variant: "success",
            });
            dispatch({
              type: "SET_PAYMENT_METHOD_INFO",
              payload: {
                paycards: state.paycards,
                ibans: [...state.ibans, response.data.iban],
              },
            });
            dispatch({ type: "SET_NEW_IBAN", payload: "" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else {
      enqueueSnackbar(t("ibanRepeated"), { variant: "warning" });
    }
  };

  const addComment = (comment = null) => {
    const newComment = comment || state.form.newComment;
    if (newComment !== "") {
      let form = {
        text: newComment,
        customerId: state.selectedInvoice.Customer.id,
        authorId: comment ? null : user.id,
        authorName: comment ? null : user.name,
      };
      api
        .post(
          "/customers/" + state.selectedInvoice.Customer.id + "/comment",
          form
        )
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("commentAddedSuccessfully"), {
              variant: "success",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else {
      enqueueSnackbar(t("commentCannotBeEmpty"), { variant: "warning" });
    }
  };

  const submitCardForm = (card) => {
    let form = {
      name: card.name,
      number: card.number,
      cvv: card.cvc,
      expirationMonth: card.expiry.substring(0, 2),
      expirationYear: card.expiry.substring(3),
      customerId: state.selectedInvoice.Customer.id,
    };

    api
      .post("/pay-cards/create", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("cardCreatedSuccessfully"), { variant: "success" });

          dispatch({
            type: "SET_PAYMENT_METHOD_INFO",
            payload: {
              paycards: [...state.paycards, response.data],
              ibans: state.ibans,
            },
          });

          handleCloseCardModal();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const recuperate = () => {
    dispatch({
      type: "SET_RECUPERATE_DIALOG_RECUPERATION_LOADING",
      payload: true,
    });
    let data = {};

    Number(state.recuperate.recuperationMethod) === RECEIPT_PAYMENT_METHOD_ID &&
      state.recuperate.ibanId !== "" &&
      (data.ibanId = state.recuperate.ibanId);
    Number(state.recuperate.recuperationMethod) === PAYCARD_PAYMENT_METHOD_ID &&
      state.recuperate.paycardId !== "" &&
      (data.paycardId = state.recuperate.paycardId);
    state.recuperate.recuperationComment !== "" &&
      (data.recuperationComment = state.recuperate.recuperationComment);
    state.recuperate.recuperationMethod !== "" &&
      (data.recuperationMethod = state.recuperate.recuperationMethod);

    api
      .post(
        "/non-payments/" +
          state.selectedInvoice.NonPayments.find(
            (nonPayment) => !nonPayment.recuperationDate
          ).id +
          "/edit",
        data
      )
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("nonPaymentRecuperatedSuccessfully"), {
            variant: "success",
          });
          closeRecuperationDialog();
          addComment(
            `${t("theUser")} ${user.name} ${t(
              "hasRecuperatedANonPayment"
            ).toLowerCase()} ${t("fromTheInvoice").toLowerCase()} ${
              state.selectedInvoice.InvoiceSerie?.name
            }${state.selectedInvoice.number}.
            ${
              state.recuperate.recuperationComment !== ""
                ? t("comment") + ": " + state.recuperate.recuperationComment
                : ""
            }`
          );
          getInvoices();
          dispatch({ type: "RESET_RECUPERATE_MODAL" });
        }
        dispatch({
          type: "SET_RECUPERATE_DIALOG_RECUPERATION_LOADING",
          payload: false,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Grid item container rowSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t("paycardCharges")}</Typography>
        </Grid>

        <Grid item container spacing={1} xs={12}>
          <Grid item xs={12} sm="auto">
            <Autocomplete
              size="large"
              sx={{ minWidth: "200px" }}
              options={state.customers}
              getOptionLabel={(customer) => customer.fullName || ""}
              isOptionEqualToValue={(option, value) =>
                option.nif === value.nif || null
              }
              filterOptions={createFilterOptions({ limit: 10 })}
              value={state.selectedCustomer || null}
              onChange={(e, customer) => {
                handleFilterChange({
                  target: {
                    value: customer ? customer.id : "",
                    name: "customerId",
                  },
                });
                dispatch({ type: "SET_CUSTOMER_FILTER", payload: customer });
              }}
              renderInput={(params) => (
                <TextInput {...params} size="small" label={t("customer")} />
              )}
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <TextInput
              label={t("dateFrom")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateFrom}
              onChange={handleFilterChange}
              name="dateFrom"
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <TextInput
              label={t("dateUntil")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.filters.dateUntil}
              onChange={handleFilterChange}
              name="dateUntil"
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <CustomSelect
              label={t("paymentDay")}
              name="paymentDay"
              value={state.filters.paymentDay}
              onChange={handleFilterChange}
              options={[
                { value: "", label: t("none") },
                ...state.paymentDays?.map((day) => ({
                  value: day,
                  label: day,
                })),
              ]}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <CustomSelect
              label={t("state")}
              value={state.filters.state}
              onChange={handleFilterChange}
              options={[
                { value: 0, label: t("issued") },
                { value: 1, label: t("paid") },
                { value: 2, label: t("expired") },
                { value: 3, label: t("unpaid") },
                { value: 4, label: t("incorrectIssuance") },
              ]}
              name="state"
              multiple
            />
          </Grid>
          <Grid item>
            <SearchButton onClick={search} loading={!state.loaded} />
          </Grid>

          <Grid item xs={12}>
            <ItemsSummary gridItems={INVOICES_ITEM_SUMMARY} />
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              columns={INVOICES_COLUMNS}
              data={state.invoices.map((invoice) => {
                invoice.actions = "";
                return invoice;
              })}
              options={state.options}
              sortBy={state.filters.orderBy}
              sort={state.filters.order}
              /*onSortCallback={(orderBy, order) => {
                handleOrderChange(orderBy, order);
                const url = generateURL("/app/invoices", {
                  ...state.filters,
                  orderBy: orderBy,
                  order: order,
                });
                history.push(url);
              }}*/
            />
          </Grid>
        </Grid>
      </Grid>

      {/*Create Non Payment dialog */}
      <CreateNonPaymentForm
        open={state.nonPaymentDialog.isOpen}
        onClose={closeNonPaymentDialog}
        onSubmit={createNonPayment}
        receipts={state.receipts}
        invoicePaymentMethodId={PAYCARD_PAYMENT_METHOD_ID}
        loading={state.nonPaymentDialog.createLoading}
      />

      {/* Non Payment info dialog */}
      <Dialog
        open={state.recuperateDialog.isOpen}
        onClose={closeRecuperationDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>{t("nonPaymentInformation")}</Grid>
            <Grid item>
              <IconButton onClick={closeRecuperationDialog}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid item container spacing={3} columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("invoiceNumber")}:
              </Typography>
              <Typography variant="body">
                {" "}
                {state.selectedInvoice.number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("amount")}:
              </Typography>
              <Typography variant="body">
                {" "}
                {state.selectedInvoice.totalAmount} €
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("reason")}:
              </Typography>
              <Typography variant="body">
                {" "}
                {state.selectedInvoice.reason}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("nonPaymentDate")}:
              </Typography>
              <Typography variant="body">
                {" "}
                {state.selectedInvoice.nonPaymentDate}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("comments")}:
              </Typography>
              <Typography variant="body" style={{ wordWrap: "break-word" }}>
                {" "}
                {state.selectedInvoice.comments}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{t("recuperation")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="recuperationMethod"
                  value={Number(state.recuperate.recuperationMethod)}
                  onChange={handleRecuperateChange}
                >
                  {state.paymentMethods.map((method, index) => (
                    <FormControlLabel
                      key={index}
                      value={method.id}
                      control={<Radio />}
                      label={getPaymentMethodTranslation(method.name)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            {Number(state.recuperate.recuperationMethod) ===
              RECEIPT_PAYMENT_METHOD_ID && (
              <Grid container spacing={1} item xs={12}>
                <Grid item xs={12} sm={5}>
                  <CustomSelect
                    value={state.recuperate.ibanId}
                    name="ibanId"
                    onChange={handleRecuperateChange}
                    label={t("ibans")}
                    options={[
                      { value: "", label: t("none") },
                      ...state?.ibans?.map((iban) => ({
                        value: iban.id,
                        label: iban.number,
                      })),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    label={t("newIban")}
                    name="newIban"
                    value={state.newIban}
                    onChange={handleInputChangeIban}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <CustomButton
                    loading={state.addIbanLoading}
                    loadingText=""
                    disabled={state.newIban === ""}
                    onClick={() => {
                      createIban(state.newIban);
                    }}
                  >
                    {t("add")}
                  </CustomButton>
                </Grid>
              </Grid>
            )}

            {Number(state.recuperate.recuperationMethod) ===
              PAYCARD_PAYMENT_METHOD_ID && (
              <Grid container item spacing={1} xs={12}>
                <Grid item xs={12} sm={5}>
                  <CustomSelect
                    value={state.recuperate.paycardId}
                    name="paycardId"
                    onChange={handleRecuperateChange}
                    label={t("card")}
                    options={[
                      { value: "", label: t("none") },
                      { value: VIRTUAL_PAYCARD_ID, label: t("paymentByLink") },
                      ...state.paycards.map((card) => ({
                        value: card.id,
                        label: card.number,
                      })),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Button variant="contained" onClick={handleOpenCardModal}>
                    {t("addCreditCard")}
                  </Button>
                </Grid>

                <Dialog
                  open={state.cardModalIsOpen}
                  onClose={handleCloseCardModal}
                >
                  <DialogTitle>
                    <Grid container justifyContent="space-between">
                      <Grid item>{t("addCreditCard")}</Grid>
                      <Grid item>
                        <IconButton onClick={handleCloseCardModal}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <CreditCardForm onSubmit={submitCardForm} />
                  </DialogContent>
                </Dialog>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Typography variant="body1">
                {t("recuperationDate") + ": " + String(new Date()).slice(4, 15)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                variant="outlined"
                label={t("recuperationComment")}
                name={"recuperationComment"}
                value={state.recuperate.recuperationComment}
                onChange={handleRecuperateChange}
                fullWidth
                rows={4}
              />
            </Grid>

            <Grid item container spacing={1} justifyContent="flex-end">
              <Grid item>
                <CustomButton
                  onClick={recuperate}
                  disabled={
                    state.recuperate.recuperationMethod === "" ||
                    state.recuperate.recuperationDate === "" ||
                    (Number(state.recuperate.recuperationMethod) ===
                      RECEIPT_PAYMENT_METHOD_ID &&
                      state.recuperate.ibanId === "") ||
                    (Number(state.recuperate.recuperationMethod) ===
                      PAYCARD_PAYMENT_METHOD_ID &&
                      state.recuperate.paycardId === "")
                  }
                  loading={state.recuperateDialog.recuperationLoading}
                >
                  {t("recuperate")}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
