import PropTypes from "prop-types";
import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";

const TabPanel = (props) => {
  const { onChange, selectedTab, tabs } = props;
  return (
    <Grid item xs={12}>
      <Tabs value={selectedTab} onChange={onChange}>
        {tabs.map(
          (tab, i) => !tab.disabled && <Tab label={tab.label} id={i} key={i} />
        )}
      </Tabs>

      <Divider></Divider>

      {tabs.map((tab, i) => (
        <Box hidden={selectedTab !== i} key={i} p={3}>
          {selectedTab === i && <Box>{tab.children}</Box>}
        </Box>
      ))}
    </Grid>
  );
};

TabPanel.propTypes = {
  onChange: PropTypes.func,
  selectedTab: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.object),
};

export default TabPanel;
