import { useState } from "react";

export default function useToken() {
  const TOKEN_NAME = "nutAuthToken";
  const getToken = () => {
    return localStorage.getItem(TOKEN_NAME);
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem(TOKEN_NAME, userToken);
    setToken(userToken);
  };

  const unsetToken = () => {
    localStorage.removeItem(TOKEN_NAME);
    setToken(undefined);
  };

  return {
    setToken: saveToken,
    unsetToken,
    token,
    getToken,
  };
}
