import {
  Avatar,
  Box,
  Card,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import AppContext from "../../../context/AppContext";

import VideocamIcon from "@mui/icons-material/Videocam";
import CloseIcon from "@mui/icons-material/Close";

import Page from "../../global/structure/Page";
import CameraStream from "./CameraStraem";
import CenterSelect from "../../Inputs/CenterSelect";
import { enqueueSnackbar } from "notistack";

const initialLiveCameras = [null, null, null, null];

const CamerasPage = () => {
  const [t] = useTranslation("cameras");
  const { api } = useContext(AppContext);
  const [cameras, setCameras] = useState([]);
  const [liveCameras, setLiveCameras] = useState(initialLiveCameras);
  const [centerId, setCenterId] = useState("");

  // Initial useEffect
  useEffect(() => {
    if (centerId !== "") getCameras();
    setLiveCameras(initialLiveCameras);
    setCameras([]);
  }, [centerId]);

  const getCameras = () => {
    if (centerId === "") return;
    const params = { centerId: centerId };
    api.get(`/surveillance/cameras`, { params }).then((response) => {
      if (response.data.error) {
        enqueueSnackbar(response.data.error.msg, { variant: "error" });
      } else {
        setCameras(response.data);
      }
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.droppableId.includes("camera-render")) {
      let newCameras = [...cameras];
      let newLiveCameras = [...liveCameras];

      const renderId = Number(
        result.destination.droppableId.split("camera-render")[1]
      );
      const cameraId = cameras.find(
        (c) => "cam" + c.id === result.draggableId
      ).id;

      newCameras = cameras.filter((c) => c.id !== cameraId);
      if (liveCameras[renderId] !== null) {
        newCameras.push(liveCameras[renderId]);
      }
      newLiveCameras[renderId] = cameras.find((c) => c.id === cameraId);
      setCameras(newCameras);
      setLiveCameras(newLiveCameras);
    }
  };

  const handleChangeFilter = (e) => {
    setCenterId(e.target.value);
  };

  const handleCameraClose = (e, cameraIndex) => {
    let newLiveCameras = [...liveCameras];
    let newCameras = [...cameras];
    newLiveCameras[cameraIndex] = null;
    newCameras.push(liveCameras[cameraIndex]);
    setCameras(newCameras);
    setLiveCameras(newLiveCameras);
  };

  return (
    <Page title={t("cameras")} browserTitle={t("cameras")}>
      <Grid container>
        <Grid item xs={12}>
          <CenterSelect
            name="centerId"
            onChange={handleChangeFilter}
            value={centerId}
          />
        </Grid>
        <Grid item xs={12}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <CamerasList
                  cameras={cameras
                    .filter(
                      (c) =>
                        !liveCameras.some((l) => {
                          if (l === null) return false;
                          else return l.id === c.id;
                        })
                    )
                    .sort((a, b) => a.id - b.id)}
                  handleDragEnd={handleDragEnd}
                />
              </Grid>
              {centerId !== "" && (
                <Grid item xs={10}>
                  <CamerasPlaceHolders
                    liveCameras={liveCameras}
                    handleCameraClose={handleCameraClose}
                  />
                </Grid>
              )}
            </Grid>
          </DragDropContext>
        </Grid>
      </Grid>
    </Page>
  );
};

function CamerasList({ cameras, handleDragEnd }) {
  return (
    <List sx={{ width: "100%" }}>
      <Droppable droppableId="cameras">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {cameras.map((camera) => (
              <Draggable
                key={camera.id}
                draggableId={"cam" + camera.id}
                index={camera.id}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Card elevation={2} sx={{ my: 1 }}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <VideocamIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={camera.name}
                          secondary={"Cam: " + camera.id}
                        />
                      </ListItem>
                    </Card>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </List>
  );
}

function CamerasPlaceHolders({ liveCameras, handleCameraClose }) {
  return (
    <Grid container spacing={2}>
      {liveCameras.map((content, index) => (
        <Grid item xs={6} key={index}>
          <Square
            index={index}
            camera={liveCameras[index]}
            handleCameraClose={handleCameraClose}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function Square({ index, camera, handleCameraClose }) {
  return (
    <Droppable droppableId={"camera-render" + index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <Box position="relative" width={"100%"} height={400} bgcolor="white">
            <CameraStream camera={camera} />
            {camera !== null && (
              <IconButton
                sx={{ position: "absolute", top: 0, right: 0 }}
                onClick={(e) => {
                  handleCameraClose(e, index);
                }}
              >
                <CloseIcon color="white" />
              </IconButton>
            )}
          </Box>
        </div>
      )}
    </Droppable>
  );
}

export default CamerasPage;
