import { useSnackbar } from "notistack";
import { useState } from "react";
import { useContext, useEffect } from "react";
import AppContext from "../../context/AppContext";

import CustomSelect from "./CustomSelect";

const ExpenseTypeSelect = (props) => {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const [expenseTypes, setExpenseTypes] = useState([]);

  useEffect(() => {
    getExpenseTypes();
  }, []);

  const getExpenseTypes = () => {
    api
      .get("/expense-types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          setExpenseTypes(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <CustomSelect
      {...props}
      options={expenseTypes.map((expenseType) => ({
        value: expenseType.id,
        label: expenseType.name,
        parentId: expenseType.parentId,
      }))}
      grouping={true}
      value={props.value || ""} //To avoid out-of-range value `null` MUI warning
    />
  );
};

export default ExpenseTypeSelect;
