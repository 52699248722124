import {
  Button,
  ButtonGroup,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";

import AppContext from "../../../context/AppContext";
import ButtonCSV from "../../Inputs/ButtonCSV";
import CenterSelect from "../../Inputs/CenterSelect";
import CustomSelect from "../../Inputs/CustomSelect";
import CreateButton from "../../Inputs/CreateButton";
import { CustomTable } from "../../CustomTable";
import Filters from "../../global/structure/Filters";
import { getParams, generateURL } from "../../../utils/url";
import Page from "../../global/structure/Page";
import TextInput from "../../Inputs/TextInput";
import SearchButton from "../../Inputs/SearchButton";
import { formatPhoneNumber, localeFormat } from "../../../utils/format";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  providers: [],
  providersLoading: false,
  filters: {
    autoSearch: "false",
    centers: [],
    name: "",
    authorized: "",
    orderBy: "",
    order: "asc",
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_PROVIDERS":
      return { ...state, providers: action.payload.providers };
    case "SET_PROVIDERS_LOADING":
      return { ...state, providersLoading: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    default:
      throw new Error();
  }
}

export default function ProvidersPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("providers");

  const filters = [
    { name: "name", type: "string" },
    { name: "centers", type: "array" },
    { name: "orderBy", type: "string" },
    { name: "order", type: "string" },
    { name: "autoSearch", type: "string" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, filters) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const columns = [
    { label: "ID", key: "external_id_1", sortType: "number" },
    { label: t("brand"), key: "brand", sortType: "string" },
    { label: t("businessName"), key: "businessName", sortType: "string" },
    { label: t("cif"), key: "cif", sortType: "string" },
    { label: t("center"), key: "Center.name", sortType: "string" },
    {
      label: t("email"),
      key: "emails",
      sortType: "string",
      renderFunction: (emails) => emails.map((email) => email.email).join(" "),
    },
    {
      label: t("phone"),
      key: "phoneNumbers",
      sortType: "string",
      renderFunction: (phones) => (
        <List>
          {phones.map((phone) => (
            <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
                {formatPhoneNumber(phone.number)}
              </Typography>
            </ListItem>
          ))}
        </List>
      ),
    },
    {
      label: t("invoices"),
      key: "invoices",
      sortType: "number",
      renderFunction: (invoices) => invoices.length,
    },
    {
      label: t("authorized"),
      key: "authorizedBy",
      sortType: "number",
      renderFunction: (value) => value && <CheckIcon color="success" />,
    },
    {
      label: t("amount"),
      key: "amount",
      sortType: "number",
      renderFunction: (amount) => localeFormat(amount) + "€",
    },
    {
      label: t("pending"),
      key: "pending",
      sortType: "number",
      renderFunction: (pending) => localeFormat(pending) + "€",
    },
    {
      label: t("blocked"),
      key: "blocked",
      sortType: "other",
      renderFunction: (value) => {
        if (value) return <CircleIcon color="error" />;
        else return "";
      },
    },
  ];

  const csvHeaders = [
    { label: "ID", key: "id" },
    { label: t("brand"), key: "brand" },
    { label: t("businessName"), key: "businessName" },
    { label: t("cif"), key: "cif" },
    { label: t("center"), key: "Center.name" },
    { label: t("email"), key: "emailsCsv" },
    { label: t("phone"), key: "phoneNumbersCsv" },
    { label: t("blocked"), key: "blocked" },
  ];

  const getProviders = () => {
    //Change url parameters:
    const url = generateURL("/app/providers", state.filters);
    history.push(url);

    dispatch({ type: "SET_PROVIDERS_LOADING", payload: true });

    let params = {
      include: [
        "Center",
        "ProviderInvoice",
        "ProviderEmail",
        "ProviderPhoneNumber",
      ],
    };

    if (state.filters.centers.length > 0)
      params.centers = state.filters.centers;
    if (state.filters.name) params.name = state.filters.name;
    if (state.filters.authorized) params.authorized = state.filters.authorized;

    api
      .get("/providers", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noProviders"), { variant: "warning" });

          response.data.forEach((provider) => {
            provider.amount = provider.invoices.reduce(
              (total, invoice) => total + invoice.amount,
              0
            );
            provider.pending = provider.invoices
              .filter((invoice) => invoice.paidAt === null)
              .reduce((total, invoice) => total + invoice.amount, 0);
            provider.emailsCsv = provider.emails
              .map((email) => email.email)
              .join(" ");
            provider.phoneNumbersCsv = provider.phoneNumbers
              .map((phone) => phone.number)
              .join(" ");
          });
          dispatch({
            type: "SET_PROVIDERS",
            payload: {
              providers: response.data,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      })
      .finally(() =>
        dispatch({ type: "SET_PROVIDERS_LOADING", payload: false })
      );
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: { inputname: e.target.name, value: e.target.value },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const search = () => {
    if (state.filters.autoSearch === "true") getProviders();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  useEffect(() => {
    if (state.filters.autoSearch === "true") getProviders();
  }, [state.filters.autoSearch]);

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_PROVIDERS", payload: [] });
  };

  return (
    <Page browserTitle={t("providers")} title={t("providers")}>
      <Grid container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                id="nameFilter"
                label={t("search")}
                value={state.filters.name}
                name="name"
                onChange={(e) => {
                  handleFilterChange(e);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
              />,
              <CenterSelect
                multiple
                name="centers"
                value={state.filters.centers}
                onChange={handleFilterChange}
              />,
              <CustomSelect
                name="authorized"
                label={t("authorized")}
                value={state.filters.authorized}
                onChange={(e) => {
                  handleFilterChange(e);
                }}
                options={[
                  { label: t("all"), value: 0 },
                  { label: t("authorized"), value: 1 },
                  { label: t("notAuthorized"), value: 2 },
                ]}
              />,
            ]}
          />
        </Grid>

        <Grid item>
          <ButtonGroup variant="contained">
            <Button onClick={resetFilters}>{t("reset")}</Button>
            <ButtonCSV
              data={state.providers}
              headers={csvHeaders}
              filename={t("providers")}
            />
            <SearchButton onClick={search} loading={state.providersLoading} />
          </ButtonGroup>
        </Grid>

        <Grid item>
          <CreateButton
            action={"CREATE_PROVIDERS"}
            label={t("createProvider")}
            link={"/provider/create"}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            columns={columns}
            data={state.providers}
            options={{
              loaded: !state.providersLoading,
              rowlink: "provider",
            }}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/providers", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>
    </Page>
  );
}
