import { Route, Switch, useRouteMatch, Redirect } from "react-router";
import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState, useReducer } from "react";

import useToken from "./hooks/useToken";
import AppContext from "./context/AppContext";
import API from "./utils/API";
import pages from "./data/pages";

//components
import NavBar from "./components/NavBar";
import ContractTypesPage from "./components/pages/Contracts/ContractTypesPage";
import CustomErrorFallback from "./components/CustomErrorFallback";
import FloorMap from "./components/FloorMap";
import WelcomePage from "./components/pages/Welcome/WelcomePage";
import ProfilePage from "./components/pages/ProfilePage.js";
import { DragTestPage } from "./components/pages/DragTestPage";
import { DragTestPage2 } from "./components/pages/DragTestPage2";
import { ErrorBoundary } from "react-error-boundary";
import NotFoundPage from "./components/pages/NotFoundPage.js";

// import VideoStream from "./components/WebRTC";
// import Broadcaster from "./components/Broadcast";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    default:
      throw new Error();
  }
};

const App = () => {
  // Path for router
  const { path } = useRouteMatch();

  // Auth token
  const { token, unsetToken, setToken, getToken } = useToken();

  // Connector to api / axios
  const api = API(getToken, unsetToken);

  const initialState = {
    api,
    token,
    unsetToken,
    setToken,
    user: undefined,
  };
  const redirectPath = `/login?redirect=${
    window.location.pathname + window.location.search.replaceAll("&", "~")
  }`;

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Set user
  useEffect(() => {
    verifyAccount();
  }, []);

  const verifyAccount = () => {
    api
      .get("/auth/verify-account")
      .then((res) => {
        //TODO create class?
        const user = {
          ...res.data.user,
          hasAction: function (action) {
            return this?.Role?.Actions?.some((item) => item.id === action);
          },
          hasPage: function (page) {
            return this?.Role?.Pages?.some((item) => item.name === page);
          },
        };
        dispatch({ type: "SET_USER", payload: user });
        setToken(res.data.token);

        if (!res.data.error) {
          /**
           * Every 10 minutes call verify account to refresh the auth token
           */
          const INTERVAL = 600000; // 10 minutes in miliseconds
          setTimeout(() => {
            verifyAccount();
          }, INTERVAL);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  return token === undefined ? (
    <Redirect to={redirectPath} />
  ) : (
    <AppContext.Provider value={state}>
      <ErrorBoundary FallbackComponent={CustomErrorFallback}>
        <Box display="flex">
          <NavBar
            unsetToken={unsetToken}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              paddingTop: "64px", // navbar height
              overflow: "auto",
              background:
                "linear-gradient(135deg, rgba(245, 246, 247, 1) 50%, rgba(0,159,255,1) 100%)",
            }}
          >
            {state.user === undefined ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Switch>
                <Route exact path={path}>
                  <WelcomePage />
                </Route>
                {pages
                  .filter((page) =>
                    state.user?.Role?.Pages?.some((p) => p.name === page.name)
                  )
                  .map((page) => (
                    <Route key={page.path} path={path + page.path}>
                      {page.component}
                    </Route>
                  ))}
                <Route key={3324} path={path + "/contract-types"}>
                  <ContractTypesPage />
                </Route>
                <Route key={32423} path={path + "/floor-map"}>
                  <FloorMap />
                </Route>
                <Route key={324223} path={path + "/profile"}>
                  <ProfilePage />
                </Route>

                <Route key={32422} path={path + "/drag"}>
                  <DragTestPage />
                </Route>
                <Route key={32423} path={path + "/drag2"}>
                  <DragTestPage2 />
                </Route>
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            )}
          </Box>
          {/*<Chat />*/}
        </Box>
      </ErrorBoundary>
    </AppContext.Provider>
  );
};

export default App;
