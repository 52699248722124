import React, { useContext, useEffect, useReducer, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Material UI
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Icons
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

// Components & utils
import {
  CENTER_ROLE_ID,
  CUSTOMER_ROLE_ID,
  SUPPLY_TYPES,
  WATER_EXPENSE_TYPE_ID,
} from "../../../data/constants";
import { localeFormat } from "../../../utils/format";
import AppContext from "../../../context/AppContext";
import CenterSelect from "../../Inputs/CenterSelect";
import ConfirmDialog from "../../ConfirmDialog";
import CustomButton from "../../Inputs/CustomButton";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import ExpenseTypeSelect from "../../Inputs/ExpenseTypeSelect";
import ItemsSummary from "../../ItemsSummary";
import TextInput from "../../Inputs/TextInput";
import Select from "../../global/inputs/Select";

const initialState = {
  authorizations: {
    number: "1",
    requestedUsers: [{ id: null }],
  },
  centers: [],
  confirmDialog: {
    isOpen: false,
    title: "",
    callback: () => {},
  },
  form: {
    amount: "0",
    centerId: "",
    createdBy: "",
    dateInvoice: "",
    description: "",
    dueDate: "",
    expenseTypeId: "",
    file: "",
    filePath: "",
    invoiceNumber: "",
    isTicket: false,
    paidAt: null,
    paymentMethod: "",
    personalIncomeTax: "0",
    provider: null,
    providerInvoiceCenters: [],
    providerInvoiceItems: [],
    title: "",
    vat: "0",
  },
  id: "",
  inputError: {
    amount: false,
    amountCenters: false,
    centerId: false,
    concept: false,
    createdBy: false,
    dateInvoice: false,
    description: false,
    dueDate: false,
    expenseTypeId: false,
    filePath: false,
    invoiceNumber: false,
    paidAt: false,
    personalIncomeTax: false,
    provider: false,
    title: false,
    vat: false,
  },
  newProviderInvoiceItem: {
    amount: "",
    concept: "",
    vatPercentage: "",
  },
  paymentMethods: [],
  providers: [],
  providerInvoiceCenters: {
    centerIds: [],
    amountCenters: "",
  },
  saved: true,
  assetManagementSupply: {
    meterId: "",
    centerId: null,
    consumption: "",
    type: SUPPLY_TYPES.WATER,
    dateFrom: "",
    dateTo: "",
  },
  supplyCenterDisabled: false,
  users: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "RESET_CENTER_INPUT":
      return {
        ...state,
        providerInvoiceCenters: initialState.providerInvoiceCenters,
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "RESET_ITEM_INPUT":
      return {
        ...state,
        newProviderInvoiceItem: initialState.newProviderInvoiceItem,
      };
    case "SET_AUTHORIZATION_NUMBER":
      return {
        ...state,
        authorizations: {
          ...state.authorizations,
          number: action.payload,
        },
      };
    case "SET_AUTHORIZATION_REQUESTED_USERS":
      return {
        ...state,
        authorizations: {
          ...state.authorizations,
          requestedUsers: action.payload,
        },
      };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "SET_FILE":
      return {
        ...state,
        form: {
          ...state.form,
          file: action.payload.files,
        },
      };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_ITEM_INPUT":
      return {
        ...state,
        newProviderInvoiceItem: {
          ...state.newProviderInvoiceItem,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_SUPPLY_INPUT":
      return {
        ...state,
        assetManagementSupply: {
          ...state.assetManagementSupply,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_PROVIDERS":
      return { ...state, providers: action.payload };
    case "SET_PROVIDER_INVOICE_CENTERS_INPUT":
      return {
        ...state,
        providerInvoiceCenters: {
          ...state.providerInvoiceCenters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_SAVED_VALUE":
      return { ...state, saved: action.payload.value };
    case "SET_USERS":
      return { ...state, users: action.payload };
    case "TOGGLE_SUPPLY_CENTER_DISABLED":
      return {
        ...state,
        supplyCenterDisabled: !state.supplyCenterDisabled,
      };
    default:
      throw new Error("Action not found in reducer.");
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CreateProviderInvoicePage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [t] = useTranslation("providerInvoices");
  const [tErrors] = useTranslation("errors");

  const [idInvoiceItem, setIdInvoiceItem] = useState(0);

  //Get parameters filters:
  const query = useQuery();
  const providerId = query.get("providerId");

  const [state, dispatch] = useReducer(reducer, initialState);

  const ANY_USER = { id: null, name: "" };
  const MIN_AUTHORIZATION_NUMBER = 1;
  const MAX_AUTHORIZATION_NUMBER = 6;

  const PROVIDER_INVOICE_COLUMNS = [
    {
      field: "concept",
      headerName: t("concept"),
      editable: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: t("amount"),
      editable: true,
      flex: 1,
      minWidth: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "vatPercentage",
      headerName: t("vatPercentage"),
      editable: true,
      flex: 1,
      minWidth: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "%",
    },
    {
      field: "vat",
      headerName: t("vat"),
      flex: 1,
      minWidth: 100,
      valueGetter: ({ row }) =>
        localeFormat((Number(row.amount) * Number(row.vatPercentage)) / 100) +
        "€",
    },
    {
      field: "total",
      headerName: t("total"),
      flex: 1,
      minWidth: 100,
      valueGetter: ({ row }) =>
        localeFormat(
          Number(row.amount) +
            (Number(row.amount) * Number(row.vatPercentage)) / 100
        ) + "€",
    },
    {
      field: "actions",
      headerName: t("actions"),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const onEdit = (e) => {
          e.stopPropagation();
          editProviderInvoiceItem(params.row);
        };
        const onDelete = (e) => {
          e.stopPropagation();
          deleteProviderInvoiceItem(params.row.id);
        };
        return (
          <Grid item container spacing={1}>
            <Grid item>
              <Tooltip title={t("confirmEdit")} placement="top">
                <IconButton size="small" color="primary" onClick={onEdit}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={t("delete")} placement="top">
                <IconButton size="small" color="error" onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const PROVIDER_INVOICE_CENTER_COLUMNS = [
    {
      field: "center",
      headerName: t("center"),
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) =>
        state.centers.find((center) => center.id === Number(row.centerId)).name,
    },
    {
      field: "amount",
      headerName: t("amount"),
      editable: true,
      flex: 1,
      minWidth: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "actions",
      headerName: t("actions"),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const onEdit = (e) => {
          e.stopPropagation();
          editProviderInvoiceCenter(params.row);
        };
        const onDelete = (e) => {
          e.stopPropagation();
          deleteProviderInvoiceCenter(params.row.id);
        };
        return (
          <Grid item container spacing={1}>
            <Grid item>
              <Tooltip title={t("confirmEdit")} placement="top">
                <IconButton size="small" color="primary" onClick={onEdit}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={t("delete")} placement="top">
                <IconButton size="small" color="error" onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const ITEMS_SUMMARY = [
    {
      translatedText: t("numberOfRows"),
      value: state.form.providerInvoiceCenters.length,
    },
    {
      translatedText: t("invoiceBaseAmount"),
      value: localeFormat(state.form.amount) + "€",
    },
    {
      translatedText: t("itemsAmount"),
      value:
        localeFormat(
          state.form.providerInvoiceCenters.reduce(
            (acc, item) => acc + Number(item.amount),
            0
          )
        ) + "€",
    },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("createProviderInvoice");

    getCenters();
    getPaymentMethods();
    getProviders();
    getUsers();
  }, []);

  //Set parameter providerId:
  useEffect(() => {
    if (providerId)
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "provider",
          value: state.providers.find(
            (provider) => provider.id === Number(providerId)
          ),
        },
      });
  }, [state.providers]);

  useEffect(() => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "paymentMethod",
        value: state.form.provider?.defaultPaymentMethodId ?? "",
      },
    });
  }, [state.form.provider]);

  /* API CALLS */

  const checkDuplicateFields = () => {
    // Returns a promise that resolves to true if the title or invoice number already exists
    return api
      .get("/provider-invoices", {
        params: { provider: state.form.provider.id },
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const exists = response.data.some(
            (invoice) =>
              invoice.title === state.form.title ||
              invoice.invoiceNumber === state.form.invoiceNumber
          );
          return exists;
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noCenters"), { variant: "warning" });
          }
          dispatch({
            type: "SET_CENTERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/providers/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length == 0) {
            enqueueSnackbar(t("noPaymentMethods"), { variant: "warning" });
          }
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getProviders = () => {
    api
      .get("/providers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noProviders"), { variant: "warning" });
          }
          dispatch({
            type: "SET_PROVIDERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getUsers = () => {
    const params = {
      excludeRoleIds: [CUSTOMER_ROLE_ID, CENTER_ROLE_ID],
    };

    api
      .get("/users", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_USERS",
            payload: [...response.data, ANY_USER],
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getSupplyCenter = (meterId) => {
    api.get(`/asset-management-supply-meter/${meterId}`).then((response) => {
      const centerId = response?.data?.centerId?.toString();
      if (centerId) {
        handleSupplyInputChange({
          target: { name: "centerId", value: response.data.centerId },
        });
        dispatch({ type: "TOGGLE_SUPPLY_CENTER_DISABLED" });
      }
    });
  };

  const submitForm = () => {
    let data = { createdBy: user.id };
    state.form.invoiceNumber !== "" &&
      (data.invoiceNumber = state.form.invoiceNumber);
    state.form.title !== "" && (data.title = state.form.title);
    state.form.description !== "" &&
      (data.description = state.form.description);
    state.form.amount !== "" && (data.amount = state.form.amount);
    state.form.vat !== "" && (data.vat = state.form.vat);
    state.form.personalIncomeTax !== "" &&
      (data.personalIncomeTax = state.form.personalIncomeTax);
    state.form.filePath !== "" && (data.filePath = state.form.filePath);
    state.form.paidAt !== null && (data.paidAt = state.form.paidAt);
    state.form.provider !== null && (data.providerId = state.form.provider.id);
    state.form.centerId !== "" && (data.centerId = state.form.centerId);
    state.form.expenseTypeId !== "" &&
      (data.expenseTypeId = state.form.expenseTypeId);
    state.form.dateInvoice !== "" && (data.date = state.form.dateInvoice);
    state.form.paymentMethod !== "" &&
      (data.paymentMethodId = state.form.paymentMethod);
    state.form.providerInvoiceItems?.length !== 0 &&
      (data.items = state.form.providerInvoiceItems.map((item) => ({
        amount: Number(item.amount),
        concept: item.concept,
        vatPercentage: Number(item.vatPercentage),
      })));
    data.isTicket = state.form.isTicket;
    state.form.dueDate !== "" && (data.dueDate = state.form.dueDate);
    state.form.providerInvoiceCenters.length !== 0 &&
      (data.CenterProviderInvoices = state.form.providerInvoiceCenters.map(
        (providerInvoiceCenter) => ({
          centerId: providerInvoiceCenter.centerId,
          amount: providerInvoiceCenter.amount,
        })
      ));
    state.authorizations.number &&
      state.authorizations.requestedUsers.length > 0 &&
      (data.ProviderInvoiceAuthorizations =
        state.authorizations.requestedUsers.map((requestedUser) => ({
          authorizerId: requestedUser?.id || null,
        })));

    dispatch({ type: "SET_SAVED_VALUE", payload: { value: false } });
    api
      .post("/provider-invoices/create", data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (
            state.form.expenseTypeId === WATER_EXPENSE_TYPE_ID &&
            state.assetManagementSupply.meterId
          ) {
            createAssetManagementSupply(response.data.id);
          } else {
            enqueueSnackbar(t("providerInvoiceCreateSuccess"), {
              variant: "success",
            });
            history.replace("/app/provider-invoice/" + response.data.id);
          }
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() =>
        dispatch({ type: "SET_SAVED_VALUE", payload: { value: true } })
      );
  };

  const createAssetManagementSupply = (providerInvoiceId) => {
    const { meterId, centerId, consumption, type, dateFrom, dateTo } =
      state.assetManagementSupply;
    if (!meterId || !centerId || !consumption || !type || !dateFrom || !dateTo)
      return enqueueSnackbar(
        t("allFieldsAreRequiredToCreateAssetManagementSupply"),
        { variant: "error" }
      );
    if (new Date(dateFrom) > new Date(dateTo))
      return enqueueSnackbar(t("dateFromMustBeLessThanDateTo"), {
        variant: "error",
      });
    const data = {
      providerInvoiceId,
      meterId,
      consumption,
      centerId,
      type,
      dateFrom,
      dateTo,
    };
    api
      .post("/asset-management-supplies/create", data)
      .then((response) => {
        console.log(response);
        enqueueSnackbar(t("providerInvoiceCreateSuccess"), {
          variant: "success",
        });
        history.replace("/app/provider-invoice/" + providerInvoiceId);
      })
      .catch((error) => console.log(error));
  };

  /* HANDLERS */

  const createInvoice = () => {
    if (validateForm()) {
      checkDuplicateFields().then((exists) => {
        if (exists) {
          openConfirmDuplicateTitle();
        } else {
          submitForm();
        }
      });
    }
  };

  const createProviderInvoiceCenters = () => {
    if (validateProviderInvoiceCenters(state.providerInvoiceCenters)) {
      const { amountCenters, centerIds } = state.providerInvoiceCenters;
      // Calculate the amount per center, rounding downward to 2 decimals
      const amountPerCenter =
        Math.floor((amountCenters / centerIds.length) * 100) / 100;

      // Get id of last item in array (for datagrid)
      let maxId = Math.max(
        ...state.form.providerInvoiceCenters.map((item) => item.id)
      );
      if (maxId === -Infinity) {
        maxId = 0;
      }
      const newProviderInvoiceCenters = centerIds.map((centerId) => {
        maxId++;
        return {
          id: maxId,
          amount: amountPerCenter,
          centerId: centerId,
        };
      });
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "providerInvoiceCenters",
          value: state.form.providerInvoiceCenters.concat(
            newProviderInvoiceCenters
          ),
        },
      });

      enqueueSnackbar(
        centerIds.length <= 0
          ? t("providerInvoiceCenterAdded")
          : t("providerInvoiceCentersAdded"),
        { variant: "success" }
      );
      dispatch({ type: "RESET_CENTER_INPUT" });
    }
  };

  const deleteProviderInvoiceCenter = (providerInvoiceCenterId) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "providerInvoiceCenters",
        value: state.form.providerInvoiceCenters.filter(
          (element) => element.id != providerInvoiceCenterId
        ),
      },
    });
    enqueueSnackbar(t("providerInvoiceCenterRemoved"), { variant: "success" });
  };

  const createProviderInvoiceItem = () => {
    state.newProviderInvoiceItem.id = idInvoiceItem;
    if (validateProviderInvoiceItem(state.newProviderInvoiceItem)) {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "providerInvoiceItems",
          value: [
            ...state.form.providerInvoiceItems,
            state.newProviderInvoiceItem,
          ],
        },
      });

      enqueueSnackbar(t("providerInvoiceItemAdded"), { variant: "success" });
      dispatch({ type: "RESET_ITEM_INPUT" });
      setIdInvoiceItem(idInvoiceItem + 1);
    }
  };

  const deleteProviderInvoiceItem = (providerInvoiceId) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "providerInvoiceItems",
        value: state.form.providerInvoiceItems.filter(
          (item) => item.id != providerInvoiceId
        ),
      },
    });
    enqueueSnackbar(t("providerInvoiceItemRemoved"), { variant: "success" });
  };

  const editProviderInvoiceCenter = (providerInvoiceCenter) => {
    let editedProviderInvoiceCenter = state.form.providerInvoiceCenters.filter(
      (element) => element.id !== providerInvoiceCenter.id
    );

    if (Number(providerInvoiceCenter.amount)) {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "providerInvoiceCenters",
          value: [...editedProviderInvoiceCenter, providerInvoiceCenter],
        },
      });
      enqueueSnackbar(t("providerInvoiceCenterEdited"), { variant: "success" });
    } else {
      enqueueSnackbar(t("amountIsNotValid"), { variant: "warning" });
    }
  };

  const editProviderInvoiceItem = (providerInvoice) => {
    let editedItem = state.form.providerInvoiceItems.filter(
      (item) => item.id !== providerInvoice.id
    );

    if (validateProviderInvoiceItem(providerInvoice)) {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "providerInvoiceItems",
          value: [...editedItem, providerInvoice],
        },
      });
      enqueueSnackbar(t("providerInvoiceItemEdited"), { variant: "success" });
    }
  };

  const handleAuthorizationNumberChange = (e) => {
    const newNumber = e.target.value;
    dispatch({
      type: "SET_AUTHORIZATION_NUMBER",
      payload: newNumber,
    });

    if (
      newNumber < MIN_AUTHORIZATION_NUMBER ||
      newNumber > MAX_AUTHORIZATION_NUMBER
    ) {
      return;
    }
    const newRequestedUsers = Array.from({ length: newNumber }).map(
      (_, index) => {
        return state.authorizations.requestedUsers[index] || ANY_USER;
      }
    );

    dispatch({
      type: "SET_AUTHORIZATION_REQUESTED_USERS",
      payload: newRequestedUsers,
    });
  };

  const handleAuthorizationRequestedUsersChange = (e, user, index) => {
    const newRequestedUsers = state.authorizations.requestedUsers.map(
      (currentUser, currentIndex) =>
        currentIndex === index ? user : currentUser
    );
    dispatch({
      type: "SET_AUTHORIZATION_REQUESTED_USERS",
      payload: newRequestedUsers,
    });
  };

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleItemInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_ITEM_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleSupplyInputChange = (e) => {
    if (e.target.name === "meterId" && state.supplyCenterDisabled)
      dispatch({ type: "TOGGLE_SUPPLY_CENTER_DISABLED" });
    dispatch({
      type: "SET_SUPPLY_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleProviderInvoiceCentersChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_PROVIDER_INVOICE_CENTERS_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const openConfirmDuplicateTitle = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("warningProviderInvoiceDuplicate"),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && submitForm();
          resetConfirmDialog();
        },
      },
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  /* VALIDATIONS */

  const validateForm = () => {
    let isValid = true;

    let fields = [
      "title",
      "invoiceNumber",
      "dateInvoice",
      "amount",
      "vat",
      "personalIncomeTax",
      "provider",
    ];

    fields.forEach((field) => {
      if (state.form[field] === "" || state.form[field] === null) {
        setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("isRequired"), {
          variant: "error",
        });
        isValid = false;
      }
    });

    if (
      state.authorizations.number < MIN_AUTHORIZATION_NUMBER ||
      state.authorizations.number > MAX_AUTHORIZATION_NUMBER
    ) {
      enqueueSnackbar(
        t("authorizationNumberValidation", {
          min: MIN_AUTHORIZATION_NUMBER,
          max: MAX_AUTHORIZATION_NUMBER,
        }),
        { variant: "error" }
      );
      isValid = false;
    }

    return isValid;
  };

  const validateProviderInvoiceCenters = (providerInvoiceCenters) => {
    if (providerInvoiceCenters.centerIds.length === 0) {
      enqueueSnackbar(t("centerIsRequired"), { variant: "warning" });
      return false;
    }
    if (providerInvoiceCenters.amountCenters === "") {
      setInputErrorTrue("amountCenters");
      return false;
    }

    // Validate that the center is not already selected
    const isDuplicateCenterId = state.form.providerInvoiceCenters?.some(
      (item) => providerInvoiceCenters.centerIds.includes(item.centerId)
    );
    if (isDuplicateCenterId) {
      enqueueSnackbar(t("centerAlreadySelected"), { variant: "warning" });
      return false;
    }

    return true;
  };

  const validateProviderInvoiceItem = (item) => {
    if (item.concept.length === 0) {
      setInputErrorTrue("concept");
      return false;
    }
    return true;
  };

  /* HELPER FUNCTIONS */
  const calculateTotal = () => {
    return Number(
      Number(state.form.amount) +
        Number(state.form.vat) -
        Number(state.form.personalIncomeTax)
    );
  };

  const totalAmountIsRight = (elements, total) => {
    let sum = 0;
    elements.forEach((element) => {
      sum += Number(element.amount);
    });
    let min = sum - 0.01 * elements.length;
    let max = sum + 0.01 * elements.length;
    return total >= min && total <= max;
  };

  return (
    <Container maxWidth="md" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12}>
            <Typography variant="h4">{t("createProviderInvoice")}</Typography>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  error={state.inputError.title}
                  helperText={
                    state.inputError.title
                      ? t("title") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("title")}
                  name="title"
                  onChange={handleInputChange}
                  value={state.form.title}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  error={state.inputError.invoiceNumber}
                  helperText={
                    state.inputError.invoiceNumber
                      ? t("invoiceNumber") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("invoiceNumber")}
                  name="invoiceNumber"
                  onChange={handleInputChange}
                  value={state.form.invoiceNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomDate
                  error={state.inputError.dateInvoice}
                  helperText={
                    state.inputError.dateInvoice
                      ? t("dateInvoice") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("dateInvoice")}
                  name="dateInvoice"
                  value={state.form.dateInvoice}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomDate
                  error={state.inputError.dueDate}
                  helperText={
                    state.inputError.dueDate
                      ? t("dueDate") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("dueDate")}
                  name="dueDate"
                  value={state.form.dueDate}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  error={state.inputError.description}
                  helperText={
                    state.inputError.description
                      ? t("description") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("description")}
                  multiline
                  rows={4}
                  name="description"
                  onChange={handleInputChange}
                  value={state.form.description}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  size="small"
                  xs={{ minWidth: "210px" }}
                  value={state.form.provider}
                  name="provider"
                  onChange={(e, provider) => {
                    setInputErrorFalse("provider");
                    dispatch({
                      type: "SET_INPUT",
                      payload: {
                        inputname: "provider",
                        value: provider,
                      },
                    });
                  }}
                  options={state.providers.sort((a, b) =>
                    a.brand.localeCompare(b.brand)
                  )}
                  getOptionDisabled={(provider) => provider.blocked === true}
                  getOptionLabel={(provider) => provider.brand}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      label={t("provider")}
                      error={state.inputError.provider}
                      helperText={
                        state.inputError.provider
                          ? t("provider") + " " + t("mustNotBeBlank")
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  label={t("paymentMethod")}
                  value={state.form.paymentMethod}
                  onChange={handleInputChange}
                  name="paymentMethod"
                  options={[
                    { value: "", label: "-" },
                    ...state.paymentMethods.map((method) => ({
                      label: t(method.name),
                      value: method.id,
                    })),
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ExpenseTypeSelect
                  label={t("expenseType")}
                  value={state.form.expenseTypeId}
                  onChange={handleInputChange}
                  name="expenseTypeId"
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2} alignItems="center">
              <Grid item xs={6} sm={2}>
                <TextInput
                  error={state.inputError.amount}
                  helperText={
                    state.inputError.amount
                      ? t("amount") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  type="number"
                  label={t("amount")}
                  name="amount"
                  onChange={(e) => {
                    handleInputChange(e);
                    handleProviderInvoiceCentersChange({
                      target: {
                        name: "amountCenters",
                        value: e.target.value,
                      },
                    });
                  }}
                  value={state.form.amount}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextInput
                  error={state.inputError.vat}
                  helperText={
                    state.inputError.vat
                      ? t("vat") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("vat")}
                  type="number"
                  name="vat"
                  onChange={handleInputChange}
                  value={state.form.vat}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextInput
                  error={state.inputError.personalIncomeTax}
                  helperText={
                    state.inputError.personalIncomeTax
                      ? t("personalIncomeTax") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("personalIncomeTax")}
                  type="number"
                  name="personalIncomeTax"
                  onChange={handleInputChange}
                  value={state.form.personalIncomeTax}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <Typography variant="body1" fontWeight="bold">
                  {t("total") + ": "}
                  <Typography variant="body1" component="span">
                    {localeFormat(calculateTotal())}€
                  </Typography>
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  label={t("isTicket")}
                  labelPlacement="end"
                  control={
                    <Switch
                      onChange={(e) => {
                        handleInputChange({
                          target: { name: "isTicket", value: e.target.checked },
                        });
                      }}
                      name="isTicket"
                      value={state.form.isTicket}
                    />
                  }
                />
              </Grid>

              <Grid container item flexDirection="row" spacing={2} xs={12}>
                <Grid item>
                  <FormControlLabel
                    label={t("paid")}
                    control={
                      <Switch
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            handleInputChange({
                              target: {
                                name: "paidAt",
                                value: new Date().toISOString().slice(0, 10),
                              },
                            });
                          } else {
                            handleInputChange({
                              target: { name: "paidAt", value: null },
                            });
                          }
                        }}
                        name="paidAt"
                        value={state.form.paidAt}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                  />
                </Grid>

                <Grid item>
                  {state.form.paidAt !== null && (
                    <TextInput
                      error={state.inputError.paidAt}
                      helperText={
                        state.inputError.paidAt
                          ? t("paidAt") + " " + t("mustNotBeBlank")
                          : ""
                      }
                      label={t("paidAt")}
                      type="date"
                      variant="outlined"
                      name="paidAt"
                      onChange={handleInputChange}
                      value={state.form.paidAt}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Center provider invoices */}

            <Grid item container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6">{t("breakdownByCenter")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Collapse
                  in={
                    !totalAmountIsRight(
                      state.form.providerInvoiceCenters,
                      state.form.amount
                    )
                  }
                >
                  <Alert severity="warning">
                    {t("sumOfCentersAmountsNotEqualToTotalAmount")}
                  </Alert>
                </Collapse>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CenterSelect
                  multiple
                  name="centerIds"
                  value={state.providerInvoiceCenters.centerIds}
                  onChange={handleProviderInvoiceCentersChange}
                  autoWidth
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextInput
                  error={state.inputError.amountCenters}
                  helperText={
                    state.inputError.amountCenters
                      ? t("amount") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("totalAmount")}
                  type="number"
                  name="amountCenters"
                  value={state.providerInvoiceCenters.amountCenters}
                  onChange={handleProviderInvoiceCentersChange}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={createProviderInvoiceCenters}
                >
                  {state.providerInvoiceCenters.centerIds.length <= 1
                    ? t("addCenter")
                    : t("addCenters")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <ItemsSummary gridItems={ITEMS_SUMMARY} />
              </Grid>
              <Grid item xs={12}>
                <Box style={{ height: 300, width: "100%" }}>
                  <DataGrid
                    rows={state.form.providerInvoiceCenters}
                    columns={PROVIDER_INVOICE_CENTER_COLUMNS}
                    density="compact"
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6">{t("authorizations")}</Typography>
              </Grid>

              <Grid
                container
                item
                xs={12}
                spacing={2}
                flexDirection="row"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="body1">{t("request")}</Typography>
                </Grid>
                <Grid item xs={3} md={1.5}>
                  <TextInput
                    type="number"
                    value={state.authorizations.number}
                    onChange={handleAuthorizationNumberChange}
                    inputProps={{
                      style: { textAlign: "center" },
                      min: MIN_AUTHORIZATION_NUMBER,
                      max: MAX_AUTHORIZATION_NUMBER,
                    }}
                    error={
                      state.authorizations.number < MIN_AUTHORIZATION_NUMBER ||
                      state.authorizations.number > MAX_AUTHORIZATION_NUMBER
                    }
                  />
                </Grid>
                <Grid item flexGrow={1}>
                  <Typography variant="body1">
                    {t("authorization", {
                      count: Number(state.authorizations.number),
                    }).toLowerCase() +
                      " " +
                      t("from").toLowerCase() +
                      ": "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                {state.authorizations.requestedUsers.map(
                  (requestedUser, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Autocomplete
                        size="small"
                        value={requestedUser}
                        name="authorizationRequestedUsers"
                        onChange={(e, user) => {
                          handleAuthorizationRequestedUsersChange(
                            e,
                            user,
                            index
                          );
                        }}
                        options={state.users.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        )}
                        getOptionLabel={(user) => user.name || t("anyone")}
                        getOptionDisabled={(user) =>
                          user !== ANY_USER &&
                          state.authorizations.requestedUsers.includes(user)
                        }
                        renderInput={(params) => (
                          <TextInput {...params} label={t("user")} />
                        )}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t("providerInvoiceItems")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  error={state.inputError.concept}
                  helperText={
                    state.inputError.concept
                      ? t("concept") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("concept")}
                  name="concept"
                  onChange={handleItemInputChange}
                  value={state.newProviderInvoiceItem.concept}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <TextInput
                  type="number"
                  label={t("amount")}
                  name="amount"
                  onChange={handleItemInputChange}
                  value={state.newProviderInvoiceItem.amount}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <TextInput
                  type="number"
                  label={t("vatPercentage")}
                  name="vatPercentage"
                  onChange={handleItemInputChange}
                  value={state.newProviderInvoiceItem.vatPercentage}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={createProviderInvoiceItem}
                >
                  {t("addItem")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box style={{ height: 300, width: "100%" }}>
                  <DataGrid
                    rows={state.form.providerInvoiceItems}
                    columns={PROVIDER_INVOICE_COLUMNS}
                    density="compact"
                  />
                </Box>
              </Grid>

              {/* Provider invoice items totals summary */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={1} justifyContent="space-around">
                      <Grid item>
                        <Typography>
                          {t("totalAmount")}:
                          <Typography
                            marginLeft={1}
                            variant="h6"
                            component="span"
                            fontWeight={600}
                          >
                            {localeFormat(
                              state.form.providerInvoiceItems.reduce(
                                (total, cur) => (total += Number(cur.amount)),
                                0
                              )
                            ) + "€"}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {t("totalVat")}:
                          <Typography
                            marginLeft={1}
                            variant="h6"
                            component="span"
                            fontWeight={600}
                          >
                            {localeFormat(
                              state.form.providerInvoiceItems.reduce(
                                (total, cur) =>
                                  (total +=
                                    (Number(cur.amount) *
                                      Number(cur.vatPercentage)) /
                                    100),
                                0
                              )
                            ) + "€"}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {t("total")}:
                          <Typography
                            marginLeft={1}
                            variant="h6"
                            component="span"
                            fontWeight={600}
                          >
                            {localeFormat(
                              state.form.providerInvoiceItems.reduce(
                                (total, cur) =>
                                  (total +=
                                    Number(cur.amount) +
                                    (Number(cur.amount) *
                                      Number(cur.vatPercentage)) /
                                      100),
                                0
                              )
                            ) + "€"}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {state.form.expenseTypeId === WATER_EXPENSE_TYPE_ID && (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        {t("supplyManagement")}
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <TextInput
                          label={t("meter")}
                          name="meterId"
                          inputProps={{
                            onBlur: (e) => getSupplyCenter(e.target.value),
                          }}
                          onChange={handleSupplyInputChange}
                          value={state.assetManagementSupply.meterId}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CenterSelect
                          value={state.assetManagementSupply.centerId}
                          onChange={handleSupplyInputChange}
                          autoWidth
                          name="centerId"
                          disabled={state.supplyCenterDisabled}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput
                          label={t("consumption")}
                          name="consumption"
                          onChange={handleSupplyInputChange}
                          value={state.assetManagementSupply.consumption}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Select
                          label={t("type")}
                          name="type"
                          options={[
                            { value: SUPPLY_TYPES.WATER, label: t("water") },
                          ]}
                          value={state.assetManagementSupply.type}
                          autoWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDate
                          label={t("dateFrom")}
                          name="dateFrom"
                          value={state.assetManagementSupply.dateFrom}
                          onChange={handleSupplyInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDate
                          label={t("dateTo")}
                          name="dateTo"
                          value={state.assetManagementSupply.dateTo}
                          onChange={handleSupplyInputChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("cancel")}</Button>
            </Grid>
            <Grid item>
              <CustomButton
                color="success"
                loading={!state.saved}
                onClick={createInvoice}
              >
                {t("create")}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>

        <ConfirmDialog
          title={state.confirmDialog.title}
          open={state.confirmDialog.isOpen}
          setOpen={setConfirmDialogState}
          onConfirm={state.confirmDialog.callback}
        >
          <Typography variant="body2" color="initial">
            {t("the")} {t("title").toLowerCase()} ({state.form.title}){" "}
            {t("and/or").toLowerCase()} {t("the")}{" "}
            {t("invoiceNumber").toLowerCase()} ({state.form.invoiceNumber}){" "}
            {t("alreadyExist").toLowerCase()}{" "}
            {t("forThisProvider").toLowerCase()}. {t("createAnyway")}?
          </Typography>
        </ConfirmDialog>
      </Paper>
    </Container>
  );
}
