import i18n from "../../utils/i18n";
import ReactFlagsSelect from "react-flags-select";
import { useEffect, useState } from "react";

const LanguageSelect = ({ defaultValue = "ES", onChange, value }) => {
  const languages = {
    US: "en",
    ES: "es",
    //PT: "pt",
  };

  const [language, setLanguage] = useState(defaultValue);

  useEffect(()=>{
    i18n.changeLanguage(languages[value]);
  }, [value]);

  const changeLanguage = (value) => {
    i18n.changeLanguage(languages[value]);
    setLanguage(value);
    if (onChange) onChange(value);
  };

  return (
    <ReactFlagsSelect
      selected={value || language}
      onSelect={changeLanguage}
      countries={Object.keys(languages)}
      showSelectedLabel={false}
      showOptionLabel={false}
    />
  );
};

export default LanguageSelect;
