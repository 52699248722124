import { Button, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextInput from "../../Inputs/TextInput";

export default function BankAccountForm({ form, onChange, submitForm }) {
  const [t] = useTranslation("settings");

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item>
          <TextInput
            label={t("name")}
            value={form.name}
            name="name"
            onChange={onChange}
          />
        </Grid>
        <Grid item>
          <TextInput
            label={t("iban")}
            value={form.iban}
            name="iban"
            onChange={onChange}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={submitForm}>
            {t("save")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
