import { Box, Button } from "@mui/material";
import axios from "axios";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { useRef, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";

// Import Mapbox token
mapboxgl.accessToken =
  "pk.eyJ1IjoiZ25vbWJvIiwiYSI6ImNrNGszYXlxZjBjdGozanFpOGFpZno0bWEifQ.Gchc2G72ohn5xofBIq5agg";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_MAP":
      const marker = new mapboxgl.Marker()
        .setLngLat(action.payload.coords)
        .addTo(action.payload.map);
      return {
        ...state,
        map: { ...state.map, current: action.payload.map, markers: [marker] },
      };
    case "SET_MARKERS":
      return { ...state, map: { ...state.map, markers: action.payload } };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

export default function Map(props) {
  const { address, lat, lng, setCoordinates } = props;
  const [t] = useTranslation("acquisitions");

  const initialState = {
    map: useRef(null),
    mapContainer: useRef(null),
    zoom: 9,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setMap();
    return () => {
      if (state.map.current) {
        state.map.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (state.map.current) setMap();
  }, [lat, lng, state.map.current]);

  const addressToCoordinates = (address) => {
    let lat, lng;

    const route =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      encodeURIComponent(address) +
      ".json" +
      "?access_token=" +
      mapboxgl.accessToken +
      "&autocomplete=true&country=es&language=es";

    axios
      .get(route)
      .then((response) => {
        if (response.data.error) console.log(response.data.error);
        else {
          [lng, lat] = response.data?.features[0]?.center || [0, 0];
          removeAllMarks();

          setMapCenter(lat, lng);
          addMark(lat, lng);
          setCoordinates(lat, lng);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setMap = () => {
    removeAllMarks();

    if (!state.map.current) {
      dispatch({
        type: "SET_MAP",
        payload: {
          map: new mapboxgl.Map({
            container: state.mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: [lat, lng],
            zoom: state.zoom,
          }),
          coords: [lat, lng],
        },
      });
    } else if (lat && lng) {
      setMapCenter(lat, lng);
      addMark(lat, lng);
    }
  };

  const addMark = (lat, lng) => {
    let mark = new mapboxgl.Marker()
      .setLngLat([lng, lat])
      .addTo(state.map.current);

    let markers = state.map.markers;
    markers.push(mark);
    dispatch({ type: "SET_MARKERS", payload: markers });
  };

  const removeAllMarks = () => {
    if (state.map.markers != null) {
      for (let currentMarker of state.map.markers) {
        currentMarker.remove();
      }
    }
  };

  const setMapCenter = (lat, lng) => {
    state.map.current.setCenter([lng, lat]);
  };

  return (
    <Box position="relative">
      <Box width="100%" height={400} {...props} ref={state.mapContainer} />
      <Button
        sx={{ position: "absolute", top: 0, left: "0" }}
        onClick={() => {
          addressToCoordinates(address);
        }}
        variant="contained"
        startIcon={<SearchIcon />}
      >
        {t("searchLocation")}
      </Button>
    </Box>
  );
}
