import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Link from "./Inputs/Link";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const defaultRowsPerPageOptions = [5, 10, 25];
const defaultRowsPerPage = 10;

const attributeGetterFunction = (orderBy, element) => {
  return orderBy.split(".").reduce(function (prev, curr) {
    return prev ? prev[curr] : "";
  }, element);
};

export function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns } = props;
  const createSortHandler = (property) => (event) => {
    if (property.sortType !== "other") {
      //Temp fix for component based values
      onRequestSort(event, property);
    }
  };
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.key}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : "asc"}
              onClick={createSortHandler(headCell)}
            >
              {headCell.label}
              {orderBy === headCell.key ? (
                <span style={{ display: "none" }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
};

export const CustomTable = (props) => {
  const {
    columns,
    containerProps,
    currentPage,
    data,
    linesPerPage,
    onSortCallback,
    onPaginationCallback,
    sort,
    sortBy,
  } = props;

  // Set default options
  const options = { loaded: true, size: "small", ...props.options };

  const [t] = useTranslation("others");

  const [page, setPage] = React.useState(currentPage ?? 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    linesPerPage ?? defaultRowsPerPage
  );

  const [order, setOrder] = React.useState(sort);
  const [orderBy, setOrderBy] = React.useState(sortBy);

  const [numeric, setNumeric] = React.useState(true);

  // Initial useEffect: Sets sort type
  useEffect(() => {
    const column = columns?.find((column) => column.key === sortBy);
    if (column) {
      if (column.sortType === "number") setNumeric(true);
      else setNumeric(false);
    }
  }, []);

  useEffect(() => {
    if (options.rowsPerPage) setRowsPerPage(options.rowsPerPage);
  }, [options]);

  useEffect(() => {
    setPage(currentPage ?? 0);
  }, [data]);

  const handleRequestSort = (event, property) => {
    let isAsc;

    if (property.sortKey)
      isAsc = orderBy === property.sortKey && order === "asc";
    else isAsc = orderBy === property.key && order === "asc";

    setOrder(isAsc ? "desc" : "asc");

    if (property.sortKey) setOrderBy(property.sortKey);
    else setOrderBy(property.key);

    property.sortType === "number" ? setNumeric(true) : setNumeric(false);

    //If callback define, execute
    onSortCallback && onSortCallback(property.key, isAsc ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPaginationCallback && onPaginationCallback(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onPaginationCallback &&
      onPaginationCallback(0, parseInt(event.target.value, 10));
  };

  function stableSort(array, comparator) {
    let stabilizedThis;

    stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy, valueGetter) {
    let aAttr = a[orderBy];
    let bAttr = b[orderBy];

    if (valueGetter !== undefined) {
      aAttr = valueGetter(aAttr, a);
      bAttr = valueGetter(bAttr, b);
    } else {
      if (orderBy?.includes(".")) {
        aAttr = attributeGetterFunction(orderBy, a);
        bAttr = attributeGetterFunction(orderBy, b);
      }
    }

    if (numeric) return bAttr - aAttr;
    else return bAttr?.toLowerCase().localeCompare(aAttr?.toLowerCase());
  }

  function getComparator(order, orderBy, valueGetter) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy, valueGetter)
      : (a, b) => -descendingComparator(a, b, orderBy, valueGetter);
  }

  const getRowsPerPage = () => {
    let options = [...defaultRowsPerPageOptions];
    if (linesPerPage && linesPerPage > 0 && !options.includes(linesPerPage))
      options.push(linesPerPage);
    options = options.sort((a, b) => a - b);
    options.push({ label: "All", value: -1 });
    return options;
  };

  const columnValueGetter = columns.find(
    (column) => column.key === orderBy
  )?.valueGetter;

  return (
    <TableContainer component={Paper} {...containerProps}>
      {/* If loaded is undefined, show table */}
      {options.loaded ? (
        <Table size={options.size}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
          />
          {data?.length > 0 && (
            <TableBody>
              {stableSort(
                data,
                getComparator(order, orderBy, columnValueGetter)
              )
                .slice(
                  page * rowsPerPage,
                  rowsPerPage !== -1
                    ? page * rowsPerPage + rowsPerPage
                    : data.length
                )
                .map((row) => (
                  <TableRow
                    key={row.id}
                    component={options.rowlink && Link}
                    to={
                      options.rowlink &&
                      "/app/" +
                        options.rowlink +
                        "/" +
                        (options.rowlinkOption
                          ? row[options.rowlinkOption].id
                          : row.id)
                    }
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f2f2f2" },
                    }}
                  >
                    {columns.map((column, i) => {
                      let attr = column.key
                        .split(".")
                        .reduce(function (prev, curr) {
                          return prev ? prev[curr] : null;
                        }, row);
                      if (column.valueGetter)
                        attr = column.valueGetter(attr, row);

                      let displayValue = column.renderFunction
                        ? column.renderFunction(attr, row)
                        : attr;

                      return <TableCell key={i}>{displayValue}</TableCell>;
                    })}
                  </TableRow>
                ))}
            </TableBody>
          )}

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={getRowsPerPage()}
                // colSpan={5}
                count={data?.length}
                rowsPerPage={Number(rowsPerPage)}
                labelRowsPerPage={t("rowsPerPage")}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={300}
        >
          <CircularProgress />
        </Box>
      )}
    </TableContainer>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  linesPerPage: PropTypes.number,
};
