import {
  Button,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import AppContext from "../../../context/AppContext";
import { useEffect } from "react";
import { useReducer } from "react";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import MailIcon from "@mui/icons-material/Mail";
import SyncIcon from "@mui/icons-material/Sync";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const initialState = {
  levels: [],
  accessUser: null,
  isAccessUserLoading: false,
  isNewCodeLoading: false,
  isLevelLoading: false,
  isCreateUserLoading: false,
  isSendEmailLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LEVELS":
      return { ...state, levels: action.payload };
    case "SET_ACCESS_USER":
      return { ...state, accessUser: action.payload };
    case "SET_NEW_CODE_LOADING":
      return { ...state, isNewCodeLoading: action.payload };
    case "SET_LEVEL_LOADING":
      return { ...state, isLevelLoading: action.payload };
    case "SET_CREATE_USER_LOADING":
      return { ...state, isCreateUserLoading: action.payload };
    case "SET_SEND_EMAIL_LOADING":
      return { ...state, isSendEmailLoading: action.payload };
    case "SET_GET_ACCESS_USER_LOADING":
      return { ...state, isAccessUserLoading: action.payload };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

const AccessControlDialog = (props) => {
  const { open = false, onClose, customerId } = props;
  const { api } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("accessControl");

  // Initial setup
  useEffect(() => {
    getAccessUser();
    getAccessLevels();
  }, []);

  const getAccessUser = () => {
    if (!customerId) return;

    dispatch({ type: "SET_GET_ACCESS_USER_LOADING", payload: true });

    api
      .get("/access-control/users/" + customerId)
      .then((res) => {
        if (res.data?.error) {
          if (res.data.error === "Customer does not have access user") {
          } else {
            enqueueSnackbar(res.data.error, { variant: "error" });
          }
        } else {
          dispatch({ type: "SET_ACCESS_USER", payload: res.data });
          dispatch({ type: "SET_GET_ACCESS_USER_LOADING", payload: false });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getAccessLevels = () => {
    api
      .get("/access-control/levels")
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_LEVELS", payload: res.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const resetPassword = () => {
    if (!state.accessUser) return;

    dispatch({ type: "SET_NEW_CODE_LOADING", payload: true });

    api
      .post(`/access-control/users/${state.accessUser.pin}/reset-password`)
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_ACCESS_USER", payload: res.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_NEW_CODE_LOADING", payload: false });
      });
  };

  const addLevelToUser = (level) => {
    if (!state.accessUser) return;

    dispatch({ type: "SET_LEVEL_LOADING", payload: true });

    api
      .post(`/access-control/users/${state.accessUser.pin}/add-level`, {
        levelId: level.id,
      })
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_ACCESS_USER", payload: res.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LEVEL_LOADING", payload: false });
      });
  };

  const removeLevelFromUser = (level) => {
    if (!state.accessUser) return;

    dispatch({ type: "SET_LEVEL_LOADING", payload: true });

    api
      .post(`/access-control/users/${state.accessUser.pin}/remove-level`, {
        levelId: level.id,
      })
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_ACCESS_USER", payload: res.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LEVEL_LOADING", payload: false });
      });
  };

  const createAccessUser = () => {
    if (!customerId) {
      enqueueSnackbar(t("customerNotFound"), { variant: "error" });
      return;
    }

    dispatch({ type: "SET_CREATE_USER_LOADING", payload: true });

    api
      .post(`/access-control/create-user/${customerId}`, {})
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
        } else {
          console.log(res.data);
          dispatch({ type: "SET_ACCESS_USER", payload: res.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_CREATE_USER_LOADING", payload: false });
      });
  };

  const sendEmail = () => {
    dispatch({ type: "SET_SEND_EMAIL_LOADING", payload: true });
    api
      .post(`/access-control/send-code-email`, { customerId: customerId })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("emailSentSuccessfully"), { variant: "success" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_SEND_EMAIL_LOADING", payload: false });
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose && onClose(state.accessUser);
      }}
    >
      <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
        {t("accessControl")}
        <IconButton
          onClick={() => {
            onClose && onClose(state.accessUser);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!state.isAccessUserLoading ? (
          <Container>
            {!state.accessUser ? (
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    onClick={createAccessUser}
                    startIcon={
                      state.isCreateUserLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <PersonAddIcon />
                      )
                    }
                    variant="contained"
                    disabled={state.isCreateUserLoading}
                  >
                    {t("createUser")}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <Grid item container xs={12} spacing={1}>
                  <Grid item>
                    <Typography variant="h5">{t("accessId")}:</Typography>
                    <Typography variant="h6">{state.accessUser?.pin}</Typography>
                  </Grid>
                  <Grid
                    item
                    alignItems="center"
                    flex={1}
                    justifyContent="flex-end"
                    display="flex"
                  >
                    <Tooltip title={t("sendEmail")} placement="top">
                      {state.isSendEmailLoading ? (
                        <IconButton disabled>
                          <CircularProgress size={20} />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => sendEmail()}>
                          <MailIcon color="primary" />
                        </IconButton>
                      )}
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="space-between"
                  alignItems="end"
                >
                  <Grid item>
                    <Typography variant="h5">{t("accessCode")}:</Typography>
                    <Typography variant="h6" fontWeight="bold">
                      {state.isNewCodeLoading
                        ? "- - - - - -"
                        : state.accessUser?.personPwd}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Grid item>
                      <Button
                        endIcon={
                          state.isNewCodeLoading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <SyncIcon />
                          )
                        }
                        onClick={resetPassword}
                        disabled={state.isNewCodeLoading}
                      >
                        {t("newCode")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">{t("accessTo")}:</Typography>
                  <List>
                    {state.levels.map((level, index) => (
                      <ListItem key={index}>
                        <ListItemText>{level.name}</ListItemText>
                        <Switch
                          checked={
                            typeof state.accessUser?.accLevelIds === "string" &&
                            state.accessUser.accLevelIds
                              .split(",")
                              .includes(level.id)
                          }
                          onChange={(e) => {
                            e.target.checked === true
                              ? addLevelToUser(level)
                              : removeLevelFromUser(level);
                          }}
                          disabled={state.isLevelLoading}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}
          </Container>
          ) : (
            <CircularProgress size={30} />
          )}
      </DialogContent>
    </Dialog>
  );
};

export default AccessControlDialog;
