import React, { useState, useContext, memo, useCallback } from "react";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TreeItem from "@mui/lab/TreeItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PropTypes from "prop-types";

import AppContext from "../../../context/AppContext";
import fileIcons from "../../../data/fileIcons";

const FileTreeItem = (props) => {
  const { file, onEditFile, onDeleteFile, onViewFile, onDownloadFile } = props;
  const [iconsVisible, setIconsVisible] = useState(false);
  const { user } = useContext(AppContext);

  const handleMouseEnter = useCallback(() => {
    setIconsVisible(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIconsVisible(false);
  }, []);

  const handleViewFile = useCallback(() => {
    onViewFile(file.id);
  }, [onViewFile, file.id]);

  const handleDownloadFile = useCallback(() => {
    onDownloadFile(file.id);
  }, [onDownloadFile, file.id]);

  const handleEditFile = useCallback(() => {
    onEditFile(file.id);
  }, [onEditFile, file.id]);

  const handleDeleteFile = useCallback(() => {
    onDeleteFile(file.id, file.name);
  }, [onDeleteFile, file.id, file.name]);

  return (
    <Paper variant="outlined" sx={{ margin: 1 }}>
      <TreeItem
        {...props}
        icon={
          Object.keys(fileIcons).includes(
            file.name.substring(
              file.name.lastIndexOf(".") + 1,
              file.name.length
            )
          )
            ? fileIcons[
                file.name.substring(
                  file.name.lastIndexOf(".") + 1,
                  file.name.length
                )
              ]()
            : fileIcons.default()
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        label={
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography sx={{ marginY: 1 }}>{file?.name}</Typography>
            </Grid>
            {iconsVisible && (
              <Grid item>
                <IconButton onClick={handleViewFile} size="small">
                  <VisibilityIcon />
                </IconButton>
                <IconButton onClick={handleDownloadFile} size="small">
                  <CloudDownloadIcon />
                </IconButton>
                <IconButton onClick={handleEditFile} size="small">
                  <FontAwesomeIcon icon={faPenSquare} />
                </IconButton>
                {file.createdBy === user.id && (
                  <IconButton onClick={handleDeleteFile} size="small">
                    <DeleteForeverIcon />
                  </IconButton>
                )}
              </Grid>
            )}
          </Grid>
        }
      />
    </Paper>
  );
};

FileTreeItem.propTypes = {
  file: PropTypes.object.isRequired,
  onEditFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onViewFile: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
};

export default memo(FileTreeItem);
