import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import { CallsPanel } from "./CallsPanel";
import { CashFlowPanel } from "./CashFlowPanel";
import ContractReportPanel from "./ContractReportPanel";
import { ContractsPanel } from "./ContractsPanel";
import { CenterReportPanel } from "./CenterReport/CenterReportPanel";
import DashboardTabs from "./DashboardTabs";
import { InvoicingPanel } from "./InvoicingPanel";
import { ProviderInvoicesPanel } from "./ProviderInvoicesPanel";
import { MeterPricePanel } from "./MeterPricePanel";
import OccupancyPanel from "./OccupancyPanel";
import CustomersPanel from "./CustomersPanel";
import { TrackingPanel } from "./TrackingPanel";
import CenterHistoricalPanel from "./CenterHistoricalPanel";
import HistoricalStats from "./HistoricalStats/HistoricalStats";

const Dashboard = () => {
  const [t] = useTranslation("dashboard");

  //Initial useEffect
  useEffect(() => {
    document.title = t("dashboard");
  }, []);

  const PANELS = [
    //{ tabLabel: t("meterPrice"), content: <MeterPricePanel /> },
    { tabLabel: t("cashFlow"), content: <CashFlowPanel /> },
    //{ tabLabel: t("customers"), content: <CustomersPanel /> },
    //{ tabLabel: t("invoicing"), content: <InvoicingPanel /> },
    //{ tabLabel: t("providerInvoices"), content: <ProviderInvoicesPanel /> },
    //{ tabLabel: t("calls"), content: <CallsPanel /> },
    { tabLabel: t("timeMachine"), content: <OccupancyPanel /> },
    //{ tabLabel: t("contracts"), content: <ContractsPanel /> },
    { tabLabel: t("centerReport"), content: <CenterReportPanel /> },
    //{ tabLabel: t("contractReport"), content: <ContractReportPanel /> },
    { tabLabel: t("tracking"), content: <TrackingPanel /> },
    { tabLabel: t("centerHistorical"), content: <CenterHistoricalPanel /> },
    { tabLabel: t("historicalStats"), content: <HistoricalStats />}
  ];

  return <DashboardTabs panels={PANELS} />;
};

export default Dashboard;
