import { useTranslation } from "react-i18next";
import { useEffect } from "react";

// Material UI
import { FormControlLabel, Grid, Checkbox } from "@mui/material";

// Icons
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// Components & Utils
import CityInput from "../../Inputs/CityInput";
import CountryInput from "../../Inputs/CountryInput";
import TextInput from "../../Inputs/TextInput";
import PostalCodeInput from "../../Inputs/PostalCodeInput";
import CustomButton from "../../Inputs/CustomButton";

// Constants
import {
  PASSPORT_ID_TYPE,
  SPAIN_ALPHA2,
  SPAIN_COUNTRY_ID,
} from "../../../data/constants";
import IdTypeSelect from "../../Inputs/IdTypeSelect";

export default function BillingAddressForm({
  address,
  handleInputChange,
  handleSave = undefined,
  loading = false,
}) {
  const [t] = useTranslation("customers");

  useEffect(() => {
    !address.tinCountry &&
      handleInputChange({
        target: {
          name: "tinCountry",
          value: {
            id: SPAIN_COUNTRY_ID,
            name: t("spain"),
            alpha2: SPAIN_ALPHA2,
          },
        },
      });
    !address.residenceCountry &&
      handleInputChange({
        target: {
          name: "residenceCountry",
          value: { id: SPAIN_COUNTRY_ID, name: t("spain") },
        },
      });
  }, []);

  useEffect(() => {
    !address.tinCountry &&
      handleInputChange({
        target: {
          name: "nif",
          value: "",
        },
      });
  }, [address.tinCountry]);

  useEffect(() => {
    if (address.residenceCountry?.id !== SPAIN_COUNTRY_ID) {
      address.postalCode &&
        handleInputChange({
          target: {
            name: "postalCode",
            value: "",
          },
        });
      address.city &&
        handleInputChange({
          target: {
            name: "city",
            value: "",
          },
        });
    }
  }, [address.residenceCountry]);

  const getIdTypeLabel = (idType) => {
    return idType?.toUpperCase() === PASSPORT_ID_TYPE
      ? t(idType)
      : idType?.toUpperCase();
  };

  const addAddressButtonDisabled = () => {
    const {
      name,
      tinCountry,
      residenceCountry,
      address: addressField,
      postalCode,
      city,
      idType,
      nif,
    } = address;

    const isSpainAndMissingDetails =
      residenceCountry?.id === SPAIN_COUNTRY_ID && (!postalCode || !city);
    const isMissingBasicDetails =
      !name ||
      !tinCountry ||
      !residenceCountry ||
      !addressField ||
      !idType ||
      !nif;

    return isSpainAndMissingDetails || isMissingBasicDetails;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextInput
          label={t("name")}
          name="name"
          value={address.name}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CountryInput
          label={t("countryIssuance")}
          name="tinCountry"
          onChange={handleInputChange}
          value={address.tinCountry}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <IdTypeSelect
          idType={address.idType}
          countryAlpha2={address.tinCountry?.alpha2}
          handleInputChange={handleInputChange}
          disabled={!address.tinCountry}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label={getIdTypeLabel(address.idType)}
          name="nif"
          value={address.nif}
          onChange={handleInputChange}
          disabled={!address.tinCountry}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CountryInput
          label={t("residenceCountry")}
          name="residenceCountry"
          onChange={handleInputChange}
          value={address.residenceCountry}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label={t("address")}
          name="address"
          value={address.address}
          onChange={handleInputChange}
        />
      </Grid>
      {address.residenceCountry?.id === SPAIN_COUNTRY_ID && (
        <>
          <Grid item xs={12} sm={4}>
            <PostalCodeInput
              label={t("postalCode")}
              name="postalCode"
              value={address.postalCode}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <CityInput
              label={t("city")}
              value={address.city}
              name="city"
              onChange={handleInputChange}
            />
          </Grid>
        </>
      )}

      <Grid
        container
        item
        xs={12}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={address.isDefault}
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: e.target.name,
                      value: e.target.checked,
                    },
                  })
                }
                name="isDefault"
              />
            }
            label={t("defaultAddress")}
            labelPlacement="start"
          />
        </Grid>
        {handleSave && (
          <Grid item>
            <CustomButton
              color="success"
              fullWidth
              onClick={handleSave}
              startIcon={<AddCircleOutlineIcon />}
              disabled={addAddressButtonDisabled()}
              loading={loading}
            >
              {t("add")}
            </CustomButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
