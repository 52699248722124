import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Grid, IconButton, List, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function BankAccountsList({
  bankAccounts,
  deleteBankAccount,
}) {
  const [t] = useTranslation("settings");

  return (
    <Grid container spacing={3}>
      <Grid item>
        <List component="div">
          {bankAccounts?.map((bankAccount) => (
            <ListItem key={bankAccount.id}>
              {bankAccount.name + " - " + bankAccount.IBAN?.number}
              <IconButton
                onClick={() => deleteBankAccount(bankAccount) }
              >
                <DeleteForeverIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
