// React and npm packages
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMemo, useContext, useEffect, useReducer } from "react";

// Mui components
import { ButtonGroup, Container, Grid, Paper, Typography } from "@mui/material";

// Icons
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

// Context
import AppContext from "../../context/AppContext";

// Utils
import { getParams, generateURL } from "../../utils/url";
import { localeFormat } from "../../utils/format";

// Custom components
import { CustomTable } from "../CustomTable";
import BoxStateChip from "../BoxStateChip";

// Custom inputs
import Page from "../global/structure/Page";
import Filters from "../global/structure/Filters";
import DataCard from "../global/DataCard";
import BoxClusterChip from "../BoxClusterChip";
import ButtonCSV from "../Inputs/ButtonCSV";
import CenterSelect from "../Inputs/CenterSelect";
import ClusterSelect from "../Inputs/ClusterSelect";
import SearchButton from "../Inputs/SearchButton";

// Custom utils
import { getMonthsFromPeriodicityId } from "../../utils/date";

// Constants
import {
  PENDING_CONTRACT_STATE_ID,
  ACTIVE_CONTRACT_STATE_ID,
  BLOCKED_BOX_STATE_ID,
  FREE_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,
  BOOKED_BOX_STATE_ID,
} from "../../data/constants";
import BoxStateFilter from "../Inputs/BoxStateFilter";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function reducer(state, action) {
  switch (action.type) {
    case "SET_BOXES":
      return {
        ...state,
        boxes: action.payload,
      };
    case "SET_CENTER":
      return { ...state, center: action.payload };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_CLUSTERS":
      return {
        ...state,
        clusters: action.payload,
      };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    default:
      throw new Error("Action type unknown in reducer");
  }
}

const initialState = {
  boxes: [],
  center: {},
  centers: [],
  clusters: [],
  filters: {
    centerId: "",
    clusters: [],
    order: "",
    orderBy: "",
    state: "",
  },
  options: {
    loaded: true,
    rowlink: "box",
    rowsPerPage: -1, // Show all
  },
};

export default function OccupancyPage() {
  const { api } = useContext(AppContext);

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const query = useQuery();

  const [t] = useTranslation("occupancy");
  const [tErrors] = useTranslation("errors");

  const FILTERS = [
    { name: "centerId", type: "number" },
    { name: "state", type: "number" },
    { name: "orderBy", type: "string" },
    { name: "order", type: "string" },
  ];

  const OCCUPANCY_COLUMNS = [
    { key: "name", label: t("box"), sortType: "string" },
    {
      key: "customerName",
      label: t("customer"),
      sortType: "string",
      renderFunction: (value, item) => {
        if (item.state === OCCUPIED_BOX_STATE_ID)
          return item.Contracts[item.Contracts.length - 1].Customer.fullName;
        else return "";
      },
    },
    {
      key: "cluster.name",
      label: t("cluster"),
      renderFunction: (value) =>
        value ? <BoxClusterChip name={value} /> : " - ",
    },
    {
      key: "meters",
      label: t("meters"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "m²",
    },
    {
      key: "pricePerMeter",
      label: t("pricePerMeter"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "monthlyPricePerMeter",
      label: t("pricePerMonthWithoutVAT"),
      sortType: "number",
      renderFunction: (value) => (value ? localeFormat(value) + "€" : ""),
    },
    {
      key: "finalPrice",
      label: t("pricePerInstallment"),
      sortType: "number",
      renderFunction: (value, item) => (value ? localeFormat(value) + "€" : ""),
    },
    {
      key: "finalPriceTaxes",
      label: t("pricePerInstallmentWithVAT"),
      sortType: "number",
      renderFunction: (value) => (value ? localeFormat(value) + "€" : ""),
    },
    {
      key: "state",
      label: t("state"),
      sortType: "number",
      renderFunction: (value, item) => (
        <BoxStateChip
          state={value}
          endDate={
            value === OCCUPIED_BOX_STATE_ID
              ? getCurrentBoxContract(item)?.endDate
              : null
          }
        />
      ),
    },
    {
      key: "period",
      label: t("period"),
      sortType: "number",
      renderFunction: (value) => t(value),
    },
    { key: "startDate", label: t("startDate"), sortType: "string" },
    { key: "comments", label: t("comments"), sortType: "string" },
  ];

  const CSV_HEADERS = [
    { key: "name", label: t("box") },
    { key: "customerName", label: t("customer") },
    { key: "meters", label: t("meters") },
    { key: "pricePerMeter", label: t("pricePerMeter") },
    { key: "monthlyPricePerMeter", label: t("monthlyPricePerMeter") },
    { key: "finalPrice", label: t("finalPrice") },
    { key: "finalPriceTaxes", label: t("finalPriceTaxes") },
    { key: "stateName", label: t("state") },
    { key: "period", label: t("period") },
    { key: "startDate", label: t("startDate") },
    { key: "comments", label: t("comments") },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  useEffect(() => {
    document.title = t("occupancyPage");

    getCenters();
    state.filters.centerId !== "" && getBoxes();
  }, []);

  useEffect(() => {
    let clusters = [];
    if (state.filters.centerId !== "") {
      const center = state.centers.find(
        (center) => Number(center.id) === Number(state.filters.centerId)
      );
      clusters = center && center.BoxClusters ? center.BoxClusters : [];
    }
    dispatch({ type: "SET_CLUSTERS", payload: clusters });
  }, [state.filters.centerId]);

  const getBoxes = () => {
    if (!state.filters.centerId) {
      enqueueSnackbar(t("selectACenter"), { variant: "warning" });
      return;
    }
    // Display loading animation while loading data
    dispatch({ type: "SET_LOADED_FALSE" });

    dispatch({ type: "SET_BOXES", payload: [] });

    // Set center for comments
    let center = state.centers.find(
      (center) => center.id == state.filters.centerId
    );
    dispatch({ type: "SET_CENTER", payload: center });

    let params = {
      include: ["Contract", "BoxCluster"],
    };
    params.centers = [state.filters.centerId];
    if (state.filters.state !== "") params.state = state.filters.state;
    if (state.filters.clusters.length) params.clusters = state.filters.clusters;

    api
      .get("/boxes", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else if (response.data.length > 0) {
          response.data.map((box) => {
            const pendingContract = box.Contracts.find(
              (contract) => contract.state === PENDING_CONTRACT_STATE_ID
            );
            /* Check state of box */
            if (Number(box.state) === OCCUPIED_BOX_STATE_ID) {
              const activeContract = box.Contracts.find(
                (contract) => contract.state === ACTIVE_CONTRACT_STATE_ID
              );
              if (activeContract) {
                box.customerName = activeContract.Customer.name;
                box.meters = activeContract.meters;
                box.pricePerMeter = activeContract.pricePerMeter;
                box.monthlyPricePerMeter =
                  activeContract.meters * activeContract.pricePerMeter;
                box.finalPrice =
                  box.monthlyPricePerMeter *
                  getMonthsFromPeriodicityId(activeContract.Periodicity.id);
                box.finalPriceTaxes =
                  box.finalPrice * (1 + activeContract.taxes / 100);
                box.state = activeContract?.state;
                switch (activeContract?.state) {
                  case FREE_BOX_STATE_ID:
                    box.stateName = t("free");
                    break;
                  case OCCUPIED_BOX_STATE_ID:
                    box.stateName = t("occupied");
                    break;
                  case UNAVAILABLE_BOX_STATE_ID:
                    box.stateName = t("unavailable");
                    break;
                  case BOOKED_BOX_STATE_ID:
                    box.stateName = t("booked");
                  case BLOCKED_BOX_STATE_ID:
                    box.stateName = t("blocked");
                  default:
                }
                box.period = activeContract.Periodicity.name;
                box.startDate = activeContract.startDate;
                box.comments = activeContract.comments;
                box.isActive = true;
              }
            } else if (
              Number(box.state) === FREE_BOX_STATE_ID &&
              pendingContract
            ) {
              box.finalPrice = "";
              box.customer = "";
              box.finalPriceTaxes = "";
              box.monthlyPricePerMeter = "";
              box.period = "";
              box.startDate = "";
              box.comments = "";
              box.isActive = true;
              box.state = ACTIVE_CONTRACT_STATE_ID;
              box.stateName = t("occupied");
            } else {
              // Free boxes
              box.finalPrice = "";
              box.customer = "";
              box.finalPriceTaxes = "";
              box.monthlyPricePerMeter = "";
              box.period = "";
              box.startDate = "";
              box.comments = "";
              box.isActive = false;
            }
            return box;
          });
          dispatch({ type: "SET_BOXES", payload: response.data });
        } else {
          enqueueSnackbar(t("noBoxes"), { variant: "info" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  const getCenters = () => {
    const params = { include: ["BoxCluster"] };
    api
      .get("/centers", { params })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length > 0) {
            dispatch({
              type: "SET_CENTERS",
              payload: response.data,
            });
            if (state.filters.centerId !== "") {
              // Set center for comments
              let center = response.data.find(
                (center) => center.id == state.filters.centerId
              );
              dispatch({ type: "SET_CENTER", payload: center });
            }
          } else {
            enqueueSnackbar(t("noCenters"), { variant: "error" });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const search = () => {
    let url = generateURL("/app/occupancy", state.filters);
    history.push(url);
    getBoxes();
  };

  const getCurrentBoxContract = (box) => {
    if (!box || !box.Contracts || !box.Contracts.length) return null;
    for (let contract of box.Contracts) {
      if (!contract.endDate) return contract;
    }
    const today = new Date();
    for (let contract of box.Contracts) {
      if (
        new Date(contract.startDate) <= today &&
        new Date(contract.endDate) >= today
      )
        return contract;
    }
    return null;
  };

  const getClusterOptions = useMemo(() => {
    if (!state.clusters.length) return [];
    return state.clusters.map((c) => ({
      label: t(c.name),
      value: c.name,
    }));
  }, [state.clusters, t]);

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        name: e.target.name,
        value: e.target.value,
      },
    });
  };

  return (
    <Page browserTitle={t("occupancyPage")} title={t("occupancy")}>
      <Grid container spacing={2} marginTop={2}>
        <Grid item>
          <Filters
            filters={[
              <CenterSelect
                value={state.filters.centerId}
                onChange={handleChangeFilter}
                name="centerId"
              />,
              <ClusterSelect
                label={t("clusters")}
                multiple
                name="clusters"
                onChange={handleChangeFilter}
                value={state.filters.clusters}
              />,
              <BoxStateFilter
                state={state.filters.state}
                setState={handleChangeFilter}
              />,
            ]}
          />
        </Grid>

        <Grid item>
          <ButtonGroup variant="contained">
            <ButtonCSV
              data={state.boxes.map((box) => ({
                ...box,
                meters: localeFormat(box.meters),
                pricePerMeter: localeFormat(box.pricePerMeter),
                monthlyPricePerMeter: localeFormat(box.monthlyPricePerMeter),
                finalPrice: localeFormat(box.finalPrice),
                finalPriceTaxes: localeFormat(box.finalPriceTaxes),
                state: t(box.stateName),
                period: t(box.period),
              }))}
              headers={CSV_HEADERS}
              filename={t("occupancy")}
            />
            <SearchButton
              onClick={search}
              loading={!state.options.loaded}
              disabled={!state.filters.centerId}
            />
          </ButtonGroup>
        </Grid>

        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">{t("centerInfo")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="medium">
              {t("emails")}:{" "}
              <Typography variant="body1" component="span">
                {state.center?.CenterEmails?.length > 0
                  ? state.center?.CenterEmails?.map(
                      (email) => email.email
                    ).join("; ")
                  : ""}
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="medium">
              {t("phoneNumbers")}:{" "}
              <Typography variant="body1" component="span">
                {state.center?.CenterPhoneNumbers?.length > 0
                  ? state.center?.CenterPhoneNumbers?.map(
                      (phone) => phone.number
                    ).join("; ")
                  : ""}
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="medium">
              {t("comments")}:
            </Typography>
            <Typography whiteSpace="pre-wrap" variant="body1">
              {state.center?.comments}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <DataCard
              title={t("box")}
              icon={
                <MeetingRoomIcon fontSize="medium" sx={{ marginTop: "4px" }} />
              }
              mainValue={
                (state.boxes.length !== 0
                  ? localeFormat(
                      (state.boxes.filter((box) => box.state === 1).length /
                        state.boxes.length) *
                        100
                    )
                  : 0) + "%"
              }
              body={
                <Grid container item xs={12} spacing={3}>
                  <Grid item>
                    <Typography>
                      {t("total")}:
                      <Typography
                        marginLeft={1}
                        variant="h5"
                        fontWeight={600}
                        component="span"
                        color="blue"
                      >
                        {state.boxes.length}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {t("occupied")}:
                      <Typography
                        marginLeft={1}
                        variant="h5"
                        fontWeight={600}
                        component="span"
                        color="red"
                      >
                        {
                          state.boxes.filter(
                            (box) => box.state === OCCUPIED_BOX_STATE_ID
                          ).length
                        }
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {t("free")}:
                      <Typography
                        marginLeft={1}
                        variant="h5"
                        fontWeight={600}
                        component="span"
                        color="green"
                      >
                        {
                          state.boxes.filter(
                            (box) => box.state === FREE_BOX_STATE_ID
                          ).length
                        }
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </Grid>

          <Grid item xs={4}>
            <DataCard
              title={t("meters")}
              icon={
                <Typography variant="h5" fontWeight={600}>
                  ㎡
                </Typography>
              }
              mainValue={
                (state.boxes.length !== 0
                  ? localeFormat(
                      (state.boxes
                        .filter((box) => box.state === OCCUPIED_BOX_STATE_ID)
                        .reduce((sum, box) => sum + box.meters, 0) /
                        state.boxes.reduce((sum, box) => sum + box.meters, 0)) *
                        100
                    )
                  : 0) + "%"
              }
              body={
                <Grid container item xs={12} spacing={3}>
                  <Grid item>
                    <Typography>
                      {t("total")}:
                      <Typography
                        marginLeft={1}
                        variant="h5"
                        fontWeight={600}
                        component="span"
                        color="blue"
                      >
                        {localeFormat(
                          state.boxes.reduce((sum, box) => sum + box.meters, 0)
                        )}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {t("occupied")}:
                      <Typography
                        marginLeft={1}
                        variant="h5"
                        fontWeight={600}
                        component="span"
                        color="red"
                      >
                        {localeFormat(
                          state.boxes
                            .filter(
                              (box) => box.state === OCCUPIED_BOX_STATE_ID
                            )
                            .reduce((sum, box) => sum + box.meters, 0)
                        )}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {t("free")}:
                      <Typography
                        marginLeft={1}
                        variant="h5"
                        fontWeight={600}
                        component="span"
                        color="green"
                      >
                        {localeFormat(
                          state.boxes
                            .filter((box) => box.state === FREE_BOX_STATE_ID)
                            .reduce((sum, box) => sum + box.meters, 0)
                        )}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </Grid>

          <Grid item xs={4}>
            <DataCard
              title={t("billing")}
              icon={
                <Typography variant="h5" fontWeight={600}>
                  €
                </Typography>
              }
              mainValue={
                (state.boxes.length !== 0
                  ? localeFormat(
                      (state.boxes
                        .filter((box) => box.state === OCCUPIED_BOX_STATE_ID)
                        .reduce(
                          (previousValue, currentValue) =>
                            previousValue +
                            currentValue.meters * currentValue.pricePerMeter,
                          0
                        ) /
                        state.boxes.reduce(
                          (previousValue, currentValue) =>
                            previousValue +
                            currentValue.meters * currentValue.pricePerMeter,
                          0
                        )) *
                        100
                    )
                  : 0) + "%"
              }
              body={
                <Grid container item xs={12} spacing={3}>
                  <Grid item>
                    <Typography>
                      {t("maximum")}:
                      <Typography
                        marginLeft={1}
                        variant="h5"
                        fontWeight={600}
                        component="span"
                        color="blue"
                      >
                        {localeFormat(
                          state.boxes.reduce(
                            (previousValue, currentValue) =>
                              previousValue +
                              currentValue.meters * currentValue.pricePerMeter,
                            0
                          )
                        )}
                        €
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {t("current")}:
                      <Typography
                        marginLeft={1}
                        variant="h5"
                        fontWeight={600}
                        component="span"
                        color="green"
                      >
                        {localeFormat(
                          state.boxes
                            .filter(
                              (box) => box.state === OCCUPIED_BOX_STATE_ID
                            )
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue +
                                currentValue.meters *
                                  currentValue.pricePerMeter,
                              0
                            )
                        )}
                        €
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            columns={OCCUPANCY_COLUMNS}
            data={state.boxes}
            options={state.options}
          />
        </Grid>
      </Grid>
    </Page>
  );
}
