import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
const SERVER_NAME = "localhost:8080/camera";
const socket = io(SERVER_NAME);

function base64ArrayBuffer(arrayBuffer) {
  var base64 = "";
  var encodings =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

  var bytes = new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + "==";
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + "=";
  }

  return base64;
}
const CameraStream = ({ width = "100%", height = "100%", camera }) => {
  const imgRef = useRef(null);
  const [currentCameraId, setCurrentCameraId] = useState(null);

  const [loading, setLoading] = useState(true);

  console.log("a", imgRef.current?.src);

  // Socket configuration
  useEffect(() => {
    socket.on("connect", () => {
      //console.log(socket.id, "connected!");
    });

    return () => {
      if (currentCameraId)
        socket.emit("end_camera_stream", { camId: currentCameraId });
      socket.disconnect();
    };
  }, []);

  // Get camera stream
  useEffect(() => {
    if (camera && camera.id !== currentCameraId) {
      if (currentCameraId) {
        socket.emit("end_camera_stream", { camId: currentCameraId });
      }
      socket.emit("get_camera_stream", { camId: camera.id });
      setCurrentCameraId(camera.id);
    } else if (!camera) {
      if (currentCameraId) {
        socket.emit("end_camera_stream", { camId: currentCameraId });
      }
      setCurrentCameraId(null);
    }
    return () => {
      if (currentCameraId)
        socket.emit("end_camera_stream", { camId: currentCameraId });
    };
  }, [camera]);

  // Set camera stream
  useEffect(() => {
    setLoading(true);
    if (currentCameraId) {
      socket.on("cam_image_" + currentCameraId, (msg) => {
        if (imgRef.current) {
          imgRef.current.src =
            "data:image/jpeg;base64," + base64ArrayBuffer(msg);
        }
        if (loading) setLoading(false);
      });
    }
  }, [currentCameraId]);

  return camera ? (
    loading ? (
      <CircularProgress />
    ) : (
      <img alt="no image" ref={imgRef} width={width} height={height} />
    )
  ) : (
    <>
      <Card elevation={2} sx={{ width: "100%", height: "100%" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Box sx={{ p: 10 }}>
            <Typography variant="h6">No Camera Selected</Typography>
          </Box>
        </Grid>
      </Card>
    </>
  );
};

export default CameraStream;
