import { useEffect, useRef } from "react";
import { Transformer } from "react-konva";
import Tile from "../TileShapes/Tile";

const editableShapes = ["Rect", "Image"];

const TileEdit = (props) => {
  let {
    tile,
    stroke,
    points,
    strokeWidth,
    fill,
    isEditing,
    onEditTile,
    closed,
    openDoor,
  } = props;

  const transformerRef = useRef(null);
  const tileRef = useRef(null);

  const onTransformEndHandler = () => {

    const node = tileRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // Reset tile scale
    node.scaleX(1);
    node.scaleY(1);

    /**
     * Update tile position (x, y) and size (width, heigt)
     * when transfromer changes
     */
    onEditTile({
      ...tile,
      x: Math.round(node.x()),
      y: Math.round(node.y()),
      width: Math.round(node.width() * scaleX),
      height: Math.round(node.height() * scaleY),
    });
  };

  /**
   * Update position (x, y) on drag
   */
  const onDragEndHandler = (e) => {
    onEditTile({
      ...tile,
      x: Math.round(e.target.x()),
      y: Math.round(e.target.y()),
    });
  };

  useEffect(() => {
    if (isEditing) {
      // we need to attach transformer manually
      transformerRef.current?.nodes([tileRef.current]);
      transformerRef.current?.getLayer().batchDraw();
      tileRef.current?.scaleX(1);
      tileRef.current?.scaleY(1);
    }
  }, [isEditing]);

  return (
    <>
      <Tile
        closed={closed}
        draggable={isEditing}
        fill={fill}
        onDragEnd={onDragEndHandler}
        onTransformEnd={onTransformEndHandler}
        points={points}
        stroke={stroke}
        strokeWidth={strokeWidth}
        tile={tile}
        tileRef={tileRef}
        openDoor={openDoor}
      />
      {isEditing && editableShapes.includes(tile.shape) && (
        <Transformer
          ref={transformerRef}
          flipEnabled={false}
          rotateEnabled={false}
          keepRatio={tile.shape === "Image" || tile.shape === "Text"}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default TileEdit;
