import { useReducer, useState } from "react";
import { useTranslation } from "react-i18next";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  Box,
  Button,
  List,
  ListItem,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import TextInput from "./Inputs/TextInput";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      return { ...state, value: [...state.value, action.payload] };
    case "DELETE_ITEM":
      return {
        ...state,
        value: [...state.value.filter((item) => item !== action.payload)],
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

export default function ListCard({
  text1,
  text2,
  value = [],
  validator1 = () => true,
  validator2 = () => true,
  errorText1 = "Not valid",
  errorText2 = "Not valid",
  onChange = () => {},
  showList = true,
}) {
  const [t] = useTranslation("customers");

  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");

  const initialState = {
    value,
    inputError: {
      input1: false,
      input2: false,
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const addItem = () => {
    if (validateForm()) {
      onChange([
        ...state.value,
        {
          value1,
          value2,
        },
      ]);
      dispatch({
        type: "ADD_ITEM",
        payload: {
          value1: value1,
          value2: value2,
        },
      });

      setValue1("");
      setValue2("");
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (validator1(value1) === false) {
      setInputErrorTrue("input1");

      isValid = false;
    }
    if (validator2(value2) === false) {
      setInputErrorTrue("input2");
      isValid = false;
    }

    return isValid;
  };

  const deleteItem = (item) => {
    onChange([...state.value.filter((val) => val !== item)]);
    dispatch({
      type: "DELETE_ITEM",
      payload: item,
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item>
          <TextInput
            error={state.inputError.input1}
            helperText={state.inputError.input1 ? errorText1 : ""}
            label={text1}
            name="input1"
            onChange={(e) => {
              setValue1(e.target.value);
              setInputErrorFalse("input1");
            }}
            value={value1}
          />
        </Grid>

        <Grid item>
          <TextInput
            error={state.inputError.input2}
            helperText={state.inputError.input2 ? errorText2 : ""}
            label={text2}
            name="input2"
            onChange={(e) => {
              setValue2(e.target.value);
              setInputErrorFalse("input2");
            }}
            value={value2}
          />
        </Grid>

        <Grid item>
          <Button
            onClick={(e) => {
              addItem();
            }}
            startIcon={<AddCircleOutlineOutlinedIcon />}
          >
            {t("add")}
          </Button>
        </Grid>
      </Grid>

      {showList && (
        <List>
          {state.value.map((item) => (
            <ListItem key={item.value1}>
              <Typography variant="body2">
                {item.value1 && item.value1}
                {item.value2 && " - " + item.value2}
              </Typography>
              <IconButton
                onClick={() => {
                  deleteItem(item);
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}
