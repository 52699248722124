import { Grid } from "@mui/material";
import { useContext } from "react";

import CenterReportContext from "../CenterReportContext";

import {
  BoxDistribution,
  CenterOccupancyDetails,
} from "../../charts/CenterReport";

function OccupancyTab() {
  const { boxClusters, boxes, center, contracts } =
    useContext(CenterReportContext);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={6}>
        <BoxDistribution
          useClusters={center.useClusters}
          clusters={boxClusters}
          boxes={boxes}
          contracts={contracts}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <CenterOccupancyDetails boxes={boxes} contracts={contracts} />
      </Grid>
    </Grid>
  );
}

export default OccupancyTab;
