export const filterByName = (originalRoots, filterName) => {
  let iter = 0;
  let result = [];

  if (!filterName) return originalRoots;
  while (iter < originalRoots.length) {
    if (needsToBeIncluded(originalRoots[iter], filterName.toLowerCase()))
      result = [
        ...result,
        builder(originalRoots[iter], filterName.toLowerCase()),
      ];
    iter++;
  }
  return result;
};

const builder = (node, filterName) => {
  let iter = 0;
  let toCheckFolders = [];
  let includeFiles = [];
  let includeFolders = [];
  let tree = [];

  if (node.name.toLowerCase().includes(filterName)) {
    return {
      ...node,
      Files: node.Files,
      children: node.children,
    };
  }
  includeFiles = matchedFiles(node, filterName);
  includeFolders = matchedFolders(node, filterName);
  toCheckFolders = node.children.filter(
    (folder) =>
      !includeFolders.some((includeFolder) => includeFolder.id === folder.id)
  );
  while (toCheckFolders[iter] !== undefined) {
    if (needsToBeIncluded(toCheckFolders[iter], filterName))
      tree = [...tree, builder(toCheckFolders[iter], filterName)];
    iter++;
  }
  return {
    ...node,
    Files: includeFiles,
    children: [...includeFolders, ...tree],
  };
};

const needsToBeIncluded = (node, filterName) => {
  let iter = 0;
  if (node.name.toLowerCase().includes(filterName)) return true;
  if (
    matchedFiles(node, filterName).length > 0 ||
    matchedFolders(node, filterName).length > 0
  )
    return true;
  while (node.children[iter] !== undefined) {
    if (needsToBeIncluded(node.children[iter], filterName)) return true;
    iter++;
  }
  return false;
};

const matchedFiles = (node, filterName) => {
  return node.Files.filter((file) =>
    file.name.toLowerCase().includes(filterName)
  );
};

const matchedFolders = (node, filterName) => {
  return node.children.filter((folder) =>
    folder.name.toLowerCase().includes(filterName)
  );
};
