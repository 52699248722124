import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { API_ROUTE } from "../utils/API";
import axios from "axios";

import { Grid, Paper, Typography, Button } from "@mui/material";

import TextInput from "./Inputs/TextInput";

export default function ResetPasswordCodeVerificationForm({ email, onSubmit }) {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("login");
  const [tErrors] = useTranslation("errors");
  const [code, setCode] = useState("");
  const [attempts, setAttempts] = useState(5);
  const [disabled, setDisabled] = useState(false);

  const submit = () => {
    setDisabled(true);
    const params = { code, email: email };
    axios
      .post(API_ROUTE + "/auth/forgot-password/verify-code", params)
      .then((response) => {
        if (response.data.error) {
          if (response.data.error === "auth-005") setAttempts(attempts - 1);
          console.log(response.data.error);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else onSubmit(true, code);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.message, { variant: "error" });
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      submit(e);
    }
  };

  return (
    <Paper sx={{ padding: 3, maxWidth: 500 }} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">{t("code")}</Typography>
      </Grid>
      <Grid container spacing={2} direction="column" marginTop={2}>
        <Grid item>
          <Typography>{t("introduceTheCode")}</Typography>
        </Grid>
        <Grid item xs={12} marginTop={1}>
          <TextInput
            label={t("code")}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            onKeyDown={handleEnter}
            helperText={
              attempts < 5
                ? attempts > 0
                  ? t("remmaningAttempts") + " " + attempts
                  : t("noMoreAttempts")
                : undefined
            }
          />
        </Grid>
        <Grid item container xs={12} marginTop={2}>
          <Grid item xs={6}>
            <Typography variant="caption" display="block">
              <Link to="/login">{t("backToLogin")}</Link>
            </Typography>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            {attempts > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={submit}
                disabled={
                  disabled || code === "" || code?.length !== 6 || attempts <= 0
                }
              >
                {t("verify")}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {attempts <= 0 && onSubmit(false, 111111)}
    </Paper>
  );
}
