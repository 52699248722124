import AppContext from "../../context/AppContext";
import CustomSelect from "./CustomSelect";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

function reducer(state, action) {
  switch (action.type) {
    case "SET_VALUE":
      return { ...state, value: action.payload };
    case "SET_INVOICE_SERIES":
      return { ...state, invoiceSeries: action.payload };
    default:
      throw new Error();
  }
}

const InvoiceSerieSelect = (props) => {
  const { multiple, onChange, value, label, extraOptions } = props;
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("boxes");

  const initialState = {
    value: value !== undefined ? value : multiple === true ? [] : "",
    invoiceSeries: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // Initial useEffect
  useEffect(() => {
    getInvoiceSeries();
  }, []);

  useEffect(() => {
    dispatch({ type: "SET_VALUE", payload: value });
  }, [value]);

  const getInvoiceSeries = () => {
    api
      .get("/invoice-series")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_INVOICE_SERIES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <CustomSelect
      {...props}
      multiple={multiple}
      label={label || t("invoiceSeries")}
      options={[
        ...state.invoiceSeries.map((serie) => ({
          value: serie.id,
          label: serie.name,
        })),
      ]}
      onChange={(e) => {
        console.log(e)
        dispatch({
          type: "SET_VALUE",
          payload: e.target.value,
        });
        onChange && onChange(e);
      }}
      value={state.value}
    ></CustomSelect>
  );
};

InvoiceSerieSelect.propTypes = {
  onChange: PropTypes.any,
  value: PropTypes.any,
};

export default InvoiceSerieSelect;
