import { Container, Grid, Paper, Typography } from "@mui/material";
import CustomButton from "../Inputs/CustomButton";
import { TOTP_TOKEN_LENGTH } from "../../data/constants";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import TextInput from "../Inputs/TextInput";

export default function TOTPForm({ onVerifyToken, verifyLoading }) {
  const [t] = useTranslation("login");
  const [token, setToken] = useState("");

  return (
    <Container maxWidth="xs">
      <Paper>
        <Grid container padding={2} rowGap={3}>
          {/* Title */}
          <Grid item xs={12}>
            <Typography variant="h6">{t("MFA")}</Typography>
          </Grid>

          {/* Verification Code Section */}
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                {t("verificationCode")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                {t("verificationCodeDescription")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label={t("verificationCode")}
                variant="outlined"
                fullWidth
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CustomButton
              fullWidth
              onClick={() => onVerifyToken(token)}
              disabled={token.length !== TOTP_TOKEN_LENGTH}
              loading={verifyLoading}
            >
              {t("validate")}
            </CustomButton>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
