import {
  SPAIN_PHONE_EXTENSION,
  SPAIN_PHONE_NUMBER_LENGTH,
} from "../data/constants";

export const localeFormat = (number) => {
  return Number(Number(number).toFixed(2)).toLocaleString("es-CL");
};

export const localeFormatInteger = (number) => {
  return Number(Math.round(Number(number))).toLocaleString("es-CL");
};

export const removeSpaces = (str) => {
  return str.replace(/\s+/g, "");
};

export const formatPhoneNumber = (phoneNumberString) => {
  const phoneNumberWithoutSpaces = phoneNumberString.replace(/\s/g, "");

  // Check if the first 3 characters match spanish extension
  const isSpanishExtension =
    phoneNumberWithoutSpaces.substring(0, 3) === SPAIN_PHONE_EXTENSION;

  // Remove the extension
  const phoneNumberWithoutExtension = phoneNumberWithoutSpaces.substring(3);

  // Check if the phone number is spanish and has 9 characters, if it doesn't, return the original string
  if (
    !isSpanishExtension ||
    phoneNumberWithoutExtension.length !== SPAIN_PHONE_NUMBER_LENGTH
  )
    return phoneNumberString;

  // Return the number spaced every 3 characters
  return phoneNumberWithoutExtension.replace(
    /(\d{3})(\d{3})(\d{3})/,
    "$1 $2 $3"
  );
};

export const formatHiddenPayCardNumber = (payCardNumber) => {
  const sanitizedPayCard = removeSpaces(payCardNumber);

  return (
    sanitizedPayCard.substring(0, 4) +
    " **** **** " +
    sanitizedPayCard.substring(sanitizedPayCard.length - 4)
  );
};
