import { Grid } from "@mui/material";
import { useContext } from "react";

import CenterReportContext from "../CenterReportContext";

import {
  HistoricalContractVariation,
  HistoricalInvoicing,
  HistoricalOccupancy,
  HistoricalPricePerMeter,
} from "../../charts/CenterReport";

function PerformanceTab() {
  const { boxes, contracts, acquisitionAsset, invoiceItems } =
    useContext(CenterReportContext);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <HistoricalContractVariation contracts={contracts} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <HistoricalOccupancy
          contracts={contracts}
          boxes={boxes}
          objective={acquisitionAsset?.occupancy || null}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <HistoricalPricePerMeter
          contracts={contracts}
          objective={acquisitionAsset?.sqmPriceMonth || null}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <HistoricalInvoicing
          contracts={contracts}
          objective={
            acquisitionAsset?.sqmPriceMonth *
              (acquisitionAsset?.currentArea +
                acquisitionAsset?.aditionalConstructability) *
              (acquisitionAsset?.lettableAreaRatio / 100) *
              (acquisitionAsset?.occupancy / 100) || null
          }
          invoiceItems={invoiceItems}
        />
      </Grid>
    </Grid>
  );
}

export default PerformanceTab;
