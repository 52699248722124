import React, { useState } from "react";
import { IconButton, FormControl, Grid, FormHelperText } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";

export default function PasswordInput({
  text,
  value,
  error,
  onChange = () => {},
  name,
}) {
  const [showPassword, setPassword] = useState(true);

  const handleClickShowPassword = () => {
    setPassword(!showPassword);
  };

  return (
    <FormControl variant="outlined" size="small">
      <InputLabel htmlFor="outlined-adornment-password">{text}</InputLabel>
      <OutlinedInput
        error={error}
        size="small"
        name={name}
        type={showPassword == false ? "text" : "password"}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={text}
      />
      {error && <FormHelperText error> Invalid password </FormHelperText>}
    </FormControl>
  );
}
