import { Box } from "@mui/material";
import ContractCard from "./ContractCard";

export default function ContractsList({ contracts }) {

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      flexWrap: "wrap",
      padding: "1rem",
      maxHeight: 500,
      overflow: 'auto'
    }}>
      {contracts?.map((contract) => (
        <ContractCard contract={contract} key={contract.id}/>
      ))}
    </Box>
  );
}
