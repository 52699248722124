import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import TextInput from "../../../Inputs/TextInput";
import Button from "../../../Inputs/CustomButton";
import { useState, useContext, useEffect } from "react";
import CustomSelect from "../../../Inputs/CustomSelect";
import AppContext from "../../../../context/AppContext";
import { useSnackbar } from "notistack";

export const defaultValues = {
  shape: "Camera",
  x: "100",
  y: "100",
  cameraId: "",
};

const CreateCameraForm = ({
  onCreateTile,
  closeAddTileDialog,
  centerId,
  layerTiles,
}) => {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("floorPlans");
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState("Tile " + (layerTiles.length + 1));
  const [x, setX] = useState(defaultValues.x);
  const [y, setY] = useState(defaultValues.y);
  const [cameraId, setCameraId] = useState(defaultValues.cameraId);
  const [cameras, setCameras] = useState([]);

  useEffect(() => {
    getCameras();
  }, []);

  const getCameras = () => {
    if (!centerId) return;
    const params = { centerId: centerId };
    api
      .get(`/surveillance/cameras`, { params })
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
          return;
        } else {
          setCameras(res.data);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
        console.error(err);
      });
  };

  const validateForm = () => {
    if (!name || !x || !y) {
      return false;
    }
    return true;
  };

  const handleCreate = () => {
    let tile = {
      name,
      shape: "Camera",
      x,
      y,
      cameraId,
    };
    onCreateTile(tile);
  };

  const inputs = [
    {
      label: "name",
      value: name,
      setter: setName,
      type: "text",
    },
    {
      label: "x",
      value: x,
      setter: setX,
      type: "number",
    },
    {
      label: "y",
      value: y,
      setter: setY,
      type: "number",
    },
  ];

  return (
    <Box width="100%">
      <Grid container spacing={2}>
        <Grid item container xs={6} spacing={2}>
          {inputs.map((input) => (
            <Grid container item alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <Typography align="left">{t(input.label) + ":"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  label={t(input.label)}
                  type={input.type}
                  value={input.value}
                  onChange={(e) => input.setter(e.target.value)}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item container xs={6} spacing={2} alignItems="center">
          <Grid item>
            <Typography align="left">{t("camera") + ":"}</Typography>
          </Grid>
          <Grid item>
            <CustomSelect
              options={cameras.map((camera, index) => ({
                value: camera.id,
                label: camera.name,
              }))}
              value={cameraId}
              onChange={(e) => setCameraId(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="end" spacing={1}>
          <Grid item>
            <Button onClick={closeAddTileDialog} variant="text">
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="success"
              disabled={!validateForm()}
              onClick={handleCreate}
            >
              {t("add")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateCameraForm;
