import {
  AppBar,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Grid,
  Tab,
  Tabs,
  Typography,
  List,
  ListItem,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Step,
  StepLabel,
  StepContent,
  Stepper,
  DialogTitle,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
  TextField,
  Tooltip,
  Alert,
  ButtonGroup,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { getParams, generateURL } from "../../../utils/url";
import { DataGrid } from "@mui/x-data-grid";

import AddIcon from "@mui/icons-material/Add";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BackupIcon from "@mui/icons-material/Backup";
import CloseIcon from "@mui/icons-material/Close";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

import AppContext from "../../../context/AppContext";
import Button from "../../Inputs/CustomButton";
import ConfirmDialog from "../../ConfirmDialog";
import CityInput from "../../Inputs/CityInput";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import DateInput from "../../Inputs/CustomDate";
import TextInput from "../../Inputs/TextInput";
import TrackerStatusChip from "./TrackerStatusChip";

import { formatDate } from "../../../utils/date";
import { localeFormat } from "../../../utils/format";
import Map from "./Map";
import { SERVER } from "../../../utils/API";
import { useTheme } from "@emotion/react";
import theme from "../../../theme/theme";
import ButtonLink from "../../Inputs/ButtonLink";
import SearchButton from "../../Inputs/SearchButton";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      component={Paper}
      square
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const DocumentItem = ({
  document,
  seeDocument,
  downloadDocument,
  deleteDocument,
}) => {
  return (
    <ListItem key={document.id}>
      <Typography variant="body">{document.name}</Typography>
      <IconButton
        onClick={() => {
          seeDocument(document);
        }}
      >
        <VisibilityIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          downloadDocument(document);
        }}
      >
        <DownloadIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          deleteDocument(document);
        }}
      >
        <HighlightOffIcon />
      </IconButton>
    </ListItem>
  );
};

const AccordionFolder = (props) => {
  const {
    name,
    children,
    seeDocument,
    downloadDocument,
    deleteDocument,
    isOpen,
    documents,
    switchOpenFolder,
  } = props;
  const numFiles =
    name === "DD"
      ? documents.filter((document) =>
          ["DD", "KYC", "Tecnica", "Legal"].includes(document.category)
        ).length
      : documents.filter((document) => document.category === name).length;
  return (
    <Accordion square expanded={isOpen} onChange={() => switchOpenFolder(name)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{name}</Typography>
        {numFiles > 0 && (
          <Typography color="text.secondary" marginLeft={1}>
            ({numFiles})
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {children}
        <List>
          {documents
            .filter((document) => document.category === name)
            .map((document) => (
              <DocumentItem
                document={document}
                seeDocument={seeDocument}
                downloadDocument={downloadDocument}
                deleteDocument={deleteDocument}
              />
            ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialState = {
  form: {
    address: "",
    source: "",
    cadastralReference: "",
    City: null,
    name: "",
    aditionalConstructability: 0,
    currentArea: 0,
    lettableAreaRatio: 0,
    acquisitionPrice: 0,
    acquisitionCostRatio: 0,
    capexCostSqm: 0,
    sqmPriceMonth: 0,
    occupancy: 0,
    operatingCostsSqm: 0,
    offerComments: "",
    priority: 0,
    link: "",
    contact: "",
    latitude: null,
    longitude: null,
    offerSent: false,
    AcquisitionAssetCosts: [],
  },
  acquisitionCostTypes: [],
  comments: [],
  confirmDialog: {
    title: "",
    confirmText: "",
    cancelText: "",
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
  documents: [],
  images: [],
  visits: [],
  //sources: [],
  visitDialog: {
    isOpen: false,
    date: "",
    visitors: "",
    comments: "",
  },
  folders: {
    "Info received": { isOpen: false },
    Deal: { isOpen: false },
    Letter: { isOpen: false },
    DD: { isOpen: false },
    IC: { isOpen: false },
    KYC: { isOpen: false },
    Tecnica: { isOpen: false },
    Legal: { isOpen: false },
    Otros: { isOpen: false },
  },
  uploadFileDialog: {
    isOpen: false,
    category: "Info received",
    files: [],
  },
  delImgReqModal: {
    isOpen: false,
    selected: "",
  },
  tracker: [],
  /*tracker: {
    activeStep: 0,
    NBO1: false,
    NBO2: false,
    commercialDD1: false,
    commercialDD2: false,
    technicalDD1: false,
    technicalDD2: false,
    technicalDD1: false,
    legalDD1: false,
    legalDD2: false,
    capitalCall1: false,
    capitalCall2: false,
  },*/
  costsDialog: {
    amount: 0,
    date: "",
    description: "",
    editMode: false,
    invoiceNumber: "",
    selectedInvoice: [],
    invoices: [],
    isOpen: false,
    type: "",
    invoicesLoading: false,
  },
  providerInvoiceFilter: {
    search: "",
    dateFrom: "",
    dateUntil: "",
    importMin: "",
    importMax: "",
  },
  acquisitionAssetLoading: false,
  inputError: {
    name: false,
    address: false,
    currentArea: false,
    capexCostSqm: false,
    sqmPriceMonth: false,
    lettableAreaRatio: false,
    acquisitionPrice: false,
    aditionalConstructability: false,
    cadastralReference: false,
    occupancy: false,
    operatingCostsSqm: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_ACQUISITION_COST_TYPES":
      return { ...state, acquisitionCostTypes: action.payload };
    case "SET_IMAGES":
      return { ...state, images: action.payload };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_VISIT_INPUT":
      return {
        ...state,
        visitDialog: {
          ...state.visitDialog,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_VISIT_INPUT":
      return { ...state, visitDialog: initialState.visitDialog };
    case "SET_TRACKER_CHECKBOX":
      return {
        ...state,
        tracker: {
          ...state.tracker,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_ACQUISITION":
      return { ...state, form: action.payload };
    case "SET_SOURCES":
      return { ...state, sources: action.payload };
    case "SET_DOCUMENTS":
      return { ...state, documents: action.payload };
    case "SET_COMMENTS":
      return { ...state, comments: action.payload };
    case "SET_VISITS":
      return { ...state, visits: action.payload };
    case "OPEN_VISIT_DIALOG":
      return { ...state, visitDialog: { ...state.visitDialog, isOpen: true } };
    case "CLOSE_VISIT_DIALOG":
      return { ...state, visitDialog: { ...state.visitDialog, isOpen: false } };
    case "OPEN_UPLOAD_FILE_DIALOG":
      return {
        ...state,
        uploadFileDialog: { ...state.uploadFileDialog, isOpen: true },
      };
    case "CLOSE_UPLOAD_FILE_DIALOG":
      return {
        ...state,
        uploadFileDialog: initialState.uploadFileDialog,
      };
    case "CHANGE_STATE_DELETE_IMG_REQ_MODAL":
      return {
        ...state,
        delImgReqModal: { ...state.delImgReqModal, isOpen: action.payload },
      };
    case "CHANGE_IMG_SELECT_TO_DELETE":
      return {
        ...state,
        delImgReqModal: { ...state.delImgReqModal, selected: action.payload },
      };
    case "SET_FILE_CATEGORY":
      return {
        ...state,
        uploadFileDialog: {
          ...state.uploadFileDialog,
          category: action.payload,
        },
      };
    case "SET_FILES":
      return {
        ...state,
        uploadFileDialog: {
          ...state.uploadFileDialog,
          files: action.payload,
        },
      };
    case "SET_COORDINATES":
      return {
        ...state,
        form: {
          ...state.form,
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
        },
      };
    case "SWITCH_OPEN_FOLDER": {
      return {
        ...state,
        folders: {
          ...state.folders,
          [action.payload]: {
            ...state.folders[action.payload],
            isOpen: !state.folders[action.payload].isOpen,
          },
        },
      };
    }
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_TRACKER":
      return { ...state, tracker: action.payload };
    case "SET_TRACKER_INPUT":
      return {
        ...state,
        tracker: state.tracker.map((t) =>
          t.type === action.payload.type
            ? { ...t, [action.payload.inputname]: action.payload.value }
            : t
        ),
      };
    case "SET_COSTS_DIALOG_INPUT":
      return {
        ...state,
        costsDialog: {
          ...state.costsDialog,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_PROVIDER_INVOICE_FILTER_INPUT":
      return {
        ...state,
        providerInvoiceFilter: {
          ...state.providerInvoiceFilter,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_COST":
      return { ...state, costsDialog: action.payload };
    case "SET_ACQUISITION_ASSET_LOADING":
      return { ...state, acquisitionAssetLoading: action.payload };
    case "RESET":
      return { ...state, [action.payload]: initialState[action.payload] };
    default:
      throw new Error("Action not found in reducer.");
  }
}

export default function AcquisitionAssetPage() {
  const { api, user } = useContext(AppContext);
  const { id } = useParams();
  const history = useHistory();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = React.useState(0); //<- Tabs
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("acquisitions");
  const [tErrors] = useTranslation("errors");
  const theme = useTheme();
  const isSmWindow = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdWindow = useMediaQuery(theme.breakpoints.down("md"));
  const cadastralReferenceUrl =
    "https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCBusqueda.aspx?fromVolver=ListaBienes&pest=rc&RCCompleta=";

  //Calcs
  const GIA =
    Number(state.form.currentArea) +
    Number(state.form.aditionalConstructability);
  const lettableArea = GIA * (Number(state.form.lettableAreaRatio) / 100);
  const acquisitionCost =
    state.form.acquisitionPrice *
    (Number(state.form.acquisitionCostRatio) / 100);
  const capexCosts =
    state.form.capexCostSqm * state.form.aditionalConstructability;
  const AIC =
    Number(state.form.acquisitionPrice) +
    Number(capexCosts) +
    Number(acquisitionCost);
  const potentialERV = lettableArea * state.form.sqmPriceMonth * 12;
  const achievedERV = potentialERV * (state.form.occupancy / 100) || 0;
  const operatingCosts = state.form.operatingCostsSqm * lettableArea * 12 || 0;
  const NOI = achievedERV - operatingCosts;
  const NetYield = (NOI / AIC) * 100 || 0;

  //Costs
  const totalAssets = state.form?.AcquisitionAssetCosts?.reduce(
    (total, current) => total + (current.type === "Asset" ? current.amount : 0),
    0
  );
  const totalCapex = state.form?.AcquisitionAssetCosts?.reduce(
    (total, current) => total + (current.type === "Capex" ? current.amount : 0),
    0
  );
  const totalOthers = state.form?.AcquisitionAssetCosts?.reduce(
    (total, current) =>
      total +
      (state.acquisitionCostTypes
        .filter((type) => type !== "Asset" && type !== "Capex")
        .includes(current.type)
        ? current.amount
        : 0),
    0
  );
  const totalCosts = totalAssets + totalCapex + totalOthers;
  const assetDifference =
    ((totalAssets - state.form.acquisitionPrice) /
      state.form.acquisitionPrice) *
    100;
  const capexDifference = ((totalCapex - capexCosts) / capexCosts) * 100;
  const othersDifference =
    ((totalOthers - acquisitionCost) / acquisitionCost) * 100;
  const totalDifference =
    ((totalCosts -
      (state.form.acquisitionPrice + capexCosts + acquisitionCost)) /
      (state.form.acquisitionPrice + capexCosts + acquisitionCost)) *
    100;

  const PROVIDER_INVOICE_COLUMNS = [
    { field: "title", headerName: t("title"), width: 200 },
    { field: "invoiceNumber", headerName: t("invoiceNumber"), flex: 1 },
    { field: "date", headerName: t("date"), flex: 1 },
    { field: "dueDate", headerName: t("dueDate"), flex: 1 },
    {
      field: "total",
      headerName: t("total"),
      valueFormatter: ({ value }) => localeFormat(value) + "€",
      flex: 1,
    },
    { field: "Center.name", headerName: t("center"), flex: 1 },
  ];

  const ASSET_COSTS_COLUMNS = [
    {
      field: "type",
      headerName: t("type"),
      flex: 1,
      minWidth: 75,
    },
    {
      field: "description",
      headerName: t("description"),
      flex: 2,
      minWidth: 200,
    },
    {
      field: "invoiceNumber",
      headerName: t("invoiceNumber"),
      flex: 1,
      minWidth: 75,
    },
    {
      field: "amount",
      headerName: t("amount"),
      flex: 1,
      minWidth: 75,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "providerInvoiceId",
      headerName: t("invoice"),
      flex: 1,
      minWidth: 75,
      renderCell: ({ row }) =>
        row?.ProviderInvoice?.invoiceNumber && (
          <ButtonLink
            to={"/app/provider-invoice/" + row.providerInvoiceId}
            onClick={(e) => e.stopPropagation()}
          >
            {row?.ProviderInvoice?.invoiceNumber}
          </ButtonLink>
        ),
    },
    {
      field: "id",
      headerName: t("actions"),
      flex: 1,
      minWidth: 75,
      renderCell: ({ row }) => (
        <ButtonGroup>
          <Tooltip title={t("edit")} placement="top">
            <IconButton
              onClick={() => setCostDialogOpen(true, row)}
              size="small"
              color="primary"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("delete")} placement="top">
            <IconButton onClick={() => openConfirmDeleteCost(row)} size="small">
              <DeleteIcon color="error" fontSize="small" />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ),
    },
    ,
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("acquisitionAssetPage");

    //Get and set tab value from url if necessary:
    if (getParams(query, [{ name: "tab", type: "number" }]).tab)
      setValue(Number(getParams(query, [{ name: "tab", type: "number" }]).tab));

    //getSources();
    getDocuments();
    getComments();
    getAcquisitionAsset();
    getImages();
    getAcquisitionCostTypes();
  }, []);

  // Temporary
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getAcquisitionAsset = () => {
    dispatch({ type: "SET_ACQUISITION_ASSET_LOADING", payload: true });
    let params = {
      include: ["AcquisitionTracker", "AcquisitionCosts"],
    };

    api
      .get("/acquisitions/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_ACQUISITION", payload: response.data });
          dispatch({
            type: "SET_TRACKER",
            payload: response.data.AcquisitionTrackers,
          });
          dispatch({
            type: "SET_VISITS",
            payload: response.data.AcquisitionAssetVisits,
          });
        }
        dispatch({ type: "SET_ACQUISITION_ASSET_LOADING", payload: false });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
        dispatch({ type: "SET_ACQUISITION_ASSET_LOADING", payload: false });
      });
  };

  const getAcquisitionCostTypes = () => {
    api
      .get("/acquisition-cost-types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_ACQUISITION_COST_TYPES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getDocuments = (e) => {
    api
      .get("/acquisitions/" + id + "/documents")
      .then((response) => {
        dispatch({
          type: "SET_DOCUMENTS",
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getComments = (e) => {
    let params = {
      include: ["User"],
    };
    api
      .get("/acquisitions/" + id + "/comments", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error.toString(), { variant: "error" });
        else
          dispatch({
            type: "SET_COMMENTS",
            payload: response.data,
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getImages = () => {
    api
      .get("/acquisitions/" + id + "/images")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_IMAGES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const url = generateURL("/app/acquisition/" + id, { tab: newValue });
    history.replace(url);
  };

  const handleInputChange = (e) => {
    const validNames = [
      "name",
      "address",
      "currentArea",
      "capexCostSqm",
      "sqmPriceMonth",
      "lettableAreaRatio",
      "acquisitionPrice",
      "aditionalConstructability",
      "cadastralReference",
      "occupancy",
      "operatingCostsSqm",
    ];

    if (validNames.includes(e.target.name)) {
      setInputErrorFalse(e.target.name);
    }
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleVisitInputChange = (e) => {
    dispatch({
      type: "SET_VISIT_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleTrackerCheckbox = (e) => {
    dispatch({
      type: "SET_TRACKER_CHECKBOX",
      payload: {
        inputname: e.target.name,
        value: e.target.checked,
      },
    });
  };

  const handleCadastralReferenceSearch = (e) => {
    if (state.form.cadastralReference === "") return;
    window.open(cadastralReferenceUrl + state.form.cadastralReference);
  };

  const handleOpenStatusDeleteImgConfirmDialog = (status) => {
    dispatch({ type: "CHANGE_STATE_DELETE_IMG_REQ_MODAL", payload: status });
  };

  const setCoordinates = (latitude, longitude) => {
    dispatch({ type: "SET_COORDINATES", payload: { latitude, longitude } });
  };

  const submit = () => {
    let data = {};

    if (!validateForm(state.form)) return;
    state.form.name !== "" && (data.name = state.form.name);
    state.form.address !== "" && (data.address = state.form.address);
    data.cityId = state.form.City?.id || null;
    data.source = state.form.source;
    state.form.aditionalConstructability !== "" &&
      (data.aditionalConstructability = state.form.aditionalConstructability);
    state.form.currentArea !== "" &&
      (data.currentArea = state.form.currentArea);
    state.form.lettableAreaRatio !== "" &&
      (data.lettableAreaRatio = state.form.lettableAreaRatio);
    state.form.acquisitionPrice !== "" &&
      (data.acquisitionPrice = state.form.acquisitionPrice);
    state.form.acquisitionCostRatio !== "" &&
      (data.acquisitionCostRatio = state.form.acquisitionCostRatio);
    state.form.capexCostSqm !== "" &&
      (data.capexCostSqm = state.form.capexCostSqm);
    state.form.sqmPriceMonth !== "" &&
      (data.sqmPriceMonth = state.form.sqmPriceMonth);
    state.form.occupancy !== "" && (data.occupancy = state.form.occupancy);
    state.form.operatingCostsSqm !== "" &&
      (data.operatingCostsSqm = state.form.operatingCostsSqm);
    state.form.priority !== "" && (data.priority = state.form.priority);
    state.form.offerComments !== "" &&
      (data.offerComments = state.form.offerComments);
    data.cadastralReference = state.form.cadastralReference;
    data.link = state.form.link;
    data.contact = state.form.contact;
    data.latitude = state.form.latitude;
    data.longitude = state.form.longitude;
    data.offerSent = state.form.offerSent;

    api
      .post("/acquisitions/edit/" + id, data)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("aquisitionAssetEditSuccess"), {
            variant: "success",
          });

          history.goBack();

        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteAcquisitionAsset = () => {
    api
      .delete("/acquisitions/" + id)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("acquisitionDeleted"), { variant: "success" });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createDocument = (e) => {
    let formData = new FormData();

    if (state.uploadFileDialog.files.length === 0) {
      enqueueSnackbar(t("noImagesSelected"), { variant: "warning" });
      return;
    }

    //Data for each file:
    state.uploadFileDialog.files.forEach((image) => {
      formData.append("fileUpload", new File([image], "fileUpload"));
      formData.append("name", image.name);
      formData.append("type", image.type);
    });

    //Common data:
    formData.append("acquisitionAssetId", id);
    formData.append("category", state.uploadFileDialog.category);
    
    api
      .post("/acquisitions/documents", formData)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error.toString(), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(t("documentCreated"), {
            variant: "success",
          });
          closeUploadFileDialog();
          getDocuments();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const seeDocument = (document) => {
    api
      .get("/acquisitions/documents/" + document.id, { responseType: "blob" })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: document.type });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((error) => console.log(error));
  };

  const downloadDocument = (item) => {
    api
      .get("/acquisitions/documents/" + item.id, { responseType: "blob" })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: item.type });
          const fileURL = URL.createObjectURL(file);
          const a = document.createElement("a");
          a.href = fileURL;
          a.download = fileURL.split("/").pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((error) => console.log(error));
  };

  const deleteDocument = (document) => {
    api
      .delete("/acquisitions/document/" + document.id)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else {
          enqueueSnackbar("documentDeleted", { variant: "success" });
          getDocuments();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addComment = () => {
    let comment = {
      text: state.form.comment,
      createdBy: user.id,
      acquisitionAssetId: id,
    };

    api
      .post("/acquisitions/comment", comment)
      .then((response) => {
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, { variant: "error" });
        } else {
          enqueueSnackbar(t("commentAdded"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "comment",
              value: "",
            },
          });
          getComments();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeVisitDialog = () => {
    dispatch({ type: "CLOSE_VISIT_DIALOG" });
  };

  const validateVisit = () => {
    let isValid = true;
    let fields = ["visitors", "date"];

    fields.forEach((field) => {
      if (state.visitDialog[field] === "") {
        enqueueSnackbar(t(field) + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    return isValid;
  };

  const createVisit = () => {
    if (validateVisit()) {
      const { date, visitors, comments } = { ...state.visitDialog };
      const data = {
        date,
        visitors,
        comments,
        acquisitionAssetId: state.form.id,
      };
      api
        .post("/acquisitions/visit", data)
        .then((response) => {
          if (response.data.error)
            enqueueSnackbar(response.error.toString(), { variant: "error" });
          else {
            enqueueSnackbar(t("visitAdded"), { variant: "success" });
            dispatch({
              type: "SET_VISITS",
              payload: [...state.visits, response.data],
            });
            dispatch({ type: "RESET_VISIT_INPUT" });
            closeVisitDialog();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const closeUploadFileDialog = () => {
    //TODO reset inputs
    dispatch({ type: "CLOSE_UPLOAD_FILE_DIALOG" });
  };

  const switchOpenFolder = (folderName) => {
    dispatch({ type: "SWITCH_OPEN_FOLDER", payload: folderName });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const openConfirmDocument = (document) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteDocumentQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: document.name,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteDocument(document);
          resetConfirmDialog();
        },
      },
    });
  };

  const trackerInputChange = (e) => {
    dispatch({
      type: "SET_TRACKER_INPUT",
      payload: {
        type: e.target.id,
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const editTrackerPhase = (type) => {
    const data = state.tracker.find((t) => t.type === type);

    data.endDate === "" && (data.endDate = null);
    data.startDate === "" && (data.startDate = null);

    api
      .post("/acquisitions/edit/tracker/" + data.id, data)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else {
          enqueueSnackbar(t("aquisitionTrackerEditSuccess"), {
            variant: "success",
          });

          dispatch({
            type: "SET_TRACKER_INPUT",
            payload: {
              type: response.data.result.type,
              inputname: "status",
              value: response.data.result.status,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setCostDialogOpen = (editMode, cost) => {
    if (editMode) {
      cost.editMode = editMode;
      cost.isOpen = true;
      getProviderInvoiceById(cost.providerInvoiceId);
      if (cost.providerInvoiceId)
        cost.selectedInvoice = [cost.providerInvoiceId];
      else cost.selectedInvoice = [];
      dispatch({ type: "SET_COST", payload: cost });
    } else {
      dispatch({
        type: "SET_COSTS_DIALOG_INPUT",
        payload: {
          inputname: "editMode",
          value: editMode,
        },
      });
      dispatch({
        type: "SET_COSTS_DIALOG_INPUT",
        payload: {
          inputname: "isOpen",
          value: true,
        },
      });
    }
  };

  const setCostDialogClosed = () => {
    dispatch({ type: "RESET", payload: "costsDialog" });
    dispatch({ type: "RESET", payload: "providerInvoiceFilter" });
  };

  const changeCostInput = (e) => {
    dispatch({
      type: "SET_COSTS_DIALOG_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const changeProviderInvoiceInput = (e) => {
    dispatch({
      type: "SET_PROVIDER_INVOICE_FILTER_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const getProviderInvoices = () => {
    dispatch({
      type: "SET_COSTS_DIALOG_INPUT",
      payload: {
        inputname: "invoicesLoading",
        value: true,
      },
    });
    let filters = {};

    state.providerInvoiceFilter.search !== "" &&
      (filters.text = state.providerInvoiceFilter.search);

    state.providerInvoiceFilter.dateFrom !== "" &&
      (filters.dateFrom = state.providerInvoiceFilter.dateFrom);
    state.providerInvoiceFilter.dateUntil !== "" &&
      (filters.dateUntil = state.providerInvoiceFilter.dateUntil);

    state.providerInvoiceFilter.importMin !== "" &&
      (filters.importMin = state.providerInvoiceFilter.importMin);
    state.providerInvoiceFilter.importMax !== "" &&
      (filters.importMax = state.providerInvoiceFilter.importMax);

    api
      .get("/provider-invoices", {
        params: filters,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data?.length === 0)
            enqueueSnackbar(t("noProviderInvoices"), { variant: "warning" });

          if (state.costsDialog.selectedInvoice?.length !== 0) {
            response.data.forEach((invoice) =>
              state.costsDialog.selectedInvoice[0] !== invoice.id
                ? (invoice.selected = false)
                : (invoice.selected = true)
            );
          } else response.data.forEach((invoice) => (invoice.selected = true));

          dispatch({
            type: "SET_COSTS_DIALOG_INPUT",
            payload: {
              inputname: "invoices",
              value: response.data,
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_COSTS_DIALOG_INPUT",
          payload: {
            inputname: "invoicesLoading",
            value: false,
          },
        });
      });
  };

  const getProviderInvoiceById = (providerInvoiceId) => {
    api
      .get("/provider-invoices/" + providerInvoiceId)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length == 0)
            enqueueSnackbar(t("noProviderInvoices"), { variant: "warning" });

          response.data.selected = true;
          selectProviderInvoice([providerInvoiceId], response.data);
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createAcquisitionCost = () => {
    if (validateAssetCost()) {
      let data = {
        amount: state.costsDialog.amount,
        acquisitionAssetId: id,
        date: state.costsDialog.date,
        description: state.costsDialog.description,
        invoiceNumber: state.costsDialog.invoiceNumber,
        providerInvoiceId: state.costsDialog.selectedInvoice[0],
        type: state.costsDialog.type,
      };
      api
        .post("/acquisitions/cost/", data)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("acquisitionAssetCostCreated"), {
              variant: "success",
            });
            dispatch({
              type: "SET_INPUT",
              payload: {
                inputname: "AcquisitionAssetCosts",
                value: [
                  ...state.form.AcquisitionAssetCosts,
                  response.data.newCost,
                ],
              },
            });
            setCostDialogClosed();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const validateAssetCost = () => {
    let isValid = true;
    /*if (state.costsDialog.selectedInvoice?.length === 0) {
      enqueueSnackbar(t("selectAProviderInvoice"), { variant: "error" });
      isValid = false;
    }*/

    let fields = ["type", "date"];
    fields.forEach((field) => {
      if (state.costsDialog[field] === "") {
        enqueueSnackbar(t(field) + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    return isValid;
  };

  const selectProviderInvoice = (selectedId, invoice) => {
    let newInvoices = state.costsDialog.invoices;
    invoice && (newInvoices = [invoice]);

    newInvoices?.forEach((invoice) => {
      if (selectedId.length === 0) invoice.selected = true;
      else if (invoice.id !== selectedId[0]) invoice.selected = false;
    });

    dispatch({
      type: "SET_COSTS_DIALOG_INPUT",
      payload: {
        inputname: "invoices",
        value: newInvoices,
      },
    });
    dispatch({
      type: "SET_COSTS_DIALOG_INPUT",
      payload: {
        inputname: "selectedInvoice",
        value: selectedId,
      },
    });
  };

  const deleteAssetCost = (costId) => {
    api
      .delete("/acquisitions/cost/" + costId)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else {
          enqueueSnackbar(t("assetCostDeleted"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "AcquisitionAssetCosts",
              value: state.form.AcquisitionAssetCosts.filter(
                (cost) => cost.id !== costId
              ),
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const editAssetCost = () => {
    if (validateAssetCost()) {
      let data = {};

      state.costsDialog.amount != "" &&
        (data.amount = state.costsDialog.amount);
      state.costsDialog.description != "" &&
        (data.description = state.costsDialog.description);
      state.costsDialog.invoiceNumber != "" &&
        (data.invoiceNumber = state.costsDialog.invoiceNumber);
      state.costsDialog.type != "" && (data.type = state.costsDialog.type);
      state.costsDialog.selectedInvoice.length !== 0 &&
        (data.providerInvoiceId = state.costsDialog.selectedInvoice[0]);

      api
        .post("/acquisitions/cost/" + state.costsDialog.id, data)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("assetCostEdited"), {
              variant: "success",
            });
            getAcquisitionAsset();
            setCostDialogClosed();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const openConfirmDeleteCost = (cost) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteAssetCostQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: cost.invoiceNumber,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteAssetCost(cost.id);
          resetConfirmDialog();
        },
      },
    });
  };

  const handleDeleteAcquisitionAsset = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteAcquisitionAssetQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: state.form.name,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteAcquisitionAsset();
          resetConfirmDialog();
        },
      },
    });
  };

  const addMedia = (e) => {
    if (!e.target.files.length) return;
    let formData = new FormData();
    formData.append(
      "acquisitionAssetsImage",
      new File([e.target.files[0]], e.target.files[0].name)
    );
    api
      .post("/acquisitions/" + id + "/media", formData)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          getImages();
          enqueueSnackbar(t("photosUploadedSuccessfully"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteMedia = () => {
    if (state.delImgReqModal.selected === "") return;
    api
      .delete("/acquisitions/" + id + "/media/" + state.delImgReqModal.selected)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          getImages();
          enqueueSnackbar(t("photosDeletedSuccessfully"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const validateForm = (form) => {
    let isValid = true;
    let fields = [
      "name",
      "address",
      "currentArea",
      "capexCostSqm",
      "sqmPriceMonth",
      "lettableAreaRatio",
      "acquisitionPrice",
      "aditionalConstructability",
      "occupancy",
      "operatingCostsSqm",
    ];

    fields.forEach((field) => {
      console.log(field, form);
      if (form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    return isValid;
  };

  const getFormattedDateTime = (commentDate) => {
    const date = new Date(commentDate);
    return (
      formatDate(date) +
      " " +
      String(date.getHours()).padStart(2, "0") +
      ":" +
      String(date.getMinutes()).padStart(2, "0")
    );
  };

  const setPhotos = (e) => {
    let photos = [];
    for (let i = 0; i < e.target.files.length; i++) {
      photos.push(e.target.files[i]);
    }
    dispatch({ type: "SET_FILES", payload: photos });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Grid container spacing={3} marginBottom={2}>
        <Grid item>
          <Typography variant="h4">{t("pipeline")}</Typography>
        </Grid>
        <Grid item display="flex" justifyContent="end">
          <Button
            startIcon={<ArrowBackIcon />}
            size="small"
            onClick={() => {
              history.goBack();
            }}
          >
            {t("back")}
          </Button>
        </Grid>
      </Grid>
      <AppBar position="static" sx={{ marginTop: 1 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          TabIndicatorProps={{
            style: {
              borderBottom: "2px solid white",
            },
          }}
        >
          <Tab label={t("generalInfo")} {...a11yProps(0)} />
          <Tab label={t("visits")} {...a11yProps(1)} />
          <Tab label={t("documents")} {...a11yProps(2)} />
          <Tab label={t("images")} {...a11yProps(3)} />
          <Tab label={t("comments")} {...a11yProps(4)} />
          <Tab label={t("tracker")} {...a11yProps(5)} />
          <Tab label={t("costs")} {...a11yProps(6)} />
        </Tabs>
      </AppBar>

      {/*General info tab*/}
      <TabPanel value={value} index={0}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={1}>
              {/* General Info */}
              <Grid container item xs={6} direction="column">
                <Grid container item spacing={1}>
                  <Grid item xs={6}>
                    <TextInput
                      error={state.inputError.name}
                      helperText={
                        state.inputError.name
                          ? t("name") + " " + t("mustNotBeBlank")
                          : ""
                      }
                      label={t("name")}
                      name="name"
                      value={state.form.name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CityInput
                      value={state.form.City}
                      name="City"
                      onChange={handleInputChange}
                      label={t("city")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      error={state.inputError.address}
                      helperText={
                        state.inputError.address
                          ? t("address") + " " + t("mustNotBeBlank")
                          : ""
                      }
                      label={t("address")}
                      name="address"
                      value={state.form.address}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextInput
                      label={t("source")}
                      name="source"
                      value={state.form.source}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomSelect
                      label={t("priority")}
                      name="priority"
                      value={state.form.priority || ""}
                      onChange={handleInputChange}
                      options={[
                        "underExclusivity",
                        "active",
                        "onHold",
                        "declined",
                        "closed",
                      ].map((item) => {
                        return { value: item, label: t(item) };
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Map */}
              <Grid container item xs={6} spacing={2}>
                <Grid item xs={12}>
                  <Map
                    lat={state.form.latitude}
                    lng={state.form.longitude}
                    address={state.form.address + ", " + state.form.City?.name}
                    setCoordinates={setCoordinates}
                  />
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item flex={4}>
                      <TextInput
                        error={state.inputError.cadastralReference}
                        helperText={
                          state.inputError.cadastralReference
                            ? t("cadastralReference") +
                              " " +
                              t("mustNotBeBlank")
                            : ""
                        }
                        name="cadastralReference"
                        value={state.form.cadastralReference}
                        onChange={handleInputChange}
                        label={t("cadastralReference")}
                      />
                    </Grid>
                    <Grid item flex={1}>
                      <Tooltip
                        title={t("searchCadastralReference")}
                        placement="top"
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleCadastralReferenceSearch}
                        >
                          <SearchIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="link"
                      value={state.form.link}
                      onChange={handleInputChange}
                      label={t("link")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      name="contact"
                      value={state.form.contact || ""}
                      onChange={handleInputChange}
                      label={t("contactInfo")}
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid container item spacing={1} xs={12}>
              <Grid item xs={3}>
                <TextInput
                  type="number"
                  error={state.inputError.aditionalConstructability}
                  helperText={
                    state.inputError.aditionalConstructability
                      ? t("aditionalConstructability") +
                        " " +
                        t("mustNotBeBlank")
                      : ""
                  }
                  value={state.form.aditionalConstructability}
                  name="aditionalConstructability"
                  onChange={handleInputChange}
                  label={t("aditionalConstructability")}
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  type="number"
                  error={state.inputError.currentArea}
                  helperText={
                    state.inputError.currentArea
                      ? t("currentArea") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  value={state.form.currentArea}
                  name="currentArea"
                  onChange={handleInputChange}
                  label={t("currentArea")}
                />
              </Grid>
              <Grid item xs={3}>
                {t("gia")}:{" "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(GIA)} m2
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  type="number"
                  error={state.inputError.lettableAreaRatio}
                  helperText={
                    state.inputError.lettableAreaRatio
                      ? t("lettableAreaRatio") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  value={state.form.lettableAreaRatio}
                  name="lettableAreaRatio"
                  onChange={handleInputChange}
                  label={t("lettableAreaRatio")}
                />
              </Grid>
              <Grid item xs={3}>
                {t("lettableArea")}:{" "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(lettableArea)} m2
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container item spacing={1} xs={6}>
              <Grid item xs={6}>
                <TextInput
                  type="number"
                  error={state.inputError.acquisitionPrice}
                  helperText={
                    state.inputError.acquisitionPrice
                      ? t("acquisitionPrice") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  value={state.form.acquisitionPrice}
                  name="acquisitionPrice"
                  onChange={handleInputChange}
                  label={t("acquisitionPrice")}
                />
              </Grid>
              <Grid item xs={6}>
                {t("price")}:{" "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(state.form.acquisitionPrice / GIA || 0)}€
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {t("capexCosts")}:{" "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(capexCosts)}€
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  type="number"
                  error={state.inputError.capexCostSqm}
                  helperText={
                    state.inputError.capexCostSqm
                      ? t("capexCostSqm") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  value={state.form.capexCostSqm}
                  name="capexCostSqm"
                  onChange={handleInputChange}
                  label={t("capexCost")}
                />
              </Grid>
              <Grid item xs={4}>
                {t("acquisitionCosts")}:{" "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(acquisitionCost)}€
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TextInput
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  type="number"
                  value={state.form.acquisitionCostRatio}
                  name="acquisitionCostRatio"
                  onChange={handleInputChange}
                  label={t("acquisitionCostRatio")}
                />
              </Grid>

              <Grid item xs={6}>
                {t("acquisitionCost")}:{" "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(acquisitionCost / GIA || 0)}€
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {t("aic")}:{" "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(AIC)}€
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {t("aic")} {"/ m2: "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(AIC / GIA || 0)}€
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body" sx={{ fontSize: "1.5rem" }}>
                  {t("yield") + ": "}
                </Typography>
                <Typography
                  variant="body"
                  fontWeight="900"
                  sx={{ fontSize: "1.5rem" }}
                >
                  {localeFormat(NetYield)}%
                </Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={1} xs={6}>
              <Grid item xs={6}>
                <TextInput
                  type="number"
                  error={state.inputError.occupancy}
                  helperText={
                    state.inputError.occupancy
                      ? t("occupancy") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  value={state.form.occupancy}
                  name="occupancy"
                  onChange={handleInputChange}
                  label={t("occupancy")}
                />
              </Grid>
              <Grid item xs={6}></Grid>

              <Grid item xs={6}>
                <TextInput
                  type="number"
                  error={state.inputError.sqmPriceMonth}
                  helperText={
                    state.inputError.sqmPriceMonth
                      ? t("sqmPriceMonth") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  value={state.form.sqmPriceMonth}
                  name="sqmPriceMonth"
                  onChange={handleInputChange}
                  label={t("sqmPriceMonth")}
                />
              </Grid>
              <Grid item xs={6}>
                {t("potentialDay")}{" "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(potentialERV)}€
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextInput
                  type="number"
                  error={state.inputError.operatingCostsSqm}
                  helperText={
                    state.inputError.operatingCostsSqm
                      ? t("operatingCostsSqm") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  value={state.form.operatingCostsSqm}
                  name="operatingCostsSqm"
                  onChange={handleInputChange}
                  label={t("operatingCostsSqm")}
                />
              </Grid>
              <Grid item xs={6}>
                {t("dayOperatingCosts")}:{" "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(operatingCosts)}€
                </Typography>
              </Grid>

              <Grid item xs={6}>
                {t("archived")}:{" "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(achievedERV)}€
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {t("archived")} {"/ m2: "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(
                    achievedERV / state.form.lettableAreaRatio / 12 || 0
                  )}
                  €
                </Typography>
              </Grid>

              <Grid item xs={6}>
                {t("noi")}:{" "}
                <Typography variant="span" fontWeight="900">
                  {localeFormat(NOI)}€
                </Typography>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("offer")}</Typography>
              </Grid>
              <Grid container item spacing={1} xs={12}>
                <Grid item>
                  <TextInput
                    type="number"
                    value={state.form.acquisitionPrice}
                    name="acquisitionPrice"
                    onChange={handleInputChange}
                    label={t("offer")}
                  />
                </Grid>
                <Grid item display="flex" alignItems="center">
                  {t("offer")} {"/ m2: "}
                  <Typography variant="span" fontWeight="900">
                    {localeFormat(state.form.acquisitionPrice / GIA || 0)}€
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="offerSent"
                        checked={state.form.offerSent}
                        onClick={(e) => {
                          dispatch({
                            type: "SET_INPUT",
                            payload: {
                              inputname: e.target.name,
                              value: e.target.checked,
                            },
                          });
                        }}
                      />
                    }
                    label={t("offerSent")}
                    labelPlacement="start"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextInput
                    label={t("offerComments")}
                    multiline
                    rows={3}
                    onChange={handleInputChange}
                    value={state.form.offerComments || ""}
                    name="offerComments"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1} justifyContent="flex-end">
              {user.hasAction("DELETE_ACQUISITION_ASSET") && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      handleDeleteAcquisitionAsset();
                    }}
                  >
                    {t("delete")}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => submit()}
                >
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </TabPanel>

      {/*Visits tab*/}
      <TabPanel value={value} index={1}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item>
              <Button
                onClick={() => {
                  dispatch({ type: "OPEN_VISIT_DIALOG" });
                }}
              >
                {t("addVisit")}
              </Button>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              {state.visits.map((visit) => (
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="h6" display="inline">
                          {visit.visitors}
                        </Typography>
                        <Typography variant="h6" display="inline">
                          {visit.date}
                        </Typography>
                      </Box>
                      <Typography variant="body1">{visit?.comments}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
        <Dialog
          open={state.visitDialog.isOpen}
          onClose={() => {
            closeVisitDialog();
          }}
          maxWidth="lg"
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs>
                  <TextInput
                    label={t("visitors")}
                    name="visitors"
                    onChange={handleVisitInputChange}
                    value={state.visitDialog.visitors}
                  />
                </Grid>
                <Grid item>
                  <DateInput
                    label={t("visitDate")}
                    name="date"
                    onChange={handleVisitInputChange}
                    value={state.visitDialog.date}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t("comments")}
                  multiline
                  rows={4}
                  name="comments"
                  onChange={handleVisitInputChange}
                  value={state.visitDialog.comments || ""}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={createVisit}>{t("add")}</Button>
          </DialogActions>
        </Dialog>
      </TabPanel>

      {/*Documents tab*/}
      <TabPanel value={value} index={2}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  dispatch({ type: "OPEN_UPLOAD_FILE_DIALOG" });
                }}
              >
                {t("addNewFile")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <AccordionFolder
                name="Info received"
                seeDocument={seeDocument}
                downloadDocument={downloadDocument}
                deleteDocument={openConfirmDocument}
                isOpen={state.folders["Info received"].isOpen}
                documents={state.documents}
                switchOpenFolder={switchOpenFolder}
              />
              <AccordionFolder
                name="Deal"
                seeDocument={seeDocument}
                downloadDocument={downloadDocument}
                deleteDocument={openConfirmDocument}
                isOpen={state.folders["Deal"].isOpen}
                documents={state.documents}
                switchOpenFolder={switchOpenFolder}
              />
              <AccordionFolder
                name="Letter"
                seeDocument={seeDocument}
                downloadDocument={downloadDocument}
                deleteDocument={openConfirmDocument}
                isOpen={state.folders["Letter"].isOpen}
                documents={state.documents}
                switchOpenFolder={switchOpenFolder}
              />
              <AccordionFolder
                name="DD"
                seeDocument={seeDocument}
                downloadDocument={downloadDocument}
                deleteDocument={openConfirmDocument}
                isOpen={state.folders["DD"].isOpen}
                documents={state.documents}
                switchOpenFolder={switchOpenFolder}
              >
                <AccordionFolder
                  name="KYC"
                  seeDocument={seeDocument}
                  downloadDocument={downloadDocument}
                  deleteDocument={openConfirmDocument}
                  isOpen={state.folders["KYC"].isOpen}
                  documents={state.documents}
                  switchOpenFolder={switchOpenFolder}
                />
                <AccordionFolder
                  name="Tecnica"
                  seeDocument={seeDocument}
                  downloadDocument={downloadDocument}
                  deleteDocument={openConfirmDocument}
                  isOpen={state.folders["Tecnica"].isOpen}
                  documents={state.documents}
                  switchOpenFolder={switchOpenFolder}
                />
                <AccordionFolder
                  name="Legal"
                  seeDocument={seeDocument}
                  downloadDocument={downloadDocument}
                  deleteDocument={openConfirmDocument}
                  isOpen={state.folders["Legal"].isOpen}
                  documents={state.documents}
                  switchOpenFolder={switchOpenFolder}
                />
              </AccordionFolder>
              <AccordionFolder
                name="IC"
                seeDocument={seeDocument}
                downloadDocument={downloadDocument}
                deleteDocument={openConfirmDocument}
                isOpen={state.folders["IC"].isOpen}
                documents={state.documents}
                switchOpenFolder={switchOpenFolder}
              />
              <AccordionFolder
                name="Otros"
                seeDocument={seeDocument}
                downloadDocument={downloadDocument}
                deleteDocument={openConfirmDocument}
                isOpen={state.folders["Otros"].isOpen}
                documents={state.documents}
                switchOpenFolder={switchOpenFolder}
              />
            </Grid>
          </Grid>
        </Container>
        {/* Upload file dialog */}
        <Dialog
          open={state.uploadFileDialog.isOpen}
          onClose={closeUploadFileDialog}
        >
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {t("uploadNewFile")}
            <IconButton onClick={closeUploadFileDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                {t("file")}
                {": "}
                <Box component="span" fontWeight="900">
                  {state.uploadFileDialog.file?.name}
                </Box>
              </Grid>
              <Grid item>
                <Button component="label" size="small">
                  {t("selectFile")}
                  <input
                    id="fileInput"
                    type="file"
                    hidden
                    name="file"
                    multiple
                    onChange={(e) => {
                      setPhotos(e)
                    }}
                  />
                </Button>
              </Grid>
              <Grid item>
                {t("category")}
                {": "}
              </Grid>
              <Grid item>
                <CustomSelect
                  value={state.uploadFileDialog.category}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_FILE_CATEGORY",
                      payload: e.target.value,
                    })
                  }
                  size="small"
                  options={[
                    "Info received",
                    "Deal",
                    "Letter",
                    "DD",
                    "IC",
                    "KYC",
                    "Tecnica",
                    "Legal",
                    "Otros",
                  ].map((category) => {
                    return { value: category, label: category };
                  })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<BackupIcon />}
              onClick={createDocument}
              disabled={
                !state.uploadFileDialog.files || !state.uploadFileDialog.category
              }
            >
              {t("upload")}
            </Button>
          </DialogActions>
        </Dialog>
      </TabPanel>

      {/*Images tab*/}
      <TabPanel value={value} index={3}>
        <Container maxWidth="lg">
          <Grid item container spacing={1} xs={12}>
            <Grid item xs={2} minWidth={200}>
              <Button
                component="label"
                variant="contained"
                startIcon={<AddPhotoAlternateIcon />}
              >
                {t("addImage")}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    addMedia(e);
                  }}
                  hidden
                />
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              marginTop={8}
              marginBottom={4}
              marginLeft={1}
              marginRight={1}
            >
              <ImageList
                sx={{ minWidth: 175, mb: 8 }}
                cols={isSmWindow ? 1 : isMdWindow ? 2 : 3}
                variant="quilted"
                rowHeight={200}
                gap={8}
              >
                {state.images.map((item) => {
                  return (
                    <ImageListItem sx={{ height: "100%" }}>
                      <img
                        src={`${
                          SERVER + "/acquisition-asset-media/" + id + "/" + item
                        }`}
                        srcSet={`${
                          SERVER + "/acquisition-asset-media/" + id + "/" + item
                        }`}
                        style={{ objectFit: "scale-down", cursor: "pointer" }}
                      />
                      <IconButton
                        sx={{
                          position: "absolute",
                          left: "84%",
                          color: "gray",
                        }}
                        onClick={() => {
                          dispatch({
                            type: "CHANGE_IMG_SELECT_TO_DELETE",
                            payload: item,
                          });
                          handleOpenStatusDeleteImgConfirmDialog(true);
                        }}
                      >
                        <DeleteRoundedIcon />
                      </IconButton>
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </Grid>
          </Grid>

          {/* Confirm freeze request modal */}
          <ConfirmDialog
            title={t("titleConfirmDeleteImage")}
            open={state.delImgReqModal.isOpen}
            confirmText={t("delete")}
            cancelText={t("cancel")}
            setOpen={handleOpenStatusDeleteImgConfirmDialog}
            onConfirm={(confirm) => {
              confirm && deleteMedia();
            }}
          >
            <Alert severity="warning">{t("thisActionCanNotBeUndone")}</Alert>
          </ConfirmDialog>
        </Container>
      </TabPanel>

      {/*Comments tab*/}
      <TabPanel value={value} index={4}>
        <Grid item xs={12}>
          <Box maxHeight="320px" overflow="auto">
            <Grid container spacing={2}>
              <Grid item container spacing={1} xs={12}>
                {Array.isArray(state.comments) &&
                  state.comments
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((comment, index) => (
                      <Grid item xs={12} key={index}>
                        <Box bgcolor="#f1f2f2" borderRadius={2} p={2}>
                          <Grid container spacing={1}>
                            <Grid item>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                style={{ wordWrap: "break-word" }}
                              >
                                {getFormattedDateTime(comment.createdAt)} -{" "}
                                {comment.authorName || t("system")}:
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="body1"
                                style={{
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {comment.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ))}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {state.comments.length > 0 && (
          <Grid item xs={12} sx={{ m: 2 }}>
            <Divider />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={10}>
              <TextField
                multiline
                variant="outlined"
                label={t("comment")}
                name="comment"
                value={state.form.comment || ""}
                onChange={handleInputChange}
                fullWidth
                rows={2}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => addComment()}
                fullWidth
                disabled={state.newComment === ""}
              >
                {t("addComment")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>

      {/* Tracker tab*/}
      <TabPanel value={value} index={5}>
        <Stepper orientation="vertical">
          <Step key={0} active={true}>
            <StepLabel>
              <Typography variant="h6">{t("nbo")}</Typography>
            </StepLabel>
            <StepContent>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    label={t("lead")}
                    variant="outlined"
                    name="lead"
                    id="nbo-1"
                    value={state.tracker[0]?.lead}
                    onChange={(e) => {
                      trackerInputChange(e);
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "nbo-2",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label={t("advisor")}
                    variant="outlined"
                    name="advisor"
                    id="nbo-1"
                    value={state.tracker[0]?.advisor}
                    onChange={(e) => {
                      trackerInputChange(e);
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "nbo-2",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant="body">{t("phase1NBO")}</Typography>
                  </Grid>
                  <Grid item>
                    <TrackerStatusChip status={state.tracker[0]?.status} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="nbo-1"
                    value={state.tracker[0]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="nbo-1"
                    value={state.tracker[0]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    name="comments"
                    id="nbo-1"
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[0]?.comments || ""}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    onClick={() => editTrackerPhase("nbo-1")}
                    //disabled={!state.tracker.NBO1 || !state.tracker.NBO2}
                  >
                    {t("save")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant="body">{t("phase2NBO")}</Typography>
                  </Grid>
                  <Grid item>
                    <TrackerStatusChip status={state.tracker[1]?.status} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="nbo-2"
                    value={state.tracker[1]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="nbo-2"
                    value={state.tracker[1]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[1]?.comments || ""}
                    name="comments"
                    id="nbo-2"
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("nbo-2")}>
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          </Step>

          <Step key={1} active={true}>
            <StepLabel>
              <Typography variant="h6">{t("comercialDD")}</Typography>
            </StepLabel>
            <StepContent>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    label={t("lead")}
                    variant="outlined"
                    name="lead"
                    id="commercial-1"
                    value={state.tracker[2]?.lead}
                    onChange={(e) => {
                      trackerInputChange(e);
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "commercial-2",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label={t("advisor")}
                    variant="outlined"
                    name="advisor"
                    id="commercial-1"
                    value={state.tracker[2]?.advisor}
                    onChange={(e) => {
                      trackerInputChange(e);
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "commercial-2",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant="body">
                      {t("phase1Commercial")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TrackerStatusChip status={state.tracker[2]?.status} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="commercial-1"
                    value={state.tracker[2]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="commercial-1"
                    value={state.tracker[2]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    name="comments"
                    id="commercial-1"
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[2]?.comments || ""}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    onClick={() => editTrackerPhase("commercial-1")}
                    //disabled={!state.tracker.NBO1 || !state.tracker.NBO2}
                  >
                    {t("save")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant="body">
                      {t("phase2Commercial")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TrackerStatusChip status={state.tracker[3]?.status} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="commercial-2"
                    value={state.tracker[3]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="commercial-2"
                    value={state.tracker[3]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[3]?.comments || ""}
                    name="comments"
                    id="commercial-2"
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("commercial-2")}>
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          </Step>

          <Step key={2} active={true}>
            <StepLabel>
              <Typography variant="h6">{t("technicalDD")}</Typography>
            </StepLabel>
            <StepContent>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    label={t("lead")}
                    variant="outlined"
                    name="lead"
                    id="technical-1"
                    value={state.tracker[4]?.lead}
                    onChange={(e) => {
                      trackerInputChange(e);
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "technical-2",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "technical-3",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label={t("advisor")}
                    variant="outlined"
                    name="advisor"
                    id="technical-1"
                    value={state.tracker[4]?.advisor}
                    onChange={(e) => {
                      trackerInputChange(e);
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "technical-2",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "technical-3",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant="body">
                      {t("phase1Technical")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TrackerStatusChip status={state.tracker[4]?.status} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="technical-1"
                    value={state.tracker[4]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="technical-1"
                    value={state.tracker[4]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    name="comments"
                    id="technical-1"
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[4]?.comments || ""}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("technical-1")}>
                    {t("save")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant="body">
                      {t("phase2Technical")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TrackerStatusChip status={state.tracker[5]?.status} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="technical-2"
                    value={state.tracker[5]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="technical-2"
                    value={state.tracker[5]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[5]?.comments || ""}
                    name="comments"
                    id="technical-2"
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("technical-2")}>
                    {t("save")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant="body">
                      {t("phase3Technical")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TrackerStatusChip status={state.tracker[6]?.status} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="technical-3"
                    value={state.tracker[6]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="technical-3"
                    value={state.tracker[6]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[6]?.comments || ""}
                    name="comments"
                    id="technical-3"
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("technical-3")}>
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          </Step>

          <Step key={3} active={true}>
            <StepLabel>
              <Typography variant="h6">{t("legalDD")}</Typography>
            </StepLabel>
            <StepContent>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    label={t("lead")}
                    variant="outlined"
                    name="lead"
                    id="legal-1"
                    value={state.tracker[7]?.lead}
                    onChange={(e) => {
                      trackerInputChange(e);
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "legal-2",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label={t("advisor")}
                    variant="outlined"
                    name="advisor"
                    id="legal-1"
                    value={state.tracker[7]?.advisor}
                    onChange={(e) => {
                      trackerInputChange(e);
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "legal-2",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant="body">{t("phase1Legal")}</Typography>
                  </Grid>
                  <Grid item>
                    <TrackerStatusChip status={state.tracker[7]?.status} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="legal-1"
                    value={state.tracker[7]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="legal-1"
                    value={state.tracker[7]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    name="comments"
                    id="legal-1"
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[7]?.comments || ""}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("legal-1")}>
                    {t("save")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant="body">{t("phase2Legal")}</Typography>
                  </Grid>
                  <Grid item>
                    <TrackerStatusChip status={state.tracker[8]?.status} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="legal-2"
                    value={state.tracker[8]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="legal-2"
                    value={state.tracker[8]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[8]?.comments || ""}
                    name="comments"
                    id="legal-2"
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("legal-2")}>
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          </Step>

          <Step key={4} active={true}>
            <StepLabel>
              <Grid item container xs={12} spacing={2}>
                <Grid item>
                  <Typography variant="h6">{t("approvals")}</Typography>
                </Grid>
                <Grid item>
                  <TrackerStatusChip status={state.tracker[9]?.status} />
                </Grid>
              </Grid>
            </StepLabel>
            <StepContent>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    label={t("lead")}
                    variant="outlined"
                    name="lead"
                    id="approvals"
                    value={state.tracker[9]?.lead}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label={t("advisor")}
                    variant="outlined"
                    name="advisor"
                    id="approvals"
                    value={state.tracker[9]?.advisor}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="approvals"
                    value={state.tracker[9]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="approvals"
                    value={state.tracker[9]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    name="comments"
                    id="approvals"
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[9]?.comments || ""}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("approvals")}>
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          </Step>

          <Step key={5} active={true}>
            <StepLabel>
              <Grid item container xs={12} spacing={2}>
                <Grid item>
                  <Typography variant="h6">{t("spa")}</Typography>
                </Grid>
                <Grid item>
                  <TrackerStatusChip status={state.tracker[10]?.status} />
                </Grid>
              </Grid>
            </StepLabel>
            <StepContent>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    label={t("lead")}
                    variant="outlined"
                    name="lead"
                    id="spa"
                    value={state.tracker[10]?.lead}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label={t("advisor")}
                    variant="outlined"
                    name="advisor"
                    id="spa"
                    value={state.tracker[10]?.advisor}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="spa"
                    value={state.tracker[10]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="spa"
                    value={state.tracker[10]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    name="comments"
                    id="spa"
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[10]?.comments || ""}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("spa")}>
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          </Step>

          <Step key={6} active={true}>
            <StepLabel>
              <Grid item container xs={12} spacing={2}>
                <Grid item>
                  <Typography variant="h6">{t("notarialReview")}</Typography>
                </Grid>
                <Grid item>
                  <TrackerStatusChip status={state.tracker[10]?.status} />
                </Grid>
              </Grid>
            </StepLabel>
            <StepContent>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    label={t("lead")}
                    variant="outlined"
                    name="lead"
                    id="review"
                    value={state.tracker[11]?.lead}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label={t("advisor")}
                    variant="outlined"
                    name="advisor"
                    id="review"
                    value={state.tracker[11]?.advisor}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="review"
                    value={state.tracker[11]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="review"
                    value={state.tracker[11]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    name="comments"
                    id="review"
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[11]?.comments || ""}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("review")}>
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          </Step>

          <Step key={7} active={true}>
            <StepLabel>
              <Typography variant="h6">{t("capitalCall")}</Typography>
            </StepLabel>
            <StepContent>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    label={t("lead")}
                    variant="outlined"
                    name="lead"
                    id="capital-1"
                    value={state.tracker[12]?.lead}
                    onChange={(e) => {
                      trackerInputChange(e);
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "capital-2",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label={t("advisor")}
                    variant="outlined"
                    name="advisor"
                    id="capital-1"
                    value={state.tracker[12]?.advisor}
                    onChange={(e) => {
                      trackerInputChange(e);
                      dispatch({
                        type: "SET_TRACKER_INPUT",
                        payload: {
                          type: "capital-2",
                          inputname: e.target.name,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant="body">{t("phase1Capital")}</Typography>
                  </Grid>
                  <Grid item>
                    <TrackerStatusChip status={state.tracker[12]?.status} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="capital-1"
                    value={state.tracker[12]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="capital-1"
                    value={state.tracker[12]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    name="comments"
                    id="capital-1"
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[12]?.comments || ""}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("capital-1")}>
                    {t("save")}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item>
                    <Typography variant="body">{t("phase2Capital")}</Typography>
                  </Grid>
                  <Grid item>
                    <TrackerStatusChip status={state.tracker[13]?.status} />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="capital-2"
                    value={state.tracker[13]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="capital-2"
                    value={state.tracker[13]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[13]?.comments || ""}
                    name="comments"
                    id="capital-2"
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("capital-2")}>
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          </Step>

          <Step key={8} active={true}>
            <StepLabel>
              <Grid item container xs={12} spacing={2}>
                <Grid item>
                  <Typography variant="h6">{t("closing")}</Typography>
                </Grid>
                <Grid item>
                  <TrackerStatusChip status={state.tracker[14]?.status} />
                </Grid>
              </Grid>
            </StepLabel>
            <StepContent>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextInput
                    label={t("lead")}
                    variant="outlined"
                    name="lead"
                    id="closing"
                    value={state.tracker[14]?.lead}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label={t("advisor")}
                    variant="outlined"
                    name="advisor"
                    id="closing"
                    value={state.tracker[14]?.advisor}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("startDate")}
                    variant="outlined"
                    name="startDate"
                    id="closing"
                    value={state.tracker[14]?.startDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomDate
                    label={t("endDate")}
                    variant="outlined"
                    name="endDate"
                    id="closing"
                    value={state.tracker[14]?.endDate}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("comments")}
                    name="comments"
                    id="closing"
                    multiline
                    rows={3}
                    onChange={(e) => {
                      trackerInputChange(e);
                    }}
                    value={state.tracker[14]?.comments || ""}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button onClick={() => editTrackerPhase("closing")}>
                    {t("save")}
                  </Button>
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        </Stepper>
      </TabPanel>

      {/*Costs tab */}
      <TabPanel value={value} index={6}>
        <Container maxWidth="lg" spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                startIcon={<AddIcon />}
                onClick={() => setCostDialogOpen(false)}
              >
                {t("addCost")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{t("summary")}</Typography>
            </Grid>
            <Box width="100%" overflow="auto" padding={1}>
              <Grid container item spacing={1} minWidth={500}>
                <Grid item xs={0} lg={2}></Grid>
                <Grid
                  container
                  item
                  sx={{ borderBottom: 1, borderColor: "lightgray" }}
                  columnSpacing={1}
                  xs={12}
                  lg={8}
                >
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontWeight="bold">
                      {t("budget")}
                      {" (€)"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontWeight="bold">
                      {t("total")}
                      {" (€)"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontWeight="bold">
                      {t("difference")}
                      {" (%)"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={0} lg={2}></Grid>
                <Grid item xs={0} lg={2}></Grid>
                <Grid container item columnSpacing={1} xs={12} lg={8}>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontWeight="medium">
                      {t("asset")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {localeFormat(state.form.acquisitionPrice || 0) + " €"}
                  </Grid>
                  <Grid item xs={3}>
                    {localeFormat(totalAssets || 0) + " €"}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      color={
                        assetDifference > 0
                          ? theme.palette.error.main
                          : theme.palette.success.main
                      }
                      fontWeight="medium"
                    >
                      {localeFormat(assetDifference || 0) + " %"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={0} lg={2}></Grid>
                <Grid item xs={0} lg={2}></Grid>
                <Grid
                  container
                  item
                  columnSpacing={1}
                  xs={12}
                  lg={8}
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography variant="body1" fontWeight="medium">
                      {t("acquisitionCosts")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {localeFormat(acquisitionCost || 0) + " €"}
                  </Grid>
                  <Grid item xs={3}>
                    {localeFormat(totalOthers || 0) + " €"}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      color={
                        othersDifference > 0
                          ? theme.palette.error.main
                          : theme.palette.success.main
                      }
                      fontWeight="medium"
                    >
                      {localeFormat(othersDifference || 0) + " %"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={0} lg={2}></Grid>
                <Grid item xs={0} lg={2}></Grid>
                <Grid container item columnSpacing={1} xs={12} lg={8}>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontWeight="medium">
                      {t("capex")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {localeFormat(capexCosts || 0) + " €"}
                  </Grid>
                  <Grid item xs={3}>
                    {localeFormat(totalCapex || 0) + " €"}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      color={
                        capexDifference > 0
                          ? theme.palette.error.main
                          : theme.palette.success.main
                      }
                      fontWeight="medium"
                    >
                      {localeFormat(capexDifference || 0) + " %"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={0} lg={2}></Grid>
                <Grid item xs={0} lg={2}></Grid>
                <Grid
                  container
                  item
                  sx={{ borderTop: 1, borderColor: "lightgray" }}
                  columnSpacing={1}
                  xs={12}
                  lg={8}
                >
                  <Grid item xs={3}>
                    <Typography variant="body1" fontWeight="medium">
                      {t("total")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontWeight="medium">
                      {localeFormat(
                        state.form.acquisitionPrice +
                          acquisitionCost +
                          capexCosts || 0
                      ) + "€"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" fontWeight="medium">
                      {localeFormat(totalCosts || 0) + "€"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      color={
                        totalDifference > 0
                          ? theme.palette.error.main
                          : theme.palette.success.main
                      }
                      fontWeight="medium"
                    >
                      {localeFormat(totalDifference || 0) + "%"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{t("assetCosts")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={state.form.AcquisitionAssetCosts}
                  columns={ASSET_COSTS_COLUMNS}
                  loading={state.acquisitionAssetLoading}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Dialog
          open={state.costsDialog.isOpen}
          onClose={setCostDialogClosed}
          maxWidth="md"
        >
          <DialogTitle textAlign="right">
            {state.costsDialog.editMode ? t("editCost") : t("addCost")}
            <IconButton onClick={setCostDialogClosed}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={4}>
                <TextInput
                  label={t("invoiceNumber")}
                  name="invoiceNumber"
                  variant="outlined"
                  onChange={changeCostInput}
                  value={state.costsDialog.invoiceNumber}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomSelect
                  label={t("type")}
                  value={state.costsDialog.type}
                  onChange={changeCostInput}
                  name="type"
                  options={state.acquisitionCostTypes?.map((type) => ({
                    value: type,
                    label: t(type),
                  }))}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  label={t("amount")}
                  name="amount"
                  variant="outlined"
                  type="number"
                  onChange={changeCostInput}
                  value={state.costsDialog.amount}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomDate
                  label={t("costDate")}
                  variant="outlined"
                  name="date"
                  value={state.costsDialog.date}
                  onChange={changeCostInput}
                />
              </Grid>
              <Grid item xs={8}>
                <TextInput
                  label={t("description")}
                  name="description"
                  variant="outlined"
                  onChange={changeCostInput}
                  value={state.costsDialog.description}
                />
              </Grid>
              <Grid item sx={{ m: 1 }} xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">{t("invoiceProvider")}</Typography>
              </Grid>
              <Grid item>
                <TextInput
                  label={t("search")}
                  name="search"
                  value={state.providerInvoiceFilter.search}
                  onChange={(e) => {
                    changeProviderInvoiceInput(e);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      getProviderInvoices();
                    }
                  }}
                ></TextInput>
              </Grid>
              <Grid item>
                <CustomDate
                  value={state.providerInvoiceFilter.dateFrom}
                  name="dateFrom"
                  onChange={(e) => {
                    changeProviderInvoiceInput(e);
                  }}
                  label={t("dateFrom")}
                />
              </Grid>
              <Grid item>
                <CustomDate
                  value={state.providerInvoiceFilter.dateUntil}
                  name="dateUntil"
                  onChange={(e) => {
                    changeProviderInvoiceInput(e);
                  }}
                  label={t("dateUntil")}
                />
              </Grid>
              <Grid item>
                <TextInput
                  value={state.providerInvoiceFilter.minImport}
                  name="importMin"
                  onChange={(e) => {
                    changeProviderInvoiceInput(e);
                  }}
                  label={t("importMin")}
                  type="number"
                />
              </Grid>
              <Grid item>
                <TextInput
                  value={state.providerInvoiceFilter.maxImport}
                  name="importMax"
                  onChange={(e) => {
                    changeProviderInvoiceInput(e);
                  }}
                  label={t("importMax")}
                  type="number"
                />
              </Grid>
              <Grid item>
                <SearchButton
                  onClick={getProviderInvoices}
                  loading={state.costsDialog.invoicesLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <Box style={{ maxHeight: 400, width: "100%" }}>
                  <DataGrid
                    columns={PROVIDER_INVOICE_COLUMNS}
                    rows={state.costsDialog.invoices}
                    autoHeight
                    checkboxSelection
                    selectionModel={state.costsDialog.selectedInvoice}
                    isRowSelectable={(params) => params.row.selected === true}
                    onSelectionModelChange={(selectedId) => {
                      selectProviderInvoice(selectedId);
                    }}
                    loading={state.costsDialog.invoicesLoading}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="success"
              onClick={() =>
                !state.costsDialog.editMode
                  ? createAcquisitionCost()
                  : editAssetCost()
              }
            >
              {state.costsDialog.editMode ? t("edit") : t("add")}
            </Button>
          </DialogActions>
        </Dialog>
      </TabPanel>

      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        confirmText={state.confirmDialog.confirmText}
        cancelText={state.confirmDialog.cancelText}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
}
