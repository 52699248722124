import app from "./app.json";
import bankingTransactions from "./bankingTransactions.json";
import boxes from "./boxes.json";
import calls from "./calls.json";
import centers from "./centers.json";
import contracts from "./contracts.json";
import customers from "./customers.json";
import documents from "./documents.json";
import invoices from "./invoices.json";
import landing from "./landing.json";
import login from "./login.json";
import merchantables from "./merchantables.json";
import navbar from "./navbar.json";
import others from "./others.json";
import providerInvoices from "./providerInvoices.json";
import providers from "./providers.json";
import receipts from "./receipts.json";
import register from "./register.json";
import remittances from "./remittances.json";
import roles from "./roles.json";
import settings from "./settings.json";
import settlements from "./settlements.json";
import users from "./users.json";

const translations = {
  app,
  bankingTransactions,
  boxes,
  calls,
  centers,
  contracts,
  customers,
  documents,
  invoices,
  landing,
  login,
  merchantables,
  navbar,
  others,
  providerInvoices,
  providers,
  receipts,
  register,
  remittances,
  roles,
  settings,
  settlements,
  users,
};

export default translations;
