import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Switch,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PromotionContext } from "../PromotionPage";

import CenterSelect from "../../../Inputs/CenterSelect";
import CustomButton from "../../../Inputs/CustomButton";
import LabeledText from "../../../global/LabeledText";
import TextInput from "../../../Inputs/TextInput";

function EditTab() {
  const { promotion, periodicities, editPromotion } =
    useContext(PromotionContext);
  const history = useHistory();

  const [t] = useTranslation("promotion");
  const [data, setData] = useState(promotion);

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSwitchChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };

  const handlePeriodicityChange = (e) => {
    const { name, checked } = e.target;
    let selectedPeriodicities = data.selectedPeriodicities;
    if (checked) {
      const periodicity = periodicities.find(
        (periodicity) => periodicity.id.toString() === name
      );
      selectedPeriodicities.push(periodicity);
    } else {
      selectedPeriodicities = selectedPeriodicities.filter(
        (periodicity) => periodicity.id.toString() !== name
      );
    }
    setData({ ...data, selectedPeriodicities });
  };

  useEffect(() => setData(promotion), [promotion]);

  return data.id ? (
    <Grid container spacing={3} padding={1} marginTop={1}>
      {/* Basic Info */}
      <Grid item container spacing={2}>
        <Typography variant="h5">{t("basicInfo")}</Typography>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <LabeledText label={t("name")} value={data?.name} />
          </Grid>

          <Grid item xs={6}>
            <LabeledText label={t("temporality")} value={data?.temporality} />
          </Grid>
          <Grid item xs={6}>
            <LabeledText label={t("code")} value={data?.code} />
          </Grid>
          <Grid item xs={6}>
            <LabeledText
              label={t("discount")}
              value={`${data?.value}${
                data?.discountType === "percentage" ? "%" : "€"
              }`}
            />
          </Grid>

          <Grid item xs={12} marginTop={2}>
            <TextInput
              name="description"
              label={t("description")}
              value={data.description ?? ""}
              onChange={handleInputChange}
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} marginTop={1}>
        <Divider />
      </Grid>

      {/* Availability and restrictions */}
      <Grid item container spacing={1} marginTop={1}>
        <Typography variant="h5">{t("availabilityAndRestrictions")}</Typography>

        {/* Max number of uses */}
        <Grid item container spacing={2} xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6">{t("maxNumberOfUses")}</Typography>
          </Grid>
          <Grid item>
            <TextInput
              name="maxNumberOfUses"
              label={t("maxNumberOfUses")}
              value={data.maxNumberOfUses}
              onChange={handleInputChange}
              type="number"
            />
          </Grid>
        </Grid>

        {/* Dates */}
        <Grid item container spacing={2} xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6">{t("dates")}</Typography>
          </Grid>
          <Grid item>
            <TextInput
              name="validFrom"
              label={t("validFrom")}
              value={data.validFrom}
              onChange={handleInputChange}
              type="datetime-local"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                max: data.validUntil,
              }}
            />
          </Grid>
          <Grid item>
            <TextInput
              name="validUntil"
              label={t("validUntil")}
              onChange={handleInputChange}
              value={data.validUntil}
              type="datetime-local"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: data.validFrom,
              }}
            />
          </Grid>
        </Grid>

        {/* Periodicities */}
        <Grid item container xs={12} marginTop={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="span">
              {t("restrictedByPeriodicity")}
            </Typography>
            <Switch
              name="restrictedByPeriodicity"
              checked={data.restrictedByPeriodicity}
              onChange={handleSwitchChange}
            />
          </Grid>
          {data.restrictedByPeriodicity && (
            <Grid container item xs={12}>
              <FormGroup row>
                {periodicities.map((periodicity) => (
                  <FormControlLabel
                    key={periodicity.id}
                    onClick={handlePeriodicityChange}
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        name={periodicity.id.toString()}
                        checked={data.selectedPeriodicities?.some(
                          (p) => p.name === periodicity.name
                        )}
                      />
                    }
                    label={t(periodicity.name)}
                  />
                ))}
              </FormGroup>
            </Grid>
          )}
        </Grid>

        {/* Centers */}
        <Grid item container xs={12} marginTop={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="span">
              {t("restrictedByCenters")}
            </Typography>
            <Switch
              name="restrictedByCenters"
              onChange={handleSwitchChange}
              checked={data.restrictedByCenters}
            />
          </Grid>
          {data.restrictedByCenters && (
            <Grid item xs={12}>
              <CenterSelect
                name="selectedCenters"
                multiple
                value={data.selectedCenters ?? []}
                onChange={handleInputChange}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              name="active"
              checked={data.active}
              onClick={handleSwitchChange}
            />
          }
          label={t("promotionActive")}
        />
      </Grid>

      {/* Actions */}
      <Grid item container xs={12} spacing={1} justifyContent="flex-end">
        <Grid item>
          <CustomButton
            variant="text"
            color="primary"
            onClick={() => history.goBack()}
          >
            {t("back")}
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
            variant="contained"
            color="success"
            onClick={() => {
              editPromotion(data);
            }}
          >
            {t("save")}
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="65vh"
    >
      <CircularProgress />
    </Box>
  );
}

export default EditTab;
