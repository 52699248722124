import { Container, Grid, Link, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import i18n from "../../utils/i18n";
import LanguageSelect from "../Inputs/LanguageSelect";
import NewPasswordForm from "../NewPasswordForm";

export default function NewPasswordPage() {
  const { token } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("contracts");

  return (
    <Container>
      <Grid container sx={{ height: "100vh" }} padding={2}>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item>
            <Link to="/">
              <Box
                component="img"
                src="/img/logonut.png"
                height="50px"
                width="270px"
                sx={{ objectFit: "cover", objectPosition: "50% 43%" }}
              />
            </Link>
          </Grid>
          <Grid item>
            <LanguageSelect defaultLang={i18n.language} />
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            <NewPasswordForm token={token} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
