import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from '../translations/en';
import es from '../translations/es';
import pt from '../translations/pt';

// the translations
const resources = {
  en: en,
  es: es,
  pt: pt,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es", //default language

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;