import AcquisitionAssetPage from "../components/pages/Acquisitions/AcquisitionAssetPage";
import AcquisitionsAssetsPage from "../components/pages/Acquisitions/AcquisitionsAssetsPage";
import AltasBajasPage from "../components/pages/Contracts/AltasBajasPage";
import BankingTransactionsPage from "../components/pages/BankingTransactions/BankingTransactionsPage";
import BanksPage from "../components/pages/Remittances/BanksPage";
import BoxesPage from "../components/pages/Boxes/BoxesPage";
import BoxPage from "../components/pages/Boxes/BoxPage";
import CallPage from "../components/pages/Calls/CallPage";
import CallsPage from "../components/pages/Calls/CallsPage";
import CamerasPage from "../components/pages/Cameras/CamerasPage";
import CancellationRequestsPage from "../components/pages/Contracts/CancellationRequestsPage";
import CenterPage from "../components/pages/Centers/CenterPage";
import CentersPage from "../components/pages/Centers/CentersPage";
import ContractPage from "../components/pages/Contracts/ContractPage";
import ContractsPage from "../components/pages/Contracts/ContractsPage";
import ContractTypesPage from "../components/pages/Contracts/ContractTypesPage";
import CreateAcquisitionAssetPage from "../components/pages/Acquisitions/CreateAcquisitionAssetPage";
import CreateBoxPage from "../components/pages/Boxes/CreateBoxPage";
import CreateCallPage from "../components/pages/Calls/CreateCallPage";
import CreateCenterPage from "../components/pages/Centers/CreateCenterPage";
import CreateContractPage from "../components/pages/Contracts/CreateContractPage";
import CreateCustomerPage from "../components/pages/Customers/CreateCustomerPage";
import CreateDirectRemittancePage from "../components/pages/Remittances/CreateDirectRemittancePage";
import CreateInvoicePage from "../components/pages/Invoices/CreateInvoicePage";
import CreateMassEmailCenterPage from "../components/pages/MassEmails/CreateMassEmailCenterPage";
import CreateMassEmailCustomerPage from "../components/pages/MassEmails/CreateMassEmailCustomerPage";
import CreateMerchantablePage from "../components/pages/Merchantables/CreateMerchantablePage";
import CreatePromotionPage from "../components/pages/Promotions/CreatePromotionPage";
import CreateProviderInvoicePage from "../components/pages/ProviderInvoices/CreateProviderInvoicePage";
import CreateProviderPage from "../components/pages/Providers/CreateProviderPage";
import CreateReceiptPage from "../components/pages/Receipts/CreateReceiptPage";
import CreateRemittancePage from "../components/pages/Remittances/CreateRemittancePage";
import CreateSettlementPage from "../components/pages/Settlements/CreateSettlementPage";
import CreateUserPage from "../components/pages/Users/CreateUserPage";
import CustomerPage from "../components/pages/Customers/CustomerPage";
import CustomersPage from "../components/pages/Customers/CustomersPage";
import Dashboard from "../components/pages/Dashboard/Dashboard";
import DocumentsPage from "../components/pages/Documents/DocumentsPage";
import ExpenseTypePage from "../components/pages/ExpenseTypes/ExpenseTypePage";
import ExpenseTypesPage from "../components/pages/ExpenseTypes/ExpenseTypesPage";
import FloorPlansPage from "../components/pages/FloorPlans/FloorPlansPage";
import HTMLEditor from "../components/HTMLEditor";
import InvoiceEmailingPage from "../components/pages/Invoices/InvoiceEmailingPage";
import InvoicePage from "../components/pages/Invoices/InvoicePage";
import InvoicesPage from "../components/pages/Invoices/InvoicesPage";
import InvoicingPage from "../components/pages/Invoicing/InvoicingPage";
import MapsPage from "../components/pages/Maps/MapsPage";
import MassEmailPage from "../components/pages/MassEmails/MassEmailPage";
import MassEmailsPage from "../components/pages/MassEmails/MassEmailsPage";
import MerchantablePage from "../components/pages/Merchantables/MerchantablePage";
import MerchantablesPage from "../components/pages/Merchantables/MerchantablesPage";
import NonPaymentPage from "../components/pages/NonPayments/NonPaymentPage";
import NonPaymentsPage from "../components/pages/NonPayments/NonPaymentsPage";
import OccupancyPage from "../components/pages/OccupancyPage";
import PaycardsPage from "../components/pages/Invoices/PaycardsPage";
import PromotionsPage from "../components/pages/Promotions/PromotionsPage";
import PromotionPage from "../components/pages/Promotions/PromotionPage";
import ProviderInvoicePage from "../components/pages/ProviderInvoices/ProviderInvoicePage";
import ProviderInvoicesPage from "../components/pages/ProviderInvoices/ProviderInvoicesPage";
import ProviderPage from "../components/pages/Providers/ProviderPage";
import ProvidersPage from "../components/pages/Providers/ProvidersPage";
import ReceiptsPage from "../components/pages/Receipts/ReceiptsPage";
import RemittancePage from "../components/pages/Remittances/RemittancePage";
import RemittancesPage from "../components/pages/Remittances/RemittancesPage";
import ReturnedReceiptsPage from "../components/pages/Receipts/ReturnedReceiptsPage";
import RevenueManagement from "../components/pages/RevenueManagement/RevenuePage";
import RolePage from "../components/pages/Roles/RolePage";
import RolesPage from "../components/pages/Roles/RolesPage";
import SettingsPage from "../components/pages/Settings/SettingsPage";
import SettlementPage from "../components/pages/Settlements/SettlementPage";
import SettlementsPage from "../components/pages/Settlements/SettlementsPage";
import TransferPaymentPage from "../components/pages/TransferPayments/TransferPaymentPage";
import TransferPaymentsPage from "../components/pages/TransferPayments/TransferPaymentsPage";
import UserPage from "../components/pages/Users/UserPage";
import UsersPage from "../components/pages/Users/UsersPage";

const PAGES = [
  { path: "/users", component: <UsersPage />, name: "USERS" },
  { path: "/user/create", component: <CreateUserPage />, name: "USERS" },
  { path: "/user/:id", component: <UserPage />, name: "USERS" },
  { path: "/centers", component: <CentersPage />, name: "CENTERS" },
  { path: "/center/create", component: <CreateCenterPage />, name: "CENTERS" },
  { path: "/center/:id", component: <CenterPage />, name: "CENTERS" },
  { path: "/contracts", component: <ContractsPage />, name: "CONTRACTS" },
  {
    path: "/contract/create",
    component: <CreateContractPage />,
    name: "CONTRACTS",
  },
  { path: "/contract/:id", component: <ContractPage />, name: "CONTRACTS" },
  {
    path: "/contract-types",
    component: <ContractTypesPage />,
    name: "CONTRACTS",
  },
  { path: "/dashboard", component: <Dashboard />, name: "DASHBOARD" },
  {
    path: "/expense-types",
    component: <ExpenseTypesPage />,
    name: "EXPENSE TYPES",
  },
  {
    path: "/expense-type/:id",
    component: <ExpenseTypePage />,
    name: "EXPENSE TYPES",
  },
  { path: "/html-editor", component: <HTMLEditor />, name: "CENTERS" },
  {
    path: "/merchantables",
    component: <MerchantablesPage />,
    name: "MERCHANTABLES",
  },
  {
    path: "/merchantable/create",
    component: <CreateMerchantablePage />,
    name: "MERCHANTABLES",
  },
  {
    path: "/merchantable/:id",
    component: <MerchantablePage />,
    name: "MERCHANTABLES",
  },
  { path: "/occupancy", component: <OccupancyPage />, name: "OCCUPANCY" },
  { path: "/providers", component: <ProvidersPage />, name: "PROVIDERS" },
  {
    path: "/provider/create",
    component: <CreateProviderPage />,
    name: "PROVIDERS",
  },
  { path: "/provider/:id", component: <ProviderPage />, name: "PROVIDERS" },
  {
    path: "/provider-invoices",
    component: <ProviderInvoicesPage />,
    name: "PROVIDER INVOICES",
  },
  {
    path: "/provider-invoice/create",
    component: <CreateProviderInvoicePage />,
    name: "PROVIDER INVOICES",
  },
  {
    path: "/provider-invoice/:id",
    component: <ProviderInvoicePage />,
    name: "PROVIDER INVOICES",
  },
  { path: "/roles", component: <RolesPage />, name: "ROLES" },
  { path: "/role/:id", component: <RolePage />, name: "ROLES" },
  { path: "/customers", component: <CustomersPage />, name: "CUSTOMERS" },
  {
    path: "/customer/create",
    component: <CreateCustomerPage />,
    name: "CUSTOMERS",
  },
  { path: "/customer/:id", component: <CustomerPage />, name: "CUSTOMERS" },
  {
    path: "/banking-transactions",
    component: <BankingTransactionsPage />,
    name: "BANKING TRANSACTIONS",
  },
  { path: "/boxes", component: <BoxesPage />, name: "BOXES" },
  { path: "/box/create", component: <CreateBoxPage />, name: "BOXES" },
  { path: "/box/:id", component: <BoxPage />, name: "BOXES" },
  //{ path: "/profile", component: <ProfilePage /> },
  { path: "/calls", component: <CallsPage />, name: "CALLS" },
  { path: "/call/create", component: <CreateCallPage />, name: "CALLS" },
  { path: "/call/:id", component: <CallPage />, name: "CALLS" },
  {
    path: "/cancellation-requests",
    component: <CancellationRequestsPage />,
    name: "CONTRACTS",
  },
  { path: "/invoices", component: <InvoicesPage />, name: "INVOICES" },
  {
    path: "/invoice/create",
    component: <CreateInvoicePage />,
    name: "INVOICES",
  },
  { path: "/invoice/:id", component: <InvoicePage />, name: "INVOICES" },
  { path: "/invoicing", component: <InvoicingPage />, name: "INVOICING" },
  { path: "/receipts", component: <ReceiptsPage />, name: "RECEIPTS" },
  {
    path: "/receipt/create",
    component: <CreateReceiptPage />,
    name: "RECEIPTS",
  },
  {
    path: "/returned-receipts",
    component: <ReturnedReceiptsPage />,
    name: "RECEIPTS",
  },
  { path: "/remittances", component: <RemittancesPage />, name: "REMITTANCES" },
  {
    path: "/remittance/create",
    component: <CreateRemittancePage />,
    name: "REMITTANCES",
  },
  {
    path: "/remittance/create-direct",
    component: <CreateDirectRemittancePage />,
    name: "REMITTANCES",
  },
  {
    path: "/remittance/:id",
    component: <RemittancePage />,
    name: "REMITTANCES",
  },
  { path: "/documents", component: <DocumentsPage />, name: "DOCUMENTS" },
  { path: "/maps", component: <MapsPage />, name: "MAPS" },
  { path: "/settings", component: <SettingsPage />, name: "SETTINGS" },
  { path: "/settlements", component: <SettlementsPage />, name: "SETTLEMENTS" },
  {
    path: "/settlement/create",
    component: <CreateSettlementPage />,
    name: "SETTLEMENTS",
  },
  {
    path: "/settlement/:id",
    component: <SettlementPage />,
    name: "SETTLEMENTS",
  },
  {
    path: "/non-payments",
    component: <NonPaymentsPage />,
    name: "NONPAYMENTS",
  },
  {
    path: "/non-payment/:id",
    component: <NonPaymentPage />,
    name: "NONPAYMENTS",
  },
  { path: "/mass-emails", component: <MassEmailsPage />, name: "MASS EMAILS" },
  {
    path: "/mass-email/create/center",
    component: <CreateMassEmailCenterPage />,
    name: "MASS EMAILS",
  },
  {
    path: "/mass-email/create/customer",
    component: <CreateMassEmailCustomerPage />,
    name: "MASS EMAILS",
  },
  {
    path: "/mass-email/:id",
    component: <MassEmailPage />,
    name: "MASS EMAILS",
  },
  {
    path: "/transfer-payments",
    component: <TransferPaymentsPage />,
    name: "TRANSFER PAYMENTS",
  },
  {
    path: "/transfer-payment-group/:id",
    component: <TransferPaymentPage />,
    name: "TRANSFER PAYMENTS",
  },
  {
    path: "/acquisitions",
    component: <AcquisitionsAssetsPage />,
    name: "ACQUISITIONS",
  },
  {
    path: "/acquisition/create",
    component: <CreateAcquisitionAssetPage />,
    name: "ACQUISITIONS",
  },
  {
    path: "/acquisition/:id",
    component: <AcquisitionAssetPage />,
    name: "ACQUISITIONS",
  },
  {
    path: "/revenue-management",
    component: <RevenueManagement />,
    name: "REVENUE_MANAGEMENT",
  },
  {
    path: "/banks",
    component: <BanksPage />,
    name: "REMITTANCES",
  },
  {
    path: "/invoice-emailing",
    component: <InvoiceEmailingPage />,
    name: "INVOICE EMAILING",
  },
  {
    path: "/cameras",
    component: <CamerasPage />,
    name: "CAMERAS",
  },
  {
    path: "/floor-plans",
    component: <FloorPlansPage />,
    name: "FLOOR PLANS",
  },
  {
    path: "/altas-bajas",
    component: <AltasBajasPage />,
    name: "CONTRACTS",
  },
  {
    path: "/pay-cards",
    component: <PaycardsPage />,
    name: "INVOICES",
  },
  {
    path: "/promotions",
    component: <PromotionsPage />,
    name: "PROMOTIONS",
  },
  {
    path: "/promotion/create",
    component: <CreatePromotionPage />,
    name: "PROMOTIONS",
  },
  {
    path: "/promotion/:id",
    component: <PromotionPage />,
    name: "PROMOTIONS",
  },
];

export default PAGES;
