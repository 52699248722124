import PropTypes from "prop-types";
import LabeledText from "./LabeledText";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Card, CardContent, Grid, IconButton } from "@mui/material";

const InfoCard = (props) => {
  const {
    infoArray,
    isDefault = false,
    displayDelete = false,
    onDelete = () => {},
  } = props;

  return (
    <Card
      variant="outlined"
      sx={{
        borderColor: isDefault && "primary.main",
      }}
    >
      <CardContent>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Grid item container spacing={1}>
              {infoArray.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <LabeledText label={item.label} value={item.value} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {displayDelete && (
            <Grid item>
              <IconButton onClick={onDelete}>
                <HighlightOffIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

InfoCard.propTypes = {
  infoArray: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  isDefault: PropTypes.bool,
  displayDelete: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default InfoCard;
