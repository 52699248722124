import { enqueueSnackbar } from "notistack";
import { removeSpaces } from "./format";

export const validateEmail = (email, t, existingEmailsArray = []) => {
  if (!email) {
    enqueueSnackbar(t("emptyEmail"), { variant: "error" });
    return false;
  }

  const emailRegex = /\S+@\S+\.\S+/;
  if (!emailRegex.test(email)) {
    enqueueSnackbar(t("invalidEmail"), { variant: "error" });
    return false;
  }

  const sanitizedEmail = removeSpaces(email).toLowerCase();

  if (
    existingEmailsArray.some(
      (existingEmail) =>
        removeSpaces(existingEmail).toLowerCase() === sanitizedEmail
    )
  ) {
    enqueueSnackbar(t("emailExists"), { variant: "error" });
    return false;
  }

  return true;
};

export const validatePhoneNumber = (phoneNumber, t, existingPhoneNumbers) => {
  if (!phoneNumber) {
    enqueueSnackbar(t("emptyPhoneNumber"), { variant: "error" });
    return false;
  }

  if (phoneNumber.length < 6) {
    enqueueSnackbar(t("invalidPhoneNumber"), { variant: "error" });
    return false;
  }

  const sanitizedPhoneNumber = removeSpaces(phoneNumber);
  if (
    existingPhoneNumbers.some(
      (existingPhoneNumbers) =>
        removeSpaces(existingPhoneNumbers) === sanitizedPhoneNumber
    )
  ) {
    enqueueSnackbar(t("phoneNumberExists"), { variant: "error" });
    return false;
  }

  return true;
};
