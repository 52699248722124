import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const InvoiceStateChip = (props) => {
  const { state } = props;
  const [t] = useTranslation("invoices");

  let text, color;
  switch (state) {
    case 0: // Issued
      text = t("issued");
      color = "primary";
      break;
    case 1: // Paid
      text = t("paid");
      color = "success";
      break;
    case 2: // Expired
      text = t("expired");
      color = "warning";
      break;
    case 3: // Unpaid
      text = t("unpaid");
      color = "error";
      break;
    case 4: // Incorrect issuance
      text = t("incorrectIssuance");
      color = "black";
      break;
    default:
      text = "?";
      color = "default";
  }
  return <Chip color={color} label={text} size="small" {...props} />;
};

export default InvoiceStateChip;
