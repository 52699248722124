import { Checkbox, FormControlLabel } from "@mui/material";

import { CustomTable } from "../../CustomTable";
import CustomSelect from "../../Inputs/CustomSelect";
import { useTranslation } from "react-i18next";

export default function CenterIssuersTable({
  centers,
  issuers,
  handleChangeCheckbox,
  setDefaultIssuer,
}) {
  const [t] = useTranslation("settings");

  const issuerHasCenter = (issuer, centerId) => {
    let hasCenter = false;
    issuer.Centers?.forEach((center) => {
      if (center.id === centerId) hasCenter = true;
    });

    return hasCenter;
  };

  const data = centers.map((center) => {
    let row = {
      name: center.name,
    };
    row.defaultIssuer = (
      <CustomSelect
        label={t("defaultIssuer")}
        onChange={(e) => {
          e.preventDefault();
          setDefaultIssuer(center.id, e.target.value);
        }}
        value={center.defaultIssuer}
        options={[
          { value: "", label: "none" },
          ...center.Issuers.map((issuer) => ({
            label: issuer.name,
            value: issuer.id,
          })),
        ]}
      />
    );
    row.issuers = {};

    issuers.forEach((issuer) => {
      row.issuers[issuer.id] = (
        <FormControlLabel
          control={
            <Checkbox
              name={"check" + center.id + issuer.id}
              checked={issuerHasCenter(issuer, center.id)}
              onChange={(e) => {
                e.preventDefault();
                handleChangeCheckbox(e.target.checked, issuer, center);
              }}
            />
          }
          label={issuer.name}
        />
      );
    });

    return row;
  });

  let columns = [
    { key: "name", label: t("center"), sortType: "string" },
    { key: "defaultIssuer", label: t("defaultIssuer"), sortType: "other" },
  ];
  issuers.forEach((issuer) => {
    columns.push({
      key: "issuers." + issuer.id,
      label: issuer.name,
      sortType: "other",
    });
  });
  return <CustomTable data={data} columns={columns} />;
}
