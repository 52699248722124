import { Box } from "@mui/material";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useRef, useEffect, useReducer } from "react";
import GeoJSON from "geojson";
import { Redirect } from "react-router-dom";

// Import Mapbox token
mapboxgl.accessToken =
  "pk.eyJ1IjoiZ25vbWJvIiwiYSI6ImNrNGszYXlxZjBjdGozanFpOGFpZno0bWEifQ.Gchc2G72ohn5xofBIq5agg";

const reducer = (state, action) => {
  switch (action.type) {
    case "REDIRECT":
      return { ...state, id: action.payload, redirect: true };
    case "SET_MAP":
      return {
        ...state,
        map: { ...state.map, current: action.payload.map },
      };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

const AcquisitionsMap = (props) => {
  const initialState = {
    id: null,
    map: useRef(null),
    mapContainer: useRef(null),
    redirect: false,
    zoom: 5.3, // Zoom level to view whole iberian peninsula
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { data = [] } = props;

  useEffect(() => {
    setMap();
  }, [data]);

  const setMap = () => {
    const geoJSONData = GeoJSON.parse(data, {
      Point: ["lat", "lng"],
    });
    if (!state.map.current) {
      let map = new mapboxgl.Map({
        container: state.mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [-3.68, 40.3], // center of spain
        zoom: state.zoom,
      });

      map.on("load", () => {
        // Add +/- controls
        map.addControl(new mapboxgl.NavigationControl());

        // Add fullscreen toggle
        map.addControl(new mapboxgl.FullscreenControl());

        //Add data
        map.addSource("acquisitions", {
          type: "geojson",
          data: geoJSONData,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50,
        });

        map.addLayer({
          id: "clusters",
          type: "circle",
          source: "acquisitions",
          filter: ["has", "point_count"],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#51bbd6",
              100,
              "#f1f075",
              750,
              "#f28cb1",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              100,
              30,
              750,
              40,
            ],
            "circle-stroke-width": 1,
            "circle-stroke-color": "#ffffff",
          },
        });

        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "acquisitions",
          filter: ["has", "point_count"],
          layout: {
            "text-field": ["get", "point_count_abbreviated"],
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
        });

        map.addLayer({
          id: "unclustered-point",
          type: "circle",
          source: "acquisitions",
          filter: ["!", ["has", "point_count"]],
          paint: {
            "circle-color": "#4264fb",
            "circle-radius": 8,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#ffffff",
          },
        });

        // Change mouse to pointer on hover cluster
        map.on("mouseenter", "clusters", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Reset mouse when leaving cluster
        map.on("mouseleave", "clusters", () => {
          map.getCanvas().style.cursor = "";
        });

        // Inspect a cluster on click
        map.on("click", "clusters", (e) => {
          const features = map.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });
          const clusterId = features[0].properties.cluster_id;
          map
            .getSource("acquisitions")
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              console.log(features[0].geometry.coordinates);
              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              });
            });
        });

        // Create a popup, without adding it to the map yet
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        // Change mouse to pointer, show popup
        map.on("mouseenter", "unclustered-point", (e) => {
          map.getCanvas().style.cursor = "pointer";
          // Copy coordinates array.
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(description).addTo(map);
        });

        // Change it back to a pointer when it leaves, remove popup
        map.on("mouseleave", "unclustered-point", () => {
          map.getCanvas().style.cursor = "";
          popup.remove();
        });

        // Redirect to acquisition on click
        map.on("click", "unclustered-point", (e) => {
          map.getCanvas().style.cursor = "pointer";
          // Get id from acquisition
          const id = e.features[0].properties.id;
          dispatch({
            type: "REDIRECT",
            payload: id,
          });
        });
      });
      dispatch({
        type: "SET_MAP",
        payload: {
          map: map,
        },
      });
    } else {
      let map = state.map.current;
      map.getSource("acquisitions")?.setData(geoJSONData);
    }
  };

  return (
    <>
      <Box height="80vh" ref={state.mapContainer}></Box>
      {state.redirect && <Redirect to={"/app/acquisition/" + state.id} />}
    </>
  );
};

export default AcquisitionsMap;
