import { Button, TextField, Grid } from "@mui/material";
import React, { useReducer } from "react";
import Cards from "react-credit-cards-2";
import { useTranslation } from "react-i18next";
import Payment from "payment";
import PropTypes from "prop-types";
import "react-credit-cards-2/dist/es/styles-compiled.css";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}
export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}
const initialState = {
  number: "",
  name: "",
  expiry: "",
  cvc: null,
  issuer: "",
  focused: "",
  formData: null,
  data: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return { ...state, [action.payload.inputname]: action.payload.value };
    default:
      throw new Error("Action not found in reducer");
  }
}
export default function CreditCardForm({ onSubmit, loading }) {
  const [t] = useTranslation("customers");
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "issuer",
          value: issuer,
        },
      });
    }
  };

  const handleInputFocus = ({ target }) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "focused",
        value: target.name,
      },
    });
  };

  const handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: target.name,
        value: target.value,
      },
    });
  };

  return (
    <div key="Payment">
      <div>
        <Cards
          number={state.number}
          name={state.name}
          expiry={state.expiry}
          cvc={state.cvc}
          focused={state.focused}
          callback={handleCallback}
        />
        <br />
        <form>
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <TextField
                type="tel"
                name="number"
                className="form-control"
                placeholder={t("cardNumber")}
                pattern="[\d| ]{16,22}"
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                type="text"
                name="name"
                className="form-control"
                placeholder={t("name")}
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                type="tel"
                name="expiry"
                className="form-control"
                placeholder={t("validThru") + " (MM/YY)"}
                pattern="\d\d/\d\d"
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                variant="outlined"
              />
            </Grid>
            {/*<Grid item>
              <TextField
                type="tel"
                name="cvc"
                className="form-control"
                placeholder="CVC"
                pattern="\d{3,4}"
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                variant="outlined"
              />
  </Grid>*/}

            <Grid item container xs={12} justifyContent="flex-end">
              <Grid item>
                {state.number && state.name && state.expiry && !loading ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      onSubmit(state);
                    }}
                  >
                    {t("save")}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disabled
                    onClick={() => {
                      onSubmit(state);
                    }}
                  >
                    {t("save")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>

          <input type="hidden" name="issuer" value={state.issuer} />
        </form>
        {state.formData && (
          <div className="App-highlight">
            {formatFormData(state.formData).map((d, i) => (
              <div key={i}>{d}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

CreditCardForm.propTypes = {
  classes: PropTypes.object,
};
