import { ChartContainer } from "./ChartContainer";
import { ColorGenerator, colors } from "../ColorGenerator";
import CustomSelect from "../../../Inputs/CustomSelect";
import { formatDate, getDates, splitConcat } from "../../../../utils/chartUtils";
import { Line } from "react-chartjs-2";
import { useState } from "react";

export const NewOldCustomers = ({ customers }) => {
  /*let cs = customers
    .filter((customer) => customer.Contracts.length > 0)
    .map((customer) => ({
      newAt: customer.Contracts.sort((a, b) => a.startDate > b.startDate),
    }));*/


  return <ChartContainer />;
};

export const CustomersByYear = ({ customers }) => {
  const [period, setPeriod] = useState("year");
  const filteredCustomers = customers.filter(customer => customer.Contracts.length > 0);
  const contracts = [].concat.apply([], filteredCustomers.map((box) => (box.Contracts)));

  const startDate = contracts.reduce((min, contract) =>
    min > new Date(contract.startDate) ?
      new Date(contract.startDate) : new Date(min), new Date());

  const dates = getDates(startDate, new Date(), period);

  const labels = {
    year: dates.map(date => new Date(date).getFullYear()),
    month: dates.map(date => new Date(date).getMonth() + "-" + new Date(date).getFullYear())
  }

  const customerDates = filteredCustomers.map(customer => {
    const firstDate = customer.Contracts.reduce((min, contract) =>
      min === null || min > new Date(contract.startDate) ?
        new Date(contract.startDate) : min, null);

    let lastDate = null;
    if (customer.Contracts.every(contract => contract.endDate !== null)) {
      lastDate = customer.Contracts.reduce((max, contract) =>
        max === null || max < new Date(contract.endDate) ?
          new Date(contract.endDate) : max, null);
    }

    return ([firstDate, lastDate]);
  });

  let nuevos = [];
  let bajas = [];
  let diferencias = [];

  dates.forEach(periodDate => {

    periodDate = new Date(periodDate);

    let nuevo = customerDates.filter((customer) =>
    (customer[0].getFullYear() === periodDate.getFullYear() &&
      (period === "year" || (period === "month" && customer[0].getMonth() === periodDate.getMonth()))
    )).length;
    nuevos.push(nuevo);

    let baja = customerDates.filter((customer) =>
    (customer[1] !== null && customer[1].getFullYear() === periodDate.getFullYear() &&
      (period === "year" || (period === "month" && customer[1].getMonth() === periodDate.getMonth()))
    )).length;
    bajas.push(baja);

    diferencias.push(nuevo - baja);

  });

  const chartData = {
    labels: labels[period],
    datasets: [{
      label: "Nuevos",
      data: nuevos,
      backgroundColor: colors.green,
      borderColor: colors.green
    }, {
      label: "Bajas",
      data: bajas,
      backgroundColor: colors.red,
      borderColor: colors.red
    }, {
      label: "Diferencia",
      data: diferencias,
      backgroundColor: colors.primary,
      borderColor: colors.primary
    }]
  };



  return (
    <ChartContainer
      chart={<Line data={chartData} />}
    >
      <CustomSelect
        label="Periodo"
        value={period}
        onChange={(e) => { setPeriod(e.target.value); }}
        options={[{ value: "month", label: "Mes" },
        { value: "year", label: "Año" }]}
      />
    </ChartContainer>
  )
};

export const NewVsExistingCustomers = ({ customers }) => {
  const [period, setPeriod] = useState("year");
  const filteredCustomers = customers.filter(customer => customer.Contracts.length > 0);
  const contracts = [].concat.apply([], filteredCustomers.map((box) => (box.Contracts)));

  const startDate = contracts.reduce((min, contract) =>
    min > new Date(contract.startDate) ?
      new Date(contract.startDate) : new Date(min), new Date());

  const dates = getDates(startDate, new Date(), period);

  const labels = {
    year: dates.map(date => new Date(date).getFullYear()),
    month: dates.map(date => new Date(date).getMonth() + "-" + new Date(date).getFullYear())
  }

  const customerDates = filteredCustomers.map(customer => {
    const firstDate = customer.Contracts.reduce((min, contract) =>
      min === null || min > new Date(contract.startDate) ?
        new Date(contract.startDate) : min, null);

    let lastDate = null;
    if (customer.Contracts.every(contract => contract.endDate !== null)) {
      lastDate = customer.Contracts.reduce((max, contract) =>
        max === null || max < new Date(contract.endDate) ?
          new Date(contract.endDate) : max, null);
    }

    return ([firstDate, lastDate]);
  });

  let nuevos = [];
  let bajas = [];
  let diferencias = [];
  let base = [0];

  dates.forEach((periodDate, i) => {

    periodDate = new Date(periodDate);

    let nuevo = customerDates.filter((customer) =>
    (customer[0].getFullYear() === periodDate.getFullYear() &&
      (period === "year" || (period === "month" && customer[0].getMonth() === periodDate.getMonth()))
    )).length;
    nuevos.push(nuevo);

    let baja = customerDates.filter((customer) =>
    (customer[1] !== null && customer[1].getFullYear() === periodDate.getFullYear() &&
      (period === "year" || (period === "month" && customer[1].getMonth() === periodDate.getMonth()))
    )).length;
    bajas.push(baja);

    diferencias.push(nuevo - baja);
    base.push(base[i] + diferencias[i])

  });

  const chartData = {
    labels: labels[period],
    datasets: [
      {
        label: "Bajas",
        data: bajas,
        backgroundColor: colors.red,
        borderColor: colors.red
      },
      {
        label: "Base",
        data: base,
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        fill: true
      },
      {
        label: "Nuevos",
        data: nuevos,
        backgroundColor: colors.green,
        borderColor: colors.green,
        fill: true
      },

    ],

  };

  const chartOptions = {
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    scales: {
      y: {
        stacked: true
      }
    }
  }



  return (
    <ChartContainer
      chart={<Line data={chartData} options={chartOptions} />}
    >
      <CustomSelect
        label="Periodo"
        value={period}
        onChange={(e) => { setPeriod(e.target.value); }}
        options={[{ value: "month", label: "Mes" },
        { value: "year", label: "Año" }]}
      />
    </ChartContainer>
  )
}
