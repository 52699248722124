import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";

// Material UI
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// Components
import TextInput from "./TextInput";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const initialState = {
  checked: [],
  filteredLeft: [],
  filteredRight: [],
  leftFilter: "",
  rightFilter: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CHECKED":
      return {
        ...state,
        checked: action.payload,
      };
    case "SET_FILTERED_LEFT":
      return {
        ...state,
        filteredLeft: action.payload,
      };
    case "SET_FILTERED_RIGHT":
      return {
        ...state,
        filteredRight: action.payload,
      };
    case "SET_LEFT_FILTER":
      return {
        ...state,
        leftFilter: action.payload,
      };
    case "SET_RIGHT_FILTER":
      return {
        ...state,
        rightFilter: action.payload,
      };
    default:
      throw new Error("Action not found in reducer");
  }
}

export default function TransferList({
  left,
  name,
  name2,
  right,
  setLeft,
  setRight,
}) {
  const [t] = useTranslation("others");
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "SET_FILTERED_LEFT", payload: left });
    handleFilterChange({ target: { value: state.leftFilter } }, "left");
  }, [left]);

  useEffect(() => {
    dispatch({ type: "SET_FILTERED_RIGHT", payload: right });
    handleFilterChange({ target: { value: state.rightFilter } }, "right");
  }, [right]);

  const leftChecked = intersection(state.checked, left);
  const rightChecked = intersection(state.checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = state.checked.indexOf(value);
    const newChecked = [...state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    dispatch({ type: "SET_CHECKED", payload: newChecked });
  };

  const handleAllRight = () => {
    setRight(right.concat(left), name2);
    setLeft([], name);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked), name2);
    setLeft(not(left, leftChecked), name);
    dispatch({ type: "SET_CHECKED", payload: not(state.checked, leftChecked) });
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked), name);
    setRight(not(right, rightChecked), name2);
    dispatch({
      type: "SET_CHECKED",
      payload: not(state.checked, rightChecked),
    });
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right), name);
    setRight([], name2);
  };

  const handleFilterChange = (e, side) => {
    const value = e.target.value.toLowerCase();
    const filtered =
      side === "left"
        ? left?.filter((item) => item.toLowerCase().includes(value))
        : right?.filter((item) => item.toLowerCase().includes(value));
    const type = side === "left" ? "SET_FILTERED_LEFT" : "SET_FILTERED_RIGHT";
    dispatch({
      type,
      payload: value ? filtered : side === "left" ? left : right,
    });
  };

  const customList = (items) => (
    <Paper>
      <List
        dense
        component="div"
        role="list"
        sx={{
          width: 200,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
      >
        {items &&
          items.map((value) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItem
                key={value}
                role="listitem"
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={state.checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value}`} />
              </ListItem>
            );
          })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center">
      <Grid container item xs={5} rowSpacing={1}>
        <Grid item xs={12}>
          <TextInput
            label={t("filter")}
            name="leftFilter"
            value={state.leftFilter}
            onChange={(e) => {
              handleFilterChange(e, "left");
              dispatch({ type: "SET_LEFT_FILTER", payload: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {customList(state.filteredLeft)}
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={!state.filteredLeft || state.filteredLeft.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={!state.filteredRight || state.filteredRight.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid container item rowSpacing={1} xs={5}>
        <Grid item xs={12}>
          <TextInput
            label={t("filter")}
            name="rightFilter"
            value={state.rightFilter}
            onChange={(e) => {
              handleFilterChange(e, "right");
              dispatch({ type: "SET_RIGHT_FILTER", payload: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {customList(state.filteredRight)}
        </Grid>
      </Grid>
    </Grid>
  );
}
