import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import _ from "lodash";
import { PASSPORT_ID_TYPE } from "../../data/constants";
import CustomSelect from "./CustomSelect";
import PropTypes from "prop-types";
import axios from "axios";
import { API_ROUTE } from "../../utils/API";

const IdTypeSelect = ({
  idType,
  countryAlpha2,
  handleInputChange,
  disabled,
}) => {
  const [t] = useTranslation("others");
  const [tErrors] = useTranslation("errors");

  const [idTypeOptions, setIdTypeOptions] = useState([]);

  useEffect(() => {
    getIdTypeOptions();
  }, []);

  useEffect(() => {
    const idTypeInOptions = getIdOptions()?.includes(idType?.toLowerCase());
    Object.keys(idTypeOptions).length > 0 &&
      handleInputChange({
        target: {
          name: "idType",
          value: idTypeInOptions ? idType : getIdOptions()?.[0],
        },
      });
  }, [countryAlpha2]);

  const getIdTypeOptions = () => {
    axios
      .get(API_ROUTE + "/id-types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          setIdTypeOptions(response.data);
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const getIdOptions = () => {
    return _.get(idTypeOptions, countryAlpha2, []);
  };

  const getIdTypeLabel = (idType) => {
    return idType.toUpperCase() === PASSPORT_ID_TYPE
      ? t(idType)
      : idType.toUpperCase();
  };

  return (
    <CustomSelect
      label={t("idType")}
      name="idType"
      value={idType?.toLowerCase()}
      onChange={handleInputChange}
      options={getIdOptions()?.map((idType) => ({
        value: idType.toLowerCase(),
        label: getIdTypeLabel(idType),
      }))}
      disabled={disabled}
      minWidth={70}
    />
  );
};

IdTypeSelect.propTypes = {
  idType: PropTypes.string,
  countryAlpha2: PropTypes.string,
  handleInputChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default IdTypeSelect;
