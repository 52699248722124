import {
  Grid,
  Button,
  IconButton,
  Popover,
  CircularProgress,
} from "@mui/material";
import { Rect, Group } from "react-konva";
import { Html } from "react-konva-utils";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import VideocamIcon from "@mui/icons-material/Videocam";

const CameraTile = (props) => {
  const [t] = useTranslation("floorPlans");

  const { openDoor, tile, ...otherProps } = props;
  const editing = otherProps.draggable;

  return (
    <Group draggable={editing} {...tile} {...otherProps}>
      {editing ? <Rect width={50} height={50} shadowBlur={10} /> : <></>}
      <Html
        divProps={editing ? { style: { pointerEvents: "none" } } : undefined}
      >
        <div>
          <IconButton disabled={openDoor === undefined}>
            <VideocamIcon />
          </IconButton>
        </div>
      </Html>
    </Group>
  );
};

export default CameraTile;
