import { IconButton, Tooltip } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";

import React from "react";

/**
 * Custom React hook for managing edit icons and their actions.
 *
 * @param {boolean} initialValue - The initial edit mode state.
 * @param {Object} editProps - Props for the edit icon Tooltip.
 * @param {Object} saveProps - Props for the save icon Tooltip.
 * @param {Object} cancelProps - Props for the cancel icon Tooltip.
 * @param {function} actionCallBack - Callback function to handle actions (e.g., edit, save, cancel).
 * @returns {Object} - An object with the edit mode state and renderable edit icons.
 */
export default function useEditIcons(
  initialValue,
  editProps,
  saveProps,
  cancelProps,
  actionCallBack
) {
  const [edit, setEdit] = React.useState(initialValue ?? false);

  return {
    edit: edit,
    setEdit: setEdit,
    iconsRender: (
      <>
        {!edit && (
          <Tooltip {...editProps}>
            <IconButton
              name="edit"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEdit(true);
                actionCallBack("edit");
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {edit && (
          <>
            <Tooltip {...saveProps}>
              <IconButton
                name="save"
                color="success"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEdit(false);
                  actionCallBack("save");
                }}
              >
                <DoneIcon />
              </IconButton>
            </Tooltip>
            <Tooltip {...cancelProps}>
              <IconButton
                name="cancel"
                color="warning"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEdit(false);
                  actionCallBack("cancel");
                }}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </>
    ),
  };
}
