import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

// Icons
import EmailIcon from "@mui/icons-material/Email";
import SearchButton from "../../Inputs/SearchButton";

import { getParams, generateURL } from "../../../utils/url";
import AppContext from "../../../context/AppContext";
import TextInput from "../../Inputs/TextInput";
import InvoiceStateChip from "../../InvoiceStateChip";
import { localeFormat } from "../../../utils/format";
import {
  firstDateCurrentMonth,
  lastDateCurrentMonth,
} from "../../../utils/date";

// Components
import ConfirmDialog from "../../ConfirmDialog";
import CustomSelect from "../../Inputs/CustomSelect";
import Page from "../../global/structure/Page";
import Filters from "../../global/structure/Filters";

import { useState } from "react";
import {
  F_INVOICE_SERIES_ID,
  R_INVOICE_SERIES_ID,
} from "../../../data/constants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  invoices: [],
  filters: {
    autoSearch: "false",
    types: [],
    dateFrom: firstDateCurrentMonth(),
    dateUntil: lastDateCurrentMonth(),
    number: "",
    amountFrom: "",
    amountTo: "",
    customerId: "",
    state: "",
    paymentMethodId: "",
    orderBy: "",
    order: "",
    emailSent: 1,
  },
  options: {
    loaded: true,
    sendingEmails: false,
  },
  paymentMethods: [],
  series: [],
  customers: [],
  customer: [],
  loadingAll: false,
  emailPdfLoading: false,
  confirmSendAll: {
    isOpen: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_INVOICES_SELECT":
      return { ...state, selectedInvoicesId: action.payload };
    case "SET_SERIES":
      return { ...state, series: action.payload.series };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_CUSTOMER_FILTER":
      return { ...state, customer: action.payload };
    case "RESET_FILTERS":
      return {
        ...state,
        filters: initialState.filters,
        customer: initialState.customer,
      };
    case "SET_LOADING":
      const index = state.invoices.findIndex(
        (post) => post.id === action.payload.id
      );

      return {
        ...state,
        invoices: [
          ...state.invoices.slice(0, index),
          {
            ...state.invoices[index],
            [action.payload.type]: action.payload.state,
          },
          ...state.invoices.slice(index + 1),
        ],
        loadingAll: !state.loadingAll,
      };
    case "SET_EMAIL_SEND_LOADING":
      return { ...state, emailPdfLoading: action.payload };
    case "SET_ZIP_DOWNLOAD_LOADING":
      return { ...state, zipDownloadLoading: action.payload };
    case "SET_OPTION":
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "UPDATE_INVOICES":
      return { ...state, invoice: state.invoices };
    case "UPDATE_CONFIRM_SEND_ALL":
      return {
        ...state,
        confirmSendAll: {
          ...state.confirmSendAll,
          [action.payload.inputname]: action.payload.value,
        },
      };
    default:
      throw new Error();
  }
}

export default function InvoiceEmailingPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("invoices");

  const filters = [
    { name: "types", type: "array" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "number", type: "string" },
    { name: "amountFrom", type: "number" },
    { name: "emailSent", type: "number" },
    { name: "amountTo", type: "number" },
    { name: "customerId", type: "number" },
    { name: "state", type: "number" },
    { name: "paymentMethodId", type: "number" },
    { name: "orderBy", type: "string" },
    { name: "order", type: "string" },
    { name: "autoSearch", type: "string" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, filters) },
  });

  const [page, setPage] = useState(0);
  const [state, dispatch] = useReducer(reducer, initialState, initState);
  const [selectedInvoicesId, setMiArray] = useState([]);
  const [rowPerPage, setrpg] = useState(5);

  useEffect(() => {
    getInvoiceSeries();
    getCustomers();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === "true") getInvoices();
  }, [state.filters.autoSearch]);

  const getInvoices = () => {
    //Change url parameters:
    const url = generateURL("/app/invoice-emailing", state.filters);
    history.push(url);

    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      include: ["PaymentMethod"],
      attributes: [
        {
          model: "Invoice",
          attributes: [
            "actions",
            "baseAmount",
            "customerId",
            "customerName",
            "dueDate",
            "emailSent",
            "id",
            "issueDate",
            "number",
            "state",
            "totalAmount",
          ],
        },
        {
          model: "PaymentMethod",
          attributes: ["name"],
        },
      ],
    };

    state.filters.amountFrom !== "" && (params.min = state.filters.amountFrom);
    state.filters.amountTo !== "" && (params.max = state.filters.amountTo);
    state.filters.dateFrom !== "" && (params.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil !== "" &&
      (params.dateUntil = state.filters.dateUntil);
    state.filters.number !== "" && (params.number = state.filters.number);
    state.filters.customerId !== "" &&
      (params.customerId = state.filters.customerId);
    state.filters.state !== "" && (params.state = state.filters.state);
    state.filters.paymentMethodId !== "" &&
      (params.paymentMethodId = state.filters.paymentMethodId);
    state.filters.emailSent !== "" &&
      (params.emailSent = state.filters.emailSent);

    if (state.filters.types.length > 0) params.types = state.filters.types;
    else params.types = [F_INVOICE_SERIES_ID, R_INVOICE_SERIES_ID];

    api
      .get("/invoices", { params })
      .then((response) => {
        setMiArray([]);
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noInvoices"), { variant: "warning" });
          dispatch({ type: "SET_INVOICES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoiceSeries = () => {
    api
      .get("/invoice-series")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const invoiceSeries = response.data.filter(
            (inv) => Number(inv.id) === 1 || Number(inv.id) === 6
          );
          dispatch({
            type: "SET_SERIES",
            payload: {
              series: invoiceSeries,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const search = () => {
    if (state.filters.autoSearch === "true") getInvoices();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  const getCustomers = () => {
    const params = {
      include: ["CustomerEmail"],
    };
    api
      .get("/customers", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (state.filters.customerId) {
            const customer = response.data.find(
              (customer) => customer.id === Number(state.filters.customerId)
            );
            dispatch({ type: "SET_CUSTOMER_FILTER", payload: customer });
          }

          dispatch({
            type: "SET_CUSTOMERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCustomerEmails = (customerId) => {
    const find = state.customers.find(
      (customer) => Number(customer.id) === Number(customerId)
    );

    let res = "";
    if (find) {
      for (let email of find.CustomerEmails) {
        res += email.email + "; ";
      }
    }
    return find?.CustomerEmails?.length
      ? find.CustomerEmails.map((email) => email.email).join("; ")
      : "-";
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const setConfirmSendAllState = (state) => {
    dispatch({
      type: "UPDATE_CONFIRM_SEND_ALL",
      payload: { inputname: "isOpen", value: state },
    });
  };

  const handleConfirmSendAllChoice = (state) => {
    if (!state) return;
    sendAllSelectedEmails();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleInvoiceCheckbox = (e, selectedInvoicesIds, invoice) => {
    let newArray = [];
    if (e.target.checked) {
      newArray = [...selectedInvoicesIds];
      newArray.push(invoice.id);
    } else {
      newArray = [];
      for (let inv of selectedInvoicesId) {
        if (Number(inv) !== Number(invoice.id)) newArray.push(inv);
      }
    }
    setMiArray(newArray);
  };

  const handleAllCheckbox = (e) => {
    if (e.target.checked)
      setMiArray(
        state.invoices
          .filter((inv) => getCustomerEmails(inv.customerId) !== "-")
          .map((inv) => Number(inv.id))
      );
    else setMiArray([]);
  };

  const filterOptions = createFilterOptions({
    limit: 10, //limit of options to be displayed
  });

  const sendAllSelectedEmails = () => {
    if (!selectedInvoicesId || !selectedInvoicesId.length) return;
    dispatch({
      type: "SET_OPTION",
      payload: { inputname: "sendingEmails", value: true },
    });
    api
      .post("/invoices/emailing", { invoicesIds: selectedInvoicesId })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          let errors = 0;
          let done = 0;
          for (let count = 0; count < response.data.result.length; count++) {
            if (response.data.result[count]?.error) {
              errors += 1;
              enqueueSnackbar(response.data.result[count].error, {
                variant: "error",
              });
            } else {
              done += 1;
              state.invoices[
                state.invoices.findIndex(
                  (invoice) =>
                    Number(invoice.id) === Number(selectedInvoicesId[count])
                )
              ].emailSent = true;
            }
          }
          if (done) {
            dispatch({ type: "SET_INVOICES", payload: state.invoices });
            enqueueSnackbar(`Successfully sent ${done} emails`, {
              variant: "success",
            });
          }
          setMiArray([]);
        }
        dispatch({
          type: "SET_OPTION",
          payload: { inputname: "sendingEmails", value: false },
        });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
        dispatch({
          type: "SET_OPTION",
          payload: { inputname: "sendingEmails", value: false },
        });
      });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_INVOICES", payload: [] });
  };

  return (
    <Page title={t("invoiceEmailing")} browserTitle={t("invoiceEmailing")}>
      <Grid container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                label={t("number/name")}
                value={state.filters.number}
                name="number"
                onChange={handleFilterChange}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
              />,
              <TextInput
                type="number"
                sx={{ width: "105px" }}
                label={t("amountFrom")}
                name="amountFrom"
                value={state.filters.amountFrom}
                onChange={handleFilterChange}
              />,
              <TextInput
                type="number"
                sx={{ width: "105px" }}
                label={t("amountTo")}
                name="amountTo"
                value={state.filters.amountTo}
                onChange={handleFilterChange}
              />,
              <CustomSelect
                multiple
                name="types"
                label={t("serie")}
                options={state.series.map((serie) => ({
                  value: serie.id,
                  label: serie.name,
                }))}
                value={state.filters.types}
                onChange={handleFilterChange}
              />,
              <TextInput
                label={t("dateFrom")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.filters.dateFrom}
                onChange={handleFilterChange}
                name="dateFrom"
              />,
              <TextInput
                label={t("dateUntil")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.filters.dateUntil}
                onChange={handleFilterChange}
                name="dateUntil"
              />,
              <Autocomplete
                size="large"
                sx={{ minWidth: "200px" }}
                options={state.customers}
                getOptionLabel={(customer) => customer.fullName || ""}
                isOptionEqualToValue={(option, value) => option.nif === value}
                filterOptions={filterOptions}
                value={state.customer}
                onChange={(e, customer) => {
                  handleFilterChange({
                    target: {
                      value: customer ? customer.id : "",
                      name: "customerId",
                    },
                  });
                  dispatch({ type: "SET_CUSTOMER_FILTER", payload: customer });
                }}
                name="customer"
                renderInput={(params) => (
                  <TextInput {...params} size="small" label={t("customer")} />
                )}
              />,
              <CustomSelect
                label={t("state")}
                value={state.filters.state}
                onChange={(e) => {
                  handleFilterChange({
                    target: { name: "state", value: e.target.value },
                  });
                }}
                options={[
                  { value: "", label: t("all") },
                  { value: 0, label: t("issued") },
                  { value: 1, label: t("paid") },
                  { value: 2, label: t("expired") },
                  { value: 3, label: t("unpaid") },
                  { value: 4, label: t("incorrectIssuance") },
                ]}
                name="state"
              />,
              <CustomSelect
                name="paymentMethod"
                label={t("paymentMethod")}
                value={state.filters.paymentMethodId}
                onChange={(e) => {
                  handleFilterChange({
                    target: { name: "paymentMethodId", value: e.target.value },
                  });
                }}
                options={state.paymentMethods.map((method) => ({
                  label: t(method.name),
                  value: method.id,
                }))}
              />,
              <CustomSelect
                label={t("emailSent")}
                value={state.filters.emailSent}
                onChange={(e) => {
                  handleFilterChange({
                    target: { name: "emailSent", value: e.target.value },
                  });
                }}
                options={[
                  { value: "", label: t("all") },
                  { value: 0, label: t("sent") },
                  { value: 1, label: t("notSent") },
                ]}
                name="emailSent"
              />,
            ]}
          />
        </Grid>

        <Grid item>
          <ButtonGroup variant="contained">
            <Button onClick={resetFilters}>{t("reset")}</Button>
            <SearchButton onClick={search} loading={!state.options.loaded} />
          </ButtonGroup>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox onClick={(e) => handleAllCheckbox(e)} />
                  </TableCell>
                  <TableCell>{t("serie")}</TableCell>
                  <TableCell>{t("number")}</TableCell>
                  <TableCell>{t("issueDate")}</TableCell>
                  <TableCell>{t("dueDate")}</TableCell>
                  <TableCell>{t("customerName")}</TableCell>
                  <TableCell>{t("import")} (€)</TableCell>
                  <TableCell>{t("paymentMethod")}</TableCell>
                  <TableCell>{t("state")}</TableCell>
                  <TableCell>{t("emails")}</TableCell>
                  <TableCell>{t("actions")}</TableCell>
                  <TableCell>{t("emailSent")}</TableCell>
                </TableRow>
              </TableHead>
              {!state.loadingAll ? (
                <TableBody>
                  {state.invoices
                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                    .map((invoice) => (
                      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <InvoiceEmailRow
                          invoice={invoice}
                          selectedInvoicesIds={selectedInvoicesId}
                          getCustomerEmails={getCustomerEmails}
                          isSent={invoice.emailSent}
                          isSelected={
                            selectedInvoicesId.findIndex(
                              (inv) => Number(inv.id) === Number(invoice.id)
                            ) >= 0
                              ? true
                              : false
                          }
                          handleInvoiceCheckbox={handleInvoiceCheckbox}
                        />
                      </TableRow>
                    ))}
                </TableBody>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={300}
                >
                  <CircularProgress />
                </Box>
              )}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    count={state.invoices.length}
                    colSpan={12}
                    labelRowsPerPage={t("rowsPerPage")}
                    rowsPerPage={Number(rowPerPage)}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item container xs={12}>
          <Button
            variant="contained"
            disabled={
              !state.options.loaded ||
              state.options.sendingEmails ||
              selectedInvoicesId.length === 0
            }
            onClick={() => {
              selectedInvoicesId.length && setConfirmSendAllState(true);
            }}
          >
            {!state.options.sendingEmails ? t("sendEmails") : t("sending")}
          </Button>
        </Grid>
      </Grid>

      <ConfirmDialog
        title={t("sendEmails")}
        open={state.confirmSendAll.isOpen}
        setOpen={setConfirmSendAllState}
        confirmText={t("send")}
        cancelText={t("cancel")}
        onConfirm={handleConfirmSendAllChoice}
      >
        <Typography variant="body2" color="initial">
          {t("doYouWantToSend") +
            " " +
            selectedInvoicesId.length +
            " " +
            t("emails").toLocaleLowerCase() +
            "?"}
        </Typography>
      </ConfirmDialog>
    </Page>
  );
}

const InvoiceEmailRow = (props) => {
  const { api } = useContext(AppContext);
  const {
    invoice,
    selectedInvoicesIds,
    getCustomerEmails,
    isSent,
    isSelected,
    handleInvoiceCheckbox,
  } = props;
  const [t] = useTranslation("invoices");
  const { enqueueSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(isSent);
  const [selected, setSelected] = useState(isSelected);
  const [confirmResendForm, setResendFormValues] = useState({
    isOpen: false,
    invoiceId: null,
  });

  useEffect(() => {
    setSending(false);
    setSent(invoice.emailSent);
    setSelected(isSelected);
    if (selectedInvoicesIds.includes(Number(invoice.id))) setSelected(true);
    else setSelected(false);
  }, [invoice]);

  useEffect(() => {
    if (selectedInvoicesIds.includes(Number(invoice.id))) setSelected(true);
    else setSelected(false);
  }, [selectedInvoicesIds]);

  useEffect(() => {
    setSent(isSent);
  }, [isSent]);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const emailPdf = (id) => {
    setSending(true);
    api
      .get("/invoices/" + id + "/email")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("emailSentSuccessfully"), { variant: "success" });
          invoice.emailSent = true;
          setSent(true);
        }
        setSending(false);
      })
      .catch((error) => {
        console.log(error);
        setSending(false);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const setConfirmDialogState = (state) => {
    setResendFormValues({ ...confirmResendForm, isOpen: state });
  };

  const handleConfirmDialogChoice = (state) => {
    if (!state) return;
    emailPdf(invoice.id);
  };

  const emails = getCustomerEmails(invoice.customerId);
  return (
    <>
      <TableCell>
        <Checkbox
          disabled={emails === "-"}
          checked={selected}
          onClick={(e) =>
            handleInvoiceCheckbox(e, selectedInvoicesIds, invoice)
          }
        />
      </TableCell>
      <TableCell>{invoice.InvoiceSerie.name}</TableCell>
      <TableCell>{invoice.number}</TableCell>
      <TableCell>{invoice.issueDate}</TableCell>
      <TableCell>{invoice.dueDate}</TableCell>
      <TableCell>{invoice.customerName}</TableCell>
      <TableCell>{localeFormat(Number(invoice.totalAmount)) + "€"}</TableCell>
      <TableCell>{invoice.PaymentMethod.name}</TableCell>
      <TableCell>
        <InvoiceStateChip state={invoice.state} />
      </TableCell>
      <TableCell>{emails}</TableCell>
      <TableCell>
        <Tooltip title={t("sendEmail")}>
          {!sending ? (
            <IconButton
              size="small"
              disabled={sending || emails === "-"}
              onClick={(e) => {
                if (!sent) {
                  e.stopPropagation();
                  emailPdf(invoice.id);
                } else setConfirmDialogState(true);
              }}
            >
              <EmailIcon />
            </IconButton>
          ) : (
            <Box>
              <CircularProgress size={25} />
            </Box>
          )}
        </Tooltip>
      </TableCell>
      <TableCell>
        <Chip
          size={"small"}
          label={sent ? t("sent") : t("notSent")}
          color={sent ? "success" : "error"}
        />
      </TableCell>
      <ConfirmDialog
        title={t("emailAlreadySent")}
        open={confirmResendForm.isOpen}
        setOpen={setConfirmDialogState}
        confirmText={t("send")}
        cancelText={t("cancel")}
        onConfirm={handleConfirmDialogChoice}
      >
        <Typography variant="body2" color="initial">
          {t("anEmailHasAlreadyBeenSentDoYouWantToSendAnother")}
        </Typography>
      </ConfirmDialog>
    </>
  );
};
