import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import i18n from "../../utils/i18n";
import LanguageSelect from "../Inputs/LanguageSelect";
import SendResetPasswordCodeForm from "../SendResetPasswordCodeForm";
import ResetPasswordCodeVerificationForm from "../ResetPasswordCodeVerificationForm";
import NewPasswordForm from "../NewPasswordForm";
import { useState } from "react";

export default function ResetPasswordPage() {
  const [email, setEmail] = useState(false);
  const [sended, setSended] = useState(false);
  const [correctCode, setCorrectCode] = useState({
    code: 111111,
    correct: false,
  });
  const [t] = useTranslation("login");
  return (
    <Container>
      <Grid container sx={{ height: "100vh" }} padding={2}>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item>
            <Link to="/">
              <Box
                component="img"
                src="/img/logonut.png"
                height="50px"
                width="270px"
                sx={{ objectFit: "cover", objectPosition: "50% 43%" }}
              />
            </Link>
          </Grid>
          <Grid item>
            <LanguageSelect defaultLang={i18n.language} />
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            {!sended && !correctCode.correct && (
              <SendResetPasswordCodeForm
                setEmailValue={(value) => setEmail(value)}
                onSubmit={() => setSended(true)}
              />
            )}
            {sended && !correctCode.correct && (
              <ResetPasswordCodeVerificationForm
                email={email}
                onSubmit={(result, code) => {
                  if (!result) setSended(false);
                  if (result) setCorrectCode({ code: code, correct: result });
                }}
              />
            )}
            {correctCode.correct && (
              <NewPasswordForm email={email} code={correctCode.code} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
