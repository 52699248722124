import { IconButton, Stack, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { useRef } from "react";

const ActionIconButtons = (props) => {
  const { actions } = props;

  return (
    <Stack direction="row">
      {actions
        .filter((action) => action.display === undefined || action.display)
        .map((action, index) => (
          <ActionButton key={index} {...action} />
        ))}
    </Stack>
  );
};

const ActionButton = (props) => {
  const { color, csv, disabled, icon, title, onClick, link } = props;

  const csvRef = useRef(null);

  const component = link ? Link : undefined;

  const handleClick = () => {
    if (onClick) onClick();

    if (csv) csvRef.current?.link.click();
  };

  return (
    <Tooltip title={title} placement="top">
      <span>
        <IconButton
          color={color || "primary"}
          onClick={handleClick}
          component={component}
          to={link}
          disabled={disabled}
        >
          {icon}
          {csv && (
            <CSV
              csvRef={csvRef}
              data={csv.data}
              headers={csv.headers}
              filename={csv.filename}
            />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};

const CSV = (props) => {
  const { csvRef, data, filename = "download", headers } = props;

  return (
    <CSVLink ref={csvRef} data={data} headers={headers} filename={filename} />
  );
};

CSV.propTypes = {
  csvRef: PropTypes.any,
  data: PropTypes.array,
  filename: PropTypes.string,
  headers: PropTypes.array,
};

ActionButton.propTypes = {
  color: PropTypes.string,
  csv: PropTypes.shape(CSV.propTypes),
  disabled: PropTypes.bool,
  display: PropTypes.bool,
  icon: PropTypes.element,
  link: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

ActionIconButtons.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape(ActionButton.propTypes)),
};

export default ActionIconButtons;
