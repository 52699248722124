import { useTranslation } from "react-i18next";

import CustomSelect from "./CustomSelect";

const CenterTypesSelect = (props) => {
  const [t] = useTranslation("centers");

  const CENTER_TYPES = [
    {
      label: t("none"),
      value: "",
    },
    {
      label: t("NUT"),
      value: 0,
    },
    {
      label: t("franchise"),
      value: 1,
    },
    {
      label: t("other"),
      value: 2,
    },
  ]

  return (
    <CustomSelect
      label={t("centerTypes")}
      {...props}
      options={CENTER_TYPES}
      // grouping={true}
    />
  );
};

export default CenterTypesSelect;
