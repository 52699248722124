import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Switch,
  Typography,
  FormControlLabel,
  Divider,
  Tooltip,
  Chip,
  DialogActions,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import FolderIcon from "@mui/icons-material/Folder";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MailIcon from "@mui/icons-material/Mail";
import PaymentIcon from "@mui/icons-material/Payment";
import Phone from "@mui/icons-material/Phone";
import PublishIcon from "@mui/icons-material/Publish";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VerifiedIcon from "@mui/icons-material/Verified";
import ViewListIcon from "@mui/icons-material/ViewList";
import VisibilityIcon from "@mui/icons-material/Visibility";

import AppContext from "../../../context/AppContext";
import CenterSelect from "../../Inputs/CenterSelect";

import CityInput from "../../Inputs/CityInput";
import ConfirmDialog from "../../ConfirmDialog";
import CustomSelect from "../../Inputs/CustomSelect";
import Link from "../../Inputs/Link";
import PhoneInput from "../../Inputs/PhoneInput";
import PostalCodeInput from "../../Inputs/PostalCodeInput";
import ProviderInvoiceTotals from "../ProviderInvoices/ProviderInvoiceTotals";
import TextInput from "../../Inputs/TextInput";
import { CustomTable } from "../../CustomTable";
import { localeFormat } from "../../../utils/format";
import CountryInput from "../../Inputs/CountryInput";
import { SERVER } from "../../../utils/API";

const initialState = {
  form: {
    address: "",
    approver: "",
    authorizedBy: "",
    blocked: false,
    brand: "",
    businessName: "",
    centerId: "",
    cif: "",
    city: null,
    cityId: "",
    comments: "",
    country: null,
    countryId: "",
    createdBy: "",
    creator: "",
    defaultIBANId: "",
    defaultPaymentMethodId: "",
    defaultPersonalIncome: 0,
    defaultVatPercentage: 0,
    emails: [],
    ibans: [],
    invoices: [],
    paymentDelay: 0,
    phones: [],
    postalCode: "",
    regionId: 0,
    ProviderIBANs: [],
  },
  inputError: {
    brand: false,
    businessName: false,
    cif: false,
    address: false,
    postalCode: false,
    regionId: false,
    defaultVatPercentage: false,
    defaultPersonalIncome: false,
    comments: false,
    countryId: false,
    centerId: false,
    authorizedBy: false,
    email: false,
    contactEmail: false,
    phone: false,
    contactPhone: false,
  },
  centers: [],
  countries: [],
  regions: [],
  id: null,
  optionsInvoices: {
    rowlink: "provider-invoice",
  },
  documentModal: {
    isOpen: false,
    file: "",
    name: "",
  },
  emailModal: { isOpen: false },
  newEmail: { email: "", contact: "" },
  phoneModal: { isOpen: false },
  newPhone: { number: "", contact: "" },
  ibanModal: {
    isOpen: false,
    newIban: "",
    file: "",
  },
  providerDocuments: [],
  providerPaymentMethods: [],
  confirmDialog: {
    title: "",
    confirmText: "",
    cancelText: "",
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
  verifyModal: {
    isOpen: false,
    selectedIBAN: "",
  },
  saved: true,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_COUNTRIES":
      return { ...state, countries: action.payload };
    case "SET_DOCUMENTS":
      return {
        ...state,
        providerDocuments: action.payload,
      };
    case "SET_DOCUMENT_INPUT":
      return {
        ...state,
        documentModal: {
          ...state.documentModal,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_EMAIL_INPUT":
      return {
        ...state,
        newEmail: {
          ...state.newEmail,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_PHONE_INPUT":
      return {
        ...state,
        newPhone: {
          ...state.newPhone,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_PAYMENT_METHODS":
      return {
        ...state,
        providerPaymentMethods: action.payload,
      };
    case "SET_PROVIDER":
      return {
        ...state,
        form: action.payload,
      };
    case "SET_CENTERS":
      return { ...state, centers: action.payload.centers };
    case "SET_REGIONS":
      return { ...state, regions: action.payload.regions };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_CITY":
      return {
        ...state,
        form: {
          ...state.form,
          city: action.payload.city,
          cityId: action.payload.cityId,
        },
      };
    case "SET_COUNTRY":
      return {
        ...state,
        form: {
          ...state.form,
          country: action.payload.country,
          countryId: action.payload.countryId,
        },
      };
    case "SET_MODAL":
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          isOpen: action.payload.value,
        },
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "SET_SAVED_VALUE":
      return { ...state, saved: action.payload.value };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "RESET_DOCUMENT_DIALOG":
      return {
        ...state,
        documentModal: initialState.documentModal,
      };
    case "SET_IBAN_MODAL":
      return {
        ...state,
        ibanModal: {
          ...state.ibanModal,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_IBAN_MODAL":
      return {
        ...state,
        ibanModal: initialState.ibanModal,
      };
    case "SET_SELECTED_IBAN":
      return {
        ...state,
        verifyModal: {
          ...state.verifyModal,
          selectedIBAN: action.payload,
        },
      };
    case "RESET_VERIFY_MODAL":
      return {
        ...state,
        verifyModal: initialState.verifyModal,
      };
    default:
      throw new Error();
  }
}

export default function ProviderPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("providers");
  const [tErrors] = useTranslation("errors");

  const [anchorEl, setAnchorEl] = React.useState({ id: null, anchorEl: null });

  const columnsInvoices = [
    { key: "title", label: t("title"), sortType: "string" },
    { key: "invoiceNumber", label: t("number"), sortType: "string" },
    { key: "paidAt", label: t("paidAt"), sortType: "string" },
    {
      key: "amount",
      label: t("base"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "total",
      label: t("total"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "ProviderPaymentMethod.name",
      label: t("paymentMethod"),
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    { key: "ExpenseType.name", label: t("expenseType"), sortType: "string" },
    {
      key: "CenterProviderInvoices",
      label: t("center"),
      sortType: "other",
      renderFunction: (value, item) => {
        if (!value.length) return "";
        if (value.length === 1) return value[0].Center.name;
        return (
          <>
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setAnchorEl({ id: item.id, anchorEl: e.currentTarget });
              }}
            >
              {t("variousCenters")}
            </Button>
            <Popover
              open={anchorEl.id === item.id}
              anchorEl={anchorEl.anchorEl}
              onClose={(e) => {
                e.stopPropagation();
                setAnchorEl({ id: null, anchorEl: null });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <List dense>
                {value.map((center) => (
                  <ListItem
                    key={center.id}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <ListItemText primary={center.Center?.name} />
                  </ListItem>
                ))}
              </List>
            </Popover>
          </>
        );
      },
    },
    { key: "date", label: t("date"), sortType: "string" },
    {
      key: "fileRequired",
      label: t("file"),
      sortType: "number",
      renderFunction: (value, item) =>
        value == true ? (
          item.filePath != null ? (
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                seeFile(item);
              }}
            >
              <InsertDriveFileIcon color="success" />
            </IconButton>
          ) : (
            <IconButton>
              <InsertDriveFileIcon color="error" />
            </IconButton>
          )
        ) : (
          <InsertDriveFileIcon color="disabled" />
        ),
    },
  ];

  const handleInputChange = (e) => {
    setInputErrorFalse();
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const getProvider = () => {
    let params = {
      include: [
        "ProviderInvoice",
        "CenterProviderInvoice",
        "Center",
        "Documents",
        "User",
        "City",
        "Country",
      ],
    };
    api
      .get("/providers/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          response.data.phones = response.data.phoneNumbers;
          response.data.city = response.data.City;
          response.data.country = response.data.Country;
          dispatch({
            type: "SET_PROVIDER",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getRegions = () => {
    api
      .get("/regions")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_REGIONS",
            payload: {
              regions: response.data,
            },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTERS",
            payload: {
              centers: response.data,
            },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getProviderDocuments = () => {
    if (user.hasAction("VIEW_PROVIDER_DOCUMENTS")) {
      api
        .get("/providers/" + id + "/documents")
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            dispatch({
              type: "SET_DOCUMENTS",
              payload: response.data,
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        });
    }
  };

  const getProviderPaymentMethods = () => {
    api
      .get("/providers/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("provider");

    getProvider();
    getRegions();
    getCenters();
    getProviderDocuments();
    getProviderPaymentMethods();
  }, []);

  const submitForm = () => {
    if (validateForm()) {
      let data = {};

      state.form.brand !== "" && (data.brand = state.form.brand);
      state.form.businessName !== "" &&
        (data.businessName = state.form.businessName);
      state.form.cif !== "" && (data.cif = state.form.cif);
      state.form.address !== "" && (data.address = state.form.address);
      state.form.defaultVatPercentage !== "" &&
        (data.defaultVatPercentage = state.form.defaultVatPercentage);
      state.form.defaultPersonalIncome !== "" &&
        (data.defaultPersonalIncome = state.form.defaultPersonalIncome);
      data.comments = state.form.comments;
      state.form.postalCode !== "" && (data.postalCode = state.form.postalCode);
      state.form.city && (data.cityId = state.form.city.id);
      state.form.centerId !== "" && (data.centerId = state.form.centerId);
      state.form.country && (data.countryId = state.form.country.id);
      state.form.authorizedBy !== "" &&
        (data.authorizedBy = state.form.authorizedBy);
      data.blocked = state.form.blocked;
      state.form.defaultIBANId !== "" &&
        (data.defaultIBANId = state.form.defaultIBANId);
      state.form.defaultPaymentMethodId !== "" &&
        (data.defaultPaymentMethodId = state.form.defaultPaymentMethodId);
      state.form.paymentDelay !== "" &&
        (data.paymentDelay = state.form.paymentDelay);

      data.emails = state.form.emails.map((email) => {
        email = { email: email.email, contact: email.contact };
        return email;
      });

      data.phones = state.form.phones?.map((phone) => {
        phone.countryCode = phone.number?.substring(0, 3);
        return phone;
      });
      dispatch({ type: "SET_SAVED_VALUE", payload: { value: false } });
      api
        .post("/providers/edit/" + id, data)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("providerEditSuccess"), { variant: "success" });
            history.goBack();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => {
          dispatch({ type: "SET_SAVED_VALUE", payload: { value: true } });
        });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let fields = [
      "brand",
      "businessName",
      "cif",
      "countryId",
      "defaultVatPercentage",
      "defaultPersonalIncome",
    ];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    return isValid;
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const validateEmail = (emailVal) => {
    let isValid = true;

    const re = /\S+@\S+\.\S+/; //Regex for testing email format

    if (emailVal !== null && emailVal !== "") {
      if (!emailVal || emailVal === "" || !re.test(emailVal)) {
        isValid = false;
      }
      state.form.emails.forEach((email) => {
        if (email.email.localeCompare(emailVal) === 0) {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }

    return isValid;
  };

  const validateNumber = (phoneNumber) => {
    let isValid = true;

    if (phoneNumber !== null && phoneNumber !== "") {
      state.form.phones.forEach((phone) => {
        if (phone.number.localeCompare(phoneNumber) === 0) {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }

    return isValid;
  };

  const handleOpenEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: true,
      },
    });
  };

  const handleCloseEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: false,
      },
    });
  };

  const openConfirmEmail = (email) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteEmailQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText:
          email.email + (email.contact !== null ? " - " + email.contact : ""),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteEmail(email);
          resetConfirmDialog();
        },
      },
    });
  };

  const openConfirmPhone = (phone) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deletePhoneQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: phone.number,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deletePhone(phone);
          resetConfirmDialog();
        },
      },
    });
  };

  const openConfirmIBAN = (iban) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteIBANQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: iban.IBAN.number,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteIban(iban);
          resetConfirmDialog();
        },
      },
    });
  };

  const handleOpenPhoneModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "phoneModal",
        value: true,
      },
    });
  };

  const handleClosePhoneModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "phoneModal",
        value: false,
      },
    });
  };

  const addEmail = () => {
    if (validateEmail(state.newEmail.email)) {
      let form = {
        email: state.newEmail.email,
        centerid: id,
        contact: state.newEmail.contact,
      };

      api
        .post("/providers/" + id + "/email", form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("emailAdded"), { variant: "success" });
            getProvider();
            handleCloseEmailModal();
            dispatch({
              type: "SET_EMAIL_INPUT",
              payload: { inputname: "email", value: "" },
            });
            dispatch({
              type: "SET_EMAIL_INPUT",
              payload: { inputname: "contact", value: "" },
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        });
    } else enqueueSnackbar(t("invalidEmail"), { variant: "error" });
  };

  const deleteEmail = (item) => {
    api
      .delete("/providers/" + id + "/email/" + item.email)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });

        } else {
          enqueueSnackbar(t("emailDeleted"), { variant: "success" });
          getProvider();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const addPhone = () => {
    if (state.newPhone.number !== "") {
      if (validateNumber(state.newPhone.number)) {
        let form = {
          number: state.newPhone.number,
          contact: state.newPhone.contact,
        };

        api
          .post("/providers/" + id + "/phone", form)
          .then((response) => {
            if (response.data.error) {
              enqueueSnackbar(tErrors(response.data.error), { variant: "error" });

            } else {
              enqueueSnackbar(t("phoneNumberAdded"), { variant: "success" });
              dispatch({
                type: "SET_INPUT",
                payload: {
                  inputname: "phones",
                  value: [...state.form.phones, state.newPhone],
                },
              });
              dispatch({
                type: "SET_PHONE_INPUT",
                payload: { inputname: "number", value: "" },
              });
              dispatch({
                type: "SET_PHONE_INPUT",
                payload: { inputname: "contact", value: "" },
              });
              handleClosePhoneModal();
            }
          })
          .catch((error) => {
            enqueueSnackbar(error, { variant: "error" });
          });
      }
    } else enqueueSnackbar(t("phoneNumberIsEmpty"), { variant: "warning" });
  };

  const deletePhone = (item) => {
    api
      .delete("/providers/" + id + "/phone-number/" + item.number)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("phoneNumberDeleted"), { variant: "success" });
          getProvider();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const addIban = () => {
    //Check if the iban has spaces and return a warning if it does
    if (/\s/.test(state.ibanModal.newIban))
      enqueueSnackbar(t("noSpacesAllowed"), { variant: "warning" });
    else {
      // Check if iban exists:
      let exist = state.form.ProviderIBANs.some(
        (iban) => iban.IBAN.number == state.ibanModal.newIban
      );

      if (!exist) {
        let formData = new FormData();

        formData.append("fileUpload", state.ibanModal.file);
        formData.append("number", state.ibanModal.newIban);

        api
          .post("/providers/" + id + "/iban", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            if (response.data.error) {
              enqueueSnackbar(tErrors(response.data.error), {
                variant: "error",
              });
            } else {
              enqueueSnackbar(t("ibanAdded"), { variant: "success" });
              dispatch({
                type: "RESET_IBAN_MODAL",
              });

              // If is the default iban, put it in default:
              if (state.form.ProviderIBANs.length === 0) {
                dispatch({
                  type: "SET_INPUT",
                  payload: {
                    inputname: "defaultIBANId",
                    value: response.data.IBANId,
                  },
                });
              }

              //Set data:
              response.data.creator = {
                id: user.id,
                name: user.name,
              };

              dispatch({
                type: "SET_INPUT",
                payload: {
                  inputname: "ProviderIBANs",
                  value: [...state.form.ProviderIBANs, response.data],
                },
              });
              handleCloseIbanModal();
            }
          })
          .catch((error) => {
            enqueueSnackbar(error.toString(), { variant: "error" });
          });
      } else {
        enqueueSnackbar(t("IBANIsRepeated"), { variant: "error" });
      }
    }
  };

  const deleteIban = (iban) => {
    if (iban.IBANId != state.form.defaultIBANId) {
      api
        .delete("/ibans/" + iban.IBANId)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("ibanDeleted"), { variant: "success" });
            dispatch({
              type: "SET_INPUT",
              payload: {
                inputname: "ProviderIBANs",
                value: state.form.ProviderIBANs.filter(
                  (item) => item.IBANId !== iban.IBANId
                ),
              },
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        });
    } else enqueueSnackbar(t("deleteDefaultIBANError"), { variant: "error" });
  };

  const handleIbanModalChange = (e) => {
    dispatch({
      type: "SET_IBAN_MODAL",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOpenIbanModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "ibanModal",
        value: true,
      },
    });
  };

  const handleCloseIbanModal = () => {
    dispatch({
      type: "RESET_IBAN_MODAL",
    });
  };

  const changeDefaultIban = (e) => {
    handleInputChange(e);
    if (user.hasAction("EDIT_PROVIDERS")) {
      let data = { defaultIBANId: e.target.value };
      api
        .post("/providers/" + id + "/default-iban", data)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("defaultIBANChanged"), { variant: "success" });
            getProvider();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        });
    }
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const handleEmailInputChange = (e) => {
    dispatch({
      type: "SET_EMAIL_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handlePhoneInputChange = (e) => {
    dispatch({
      type: "SET_PHONE_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOpenDocumentModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "documentModal",
        value: true,
      },
    });
  };

  const handleCloseDocumentModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "documentModal",
        value: false,
      },
    });
    dispatch({ type: "RESET_DOCUMENT_DIALOG" });
  };

  const handleFileChange = (e) => {
    dispatch({
      type: "SET_DOCUMENT_INPUT",
      payload: {
        inputname: "file",
        value: e.target.files[0],
      },
    });
    dispatch({
      type: "SET_DOCUMENT_INPUT",
      payload: {
        inputname: "name",
        value: e.target.files[0].name,
      },
    });
  };

  const createFile = () => {
    let formData = new FormData();
    formData.append(
      "fileUpload",
      new File([state.documentModal.file], "fileUpload")
    );
    formData.append("type", state.documentModal.file.type);
    formData.append("name", state.documentModal.file.name);
    formData.append("providerId", id);

    api
      .post("/providers/documents", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("documentCreated"), { variant: "success" });
          state.providerDocuments.push(response.data);
          dispatch({
            type: "SET_DOCUMENTS",
            payload: state.providerDocuments,
          });
          handleCloseDocumentModal();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getDocument = (document, download) => {
    api
      .get("/providers/documents/" + document.id, { responseType: "blob" })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (download) downloadDocument(response.data, document.type);
          else showDocument(response.data, document.type);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getUsername = (userId) => {
    api
      .get("/users/" + userId)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          return response.data.name;
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const showDocument = (document, type) => {
    const file = new Blob([document], { type: type });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const downloadDocument = (providerDocument, type) => {
    const file = new Blob([providerDocument], { type: type });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = fileURL.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const deleteDocument = (document) => {
    api
      .delete("/providers/documents/" + document.id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("fileDeletedSuccessfully"), { variant: "success" });
          dispatch({
            type: "SET_DOCUMENTS",
            payload: state.providerDocuments.filter(
              (pd) => pd.id != document.id
            ),
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const openConfirmDocument = (document) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteDocumentQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: document.name,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteDocument(document);
          resetConfirmDialog();
        },
      },
    });
  };

  const seeFile = (item) => {
    api
      .get("/provider-invoice/" + item.id + "/get-file", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: response.data.type });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const seeIbanDocument = (iban) => {
    api
      .get("/providers/" + Number(id) + "/iban-document/" + iban.IBAN.id, {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const markAsVerified = () => {
    if (Number(state.verifyModal?.selectedIBAN.createdBy) === Number(user.id)) {
      enqueueSnackbar(t("sameUserError"), { variant: "warning" });
      return;
    }
    api
      .post(
        "/providers/" +
          id +
          "/verify-iban/" +
          state.verifyModal?.selectedIBAN.IBANId
      )
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("ibanVerified"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "ProviderIBANs",
              value: [
                ...state.form.ProviderIBANs.map((formIban) => {
                  formIban.IBANId == state.verifyModal?.selectedIBAN.IBANId &&
                    (formIban.validator = {
                      id: user.id,
                      name: user.name,
                    });
                  return formIban;
                }),
              ],
            },
          });
          handleCloseVerifiedModal();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const providerIbanHasDocument = (ibanId) => {
    const providerIban = state.form.ProviderIBANs.find(
      (providerIban) => providerIban.IBANId == ibanId
    );
    return Boolean(providerIban?.hasDocument);
  };

  const handleCloseVerifiedModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "verifyModal",
        value: false,
      },
    });
    dispatch({ type: "RESET_VERIFY_MODAL" });
  };

  const handleOpenVerifiedModal = (iban) => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "verifyModal",
        value: true,
      },
    });
    dispatch({
      type: "SET_SELECTED_IBAN",
      payload: iban,
    });
  };

  return (
    <Container maxWidth="lg" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item container alignItems="center" columnSpacing={2} xs={12}>
            <Grid item>
              <Typography variant="h4">{t("editProvider")}</Typography>
            </Grid>
            {state.form.blocked && (
              <Grid item>
                <Chip color={"error"} label={t("blocked")} size="small" />
              </Grid>
            )}

            <Grid item flex={1} justifyContent="flex-end" display="flex">
              {state.form.accountingAccount && (
                <Tooltip
                  title={`
                    ${t("accountingAccount")}${":"} 
                    ${state.form.accountingAccount}
                  `}
                  >
                  <IconButton>
                    <HelpCenterIcon />
                  </IconButton>
                </Tooltip>
              )}

              {user.hasAction("CREATE_PROVIDER_INVOICES") && (
                <Tooltip title={t("createProviderInvoice")} placement="top">
                  <IconButton
                    color="primary"
                    LinkComponent={Link}
                    to={"/app/provider-invoice/create?providerId=" + id}
                  >
                    <NoteAddOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextInput
                error={state.inputError.brand}
                helperText={
                  state.inputError.brand
                    ? t("brand") + " " + t("inputError")
                    : ""
                }
                variant="outlined"
                label={t("brand")}
                value={state.form.brand}
                onChange={handleInputChange}
                name="brand"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                error={state.inputError.businessName}
                helperText={
                  state.inputError.businessName
                    ? t("businessName") + " " + t("inputError")
                    : ""
                }
                variant="outlined"
                label={t("businessName")}
                value={state.form.businessName}
                onChange={handleInputChange}
                name="businessName"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                error={state.inputError.cif}
                helperText={
                  state.inputError.cif ? t("cif") + " " + t("inputError") : ""
                }
                variant="outlined"
                label={t("cif")}
                value={state.form.cif}
                onChange={handleInputChange}
                name="cif"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CountryInput
                label={t("country")}
                name="country"
                value={state.form.country}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                error={state.inputError.address}
                helperText={
                  state.inputError.address
                    ? t("address") + " " + t("inputError")
                    : ""
                }
                variant="outlined"
                label={t("address")}
                value={state.form.address}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="address"
              />
            </Grid>

            <Grid item xs={8} sm={4}>
              <CityInput
                label={t("city")}
                value={state.form.city}
                onChange={handleInputChange}
                name="city"
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <PostalCodeInput
                value={state.form.postalCode}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                label={t("postalCode")}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextInput
                error={state.inputError.defaultVatPercentage}
                helperText={
                  state.inputError.defaultVatPercentage
                    ? t("defaultVatPercentage") + " " + t("inputError")
                    : ""
                }
                variant="outlined"
                label={t("defaultVatPercentage")}
                type="number"
                value={state.form.defaultVatPercentage}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="defaultVatPercentage"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextInput
                error={state.inputError.defaultPersonalIncome}
                helperText={
                  state.inputError.defaultPersonalIncome
                    ? t("defaultPersonalIncome") + " " + t("inputError")
                    : ""
                }
                variant="outlined"
                label={t("defaultPersonalIncome")}
                type="number"
                value={state.form.defaultPersonalIncome}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="defaultPersonalIncome"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CenterSelect
                error={state.inputError.center}
                helperText={
                  state.inputError.center
                    ? t("center") + " " + t("inputError")
                    : ""
                }
                value={state.form.centerId}
                onChange={handleInputChange}
                name="centerId"
                autoWidth
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                label={t("defaultPaymentMethod")}
                value={state.form.defaultPaymentMethodId}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="defaultPaymentMethodId"
                options={[
                  { label: "-", value: "" },
                  ...state.providerPaymentMethods.map((method) => ({
                    label: t(method.name),
                    value: method.id,
                  })),
                ]}
                shrink={true}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                label={t("paymentDelay")}
                value={state.form.paymentDelay}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="paymentDelay"
                options={[
                  { value: "0", label: "0" },
                  { value: "15", label: "15" },
                  { value: "30", label: "30" },
                  { value: "60", label: "60" },
                ]}
                shrink={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                error={state.inputError.comments}
                label={t("comments")}
                multiline
                rows={4}
                variant="outlined"
                value={state.form.comments}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="comments"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">
                {state.form.creator
                  ? t("createdBy") + " " + state.form.creator?.name
                  : ""}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={t("authorized")}
                labelPlacement="end"
                control={
                  <Switch
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        handleInputChange({
                          target: { name: "authorizedBy", value: user.id },
                        });
                      } else {
                        handleInputChange({
                          target: { name: "authorizedBy", value: null },
                        });
                        handleInputChange({
                          target: { name: "approver", value: null },
                        });
                      }
                    }}
                    name="authorizedBy"
                    checked={Boolean(
                      state.form.authorizedBy && state.form.authorizedBy !== ""
                    )}
                  />
                }
              />

              {state.form.authorizedBy && state.form.authorizedBy !== "" && (
                <Typography variant="body1" display="inline">
                  {t("authorizedBy") +
                    " " +
                    (state.form.approver
                      ? state.form.approver.name
                      : user.name)}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label={t("blocked")}
                labelPlacement="end"
                control={
                  <Switch
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        handleInputChange({
                          target: { name: "blocked", value: true },
                        });
                      } else {
                        handleInputChange({
                          target: { name: "blocked", value: false },
                        });
                      }
                    }}
                    name="blocked"
                    checked={state.form.blocked}
                  />
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Emails */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h6"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <MailIcon />
                  {t("emails")}
                </Typography>
              </Grid>
              {user.hasAction("EDIT_PROVIDERS") && (
                <Grid item flex={1} justifyContent="flex-end" display="flex">
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleOpenEmailModal}
                  >
                    {t("addEmail")}
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              {state.form.emails?.map((email) => (
                <List key={email.id}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid item container xs={12}>
                        <Grid item>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("address") + ": "}
                          </Typography>
                          <Typography variant="body">{email?.email}</Typography>
                          <Grid item xs={12}></Grid>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("contact") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {email.contact ? email.contact : "---"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          flex={1}
                          alignItems="flex-start"
                          justifyContent="flex-end"
                          display="flex"
                        >
                          {user.hasAction("EDIT_PROVIDERS") && (
                            <IconButton onClick={() => openConfirmEmail(email)}>
                              <HighlightOffIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </List>
              ))}
              {state.form.emails.length == 0 && t("noEmails")}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Phones */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h6"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <Phone />
                  {t("phoneNumbers")}
                </Typography>
              </Grid>
              {user.hasAction("EDIT_PROVIDERS") && (
                <Grid item flex={1} justifyContent="flex-end" display="flex">
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleOpenPhoneModal}
                  >
                    {t("addPhone")}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {state.form.phones?.map((phone) => (
                <List key={phone.id}>
                  <Card variant="outlined" xs={6}>
                    <CardContent>
                      <Grid item container xs={12}>
                        <Grid item>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("number") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {phone?.number}
                          </Typography>
                          <Grid item xs={12}></Grid>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("contact") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {phone.contact ? phone.contact : "---"}
                          </Typography>
                        </Grid>
                        {user.hasAction("EDIT_PROVIDERS") && (
                          <Grid
                            item
                            flex={1}
                            alignItems="flex-start"
                            justifyContent="flex-end"
                            display="flex"
                          >
                            <IconButton onClick={() => openConfirmPhone(phone)}>
                              <HighlightOffIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </List>
              ))}
              {state.form.phones.length == 0 && t("noPhones")}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* IBANs */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h6"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <PaymentIcon />
                  {t("IBANs")}
                </Typography>
              </Grid>
              {user.hasAction("EDIT_PROVIDERS") &&
                user.hasAction("EDIT_PROVIDER_IBAN") && (
                  <Grid item flex={1} justifyContent="flex-end" display="flex">
                    <Button
                      startIcon={<AddIcon />}
                      onClick={handleOpenIbanModal}
                    >
                      {t("addIban")}
                    </Button>
                  </Grid>
                )}
            </Grid>

            <Grid item xs={12}>
              <CustomSelect
                label={t("defaultIBAN")}
                value={state.form.defaultIBANId}
                onChange={(e) => {
                  changeDefaultIban(e);
                }}
                name="defaultIBANId"
                options={[
                  { value: null, label: t("none") },
                  ...state.form.ProviderIBANs.map((iban) => ({
                    label: iban.IBAN.number,
                    value: iban.IBANId,
                  })),
                ]}
                shrink={true}
              />
            </Grid>
            <Grid item xs={12}>
              {state.form.ProviderIBANs?.map((iban) => (
                <List key={iban.IBANId}>
                  <Card variant="outlined" xs={6}>
                    <CardContent>
                      <Grid item container xs={12}>
                        <Grid item>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("number") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {iban?.IBAN.number}
                          </Typography>
                        </Grid>
                        {user.hasAction("EDIT_PROVIDERS") &&
                          user.hasAction("EDIT_PROVIDER_IBAN") && (
                            <Grid
                              item
                              flex={1}
                              alignItems="flex-start"
                              justifyContent="flex-end"
                              display="flex"
                            >
                              {iban.hasDocument && (
                                <Tooltip title={t("seeDocument")}>
                                  <IconButton
                                    onClick={() => seeIbanDocument(iban)}
                                  >
                                    <RemoveRedEyeIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <Tooltip
                                title={
                                  iban.validator ? t("verified") : t("verify")
                                }
                              >
                                <IconButton
                                  onClick={() =>
                                    iban.validator
                                      ? {}
                                      : handleOpenVerifiedModal(iban)
                                  }
                                >
                                  <VerifiedIcon
                                    color={
                                      iban.validator ? "success" : "primary"
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t("delete")}>
                                <IconButton
                                  onClick={() => openConfirmIBAN(iban)}
                                >
                                  <HighlightOffIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        <Grid item xs={12}>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("createdBy") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {iban?.creator?.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("verifiedBy") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {iban?.validator?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </List>
              ))}
              {state.form.ProviderIBANs.length == 0 && (
                <Typography variant="body1">{t("noIbans")}</Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Provider Invoices */}
            <Grid item>
              <Typography
                variant="h6"
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <ViewListIcon />
                {t("providerInvoices")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ProviderInvoiceTotals invoices={state.form.invoices} />
            </Grid>

            <Grid item xs={12}>
              <CustomTable
                columns={columnsInvoices}
                data={state.form.invoices}
                options={{
                  ...state.optionsInvoices,
                  rowlink: user.hasPage("PROVIDER INVOICES")
                    ? state.optionsInvoices.rowlink
                    : null,
                }}
              />
            </Grid>

            {/* Documents */}

            {user.hasAction("VIEW_PROVIDER_DOCUMENTS") && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container>
                  <Grid item>
                    <Typography
                      variant="h6"
                      display={"flex"}
                      alignItems={"center"}
                      flexWrap={"wrap"}
                    >
                      <FolderIcon />
                      {t("documents")}
                    </Typography>
                  </Grid>
                  {user.hasAction("EDIT_PROVIDERS") &&
                    user.hasAction("CREATE_PROVIDER_DOCUMENTS") && (
                      <Grid
                        item
                        flex={1}
                        justifyContent="flex-end"
                        display="flex"
                      >
                        <Button
                          startIcon={<AddIcon />}
                          onClick={handleOpenDocumentModal}
                        >
                          {t("addDocument")}
                        </Button>
                      </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                  {state.providerDocuments?.map((document) => (
                    <List key={document.id}>
                      <Card variant="outlined" xs={6}>
                        <CardContent>
                          <Grid item container xs={12}>
                            <Grid item>
                              <Typography
                                variant="body"
                                sx={{ fontWeight: "bold" }}
                              >
                                {t("name") + ": "}
                              </Typography>
                              <Typography variant="body">
                                {document?.name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              flex={1}
                              alignItems="flex-start"
                              justifyContent="flex-end"
                              display="flex"
                            >
                              <IconButton
                                onClick={() => getDocument(document, false)}
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => getDocument(document, true)}
                              >
                                <DownloadIcon />
                              </IconButton>
                              {user.hasAction("EDIT_PROVIDERS") &&
                                user.hasAction("DELETE_PROVIDER_DOCUMENTS") && (
                                  <IconButton
                                    onClick={() =>
                                      openConfirmDocument(document)
                                    }
                                  >
                                    <HighlightOffIcon />
                                  </IconButton>
                                )}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </List>
                  ))}
                  {state.providerDocuments?.length == 0 && t("noDocuments")}
                </Grid>
              </>
            )}
          </Grid>

          <Grid item container spacing={1} xs={12} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            {user.hasAction("EDIT_PROVIDERS") && (
              <Grid item>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  disabled={!state.saved}
                >
                  {!state.saved ? t("saving") : t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Add email Modal */}
        <Dialog
          open={state.emailModal.isOpen}
          onClose={handleCloseEmailModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle textAlign="right">
            {t("addEmail")}
            <IconButton onClick={handleCloseEmailModal}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextInput
                  label={t("email")}
                  name="email"
                  variant="outlined"
                  onChange={(e) => {
                    handleEmailInputChange(e);
                  }}
                  value={state.newEmail.email}
                />
              </Grid>
              <Grid item xs={10}>
                <TextInput
                  label={t("contact")}
                  name="contact"
                  variant="outlined"
                  onChange={(e) => {
                    handleEmailInputChange(e);
                  }}
                  value={state.newEmail.contact}
                />
              </Grid>

              <Grid item xs={2}>
                <Button variant="contained" onClick={addEmail}>
                  <AddCircleOutlineOutlinedIcon />
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* Add phone Modal */}
        <Dialog
          open={state.phoneModal.isOpen}
          onClose={handleClosePhoneModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle textAlign="right">
            {t("addPhoneNumber")}
            <IconButton onClick={handleClosePhoneModal}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item>
                <PhoneInput
                  inputProps={{
                    label: t("phoneNumber"),
                    name: "number",
                    onChange: (e) => {
                      handlePhoneInputChange(e);
                    },
                  }}
                  value={state.newPhone.number}
                />
              </Grid>

              <Grid item>
                <TextInput
                  label={t("contact")}
                  name="contact"
                  onChange={(e) => {
                    handlePhoneInputChange(e);
                  }}
                  value={state.newPhone.contact}
                />
              </Grid>

              <Grid item>
                <Button
                  onClick={addPhone}
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                >
                  {t("add")}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* Add IBAN modal */}
        <Dialog
          open={state.ibanModal.isOpen}
          onClose={handleCloseIbanModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle textAlign="right">
            {t("addIban")}
            <IconButton onClick={handleCloseIbanModal}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container rowSpacing={2} marginTop={1}>
              <Grid
                item
                container
                spacing={2}
                xs={12}
                flex={1}
                alignItems="center"
              >
                <Grid item flexGrow={1}>
                  <TextInput
                    label={t("newIban")}
                    name="newIban"
                    value={state.ibanModal.newIban}
                    onChange={(e) => {
                      handleIbanModalChange(e);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    component="label"
                    variant="contained"
                    fullWidth
                    startIcon={<PublishIcon />}
                  >
                    {t("selectFile")}
                    <input
                      type="file"
                      accept="application/pdf"
                      hidden
                      onChange={(e) =>
                        handleIbanModalChange({
                          target: { name: "file", value: e.target.files[0] },
                        })
                      }
                    />
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                xs={12}
                flex={1}
                alignItems="center"
              >
                <Grid item flexGrow={1}>
                  {state.ibanModal.file.name && (
                    <Typography variant="caption" fontWeight="bold">
                      {t("file") + ": " + state.ibanModal.file.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={addIban}
                    disabled={
                      state.ibanModal.file.length != "" &&
                      state.ibanModal.newIban
                        ? false
                        : true
                    }
                  >
                    {t("add")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* Add Document Modal */}
        <Dialog
          open={state.documentModal.isOpen}
          onClose={handleCloseDocumentModal}
        >
          <DialogTitle textAlign="right">
            {t("addDocument")}
            <IconButton onClick={handleCloseDocumentModal}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid item>
              <Grid item container spacing={2}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    component="label"
                    xs={6}
                  >
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                    <Typography variant="p">{t("selectFile")}</Typography>
                  </Button>
                </Grid>
                {state.documentModal.file != "" && (
                  <Grid item>
                    <TextInput
                      label={t("name")}
                      name="name"
                      value={state.documentModal.name}
                      onChange={(e) => {
                        dispatch({
                          type: "SET_DOCUMENT_INPUT",
                          payload: {
                            inputname: e.target.name,
                            value: e.target.value,
                          },
                        });
                      }}
                      shrink={true}
                    />
                  </Grid>
                )}
                {state.documentModal.file != "" && (
                  <Grid item>
                    <Button variant="contained" onClick={createFile}>
                      {t("upload")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          open={state.verifyModal.isOpen}
          onClose={handleCloseVerifiedModal}
          fullWidth
          maxWidth={
            providerIbanHasDocument(state.verifyModal?.selectedIBAN?.IBANId)
              ? "md"
              : "xs"
          }
        >
          <DialogTitle textAlign="right">
            {t("verifyIBAN")}
            <IconButton onClick={handleCloseVerifiedModal}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {providerIbanHasDocument(
              state.verifyModal?.selectedIBAN?.IBANId
            ) ? (
              <Grid item height={400}>
                <object
                  width="100%"
                  height="100%"
                  data={
                    SERVER +
                    "/providerIbanDocuments/" +
                    id +
                    "_" +
                    state.verifyModal?.selectedIBAN?.IBANId +
                    ".pdf"
                  }
                  type="application/pdf"
                />
              </Grid>
            ) : (
              <Grid item>
                <Typography variant="body1">
                  {t("noProviderIbanDocumentFound")}
                </Typography>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseVerifiedModal}>
              {t("back")}
            </Button>
            <Button
              color="primary"
              onClick={markAsVerified}
              variant="contained"
            >
              {t("verify")}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirm Dialog */}
        <ConfirmDialog
          title={state.confirmDialog.title}
          open={state.confirmDialog.isOpen}
          setOpen={setConfirmDialogState}
          confirmText={state.confirmDialog.confirmText}
          cancelText={state.confirmDialog.cancelText}
          onConfirm={state.confirmDialog.callback}
        >
          <Typography variant="body2" color="initial">
            {state.confirmDialog.childrenText}
          </Typography>
        </ConfirmDialog>
      </Paper>
    </Container>
  );
}
