import { useRef } from "react";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";

// Material UI
import { Button, IconButton, Tooltip } from "@mui/material";

// Icons
import GetAppIcon from "@mui/icons-material/GetApp";
import ArticleIcon from "@mui/icons-material/Article";

const ButtonCSV = (props) => {
  const {
    data = [],
    headers = [],
    filename = "csv",
    icon = false,
    tooltipTitle = "",
    disabled = false,
    buttonLabel = "CSV",
  } = props;

  const linkRef = useRef();

  const handleClick = (e) => {
    e.preventDefault();
    linkRef.current.link.click();
  };

  return !icon ? (
    <>
      <Button
        startIcon={<GetAppIcon />}
        onClick={handleClick}
        disabled={disabled}
      >
        {buttonLabel}
      </Button>
      <CSVLink
        data={data}
        headers={headers}
        filename={filename}
        ref={linkRef}
        separator=";"
      />
    </>
  ) : (
    <>
      <Tooltip title={tooltipTitle} placement="top">
        <span>
          <IconButton color="primary" onClick={handleClick} disabled={disabled}>
            <ArticleIcon />
          </IconButton>
        </span>
      </Tooltip>
      <CSVLink
        data={data}
        headers={headers}
        filename={filename}
        ref={linkRef}
      />
    </>
  );
};

ButtonCSV.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  filename: PropTypes.string,
  icon: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ButtonCSV;
