import {
  Box,
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ReactFlagsSelect from "react-flags-select";

import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useReducer } from "react";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";

import AppContext from "../../../context/AppContext";
import TextInput from "../../Inputs/TextInput";
import Dialog from "../../global/Dialog";

const BanksPage = () => {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("banks");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const initialState = {
    banks: [],
    createDialog: {
      bic: "",
      code: "",
      countryCode: "ES",
      isOpen: false,
      name: "",
    },
    filter: "",
    loaded: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "RESET_CREATE_DIALOG_VALUE":
        return { ...state, createDialog: initialState.createDialog };
      case "SET_BANKS":
        return { ...state, banks: action.payload };
      case "SET_CREATE_DIALOG_VALUE":
        return {
          ...state,
          createDialog: {
            ...state.createDialog,
            [action.payload.inputname]: action.payload.value,
          },
        };
      case "SET_LOADED_VALUE":
        return { ...state, loaded: action.payload };
      case "SET_FILTER_VALUE":
        return { ...state, filter: action.payload };
      default:
        throw new Error("Action type not found in reducer");
    }
  };

  const BANKS_COLUMNS = [
    { field: "name", headerName: t("name"), flex: 2 },
    { field: "bic", headerName: t("bic"), flex: 1 },
    { field: "code", headerName: t("code"), flex: 1 },
    { field: "countryCode", headerName: t("countryCode"), flex: 1 },
  ];

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getBanks();
  }, []);

  const createNewBank = () => {
    if (
      !state.createDialog.name ||
      !state.createDialog.bic ||
      !state.createDialog.code ||
      !state.createDialog.countryCode
    ) {
      enqueueSnackbar(t("anyFieldCanBeEmpty", { variant: "waring" }));
      return;
    }
    api
      .post("/banks/create", state.createDialog)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          resetForm();
          dispatch({
            type: "SET_BANKS",
            payload: [...state.banks, response.data],
          });
          enqueueSnackbar(t("bankCreatedSuccessfully"), { variant: "success" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getBanks = () => {
    dispatch({ type: "SET_LOADED_VALUE", payload: false });
    api
      .get("/banks")
      .then((response) => {
        if (response.data.error) {
          console.log(response.date.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const banks = response.data.map((bank) => {
            return {
              ...bank,
              countryCode: bank.Country.alpha2,
            };
          });
          dispatch({ type: "SET_BANKS", payload: banks });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_VALUE", payload: true });
      });
  };

  const filterBoxes = () => {
    return state.banks.filter(
      (bank) =>
        bank.name.toLowerCase().includes(state.filter.toLowerCase()) ||
        bank.bic.toLowerCase().includes(state.filter.toLowerCase()) ||
        bank.code.toLowerCase().includes(state.filter.toLowerCase()) ||
        bank.countryCode.toLowerCase().includes(state.filter.toLowerCase())
    );
  };

  const handleCreateDialogChange = (e) => {
    dispatch({
      type: "SET_CREATE_DIALOG_VALUE",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleFilterChange = (e) => {
    dispatch({ type: "SET_FILTER_VALUE", payload: e.target.value });
  };

  const resetForm = () => {
    dispatch({ type: "RESET_CREATE_DIALOG_VALUE" });
    handleCreateDialogChange({ target: { name: "isOpen", value: false } });
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: 3, height: "100%" }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid container item xs={12} marginBottom={3}>
            <Typography variant="h4">{t("banks")}</Typography>
          </Grid>
          <Grid item container xs={12} marginBottom={1} spacing={2}>
            <Grid item xs={12} sm="auto">
              <TextInput
                sx={{ maxWidth: 230 }}
                id="nameFilter"
                label={t("name")}
                name="name"
                onChange={(e) => {
                  handleFilterChange(e);
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() =>
                  handleCreateDialogChange({
                    target: { name: "isOpen", value: true },
                  })
                }
              >
                {t("createBank")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ height: "500", width: "100%" }}>
                <DataGrid
                  columns={BANKS_COLUMNS}
                  rows={filterBoxes()}
                  loading={!state.loaded}
                  autoHeight
                />
              </Box>
            </Grid>
            <Grid item container xs={12} spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button onClick={() => history.goBack()}>{t("back")}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        onClose={() =>
          handleCreateDialogChange({ target: { name: "isOpen", value: false } })
        }
        open={state.createDialog.isOpen}
        title={t("createBank")}
        children={
          <Grid container spacing={1} minHeight={240} justifyContent="flex-start" alignContent="flex-start">
            <Grid item xs={6}>
              <TextInput
                id="nameInput"
                label={t("name")}
                name="name"
                value={state.createDialog.name}
                onChange={handleCreateDialogChange}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactFlagsSelect
                selected={state.createDialog.countryCode}
                onSelect={(e) =>
                  handleCreateDialogChange({
                    target: { name: "countryCode", value: e },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                id="bicInput"
                label={t("bic")}
                name="bic"
                value={state.createDialog.bic}
                onChange={(e) => {
                  handleCreateDialogChange(e);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                id="codeInput"
                label={t("code")}
                name="code"
                value={state.createDialog.code}
                onChange={(e) => {
                  handleCreateDialogChange(e);
                }}
              />
            </Grid>
          </Grid>
        }
        actions={
          <Button
            variant="contained"
            disabled={
              !state.createDialog.name ||
              !state.createDialog.bic ||
              !state.createDialog.code ||
              !state.createDialog.countryCode
            }
            onClick={createNewBank}
          >
            {t("create")}
          </Button>
        }
      ></Dialog>
    </Container>
  );
};

export default BanksPage;
