import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import CenterSelect from "../../Inputs/CenterSelect";
import TextInputMultiline from "../../HTMLEditor";
import TextInput from "../../Inputs/TextInput";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_CENTER_EMAILS":
      return { ...state, centerEmails: action.payload };
    case "SET_CENTER_FILTER":
      return { ...state, filterCenters: action.payload };
    default:
      throw new Error("Action not found in reducer.");
  }
};

const initialState = {
  centerEmails: [],
  filterCenters: [],
  form: {
    centerEmails: [],
    comments: "",
    html: "",
    text: "",
    title: "",
    subject: "",
  },
};

const CreateMassEmailCenterPage = () => {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("massEmails");

  const cols = [
    {
      field: "Center",
      headerName: t("centerName"),
      flex: 1,
      valueFormatter: ({ value }) => value.name,
    },
    { field: "email", headerName: t("email"), flex: 1 },
    { field: "contact", headerName: t("contact"), flex: 1 },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("createMassEmailCenterPage");

    getCenterEmails();
  }, []);

  const getCenterEmails = () => {
    api
      .get("/center-emails")
      .then((response) => {
        if (!response.data.error) {
          dispatch({ type: "SET_CENTER_EMAILS", payload: response.data });
        } else {
          enqueueSnackbar(response.data.error.toString(), { variant: "error" });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleCenterFilterChange = (e) => {
    dispatch({ type: "SET_CENTER_FILTER", payload: e.target.value });
  };

  const submitForm = () => {
    let form = {};
    state.form.comments !== "" && (form.comments = state.form.comments);
    state.form.html !== "" && (form.html = state.form.html);
    state.form.text !== "" && (form.text = state.form.text);
    state.form.title !== "" && (form.title = state.form.title);
    state.form.subject !== "" && (form.subject = state.form.subject);
    form.createdBy = user.id;
    form.centerEmails = state.form.centerEmails;

    api
      .post("/mass-emails/create", form)
      .then((response) => {
        console.log("create email res", response);
        if (!response.data.error) {
          enqueueSnackbar(t("success"), { variant: "success" });
        } else {
          enqueueSnackbar(response.data.error.toString(), { variant: "error" });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createMassEmailCenter")}</Typography>
          </Grid>

          <Grid item container spacing={3}>
            <Grid item>
              <TextInput
                label={t("title")}
                name="title"
                value={state.form.title}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item>
              <TextInput
                label={t("comments")}
                name="comments"
                value={state.form.comments}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item>
            <CenterSelect
              multiple
              value={state.filterCenters}
              onChange={handleCenterFilterChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Container sx={{ height: 500 }}>
              <DataGrid
                columns={cols}
                rows={state.centerEmails.length > 0 ? state.centerEmails : []}
                checkboxSelection
                selectionModel={state.form.centerEmails}
                onSelectionModelChange={(ids) => {
                  handleInputChange({
                    target: { name: "centerEmails", value: ids },
                  });
                }}
              />
            </Container>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item container spacing={3}>
            <Grid item>
              <TextInput
                label={t("subject")}
                name="subject"
                value={state.form.subject}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item>
              <TextInputMultiline html={state.form.html} onChange={handleInputChange} />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                label={t("text")}
                name="text"
                value={state.form.text}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Button variant="contained" onClick={submitForm}>
            {t("create")}
          </Button>
        </Grid>
      </Paper>
    </Container>
  );
};

export default CreateMassEmailCenterPage;
