import { Button, Grid } from "@mui/material";
import { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/AppContext";

import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import SearchIcon from "@mui/icons-material/Search";
import SyncIcon from "@mui/icons-material/Sync";

import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";

import { BalanceChart, CashFlowChart } from "./charts/CashFlow";
import MovementsPerDayChart from "./charts/MovementsPerDayChart";
import PercentageAuditedChart from "./charts/PercentageAuditedChart";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_BANKING_TRANSACTIONS":
      return { ...state, bankingTransactions: action.payload };
    case "SET_FILTERED_TRANSACTIONS":
      return { ...state, filteredTransactions: action.payload };
    case "SET_BANK_ACCOUNTS":
      return { ...state, bankAccounts: action.payload };
    case "SET_FILTERS":
      return { ...state, filters: action.payload };
    case "SET_INPUT":
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.payload.inputName]: action.payload.value,
        },
      };
    case "SET_LOADING_TRUE":
      return { ...state, loading: true };
    case "SET_LOADING_FALSE":
      return { ...state, loading: false };
    default:
      throw new Error("Action not found in reducer");
  }
};

const initialState = {
  bankAccounts: [],
  bankingTransactions: [],
  filters: {
    dateFrom: "",
    dateUntil: "",
    bankAccountId: -1,
  },
  inputs: {
    dateFrom: "",
    dateUntil: "",
    bankAccountId: -1,
  },
  filteredTransactions: [],
  loading: false,
};

export const CashFlowPanel = () => {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("dashboard");
  const [state, dispatch] = useReducer(reducer, initialState);

  // Initial useEffect, get data
  useEffect(() => {
    setFilterCurrentDate();
    getBankAccounts();
  }, []);

  useEffect(() => {
    filteredTransactions();
  }, [state.filters, state.bankingTransactions]);

  const setFilters = () => {
    dispatch({
      type: "SET_FILTERS",
      payload: {
        dateFrom: state.inputs.dateFrom,
        dateUntil: state.inputs.dateUntil,
        bankAccountId: state.inputs.bankAccountId,
      },
    });
  };

  const filteredTransactions = () => {
    const filteredTransactions = state.bankingTransactions.filter(
      (transaction) =>
        transaction.bankAccountId === state.filters.bankAccountId &&
        (state.filters.dateFrom === "" ||
          transaction.transactionDate >= state.filters.dateFrom) &&
        (state.filters.dateUntil === "" ||
          transaction.transactionDate <= state.filters.dateUntil)
    );
    dispatch({
      type: "SET_FILTERED_TRANSACTIONS",
      payload: filteredTransactions,
    });
  };

  const getBankAccounts = () => {
    api.get("/bank-accounts").then((response) => {
      dispatch({ type: "SET_BANK_ACCOUNTS", payload: response.data });
    });
  };

  const getBankingTransactions = () => {
    dispatch({ type: "SET_BANKING_TRANSACTIONS", payload: [] });
    dispatch({ type: "SET_LOADING_TRUE" });

    api.get("/banking-transactions").then((response) => {
      if (!response.data.error) {
        dispatch({ type: "SET_BANKING_TRANSACTIONS", payload: response.data });
      }
      dispatch({ type: "SET_LOADING_FALSE" });
    });
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputName: e.target.name,
        value: e.target.value,
      },
    });
  };

  const setFilterCurrentDate = () => {
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    let lastDay;
    switch (month) {
      case 4:
      case 6:
      case 9:
      case 11:
        lastDay = 30;
        break;
      case 2:
        lastDay = 28;
        break;
      default:
        lastDay = 31;
    }

    if (month < 10) month = "0" + month;

    let dateFrom = year + "-" + month + "-01";
    let dateUntil = year + "-" + month + "-" + lastDay;

    dispatch({
      type: "SET_INPUT",
      payload: { inputName: "dateFrom", value: dateFrom },
    });
    dispatch({
      type: "SET_INPUT",
      payload: { inputName: "dateUntil", value: dateUntil },
    });
  };

  return (
    <Grid container spacing={3} padding={2}>
      <Grid item container spacing={2}>
        <Grid item>
          <CustomDate
            label={t("from")}
            InputLabelProps={{ shrink: true }}
            value={state.inputs.dateFrom}
            name="dateFrom"
            onChange={handleChangeFilter}
          />
        </Grid>
        <Grid item>
          <CustomDate
            label={t("until")}
            InputLabelProps={{ shrink: true }}
            value={state.inputs.dateUntil}
            name="dateUntil"
            onChange={handleChangeFilter}
          />
        </Grid>
        <Grid item>
          <CustomSelect
            label={t("bankAccount")}
            value={state.inputs.bankAccountId}
            name="bankAccountId"
            onChange={handleChangeFilter}
            options={[
              ...state.bankAccounts.map((account) => ({
                value: account.id,
                label: account.name,
              })),
              { value: -1, label: "" },
            ]}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={getBankingTransactions}
            startIcon={state.loading ? <HourglassEmptyIcon /> : <SyncIcon />}
            disabled={
              state.loading ||
              state.inputs.bankAccountId !== state.filters.bankAccountId ||
              state.inputs.bankAccountId < 0
            }
          >
            {state.loading ? t("loading") : t("update")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (state.bankingTransactions.length === 0)
                getBankingTransactions();
              setFilters();
            }}
            startIcon={<SearchIcon />}
            disabled={state.loading || state.inputs.bankAccountId < 0}
          >
            {t("search")}
          </Button>
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={12} lg={6}>
          {state.filteredTransactions !== undefined && (
            <CashFlowChart
              transactions={state.filteredTransactions}
              startDate={state.filters.dateFrom}
              endDate={state.filters.dateUntil}
              loading={state.loading}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          {state.filteredTransactions !== undefined && (
            <PercentageAuditedChart
              transactions={state.filteredTransactions}
              loading={state.loading}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {state.filteredTransactions !== undefined && (
            <MovementsPerDayChart
              transactions={state.filteredTransactions}
              loading={state.loading}
            />
          )}
        </Grid>

        {/*<Grid item xs={12}>
          <BalanceChart transactions={filteredTransactions} />
          </Grid>*/}
      </Grid>
    </Grid>
  );
};
