import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

// Material UI
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Components, utils and others
import AppContext from "../../../context/AppContext";
import CustomMonth from "../../Inputs/CustomMonth";
import ItemsSummary from "../../ItemsSummary";
import TextInput from "../../Inputs/TextInput";
import SearchButton from "../../Inputs/SearchButton";
import { localeFormat } from "../../../utils/format";
import ConfirmDialog from "../../ConfirmDialog";
import CustomButton from "../../Inputs/CustomButton";
import {
  F_INVOICE_SERIES_ID,
  ISSUED_INVOICE_STATE_ID,
  RECEIPT_PAYMENT_METHOD_ID,
} from "../../../data/constants";

const initialState = {
  confirmDialog: {
    title: "",
    isOpen: false,
    childrenText: "",
    callback: () => {},
  },
  form: {
    name: "",
    comments: "",
    dueDay: "",
    dueMonthYear: "",
  },
  createConfirmIsOpen: false,
  dataGridLoading: false,
  invoices: [],
  paymentDays: ["5", "10", "15", "20", "25", "30"],
  selectedInvoices: [],
  submitLoading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "RESET_INVOICES":
      return {
        ...state,
        invoices: initialState.invoices,
        selectInvoices: initialState.selectedInvoices,
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          isOpen: action.payload.isOpen,
          childrenText: action.payload.childrenText,
          callback: action.payload.callback,
        },
      };
    case "SET_DATAGRID_LOADING_TRUE":
      return {
        ...state,
        dataGridLoading: true,
      };
    case "SET_DATAGRID_LOADING_FALSE":
      return {
        ...state,
        dataGridLoading: false,
      };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_PAYMENT_DAYS":
      return { ...state, paymentDays: action.payload };
    case "SET_SELECTED_INVOICES":
      return { ...state, selectedInvoices: action.payload };
    case "SET_SUBMIT_LOADING":
      return {
        ...state,
        submitLoading: action.payload,
      };
    default:
      throw new Error("Action not found in reducer");
  }
}

export default function CreateDirectRemittancePage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("remittances");

  const DATA_GRID_COLUMNS = [
    {
      field: "concept",
      headerName: t("concept"),
      flex: 2,
      valueGetter: (params) =>
        params.row?.InvoiceSerie?.name + params.row?.number,
    },
    {
      field: "customerName",
      headerName: t("customerName"),
      flex: 2,
    },
    {
      field: "totalAmount",
      headerName: t("amount"),
      flex: 1,
      valueFormatter: ({ value }) => `${localeFormat(value)}€`,
    },
    {
      field: "iban",
      headerName: t("iban"),
      flex: 2,
      valueGetter: (params) => params.row?.IBAN?.number,
    },
    {
      field: "bic",
      headerName: "BIC",
      flex: 1,
      valueGetter: (params) => params.row?.IBAN?.bic,
    },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("createDirectRemittancePage");
    getPaymentDays();
  }, []);

  useEffect(() => {
    state.form.dueDay &&
      state.form.dueMonthYear &&
      setDefaultName(
        state.form.dueMonthYear +
          "-" +
          (Number(state.form.dueDay) >= 10
            ? state.form.dueDay
            : "0" + state.form.dueDay)
      );
  }, [state.form.dueDay, state.form.dueMonthYear]);

  // Backend calls

  const getInvoices = () => {
    dispatch({ type: "SET_DATAGRID_LOADING_TRUE" });

    let params = {
      include: ["PaymentMethod", "Merchantable", "Contract", "IBAN"],
      attributes: [
        {
          model: "Invoice",
          attributes: [
            "baseAmount",
            "customerName",
            "id",
            "number",
            "state",
            "totalAmount",
          ],
        },
        {
          model: "PaymentMethod",
          attributes: ["name"],
        },
        {
          model: "IBAN",
          attributes: ["number"],
        },
      ],
    };

    // Filter by receipt
    params.paymentMethodId = RECEIPT_PAYMENT_METHOD_ID;
    // Filter by state issued
    params.state = ISSUED_INVOICE_STATE_ID;
    // Filter by contract dayOfPayment
    params.dayOfPayment = state.form.dueDay;
    // Filter by no receipts
    params.numberOfReceipts = 0;
    // Filter by serie: only F
    params.types = [F_INVOICE_SERIES_ID];
    // Filter by issueDate: Exclude all old program invoices
    params.dateFrom = "2023-06-01";
    // Filter by correctly issued invoices
    params.billingError = 0;

    api
      .get("/invoices", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noInvoices"), { variant: "warning" });
          }
          dispatch({ type: "SET_INVOICES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_DATAGRID_LOADING_FALSE" });
      });
  };

  const getPaymentDays = () => {
    api
      .get("/payment-days/get")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const paymentDays = response.data.map((paymentDay) =>
            paymentDay.day.toString()
          );
          paymentDays.length > 0 &&
            dispatch({ type: "SET_PAYMENT_DAYS", payload: paymentDays });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const createDirectRemittance = () => {
    const selectedInvoices = state.invoices.filter((invoice) =>
      state.selectedInvoices.includes(invoice.id)
    );

    const allHaveIBAN = selectedInvoices.every(
      (invoice) => invoice.IBAN !== null
    );

    if (!allHaveIBAN) {
      enqueueSnackbar(t("invoicesNoIBAN"), { variant: "warning" });
      return;
    }

    const allHaveBIC = selectedInvoices.every(
      (invoice) => invoice.IBAN.bic !== ""
    );

    if (!allHaveBIC) {
      enqueueSnackbar(t("invoicesNoBIC"), { variant: "warning" });
      return;
    }

    dispatch({ type: "SET_SUBMIT_LOADING", payload: true });
    api
      .post("/remittances/create-direct", {
        form: {
          name: state.form.name,
          comments: state.form.comments,
          dueDate:
            state.form.dueMonthYear +
            "-" +
            (Number(state.form.dueDay) >= 10
              ? state.form.dueDay
              : "0" + state.form.dueDay),
        },
        selectedInvoices: state.selectedInvoices,
      })
      .then((response) => {
        dispatch({ type: "SET_SUBMIT_LOADING", payload: false });
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("remittanceCreatedSuccessfully"), {
            variant: "success",
          });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
        dispatch({ type: "SET_SUBMIT_LOADING", payload: false });
      });
  };

  // Handlers

  const handleCreateDirectRemittance = () => {
    if (validateForm()) {
      dispatch({
        type: "SET_CONFIRM_DIALOG",
        payload: {
          title: t("createDirectRemittance"),
          isOpen: true,
          childrenText: t("createDirectRemittanceQuestion"),
          callback: (confirmed) => {
            confirmed && createDirectRemittance();
            resetConfirmDialog();
          },
        },
      });
    }
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const selectInvoices = (selectedInvoicesIds) => {
    dispatch({ type: "SET_SELECTED_INVOICES", payload: selectedInvoicesIds });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  // Setters

  /**
   * Sets dueDate and automatically sets month and year according to dueDay
   * @param {*} dueDay - User input remittance due day
   */
  const setDefaultDate = (dueDay) => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let dueMonthYear = "";

    // Sets month according to input due day. If the due day is more or equal than the current day,
    // the month is set to the current month, else the month is set to the next month.
    if (dueDay >= day) {
      dueMonthYear =
        year.toString() +
        "-" +
        (month < 10 ? "0" + month.toString() : month.toString());
    } else {
      dueMonthYear =
        year.toString() +
        "-" +
        (month < 9 && "0") +
        (month <= 11 ? month + 1 : 0).toString();
    }
    dispatch({ type: "SET_DUE_MONTH_YEAR", payload: dueMonthYear });

    // Formats due date adding a 0 in front of dueDay if dueDay is smaller than 10
    const dueDate = dueMonthYear + "-" + (dueDay < 10 ? "0" : "") + dueDay;
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "dueDate", value: dueDate },
    });
    if (dueDay && dueMonthYear) setDefaultName(dueDate);
  };

  /**
   * Sets the default name for the remittance (remittance - date)
   * @param {*} dueDate - Input due date
   */
  const setDefaultName = (dueDate) => {
    const date = new Date(dueDate);

    const name =
      t("remittance") +
      "-" +
      date.getDate().toString() +
      "-" +
      date.toLocaleString("default", { month: "long" }) +
      "-" +
      date.getFullYear().toString();
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "name", value: name },
    });
  };

  /**
   * Formats and sets dueDate using dueDay and dueMonthYear
   * @param {*} dueDay - User input remittance due day
   * @param {*} dueMonthYear - User input remittance due month and year
   */
  const setDate = (dueDay, dueMonthYear) => {
    const dueDate = dueMonthYear + "-" + dueDay;

    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "dueDate", value: dueDate },
    });
    if (dueDay && dueMonthYear) setDefaultName(dueDate);
  };

  // Helpers

  /**
   * @returns - Sum of amounts of selected invoices
   */
  const computeTotalAmount = () => {
    const selectedInvoices = state.invoices.filter((invoice) =>
      state.selectedInvoices.includes(invoice.id)
    );

    const totalAmount = Number(
      selectedInvoices.reduce(
        (sum, selectedInvoice) => sum + selectedInvoice.totalAmount,
        0
      )
    );
    return totalAmount;
  };

  // Validators

  const validateForm = () => {
    let isValid = true;

    let fields = ["name", "dueDate"];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        enqueueSnackbar(t(field) + " " + t("isRequired").toLowerCase(), {
          variant: "error",
        });
        isValid = false;
      }
    });

    return isValid;
  };

  return (
    <Container maxWidth="lg" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createDirectRemittance")}</Typography>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <TextInput
                select
                value={state.form.dueDay}
                onChange={handleInputChange}
                label={t("dueDay")}
                name="dueDay"
              >
                {state.paymentDays.map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </TextInput>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <CustomMonth
                value={state.form.dueMonthYear}
                onChange={handleInputChange}
                name="dueMonthYear"
                label={t("dueMonthYear")}
                inputProps={{
                  min: new Date().toISOString().slice(0, 7),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextInput
                label={t("name")}
                value={state.form.name}
                onChange={handleInputChange}
                name="name"
              />
            </Grid>

            <Grid item xs={12} sm={6} md="auto">
              <SearchButton
                loading={state.dataGridLoading}
                disabled={
                  !state.form.dueMonthYear ||
                  !state.form.dueDay ||
                  !state.form.name
                }
                onClick={getInvoices}
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                label={t("comments")}
                value={state.form.comments}
                name="comments"
                multiline
                rows={4}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ItemsSummary
              gridItems={[
                {
                  translatedText: t("selectedInvoices"),
                  value: state.selectedInvoices.length,
                },
                {
                  translatedText: t("totalAmount"),
                  value: localeFormat(computeTotalAmount()) + "€",
                },
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <Box style={{ height: 400, width: "100%" }}>
              <DataGrid
                columns={DATA_GRID_COLUMNS}
                rows={state.invoices.filter(
                  (invoice) => invoice.totalAmount > 0
                )}
                checkboxSelection
                selectionModel={state.selectedInvoices}
                onSelectionModelChange={(ids) => {
                  selectInvoices(ids);
                }}
                loading={state.dataGridLoading}
              />
            </Box>
          </Grid>

          <Grid item container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            <Grid item>
              <CustomButton
                loading={state.submitLoading}
                color="success"
                disabled={
                  state.selectedInvoices.length === 0 ||
                  state.dueMonthYear === ""
                }
                onClick={handleCreateDirectRemittance}
              >
                {t("create")}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* Confirm Dialog */}
      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
}
