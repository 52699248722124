import { Grid } from "@mui/material";
import HubspotLeadsChart from "../../charts/HubspotLeadsChart";
import { useContext } from "react";

import CenterReportContext from "../CenterReportContext";

function MarketingTab() {
  const { center, contracts } = useContext(CenterReportContext);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <HubspotLeadsChart center={center} contracts={contracts} />
      </Grid>
    </Grid>
  );
}

export default MarketingTab;
