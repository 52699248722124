/* 

    Drag test 1

    A file can moved within a directory, between separate 
    directories, and between nested directories.

    A directory can be moved within the page and moved into a separate 
    directory (if the draggable directory does not contain directories)

    A directory cannot be moved outside of a directory back to the page or to 
    another directory. Directories that contain directories cannot be moved
    into a separate directory


    A directory contains an separate arrays of its children file ids, and 
    children dir ids

*/

import {
    Box,
    Container,
    Grid,
    Typography
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useReducer } from "react";

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_DIR_FILES":
            return {
                ...state, dirs: {
                    ...state.dirs,
                    [action.payload.id]:
                    {
                        ...state.dirs[action.payload.id],
                        files: action.payload.files
                    }
                }
            };
        case "SET_DIR_ORDER":
            return { ...state, dirOrder: action.payload };
        case "SET_DIR_DIRS":
            return {
                ...state, dirs: {
                    ...state.dirs,
                    [action.payload.id]: {
                        ...state.dirs[action.payload.id],
                        dirIds: action.payload.dirIds
                    }
                }
            };
        default:
            throw new Error("Action not found in reducer");
    }
}

const initialState = {
    files: {
        "1": {
            id: "1",
            name: "fileOne"
        },
        "2": {
            id: "2",
            name: "fileTwo"
        },
        "3": {
            id: "3",
            name: "fileThree"
        }
    },
    dirs: {
        "dir1": {
            id: "dir1",
            fileIds: ["1", "2", "3"],
            dirIds: [],
        },
        "dir2": {
            id: "dir2",
            fileIds: [],
            dirIds: [],
        },
        "dir3": {
            id: "dir3",
            fileIds: [],
            dirIds: []
        },
    },
    dirOrder: ["dir1", "dir2", "dir3"]

};

const Directory = ({ id, dirs, files, index }) => {

    return (
        <Draggable draggableId={id} index={index}>
            {(provided) => (
                <Box
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    sx={{ border: "solid black", margin: 2 }}
                >
                    <Box
                        sx={{ border: "solid black" }}
                        {...provided.dragHandleProps}
                    >
                        <Typography variant="subtitle1">
                            {id}
                        </Typography>
                    </Box>

                    {dirs?.map((dirItem, i) => {
                        return (
                            <Directory
                                id={dirItem.id}
                                files={dirItem.fileIds}
                                dirs={dirItem.dirs}
                                key={dirItem.id}
                                index={i}
                            />
                        )
                    })}


                    <Droppable droppableId={id} direction="horizontal" type="file">
                        {(provided) => (
                            <Container
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                sx={{
                                    padding: 3,
                                    display: "flex",
                                }}
                            >
                                {files.map((fileId, i) => (
                                    <File
                                        id={fileId}
                                        index={i}
                                        key={fileId}
                                    />
                                ))}
                                {provided.placeholder}
                            </Container>
                        )}
                    </Droppable>
                </Box>
            )}

        </Draggable>



    );
}

const File = ({ index, id }) => {

    return (
        <Draggable draggableId={id} index={index} >
            {(provided) => (
                <Box
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    sx={{
                        width: 100,
                        height: 100,
                        backgroundColor: "secondary.main",
                        border: "solid black",
                        margin: 3
                    }}>
                    {id}
                </Box>
            )}
        </Draggable>

    );
}


export const DragTestPage = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const moveDirInPage = (source, destination, draggableId) => {
        let dirOrder = state.dirOrder;

        if (dirOrder.includes(draggableId)) {
            dirOrder.splice(source.index, 1);
            dirOrder.splice(destination.index, 0, draggableId);

        } else {
            // missing, remove dir from parent directory
            dirOrder.splice(destination.index, 0, draggableId);
        }

        dispatch({ type: "SET_DIR_ORDER", payload: dirOrder });
    }

    const moveFile = (source, destination, draggableId) => {
        const startDir = state.dirs[source.droppableId];
        const endDir = state.dirs[destination.droppableId];

        const startDirFiles = startDir.fileIds;
        startDirFiles.splice(source.index, 1);

        if (startDir === endDir) { //move files within dir
            startDirFiles.splice(destination.index, 0, draggableId);
            dispatch({
                type: "SET_DIR_FILES",
                payload: { id: source.droppableId, files: startDirFiles }
            });
        }

        if (startDir !== endDir) { // move files between dirs
            const endDirFiles = endDir.fileIds;
            endDirFiles.splice(destination.index, 0, draggableId);
            dispatch({
                type: "SET_DIR_FILES",
                payload: { id: source.droppableId, files: startDirFiles }
            });
            dispatch({
                type: "SET_DIR_FILES",
                payload: { id: destination.droppableId, files: endDirFiles }
            });
        }
    }

    const moveDirInDir = (source, combine, draggableId) => {
        // remove dir from page
        let dirOrder = state.dirOrder;
        if (dirOrder.includes(draggableId)) {
            dirOrder.splice(source.index, 1);
            dispatch({ type: "SET_DIR_ORDER", payload: dirOrder });
        }


        //add draggable dir to destination dir
        let dirDestArr = state.dirs[combine.draggableId].dirIds;
        if (!dirDestArr.includes(draggableId)) {
            dirDestArr.push(draggableId);
            dispatch({
                type: "SET_DIR_DIRS", payload:
                    { id: combine.draggableId, dirIds: dirDestArr }
            });
        }

    };

    const onDragEnd = (result) => {
        const { destination, source, draggableId, type, combine } = result;

        if (destination && !(destination.droppableId === source.droppableId && destination.index === source.index)) {

            if (type === 'dir') {
                moveDirInPage(source, destination, draggableId);
            }

            if (type === 'file') {
                moveFile(source, destination, draggableId);
            }

        } else {

            // Movement of dir in dirs
            if (combine) {
                console.log(result);
                moveDirInDir(source, combine, draggableId);
            }
        }


    };

    return (
        <Container sx={{ padding: 1 }}>
            <DragDropContext onDragEnd={onDragEnd} >
                <Droppable droppableId="all" type="dir" isCombineEnabled>
                    {provided => (
                        <Grid
                            container
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            spacing={3}
                        >
                            {state.dirOrder.map((dirId, i) => {
                                const dir = state.dirs[dirId];
                                // const files = dir.fileIds.map(fileId => state.files[fileId]);
                                const dirs = dir.dirIds.map(dirItemId => state.dirs[dirItemId]);
                                return (
                                    <Grid item xs={12}>
                                        <Directory
                                            id={dirId}
                                            files={dir.fileIds}
                                            dirs={dirs}
                                            key={dirId}
                                            index={i}
                                        />
                                    </Grid>
                                )
                            })}
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        </Container>


    );
}