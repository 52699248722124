// Material UI
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AppContext from "../../../../context/AppContext";
import ButtonCSV from "../../../Inputs/ButtonCSV";

import { months } from "../../../../utils/chartUtils";
import { localeFormat } from "../../../../utils/format";

const last12Months = getLast12Months();

function HubspotLeadsChart({ center, contracts }) {
  const [t] = useTranslation("hubspot");
  const [tErrors] = useTranslation("errors");
  const { api } = useContext(AppContext);
  const centerMonths = lastCenterMonths(last12Months, contracts);
  const [leads, setLeads] = useState([]);

  const CSV_HEADERS = [
    { label: "Month", key: "month" },
    { label: "Leads", key: "leads" },
    { label: "Contracts", key: "contracts" },
    { label: "SQM", key: "sqm" },
    { label: "Revenue", key: "revenue" },
    { label: "Conversion", key: "conversion" },
    { label: "Total", key: "total" },
  ];

  /* Backend calls */
  const getCenterLeads = () => {
    if (!contracts || !contracts.length || !center || !center?.id) return;
    const params = { centerId: center.id };
    api
      .get("/hubspot/deals", { params })
      .then((res) => {
        if (res.data.error) {
          console.error(res.data.error);
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          if (res.data.results !== undefined) setLeads(res.data.results);
          else setLeads([]);
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  /* CSV parser */
  const getCSVData = () => {
    const sqmCalc = (total, contract) => total + contract.meters;
    return centerMonths?.map((month) => {
      return {
        month: month.name,
        leads: localeFormat(getMonthLeads(month, leads).length),
        contracts: localeFormat(getMonthNewContracts(month, contracts).length),
        sqm: localeFormat(
          getMonthNewContracts(month, contracts).reduce(sqmCalc, 0)
        ),
        revenue: localeFormat(
          getMonthTotalAmount(getMonthCurrentContracts(month, contracts))
        ),
        conversion: localeFormat(
          getMonthLeads(month, leads).length > 0
            ? (getMonthNewContracts(month, contracts).length /
                getMonthLeads(month, leads).length) *
                100
            : 0
        ),
        total: localeFormat(
          getMonthTotalAmount(getMonthCurrentContracts(month, contracts))
        ),
      };
    });
  };

  /* Getters */
  const getLeads = () => {
    return centerMonths?.map((month) => (
      <TableCell>
        {leads.length > 0
          ? localeFormat(getMonthLeads(month, leads).length)
          : ""}
      </TableCell>
    ));
  };

  const getContracts = () => {
    return centerMonths?.map((month) => (
      <TableCell>
        {contracts?.length > 0
          ? localeFormat(getMonthNewContracts(month, contracts).length)
          : ""}
      </TableCell>
    ));
  };

  const getMeters = () => {
    const calc = (total, contract) => total + contract.meters;
    return centerMonths?.map((month) => (
      <TableCell>
        {contracts?.length > 0
          ? localeFormat(
              getMonthNewContracts(month, contracts).reduce(calc, 0)
            ) + "m²"
          : ""}
      </TableCell>
    ));
  };

  const getRevenue = () => {
    const calc = (total, contract) => total + contract.price;
    return centerMonths?.map((month) => (
      <TableCell>
        {contracts?.length > 0
          ? localeFormat(
              getMonthNewContracts(month, contracts).reduce(calc, 0)
            ) + "€"
          : ""}
      </TableCell>
    ));
  };

  const getConverisons = () => {
    return centerMonths?.map((month) => {
      const monthLeads = getMonthLeads(month, leads);
      const monthNewContracts = getMonthNewContracts(month, contracts);
      return (
        <TableCell>
          {monthLeads.length > 0
            ? localeFormat(
                (monthNewContracts.length / monthLeads.length) * 100
              ) + "%"
            : ""}
        </TableCell>
      );
    });
  };

  const getTotals = () => {
    return centerMonths?.map((month) => (
      <TableCell>
        {contracts?.length > 0
          ? localeFormat(
              getMonthTotalAmount(getMonthCurrentContracts(month, contracts))
            ) + "€"
          : ""}
      </TableCell>
    ));
  };

  const getRowTotals = (mode) => {
    if (mode === 4) {
      const totalLeads = getRowTotals(0);
      const totalContracts = getRowTotals(1);
      return localeFormat((totalContracts * 100) / totalLeads);
    }
    const funct = (total, el) => total + Number(el);
    const contractsFunct = (total, contract) => total + contract.meters;
    const revenueFunct = (total, contract) => total + contract.price;
    return localeFormat(
      centerMonths
        .map((month) => {
          const monthLeads = getMonthLeads(month, leads);
          const monthContracts = getMonthNewContracts(month, contracts);
          if (mode === 0 && leads.length > 0) return monthLeads.length;
          if (mode === 1 && contracts?.length > 0) return monthContracts.length;
          if (mode === 2 && contracts?.length > 0)
            return getMonthNewContracts(month, contracts).reduce(
              contractsFunct,
              0
            );
          if (mode === 3 && contracts?.length > 0)
            return getMonthNewContracts(month, contracts).reduce(
              revenueFunct,
              0
            );
        })
        .reduce(funct, 0)
    );
  };

  /* Hooks */
  useEffect(getCenterLeads, [contracts]);

  return (
    <>
      <Grid item container justifyContent="flex-end">
        <ButtonCSV
          data={getCSVData()}
          headers={CSV_HEADERS}
          filename={t("HubSpotChart")}
        />
      </Grid>
      <Box height="100%" overflow="auto">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={1}>
                  conversiones y objetivos (publicidad y contratos)
                </TableCell>
                {groupMonthsByYear().map((year) => (
                  <TableCell colSpan={year.numberOfMonths}>
                    {year.year}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                {centerMonths?.map((month) => (
                  <TableCell>{month.name}</TableCell>
                ))}
                <TableCell>{t("Total")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leads.length > 0 && (
                <TableRow>
                  <TableCell>Leads</TableCell>
                  {getLeads()}
                  <TableCell>{getRowTotals(0)}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>Contracts</TableCell>
                {getContracts()}
                <TableCell>{getRowTotals(1)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>SQM</TableCell>
                {getMeters()}
                <TableCell>{getRowTotals(2) + " m²"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Revenue</TableCell>
                {getRevenue()}
                <TableCell>{getRowTotals(3) + " €"}</TableCell>
              </TableRow>
              {leads.length > 0 && (
                <TableRow>
                  <TableCell>Conversion</TableCell>
                  {getConverisons()}
                  <TableCell>{getRowTotals(4) + " %"}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

function getMonthLeads(month, leads) {
  return leads.filter(
    (lead) =>
      lead.createdAt.slice(0, lead.createdAt.indexOf("T")) >= month.startDate &&
      lead.createdAt.slice(0, lead.createdAt.indexOf("T")) <= month.endDate
  );
}

function getMonthNewContracts(month, contracts) {
  const firstDayOfMonth = new Date(month.startDate);
  const lastDayOfMonth = new Date(month.endDate);

  const newContracts = contracts.filter((contract) => {
    const contractStartDate = new Date(contract.createdAt);
    return (
      contractStartDate >= firstDayOfMonth &&
      contractStartDate <= lastDayOfMonth
    );
  });

  return newContracts;
}

function getMonthEndingContracts(month, contracts) {
  const firstDayOfMonth = new Date(month.startDate);
  const lastDayOfMonth = new Date(month.endDate);

  lastDayOfMonth.setHours(23, 59, 59, 999);
  const endingContracts = contracts.filter((contract) => {
    const contractEndDate = contract.endDate
      ? new Date(contract.endDate)
      : null;
    return (
      contractEndDate >= firstDayOfMonth && contractEndDate <= lastDayOfMonth
    );
  });

  return endingContracts;
}

function getMonthTotalAmount(contracts) {
  return contracts.reduce((total, contract) => total + contract.price, 0);
}

function getMonthCurrentContracts(month, contracts) {
  const firstDayOfMonth = new Date(month.startDate);
  const lastDayOfMonth = new Date(month.endDate);

  // Ajustar la fecha al último milisegundo del día para incluir contratos que terminan el mismo día
  lastDayOfMonth.setHours(23, 59, 59, 999);

  const currentContracts = contracts.filter((contract) => {
    const contractStartDate = new Date(contract.createdAt);
    const contractEndDate = contract.endDate
      ? new Date(contract.endDate)
      : null;

    // Verificar si el contrato está vigente en el mes dado
    return (
      contractStartDate <= lastDayOfMonth &&
      (!contractEndDate || contractEndDate >= firstDayOfMonth)
    );
  });

  return currentContracts;
}

function lastCenterMonths(months, contracts) {
  if (!contracts || !contracts.length) return months;
  const minDate = new Date(
    Math.min(...contracts.map((c) => new Date(c.startDate)))
  );
  const firstDate = `${String(minDate.getFullYear())}-${String(
    minDate.getMonth() + 1
  ).padStart(2, "0")}-${String(minDate.getDate()).padStart(2, "0")}`;

  return months.filter((m) => m.endDate > firstDate);
}

function groupMonthsByYear() {
  const yearGroups = {};

  last12Months.forEach((month) => {
    const year = new Date(month.startDate).getFullYear();
    if (!yearGroups[year]) {
      yearGroups[year] = {
        year: year,
        numberOfMonths: 0,
      };
    }
    yearGroups[year].numberOfMonths += 1;
  });

  return Object.values(yearGroups).sort((a, b) => a.year - b.year);
}

function getLast12Months() {
  const today = new Date();
  const lastMonths = [];

  for (let i = 0; i < 12; i++) {
    const month = today.getMonth() - i;
    const year = today.getFullYear();

    const startDate = new Date(year, month, 1);
    const endDate =
      i === 0
        ? today
        : new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

    lastMonths.push({
      name: months[startDate.getMonth()],
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
    });
  }

  return lastMonths.reverse();
}

export default HubspotLeadsChart;
