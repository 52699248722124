import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { API_ROUTE } from "../utils/API";
import axios from "axios";

import {
  Grid,
  Paper,
  Typography,
  InputAdornment,
  IconButton,
  Button,
  Collapse,
  Alert,
} from "@mui/material";

import TextInput from "./Inputs/TextInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const usePasswordInput = () => {
  const [password, setPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  return {
    password,
    showPassword,
    setPass,
    setShowPassword,
  };
};

export default function NewPasswordForm({code, email}) {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("login");
  const password = usePasswordInput();
  const repeatPassword = usePasswordInput();
  const [goToLogin, setGoToLogin] = useState(false);

  const handlePasswordInputs = () => {
    return password.password &&
      repeatPassword.password &&
      password.password !== repeatPassword.password
      ? true
      : false;
  };

  const isValidInput = () => {
    if (
      !password.password ||
      !repeatPassword.password ||
      handlePasswordInputs()
    )
      return false;
    if (password.password.length < 8) return false;
    return true;
  };

  const sendNewPassword = () => {
    axios
      .post(API_ROUTE + "/auth/forgot-password/reset-password", {
        password: password.password,
        code: code,
        email: email
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("passwordSuccessfullyChanged"), {
            variant: "success",
          });
          setGoToLogin(true);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  return (
    <Paper sx={{ padding: 3, maxWidth: 500 }} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">{t("setNewPassword")}</Typography>
      </Grid>
      <Grid container spacing={2} direction="column" marginTop={2}>
        <Grid item xs={12}>
          <Collapse in={handlePasswordInputs()}>
            <Alert severity="warning">{t("newPasswordAlert")}</Alert>
          </Collapse>
        </Grid>
        <Grid item>
          <Typography>{t("newPasswordMessage")}</Typography>
        </Grid>
        <Grid item xs={12} marginTop={1}>
          <TextInput
            label={t("password")}
            type={password.showPassword ? "text" : "password"}
            value={password.password}
            onChange={(e) => {
              password.setPass(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      password.setShowPassword(!password.showPassword);
                    }}
                    edge="end"
                  >
                    {password.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} marginTop={1}>
          <TextInput
            label={t("repeatPassword")}
            type={repeatPassword.showPassword ? "text" : "password"}
            value={repeatPassword.password}
            onChange={(e) => {
              repeatPassword.setPass(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      repeatPassword.setShowPassword(
                        !repeatPassword.showPassword
                      );
                    }}
                    edge="end"
                  >
                    {repeatPassword.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item container xs={12} marginTop={2}>
          <Grid item xs={6}>
            <Typography variant="caption" display="block">
              <a href="/login">{t("backToLogin")}</a>
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" xs={6}>
            <Button
              variant="contained"
              color="primary"
              disabled={!isValidInput()}
              onClick={sendNewPassword}
            >
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {goToLogin && <Redirect to={"/login"} />}
    </Paper>
  );
}
