import axios from "axios";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { API_ROUTE } from "../../utils/API";
import TextInput from "./TextInput";

const CountryInput = (props) => {
  const { label, name, onChange, value, helperText, required } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [countries, setCountries] = useState([]);
  const [t] = useTranslation("country");

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = () => {
    axios
      .get(API_ROUTE + "/countries")
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error.toString(), {
            variant: "error",
          });
        else setCountries(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  const parsedCountries = countries
    .map((elem) => {
      return {
        ...elem,
        name: t(elem.name),
      };
    })
    .sort((a, b) => a.name > b.name);

  return (
    <Autocomplete
      name={name}
      options={parsedCountries}
      getOptionLabel={(country) => t(country.name) || ""}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextInput
          error={helperText}
          helperText={
            helperText ? t("country") + " " + t("mustNotBeBlank") : ""
          }
          {...params}
          size="small"
          label={label}
          required={required}
        />
      )}
      value={value}
      onChange={(event, newValue) => {
        onChange({ target: { name: name, value: newValue } });
      }}
      fullWidth
    />
  );
};

export default CountryInput;
