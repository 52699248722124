import { enqueueSnackbar } from "notistack";
import { SPAIN_POSTAL_CODE_LENGTH } from "../../data/constants";
import TextInput from "./TextInput";
import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { API_ROUTE } from "../../utils/API";
import axios from "axios";

const reducer = (state, action) => {
  switch (action.type) {
    case "IS_VALID":
      return { ...state, isValid: action.payload.isValid };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

const initialState = {
  isValid: true,
};

export default function PostalCodeInput(props) {
  const { value = "", onChange, label, disabled = false } = props;

  const [t] = useTranslation("others");

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleInputChange = (e) => {
    onChange(e);
    if (e.target.value && e.target.value.length === SPAIN_POSTAL_CODE_LENGTH)
      checkCode(e.target.value);
    else if (e.target.value?.length > SPAIN_POSTAL_CODE_LENGTH) {
      dispatch({
        type: "IS_VALID",
        payload: {
          isValid: false,
        },
      });
    }
  };

  const checkCode = (postalCodeNumber) => {
    axios
      .get(API_ROUTE + "/postal-codes/is-valid/" + postalCodeNumber)
      .then((response) => {
        dispatch({
          type: "IS_VALID",
          payload: {
            isValid: response.data.isValid,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  return (
    <TextInput
      {...props}
      error={value.length >= SPAIN_POSTAL_CODE_LENGTH && !state.isValid}
      label={label}
      helperText={
        value.length >= SPAIN_POSTAL_CODE_LENGTH &&
        !state.isValid &&
        t("invalidPostalCode")
      }
      value={value}
      onChange={handleInputChange}
      name="postalCode"
      type="number"
      disabled={disabled}
    />
  );
}
