import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import TextInput from "../../../Inputs/TextInput";
import Button from "../../../Inputs/CustomButton";
import { useState } from "react";

const CreateTextForm = ({ onCreateTile, closeAddTileDialog, layerTiles }) => {
  const [t] = useTranslation("floorPlans");

  const [name, setName] = useState("Tile " + (layerTiles.length + 1));
  const [x, setX] = useState("100");
  const [y, setY] = useState("100");
  const [text, setText] = useState("");
  const [fontSize, setFontSize] = useState("42");

  const validateForm = () => {
    if (!name || !x || !y || !text || !fontSize) {
      return false;
    }
    return true;
  };

  const handleCreate = () => {
    let tile = {
      name,
      shape: "Text",
      x,
      y,
      text,
      fontSize,
    };
    onCreateTile(tile);
  };

  const inputs = [
    {
      label: "name",
      value: name,
      setter: setName,
      type: "text",
    },
    {
      label: "x",
      value: x,
      setter: setX,
      type: "number",
    },
    {
      label: "fontSize",
      value: fontSize,
      setter: setFontSize,
      type: "number",
    },
    {
      label: "y",
      value: y,
      setter: setY,
      type: "number",
    },
    {
      label: "text",
      value: text,
      setter: setText,
      type: "text",
    },
  ];

  return (
    <Box width="100%">
      <Grid container spacing={2}>
        {inputs.map((input) => (
          <>
            <Grid container item xs={5} key={input.label}>
              <Grid container item alignItems="center" spacing={2}>
                <Grid item xs={2}>
                  <Typography align="left">{t(input.label) + ":"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label={t(input.label)}
                    type={input.type}
                    value={input.value}
                    onChange={(e) => input.setter(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {input.label === "name" ? (
              <Grid item container xs={6} marginBottom={2}></Grid>
            ) : (
              <></>
            )}
          </>
        ))}
        <Grid container item xs={12} justifyContent="end" spacing={1}>
          <Grid item>
            <Button onClick={closeAddTileDialog} variant="text">
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="success"
              disabled={!validateForm()}
              onClick={handleCreate}
            >
              {t("add")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateTextForm;
