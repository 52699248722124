import {
  Button,
  ButtonGroup,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import EmailIcon from "@mui/icons-material/Email";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import GetAppIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FolderZipOutlined } from "@mui/icons-material";

import AppContext from "../../../context/AppContext";
import ButtonCSV from "../../Inputs/ButtonCSV";
import ButtonLink from "../../Inputs/ButtonLink";
import CustomButton from "../../Inputs/CustomButton";
import CustomSelect from "../../Inputs/CustomSelect";
import CreateButton from "../../Inputs/CreateButton";
import Filters from "../../global/structure/Filters";
import SearchButton from "../../Inputs/SearchButton";
import Page from "../../global/structure/Page";
import TextInput from "../../Inputs/TextInput";
import InvoiceStateChip from "../../InvoiceStateChip";
import ItemsSummary from "../../ItemsSummary";
import { CustomTable } from "../../CustomTable";
import { getParams, generateURL } from "../../../utils/url";
import { localeFormat } from "../../../utils/format";
import {
  firstDateCurrentMonth,
  lastDateCurrentMonth,
} from "../../../utils/date";

import {
  CUSTOMER_ROLE_ID,
  C_INVOICE_SERIES_ID,
  F_INVOICE_SERIES_ID,
  R_INVOICE_SERIES_ID,
} from "../../../data/constants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  invoices: [],
  filters: {
    accounted: "",
    amountFrom: "",
    amountTo: "",
    autoSearch: "false",
    billingError: "",
    customerId: "",
    dateFrom: firstDateCurrentMonth(),
    dateUntil: lastDateCurrentMonth(),
    emailSent: "",
    isKeyAccount: "",
    number: "",
    order: "asc",
    orderBy: "",
    paymentMethodId: [],
    state: [],
    types: [],
  },
  options: {
    rowlink: "invoice",
    loaded: true,
  },
  paymentMethods: [],
  series: [],
  customers: [],
  customer: "",
  loadingAll: false,
  zipDownloadLoading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_SERIES":
      return { ...state, series: action.payload.series };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_CUSTOMER_FILTER":
      return { ...state, customer: action.payload };
    case "RESET_FILTERS":
      return {
        ...state,
        filters: initialState.filters,
        customer: initialState.customer,
      };
    case "SET_LOADING":
      const index = state.invoices.findIndex(
        (post) => post.id === action.payload.id
      );

      return {
        ...state,
        invoices: [
          ...state.invoices.slice(0, index),
          {
            ...state.invoices[index],
            [action.payload.type]: action.payload.state,
          },
          ...state.invoices.slice(index + 1),
        ],
        loadingAll: !state.loadingAll,
      };
    case "SET_ZIP_DOWNLOAD_LOADING":
      return { ...state, zipDownloadLoading: action.payload };
    default:
      throw new Error();
  }
}

export default function InvoicesPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("invoices");

  const INVOICE_STATE = [t("issued"), t("paid"), t("expired"), t("unpaid")];

  const FILTERS = [
    { name: "accounted", type: "number" },
    { name: "amountFrom", type: "number" },
    { name: "amountTo", type: "number" },
    { name: "autoSearch", type: "string" },
    { name: "customerId", type: "number" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "emailSent", type: "number" },
    { name: "isKeyAccount", type: "boolean" },
    { name: "number", type: "string" },
    { name: "order", type: "string" },
    { name: "orderBy", type: "string" },
    { name: "paymentMethodId", type: "array" },
    { name: "state", type: "array" },
    { name: "types", type: "array" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const INVOICES_COLUMNS = [
    {
      key: "InvoiceSerie.name",
      label: t("serie"),
      sortType: "string",
    },
    {
      key: "number",
      label: t("number"),
      sortType: "number",
    },
    {
      key: "issueDate",
      label: t("issueDate"),
      sortType: "string",
    },
    { key: "dueDate", label: t("dueDate"), sortType: "string" },
    {
      key: "customerName",
      label: t("customerName"),
      sortType: "string",
    },
    {
      key: "totalAmount",
      label: t("import"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "PaymentMethod.name",
      label: t("paymentMethod"),
      sortType: "string",
    },
    {
      key: "state",
      label: t("state"),
      sortType: "number",
      renderFunction: (value) => <InvoiceStateChip state={value} />,
    },
    {
      key: "actions",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) => (
        <ButtonGroup>
          {item?.InvoiceSerie?.id !== C_INVOICE_SERIES_ID && (
            <Tooltip title={t("seePdf")}>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  seePdf(item.id);
                }}
                disabled={state.loadingAll}
              >
                {item.seePdf ? (
                  <Fade in={item.seePdf}>
                    <CircularProgress size="1.5rem" />
                  </Fade>
                ) : (
                  <VisibilityIcon />
                )}
              </IconButton>
            </Tooltip>
          )}
          {item?.InvoiceSerie?.id !== C_INVOICE_SERIES_ID && (
            <Tooltip title={t("downloadPdf")}>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  downloadPdf(item);
                }}
                disabled={state.loadingAll}
              >
                {item.downloadPdf ? (
                  <Fade in={item.downloadPdf}>
                    <CircularProgress size="1.5rem" />
                  </Fade>
                ) : (
                  <GetAppIcon />
                )}
              </IconButton>
            </Tooltip>
          )}
          {user.Role.id !== CUSTOMER_ROLE_ID &&
            item?.InvoiceSerie?.id !== C_INVOICE_SERIES_ID && (
              <Tooltip title={t("emailPdf")}>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    emailPdf(item.id);
                  }}
                  disabled={state.loadingAll}
                  color={item.emailSent ? "success" : "primary"}
                >
                  {item.emailPdf ? (
                    <Fade in={item.loading?.emailPdf}>
                      <CircularProgress size="1.5rem" />
                    </Fade>
                  ) : item.emailSent ? (
                    <MarkEmailReadIcon />
                  ) : (
                    <EmailIcon />
                  )}
                </IconButton>
              </Tooltip>
            )}
        </ButtonGroup>
      ),
    },
  ];

  const CSV_HEADERS = [
    { key: "InvoiceSerie.name", label: t("serie") },
    { key: "number", label: t("number") },
    { key: "issueDate", label: t("issueDate") },
    { key: "dueDate", label: t("dueDate") },
    { key: "customerName", label: t("customerName") },
    { key: "totalAmount", label: t("import") },
    { key: "PaymentMethod.name", label: t("paymentMethod") },
    { key: "stateName", label: t("state") },
  ];

  const ITEMS_SUMMARY = [
    {
      translatedText: t("invoiceNumber"),
      value: state.invoices?.length,
    },
    {
      translatedText: t("totalBase"),
      value:
        localeFormat(
          state.invoices?.reduce(
            (value, element) => value + element.baseAmount,
            0
          )
        ) + "€",
    },
    {
      translatedText: t("total"),
      value:
        localeFormat(
          state.invoices?.reduce(
            (value, element) => value + element.totalAmount,
            0
          )
        ) + "€",
    },
  ];

  //Initial useEffect
  useEffect(() => {
    setFiltersForUser();
    getInvoiceSeries();
    getCustomers();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === "true") getInvoices();
  }, [state.filters.autoSearch]);

  const setFiltersForUser = () => {
    if (user.Role?.id && user.Role?.id === CUSTOMER_ROLE_ID) {
      dispatch({
        type: "SET_FILTER",
        payload: {
          inputname: "dateFrom",
          value: "",
        },
      });
      dispatch({
        type: "SET_FILTER",
        payload: {
          inputname: "dateUntil",
          value: "",
        },
      });
    }
  };

  const getInvoices = () => {
    //Change url parameters:
    const url = generateURL("/app/invoices", state.filters);
    history.push(url);

    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      attributes: [
        {
          model: "Invoice",
          attributes: [
            "actions",
            "baseAmount",
            "billingError",
            "customerAddress",
            "customerName",
            "dueDate",
            "emailSent",
            "id",
            "issueDate",
            "items",
            "number",
            "paycardId",
            "paymentMethodId",
            "serieId",
            "state",
            "stateName",
            "totalAmount",
          ],
        },
        {
          model: "PaymentMethod",
          attributes: ["name"],
        },
        {
          model: "Customer",
          attributes: ["isKeyAccount"],
        },
      ],
      include: ["PaymentMethod", "Customer"],
    };

    state.filters.accounted !== "" &&
      (params.accounted = state.filters.accounted);
    state.filters.amountFrom !== "" && (params.min = state.filters.amountFrom);
    state.filters.amountTo !== "" && (params.max = state.filters.amountTo);
    state.filters.types.length > 0 && (params.types = state.filters.types);
    state.filters.dateFrom !== "" && (params.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil !== "" &&
      (params.dateUntil = state.filters.dateUntil);
    state.filters.number !== "" && (params.number = state.filters.number);
    state.filters.customerId !== "" &&
      (params.customerId = state.filters.customerId);
    state.filters.state !== "" && (params.state = state.filters.state);
    state.filters.paymentMethodId !== "" &&
      (params.paymentMethodId = state.filters.paymentMethodId);
    state.filters.emailSent !== "" &&
      (params.emailSent = state.filters.emailSent);
    state.filters.isKeyAccount !== "" &&
      (params.isKeyAccount = state.filters.isKeyAccount);
    state.filters.billingError !== "" &&
      (params.billingError = state.filters.billingError);

    api
      .get("/invoices", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noInvoices"), { variant: "warning" });
          dispatch({ type: "SET_INVOICES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  const getInvoiceSeries = () => {
    api
      .get("/invoice-series")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_SERIES",
            payload: {
              series: response.data,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const search = () => {
    if (state.filters.autoSearch === "true") getInvoices();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  const getCustomers = () => {
    api
      .get("/customers")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (state.filters.customerId) {
            const customer = response.data.find(
              (customer) => customer.id === Number(state.filters.customerId)
            );
            dispatch({ type: "SET_CUSTOMER_FILTER", payload: customer });
          }

          dispatch({
            type: "SET_CUSTOMERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const seePdf = (id) => {
    dispatch({
      type: "SET_LOADING",
      payload: { type: "seePdf", id: id, state: true },
    });
    api
      .get("/invoices/" + id + "/pdf", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: "application/pdf",
          });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: { type: "seePdf", id: id, state: false },
        });
      });
  };

  const downloadPdf = (invoice) => {
    dispatch({
      type: "SET_LOADING",
      payload: { type: "downloadPdf", id: invoice.id, state: true },
    });
    api
      .get("/invoices/" + invoice.id + "/pdf", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          downloadFile(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: { type: "downloadPdf", id: invoice.id, state: false },
        });
      });
  };

  const getInvoicesZip = () => {
    const invoicesIds = state.invoices.map((invoice) => invoice.id);
    if (state.invoices.length === 0) {
      enqueueSnackbar(t("noInvoices"), { variant: "warning" });
    } else if (invoicesIds.length > 20) {
      enqueueSnackbar(t("maxInvoicesZip20"), { variant: "warning" });
    } else if (
      state.invoices.every(
        (invoice) =>
          ![F_INVOICE_SERIES_ID, R_INVOICE_SERIES_ID].includes(invoice.serieId)
      )
    ) {
      enqueueSnackbar(t("mustContainSeriesFOrR"), { variant: "warning" });
    } else {
      dispatch({ type: "SET_ZIP_DOWNLOAD_LOADING", payload: true });

      api
        .get("/invoices/pdf-zip", {
          params: {
            invoicesIds,
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          if (response.data.byteLength < 1024) {
            // Transform array buffer to string
            const errorResponse = String.fromCharCode.apply(
              null,
              new Uint8Array(response.data)
            );
            const errorResponseJSON = JSON.parse(errorResponse);
            console.log(errorResponseJSON.error);
            enqueueSnackbar(errorResponseJSON.error, { variant: "error" });
          } else {
            downloadFile(response.data, "zip");
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => {
          dispatch({ type: "SET_ZIP_DOWNLOAD_LOADING", payload: false });
        });
    }
  };

  const emailPdf = (id) => {
    dispatch({
      type: "SET_LOADING",
      payload: { type: "emailPdf", id: id, state: true },
    });
    api
      .get("/invoices/" + id + "/email")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("emailSentSuccessfully"), { variant: "success" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          payload: { type: "emailPdf", id: id, state: false },
        });
      });
  };

  const downloadFile = (invoiceFile, fileType = "pdf") => {
    // Create a Blob from the file
    const file = new Blob([invoiceFile], {
      type: "application/" + fileType,
    });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = fileURL.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const filterOptions = createFilterOptions({
    limit: 10, //limit of options to be displayed
  });

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_INVOICES", payload: [] });
  };

  return (
    <Page browserTitle={t("invoicesPage")} title={t("invoices")}>
      <Grid container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                label={
                  user.Role.id !== CUSTOMER_ROLE_ID
                    ? t("number/name")
                    : t("invoiceNumber")
                }
                value={state.filters.number}
                name="number"
                onChange={handleFilterChange}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
              />,
              <TextInput
                type="number"
                sx={{ width: "105px" }}
                label={t("amountFrom")}
                name="amountFrom"
                value={state.filters.amountFrom}
                onChange={handleFilterChange}
              />,
              <TextInput
                type="number"
                sx={{ width: "105px" }}
                label={t("amountTo")}
                name="amountTo"
                value={state.filters.amountTo}
                onChange={handleFilterChange}
              />,
              <CustomSelect
                multiple
                name="types"
                label={t("serie")}
                options={state.series.map((serie) => ({
                  value: serie.id,
                  label: serie.name,
                }))}
                value={state.filters.types}
                onChange={handleFilterChange}
              />,
              <TextInput
                label={t("dateFrom")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.filters.dateFrom}
                onChange={handleFilterChange}
                name="dateFrom"
              />,
              <TextInput
                label={t("dateUntil")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.filters.dateUntil}
                onChange={handleFilterChange}
                name="dateUntil"
              />,
              user.Role?.id && user.Role?.id !== CUSTOMER_ROLE_ID && (
                <Autocomplete
                  size="large"
                  sx={{ minWidth: "200px" }}
                  options={state.customers}
                  getOptionLabel={(customer) => customer.fullName || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.nif === value.nif || null
                  }
                  filterOptions={filterOptions}
                  value={state.customer || null}
                  onChange={(e, customer) => {
                    handleFilterChange({
                      target: {
                        value: customer ? customer.id : "",
                        name: "customerId",
                      },
                    });
                    dispatch({
                      type: "SET_CUSTOMER_FILTER",
                      payload: customer,
                    });
                  }}
                  name="customer"
                  renderInput={(params) => (
                    <TextInput {...params} size="small" label={t("customer")} />
                  )}
                />
              ),
              <CustomSelect
                options={[
                  { value: "", label: t("all") },
                  { value: true, label: t("yes") },
                  { value: false, label: t("no") },
                ]}
                label={t("keyAccount")}
                name="isKeyAccount"
                onChange={handleFilterChange}
                value={state.filters.isKeyAccount}
              />,
              <CustomSelect
                label={t("accounted")}
                value={state.filters.accounted}
                onChange={handleFilterChange}
                options={[
                  { value: "", label: t("all") },
                  { value: 0, label: t("accounted") },
                  { value: 1, label: t("notAccounted") },
                ]}
                name="accounted"
              />,
              <CustomSelect
                label={t("state")}
                value={state.filters.state}
                onChange={handleFilterChange}
                options={[
                  { value: 0, label: t("issued") },
                  { value: 1, label: t("paid") },
                  { value: 2, label: t("expired") },
                  { value: 3, label: t("unpaid") },
                  { value: 4, label: t("incorrectIssuance") },
                ]}
                name="state"
                multiple
              />,
              <CustomSelect
                name="paymentMethodId"
                label={t("paymentMethod")}
                value={state.filters.paymentMethodId}
                onChange={handleFilterChange}
                options={state.paymentMethods.map((method) => ({
                  label: t(method.name),
                  value: method.id,
                }))}
                multiple
              />,
              <CustomSelect
                name="emailSent"
                label={t("sent")}
                value={state.filters.emailSent}
                onChange={handleFilterChange}
                options={[
                  { label: t("all"), value: "" },
                  { label: t("sent"), value: 0 },
                  { label: t("notSent"), value: 1 },
                ]}
              />,
              <CustomSelect
                name="billingError"
                label={t("billingError")}
                value={state.filters.billingError}
                onChange={handleFilterChange}
                options={[
                  { label: t("all"), value: "" },
                  { label: t("error"), value: 1 },
                  { label: t("noError"), value: 0 },
                ]}
              />,
            ]}
          />
        </Grid>

        <Grid item>
          <ButtonGroup variant="contained">
            <Button onClick={resetFilters}>{t("reset")}</Button>
            <ButtonCSV
              data={state.invoices.map((e) => {
                if (e.state !== undefined && e.state !== null) {
                  e.stateName = INVOICE_STATE[e.state];
                } else e.stateName = "";
                return e;
              })}
              headers={CSV_HEADERS}
              filename={t("invoices")}
            />
            <CustomButton
              loading={state.zipDownloadLoading}
              icon={<FolderZipOutlined />}
              onClick={getInvoicesZip}
            >
              ZIP
            </CustomButton>
            <SearchButton onClick={search} loading={!state.options.loaded} />
          </ButtonGroup>
        </Grid>

        <Grid item>
          <ButtonLink to="/app/pay-cards" endIcon={<CreditCardIcon />}>
            {t("paycardCharges")}
          </ButtonLink>
        </Grid>

        <Grid item>
          <CreateButton
            action={"CREATE_INVOICES"}
            label={t("createInvoice")}
            link={"/invoice/create"}
          />
        </Grid>

        <Grid item xs={12}>
          <ItemsSummary gridItems={ITEMS_SUMMARY} />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            columns={INVOICES_COLUMNS}
            data={state.invoices.map((invoice) => {
              invoice.actions = "";
              return invoice;
            })}
            options={state.options}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/invoices", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>
    </Page>
  );
}
