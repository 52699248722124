import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

import axios from "axios";
import React from "react";

import { Stage, Layer, Line } from "react-konva";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import CustomButton from "../../Inputs/CustomButton";

import { API_ROUTE } from "../../../utils/API";
import { openFile } from "../../../utils/file";

const initialState = {
  loadingPdf: false,
  acceptedTerms: false,
};

export default function ContractSignature({
  onSave,
  loading,
  contractId,
  token,
}) {
  const [t] = useTranslation("contracts");
  const [tool, setTool] = React.useState("pen");
  const [lines, setLines] = React.useState([]);
  const [state, setState] = React.useState(initialState);
  const isDrawing = React.useRef(false);
  const stageRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
    e.evt.preventDefault();
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const handleTermsAcceptance = (event) => {
    setState({ ...state, acceptedTerms: event.target.checked });
  };

  const seeContractPdf = () => {
    if (!contractId) return;
    setState({ ...state, loadingPdf: true });
    const params = { token: token };
    axios
      .get(API_ROUTE + "/sign-contract/contract/" + contractId, {
        params,
        responseType: "blob",
      })
      .then((response) => {
        console.error(response.data);
        if (response.status === 200) {
          openFile(response.data, "pdf");
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        console.log(error);
      })
      .finally(() => {
        setState({ ...state, loadingPdf: false });
      });
  };

  return (
    <>
      <Grid container spacing={1} justifyContent="center" marginTop={1}>
        <Grid
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="left"
          spacing={2}
        >
          <Checkbox onChange={handleTermsAcceptance} />
          <Typography
            onClick={seeContractPdf}
            style={{
              cursor: "pointer",
              color: state.loadingPdf ? "purple" : "blue",
              textDecoration: "underline",
              marginRight: "8px",
            }}
          >
            {t("acceptTerms")}
          </Typography>
          <Grid item>
            {state.loadingPdf && <CircularProgress size={"1rem"} />}
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center" marginTop={2}>
          <Box
            sx={{
              border: "solid black 1px",
              borderRadius: "10px",
              width: 240,
              height: 240,
            }}
          >
            <Stage
              onTouchStart={handleMouseDown}
              onTouchMove={handleMouseMove}
              onTouchEnd={handleMouseUp}
              onMouseDown={handleMouseDown}
              onMousemove={handleMouseMove}
              onMouseup={handleMouseUp}
              width={240}
              height={240}
              ref={stageRef}
            >
              <Layer>
                {lines.map((line, i) => (
                  <Line
                    key={i}
                    points={line.points}
                    stroke="#000000"
                    strokeWidth={3}
                    tension={0.5}
                    lineCap="round"
                    globalCompositeOperation={
                      line.tool === "eraser" ? "destination-out" : "source-over"
                    }
                  />
                ))}
              </Layer>
            </Stage>
          </Box>
        </Grid>

        <Grid item>
          <CustomButton
            onClick={(e) => {
              const uri = stageRef.current.toDataURL();
              onSave(uri);
            }}
            loading={loading}
            disabled={lines.length === 0 || !state.acceptedTerms}
          >
            {t("sign")}
          </CustomButton>
        </Grid>
      </Grid>
    </>
  );
}
