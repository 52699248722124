/**
 * Rounds a number to the specified number of decimals.
 * @param {number} value - The number to be rounded.
 * @param {number} decimals - The number of decimal places to round to (default is 2).
 * @returns {number} - The rounded number.
 */
export const round = (value, decimals = 2) => {
  return (
    Math.round(value * Math.pow(10, decimals) + Number.EPSILON) /
    Math.pow(10, decimals)
  );
};
