import { Box } from "@mui/material";
import { useEffect, useReducer, useContext } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../../context/AppContext";
import CenterReportContext from "./CenterReportContext";

import CenterSelect from "../../../Inputs/CenterSelect";
import Page from "../../../global/structure/Page";
import Tabs from "../../../global/structure/Tabs";

import PerformanceTab from "./CenterReportTabs/PerformanceTab";
import OccupancyTab from "./CenterReportTabs/OccupancyTab";
import CustomerTab from "./CenterReportTabs/CustomerTab";
import MarketingTab from "./CenterReportTabs/MarketingTab";
import ExpensesTab from "./CenterReportTabs/ExpensesTab";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CENTER":
      return { ...state, center: action.payload };
    case "SET_CONTRACTS":
      return { ...state, contracts: action.payload };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_PROVIDER_INVOICES":
      return { ...state, providerInvoices: action.payload };
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_ACQUISITION_ASSET":
      return { ...state, acquisitionAsset: action.payload };
    case "SET_BOX_CLUSTERS":
      return { ...state, boxClusters: action.payload };
    case "SET_INVOICE_ITEMS":
      return { ...state, invoiceItems: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    default:
      throw new Error(
        "Error using reducer, action type not found:",
        action.type
      );
  }
};

const initialState = {
  acquisitionAsset: null,
  boxClusters: [],
  boxes: [],
  center: { Boxes: [] },
  contracts: [],
  customers: [],
  filters: { center: "" },
  invoiceItems: [],
  providerInvoices: [],
};

export const CenterReportPanel = () => {
  const { api, user } = useContext(AppContext);
  const [t] = useTranslation("dashboard");
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.filters.center) {
      getCenter();
      getContracts();
      getBoxes();
      getBoxClusters();
      getInvoiceItems();
      getCustomers();
      getProviderInvoices();
    }
  }, [state.filters.center]);

  useEffect(() => {
    getAcquisitionAsset();
  }, [state.center]);

  const getCenter = () => {
    if (state.filters.center === "") return;
    let params = {
      /*include: [
          "Box",
          "Contract",
          "AcquisitionAsset",
          "AcquisitionAssetCosts",
          "BoxCluster",
        ],*/
    };

    api
      .get("/centers/" + state.filters.center, { params })
      .then((response) => {
        dispatch({ type: "SET_CENTER", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar("Centers: " + error.toString(), { variant: "error" })
      );
  };

  const getBoxes = () => {
    if (state.filters.center === "") return;
    let params = {
      include: ["Contract"],
      centers: [state.filters.center],
    };

    api
      .get("/boxes", { params })
      .then((response) => {
        dispatch({ type: "SET_BOXES", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar("Boxes: " + error.toString(), { variant: "error" })
      );
  };

  const getCustomers = () => {
    if (state.filters.center === "") return;
    api
      .get("/customers", {
        params: {
          centerId: state.filters.center,
          hasActiveContract: true,
          include: ["Contract", "Box"],
        },
      })
      .then((response) => {
        dispatch({ type: "SET_CUSTOMERS", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar("Customers: " + error.toString(), {
          variant: "error",
        })
      );
  };

  const getBoxClusters = () => {
    if (state.filters.center === "") return;

    api
      .get("/centers/" + state.filters.center + "/box-clusters")
      .then((response) => {
        dispatch({ type: "SET_BOX_CLUSTERS", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar("Box clusters: " + error.toString(), {
          variant: "error",
        })
      );
  };

  const getContracts = () => {
    if (state.filters.center === "") return;

    let params = {
      centerId: [state.filters.center],
      include: ["Box"],
    };

    api
      .get("/contracts", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else dispatch({ type: "SET_CONTRACTS", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar("Error getting contracts: " + error.toString(), {
          variant: "error",
        })
      );
  };

  const getAcquisitionAsset = () => {
    if (!state.center.acquisitionAssetId) return;

    api
      .get("/acquisitions/" + state.center.acquisitionAssetId)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else
          dispatch({ type: "SET_ACQUISITION_ASSET", payload: response.data });
      })
      .catch((error) =>
        enqueueSnackbar("Acquisition asset: " + error.toString(), {
          variant: "error",
        })
      );
  };

  const getInvoiceItems = () => {
    api
      .get("/invoice-items/" + state.filters.center)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_INVOICE_ITEMS", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar("Invoice items: " + error.toString(), {
          variant: "error",
        });
      });
  };

  const getProviderInvoices = () => {
    const params = {
      include: ["Center", "Provider", "ExpenseType"],
      centerId: [state.filters.center],
    };
    user.hasAction("VIEW_PROVIDER_INVOICES") &&
      api
        .get("/provider-invoices", { params })
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            response.data = response.data.map((invoice) => {
              invoice.CenterProviderInvoices =
                invoice.CenterProviderInvoices.filter(
                  (center) => center.centerId == state.filters.center
                );
              invoice.centerAmount = invoice.CenterProviderInvoices.reduce(
                (sum, center) => sum + center.amount,
                0
              );
              return invoice;
            });
            dispatch({
              type: "SET_PROVIDER_INVOICES",
              payload: response.data,
            });
          }
        })
        .catch((error) =>
          enqueueSnackbar(error.toString(), { variant: "error" })
        );
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  return (
    <CenterReportContext.Provider value={state}>
      <Page paper={true}>
        <Box marginTop={1}>
          <Box marginBottom={2}>
            <CenterSelect
              value={state.filters.center}
              onChange={handleChangeFilter}
              name="center"
            />
          </Box>
          <Tabs
            tabBottomMargin={1}
            tabs={[
              {
                label: t("performance"),
                content: <PerformanceTab />,
              },
              {
                label: t("occupancy"),
                content: <OccupancyTab />,
              },
              {
                label: t("customers"),
                content: <CustomerTab />,
              },
              {
                label: t("marketing"),
                content: <MarketingTab />,
              },
              {
                label: t("expenses"),
                content: <ExpensesTab />,
              },
            ]}
          />
        </Box>
      </Page>
    </CenterReportContext.Provider>
  );
};
