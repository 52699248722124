import {
  Avatar,
  Chip,
  Grid,
  List,
  ListItem,
  Popover,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";

import { SketchPicker } from "react-color";

import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";

import { useTranslation } from "react-i18next";
import TextInput from "../../../Inputs/TextInput";

function PlanEdit(props) {
  const [t] = useTranslation("floorPlans");
  const { data, editPlan } = props;
  const [tmpData, setTmpData] = useState({
    ...data,
    backgroundColor: data.backgroundColor ?? "#ffffff",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElColor, setAnchorElColor] = useState(null);

  useEffect(() => {
    setTmpData({ ...data, backgroundColor: data.backgroundColor ?? "#ffffff" });
  }, [data]);

  return (
    <>
      <IconButton
        color="primary"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <EditIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorPosition={{ top: 0, left: -100 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Grid container>
          <Grid item>
            <List>
              <ListItem>
                <TextInput
                  label={t("width")}
                  value={tmpData.width}
                  type="number"
                  onChange={(e) => {
                    setTmpData({ ...tmpData, width: e.target.value });
                  }}
                />
              </ListItem>
              <ListItem>
                <TextInput
                  label={t("height")}
                  value={tmpData.height}
                  type="number"
                  onChange={(e) => {
                    setTmpData({ ...tmpData, height: e.target.value });
                  }}
                />
              </ListItem>
              <ListItem>
                <Chip
                  icon={<EditIcon />}
                  label={t("backgroundColor")}
                  style={{
                    backgroundColor: tmpData.backgroundColor,
                  }}
                  onClick={(e) => {
                    setAnchorElColor(e.currentTarget);
                  }}
                />
                <Popover
                  open={Boolean(anchorElColor)}
                  anchorEl={anchorElColor}
                  onClose={() => {
                    setAnchorElColor(null);
                  }}
                  anchorPosition={{ top: 0, left: -100 }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <SketchPicker
                    color={tmpData.backgroundColor}
                    onChange={(color) => {
                      setTmpData({ ...tmpData, backgroundColor: color.hex });
                    }}
                  />
                </Popover>
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                editPlan(tmpData);
                setAnchorEl(null);
              }}
            >
              <DoneIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

export default PlanEdit;
