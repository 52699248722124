import {
  Button,
  ButtonGroup,
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useParams } from "react-router";
import { Bar } from "react-chartjs-2";

import AppContext from "../../../context/AppContext";
import { localeFormat } from "../../../utils/format";
import { formatDate } from "../../../utils/chartUtils";
import ButtonLink from "../../Inputs/ButtonLink";
import CustomSelect from "../../Inputs/CustomSelect";
import TextInput from "../../Inputs/TextInput";
import ItemsSummary from "../../ItemsSummary";
import { colors } from "../Dashboard/ColorGenerator";
import { ChartContainer } from "../Dashboard/charts/ChartContainer";

import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid } from "@mui/x-data-grid";
import CustomButton from "../../Inputs/CustomButton";
import ConfirmDialog from "../../ConfirmDialog";
import EditIcon from "@mui/icons-material/Edit";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          isOpen: action.payload.isOpen,
          childrenText: action.payload.childrenText,
          callback: action.payload.callback,
        },
      };
    case "SET_EXPENSE_TYPE":
      return { ...state, expenseType: action.payload };
    case "SET_EXPENSE_TYPES":
      return { ...state, expenseTypes: action.payload };
    case "SET_EXPENSE_TYPES_DIRECTORIES":
      return { ...state, expenseTypesDirectories: action.payload };
    case "SET_EXPENSE_TYPES_TREE":
      return { ...state, expenseTypesTree: action.payload };
    case "SET_INPUT":
      return {
        ...state,
        expenseType: {
          ...state.expenseType,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_LOADED_TRUE":
      return {
        ...state,
        options: {
          ...state.options,
          loaded: true,
        },
      };
    case "SET_LOADED_FALSE":
      return {
        ...state,
        options: {
          ...state.options,
          loaded: false,
        },
      };
    case "SET_PROVIDER_INVOICES":
      return { ...state, providerInvoices: action.payload };
    case "SET_SELECTED_EXPENSE_TYPE":
      return { ...state, selectedExpenseType: action.payload };
    case "SET_SELECTED_PROVIDER_INVOICES_IDS":
      return { ...state, selectedProviderInvoicesIds: action.payload };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "RESET_SELECTED_EXPENSE_TYPE":
      return {
        ...state,
        selectedExpenseType: initialState.selectedExpenseType,
      };
    default:
      throw new Error("Action not found in reducer");
  }
};

const initialState = {
  confirmDialog: {
    title: "",
    isOpen: false,
    childrenText: "",
    callback: () => {},
  },
  expenseType: {
    children: [],
    description: "",
    id: "",
    name: "",
    parentId: "",
    ProviderInvoices: [],
  },
  expenseTypes: [],
  expenseTypesDirectories: [],
  expenseTypesTree: [],
  inputError: {
    name: false,
  },
  options: {
    rowlink: "provider-invoice",
    loaded: false,
  },
  providerInvoices: [],
  selectedExpenseType: {
    id: "",
    name: "",
  },
  selectedProviderInvoicesIds: [],
};

const ExpenseTypePage = () => {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const [t] = useTranslation("expenseTypes");
  const [tErrors] = useTranslation("errors");

  const [state, dispatch] = useReducer(reducer, initialState);

  const EXPENSE_TYPE_COLUMNS = [
    {
      field: "expenseTypeName",
      headerName: t("expenseType"),
      flex: 1,
      renderCell: (params) =>
        id != params.row.expenseTypeId ? (
          <ButtonLink
            to={"/app/expense-type/" + params.row.expenseTypeId}
            onClick={(e) => e.stopPropagation()}
            disabled={id == params.row.expenseTypeId}
          >
            {params.value}
          </ButtonLink>
        ) : (
          params.value
        ),
    },
    {
      field: "date",
      headerName: t("date"),
      flex: 1,
    },
    {
      field: "title",
      headerName: t("title"),
      flex: 1,
    },
    {
      field: "total",
      headerName: t("total"),
      flex: 1,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "id",
      headerName: t("actions"),
      flex: 1,
      renderCell: (params, item) => (
        <ButtonGroup>
          <Tooltip title={t("viewInvoice")} placement="top">
            <IconButton>
              <EditIcon
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/app/provider-invoice/${params.id}`);
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("viewInvoicePdf")} placement="top">
            <IconButton disabled={setPdfButtonValue(params.id)}>
              <VisibilityIcon
                onClick={(e) => {
                  e.stopPropagation();
                  getFile(params.id);
                }}
              />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ),
    },
  ];

  const EXPENSE_TYPE_ITEMS_SUMMARY = [
    {
      translatedText: t("numberOfProviderInvoices"),
      value: state.providerInvoices?.length,
    },
    {
      translatedText: t("selectedProviderInvoices"),
      value: state.selectedProviderInvoicesIds.length,
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          state.providerInvoices.reduce(
            (total, current) => total + current.total,
            0
          )
        ) + "€",
    },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("expenseType");

    getExpenseType();
    getExpenseTypes();
    getExpenseTypesStructure();
  }, [id]);

  useEffect(() => {
    handleSetExpenseTypesDirectories();
    handleSetProviderInvoices();
  }, [state.expenseTypesTree]);

  useEffect(() => {
    state.selectedProviderInvoicesIds.length === 0 &&
      state.selectedExpenseType.id !== "" &&
      resetSelectedExpenseType();
  }, [state.selectedProviderInvoicesIds]);

  // Backend calls
  const deleteExpenseType = () => {
    api
      .delete("/expense-types/" + id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            `${t("expenseType")} ${response.data.name} ${t(
              "deletedSuccessfully"
            ).toLowerCase()}`,
            {
              variant: "success",
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const editExpenseType = (expenseType) => {
    let data = {};

    if (validateExpenseType(expenseType)) {
      expenseType.name !== "" && (data.name = expenseType.name);
      data.description = expenseType.description;
      expenseType.parentId !== "" && (data.parentId = expenseType.parentId);

      api
        .post("/expense-types/edit/" + id, data)
        .then((response) => {
          if (response.data.error)
            enqueueSnackbar(response.data.error, { variant: "error" });
          else {
            enqueueSnackbar(`${t("expenseType")} ${t("editedSuccessfully")}`, {
              variant: "success",
            });
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const editProviderInvoicesExpenseType = () => {
    api
      .post("/provider-invoices/edit-expense-type", {
        expenseTypeId: state.selectedExpenseType.id,
        providerInvoiceIds: state.selectedProviderInvoicesIds,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(
            `${response.data[0]} ${t("providerInvoices").toLowerCase()} ${t(
              "editedSuccessfullyFP"
            ).toLowerCase()}`,
            {
              variant: "success",
            }
          );
          getExpenseType();
          getExpenseTypesStructure();
          resetSelectedExpenseType();
          dispatch({ type: "SET_SELECTED_PROVIDER_INVOICES_IDS", payload: [] });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getExpenseType = () => {
    let params = {
      include: ["ProviderInvoice"],
    };
    api
      .get("/expense-types/" + id, { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.message, { variant: "error" });
        else {
          const {
            children,
            description,
            id,
            name,
            parentId,
            ProviderInvoices,
          } = response.data;
          const expenseType = {
            children,
            description,
            id,
            name,
            parentId,
            ProviderInvoices,
          };
          dispatch({ type: "SET_EXPENSE_TYPE", payload: expenseType });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getExpenseTypes = () => {
    api
      .get("/expense-types", { params: { include: ["ProviderInvoice"] } })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_EXPENSE_TYPES",
            payload: response.data,
          });
        }
        dispatch({
          type: "SET_LOADED_TRUE",
        });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getExpenseTypesStructure = () => {
    api
      .get("/expense-types-structure")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_EXPENSE_TYPES_TREE",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const getFile = (providerInvoiceId) => {
    api
      .get("/provider-invoice/" + providerInvoiceId + "/get-file", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: response.data.type });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  // Functions and handlers
  const getExpenseTypeDirectories = (expenseTypes) => {
    let directories = [];
    expenseTypes.forEach((expenseType) => {
      if (expenseType.children.length !== 0 && expenseType.id !== Number(id)) {
        directories.push({
          id: expenseType.id,
          name: expenseType.name,
        });
        directories = directories.concat(
          getExpenseTypeDirectories(expenseType.children)
        );
      }
    });
    return directories;
  };

  const getProviderInvoices = (expenseType) => {
    let providerInvoices = [];
    if (expenseType.ProviderInvoices?.length !== 0) {
      expenseType.ProviderInvoices.forEach((invoice) => {
        invoice.expenseTypeName = expenseType.name;
      });
      providerInvoices = providerInvoices.concat(expenseType.ProviderInvoices);
    }
    expenseType.children.forEach((child) => {
      providerInvoices = providerInvoices.concat(getProviderInvoices(child));
    });
    return providerInvoices;
  };

  const handleChangeProviderInvoiceExpenseType = (e) => {
    const name = state.expenseTypes.find(
      (expenseType) => expenseType.id === e.target.value
    ).name;
    dispatch({
      type: "SET_SELECTED_EXPENSE_TYPE",
      payload: {
        id: e.target.value,
        name,
      },
    });
  };

  const handleDeleteExpenseType = (e) => {
    deleteExpenseType();
    history.goBack();
  };

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleSetExpenseTypesDirectories = () => {
    let directories = [];
    state.expenseTypesTree?.length !== 0 &&
      (directories = getExpenseTypeDirectories(state.expenseTypesTree));
    dispatch({
      type: "SET_EXPENSE_TYPES_DIRECTORIES",
      payload: directories,
    });
  };

  const handleSetProviderInvoices = () => {
    let providerInvoices = [];
    providerInvoices = getProviderInvoices(state.expenseType);
    dispatch({
      type: "SET_PROVIDER_INVOICES",
      payload: providerInvoices,
    });
    // dispatch({
    //   type: "SET_LOADED_TRUE",
    // });
  };

  const hasAction = (action) => {
    let isFound = false;
    user?.Role.Actions.forEach((item) => {
      if (item.id === action) {
        isFound = true;
      }
    });

    return isFound;
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const selectProviderInvoicesRows = (selectedIds) => {
    dispatch({
      type: "SET_SELECTED_PROVIDER_INVOICES_IDS",
      payload: selectedIds,
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const setPdfButtonValue = (providerInvoiceId) => {
    const found = state.providerInvoices.find(
      (provid) => provid.id === providerInvoiceId
    );
    if (!found) return true;
    if (!found.fileRequired) return true;
    return false;
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const resetSelectedExpenseType = () => {
    dispatch({
      type: "RESET_SELECTED_EXPENSE_TYPE",
    });
  };

  const validateExpenseType = (expenseType) => {
    let isValid = true;
    let fields = ["name"];

    fields.forEach((field) => {
      if (expenseType[field] === "") {
        setInputErrorTrue(field);
        isValid = false;
      }
    });

    return isValid;
  };

  return (
    <Container maxWidth="lg" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item container alignItems="center" xs={12} spacing={1}>
            <Grid item>
              <Typography variant="h4">{state.expenseType.name}</Typography>
            </Grid>
            {hasAction("DELETE_EXPENSE_TYPES") &&
              state.expenseType.children.length === 0 &&
              state.providerInvoices.length === 0 && (
                <Grid item flex={1} justifyContent="flex-end" display="flex">
                  <Tooltip title={t("delete")} placement="top">
                    <IconButton color="error" onClick={handleDeleteExpenseType}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
          </Grid>
          {hasAction("EDIT_EXPENSE_TYPES") && (
            <Grid item container spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <TextInput
                  error={state.inputError.name}
                  helperText={
                    state.inputError.name
                      ? t("name") + " " + t("isRequired")
                      : ""
                  }
                  label={t("name")}
                  name="name"
                  onChange={handleInputChange}
                  value={state.expenseType.name}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomSelect
                  label={t("parentExpenseType")}
                  name="parentId"
                  value={state.expenseType.parentId}
                  onChange={handleInputChange}
                  options={[
                    { value: null, label: t("none") },
                    ...state.expenseTypes?.map((expenseType) => ({
                      value: expenseType.id,
                      label: expenseType.name,
                      disabled: expenseType.ProviderInvoices?.length > 0,
                    })),
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <TextInput
                  label={t("description")}
                  name="description"
                  onChange={handleInputChange}
                  value={state.expenseType.description}
                />
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} lg={1}></Grid>
          <Grid item xs={12} lg={10}>
            <ExpenseTypesChartFlow items={state.expenseType.ProviderInvoices} />
          </Grid>
          <Grid item xs={12} lg={1}></Grid>

          <Grid item xs={12} marginTop={2}>
            <ItemsSummary gridItems={EXPENSE_TYPE_ITEMS_SUMMARY} />
          </Grid>

          <Grid item xs={12}>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                columns={EXPENSE_TYPE_COLUMNS}
                rows={state.providerInvoices}
                checkboxSelection
                selectionModel={state.selectedProviderInvoicesIds}
                onSelectionModelChange={(selectedIds) => {
                  selectProviderInvoicesRows(selectedIds);
                }}
                loading={!state.options.loaded}
              />
            </div>
          </Grid>

          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("changeExpenseType")}</Typography>
            </Grid>
            <Grid item>
              {state.selectedProviderInvoicesIds?.length > 0 ? (
                <CustomSelect
                  label={t("change") + " " + t("to")}
                  name="parentId"
                  onChange={handleChangeProviderInvoiceExpenseType}
                  options={state.expenseTypes
                    ?.filter(
                      (expenseType) =>
                        !state.expenseTypesDirectories.some(
                          (type) => type.id === expenseType.id
                        )
                    )
                    .map((expenseType) => ({
                      value: expenseType.id,
                      label: expenseType.name,
                    }))}
                />
              ) : (
                <Typography variant="body2">
                  {t("selectProviderInvoicesToChangeExpenseType")}
                </Typography>
              )}
            </Grid>
            <Grid item>
              {state.selectedProviderInvoicesIds?.length > 0 &&
                state.selectedExpenseType.id !== "" && (
                  <CustomButton
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch({
                        type: "SET_CONFIRM_DIALOG",
                        payload: {
                          title: t("changeExpenseType"),
                          isOpen: true,
                          childrenText: `${t("changeExpenseType")} ${t("of")} ${
                            state.selectedProviderInvoicesIds.length
                          } ${t("providerInvoices").toLowerCase()} ${t("to")} ${
                            state.selectedExpenseType?.name
                          }?`,
                          callback: (confirmed) => {
                            confirmed && editProviderInvoicesExpenseType();
                            resetConfirmDialog();
                          },
                        },
                      });
                    }}
                  >
                    {t("change")}
                  </CustomButton>
                )}
            </Grid>
          </Grid>

          {hasAction("EDIT_EXPENSE_TYPES") && (
            <Grid container item spacing={1} xs={12} justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {t("back")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => editExpenseType(state.expenseType)}
                >
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>

      {/* Confirm Dialog */}
      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
      {state.redirect && <Redirect to={"/app/expense-types"} />}
    </Container>
  );
};

const ExpenseTypesChartFlow = ({ items }) => {
  const labels = items.map((invoice) => invoice.date);
  const data = items.map((invoice) => invoice.amount);
  const [t] = useTranslation("expenseTypes");

  const chartData = {
    labels,
    datasets: [
      {
        label: t("amount"),
        data: data,
        backgroundColor: colors.primary,
      },
    ],
  };

  const options = {
    elements: { point: { radius: 0 } },
    animation: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
  };

  return (
    <ChartContainer
      title={t("costs")}
      chart={<Bar data={chartData} options={options} />}
    ></ChartContainer>
  );
};

export default ExpenseTypePage;
