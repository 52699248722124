import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../Inputs/TextInput";

/**
 * CustomSelect Component
 *
 * This component creates a custom Select dropdown with various features.
 *
 * @param {Object} props - Component properties
 *   - options: An array of options for the Select dropdown.
 *   - multiple: A boolean indicating whether multiple options can be selected.
 *   - value: The currently selected value(s) of the dropdown.
 *   - onChange: Callback function triggered on value change.
 *   - grouping: A boolean indicating whether options should be grouped.
 *   - shrink: A boolean indicating whether the label should shrink when not focused.
 *   - helperText: Additional helper text to be displayed below the dropdown.
 *   - ...rest: Additional properties to be spread onto the Select component.
 *
 * @returns {JSX.Element} CustomSelect component
 *
 * Note:
 * - For grouped options, a tree structure is built using the 'options' array.
 * - 'renderOptions' is a recursive function to render nested options.
 * - When 'multiple' is true, a 'Select All' checkbox is provided for convenience.
 */
const CustomSelect = (props) => {
  const [t] = useTranslation("app");
  const {
    options,
    multiple,
    value,
    onChange,
    grouping,
    shrink,
    helperText,
    ...rest
  } = props;
  const [selectAllValue, setSelectAllValue] = useState(false);
  const [filter, setFilter] = useState("");

  const selectAllOptions = () => {
    setSelectAllValue(!selectAllValue);
  };

  /**
   * The buildTree function first filters the options array based on the parentId
   * of each option, keeping only the options that have a matching parentId value.
   * It then maps over the filtered array, adding a children key to each option and
   * assigning it the result of a recursive call to buildTree with the option's
   * value as the parentId.
   * This way, the function builds a nested tree structure of options, with the children
   * of each option being the options with a matching parentId.
   */
  const buildTree = (options, parentId = null) => {
    return options
      .filter((option) => option.parentId === parentId)
      .map((option) => ({
        ...option,
        children: buildTree(options, option.value),
      }));
  };

  /**
   * The hasFilterInLabelOrChildren function checks if a given filter string is present in the label of the option
   * or any of its descendants. It first checks if the filter string is present in the label of the current option.
   * If not, and if the option has children, it makes a recursive call for each child until it finds a match or exhausts all options.
   */
  const hasFilterInLabelOrChildren = (option, filter) => {
    // Normalize both the option label and filter string to remove accents
    const normalizedLabel = option.label
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    const normalizedFilter = filter
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    return (
      normalizedLabel.includes(normalizedFilter) ||
      (option.children &&
        option.children.some((child) =>
          hasFilterInLabelOrChildren(child, filter)
        ))
    );
  };

  /**
   * The renderOptions function is a recursive function that is used to render the
   * options in a nested menu. The function starts by building the tree structure
   * of the options if the level is equal to 0. Then, it maps through each option
   * in the optionsTree and does the following: If the option has children, it returns
   * a disabled MenuItem component for the parent option, and calls the function
   * recursively for each child, passing the children and an increased level as arguments.
   * The paddingLeft style is used to indent each level by level + 0.5em.
   * If the option does not have children, it returns a MenuItem component for the
   * leaf node, passing the option's value and label as props. The paddingLeft style
   * is also applied to this component.
   */
  const renderOptions = (options, level = 0) => {
    // Build tree structure of options if at top level
    let optionsTree = level === 0 ? buildTree(options) : options;

    // Filter options on level 0 and children based on filter string
    if (filter !== "") {
      optionsTree = optionsTree.filter((option) =>
        hasFilterInLabelOrChildren(option, filter)
      );
    }

    // Return sorted and mapped options
    return optionsTree
      .sort((a, b) => a.label.localeCompare(b.label)) // Sort options alphabetically by label
      .map((option) => {
        // If option has children, render them recursively
        if (option.children && option.children.length !== 0) {
          return [
            <MenuItem
              disabled
              key={option.value}
              style={{ paddingLeft: level + 0.5 + "em" }}
            >
              {option.label}
            </MenuItem>,
            ...renderOptions(option.children, level + 1),
          ];
        }

        // If option has no children, render it normally
        return (
          <MenuItem
            disabled={option.disabled}
            value={option.value}
            key={option.value}
            style={{ paddingLeft: level + 0.5 + "em" }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            {option.label}
          </MenuItem>
        );
      });
  };

  return (
    <FormControl
      error={props.error}
      size="small"
      fullWidth
      sx={{ minWidth: props.minWidth || 200 }}
    >
      <InputLabel id="custom-select-label" shrink={shrink}>
        {props.label}
      </InputLabel>
      <Select
        {...rest}
        size="small"
        // native={grouping ? true : false}
        labelId={grouping ? "grouped-native-select" : "custom-select-label"}
        onChange={(e) => {
          if (multiple && e.target.value.includes("selectAllCheckbox")) {
            if (!selectAllValue) e.target.value = options.map((o) => o.value);
            else e.target.value = [];
          } else setSelectAllValue(false);
          e.preventDefault();
          e.stopPropagation();
          onChange(e);
        }}
        multiple={multiple}
        value={value}
        renderValue={
          multiple &&
          ((selected) => {
            return options
              .filter((option) => selected.indexOf(option.value) > -1)
              .map((option) => option.label)
              .join(", ");
          })
        }
      >
        {multiple && (
          <MenuItem
            value={"selectAllCheckbox"}
            key={"selectAllCheckbox"}
            onClick={selectAllOptions}
          >
            <Checkbox checked={selectAllValue} />
            {t("selectAll")}
          </MenuItem>
        )}
        {multiple && <Divider />}

        {grouping && (
          <Box padding={1} minWidth={400}>
            <TextInput
              autoFocus
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
              label={t("search")}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </Box>
        )}

        {grouping
          ? renderOptions(options)
          : options.map((option) => (
              <MenuItem
                value={option.value}
                key={option.value}
                disabled={option.disabled === true}
              >
                {multiple && (
                  <Checkbox checked={value.indexOf(option.value) > -1} />
                )}
                {option.label}
              </MenuItem>
            ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

CustomSelect.propTypes = {
  options: PropTypes.any,
  multiple: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  grouping: PropTypes.bool,
  shrink: PropTypes.bool,
};

export default CustomSelect;
