import {
  Autocomplete,
  Button,
  Box,
  Container,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Paper,
  Typography,
  ButtonGroup,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { DataGrid } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import CenterSelect from "../../Inputs/CenterSelect";
import ItemsSummary from "../../ItemsSummary";
import TextInput from "../../Inputs/TextInput";
import { localeFormat } from "../../../utils/format";
import SearchButton from "../../Inputs/SearchButton";
import CustomButton from "../../Inputs/CustomButton";
import { round } from "../../../utils/math";
import {
  RENT_MERCHANTABLE_TYPE_ID,
  PROMOTION_MERCHANTABLE_TYPE_ID,
} from "../../../data/constants";

const initialState = {
  form: {
    centerId: "",
    comments: "",
    concept: "",
    contractId: "",
    customer: "",
    endDate: "",
    startDate: "",
    pricePerUnit: "",
    vatPercentage: 21,
    merchantableTypeId: "",
    units: 1,
    royaltyAmount: "",
    royaltyPercentage: "",
  },
  inputError: {
    contractId: false,
    startDate: false,
    endDate: false,
    pricePerUnit: false,
    concept: false,
    comments: false,
    vatPercentage: false,
    merchantableTypeId: false,
  },
  activeStep: 0,
  boxes: [],
  contracts: [],
  customers: [],
  customerFilters: {
    loaded: true,
    boxId: "",
    centerId: "",
    customer: "",
  },
  merchantableTypes: [],
  submitLoading: false,
  hasContract: false,
  id: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_ACTIVE_STEP":
      return { ...state, activeStep: action.payload };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_MERCHANTABLE":
      return {
        ...state,
        form: action.payload.merchantable,
      };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_CONTRACTS":
      return { ...state, contracts: action.payload };
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_TYPES":
      return { ...state, merchantableTypes: action.payload };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_SUBMIT_LOADING":
      return { ...state, submitLoading: action.payload };
    case "SET_FILTER_CONTRACT":
      return {
        ...state,
        customerFilters: {
          ...state.customerFilters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_HAS_CONTRACT":
      return { ...state, hasContract: action.payload };
    default:
      throw new Error();
  }
}

export default function CreateMerchantablePage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("merchantables");
  const history = useHistory();

  const [state, dispatch] = useReducer(reducer, initialState);

  //Initial useEffect
  useEffect(() => {
    document.title = t("createMerchantablePage");

    getMerchantableTypes();
    getBoxes();
    getCustomers();
  }, []);

  const CUSTOMER_COLUMNS = [
    { field: "publicId", headerName: t("publicId"), flex: 1 },
    { field: "boxName", headerName: t("box"), flex: 1 },
    { field: "startDate", headerName: t("startDate"), flex: 1 },
    { field: "customerName", headerName: t("customer"), flex: 1 },
    { field: "centerName", headerName: t("center"), flex: 1 },
  ];

  const getMerchantableTypes = () => {
    api
      .get("/merchantables/types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_TYPES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getBoxes = () => {
    api
      .get("/boxes")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_BOXES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getContracts = () => {
    let params = {
      include: ["Box", "Center", "Customer"],
      customerId: state.customerFilters.customer.id,
    };

    state.customerFilters.boxId !== "" &&
      (params.boxId = state.customerFilters.boxId);
    state.customerFilters.centerId !== "" &&
      (params.centerId = [state.customerFilters.centerId]);

    handleChangeFilterContract({
      target: { name: "inputname", value: "true" },
    });

    api
      .get("/contracts", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("updatedContracts"), { variant: "success" });
          response.data.forEach((contract) => {
            contract.selected = true;
            contract.customerName = contract.Customer.name;
            contract.boxName = contract.Box.name;
            contract.centerName = contract.Box.Center.name ?? "-";
          });
          dispatch({
            type: "SET_CONTRACTS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        handleChangeFilterContract({
          target: { name: "inputname", value: "true" },
        });
      });
  };

  const getCustomers = () => {
    api
      .get("/customers")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_CUSTOMERS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const submitForm = () => {
    if (validateForm()) {
      dispatch({ type: "SET_SUBMIT_LOADING", payload: true });

      // Obligatory fields
      let data = {
        concept: state.form.concept,
        pricePerUnit: state.form.pricePerUnit,
        vatPercentage: state.form.vatPercentage,
        startDate: state.form.startDate,
        merchantableTypeId: state.form.merchantableTypeId,
        royaltyAmount: state.form.royaltyAmount ?? 0,
      };

      // Optional fields
      state.form.endDate !== "" && (data.endDate = state.form.endDate);
      state.form.comments !== "" && (data.comments = state.form.comments);
      state.form.units !== "" && (data.units = state.form.units);

      if (state.hasContract) {
        const contract = state.contracts.find(
          (contract) => contract.id === state.form.contractId[0]
        );

        data.contractId = contract.id;
        data.centerId = contract.Box.centerId;
        data.customerId = contract.customerId;
      } else {
        data.centerId = state.form.centerId;
        data.customerId = state.form.customer.id;
      }

      api
        .post("/merchantables/create", data)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("merchantableCreateSuccess"), {
              variant: "success",
            });
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => {
          dispatch({ type: "SET_SUBMIT_LOADING", payload: false });
        });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let fields = [
      "concept",
      "pricePerUnit",
      "vatPercentage",
      "startDate",
      "merchantableTypeId",
    ];

    if (state.hasContract)
      state.form.contractId.length === 0 &&
        enqueueSnackbar(t("contract") + " " + t("isRequired"), {
          variant: "error",
        });
    else {
      fields.push("centerId");
      fields.push("customer");
    }

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    return isValid;
  };

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
    if (e.target.name === "royaltyAmount") {
      const royaltyPercentage = round(
        (Number(e.target.value) / state.form.units / state.form.pricePerUnit) *
          100
      );
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "royaltyPercentage",
          value: royaltyPercentage,
        },
      });
    } else if (e.target.name === "royaltyPercentage") {
      const royaltyAmount = round(
        (Number(e.target.value) / 100) *
          (state.form.units * state.form.pricePerUnit)
      );
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "royaltyAmount",
          value: royaltyAmount,
        },
      });
    }
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const handleChangeFilterContract = (e) => {
    dispatch({
      type: "SET_FILTER_CONTRACT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleChangeCenter = (e) => {
    dispatch({
      type: "SET_FILTER_CONTRACT",
      payload: {
        inputname: "boxId",
        value: "",
      },
    });

    dispatch({
      type: "SET_FILTER_CONTRACT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const selectContract = (selectedId) => {
    let newContracts = state.contracts;

    newContracts?.forEach((contract) => {
      if (selectedId.length === 0) contract.selected = true;
      else if (contract.id !== selectedId[0]) contract.selected = false;
    });

    dispatch({
      type: "SET_CONTRACTS",
      payload: newContracts,
    });

    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "contractId",
        value: selectedId,
      },
    });
  };

  const handleSteps = (step) => {
    if (state.hasContract && step === 2 && state.form.contractId) {
      const contract = state.contracts.find(
        (contract) => Number(contract.id) === Number(state.form.contractId)
      );
      const vatPercentage = contract.taxes;
      const pricePerUnit = contract.pricePerMeter;
      handleInputChange({
        target: {
          name: "vatPercentage",
          value: vatPercentage,
        },
      });
      handleInputChange({
        target: {
          name: "pricePerUnit",
          value: pricePerUnit,
        },
      });
    }
    if (
      state.hasContract &&
      step === 3 &&
      state.form.contractId &&
      state.form.merchantableTypeId === 1
    ) {
      const contract = state.contracts.find(
        (contract) => Number(contract.id) === Number(state.form.contractId)
      );
      const royaltyPercentage = contract.Box.royalty;
      const royaltyAmount = round(
        (Number(royaltyPercentage) / 100) *
          (state.form.units * state.form.pricePerUnit)
      );
      handleInputChange({
        target: {
          name: "royaltyPercentage",
          value: royaltyPercentage,
        },
      });
      handleInputChange({
        target: {
          name: "royaltyAmount",
          value: royaltyAmount,
        },
      });
    }
    dispatch({ type: "SET_ACTIVE_STEP", payload: step });
  };

  return (
    <Container sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createMerchantable")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Stepper orientation="vertical" activeStep={state.activeStep}>
              <Step>
                <StepLabel> {t("merchantableType")}</StepLabel>
                <StepContent>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={5}>
                      <CustomSelect
                        error={state.inputError.merchantableTypeId}
                        helperText={
                          state.inputError.merchantableTypeId
                            ? t("merchantableType") + " " + t("mustNotBeBlank")
                            : ""
                        }
                        label={t("merchantableType")}
                        value={state.form.merchantableTypeId}
                        name="merchantableTypeId"
                        onChange={handleInputChange}
                        options={[
                          { value: "0", label: t("none") },
                          ...state.merchantableTypes.map((type) => ({
                            value: type.id,
                            label: type.name,
                            disabled:
                              type.id === PROMOTION_MERCHANTABLE_TYPE_ID
                                ? true
                                : false,
                          })),
                        ]}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonGroup
                        variant="contained"
                        disabled={
                          state.form.merchantableTypeId != "" ? false : true
                        }
                      >
                        <Button
                          onClick={() => {
                            handleSteps(1);
                            dispatch({
                              type: "SET_HAS_CONTRACT",
                              payload: true,
                            });
                          }}
                        >
                          {t("contract")}
                        </Button>
                        <Button
                          onClick={() => {
                            dispatch({
                              type: "SET_HAS_CONTRACT",
                              payload: false,
                            });
                            handleSteps(1, true);
                          }}
                        >
                          {t("noContract")}
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>{t("associations")}</StepLabel>
                <StepContent>
                  <Grid item container spacing={2}>
                    {state.hasContract ? (
                      <>
                        <Grid item xs={12} sm="auto">
                          <CenterSelect
                            onChange={handleChangeCenter}
                            value={state.customerFilters.centerId}
                            name="centerId"
                          />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                          <CustomSelect
                            name="boxId"
                            onChange={handleChangeFilterContract}
                            label={t("box")}
                            value={state.customerFilters.boxId}
                            options={[
                              { value: "", label: t("all") },
                              ...state.boxes
                                .filter(
                                  (box) =>
                                    state.customerFilters.centerId !== "" &&
                                    box.centerId ===
                                      state.customerFilters.centerId
                                )
                                .map((box) => ({
                                  value: box.id,
                                  label: box.name,
                                })),
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                          <Autocomplete
                            options={state.customers}
                            getOptionLabel={(customer) =>
                              customer.fullName || ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id || null
                            }
                            renderInput={(params) => (
                              <TextInput
                                {...params}
                                size="small"
                                label={t("customer") + " *"}
                                fullWidth
                              />
                            )}
                            value={state.customerFilters.customer || null}
                            onChange={(e, customer) => {
                              handleChangeFilterContract({
                                target: { value: customer, name: "customer" },
                              });
                            }}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.fullName}
                              </li>
                            )}
                            filterOptions={createFilterOptions({ limit: 10 })}
                            sx={{ width: 300 }}
                          />
                        </Grid>
                        <Grid item>
                          <SearchButton
                            onClick={getContracts}
                            loading={!state.customerFilters.loaded}
                            disabled={
                              state.customerFilters.customer !== null &&
                              state.customerFilters.customer !== ""
                                ? false
                                : true
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box style={{ height: 400, width: "100%" }}>
                            <DataGrid
                              columns={CUSTOMER_COLUMNS}
                              rows={state?.contracts}
                              checkboxSelection
                              selectionModel={state.form.contractId}
                              isRowSelectable={(params) =>
                                params.row.selected === true
                              }
                              onSelectionModelChange={(selectedId) => {
                                selectContract(selectedId);
                              }}
                              loading={!state.customerFilters.loaded}
                            />
                          </Box>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} sm="auto">
                          <CenterSelect
                            value={state.form.centerId}
                            name="centerId"
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                          <Autocomplete
                            options={state.customers}
                            getOptionLabel={(customer) =>
                              customer.fullName || ""
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id || null
                            }
                            renderInput={(params) => (
                              <TextInput
                                {...params}
                                size="small"
                                label={t("customer")}
                                fullWidth
                              />
                            )}
                            value={state.form.customer || null}
                            onChange={(e, customer) => {
                              handleInputChange({
                                target: { value: customer, name: "customer" },
                              });
                            }}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {option.fullName}
                              </li>
                            )}
                            filterOptions={createFilterOptions({ limit: 10 })}
                            sx={{ width: 300 }}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          handleSteps(0);
                        }}
                        variant="text"
                      >
                        {t("back")}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleSteps(2);
                        }}
                        disabled={
                          state.hasContract
                            ? Boolean(state.form.contractId.length === 0)
                            : !Boolean(
                                state.form.customer && state.form.centerId
                              )
                        }
                      >
                        {t("next")}
                      </Button>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>{t("data")}</StepLabel>
                <StepContent>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <TextInput
                        error={state.inputError.concept}
                        helperText={
                          state.inputError.concept
                            ? t("conceptMustNotBeBlank")
                            : ""
                        }
                        label={t("concept")}
                        value={state.form.concept}
                        onChange={handleInputChange}
                        name="concept"
                      />
                    </Grid>
                    <Grid container item spacing={2} xs={12}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CustomDate
                          error={state.inputError.startDate}
                          helperText={
                            state.inputError.startDate
                              ? t(
                                  state.form.merchantableTypeId === 1
                                    ? "startDate"
                                    : "date"
                                ) +
                                " " +
                                t("mustNotBeBlank")
                              : ""
                          }
                          label={t(
                            state.form.merchantableTypeId === 1
                              ? "startDate"
                              : "date"
                          )}
                          value={state.form.startDate}
                          onChange={handleInputChange}
                          name="startDate"
                        />
                      </Grid>
                      {state.form.merchantableTypeId === 1 && (
                        <Grid item xs={12} sm={6} md={4}>
                          <CustomDate
                            error={state.inputError.endDate}
                            helperText={
                              state.inputError.endDate
                                ? t("endDateMustNotBeBlank")
                                : ""
                            }
                            label={t("endDate")}
                            value={state.form.endDate}
                            onChange={handleInputChange}
                            name="endDate"
                            inputProps={{
                              min: state.form.startDate,
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextInput
                        type="number"
                        label={t("units")}
                        value={state.form.units}
                        onChange={handleInputChange}
                        name="units"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextInput
                        error={state.inputError.pricePerUnit}
                        helperText={
                          state.inputError.pricePerUnit
                            ? t("pricePerUnitMustNotBeBlank")
                            : ""
                        }
                        type="number"
                        label={t("pricePerUnit")}
                        value={state.form.pricePerUnit}
                        onChange={handleInputChange}
                        name="pricePerUnit"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextInput
                        error={state.inputError.vatPercentage}
                        helperText={
                          state.inputError.vatPercentage
                            ? t("vatPercentageMustNotBeBlank")
                            : ""
                        }
                        type="number"
                        label={t("vatPercentage")}
                        value={state.form.vatPercentage}
                        onChange={handleInputChange}
                        name="vatPercentage"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ItemsSummary
                        gridItems={[
                          {
                            translatedText: t("baseAmount"),
                            value:
                              localeFormat(
                                state.form.units * state.form.pricePerUnit
                              ) + "€",
                          },
                          {
                            translatedText: t("totalAmount"),
                            value:
                              localeFormat(
                                state.form.units * state.form.pricePerUnit +
                                  state.form.units *
                                    state.form.pricePerUnit *
                                    (state.form.vatPercentage / 100)
                              ) + "€",
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          handleSteps(1);
                        }}
                        variant="text"
                      >
                        {t("back")}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          handleSteps(3);
                        }}
                        disabled={
                          !state.form.concept ||
                          !state.form.startDate ||
                          state.form.units === "" ||
                          state.form.pricePerUnit === "" ||
                          state.form.vatPercentage === ""
                        }
                      >
                        {t("next")}
                      </Button>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>{t("moreInfo")}</StepLabel>
                <StepContent>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextInput
                        type="number"
                        label={t("royaltyAmount")}
                        value={state.form.royaltyAmount}
                        onChange={handleInputChange}
                        name="royaltyAmount"
                        disabled={
                          state.form.merchantableTypeId === 1 &&
                          state.hasContract
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextInput
                        type="number"
                        label={t("royalty") + "%"}
                        value={state.form.royaltyPercentage}
                        onChange={handleInputChange}
                        name="royaltyPercentage"
                        disabled={
                          state.form.merchantableTypeId === 1 &&
                          state.hasContract
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        error={state.inputError.comments}
                        helperText={
                          state.inputError.comments
                            ? t("commentsMustNotBeBlank")
                            : ""
                        }
                        label={t("comments")}
                        multiline
                        rows={4}
                        value={state.form.comments}
                        onChange={handleInputChange}
                        name="comments"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          handleSteps(2);
                        }}
                        variant="text"
                      >
                        {t("back")}
                      </Button>
                      <CustomButton
                        loading={state.submitLoading}
                        color="success"
                        onClick={submitForm}
                        disabled={
                          state.form.merchantableTypeId !==
                            RENT_MERCHANTABLE_TYPE_ID &&
                          state.form.royaltyAmount === ""
                        }
                      >
                        {t("create")}
                      </CustomButton>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
            </Stepper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
