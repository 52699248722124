import {
  Box,
  ButtonBase,
  Card,
  Divider,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import CustomSelect from "../../../Inputs/CustomSelect";

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import CreateRectForm, {
  defaultValues as rectDefValues,
} from "./CreateRectForm";
import CreateImageForm from "./CreateImageForm";
import CreatePolygonForm from "./CreatePolygonForm";
import CreateTextForm from "./CreateTextForm";
import CreateDoorForm, {
  defaultValues as doorDefValues,
} from "./CreateDoorForm";
import CreateCameraForm, {
  defaultValues as cameraDefValues,
} from "./CreateCameraForm";
import { TileIcons } from "../TileShapes/Tile";

const CREATE_FORMS = {
  // Rect: CreateRectForm,
  Image: CreateImageForm,
  Polygon: CreatePolygonForm,
  Text: CreateTextForm,
  // Door: CreateDoorForm,
  // Camera: CreateCameraForm,
};

const DEFAULT_VALUES = {
  Rect: rectDefValues,
  Door: doorDefValues,
  Camera: cameraDefValues,
};

const CreateTileForm = ({
  centerId,
  closeAddTileDialog,
  onCreateTile,
  layerTiles,
}) => {
  const [t] = useTranslation("floorPlans");
  const [shape, setShape] = useState("");

  const CreateForm = CREATE_FORMS[shape];

  const shapes = [
    { value: "Rect", label: t("rectangle") },
    { value: "Image", label: t("image") },
    { value: "Polygon", label: t("polygon") },
    { value: "Text", label: t("text") },
    { value: "Door", label: t("door") },
    { value: "Camera", label: t("camera") },
  ];

  useEffect(() => {
    if (shape === "Rect" || shape === "Door" || shape === "Camera") {
      onCreateTile({
        ...DEFAULT_VALUES[shape],
        name: "Tile " + (layerTiles.length + 1),
      });
    }
  }, [shape]);

  return shape ? (
    <Grid container>
      <Grid item maxWidth={800}>
        {CreateForm && (
          <CreateForm
            onCreateTile={onCreateTile}
            closeAddTileDialog={closeAddTileDialog}
            centerId={centerId}
            layerTiles={layerTiles}
          />
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      {shapes.map((shape, index) => (
        <Grid item>
          <ButtonBase
            key={index}
            onClick={() => {
              setShape(shape.value);
            }}
          >
            <Card elevation={2}>
              <Box
                position="relative"
                width={100}
                height={100}
                bgcolor="white"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Icon color="action">{TileIcons[shape.value]}</Icon>
              </Box>
            </Card>
          </ButtonBase>
        </Grid>
      ))}
    </Grid>
  );
};

export default CreateTileForm;
