import { Container, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextInput from "../Inputs/TextInput";
import CustomButton from "../Inputs/CustomButton";
import { useState } from "react";
import LabeledText from "../global/LabeledText";

export default function SecureLocationForm({
  onTrustClick,
  userDeviceLabel,
  userLocationLabel,
}) {
  const [t] = useTranslation("login");

  const [{ deviceLabel, locationLabel }, setLabels] = useState({
    deviceLabel: userDeviceLabel || "",
    locationLabel: userLocationLabel || "",
  });

  const handleSetLabels = (e) => {
    setLabels((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const addButtonDisabled =
    !deviceLabel ||
    !locationLabel ||
    !deviceLabel.trim() ||
    !locationLabel.trim();

  return (
    <Container maxWidth="xs">
      <Paper>
        <Grid container padding={2} rowGap={3}>
          {/* Title */}
          <Grid item xs={12}>
            <Typography variant="h6">{t("trustedDevices")}</Typography>
          </Grid>

          {/* Verification Code Section */}
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                {t("addTrustedDevice")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                {t("addTrustedDeviceDescription")}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              {userDeviceLabel ? (
                <LabeledText
                  label={t("accessingWith")}
                  value={userDeviceLabel}
                  variant="body2"
                />
              ) : (
                <TextInput
                  label={t("deviceName")}
                  value={deviceLabel}
                  name="deviceLabel"
                  onChange={handleSetLabels}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {userLocationLabel ? (
                <LabeledText
                  label={t("accessingFrom")}
                  value={userLocationLabel}
                  variant="body2"
                />
              ) : (
                <TextInput
                  label={t("fromWhereAccess")}
                  value={locationLabel}
                  name="locationLabel"
                  onChange={handleSetLabels}
                />
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} sm={6}>
              <CustomButton
                fullWidth
                variant="outlined"
                onClick={() => onTrustClick(false)}
              >
                {t("doNotAdd")}
              </CustomButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomButton
                fullWidth
                onClick={() => onTrustClick(true, deviceLabel, locationLabel)}
                disabled={addButtonDisabled}
              >
                {t("add")}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
