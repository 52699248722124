//React imports
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Draft imports
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";

//Mui material imports
import { Card, Menu, MenuItem, IconButton, Tooltip } from "@mui/material";

//Mui icons imports
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

//Components imports
import TextInput from "../../Inputs/TextInput";

//Constants imports
import { EMAIL_TEMPLATE_VARIABLES } from "../../../data/constants";

const CustomOption = ({ editorState, onChange, variablesList }) => {
  const [t] = useTranslation("others");

  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    document.title = t("htmlEditor");
  }, []);

  const addVar = (text) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text,
      editorState.getCurrentInlineStyle()
    );

    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  return (
    <>
      <Tooltip
        title={t("variables")}
        placement="right"
        sx={{ marginBottom: 1 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        >
          <Card variant="outlined" sx={{ width: 35, height: 30 }}>
            <TextSnippetIcon
              color="black"
              sx={{ fontSize: 20, marginTop: 0.5 }}
            />
          </Card>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <TextInput
          label={t("search")}
          autoFocus
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          value={filter}
        />

        {variablesList?.variables
          .filter((variable) =>
            variable.label
              .toLocaleLowerCase()
              .includes(filter.toLocaleLowerCase())
          )
          .map((item) => (
            <MenuItem
              onClick={() => {
                addVar(item.value);
              }}
            >
              {item.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

const EmailTextEditor = ({
  contractTypeId,
  html,
  onChange,
  forceUpdate = false,
}) => {
  const content = ContentState.createFromBlockArray(htmlToDraft(html));
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(content)
  );

  console.log(EMAIL_TEMPLATE_VARIABLES, contractTypeId);
  const variablesList = EMAIL_TEMPLATE_VARIABLES.find(
    (template) => template.id == contractTypeId
  );

  useEffect(() => {
    const content = ContentState.createFromBlockArray(htmlToDraft(html));
    setEditorState(() => EditorState.createWithContent(content));
  }, []);

  useEffect(() => {
    const content = ContentState.createFromBlockArray(htmlToDraft(html));
    setEditorState(() => EditorState.createWithContent(content));
  }, [forceUpdate]);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      toolbarCustomButtons={
        variablesList &&
        variablesList.variables.length > 0 && [
          <CustomOption variablesList={variablesList} />,
        ]
      }
      onChange={() => {
        onChange(
          draftToHtml(convertToRaw(editorState.getCurrentContent())),
          editorState.getCurrentContent().getPlainText("\u0001")
        );
      }}
    />
  );
};

export default EmailTextEditor;
