import React, { useContext, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// mui
import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// components & utils
import AppContext from "../../../context/AppContext";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import ButtonCSV from "../../Inputs/ButtonCSV";
import ButtonLink from "../../Inputs/ButtonLink";
import CenterSelect from "../../Inputs/CenterSelect";
import ConfirmDialog from "../../ConfirmDialog";
import CreateButton from "../../Inputs/CreateButton";
import CustomButton from "../../Inputs/CustomButton";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import Dialog from "../../global/Dialog";
import Filters from "../../global/structure/Filters";
import ItemsSummary from "../../ItemsSummary";
import Page from "../../global/structure/Page";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";

import { CustomTable } from "../../CustomTable";
import { getParams, generateURL } from "../../../utils/url";
import { localeFormat } from "../../../utils/format";
import {
  firstDateCurrentMonth,
  lastDateCurrentMonth,
  today,
} from "../../../utils/date";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  confirmDialog: {
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
  customer: [],
  customers: [],
  paymentMethods: [],
  periodicities: [],
  customer: null,
  dialog: {
    contracts: [],
    createAllLoading: false,
    searchLoading: false,
    selectedContracts: [],
    selectedDate: "",
    maxWidth: "xl",
    title: "createPendingMerchantables",
    isOpen: false,
  },
  dialogFilters: {
    date: today(),
    centers: [],
    customer: [],
    paymentMethod: [],
    periodicities: [],
  },
  merchantables: [],
  merchantableTypes: [],
  options: { rowlink: "merchantable", loaded: true },
  filters: {
    autoSearch: "false",
    centers: [],
    concept: "",
    customerId: "",
    dateFrom: firstDateCurrentMonth(),
    dateUntil: lastDateCurrentMonth(),
    invoiced: "",
    merchantableTypes: [],
    order: "asc",
    orderBy: "",
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "RESET":
      return {
        ...state,
        filters: initialState.filters,
        merchantables: [],
        customer: [],
      };
    case "SET_CONTRACTS":
      return {
        ...state,
        dialog: { ...state.dialog, contracts: action.payload },
      };
    case "SET_SELECTED_CONTRACTS":
      return {
        ...state,
        dialog: { ...state.dialog, selectedContracts: action.payload },
      };
    case "SET_CREATE_ALL_LOADING":
      return {
        ...state,
        dialog: { ...state.dialog, createAllLoading: action.payload },
      };
    case "SET_CUSTOMER":
      return { ...state, customer: action.payload };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_CUSTOMER":
      return { ...state, customer: action.payload };
    case "SET_DIALOG_DATE":
      return { ...state, dialog: { ...state.dialog, date: action.payload } };
    case "CLOSE_DIALOG":
      return { ...state, dialog: { ...state.dialog, isOpen: false } };
    case "OPEN_DIALOG":
      return { ...state, dialog: { ...state.dialog, isOpen: true } };
    case "SET_MERCHANTABLES":
      return { ...state, merchantables: action.payload };
    case "SET_MERCHANTABLE_TYPES":
      return { ...state, merchantableTypes: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_PERIODICITIES":
      return { ...state, periodicities: action.payload };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_SEARCH_LOADING":
      return {
        ...state,
        dialog: { ...state.dialog, searchLoading: action.payload },
      };
    case "SET_SELECTED_DATE":
      return {
        ...state,
        dialog: { ...state.dialog, selectedDate: action.payload },
      };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_ORDER":
      return {
        ...state,
        filters: {
          ...state.filters,
          orderBy: action.payload.orderBy,
          order: action.payload.order,
        },
      };
    case "SET_DIALOG_FILTER":
      return {
        ...state,
        dialogFilters: {
          ...state.dialogFilters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_DIALOG":
      return { ...state, dialogFilters: initialState.dialogFilters };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    default:
      throw new Error("Action not found:", action);
  }
}

export default function MerchantablesPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("merchantables");

  const FILTERS = [
    { name: "concept", type: "string" },
    { name: "autoSearch", type: "string" },
    { name: "centers", type: "array" },
    { name: "customerId", type: "number" },
    { name: "merchantableTypes", type: "array" },
    { name: "dateFrom", type: "date" },
    { name: "dateUntil", type: "date" },
    { name: "orderBy", type: "string" },
    { name: "order", type: "string" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const MERCHANTABLE_COLUMNS = [
    {
      key: "Contract.publicId",
      label: t("contract"),
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/contract/" + item.contractId}>{value}</ButtonLink>
      ),
    },
    {
      key: "MerchantableType.name",
      label: t("type"),
      sortType: "string",
    },
    {
      key: "concept",
      label: t("concept"),
      sortType: "string",
    },
    {
      key: "startDate",
      label: t("startDate"),
      sortType: "string",
    },
    {
      key: "units",
      label: t("units"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value),
    },
    {
      key: "pricePerUnit",
      label: t("pricePerUnit"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "baseAmount",
      label: t("baseAmount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "vatPercentage",
      label: t("vatPercentage"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "%",
    },
    {
      key: "totalAmount",
      label: t("total"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "invoiced",
      label: t("invoiced"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
  ];

  const CONTRACTS_COLUMNS = [
    { field: "publicId", headerName: t("contract"), flex: 1 },
    { field: "center", headerName: t("center"), flex: 1 },
    {
      field: "Box",
      headerName: t("box"),
      valueFormatter: ({ value }) => value.name,
      valueGetter: ({ row }) => row.Box?.name,
      flex: 1,
    },
    {
      field: "Customer",
      headerName: t("customer"),
      valueFormatter: ({ value }) => value.fullName,
      valueGetter: ({ row }) => row.Customer?.fullName,
      flex: 1,
    },
    {
      field: "Periodicity",
      headerName: t("periodicity"),
      valueFormatter: ({ value }) => value.name,
      valueGetter: ({ row }) => row.Periodicity?.name,
      flex: 1,
    },
    { field: "lastInvoicedDay", headerName: t("lastInvoicedDay"), flex: 1 },
    {
      field: "newMerchantables",
      headerName: t("newMerchantables"),
      valueGetter: ({ row }) => row.newMerchantables.length,
      flex: 1,
    },
    {
      field: "Merchantables",
      headerName: t("totalAmount"),
      valueGetter: ({ row }) => {
        let total = 0;
        row.newMerchantables.forEach(
          (merchantable) =>
            (total +=
              merchantable.units *
              merchantable.pricePerUnit *
              (1 + merchantable.vatPercentage / 100))
        );
        return total;
      },
      valueFormatter: ({ value }) => localeFormat(value) + "€",
      flex: 1,
    },
  ];

  const MERCHANTABLE_SUMMARY = [
    {
      translatedText: t("numberOfLines"),
      value: (typeof state.merchantables?.length === "number"
        ? state.merchantables?.length
        : 0
      ).toString(),
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          state.merchantables?.reduce(
            (total, merchantable) =>
              total + merchantable.units * merchantable.pricePerUnit,
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalAmountWithVAT"),
      value:
        localeFormat(
          state.merchantables?.reduce(
            (total, merchantable) =>
              total +
              merchantable.units *
                merchantable.pricePerUnit *
                (1 + merchantable.vatPercentage / 100),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalInvoiced"),
      value:
        localeFormat(
          state.merchantables?.reduce(
            (total, merchantable) => total + Number(merchantable.invoiced),
            0
          )
        ) + "€",
    },
  ];

  const csvHeaders = [
    { key: "Contract.publicId", label: t("contract") },
    { key: "MerchantableType.name", label: t("type") },
    { key: "concept", label: t("concept") },
    { key: "startDate", label: t("startDate") },
    { key: "units", label: t("units") },
    { key: "pricePerUnit", label: t("pricePerUnit") },
    { key: "baseAmount", label: t("baseAmount") },
    { key: "vatPercentage", label: t("vatPercentage") },
    { key: "totalAmount", label: t("total") },
    { key: "invoiced", label: t("invoiced") },
  ];

  //Initial useEffect
  useEffect(() => {
    getMerchantableTypes();
    getCustomers();
    getPaymentMethods();
    getPeriodicities();
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === "true") getMerchantables();
  }, [state.filters.autoSearch]);

  /* BACKEND CALLS */

  const getMerchantables = () => {
    const url = generateURL("/app/merchantables", state.filters);
    history.push(url);

    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      include: ["Contract", "InvoiceItem", "Box"],
    };

    // Filters
    state.filters.centers?.length > 0 &&
      (params.centers = state.filters.centers);
    state.filters.merchantableTypes?.length > 0 &&
      (params.merchType = state.filters.merchantableTypes);
    state.filters.customerId && (params.customer = state.filters.customerId);
    state.filters.concept?.length && (params.concept = state.filters.concept);
    state.filters.dateFrom && (params.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil && (params.dateUntil = state.filters.dateUntil);
    state.filters.invoiced !== "" && (params.invoiced = state.filters.invoiced);

    api
      .get("/merchantables", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noMerchantables"), { variant: "info" });
          }
          response.data.forEach((merch) => {
            merch.invoiced = localeFormat(
              merch.InvoiceItems.reduce(
                (sum, invoiceItem) => sum + invoiceItem.base,
                0
              )
            ).replace(",", ".");
          });

          dispatch({
            type: "SET_MERCHANTABLES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getMerchantableTypes = () => {
    api
      .get("/merchantables/types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_MERCHANTABLE_TYPES", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCustomers = () => {
    api
      .get("/customers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (state.filters.customerId !== "") {
            const customer = response.data.find(
              (customer) => customer.id === Number(state.filters.customerId)
            );
            dispatch({
              type: "SET_CUSTOMER",
              payload: customer,
            });
          }
          dispatch({
            type: "SET_CUSTOMERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPeriodicities = () => {
    api
      .get("/periods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_PERIODICITIES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPendingContracts = () => {
    dispatch({ type: "SET_SEARCH_LOADING", payload: true });
    dispatch({ type: "SET_CONTRACTS", payload: [] });

    let params = { date: state.dialogFilters.date };
    if (state.dialogFilters.centers.length > 0)
      params.centers = state.dialogFilters.centers;
    params.customerId = state.dialogFilters.customer.id;

    // Payment method
    if (state.dialogFilters.paymentMethod.length > 0) {
      params.paymentMethod = state.dialogFilters.paymentMethod;
    }

    // Periodicities
    if (state.dialogFilters.periodicities.length > 0) {
      params.periodicities = state.dialogFilters.periodicities;
    }

    state.dialogFilters.isKeyAccount !== "" &&
      (params.isKeyAccount = state.dialogFilters.isKeyAccount);

    api
      .get("/invoicing/pending-contracts", {
        params: params,
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noContracts"), { variant: "info" });
          }
          dispatch({ type: "SET_CONTRACTS", payload: response.data });
          dispatch({
            type: "SET_SELECTED_DATE",
            payload: state.dialogFilters.date,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_SEARCH_LOADING", payload: false });
      });
  };

  const createAll = (selectedIds) => {
    dispatch({ type: "SET_CREATE_ALL_LOADING", payload: true });
    api
      .post("/invoicing/create-merchantables", {
        contracts: selectedIds,
        date: state.dialog.selectedDate,
      })
      .then((response) => {
        dispatch({ type: "SET_CREATE_ALL_LOADING", payload: false });
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("merchantablesCreatedSuccessfully"), {
            variant: "success",
          });
          closeDialog();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
        dispatch({ type: "SET_CREATE_ALL_LOADING", payload: false });
      });
  };

  /* HANDLERS */

  const search = () => {
    if (state.filters.autoSearch === "true") getMerchantables();
    else
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const handleDialogFilterChange = (e) => {
    dispatch({
      type: "SET_DIALOG_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const openDialog = () => dispatch({ type: "OPEN_DIALOG" });

  const closeDialog = () => {
    dispatch({ type: "CLOSE_DIALOG" });
    dispatch({ type: "RESET_DIALOG" });
    dispatch({ type: "SET_CONTRACTS", payload: [] });
  };

  const handleContractSelection = (newSelection) => {
    dispatch({ type: "SET_SELECTED_CONTRACTS", payload: newSelection });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const handleCreate = () => {
    let selectedIds = state.dialog.selectedContracts;
    let contracts = state.dialog.contracts.filter((contract) =>
      selectedIds.includes(contract.id)
    );
    const numOfMerchantables = contracts.reduce(
      (num, contract) => num + contract.newMerchantables?.length,
      0
    );
    const totalPrice = contracts.reduce(
      (num, contract) =>
        num +
        contract.newMerchantables?.reduce(
          (totalPrice, merch) =>
            totalPrice +
            merch.units * merch.pricePerUnit * (1 + merch.vatPercentage / 100),
          0
        ),
      0
    );

    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        childrenText: `${t("creating")} ${numOfMerchantables} ${t(
          "merchantablesOf"
        )} ${contracts.length} ${t("contractsWithValue")} ${localeFormat(
          totalPrice
        )}€`,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && createAll(selectedIds);
          resetConfirmDialog();
        },
      },
    });
  };

  // Autocomplete input options
  const filterOptions = createFilterOptions({
    limit: 10, //limit of options to be displayed
  });

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  return (
    <Page browserTitle={t("merchantablesPage")} title={t("merchantables")}>
      <Grid container spacing={2}>
        <Grid item>
          <Filters
            filters={[
              <TextInput
                label={t("concept")}
                size="small"
                name="concept"
                value={state.filters.concept}
                onChange={handleFilterChange}
                onKeyPress={(e) => e.key === "Enter" && search()}
              />,
              <CustomDate
                label={t("dateFrom")}
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.filters.dateFrom}
                onChange={handleFilterChange}
                name="dateFrom"
              />,
              <CustomDate
                label={t("dateUntil")}
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.filters.dateUntil}
                onChange={handleFilterChange}
                name="dateUntil"
              />,
              <CustomSelect
                label={t("merchantableType")}
                name="merchantableTypes"
                multiple
                value={state.filters.merchantableTypes}
                onChange={handleFilterChange}
                options={state.merchantableTypes.map((type) => ({
                  value: type.id,
                  label: type.name,
                }))}
              />,
              <CenterSelect
                name="centers"
                value={state.filters.centers}
                onChange={handleFilterChange}
                multiple
              />,
              <Autocomplete
                size="small"
                sx={{ minWidth: "200px" }}
                options={state.customers}
                getOptionLabel={(customer) => customer.fullName || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id || null
                }
                filterOptions={filterOptions}
                value={state.customer}
                onChange={(e, customer) => {
                  handleFilterChange({
                    target: {
                      name: "customerId",
                      value: customer ? customer.id : "",
                    },
                  });
                  dispatch({ type: "SET_CUSTOMER", payload: customer });
                }}
                name="customer"
                renderInput={(params) => (
                  <TextInput
                    {...params}
                    size="small"
                    label={t("customerName")}
                  />
                )}
              />,
              <CustomSelect
                label={t("invoiced")}
                name="invoiced"
                value={state.filters.invoiced}
                onChange={handleFilterChange}
                options={[
                  {
                    value: "",
                    label: t("all"),
                  },
                  { value: true, label: t("yes") },
                  { value: false, label: t("no") },
                ]}
              />,
            ]}
          />
        </Grid>

        <Grid item>
          <ButtonGroup variant="contained">
            <Button onClick={reset}>{t("reset")}</Button>
            <ButtonCSV
              data={state.merchantables}
              headers={csvHeaders}
              filename={t("merchantables")}
            />
            <SearchButton onClick={search} loading={!state.options.loaded} />
          </ButtonGroup>
        </Grid>

        <Grid item>
          <CreateButton
            action={"CREATE_MERCHANTABLES"}
            label={t("createMerchantable")}
            link={"/merchantable/create"}
          />
        </Grid>

        <Grid item>
          <Button onClick={openDialog} variant="contained">
            {t("createAll")}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <ItemsSummary gridItems={MERCHANTABLE_SUMMARY} />
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            columns={MERCHANTABLE_COLUMNS}
            data={state.merchantables}
            options={state.options}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onSortCallback={(orderBy, order) => {
              handleOrderChange(orderBy, order);
              const url = generateURL("/app/merchantables", {
                ...state.filters,
                orderBy: orderBy,
                order: order,
              });
              history.push(url);
            }}
          />
        </Grid>
      </Grid>

      {/*Create all dialog */}
      <Dialog
        onClose={closeDialog}
        open={state.dialog.isOpen}
        title={t(state.dialog.title)}
        maxWidth={state.dialog.maxWidth}
        children={
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={1}>
              <Grid item>
                <Filters
                  filters={[
                    <CustomDate
                      value={state.dialogFilters.date}
                      name="date"
                      onChange={handleDialogFilterChange}
                    />,
                    <CenterSelect
                      multiple
                      name="centers"
                      value={state.dialogFilters.centers}
                      onChange={handleDialogFilterChange}
                    />,
                    <Autocomplete
                      size="small"
                      sx={{ minWidth: "200px" }}
                      options={state.customers}
                      getOptionLabel={(customer) => customer.fullName || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.nif === value.nif || null
                      }
                      filterOptions={filterOptions}
                      value={state.dialogFilters.customer}
                      onChange={(e, customer) => {
                        dispatch({
                          type: "SET_DIALOG_FILTER",
                          payload: {
                            inputname: "customer",
                            value: customer,
                          },
                        });
                      }}
                      name="customer"
                      renderInput={(params) => (
                        <TextInput
                          {...params}
                          size="small"
                          label={t("customerName")}
                        />
                      )}
                    />,
                    <CustomSelect
                      options={state.paymentMethods.map((p) => ({
                        value: p.id,
                        label: t(p.name),
                      }))}
                      value={state.dialogFilters.paymentMethod}
                      name="paymentMethod"
                      onChange={handleDialogFilterChange}
                      multiple
                      label={t("paymentMethod")}
                    />,
                    <CustomSelect
                      options={state.periodicities.map((p) => ({
                        value: p.id,
                        label: t(p.name),
                      }))}
                      value={state.dialogFilters.periodicities}
                      name="periodicities"
                      onChange={handleDialogFilterChange}
                      multiple
                      label={t("periodicities")}
                    />,
                    <CustomSelect
                      options={[
                        { value: "", label: t("all") },
                        { value: true, label: t("yes") },
                        { value: false, label: t("no") },
                      ]}
                      label={t("keyAccount")}
                      name="isKeyAccount"
                      onChange={handleDialogFilterChange}
                      value={state.dialogFilters.isKeyAccount}
                    />,
                  ]}
                />
              </Grid>
              <Grid item>
                <CustomButton
                  onClick={getPendingContracts}
                  loading={state.dialog.searchLoading}
                >
                  {t("search")}
                </CustomButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                sx={{ minWidth: "600px" }}
                autoHeight
                loading={state.dialog.searchLoading}
                columns={CONTRACTS_COLUMNS}
                rows={state.dialog.contracts}
                checkboxSelection
                disableSelectionOnClick
                selectionModel={state.dialog.selectedContracts}
                onSelectionModelChange={handleContractSelection}
              />
            </Grid>
          </Grid>
        }
        actions={
          <CustomButton
            loading={state.dialog.createAllLoading}
            onClick={handleCreate}
            disabled={state.dialog.selectedContracts.length == 0 ? true : false}
          >
            {t("create")}
          </CustomButton>
        }
      />

      <ConfirmDialog
        title={t("create")}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        confirmText={t("confirm")}
        cancelText={t("cancel")}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Page>
  );
}
