import {
  Box,
  Checkbox,
  Collapse,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useContext, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSnackbar } from "notistack";

import AppContext from "../../../context/AppContext";
import Button from "../../Inputs/CustomButton";
import CustomSelect from "../../Inputs/CustomSelect";
import CenterSelect from "../../Inputs/CenterSelect";
import CustomDate from "../../Inputs/CustomDate";
import ConfirmDialog from "../../ConfirmDialog";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import { localeFormat } from "../../../utils/format";
import ItemsSummary from "../../ItemsSummary";
import CustomButton from "../../Inputs/CustomButton";

const InvoiceRow = (props) => {
  const { invoice, onSelect, i, selected } = props;
  const [t] = useTranslation("invoices");
  // Variable to set if the details are displayed
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Checkbox
            value={i}
            invoice={invoice}
            onClick={onSelect}
            checked={selected}
          />
        </TableCell>
        <TableCell>{invoice.customer.fullName}</TableCell>
        <TableCell>{invoice.paymentMethod.name}</TableCell>
        <TableCell>{invoice.merchantables.length}</TableCell>
        <TableCell>{localeFormat(invoice.baseAmount)}€</TableCell>
        <TableCell>{localeFormat(invoice.vatAmount)}€</TableCell>
        <TableCell>{localeFormat(invoice.totalAmount)}€</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6">{t("details")}</Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("concept")}</TableCell>
                    <TableCell>{t("baseAmount")}</TableCell>
                    <TableCell>{t("vat")}(%)</TableCell>
                    <TableCell>{t("vat")}</TableCell>
                    <TableCell>{t("total")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice?.merchantables?.map((item) => (
                    <>
                      <TableRow>
                        <TableCell>{item.concept}</TableCell>
                        <TableCell>{localeFormat(item.baseAmount)}€</TableCell>
                        <TableCell>{item.vatPercentage}%</TableCell>
                        <TableCell>
                          {localeFormat(
                            (item.baseAmount * item.vatPercentage) / 100
                          )}
                          €
                        </TableCell>
                        <TableCell>{localeFormat(item.totalAmount)}€</TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const initialState = {
  selectedItems: [],
  selectedInvoices: [],
  tableColumns: [
    { field: "publicId", headerName: "id" },
    { field: "startDate" },
  ],
  customers: [],
  customerTypes: [],
  confirmDialog: {
    isOpen: false,
    childrenText: "",
  },
  filters: {
    customer: null,
    customerType: [],
    centers: [],
    date: "",
    isKeyAccount: "",
    paymentMethods: [],
    paymentDay: [],
  },
  invoicingTypes: [],
  issuers: [],
  invoices: [],
  paymentDays: [],
  paymentMethods: [],
  loading: false,
  submitLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ISSUERS":
      return { ...state, issuers: action.payload };
    case "SET_INVOICING_TYPES":
      return { ...state, invoicingTypes: action.payload };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_SELECTED_ITEMS":
      return { ...state, selectedItems: action.payload };
    case "SET_SELECTED_INVOICES":
      return { ...state, selectedInvoices: action.payload };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_SUBMIT_LOADING":
      return { ...state, submitLoading: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_CUSTOMER_TYPES":
      return { ...state, customerTypes: action.payload };
    case "SET_PAYMENT_DAYS":
      return { ...state, paymentDays: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          isOpen: action.payload.isOpen,
          childrenText: action.payload.childrenText,
        },
      };
    case "RESET":
      return {
        ...state,
        filters: initialState.filters,
        invoices: initialState.invoices,
        selectedItems: initialState.selectedItems,
        selectedInvoices: initialState.selectedInvoices,
      };
    default:
      throw new Error("Action type not found in reducer");
  }
};

const InvoicingPage = () => {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("invoices");
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialState);

  //Initial useEffect
  useEffect(() => {
    document.title = t("invoicingPage");

    getCustomers();
    getIssuers();
    getInvoicingTypes();
    getPaymentMethods();
    getCustomerTypes();
    getPaymentDays();
  }, []);

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const getIssuers = () => {
    api
      .get("/issuers")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_ISSUERS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoicingTypes = () => {
    api
      .get("/invoicing-types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_INVOICING_TYPES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCustomers = () => {
    api
      .get("/customers")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_CUSTOMERS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_PAYMENT_METHODS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCustomerTypes = () => {
    api
      .get("/customer-types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_CUSTOMER_TYPES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentDays = () => {
    api
      .get("/payment-days/get")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_PAYMENT_DAYS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPendingMerchantables = () => {
    dispatch({ type: "SET_INVOICES", payload: [] });
    dispatch({ type: "SET_LOADING", payload: true });
    const params = {};

    state.filters.date !== "" && (params.date = state.filters.date);

    state.filters.centers.length > 0 &&
      (params.centers = state.filters.centers);

    state.filters.paymentMethods.length > 0 &&
      (params.paymentMethods = state.filters.paymentMethods);

    state.filters.customer !== null &&
      (params.customer = state.filters.customer.id);

    state.filters.customerType !== null &&
      (params.customerTypeId = state.filters.customerType);

    state.filters.paymentDay !== null &&
      (params.paymentDay = state.filters.paymentDay);

    state.filters.isKeyAccount !== "" &&
      (params.isKeyAccount = state.filters.isKeyAccount);

    api
      .get("/invoicing/pending-merchantables", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_INVOICES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", payload: false });
      });
  };

  const invoiceMerchantables = () => {
    dispatch({ type: "SET_SUBMIT_LOADING", payload: true });

    let merchantableIds = state.selectedItems;

    api
      .post("/invoicing/invoice-merchantables", { merchantableIds })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "RESET" });
          enqueueSnackbar(t("invoicedSuccess"), { variant: "success" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_SUBMIT_LOADING", payload: false });
      });
  };

  const setSelectedItems = (e) => {
    let invoice = state.invoices[e.target.value];
    let merchantableIds = invoice.merchantables.map((m) => m.id);

    if (e.target.checked === true) {
      // If true, add merchantables
      dispatch({
        type: "SET_SELECTED_ITEMS",
        payload: [...state.selectedItems, ...merchantableIds],
      });

      // add invoice
      dispatch({
        type: "SET_SELECTED_INVOICES",
        payload: [...state.selectedInvoices, e.target.value],
      });
    } else {
      // If false, remove merchantables
      dispatch({
        type: "SET_SELECTED_ITEMS",
        payload: state.selectedItems.filter(
          (m) => !merchantableIds.includes(m)
        ),
      });

      dispatch({
        type: "SET_SELECTED_INVOICES",
        payload: state.selectedInvoices.filter((m) => m !== e.target.value),
      });
    }
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const openCreateConfirm = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        isOpen: true,
        childrenText: t("creatingInvoices") + ": " + state.selectedItems.length,
      },
    });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t("invoicing")}</Typography>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          {/*<Grid item>
            <CustomSelect
              label="invoicingMode"
              value={state.filters.invoicingMode}
              options={state.invoicingTypes.map((type) => ({
                value: type.id,
                label: type.name,
              }))}
              handleChange={handleFilterChange}
              name="invoicingMode"
            />
          </Grid>
          <Grid item>
            <CustomSelect
              label="issuer"
              value={state.filters.issuer}
              options={state.issuers.map((issuer) => ({
                value: issuer.id,
                label: issuer.name,
              }))}
              handleChange={handleFilterChange}
              name="issuer"
            />
            </Grid>*/}
          <Grid item xs={12} sm="auto">
            <Autocomplete
              options={state.customers}
              getOptionLabel={(customer) => customer.fullName || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextInput {...params} size="small" label={t("customer")} />
              )}
              value={state.filters.customer}
              onChange={(e, customer) => {
                handleFilterChange({
                  target: { value: customer, name: "customer" },
                });
              }}
              sx={{ width: 200 }}
              filterOptions={createFilterOptions({ limit: 10 })}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <CenterSelect
              name="centers"
              value={state.filters.centers}
              onChange={handleFilterChange}
              multiple
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <CustomSelect
              name="paymentMethods"
              label={t("paymentMethod")}
              value={state.filters.paymentMethods}
              options={state.paymentMethods.map((p) => ({
                value: p.id,
                label: t(p.name),
              }))}
              onChange={handleFilterChange}
              multiple
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <CustomDate
              name="date"
              value={state.filters.date}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <CustomSelect
              name="customerType"
              label={t("customerType")}
              value={state.filters.customerType}
              options={state.customerTypes.map((type) => ({
                value: type.id,
                label: t(type.name),
              }))}
              onChange={handleFilterChange}
              multiple
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <CustomSelect
              options={[
                { value: "", label: t("all") },
                { value: true, label: t("yes") },
                { value: false, label: t("no") },
              ]}
              label={t("keyAccount")}
              name="isKeyAccount"
              onChange={handleFilterChange}
              value={state.filters.isKeyAccount}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <CustomSelect
              name="paymentDay"
              label={t("paymentDay")}
              value={state.filters.paymentDay}
              options={state.paymentDays.map((day) => ({
                value: day.day,
                label: t(day.day),
              }))}
              onChange={handleFilterChange}
              multiple
            />
          </Grid>
          <Grid item>
            <SearchButton
              loading={state.loading}
              onClick={getPendingMerchantables}
              disabled={state.filters.date === ""}
            >
              {t("search")}
            </SearchButton>
          </Grid>
          <Grid item>
            <CustomButton
              loading={state.submitLoading}
              color="success"
              onClick={openCreateConfirm}
              disabled={state.selectedItems.length >= 1 ? false : true}
            >
              {t("createInvoices")}!
            </CustomButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ItemsSummary
            gridItems={[
              {
                translatedText: t("items"),
                value: state.invoices.length,
              },
              {
                translatedText: t("baseAmount"),
                value:
                  localeFormat(
                    state.invoices?.reduce(
                      (sum, item) => sum + item.baseAmount,
                      0
                    )
                  ) + "€",
              },
              {
                translatedText: t("taxes"),
                value:
                  localeFormat(
                    state.invoices?.reduce(
                      (sum, item) => sum + item.vatAmount,
                      0
                    )
                  ) + "€",
              },
              {
                translatedText: t("total"),
                value:
                  localeFormat(
                    state.invoices?.reduce(
                      (sum, item) => sum + item.totalAmount,
                      0
                    )
                  ) + "€",
              },
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Checkbox
                      onClick={(e) => {
                        if (e.target.checked === true) {
                          dispatch({
                            type: "SET_SELECTED_INVOICES",
                            payload: state.invoices.map((_, i) => i.toString()),
                          });
                          dispatch({
                            type: "SET_SELECTED_ITEMS",
                            payload: state.invoices
                              .map((i) => i.merchantables.map((m) => m.id))
                              .flat(1),
                          });
                        } else {
                          dispatch({ type: "SET_SELECTED_ITEMS", payload: [] });
                          dispatch({
                            type: "SET_SELECTED_INVOICES",
                            payload: [],
                          });
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>{t("customer")}</TableCell>
                  <TableCell>{t("paymentMethod")}</TableCell>
                  <TableCell>{t("numItems")}</TableCell>
                  <TableCell>{t("baseAmount")} (€)</TableCell>
                  <TableCell>{t("vat")} (€)</TableCell>
                  <TableCell>{t("total")} (€)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.invoices.map((a, index) => (
                  <InvoiceRow
                    key={a.id}
                    invoice={a}
                    onSelect={setSelectedItems}
                    selected={state.selectedInvoices.includes(index.toString())}
                    i={index}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* Confirm Dialog */}
      <ConfirmDialog
        title={t("createInvocesQuestion")}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={(confirmed) => {
          confirmed && invoiceMerchantables();
          resetConfirmDialog();
        }}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
};

export default InvoicingPage;
