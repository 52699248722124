// MUI COMPONENTS
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";

// REACT COMPONENTS
import { useEffect, useState } from "react";

// MUI ICONS
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// OTHERS
import { localeFormat } from "../../../utils/format";
import { useTranslation } from "react-i18next";
import useEditIcons from "../../../hooks/useEditIcons";
import TextInput from "../../Inputs/TextInput";
import BoxClustersTable from "./BoxClustersTable";

const lightGreyBorder = "1px solid #ccc";

const SAVE_ACTION_NAME = "save";
const CANCEL_ACTION_NAME = "cancel";

function BoxClusterAccordion({
  boxes,
  cluster,
  clusters,
  editCluster,
  deleteCluster,
  loading,
}) {
  const [clusterData, setClusterData] = useState({ ...cluster });
  const [t] = useTranslation("revenueManagement");

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    cluster && setClusterData({ ...cluster });
  }, [clusters, cluster]);

  const actionCallback = (actionName) => {
    if (actionName === CANCEL_ACTION_NAME) {
      setClusterData({ ...cluster });
    }
    if (actionName === SAVE_ACTION_NAME) {
      editCluster({
        ...cluster,
        minSize: clusterData.minSize,
        maxSize: clusterData.maxSize,
        price: clusterData.price,
      });
    }
  };

  const { edit, iconsRender } = useEditIcons(
    false,
    { title: t("editCluster") },
    { title: t("save") },
    { title: t("cancel") },
    actionCallback
  );

  const clusterBoxes =
    boxes && cluster
      ? boxes.filter(
          (box) =>
            box.meters >= cluster.minSize && box.meters <= cluster.maxSize
        )
      : [];

  return loading ? (
    <Skeleton variant="rectangular" height={100} />
  ) : (
    <Accordion
      style={{
        width: "100%",
        border: lightGreyBorder,
        borderRadius: "4px",
        margin: "0 auto",
      }}
      onChange={(e, expanded) => {
        setExpanded(expanded);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <Typography variant="body1" fontWeight="bold">
              {cluster?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            {edit ? (
              <TextInput
                label={t("minSize")}
                type="number"
                value={clusterData.minSize}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  setClusterData({ ...clusterData, minSize: e.target.value });
                }}
              />
            ) : (
              <Typography variant="body2" fontWeight="bold">
                {t("minSize") + ": "}
                <Typography
                  variant="body2"
                  fontWeight="normal"
                  component="span"
                >
                  {localeFormat(clusterData.minSize) + " m²"}
                </Typography>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            {edit ? (
              <TextInput
                label={t("maxSize")}
                type="number"
                value={clusterData.maxSize}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  setClusterData({ ...clusterData, maxSize: e.target.value });
                }}
              />
            ) : (
              <Typography variant="body2" fontWeight="bold">
                {t("maxSize") + ": "}
                <Typography
                  variant="body2"
                  fontWeight="normal"
                  component="span"
                >
                  {localeFormat(clusterData.maxSize) + " m²"}
                </Typography>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            {edit ? (
              <TextInput
                label={t("price")}
                type="number"
                value={clusterData.price}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  setClusterData({ ...clusterData, price: e.target.value });
                }}
              />
            ) : (
              <Typography variant="body2" fontWeight="bold">
                {t("price") + ": "}
                <Typography
                  variant="body2"
                  fontWeight="normal"
                  component="span"
                >
                  {localeFormat(clusterData.price) + " €"}
                </Typography>
              </Typography>
            )}
          </Grid>
          <Grid
            container
            item
            xs="auto"
            justifyContent="flex-end"
            style={{ flexGrow: 1 }}
          >
            {iconsRender}
            <Tooltip title={t("deleteCluster")}>
              <IconButton
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteCluster(cluster.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {expanded ? (
              <BoxClustersTable
                boxes={clusterBoxes}
                loading={loading}
                editable={false}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default BoxClusterAccordion;
