import { useTranslation } from "react-i18next";
import CustomSelect from "./CustomSelect";
import PropTypes, { oneOfType } from "prop-types";
import React, { useState } from "react";

/**
 * ClusterSelect Component
 *
 * This component is used to render a select input for choosing cluster types.
 * It provides predefined cluster options with associated labels and allows for single or multiple selections.
 *
 * @param {Object} props - The props for the ClusterSelect component.
 * @param {boolean} [props.multiple] - If true, allows multiple selections.
 * @param {Function} props.onChange - A callback function to handle changes in the selected value(s).
 * @param {string} [props.value] - The initial value of the select input.
 * @param {string} [props.label] - The label to display above the select input.
 * @returns {JSX.Element} - A React component for selecting cluster types.
 */

const ClusterSelect = (props) => {
  const { multiple, onChange, value, label, ...rest } = props;
  const [selectedCluster, setselectedCluster] = useState(value);
  const [t] = useTranslation("boxes");

  const clusterOptions = [
    { value: "Taquilla", label: t("locker") },
    { value: "Trastero Pequeño", label: t("smallBox") },
    { value: "Trastero Mediano", label: t("mediumBox") },
    { value: "Trastero Grande", label: t("largeBox") },
    { value: "Trastero XL", label: t("boxXl") },
    { value: "Almacén", label: t("warehouse") },
  ];

  return (
    <CustomSelect
      label={label || t("clusters")}
      multiple={multiple}
      sx={{ maxWidth: 230 }}
      value={selectedCluster}
      options={clusterOptions}
      onChange={(e) => {
        setselectedCluster(e.target.value);
        onChange && onChange(e);
      }}
      {...rest}
    />
  );
};

ClusterSelect.propTypes = {
  onChange: PropTypes.func,
  value: oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  helperText: PropTypes.string,
};

export default ClusterSelect;
