import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSnackbar } from "notistack";

// Material UI
import {
  Box,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

//Icons
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Components & Utils
import CityInput from "../Inputs/CityInput";
import LanguageSelect from "../Inputs/LanguageSelect";
import TextInput from "../Inputs/TextInput";
import i18n from "../../utils/i18n";
import { API_ROUTE } from "../../utils/API";
import CountryInput from "../Inputs/CountryInput";
import CustomDate from "../Inputs/CustomDate";
import {
  COMPANY_CUSTOMER_TYPE_ID,
  FEMALE_GENDER_ID,
  INDIVIDUAL_CUSTOMER_TYPE_ID,
  MALE_GENDER_ID,
  NIF_ID_TYPE,
  OTHER_GENDER_ID,
  PASSPORT_ID_TYPE,
  SPAIN_ALPHA2,
  SPAIN_COUNTRY_ID,
} from "../../data/constants";
import CustomButton from "../Inputs/CustomButton";
import PostalCodeInput from "../Inputs/PostalCodeInput";
import IdTypeSelect from "../Inputs/IdTypeSelect";

const initialState = {
  form: {
    address: "",
    birthdate: "",
    city: null,
    customerTypeId: INDIVIDUAL_CUSTOMER_TYPE_ID,
    email: "",
    gender: "",
    idType: NIF_ID_TYPE,
    name: "",
    nif: "",
    password: "",
    postalCode: "",
    repeatPassword: "",
    residenceCountry: {
      id: SPAIN_COUNTRY_ID,
      name: "Spain",
    },
    surnames: "",
    tinCountry: {
      id: SPAIN_COUNTRY_ID,
      name: "Spain",
      alpha2: SPAIN_ALPHA2,
    },
  },
  inputError: {
    address: false,
    birthdate: false,
    city: false,
    customerTypeId: false,
    email: false,
    gender: false,
    name: false,
    nif: false,
    password: false,
    postalCode: false,
    repeatPassword: false,
    residenceCountry: false,
    surnames: false,
    tinCountry: false,
  },
  showPassword: {
    password: false,
    repeatPassword: false,
  },
  submitFormLoading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_SHOW_PASSWORD":
      return {
        ...state,
        showPassword: {
          ...state.showPassword,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_SUBMIT_FORM_LOADING":
      return { ...state, submitFormLoading: action.payload };
    default:
      throw new Error("Action type not found in reducer");
  }
}

const RegisterPage = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("register");
  const [tErrors] = useTranslation("errors");
  const history = useHistory();

  useEffect(() => {
    if (
      state.form.password.length === 0 &&
      state.form.repeatPassword.length === 0
    ) {
      setInputError("repeatPassword", false);
      setInputError("password", false);
    }
  }, [state.form.password, state.form.repeatPassword]);

  useEffect(() => {
    if (state.form.email.length === 0) {
      setInputError("email", false);
    }
  }, [state.form.email]);

  /* BACKEND CALLS */

  const submitForm = () => {
    if (validateForm() === true) {
      dispatch({ type: "SET_SUBMIT_FORM_LOADING", payload: true });
      const form = state.form;

      let data = {};

      data.address = form.address;
      data.customerTypeId = Number(form.customerTypeId);
      data.email = form.email;
      data.idType = form.idType;
      data.name = form.name;
      data.nif = form.nif;
      data.password = form.password;
      data.residenceCountryId = form.residenceCountry.id;
      data.tinCountryId = form.tinCountry.id;

      if (Number(form.customerTypeId) === INDIVIDUAL_CUSTOMER_TYPE_ID) {
        data.surnames = form.surnames;
        data.birthdate = form.birthdate || null;
        data.gender = form.gender || null;
      }

      if (data.residenceCountryId === SPAIN_COUNTRY_ID) {
        data.cityId = form.city?.id || null;
        data.postalCodeNumber = form.postalCode;
      }

      axios
        .post(API_ROUTE + "/auth/register", data)
        .then((response) => {
          if (response.data.error) {
            console.error(response.data.msg);
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("registrationSuccess"), { variant: "success" });
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.message, { variant: "error" });
        })
        .finally(() => {
          dispatch({ type: "SET_SUBMIT_FORM_LOADING", payload: false });
        });
    }
  };

  /* HANDLERS */

  const handleInputChange = (e) => {
    if (state.inputError[e.target.name]) {
      setInputError(e.target.name, false);
    }
    if (e.target.name === "password") validatePassword(e.target.value);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleClickShowPassword = (name) => {
    dispatch({
      type: "SET_SHOW_PASSWORD",
      payload: {
        inputname: name,
        value: !state.showPassword[name],
      },
    });
  };

  const setInputError = (name, value) => {
    dispatch({
      type: "SET_INPUT_ERROR",
      payload: {
        inputname: name,
        value: value,
      },
    });
  };

  const setCustomerType = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "customerTypeId",
        value: e.target.value,
      },
    });
  };

  /* VALIDATORS */

  const validateForm = () => {
    let isValid = true;
    const form = state.form;

    let requiredFields = [
      "address",
      "tinCountry",
      "residenceCountry",
      "customerTypeId",
      "email",
      "idType",
      "name",
      "nif",
      "password",
      "repeatPassword",
    ];

    if (Number(form.customerTypeId) === INDIVIDUAL_CUSTOMER_TYPE_ID) {
      requiredFields.push("surnames");
    }

    requiredFields.forEach((field) => {
      if (field === "email" && !validateEmail(form[field])) {
        setInputError(field, true);
        isValid = false;
      } else if (!form[field]) {
        setInputError(field, true);
        isValid = false;
      }
    });

    if (!isValid) {
      enqueueSnackbar(t("pleaseFillHighlightedFields"), { variant: "error" });
    }
    if (
      state.form.password.length < 8 ||
      state.form.password.length > 32 ||
      state.form.password !== state.form.repeatPassword
    ) {
      isValid = false;
    }
    return isValid;
  };

  const validateEmail = (email) => {
    if (state.form.email !== "") {
      const re = /\S+@\S+\.\S+/; //Regex for testing email format
      if (!re.test(email)) {
        setInputError("email", true);
        return false;
      } else {
        setInputError("email", false);
        return true;
      }
    }
  };

  const validatePassword = (password) => {
    if (state.form.repeatPassword !== "") {
      if (password !== state.form.repeatPassword) {
        setInputError("repeatPassword", true);
      } else {
        setInputError("repeatPassword", false);
      }
    }
    if (password.length < 8 || password.length > 32) {
      setInputError("password", true);
      return false;
    } else {
      setInputError("password", false);
      return true;
    }
  };

  const validateRepeatPassword = (repeatPassword) => {
    if (repeatPassword !== state.form.password) {
      setInputError("repeatPassword", true);
      return false;
    } else {
      setInputError("repeatPassword", false);
      return true;
    }
  };

  const getIdTypeLabel = (idType) => {
    return idType?.toUpperCase() === PASSPORT_ID_TYPE
      ? t(idType)
      : idType?.toUpperCase();
  };

  return (
    <Container>
      <Grid
        container
        sx={{ minHeight: "100vh" }}
        paddingTop={2}
        paddingBottom={2}
        spacing={2}
      >
        <Grid
          container
          item
          xs={12}
          rowSpacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Link to="/">
              <Box
                component="img"
                src="/img/logonut.png"
                height="50px"
                width="270px"
                sx={{ objectFit: "cover", objectPosition: "50% 43%" }}
              />
            </Link>
          </Grid>
          <Grid item>
            <LanguageSelect defaultLang={i18n.language} />
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item xs={12} sm={9} md={6}>
            <Paper sx={{ padding: 3, marginBottom: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">{t("register")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    id="email"
                    name="email"
                    label={t("email")}
                    value={state.form.email}
                    onChange={handleInputChange}
                    onBlur={(e) => validateEmail(e.target.value)}
                    error={state.inputError.email}
                    helperText={
                      state.inputError.email ? t("emailHelperText") : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    id="password"
                    name="password"
                    label={t("password")}
                    type={state.showPassword.password ? "text" : "password"}
                    value={state.form.password}
                    onChange={handleInputChange}
                    error={state.inputError.password}
                    helperText={
                      state.inputError.password ? t("passwordHelperText") : ""
                    }
                    InputProps={{
                      endAdornment:
                        state.form.password === "" ? (
                          <InputAdornment position="end">
                            <LockIcon fontSize="small" />
                          </InputAdornment>
                        ) : (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickShowPassword("password")
                              }
                              size="small"
                            >
                              {state.showPassword.password ? (
                                <VisibilityIcon fontSize="small" />
                              ) : (
                                <VisibilityOffIcon fontSize="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    id="repeatPassword"
                    name="repeatPassword"
                    label={t("repeatPassword")}
                    type={
                      state.showPassword.repeatPassword ? "text" : "password"
                    }
                    value={state.form.repeatPassword}
                    onChange={handleInputChange}
                    onBlur={(e) => validateRepeatPassword(e.target.value)}
                    error={state.inputError.repeatPassword}
                    helperText={
                      state.inputError.repeatPassword
                        ? t("repeatPasswordHelperText")
                        : ""
                    }
                    InputProps={{
                      endAdornment:
                        state.form.repeatPassword === "" ? (
                          <InputAdornment position="end">
                            <LockIcon fontSize="small" />
                          </InputAdornment>
                        ) : (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickShowPassword("repeatPassword")
                              }
                              size="small"
                            >
                              {state.showPassword.repeatPassword ? (
                                <VisibilityIcon fontSize="small" />
                              ) : (
                                <VisibilityOffIcon fontSize="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item container textAlign="center">
                  <Grid item xs={12}>
                    <ToggleButtonGroup
                      exclusive
                      value={state.form.customerTypeId.toString()}
                      onChange={setCustomerType}
                    >
                      <ToggleButton
                        value={INDIVIDUAL_CUSTOMER_TYPE_ID.toString()}
                      >
                        {t("individual")}
                      </ToggleButton>
                      <ToggleButton value={COMPANY_CUSTOMER_TYPE_ID.toString()}>
                        {t("company")}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CountryInput
                    label={t("countryEmissionDocument")}
                    name="tinCountry"
                    onChange={handleInputChange}
                    value={state.form.tinCountry}
                    helperText={state.inputError.tinCountry}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <IdTypeSelect
                    idType={state.form.idType}
                    countryAlpha2={state.form.tinCountry?.alpha2}
                    handleInputChange={handleInputChange}
                    disabled={!state.form.tinCountry}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextInput
                    name="nif"
                    label={getIdTypeLabel(state.form.idType)}
                    value={state.form.nif}
                    onChange={handleInputChange}
                    error={state.inputError.nif}
                    disabled={!state.form.tinCountry}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={
                    Number(state.form.customerTypeId) ===
                    INDIVIDUAL_CUSTOMER_TYPE_ID
                      ? 4
                      : 12
                  }
                >
                  <TextInput
                    name="name"
                    label={t("name")}
                    value={state.form.name}
                    onChange={handleInputChange}
                    error={state.inputError.name}
                    required
                  />
                </Grid>
                {Number(state.form.customerTypeId) ===
                  INDIVIDUAL_CUSTOMER_TYPE_ID && (
                  <Grid item xs={12} sm={8}>
                    <TextInput
                      name="surnames"
                      label={t("surnames")}
                      value={state.form.surnames}
                      onChange={handleInputChange}
                      error={state.inputError.surnames}
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <CountryInput
                    name="residenceCountry"
                    label={t("residenceCountry")}
                    value={state.form.residenceCountry}
                    onChange={handleInputChange}
                    helperText={state.inputError.residenceCountry}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    name="address"
                    label={t("address")}
                    value={state.form.address}
                    onChange={handleInputChange}
                    error={state.inputError.address}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CityInput
                    name="city"
                    label={t("city")}
                    value={state.form.city}
                    onChange={handleInputChange}
                    helperText={state.inputError.city}
                    disabled={
                      state.form.residenceCountry?.id !== SPAIN_COUNTRY_ID
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <PostalCodeInput
                    name="postalCode"
                    label={t("postalCode")}
                    value={state.form.postalCode}
                    onChange={handleInputChange}
                    disabled={
                      state.form.residenceCountry?.id !== SPAIN_COUNTRY_ID
                    }
                  />
                </Grid>
                {Number(state.form.customerTypeId) ===
                  INDIVIDUAL_CUSTOMER_TYPE_ID && (
                  <Grid item xs={12}>
                    <CustomDate
                      name="birthdate"
                      label={t("birthdate")}
                      value={state.form.birthdate}
                      onChange={handleInputChange}
                      error={state.inputError.birthdate}
                      disabled={!state.form.residenceCountry}
                    />
                  </Grid>
                )}
                {Number(state.form.customerTypeId) ===
                  INDIVIDUAL_CUSTOMER_TYPE_ID && (
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>{t("gender")}</FormLabel>
                      <RadioGroup
                        value={state.form.gender}
                        name="gender"
                        row
                        onChange={handleInputChange}
                      >
                        <FormControlLabel
                          name="gender"
                          value={MALE_GENDER_ID}
                          control={<Radio />}
                          label={t("male")}
                        />
                        <FormControlLabel
                          name="gender"
                          value={FEMALE_GENDER_ID}
                          control={<Radio />}
                          label={t("female")}
                        />
                        <FormControlLabel
                          name="gender"
                          value={OTHER_GENDER_ID}
                          control={<Radio />}
                          label={t("other")}
                        />
                        <FormControlLabel
                          name="gender"
                          value=""
                          control={<Radio />}
                          label={t("ratherNotSay")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12} sm={8}>
                  <Typography variant="caption" display="block">
                    {t("alreadyHaveAccountQuestion")}{" "}
                    <Link to="/login">{t("logIn") + "!"}</Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomButton
                    onClick={submitForm}
                    loading={state.submitFormLoading}
                    fullWidth
                  >
                    {t("register")}
                  </CustomButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RegisterPage;
