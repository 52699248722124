import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useSnackbar } from "notistack";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import AppContext from "../../../context/AppContext";
import CustomSelect from "../../Inputs/CustomSelect";
import Dialog from "../../global/Dialog";
import EditIcon from "@mui/icons-material/Edit";
import EmailTextEditor from "./EmailTextEditor";
import TextInput from "../../Inputs/TextInput";

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_DIALOG_MODE":
      return { ...state, dialogMode: action.payload };
    case "SET_FORM":
      return { ...state, form: action.payload };
    case "OPEN_DIALOG":
      return { ...state, dialogIsOpen: true };
    case "CLOSE_DIALOG":
      return { ...state, dialogIsOpen: false };
    default:
      throw new Error("Action not found in reducer");
  }
}

const initialState = {
  deleteId: "",
  dialogIsOpen: false,
  dialogMode: "create",
  form: {
    html: "",
    name: "",
    subject: "",
    text: "",
    typeId: "",
  },
};

export default function EmailTemplateList({
  createTemplate,
  deleteTemplate,
  emailTemplateTypes,
  modifyTemplate,
  modifyTemplateTypes,
  modifySenderEmail,
  emailsAccounts,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("settings");

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const openDialog = (typeId) => {
    dispatch({ type: "OPEN_DIALOG" });
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "typeId", value: typeId },
    });
  };

  const closeDialog = () => {
    dispatch({ type: "CLOSE_DIALOG" });
  };

  const handleTemplateChange = (html, text) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "html",
        value: html,
      },
    });

    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "text",
        value: text,
      },
    });
  };

  const changeDefaultTemplate = (type, defaultTemplateId) => {
    const form = { defaultTemplateId: defaultTemplateId };

    api
      .post("/email-template-types/" + type.id, form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("editTempleteSuccess"), { variant: "success" });
          modifyTemplateTypes(type.id, defaultTemplateId);
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const changeDefaultSenderEmail = (type, senderEmailId) => {
    const form = { senderEmail: senderEmailId };

    api
      .post("/email-template-types/" + type.id, form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("changeDefaultSenderEmailSuccess"), {
            variant: "success",
          });
          modifySenderEmail(type.id, senderEmailId);
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const checkDefault = (emailTemplateType, template) => {
    if (emailTemplateType.defaultTemplateId === template.id)
      enqueueSnackbar(t("noDeleteDefault"), { variant: "error" });
    else deleteTemplate(template);
  };

  return (
    <>
      <List>
        {emailTemplateTypes?.map((type) => (
          <ListItem key={type.id}>
            <Container>
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography
                    variant="h6"
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                  >
                    {type.name}
                  </Typography>
                </Grid>
                <Grid item flex={1} justifyContent="flex-end" display="flex">
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => {
                      dispatch({
                        type: "SET_FORM",
                        payload: initialState.form,
                      });
                      dispatch({ type: "SET_DIALOG_MODE", payload: "create" });
                      openDialog(type.id);
                    }}
                  >
                    {t("createNewTemplate")}
                  </Button>
                </Grid>
              </Grid>

              <Grid container item xs={12} spacing={1} marginTop={1}>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    label={t("defaultTemplate")}
                    value={type.defaultTemplateId}
                    onChange={(e) => {
                      changeDefaultTemplate(type, e.target.value);
                    }}
                    options={[
                      { value: null, label: t("none") },
                      ...type.EmailTemplates.map((template) => ({
                        label: template.name,
                        value: template.id,
                      })),
                    ]}
                    shrink={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    label={t("defaultEmail")}
                    value={type.senderEmail}
                    onChange={(e) => {
                      changeDefaultSenderEmail(type, e.target.value);
                    }}
                    options={[
                      { value: null, label: t("none") },
                      ...emailsAccounts.map((email) => ({
                        label: email.user,
                        value: email.id,
                      })),
                    ]}
                    shrink={true}
                  />
                </Grid>
              </Grid>

              <Box maxHeight="200px" item overflow="auto">
                {type.EmailTemplates.map((template) => (
                  <List key={template.id}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid item container xs={12}>
                          <Grid item>
                            <Typography
                              variant="body"
                              sx={{ fontWeight: "bold" }}
                            >
                              {t("name") + ": "}
                            </Typography>
                            <Typography variant="body">
                              {template.name}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            flex={1}
                            alignItems="flex-start"
                            justifyContent="flex-end"
                            display="flex"
                          >
                            <IconButton
                              onClick={() => {
                                dispatch({
                                  type: "SET_DIALOG_MODE",
                                  payload: "edit",
                                });
                                dispatch({
                                  type: "SET_FORM",
                                  payload: template,
                                });
                                openDialog(type.id);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => checkDefault(type, template)}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </List>
                ))}
              </Box>
              <Grid item sx={{ my: 2 }} xs={12}>
                <Divider />
              </Grid>
            </Container>
          </ListItem>
        ))}
      </List>

      {/* Create/edit dialog */}
      <Dialog
        open={state.dialogIsOpen}
        onClose={closeDialog}
        title={t("addTemplate")}
        maxWidth="md"
        children={
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextInput
                onChange={handleInputChange}
                name="name"
                label={t("name")}
                value={state.form.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                onChange={handleInputChange}
                name="subject"
                label={t("subject")}
                value={state.form.subject}
              />
            </Grid>
            <Grid item>
              <EmailTextEditor
                onChange={handleTemplateChange}
                html={state.form.html}
                contractTypeId={state.form.typeId}
              />
            </Grid>
          </Grid>
        }
        actions={
          <Button
            variant="contained"
            onClick={() => {
              if (state.dialogMode === "create") {
                createTemplate(state.form);
              } else {
                modifyTemplate(state.form);
              }
              closeDialog();
            }}
          >
            {state.dialogMode === "create" && t("create")}
            {state.dialogMode === "edit" && t("save")}
          </Button>
        }
      />
    </>
  );
}
