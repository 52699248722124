import AppContext from "../../context/AppContext";

import { useEffect, useState, useContext } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import PropTypes, { oneOfType } from "prop-types";

import CustomSelect from "./CustomSelect";

const PromotionSelect = (props) => {
  const [t] = useTranslation("contracts");
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const { filters, options, onChange, value, label, ...rest } = props;
  const [promotions, setPromotions] = useState(options ?? []);
  const [selectedPromotion, setSelectedPromotion] = useState(value || "");

  useEffect(() => {
    options === undefined && getPromotions();
  }, []);

  useEffect(() => {
    setPromotions(options ?? []);
  }, [options]);

  const getPromotions = () => {
    api
      .get("/promotions")
      .then((res) => {
        if (res.data.error)
          enqueueSnackbar(res.data.error, { variant: "error" });
        else setPromotions(res.data);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  return (
    <CustomSelect
      name="promotionCode"
      label={label || t("promotions")}
      value={selectedPromotion}
      options={promotions.map((p) => ({
        value: p.code,
        label: p.name + " - " + p.code,
      }))}
      onChange={(e) => {
        setSelectedPromotion(e.target.value);
        onChange && onChange(e);
      }}
      {...rest}
    />
  );
};

PromotionSelect.propTypes = {
  onChange: PropTypes.func,
  value: oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  helperText: PropTypes.string,
};

export default PromotionSelect;
