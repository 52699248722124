import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Container,
  IconButton,
  Grid,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import AppContext from "../../../context/AppContext";
import React, { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useLocation, useHistory } from "react-router-dom";
import { useRef } from "react";
import { getParams, generateURL } from "../../../utils/url";

import DownloadIcon from "@mui/icons-material/Download";

import ButtonCSV from "../../Inputs/ButtonCSV";
import ButtonLink from "../../Inputs/ButtonLink";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import ItemsSummary from "../../ItemsSummary";
import { CustomTable } from "../../CustomTable";
import { localeFormat } from "../../../utils/format";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      component={Paper}
      square
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialState = {
  transferAccounts: [],
  transfers: [],
  transferGroups: [],
  options: {
    loaded: true,
  },
  transferencesFilters: {
    tab: 1,
    search: "",
    debtorIBAN: "",
    fromDate: "",
    untilDate: "",
    importMin: "",
    importMax: "",
  },
  groupsFilters: {
    tab: 0,
    fromDate: "",
    debtorIBAN: "",
    untilDate: "",
    importMin: "",
    importMax: "",
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_TRANSFER_ACCOUNTS":
      return { ...state, transferAccounts: action.payload };
    case "SET_TRANSFERS":
      return { ...state, transfers: action.payload };
    case "SET_TRANSFER_GROUPS":
      return { ...state, transferGroups: action.payload };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_TRANSFERENCES_FILTER":
      return {
        ...state,
        transferencesFilters: {
          ...state.transferencesFilters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_GROUPS_FILTER":
      return {
        ...state,
        groupsFilters: {
          ...state.groupsFilters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_GROUPS":
      return {
        ...state,
        groupsFilters: initialState.groupsFilters,
        transferGroups: [],
      };
    case "RESET_TRANSFERS":
      return {
        ...state,
        transferencesFilters: initialState.transferencesFilters,
        transfers: [],
      };
    default:
      throw new Error();
  }
}

const ActionsCell = ({ item }) => {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("transferPayments");
  const [tErrors] = useTranslation("errors");
  const { enqueueSnackbar } = useSnackbar();

  const downloadXml = (transferGroupId) => {
    api
      .get(`/transfer-payment-groups/${transferGroupId}/xml`)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: "application/xml",
          });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          const a = document.createElement("a");
          a.href = fileURL;
          a.download = fileURL.split("/").pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <ButtonGroup>
      <Tooltip title={t("downloadXML")} placement="top">
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            downloadXml(item.id);
          }}
          color="primary"
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    </ButtonGroup>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function TransferPaymentsPage() {
  const { api } = useContext(AppContext);
  const [t] = useTranslation("transferPayments");
  const [tErrors] = useTranslation("errors");
  const history = useHistory();
  const [currentTab, setTab] = React.useState(0); //<- Tabs
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();

  let params = getParams(new URLSearchParams(useLocation().search), [
    { name: "tab", type: "number" },
  ]);

  let filters;
  let target;

  if (params.tab === "1") {
    filters = [
      { name: "tab", type: "number" },
      { name: "debtorIBAN", type: "number" },
      { name: "fromDate", type: "string" },
      { name: "untilDate", type: "string" },
      { name: "importMin", type: "number" },
      { name: "importMax", type: "number" },
    ];
    target = "transferencesFilters";
  } else {
    filters = [
      { name: "tab", type: "number" },
      { name: "search", type: "string" },
      { name: "debtorIBAN", type: "number" },
      { name: "fromDate", type: "string" },
      { name: "untilDate", type: "string" },
      { name: "importMin", type: "number" },
      { name: "importMax", type: "number" },
    ];
    target = "groupsFilters";
  }

  const initState = (state) => ({
    ...state,
    [target]: { ...state.groupsFilters, ...getParams(query, filters) },
  });
  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const TRANSFER_COLUMNS = [
    { key: "concept", label: t("concept"), sortType: "string" },
    {
      key: "amount",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    { key: "signatureDate", label: t("signatureDate"), sortType: "string" },
    { key: "creditorIBAN", label: t("creditorIBAN"), sortType: "string" },
    { key: "creditorName", label: t("businessName"), sortType: "string" },
    {
      key: "ProviderInvoice.title",
      label: t("providerInvoice"),
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink
          to={"/app/provider-invoice/" + item.providerInvoiceId}
          size="small"
        >
          {value || t("invoice")}
        </ButtonLink>
      ),
    },
    {
      key: "ProviderInvoice.invoiceNumber",
      label: t("invoiceNumber"),
      sortType: "string",
    },
  ];

  const GROUPS_COLUMNS = [
    { key: "date", label: t("date"), sortType: "string" },
    { key: "debtorName", label: t("debtorName"), sortType: "string" },
    { key: "debtorNIF", label: t("debtorNIF"), sortType: "string" },
    { key: "debtorIBAN", label: t("debtorIBAN"), sortType: "string" },
    {
      key: "numberOfTransfers",
      label: t("numberOfTransfers"),
      sortType: "number",
    },
    {
      key: "amount",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "actions",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) => <ActionsCell item={item} />,
    },
  ];

  const CSV_GROUPS = [
    { key: "date", label: t("date") },
    { key: "debtorName", label: t("debtorName") },
    { key: "debtorNIF", label: t("debtorNIF") },
    { key: "debtorIBAN", label: t("debtorIBAN") },
    { key: "numberOfTransfers", label: t("numberOfTransfers") },
    { key: "amount", label: t("amount") },
  ];

  const GROUP_SUMMARY = [
    {
      translatedText: t("numberOfReceipts"),
      value: localeFormat(state.transferGroups.length),
    },
    {
      translatedText: t("numberOfTransfers"),
      value: localeFormat(
        state.transferGroups?.length > 0
          ? state.transferGroups.reduce(
              (total, element) => total + element.numberOfTransfers,
              0
            )
          : 0
      ),
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          state.transferGroups?.length > 0
            ? state.transferGroups.reduce(
                (total, element) => total + element.amount,
                0
              )
            : 0
        ) + "€",
    },
  ];

  const TRANSFERENCES_SUMMARY = [
    {
      translatedText: t("numberOfTransfers"),
      value: localeFormat(state.transfers.length),
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          state.transfers?.length > 0
            ? state.transfers.reduce(
                (total, element) => total + element.amount,
                0
              )
            : 0
        ) + "€",
    },
  ];

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);

    let url;
    newTab === 0
      ? (url = generateURL("/app/transfer-payments", state.groupsFilters))
      : (url = generateURL(
          "/app/transfer-payments",
          state.transferencesFilters
        ));
    history.push(url);
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("transferPayments");
    params.tab !== undefined ? setTab(Number(params.tab)) : setTab(0);

    getTransferAccounts();
  }, []);

  const getTransferPayments = () => {
    dispatch({ type: "SET_LOADED_FALSE" });

    const params = { include: ["ProviderInvoice"] };

    state.transferencesFilters.debtorIBAN != "" &&
      (params.debtorIBAN = state.transferencesFilters.debtorIBAN);
    state.transferencesFilters.search != "" &&
      (params.search = state.transferencesFilters.search);
    state.transferencesFilters.fromDate != "" &&
      (params.fromDate = state.transferencesFilters.fromDate);
    state.transferencesFilters.untilDate != "" &&
      (params.untilDate = state.transferencesFilters.untilDate);
    state.transferencesFilters.importMax != "" &&
      (params.importMax = state.transferencesFilters.importMax);
    state.transferencesFilters.importMin != "" &&
      (params.importMin = state.transferencesFilters.importMin);

    api
      .get("/transfer-payments", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noResults"), { variant: "warning" });
          dispatch({ type: "SET_TRANSFERS", payload: response.data });
          const url = generateURL(
            "/app/transfer-payments",
            state.transferencesFilters
          );
          history.push(url);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getTransferPaymentGroups = () => {
    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      include: ["TransferPayment"],
    };

    state.groupsFilters.debtorIBAN != "" &&
      (params.debtorIBAN = state.groupsFilters.debtorIBAN);
    state.groupsFilters.fromDate != "" &&
      (params.dateFrom = state.groupsFilters.fromDate);
    state.groupsFilters.untilDate != "" &&
      (params.dateUntil = state.groupsFilters.untilDate);
    state.groupsFilters.importMax != "" &&
      (params.importMax = state.groupsFilters.importMax);
    state.groupsFilters.importMin != "" &&
      (params.importMin = state.groupsFilters.importMin);

    api
      .get("/transfer-payment-groups", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noResults"), { variant: "warning" });
          response.data.forEach((transferGroup) => {
            transferGroup.numberOfTransfers =
              transferGroup.TransferPayments?.length;
            transferGroup.amount = Number(transferGroup?.amount?.toFixed(2));
          });
          dispatch({ type: "SET_TRANSFER_GROUPS", payload: response.data });
          const url = generateURL(
            "/app/transfer-payments",
            state.groupsFilters
          );
          history.push(url);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  const handleTransferencesFilterChange = (e) => {
    dispatch({
      type: "SET_TRANSFERENCES_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleGroupsFilterChange = (e) => {
    dispatch({
      type: "SET_GROUPS_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const getTransferAccounts = () => {
    api
      .get("/transfer-accounts")
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          dispatch({
            type: "SET_TRANSFER_ACCOUNTS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t("transferPayments")}</Typography>
        </Grid>
      </Grid>
      <AppBar position="static" sx={{ marginTop: 1 }}>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          textColor="inherit"
          indicatorColor="secondary"
        >
          <Tab label={t("transferGroups")} {...a11yProps(0)} />
          <Tab label={t("transferences")} {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      {/*Transfer group tab*/}
      <TabPanel value={currentTab} index={0}>
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item>
              <CustomDate
                name="fromDate"
                value={state.groupsFilters.fromDate}
                onChange={handleGroupsFilterChange}
                label={t("fromDate")}
              />
            </Grid>
            <Grid item>
              <CustomDate
                name="untilDate"
                value={state.groupsFilters.untilDate}
                onChange={handleGroupsFilterChange}
                label={t("untilDate")}
              />
            </Grid>
            <Grid item>
              <TextInput
                value={state.groupsFilters.importMin}
                name="importMin"
                onChange={handleGroupsFilterChange}
                label={t("importMin")}
                type="number"
              />
            </Grid>
            <Grid item>
              <TextInput
                value={state.groupsFilters.importMax}
                name="importMax"
                onChange={handleGroupsFilterChange}
                label={t("importMax")}
                type="number"
              />
            </Grid>
            <Grid item>
              <CustomSelect
                label={t("transferAccount")}
                name="debtorIBAN"
                value={state.groupsFilters.debtorIBAN}
                onChange={handleGroupsFilterChange}
                options={[
                  { value: "", label: t("none") },
                  ...state.transferAccounts.map((account) => ({
                    value: account.IBAN,
                    label: account.accountName + " - " + account.IBAN,
                  })),
                ]}
              />
            </Grid>
            <Grid item>
              <ButtonGroup variant="contained">
                <Button onClick={() => dispatch({ type: "RESET_GROUPS" })}>
                  {t("reset")}
                </Button>
                <ButtonCSV
                  data={state.transferGroups}
                  headers={CSV_GROUPS}
                  filename={t("transferGroups")}
                />
                <SearchButton onClick={getTransferPaymentGroups} />
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <ItemsSummary gridItems={GROUP_SUMMARY} />
            </Grid>
            <Grid item xs={12}>
              <CustomTable
                columns={GROUPS_COLUMNS}
                data={state.transferGroups}
                options={{
                  loaded: state.options.loaded,
                  rowlink: "transfer-payment-group",
                }}
              ></CustomTable>
            </Grid>
          </Grid>
        </Container>
      </TabPanel>

      {/*Transfer tab*/}
      <TabPanel value={currentTab} index={1}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item container spacing={1} xs={12}>
              <Grid item>
                <TextInput
                  name="search"
                  value={state.transferencesFilters.search}
                  label={t("search")}
                  onChange={(e) => {
                    handleTransferencesFilterChange(e);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      getTransferPayments();
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <CustomDate
                  name="fromDate"
                  value={state.transferencesFilters.fromDate}
                  onChange={handleTransferencesFilterChange}
                  label={t("fromDate")}
                />
              </Grid>
              <Grid item>
                <CustomDate
                  name="untilDate"
                  value={state.transferencesFilters.untilDate}
                  onChange={handleTransferencesFilterChange}
                  label={t("untilDate")}
                />
              </Grid>
              <Grid item>
                <TextInput
                  value={state.transferencesFilters.importMin}
                  name="importMin"
                  onChange={handleTransferencesFilterChange}
                  label={t("importMin")}
                  type="number"
                />
              </Grid>

              <Grid item>
                <TextInput
                  value={state.transferencesFilters.importMax}
                  name="importMax"
                  onChange={handleTransferencesFilterChange}
                  label={t("importMax")}
                  type="number"
                />
              </Grid>
              <Grid item>
                <CustomSelect
                  label={t("transferAccount")}
                  name="debtorIBAN"
                  value={state.transferencesFilters.debtorIBAN}
                  onChange={handleTransferencesFilterChange}
                  options={[
                    { value: "", label: t("none") },
                    ...state.transferAccounts.map((account) => ({
                      value: account.IBAN,
                      label: account.accountName + " - " + account.IBAN,
                    })),
                  ]}
                />
              </Grid>
              <Grid item>
                <ButtonGroup variant="contained">
                  <Button onClick={() => dispatch({ type: "RESET_TRANSFERS" })}>
                    {t("reset")}
                  </Button>
                  <ButtonCSV
                    data={state.transfers}
                    headers={TRANSFER_COLUMNS}
                    filename={t("transferPayments")}
                  />
                  <SearchButton onClick={getTransferPayments} />
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ItemsSummary gridItems={TRANSFERENCES_SUMMARY} />
            </Grid>
            <Grid item xs={12}>
              <CustomTable
                columns={TRANSFER_COLUMNS}
                data={state.transfers}
                options={state.options}
              />
            </Grid>
          </Grid>
        </Container>
      </TabPanel>
    </Container>
  );
}
