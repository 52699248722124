import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useTheme } from "@emotion/react";

import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import AcquisitionsMap from "./AcquisitionsMap";
import AcquisitionsTracker from "./AcquisitionsTracker";
import AppContext from "../../../context/AppContext";
import ButtonCSV from "../../Inputs/ButtonCSV";
import ButtonLink from "../../Inputs/ButtonLink";
import CreateButton from "../../Inputs/CreateButton";
import CustomSelect from "../../Inputs/CustomSelect";
import CityInput from "../../Inputs/CityInput";
import ItemsSummary from "../../ItemsSummary";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import ConfirmDialog from "../../ConfirmDialog";

import { localeFormat } from "../../../utils/format";
import { useHistory, useLocation } from "react-router-dom";
import { generateURL, getParams } from "../../../utils/url";
import { DataGrid } from "@mui/x-data-grid";
import { get } from "lodash";
import { CustomTable } from "../../CustomTable";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function reducer(state, action) {
  switch (action.type) {
    case "SET_ACQUISITIONS":
      return { ...state, acquisitionAssets: action.payload };
    case "SET_ACQUISITION_COSTS":
      return { ...state, acquisitionCosts: action.payload };
    case "SET_SUMMARY_COSTS":
      return { ...state, costsSummary: action.payload };
    case "SET_CURRENT_TAB":
      return { ...state, currentTab: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "SET_CITY_FILTER":
      return { ...state, city: action.payload };
    default:
      throw new Error("Action not found in reducer.");
  }
}

const initialState = {
  acquisitionAssets: [],
  acquisitionCosts: [],
  city: "",
  costsSummary: [],
  currentTab: 0,
  filters: {
    cityId: "",
    priority: [],
    search: "",
    source: "",
  },
  options: {
    loaded: false,
  },
};

export default function AcquisitionsAssetsPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("acquisitions");
  const [tErrors] = useTranslation("errors");
  const history = useHistory();
  const query = useQuery();
  const theme = useTheme();

  const filters = [
    { name: "priority", type: "array" },
    { name: "search", type: "string" },
    { name: "cityId", type: "number" },
    { name: "source", type: "string" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, filters) },
    currentTab: parseInt(query.get("tab") ?? state.currentTab),
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const priorityValue = {
    0: t("underExclusivity"),
    1: t("active"),
    2: t("onHold"),
    3: t("declined"),
  };

  const ACQUISITION_ITEMS_SUMMARY = [
    {
      translatedText: t("assets"),
      value: state.acquisitionAssets?.length,
    },
    {
      translatedText: t("totalAssetsValue"),
      value:
        localeFormat(
          state.acquisitionAssets?.reduce(
            (value, element) =>
              value + (element.acquisitionPrice ? element.acquisitionPrice : 0),
            0
          )
        ).toString() + " €",
    },
    {
      translatedText: t("lettableArea"),
      value:
        localeFormat(
          state.acquisitionAssets?.reduce(
            (value, element) =>
              value + (element.lettableArea ? element.lettableArea : 0),
            0
          )
        ).toString() + " m²",
    },
    {
      translatedText: t("averageYield"),
      value:
        state.acquisitionAssets.length !== 0
          ? localeFormat(
              (state.acquisitionAssets?.reduce((value, element) => {
                return value + (element.NOI ? element.NOI : 0);
              }, 0) /
                state.acquisitionAssets?.reduce(
                  (value, element) => value + (element.AIC ? element.AIC : 0),
                  0
                )) *
                100
            ).toString() + " %"
          : 0 + "€",
    },
    {
      translatedText: t("pricePerMeter"),
      value:
        state.acquisitionAssets.length !== 0
          ? localeFormat(
              state.acquisitionAssets?.reduce(
                (value, element) =>
                  value +
                  (element.acquisitionPrice ? element.acquisitionPrice : 0),
                0
              ) /
                state.acquisitionAssets?.reduce(
                  (value, element) =>
                    value + (element.lettableArea ? element.lettableArea : 0),
                  0
                )
            ).toString() + " €"
          : 0 + "€",
    },
    {
      translatedText: t("totalCapex"),
      value:
        state.acquisitionAssets.length !== 0
          ? localeFormat(
              state.acquisitionAssets?.reduce(
                (value, element) =>
                  value + (element.capexCosts ? element.capexCosts : 0),
                0
              )
            ).toString() + " €"
          : 0 + "€",
    },
    {
      translatedText: t("acquisitionCosts"),
      value:
        state.acquisitionAssets.length !== 0
          ? localeFormat(
              state.acquisitionAssets?.reduce(
                (value, element) =>
                  value +
                  (element.acquisitionCost ? element.acquisitionCost : 0),
                0
              )
            ).toString() + " €"
          : 0 + "€",
    },
  ];

  const ACQUISITION_COLUMNS = [
    {
      field: "id",
      headerName: t("actions"),
      renderCell: (params) => (
        <ButtonGroup>
          <Tooltip title={t("edit")} placement="top">
            <IconButton
              to={`/app/acquisition/${params.row.id}`}
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/app/acquisition/${params.row.id}`);
              }}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ),
      minWidth: 100,
    },
    {
      field: "priority",
      headerName: t("priority"),
      valueFormatter: ({ value }) => priorityValue[value],
      minWidth: 125,
    },
    { field: "name", headerName: t("name"), minWidth: 200 },
    {
      field: "cityName",
      headerName: t("city"),
      valueGetter: (params) => get(params.row, "City.name"),
      minWidth: 200,
    },
    { field: "address", headerName: t("address"), minWidth: 200 },
    { field: "source", headerName: t("source"), minWidth: 150 },
    {
      field: "aditionalConstructability",
      headerName: t("aditionalConstructability"),
      valueFormatter: ({ value }) => localeFormat(value) + " m²",
      minWidth: 150,
    },
    {
      field: "currentArea",
      headerName: t("currentArea"),
      valueFormatter: ({ value }) => localeFormat(value) + " m²",
      minWidth: 150,
    },
    {
      field: "grossInternalArea",
      headerName: t("gia") + " (m²)",
      valueFormatter: ({ value }) => localeFormat(value) + " m²",
      minWidth: 150,
    },
    {
      field: "lettableAreaRatio",
      headerName: t("lettableRatio") + " (%)",
      valueFormatter: ({ value }) => localeFormat(value) + " %",
      minWidth: 150,
    },
    {
      field: "lettableArea",
      headerName: t("lettableArea") + " (m²)",
      valueFormatter: ({ value }) => localeFormat(value) + " m²",
      minWidth: 150,
    },
    {
      field: "acquisitionPrice",
      headerName: t("acquisitionPrice"),
      valueFormatter: ({ value }) => localeFormat(value) + " €",
      minWidth: 150,
    },
    {
      field: "capexCostSqm",
      headerName: t("capexCost") + " (€)",
      valueFormatter: ({ value }) => localeFormat(value) + " €",
      minWidth: 150,
    },
    {
      field: "capexCosts",
      headerName: t("capexCosts"),
      valueFormatter: ({ value }) => localeFormat(value) + " €",
      minWidth: 150,
    },
    {
      field: "occupancy",
      headerName: t("occupancy") + " (%)",
      valueFormatter: ({ value }) => localeFormat(value) + " %",
      minWidth: 150,
    },
    {
      field: "operatingCostsSqm",
      headerName: t("operatingCosts") + "/m2",
      valueFormatter: ({ value }) => localeFormat(value) + " €",
      minWidth: 150,
    },
    {
      field: "operatingCosts",
      headerName: t("operatingCosts"),
      valueFormatter: ({ value }) => localeFormat(value) + " €",
      minWidth: 150,
    },
    {
      field: "acquisitionCost",
      headerName: t("acquisitionCosts"),
      valueFormatter: ({ value }) => localeFormat(value) + " €",
      minWidth: 150,
    },
    {
      field: "grossYield",
      headerName: t("grossYield"),
      valueFormatter: ({ value }) => localeFormat(value) + " %",
      minWidth: 150,
    },
  ];

  const CSV_HEADERS = [
    { key: "priority", label: t("priority") },
    { key: "name", label: t("name") },
    { key: "address", label: t("address") },
    { key: "City.name", label: t("city") },
    { key: "source", label: t("source") },
    { key: "aditionalConstructability", label: t("aditionalConstructability") },
    { key: "currentArea", label: t("currentArea") },
    { key: "grossInternalArea", label: t("gia") },
    { key: "lettableAreaRatio", label: t("lettableArea") + " (%)" },
    { key: "lettableArea", label: t("lettableArea") + " (m2)" },
    { key: "acquisitionPrice", label: t("acquisitionPrice") },
    { key: "capexCosts", label: t("capexCosts") + "/m2" },
    { key: "capexCosts", label: t("capexCosts") },
    { key: "occupancy", label: t("occupancy") },
    { key: "operatingCostsSqm", label: t("operatingCosts") + "/m2" },
    { key: "operatingCosts", label: t("operatingCosts") },
    { key: "grossYield", label: t("grossYield") },
  ];

  const ASSET_COSTS_COLUMNS = [
    {
      field: "type",
      headerName: t("type"),
      flex: 1,
      minWidth: 75,
    },
    {
      field: "description",
      headerName: t("description"),
      flex: 2,
      minWidth: 200,
    },
    {
      field: "invoiceNumber",
      headerName: t("invoiceNumber"),
      flex: 1,
      minWidth: 75,
    },
    {
      field: "amount",
      headerName: t("amount"),
      flex: 1,
      minWidth: 75,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "providerInvoiceId",
      headerName: t("invoice"),
      flex: 1,
      minWidth: 75,
      renderCell: ({ row }) =>
        row?.ProviderInvoice?.invoiceNumber && (
          <ButtonLink
            to={"/app/provider-invoice/" + row.providerInvoiceId}
            onClick={(e) => e.stopPropagation()}
          >
            {row?.ProviderInvoice?.invoiceNumber}
          </ButtonLink>
        ),
    },
  ];

  const ASSET_COSTS_CSV_HEADERS = [
    { key: "type", label: t("type") },
    { key: "description", label: t("description") },
    { key: "invoiceNumber", label: t("invoiceNumber") },
    { key: "amount", label: t("amount") },
  ];

  //Initital useEffect
  useEffect(() => {
    document.title = t("acquisitionAssets");

    getAcquisitions();
    if (state.filters.cityId != "") getCity();
  }, []);

  const updateQuery = () => {
    let params = {
      tab: state.currentTab,
      ...state.filters,
    };
    const url = generateURL("/app/acquisitions/", params);
    history.push(url);
  };

  useEffect(() => {
    updateQuery();
  }, [state.currentTab]);

  const getAcquisitions = () => {
    // Change url parameters
    const url = generateURL("/app/acquisitions", {
      ...state.filters,
      tab: state.currentTab,
    });
    history.push(url);

    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      include: [
        "City",
        "AcquisitionTracker",
        "AcquisitionAssetCost",
        "ProviderInvoice",
      ],
    };

    state.filters.search !== "" && (params.search = state.filters.search);
    state.filters.priority.length > 0 &&
      (params.priority = state.filters.priority);
    state.filters.cityId !== "" && (params.cityId = state.filters.cityId);
    state.filters.source !== null && (params.source = state.filters.source);

    api
      .get("/acquisitions", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          let costs = [];

          const assets = response.data.map((asset) => {
            asset.grossInternalArea =
              asset.aditionalConstructability + asset.currentArea;

            asset.lettableArea =
              Math.round(
                asset.grossInternalArea * (asset.lettableAreaRatio / 100) * 100
              ) / 100;

            asset.capexCosts =
              asset.capexCostSqm * asset.aditionalConstructability;

            asset.potentialERV = asset.lettableArea * asset.sqmPriceMonth * 12;

            asset.achievedERV =
              Math.round(asset.potentialERV * (asset.occupancy / 100) * 100) /
              100;

            asset.operatingCosts =
              asset.operatingCostsSqm * asset.lettableArea * 12;

            asset.acquisitionCost =
              asset.acquisitionPrice * (asset.acquisitionCostRatio / 100);

            asset.AIC =
              asset.acquisitionPrice + asset.capexCosts + asset.acquisitionCost;

            asset.NOI = asset.achievedERV - asset.operatingCosts;

            asset.grossYield =
              Math.round((asset.NOI / asset.AIC) * 100 * 100) / 100 || 0;

            asset.AcquisitionAssetCosts?.forEach((cost) => costs.push(cost));

            asset.GIA =
              Number(asset.currentArea) +
              Number(asset.aditionalConstructability);
            return asset;
          });

          const summary = {};

          summary.totalPrice = assets.reduce(
            (total, current) => total + current.acquisitionPrice,
            0
          );
          summary.totalRatio = assets.reduce(
            (total, current) => total + current.acquisitionCostRatio,
            0
          );
          summary.totalAssets = costs.reduce(
            (total, current) =>
              total + (current.type === "Asset" ? current.amount : 0),
            0
          );
          summary.assetDifference =
            ((summary.totalAssets - summary.totalPrice) / summary.totalPrice) *
            100;
          summary.acquisitionCost = assets.reduce(
            (total, current) => total + current.acquisitionCost,
            0
          );
          summary.totalOthers = costs.reduce(
            (total, current) =>
              total +
              (current.type !== "Asset" &&
              current.type !== "Capex"
                ? current.amount
                : 0),
            0
          );
          summary.othersDifference =
            ((summary.totalOthers - summary.acquisitionCost) /
              summary.acquisitionCost) *
            100;
          summary.capexCosts = response.data.reduce(
            (total, current) => total + current.capexCosts,
            0
          );
          summary.totalCapex = costs.reduce(
            (total, current) =>
              total + (current.type === "Capex" ? current.amount : 0),
            0
          );
          summary.capexDifference =
            ((summary.totalCapex - summary.capexCosts) / summary.capexCosts) *
            100;
          summary.totalCosts =
            summary.totalAssets + summary.totalCapex + summary.totalOthers;
          summary.totalDifference =
            ((summary.totalCosts -
              (summary.totalPrice +
                summary.capexCosts +
                summary.acquisitionCost)) /
              (summary.totalPrice +
                summary.capexCosts +
                summary.acquisitionCost)) *
            100;

          dispatch({ type: "SET_ACQUISITIONS", payload: assets });
          dispatch({ type: "SET_ACQUISITION_COSTS", payload: costs });
          dispatch({ type: "SET_SUMMARY_COSTS", payload: summary });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCity = () => {
    api
      .get("/cities/" + state.filters.cityId)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_CITY_FILTER",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleTabChange = (event, value) => {
    dispatch({
      type: "SET_CURRENT_TAB",
      payload: value,
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_ACQUISITIONS", payload: [] });
  };

  return (
    <Container maxWidth={false} sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("pipeline")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Tabs value={state.currentTab} onChange={handleTabChange}>
              <Tab label={t("table")} id={0} />
              <Tab label={t("map")} id={1} />
              <Tab label={t("tracker")} id={2} />
              <Tab label={t("costs")} id={3} />
              <Tab label={t("documents")} id={4} />
            </Tabs>
          </Grid>

          {state.currentTab >= 0 && state.currentTab <= 3 && (
            <Grid container item xs={12} spacing={1}>
              <Grid item>
                <TextInput
                  label={t("search")}
                  name="search"
                  value={state.filters.search}
                  onChange={(e) => {
                    handleFilterChange(e);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      getAcquisitions();
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <CustomSelect
                  label={t("priority")}
                  name="priority"
                  value={state.filters.priority}
                  options={[
                    "underExclusivity",
                    "active",
                    "onHold",
                    "declined",
                    "closed",
                  ].map((item) => {
                    return { value: item, label: t(item) };
                  })}
                  multiple
                  onChange={handleFilterChange}
                />
              </Grid>
              <Grid item xs={2}>
                <CityInput
                  label={t("city")}
                  value={state.city}
                  name="city"
                  onChange={(e) => {
                    dispatch({
                      type: "SET_CITY_FILTER",
                      payload: e.target.value,
                    });
                    dispatch({
                      type: "SET_FILTER",
                      payload: {
                        inputname: "cityId",
                        value: e.target.value ? e.target.value.id : "",
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item>
                <TextInput
                  label={t("source")}
                  name="source"
                  value={state.filters.source}
                  onChange={(e) => {
                    handleFilterChange(e);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      getAcquisitions();
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <ButtonGroup variant="contained" color="primary">
                  <Button onClick={resetFilters}>{t("reset")}</Button>
                  <ButtonCSV
                    data={state.acquisitionAssets}
                    headers={CSV_HEADERS}
                    filename={t("acquisitionAssets")}
                  />
                  <SearchButton
                    onClick={getAcquisitions}
                    loading={!state.options.loaded}
                  />
                </ButtonGroup>
              </Grid>
              <Grid item>
                <CreateButton
                  action={"CREATE_ACQUISITION_ASSET"}
                  label={t("createAcquisitions")}
                  link={"/acquisition/create"}
                />
              </Grid>
            </Grid>
          )}

          {state.currentTab === 0 && (
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <ItemsSummary gridItems={ACQUISITION_ITEMS_SUMMARY} />
              </Grid>
              <Grid item xs={12} marginTop={1}>
                <Box style={{ width: "100%" }}>
                  <DataGrid
                    columns={ACQUISITION_COLUMNS}
                    rows={state.acquisitionAssets}
                    loading={!state.options.loaded}
                    autoHeight
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {state.currentTab === 1 && (
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <AcquisitionsMap
                  data={state.acquisitionAssets.map((asset) => {
                    const description = `<b>${asset.name}</b>`; //TODO: Create description<p>Test description</p>`;
                    return {
                      lng: asset.longitude,
                      lat: asset.latitude,
                      name: asset.name,
                      description,
                      id: asset.id,
                    };
                  })}
                />
              </Grid>
            </Grid>
          )}
          {state.currentTab === 2 && (
            <Grid container>
              <Grid item xs={12}>
                <AcquisitionsTracker
                  assets={state.acquisitionAssets}
                ></AcquisitionsTracker>
              </Grid>
            </Grid>
          )}
          {state.currentTab === 3 && (
            <Grid container item>
              <Grid item xs={12} marginBottom={1}>
                <Typography variant="h6">{t("summary")}</Typography>
              </Grid>
              <Box width="100%" overflow="auto" padding={1}>
                <Grid container item spacing={1} minWidth={500}>
                  <Grid item xs={0} lg={2}></Grid>
                  <Grid
                    container
                    item
                    sx={{ borderBottom: 1, borderColor: "lightgray" }}
                    columnSpacing={1}
                    xs={12}
                    lg={8}
                  >
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1" fontWeight="bold">
                        {t("budget")}
                        {" (€)"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1" fontWeight="bold">
                        {t("total")}
                        {" (€)"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1" fontWeight="">
                        {t("difference")}
                        {" (%)"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={0} lg={2}></Grid>
                  <Grid item xs={0} lg={2}></Grid>
                  <Grid container item columnSpacing={1} xs={12} lg={8}>
                    <Grid item xs={3}>
                      <Typography variant="body1" fontWeight="medium">
                        {t("asset")}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {localeFormat(state.costsSummary.totalPrice || 0) + " €"}
                    </Grid>
                    <Grid item xs={3}>
                      {localeFormat(state.costsSummary.totalAssets || 0) + " €"}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        color={
                          state.costsSummary.assetDifference > 0
                            ? theme.palette.error.main
                            : theme.palette.success.main
                        }
                        fontWeight="medium"
                      >
                        {localeFormat(state.costsSummary.assetDifference || 0) +
                          " %"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={0} lg={2}></Grid>
                  <Grid item xs={0} lg={2}></Grid>
                  <Grid
                    container
                    item
                    columnSpacing={1}
                    xs={12}
                    lg={8}
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <Typography variant="body1" fontWeight="medium">
                        {t("acquisitionCosts")}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {localeFormat(state.costsSummary.acquisitionCost || 0) +
                        " €"}
                    </Grid>
                    <Grid item xs={3}>
                      {localeFormat(state.costsSummary.totalOthers || 0) + " €"}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        color={
                          state.costsSummary.othersDifference > 0
                            ? theme.palette.error.main
                            : theme.palette.success.main
                        }
                        fontWeight="medium"
                      >
                        {localeFormat(
                          state.costsSummary.othersDifference || 0
                        ) + " %"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={0} lg={2}></Grid>
                  <Grid item xs={0} lg={2}></Grid>
                  <Grid container item columnSpacing={1} xs={12} lg={8}>
                    <Grid item xs={3}>
                      <Typography variant="body1" fontWeight="medium">
                        {t("capex")}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {localeFormat(state.costsSummary.capexCosts || 0) + " €"}
                    </Grid>
                    <Grid item xs={3}>
                      {localeFormat(state.costsSummary.totalCapex || 0) + " €"}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        color={
                          state.costsSummary.capexDifference > 0
                            ? theme.palette.error.main
                            : theme.palette.success.main
                        }
                        fontWeight="medium"
                      >
                        {localeFormat(state.costsSummary.capexDifference || 0) +
                          " %"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={0} lg={2}></Grid>
                  <Grid item xs={0} lg={2}></Grid>
                  <Grid
                    container
                    item
                    sx={{ borderTop: 1, borderColor: "lightgray" }}
                    columnSpacing={1}
                    xs={12}
                    lg={8}
                  >
                    <Grid item xs={3}>
                      <Typography variant="body1" fontWeight="medium">
                        {t("total")}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1" fontWeight="medium">
                        {localeFormat(
                          state.costsSummary.totalPrice +
                            state.costsSummary.acquisitionCost +
                            state.costsSummary.capexCosts || 0
                        ) + "€"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1" fontWeight="medium">
                        {localeFormat(state.costsSummary.totalCosts || 0) + "€"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        color={
                          state.costsSummary.totalDifference > 0
                            ? theme.palette.error.main
                            : theme.palette.success.main
                        }
                        fontWeight="medium"
                      >
                        {localeFormat(state.costsSummary.totalDifference || 0) +
                          "%"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Grid item xs={12} sx={{ m: 2 }}>
                <Divider />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6">{t("assetCosts")}</Typography>
              </Grid>
              <Grid container justifyContent={"flex-end"} item xs={1}>
                <Grid item>
                  <ButtonCSV
                    data={state.acquisitionCosts}
                    headers={ASSET_COSTS_CSV_HEADERS}
                    filename={t("acquisitionAssets")}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} marginTop={1}>
                <Box style={{ height: 400, width: "100%" }}>
                  <DataGrid
                    rows={state.acquisitionCosts}
                    columns={ASSET_COSTS_COLUMNS}
                    loading={!state.options.loaded}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {state.currentTab === 4 && <DocumentsTab />}
        </Grid>
      </Paper>
    </Container>
  );
}

const defaultFilters = { name: "", category: [] };

const DocumentsTab = (props) => {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("acquisitions");
  const [tErrors] = useTranslation("errors");

  const [filters, setFilters] = useState(defaultFilters);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const DOCUMENTS_COLUMNS = [
    {
      key: "name",
      label: t("fileName"),
      sortType: "string",
    },
    {
      key: "AcquisitionAsset.name",
      label: t("asset"),
      sortType: "string",
    },
    {
      key: "category",
      label: t("category"),
      sortType: "string",
    },
    {
      key: "id",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) => (
        <>
          <IconButton
            onClick={() => {
              seeDocument(item);
            }}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              downloadDocument(item);
            }}
          >
            <DownloadIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeleteConfirmDialog(true);
              setSelectedDocument(item);
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const getDocuments = () => {
    setLoading(true);

    const params = {
      name: filters.name,
      category: filters.category,
    };

    api
      .get("/acquisitions/documents", { params })
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(tErrors(res.data.error), { variant: "error" });
        } else {
          setDocuments(res.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const seeDocument = (document) => {
    api
      .get("/acquisitions/documents/" + document.id, { responseType: "blob" })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: document.type });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const downloadDocument = (item) => {
    api
      .get("/acquisitions/documents/" + item.id, { responseType: "blob" })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const file = new Blob([response.data], { type: item.type });
          const fileURL = URL.createObjectURL(file);
          const a = document.createElement("a");
          a.href = fileURL;
          a.download = fileURL.split("/").pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const downloadAllDocumentsZip = (documentIds) => {
    api
      .post("/acquisitions/documents/downloadZip", { documentIds })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/zip" });
        const fileURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = fileURL;
        a.download = "documents.zip";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const downloadAllDocuments = () => {
    if (!documents.length) return;
    const documentIds = documents.map((item) => item.id);
    downloadAllDocumentsZip(documentIds);
  };

  const deleteDocument = (document) => {
    api
      .delete("/acquisitions/document/" + document.id)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          enqueueSnackbar("documentDeleted", { variant: "success" });
          getDocuments();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const search = () => {
    getDocuments();
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
  };

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("documents")}</Typography>
      </Grid>

      <Grid container item xs={12} spacing={1}>
        <Grid item>
          <TextInput
            label={t("name")}
            name="name"
            value={filters.name}
            onChange={(e) => {
              setFilters({ ...filters, name: e.target.value });
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                search();
              }
            }}
          />
        </Grid>
        <Grid item>
          <CustomSelect
            label={t("category")}
            name="category"
            value={filters.category}
            options={[
              "Info received",
              "Deal",
              "Letter",
              "IC",
              "KYC",
              "Tecnica",
              "Legal",
              "Otros",
            ].map((item) => {
              return { value: item, label: t(item) };
            })}
            multiple
            onChange={(e) => {
              setFilters({ ...filters, category: e.target.value });
            }}
          />
        </Grid>
        <Grid item>
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={resetFilters}>{t("reset")}</Button>
            <Button onClick={downloadAllDocuments}>{t("zip")}</Button>
            <SearchButton onClick={search} loading={loading} />
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          columns={DOCUMENTS_COLUMNS}
          data={documents}
          loading={loading}
        />
      </Grid>

      <ConfirmDialog
        open={deleteConfirmDialog}
        title={t("deleteDocument")}
        setOpen={(status) => {
          setDeleteConfirmDialog(status);
          setSelectedDocument(null);
        }}
        onConfirm={(status) => {
          if (status) deleteDocument(selectedDocument);
        }}
      >
        <Typography>{t("deleteDocumentConfirmation")}</Typography>
      </ConfirmDialog>
    </Grid>
  );
};
