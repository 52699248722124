import { TextField } from "@mui/material";

const MonthInput = (props) => {
  return (
    <TextField
      {...props}
      type="month"
      size="small"
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
    />
  );
};

export default MonthInput;
