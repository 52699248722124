import React from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      flex={1}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      height="100%"
      {...other}
    >
      {value === index && <Box sx={{ height: "100%" }}>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const DashboardTabs = ({ panels }) => {
  const DEFAULT_TAB = 0; // Center Report

  const [tabIndex, setTabIndex] = React.useState(DEFAULT_TAB);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box display="flex" height="100%">
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabIndex}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {panels.map((panel, index) => (
          <Tab key={index} label={panel.tabLabel} {...a11yProps(index)} />
        ))}
      </Tabs>
      <Box flex={1} overflow="auto">
        {panels.map((panel, index) => (
          <TabPanel key={index} value={tabIndex} index={index}>
            {panel.content}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default DashboardTabs;
