import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useContext, useEffect, useReducer } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import CustomDate from "../../Inputs/CustomDate";
import CenterSelect from "../../Inputs/CenterSelect";
import CustomSelect from "../../Inputs/CustomSelect";
import PhoneInput from "../../Inputs/PhoneInput";
import PostalCodeInput from "../../Inputs/PostalCodeInput";
import TextInput from "../../Inputs/TextInput";

import DeleteIcon from "@mui/icons-material/Delete";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

const initialState = {
  form: {
    phoneNumber: "",
    customerName: "",
    postalCode: "",
    customerEmail: "",
    wantCallBack: false,
    callBackAt: "",
    centerId: "",
    comments: "",
    centerComments: "",
    departmentId: "",
    CallDepartment: {
      name: "",
    },
    callReasonTypes: [],
    Center: {
      boxes: [],
      comments: "",
      name: "",
    },
  },
  inputError: {
    phoneNumber: false,
    customerName: false,
    postalCode: false,
    customerEmail: false,
    wantCallBack: false,
    callBackAt: false,
    centerId: false,
    comments: false,
    centerComments: false,
    departmentId: false,
  },
  callReasons: [],
  centers: [],
  departments: [],
  id: null,
  open: false,
  deleteDialogIsOpen: false,
  emailDialogIsOpen: false,
  sendEmailCenter: "no",
  sendEmailDep: "no",
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_CALL":
      return { ...state, form: action.payload };
    case "SET_DEPARTMENTS":
      return { ...state, departments: action.payload };
    case "SET_CENTERS":
      return { ...state, centers: action.payload.centers };
    case "SET_REASONS":
      return { ...state, callReasons: action.payload };
    case "SET_OPEN":
      return { ...state, open: !state.open };

    case "OPEN_DELETE_DIALOG":
      return { ...state, deleteDialogIsOpen: true };
    case "CLOSE_DELETE_DIALOG":
      return { ...state, deleteDialogIsOpen: false };

    case "OPEN_EMAIL_DIALOG":
      return { ...state, emailDialogIsOpen: true };
    case "CLOSE_EMAIL_DIALOG":
      return { ...state, emailDialogIsOpen: false };
    case "SET_EMAIL_DEP":
      return { ...state, sendEmailDep: action.payload };
    case "SET_EMAIL_CENTER":
      return { ...state, sendEmailCenter: action.payload };
    default:
      throw new Error();
  }
}

export default function CallPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("calls");

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleInputChangeCheckbox = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.checked,
      },
    });
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("callPage");

    getCall();
    getCenters();
    getCallReasons();
    getDepartments();
  }, []);

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        dispatch({
          type: "SET_CENTERS",
          payload: {
            centers: response.data,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCallReasons = () => {
    api
      .get("/calls/reasons")
      .then((response) => {
        dispatch({ type: "SET_REASONS", payload: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCall = () => {
    let params = {
      include: ["Center"],
    };
    api
      .get("/calls/" + id, { params })
      .then((response) => {
        let call = response.data;
        call.postalCode = response.data.PostalCode?.number;
        call.callReasonTypes = call.CallReasonTypes.map((reason) => reason.id);

        dispatch({ type: "SET_CALL", payload: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDepartments = () => {
    api
      .get("/calls/departments")
      .then((response) => {
        dispatch({ type: "SET_DEPARTMENTS", payload: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitForm = () => {
    if (validateForm()) {
      let data = {};
      state.form.customerName !== "" &&
        (data.customerName = state.form.customerName);
      state.form.phoneNumber !== "" &&
        (data.phoneNumber = state.form.phoneNumber);
      state.form.customerEmail !== "" &&
        (data.customerEmail = state.form.customerEmail);
      state.form.postalCode !== "" && (data.postalCode = state.form.postalCode);
      state.form.wantCallBack !== "" &&
        (data.wantCallBack = state.form.wantCallBack);
      state.form.comments !== "" && (data.comments = state.form.comments);
      state.form.centerId !== "" && (data.centerId = state.form.centerId);
      state.form.centerComments !== "" &&
        (data.centerComments = state.form.centerComments);
      state.form.departmentId !== "" &&
        (data.departmentId = state.form.departmentId);

      data.callReasonTypes = state.form.callReasonTypes;
      data.createdBy = user.id;

      api
        .post("/calls/edit/" + id, state.form)
        .then((response) => {
          if (response.data.error) {
            const msg =
              response.data.error.errors[0]?.message || response.data.error;
            enqueueSnackbar(msg, { variant: "error" });
          } else {
            enqueueSnackbar(t("callEditSuccess"), { variant: "success" });

            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let fields = ["customerName", "phoneNumber"];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(field + " is required.", { variant: "error" });
        isValid = false;
      }
    });

    const re = /\S+@\S+\.\S+/; //Regex for testing email format

    if (state.form.customerEmail !== null && state.form.customerEmail !== "") {
      if (
        !state.form.customerEmail ||
        state.form.customerEmail === "" ||
        !re.test(state.form.customerEmail)
      ) {
        setInputErrorTrue("customerEmail");
        enqueueSnackbar("Email must be valid", { variant: "error" });
        isValid = false;
      }
    }

    return isValid;
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const deleteCall = () => {
    handleCloseDeleteModal();
    api
      .delete("/calls/" + id)
      .then((response) => {
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, { variant: "error" });
        } else {
          enqueueSnackbar(t("callDeleteSuccess"), { variant: "success" });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const hasAction = (action) => {
    let isFound = false;
    user?.Role.Actions.forEach((item) => {
      if (item.id === action) {
        isFound = true;
      }
    });

    return isFound;
  };

  const handleCloseDeleteModal = () => {
    dispatch({ type: "CLOSE_DELETE_DIALOG" });
  };

  const openDeleteDialog = () => {
    dispatch({ type: "OPEN_DELETE_DIALOG" });
  };

  const openEmailDialog = () => {
    dispatch({ type: "OPEN_EMAIL_DIALOG" });
  };

  const handleCloseEmailDialog = () => {
    dispatch({ type: "CLOSE_EMAIL_DIALOG" });
  };

  const handleEmailDepChange = (e) => {
    dispatch({ type: "SET_EMAIL_DEP", payload: e.target.value });
  };

  const handleEmailCenterChange = (e) => {
    dispatch({ type: "SET_EMAIL_CENTER", payload: e.target.value });
  };

  const sendEmail = () => {
    let form = {
      sendEmailCenter: state.sendEmailCenter,
      sendEmailDep: state.sendEmailDep,
    };

    api
      .post("/calls/:id/send-email", form)
      .then((response) => {
        console.log(response);
        if (!response.data.error) {
          enqueueSnackbar(response.data, { variant: "success" });
          handleCloseEmailDialog();
        } else {
          enqueueSnackbar(response.data.error, { variant: "error" });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleCallReasonCheck = (e, callReason) => {
    if (e.target.checked) {
      let reasons =
        state.form.callReasonTypes?.length > 0
          ? [...state.form.callReasonTypes, callReason]
          : [callReason];

      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "callReasonTypes",
          value: reasons,
        },
      });
    } else {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "callReasonTypes",
          value: state.form.callReasonTypes.filter(
            (reason) => callReason !== reason
          ),
        },
      });
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item container xs={12} spaicing={1}>
            <Grid item>
              <Typography variant="h4">{t("editCall")}</Typography>
            </Grid>
            <Grid item flex={1} justifyContent="flex-end" display="flex">
              <Tooltip
                title={t("sendEmail")}
                placement="top"
                onClick={openEmailDialog}
              >
                <IconButton color="primary">
                  <ForwardToInboxIcon />
                </IconButton>
              </Tooltip>
              {hasAction("DELETE_CALLS") && (
                <Tooltip title={t("delete")} placement="top">
                  <IconButton
                    size="large"
                    color="error"
                    onClick={openDeleteDialog}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {hasAction("EDIT_CALLS") ? (
              <TextInput
                error={state.inputError.customerName}
                helperText={
                  state.inputError.customerName ? "Name must not be blank" : ""
                }
                label={t("name")}
                value={state.form.customerName}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="customerName"
              />
            ) : (
              <Typography variant="body1">
                {t("name") + ": " + state.form.customerName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {hasAction("EDIT_CALLS") ? (
              <PhoneInput
                inputProps={{
                  error: state.inputError.phoneNumber,
                  label: t("phoneNumber"),
                  name: "phoneNumber",
                  onChange: (e) => {
                    handleInputChange(e);
                  },
                }}
                value={state.form.phoneNumber}
              />
            ) : (
              <Typography variant="body1">
                {t("phoneNumber") + ": " + state.form.phoneNumber}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {hasAction("EDIT_CALLS") ? (
              <TextInput
                error={state.inputError.customerEmail}
                helperText={
                  state.inputError.customerEmail ? "Email must be valid." : ""
                }
                label={t("email")}
                value={state.form.customerEmail}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="customerEmail"
              />
            ) : (
              <Typography variant="body1">
                {t("email") + ": " + state.form.customerEmail}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {hasAction("EDIT_CALLS") ? (
              <PostalCodeInput
                error={state.inputError.postalCode}
                helperText={
                  state.inputError.postalCode
                    ? "Postal code must not be blank"
                    : ""
                }
                value={state.form.postalCode}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                label={t("postalCode")}
              />
            ) : (
              <Typography variant="body1">
                {t("postalCode") + ": " + state.form.postalCode}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {hasAction("EDIT_CALLS") ? (
              <CenterSelect
                value={state.form.centerId}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="centerId"
              />
            ) : (
              <Typography variant="body1">
                {hasAction("VIEW_CENTERS") ? (
                  <Link to={"/app/center/" + state.form.centerId}>
                    {t("center") + ": " + state.form.Center?.name}
                  </Link>
                ) : (
                  t("center") + ": " + state.form.Center?.name
                )}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {hasAction("EDIT_CALLS") ? (
              <CustomSelect
                label={t("department")}
                value={state.form.departmentId}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="departmentId"
                options={[
                  { value: "", label: t("none") },
                  ...state.departments.map((department) => ({
                    value: department.id,
                    label: department.name + " - " + department.email,
                  })),
                ]}
              />
            ) : (
              <Typography variant="body1">
                {t("department") + ": " + state.form.CallDepartment?.name}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {hasAction("EDIT_CALLS") ? (
              <TextInput
                error={state.inputError.comments}
                helperText={
                  state.inputError.comments ? "Comments must not be blank" : ""
                }
                label={t("comments")}
                multiline
                rows={4}
                value={state.form.comments}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="comments"
              />
            ) : (
              <Typography variant="body1">
                {t("comments") + ": " + state.form.comments}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            {hasAction("EDIT_CALLS") ? (
              <TextInput
                error={state.inputError.centerComments}
                helperText={
                  state.inputError.centerComments
                    ? "Center comments must not be blank"
                    : ""
                }
                label={t("centerComments")}
                multiline
                rows={4}
                value={state.form.centerComments}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="centerComments"
              />
            ) : (
              <Typography variant="body1">
                {t("centerComments") + ": " + state.form.centerComments}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            {hasAction("EDIT_CALLS") ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.form.wantCallBack}
                    name="wantCallBack"
                    onChange={(e) => {
                      handleInputChangeCheckbox(e);
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={t("wantCallBack")}
              ></FormControlLabel>
            ) : (
              <Typography variant="body1">
                {t("wantCallBack") +
                  ": " +
                  (state.form.wantCallBack ? "Yes" : "No")}
              </Typography>
            )}
          </Grid>

          {state.form.wantCallBack && (
            <Grid item xs={6}>
              {hasAction("EDIT_CALLS") ? (
                <CustomDate
                  error={state.inputError.callBackAt}
                  helperText={
                    state.inputError.callBackAt
                      ? "Call back at date must not be blank"
                      : ""
                  }
                  label={t("callBackAt")}
                  value={state.form.callBackAt}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  name="callBackAt"
                />
              ) : (
                <Typography variant="body1">
                  {t("callBackAt") + ": " + state.form.callBackAt}
                </Typography>
              )}
            </Grid>
          )}

          {hasAction("EDIT_CALLS") && (
            <Grid item container>
              <Grid item xs={12}>
                <Typography variant="body1">{t("callReasons")}</Typography>
              </Grid>

              <Grid item>
                <FormGroup>
                  {state.callReasons.map((reason) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.form.callReasonTypes?.includes(
                            reason.id
                          )}
                          onChange={(e) => {
                            handleCallReasonCheck(e, reason.id);
                          }}
                        />
                      }
                      label={reason.name}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>
          )}

          <Grid item container xs={12} justifyContent="flex-end" spacing={3}>
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            {hasAction("EDIT_CALLS") && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Dialog open={state.deleteDialogIsOpen} onClose={handleCloseDeleteModal}>
        <DialogContent>
          <Typography variant="h6">{t("confirmDelete")}</Typography>
          <Grid container spacing={1} justify="center">
            <Grid item>
              <Button variant="contained" color="primary" onClick={deleteCall}>
                {t("yes")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseDeleteModal}
              >
                {t("no")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={state.emailDialogIsOpen} onClose={handleCloseEmailDialog}>
        <DialogActions>
          <IconButton onClick={handleCloseEmailDialog}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Grid container rowSpacing={2}>
            {state.form.departmentId !== null && state.form.departmentId !== "" && (
              <Grid item container xs={12}>
                <Grid item xs={9}>
                  <Typography variant="body1">
                    {t("sendEmailToDepartment")}
                    {state.departments.filter((value) => value.id === state.form.departmentId)[0].email}
                    {" ("+state.departments.filter((value) => value.id === state.form.departmentId)[0].name+")"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <ToggleButtonGroup
                    value={state.sendEmailDep}
                    onChange={handleEmailDepChange}
                    exclusive
                  >
                    <ToggleButton
                      value="yes"
                      color={state.sendEmailDep === "yes" && "success"}
                    >
                      {t("yes")}
                    </ToggleButton>
                    <ToggleButton
                      value="no"
                      color={state.sendEmailDep === "no" && "error"}
                    >
                      {t("no")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            )}
            {state.form.centerId !== "" && (
              <Grid item container xs={12}>
                <Grid item xs={9}>
                  <Typography variant="body1">{t("sendEmailToCenter")}</Typography>
                  <Typography variant="body1">{state.form.Center?.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <ToggleButtonGroup
                    value={state.sendEmailCenter}
                    onChange={handleEmailCenterChange}
                    exclusive
                  >
                    <ToggleButton
                      value="yes"
                      color={state.sendEmailCenter === "yes" && "success"}
                    >
                      {t("yes")}
                    </ToggleButton>
                    <ToggleButton
                      value="no"
                      color={state.sendEmailCenter === "no" && "error"}
                    >
                      {t("no")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={sendEmail}
            disabled={
              state.sendEmailDep === "no" && state.sendEmailCenter === "no"
            }
          >
            {t("sendEmail")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
