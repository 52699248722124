import { Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import Button from "../../Inputs/CreateButton";
import { CustomTable } from "../../CustomTable";
import TextInput from "../../Inputs/TextInput";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_MASS_EMAILS":
      return { ...state, massEmails: action.payload };
    case "SET_TAB":
      return { ...state, currentTab: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    default:
      throw new Error("Action not found in reducer.");
  }
};

const initialState = {
  currentTab: 0,
  filters: {
    text: "",
  },
  massEmails: [],
};

const MassEmailsPage = () => {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("boxes");

  const [state, dispatch] = useReducer(reducer, initialState);

  const cols = [
    { key: "title", label: t("title") },
    { key: "comments", label: t("comments") },
    { key: "User.name", label: t("createdBy") },
    { key: "subject", label: t("subject") },
    {
      key: "MassEmailRecipients",
      label: t("numRecipients"),
      renderFunction: (value) => value.length,
    },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("massEmailsPage");

    getMassEmails();
  }, []);

  const getMassEmails = () => {
    let params = {};

    state.filters.text !== "" && (params.text = state.filters.text);

    api
      .get("/mass-emails", { params })
      .then((response) => {
        if (!response.data.error) {
          dispatch({ type: "SET_MASS_EMAILS", payload: response.data });
        } else {
          enqueueSnackbar(response.data.error.toString(), { variant: "error" });
        }
      })
      .catch((error) => console.log(error));
  };

  const search = () => {
    getMassEmails();
  };

  const handleTabChange = (event, newValue) => {
    dispatch({ type: "SET_TAB", payload: newValue });
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.value,
        value: e.target.value,
      },
    });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t("massEmails")}</Typography>
        </Grid>

        <Tabs value={state.currentTab} onChange={handleTabChange}>
          <Tab label={t("centers")} id={0} />
          <Tab label={t("customers")} id={1} />
        </Tabs>

        <Grid container item spacing={1} xs={12}>
          <Grid item>
            <TextInput
              value={state.filters.text}
              onChange={handleFilterChange}
              name="text"
              label={t("text")}
            />
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={search}>
              {t("search")}
            </Button>
          </Grid>

          <Grid item>
            <Button
              label={t("create")}
              link={
                state.currentTab === 0
                  ? "/mass-email/create/center"
                  : "/mass-email/create/customers"
              }
            />
          </Grid>
        </Grid>

        {state.currentTab === 0 && (
          <Grid item xs={12}>
            <CustomTable columns={cols} data={state.massEmails} />
          </Grid>
        )}
        {state.currentTab === 1 && (
          <Grid item xs={12}>
            <CustomTable columns={cols} data={state.customerEmails} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default MassEmailsPage;
