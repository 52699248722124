import { Grid } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import CenterReportContext from "../CenterReportContext";

import { CustomTable } from "../../../../CustomTable";
import { localeFormat } from "../../../../../utils/format";
import ButtonLink from "../../../../Inputs/ButtonLink";

import {
  CustomerAgeDistribution,
  CustomerGenderDetails,
} from "../../charts/CenterReport";

function CustomerTab() {
  const { customers } = useContext(CenterReportContext);
  const [t] = useTranslation("dashboard");

  const CUSTOMER_COLUMNS = [
    {
      key: "fullName",
      label: t("customer"),
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={`/app/customer/${item.id}`}>{value}</ButtonLink>
      ),
    },
    {
      key: "birthdate",
      label: t("age"),
      sortType: "number",
      renderFunction: (value) => {
        if (!value) return "";
        const age = new Date().getFullYear() - new Date(value).getFullYear();
        return age;
      },
    },
    {
      key: "customerTypeId",
      label: t("type"),
      sortType: "number",
      renderFunction: (value) => {
        return Number(value) === 1 ? t("individual") : t("company");
      },
    },
    {
      key: "gender",
      label: t("gender"),
      sortType: "number",
      renderFunction: (value, item) => {
        if (!value) return "";
        if (Number(value) === 1) return t("male");
        else return t("famale");
      },
    },
    {
      key: "Contracts",
      label: t("boxes"),
      sortType: "other",
      renderFunction: (value, item) => {
        return value ? value.length : 0;
      },
    },
    {
      key: "Contracts",
      label: t("meters"),
      sortType: "other",
      renderFunction: (value, item) => {
        return (
          (value ? value.reduce((acum, e) => acum + Number(e.meters), 0) : 0) +
          " m²"
        );
      },
    },
    {
      key: "Contracts",
      label: t("income"),
      sortType: "other",
      renderFunction: (value, item) => {
        return (
          (value
            ? localeFormat(
                value.reduce(
                  (acum, e) =>
                    acum + Number(e.meters) * Number(e.pricePerMeter),
                  0
                )
              )
            : 0) + " €"
        );
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <CustomerAgeDistribution customers={customers} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <CustomerGenderDetails customers={customers} />
      </Grid>
      <Grid item xs={12} margin={2}>
        <CustomTable columns={CUSTOMER_COLUMNS} data={customers} />
      </Grid>
    </Grid>
  );
}

export default CustomerTab;
