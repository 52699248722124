import PropTypes from "prop-types";
import {
  Dialog as MUIDialog,
  DialogTitle,
  Grid,
  IconButton,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Title = ({ title, onClose }) => {
  return (
    <DialogTitle>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>{title}</Grid>
        <Grid item>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

const Dialog = (props) => {
  const { open = false, onClose = () => {}, children, title, ...otherProps } = props;

  return (
    <MUIDialog open={open} {...otherProps}>
      <Title onClose={onClose} title={title} />
      <DialogContent>{children}</DialogContent>
    </MUIDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default Dialog;
