import PropTypes from "prop-types";
import { Grid } from "@mui/material";

const Filters = (props) => {
  const { filters } = props;

  return (
    <Grid container spacing={1}>
      {filters.map((filter, i) => (
        <Grid item xs={12} sm="auto" key={i}>
          {filter}
        </Grid>
      ))}
    </Grid>
  );
};

Filters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.element),
};

export default Filters;
