import AppContext from "../../../context/AppContext";
import { useContext, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";

// mui
import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  DialogTitle,
  Box,
} from "@mui/material";

// icons
import CloseIcon from "@mui/icons-material/Close";

// components & utils
import CustomDate from "../../Inputs/CustomDate";
import ReceiptStateChip from "../../ReceiptStateChip";
import { today } from "../../../utils/date";
import { localeFormat } from "../../../utils/format";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import CustomButton from "../../Inputs/CustomButton";
import {
  RECEIPT_PAYMENT_METHOD_ID,
  RETURNED_RECEIPT_STATE_ID,
} from "../../../data/constants";

const initialState = {
  form: {
    comment: "",
    date: today(),
    reason: "",
  },
  nonPaymentReasons: [],
  paymentMethods: [],
  selectedReceipts: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_NON_PAYMENT_REASONS":
      return { ...state, nonPaymentReasons: action.payload };
    case "SET_SELECTED_RECEIPTS":
      return { ...state, selectedReceipts: action.payload };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_FORM":
      return {
        ...state,
        form: initialState.form,
        selectedReceipts: initialState.selectedReceipts,
      };
    default:
      throw new Error("Action not found in reducer");
  }
};

const CreateNonPaymentForm = (props) => {
  const { receipts, invoicePaymentMethodId, open, onClose, onSubmit, loading } =
    props;

  const { api } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("nonPayments");

  const RECEIPT_COLUMNS = [
    {
      field: "remittanceName",
      headerName: t("remittance"),
      width: 200,
      valueGetter: ({ row }) => row.Remittance?.name,
    },
    { field: "concept", headerName: t("concept"), width: 150 },
    {
      field: "amount",
      headerName: t("amount"),
      width: 150,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    { field: "dueDate", headerName: t("dueDate"), width: 150 },
    {
      field: "state",
      headerName: t("state"),
      width: 150,
      renderCell: ({ value }) => <ReceiptStateChip state={value} />,
    },
  ];

  useEffect(() => {
    getPaymentMethods();
    getNonPaymentReasons();
  }, []);

  useEffect(() => {
    //TODO: Provisional code, when there are more reasons this will not work
    if (invoicePaymentMethodId && open) {
      dispatch({
        type: "SET_INPUT",
        payload: { inputname: "reason", value: invoicePaymentMethodId },
      });
    }
  }, [open]);

  const getNonPaymentReasons = () => {
    api
      .get("/non-payments/reasons")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_NON_PAYMENT_REASONS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };
  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const selectReceipts = (ids) => {
    dispatch({ type: "SET_SELECTED_RECEIPTS", payload: ids });
  };

  const getPaymentMethodTranslation = (paymentMethodName) => {
    const paymentMethodMap = {
      CONTADO: t("cash"),
      RECIBO: t("receipt"),
      TARJETA: t("card"),
      TRANSFERENCIA: t("transfer"),
      CONSOLIDACION: t("consolidation"),
    };
    return paymentMethodMap[paymentMethodName]
      ? paymentMethodMap[paymentMethodName]
      : "PaymentMethod not found";
  };

  const handleClose = () => {
    onClose();
    dispatch({ type: "RESET_FORM" });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Grid item container justifyContent="space-between">
          <Grid item>{t("addNonPayment")}</Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item container alignItems="center" spacing={1}>
          <Grid item xs={12} sm={2}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t("paymentMethod")}:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="paymentMethods"
                value={Number(invoicePaymentMethodId)}
              >
                {state.paymentMethods.map((method, index) => (
                  <FormControlLabel
                    key={index}
                    value={method.id}
                    control={<Radio />}
                    label={getPaymentMethodTranslation(method.name) || ""}
                    disabled={invoicePaymentMethodId !== method.id}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t("reason")}:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            {/* The following code is provisional. Should be changed for a select when there are more than 1 reason per payment method */}
            <Typography variant="body1">
              {
                state.nonPaymentReasons.find(
                  (reason) => reason.id === Number(invoicePaymentMethodId)
                )?.name
              }
            </Typography>
          </Grid>
          {Number(invoicePaymentMethodId) === RECEIPT_PAYMENT_METHOD_ID && (
            <Grid item xs={12}>
              <Box style={{ height: 300, width: "100%" }}>
                <DataGrid
                  columns={RECEIPT_COLUMNS}
                  rows={receipts}
                  checkboxSelection
                  selectionModel={state.selectedReceipts}
                  onSelectionModelChange={(ids) => {
                    selectReceipts(ids);
                  }}
                  isRowSelectable={({ row }) =>
                    row.state !== RETURNED_RECEIPT_STATE_ID
                  } //Only selectable if state is not returned
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <CustomDate
              InputLabelProps={{ shrink: true }}
              value={state.form.date}
              onChange={handleInputChange}
              name="date"
              label={t("date")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              variant="outlined"
              label={t("comment")}
              name={"comment"}
              onChange={handleInputChange}
              fullWidth
              rows={4}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("back")}</Button>
        <CustomButton
          onClick={() => onSubmit(state.form, state.selectedReceipts)}
          loading={loading}
          disabled={!state.form.date || !state.form.reason}
        >
          {t("add")}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNonPaymentForm;
