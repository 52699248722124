import { TextField } from "@mui/material";

const DateInput = (props) => {
  return (
    <TextField
      {...props}
      type="date"
      size="small"
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
    />
  );
};

export default DateInput;
