import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { faHtml5, faCss3Alt } from "@fortawesome/free-brands-svg-icons";
import {
  faFile,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePowerpoint,
  faFilePdf,
  faFileVideo,
  faFileWord,
} from "@fortawesome/free-regular-svg-icons";

const fileIcons = {
  doc: () => <FontAwesomeIcon icon={faFileWord} />,
  xls: () => <FontAwesomeIcon icon={faFileExcel} />,
  xlsx: () => <FontAwesomeIcon icon={faFileExcel} />,
  ppt: () => <FontAwesomeIcon icon={faFilePowerpoint} />,
  pptx: () => <FontAwesomeIcon icon={faFilePowerpoint} />,
  pdf: () => <FontAwesomeIcon icon={faFilePdf} />,
  csv: () => <FontAwesomeIcon icon={faFileCsv} />,
  html: () => <FontAwesomeIcon icon={faHtml5} />,
  css: () => <FontAwesomeIcon icon={faCss3Alt} />,
  jpg: () => <FontAwesomeIcon icon={faFileImage} />,
  png: () => <FontAwesomeIcon icon={faFileImage} />,
  mov: () => <FontAwesomeIcon icon={faFileVideo} />,
  mp4: () => <FontAwesomeIcon icon={faFileVideo} />,
  mp3: () => <FontAwesomeIcon icon={faFileAudio} />,
  m4a: () => <FontAwesomeIcon icon={faFileAudio} />,
  wav: () => <FontAwesomeIcon icon={faFileAudio} />,
  zip: () => <FontAwesomeIcon icon={faFileArchive} />,
  rar: () => <FontAwesomeIcon icon={faFileArchive} />,
  cpp: () => <FontAwesomeIcon icon={faFileCode} />,
  default: () => <FontAwesomeIcon icon={faFile} />,
};

export default fileIcons;