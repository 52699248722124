/**
 * Downloads the given file.
 *
 * @param {*} file - The file to be downloaded. This can be a Blob, File, or any data that represents the file content.
 * @param {string} fileName - The name of the file to be downloaded. Default is null.
 * @param {string} fileType - The type of the file to be downloaded. Default is "pdf".
 */
export const downloadFile = (file, fileName = null, fileType = "pdf") => {
  // Create a Blob from the file
  const blobFile = createBlobFile(file, fileType);

  // Build a URL from the file
  const fileURL = URL.createObjectURL(blobFile);

  // Create a temporary anchor element and set its attributes
  const a = document.createElement("a");
  a.href = fileURL;

  // Set the file name
  if (fileName) a.download = fileName + "." + fileType;
  else a.download = fileURL.split("/").pop();

  // Append the anchor element to the DOM (in this case, the body) to trigger the download
  document.body.appendChild(a);

  // Simulate a click on the anchor element to initiate the download
  a.click();

  // Remove the anchor element from the DOM once the download is initiated
  document.body.removeChild(a);
};

/**
 * Opens the file in a new window.
 *
 * @param {*} file - The file to be downloaded. This can be a Blob, File, or any data that represents the file content.
 * @param {string} fileType - The type of the file to be downloaded. Default is "pdf".
 */
export const openFile = (file, fileType = "pdf") => {
  const blobFile = createBlobFile(file, fileType);
  const fileURL = URL.createObjectURL(blobFile);
  window.open(fileURL);
};

/**
 * Creates a Blob file from the provided data.
 *
 * @param {*} file - The data of the file to be converted into a Blob.
 * @param {string} fileType - (Optional) The file type or extension. Default is "pdf".
 * @returns {Blob} - A Blob object representing the file data with the specified file type.
 */
const createBlobFile = (file, fileType = "pdf") => {
  return new Blob([file], {
    type: "application/" + fileType,
  });
};
