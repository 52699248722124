import { Container, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextInput from "../Inputs/TextInput";
import CustomButton from "../Inputs/CustomButton";
import { useState } from "react";
import { TOTP_TOKEN_LENGTH } from "../../data/constants";

export default function SetupMFA({
  secretBase32,
  qrCode,
  onVerifyToken,
  verifyLoading,
}) {
  const [t] = useTranslation("login");
  const [token, setToken] = useState("");

  return (
    <Container maxWidth="sm">
      <Paper>
        <Grid container padding={2} rowGap={3}>
          {/* Title */}
          <Grid item xs={12}>
            <Typography variant="h6">{t("enableMFA")}</Typography>
          </Grid>

          {/* Step 1 */}
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                {t("step") + " 1"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                {t("downloadApp")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                {t("downloadAppDescription")}
              </Typography>
            </Grid>
          </Grid>

          {/* Step 2 */}
          <Grid item container xs={12} sm={6}>
            <Grid item container spacing={1} alignContent="flex-start">
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t("step") + " 2"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight="bold">
                  {t("scanThisQRCode")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {t("scanThisQRCodeDescription")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* QR Code */}
          <Grid
            item
            container
            xs={12}
            sm={6}
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <img src={qrCode} width={150} height={150} alt="QR Code" />
            </Grid>
            <Grid item>
              <Typography variant="caption" color="textSecondary" fontSize={8}>
                {secretBase32}
              </Typography>
            </Grid>
          </Grid>

          {/* Step 3 */}
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                {t("step") + " 3"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                {t("enterVerificationCode")}
              </Typography>
            </Grid>
          </Grid>

          {/* Verification Code */}
          <Grid item xs={12}>
            <TextInput
              label={t("verificationCode")}
              variant="outlined"
              fullWidth
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomButton
              fullWidth
              onClick={() => onVerifyToken(token)}
              disabled={token.length !== TOTP_TOKEN_LENGTH}
              loading={verifyLoading}
            >
              {t("verifyAndActivate")}
            </CustomButton>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
