import AppContext from "../../context/AppContext";
import CustomSelect from "./CustomSelect";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Select from "../global/inputs/Select";
import {
  FRANCHISE_CENTER_TYPE_ID,
  NUT_CENTER_TYPE_ID,
  OTHER_CENTER_TYPE_ID,
} from "../../data/constants";

function reducer(state, action) {
  switch (action.type) {
    case "SET_VALUE":
      return { ...state, value: action.payload };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    default:
      throw new Error();
  }
}

const CenterSelect = (props) => {
  const {
    autoWidth,
    disabled,
    error,
    grouping,
    helperText,
    label,
    multiple,
    name,
    onChange,
    required,
    searchable,
    value,
  } = props;

  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("centers");
  const [tErrors] = useTranslation("errors");

  const initialState = {
    value: value !== undefined ? value : multiple === true ? [] : "",
    centers: [],
    loading: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // Initial useEffect
  useEffect(() => {
    getCenters();
  }, []);

  useEffect(() => {
    dispatch({
      type: "SET_VALUE",
      payload: typeof value === "number" ? value.toString() : value,
    });
  }, [value]);

  const groupByType = user.hasAction("VIEW_ALL_CENTERS");

  const getCenters = () => {
    dispatch({ type: "SET_LOADING", payload: true });
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const centerOptions = response.data.map((center) => ({
            value: center.id.toString(),
            label: center.name,
            type: center.type,
          }));

          let groupedCenterOptions = [];
          if (groupByType || grouping) {
            const centerTypes = [
              { label: "NUT", type: NUT_CENTER_TYPE_ID },
              { label: t("franchise"), type: FRANCHISE_CENTER_TYPE_ID },
              { label: t("other"), type: OTHER_CENTER_TYPE_ID },
            ];

            groupedCenterOptions = centerTypes.map(({ label, type }) => ({
              label,
              options: centerOptions.filter((option) => option.type === type),
            }));
          }

          dispatch({
            type: "SET_CENTERS",
            payload:
              groupByType || grouping ? groupedCenterOptions : centerOptions,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_LOADING", payload: false }));
  };

  const handleOnChange = (e) => {
    dispatch({
      type: "SET_VALUE",
      payload: e.target.value,
    });
    onChange && onChange(e);
  };

  return (
    <Select
      autoWidth={autoWidth}
      disabled={disabled}
      error={error}
      grouping={groupByType || grouping}
      helperText={helperText}
      label={label || (state.value?.length > 1 ? t("centers") : t("center"))}
      loading={state.loading}
      multiple={multiple}
      name={name}
      onChange={handleOnChange}
      options={state.centers}
      required={required}
      searchable={groupByType || searchable}
      selectAllGroup={multiple && (groupByType || grouping)}
      value={state.value}
    />
  );
};

CenterSelect.propTypes = {
  autoWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  grouping: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  searchable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default CenterSelect;
