import { useContext, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Material UI
import { ButtonGroup, Container, Grid, Typography } from "@mui/material";

// Components & Utils
import { CustomTable } from "../../CustomTable";
import { generateURL } from "../../../utils/url";
import { getParams } from "../../../utils/url";
import AppContext from "../../../context/AppContext";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Button from "../../Inputs/CustomButton";
import ButtonCSV from "../../Inputs/ButtonCSV";
import ButtonLink from "../../Inputs/ButtonLink";
import CancellationRequestStateChip from "./CancellationRequestStateChip";
import CenterSelect from "../../Inputs/CenterSelect";
import CustomSelect from "../../Inputs/CustomSelect";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";

const initialState = {
  boxes: [],
  cancellationRequests: [],
  centers: [],
  customer: "",
  customers: [],
  filteredBoxes: [],
  filters: {
    autoSearch: false,
    boxId: "",
    centers: [],
    customerId: "",
    dateOfPayment: "",
    order: "desc",
    orderBy: "cancellationDate",
    paymentMethod: "",
    state: [0],
  },
  tableOptions: {
    loaded: true,
    rowlink: "contract",
    rowlinkOption: "Contract",
  },
  paymentDays: [],
  paymentMethods: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET_FILTERS":
      return {
        ...state,
        filters: initialState.filters,
        cancellationRequests: [],
        customer: "",
      };
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_CANCELLATION_REQUESTS":
      return { ...state, cancellationRequests: action.payload };
    case "SET_CUSTOMER_FILTER":
      return { ...state, customer: action.payload };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_FILTERED_BOXES":
      return { ...state, filteredBoxes: action.payload };
    case "SET_LOADING":
      return {
        ...state,
        tableOptions: { ...state.tableOptions, loaded: !action.payload },
      };
    case "SET_PAYMENT_DAYS":
      return { ...state, paymentDays: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    default:
      throw new Error("Action not found in reducer");
  }
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CancellationRequestsPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const [t] = useTranslation("contracts");

  const FILTERS = [
    { name: "autoSearch", type: "boolean" },
    { name: "centers", type: "array" },
    { name: "customerId", type: "number" },
    { name: "dateOfPayment", type: "number" },
    { name: "paymentMethod", type: "number" },
    { name: "state", type: "array" },
  ];

  const initState = (state) => ({
    ...state,
    filters: {
      ...state.filters,
      ...getParams(query, FILTERS),
    },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const CANCELLATION_REQUEST_COLUMNS = [
    { label: t("contract"), key: "Contract.publicId" },
    {
      label: t("center"),
      key: "Contract.Box.Center.name",
      sortKey: "Contract.Box.Center.name",
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink
          to={"/app/center/" + item.Contract.Box.centerId}
          size="small"
        >
          {value}
        </ButtonLink>
      ),
    },
    {
      label: t("box"),
      key: "Contract.Box.name",
      sortKey: "Contract.Box.name",
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/box/" + item.Contract.Box.id} size="small">
          {value}
        </ButtonLink>
      ),
    },
    {
      label: t("customer"),
      key: "Contract.Customer.fullName",
      sortKey: "Contract.Customer.fullName",
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink
          to={"/app/customer/" + item.Contract.Customer.id}
          size="small"
        >
          {value}
        </ButtonLink>
      ),
    },
    { label: t("startDate"), key: "Contract.startDate", sortType: "string" },
    {
      label: t("cancellationDate"),
      key: "cancellationDate",
      sortType: "string",
    },
    {
      label: t("applicationDate"),
      key: "createdAt",
      sortType: "string",
      renderFunction: (value) => value.slice(0, 10),
    },
    {
      label: t("cancellationType"),
      key: "CancellationType.name",
      sortType: "string",
    },
    {
      label: t("paymentMethod"),
      key: "Contract.PaymentMethod.name",
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    { label: t("payDay"), key: "Contract.dateOfPayment", sortType: "number" },
    {
      label: t("comments"),
      key: "comments",
      sortType: "string",
    },
    {
      label: t("state"),
      key: "state",
      sortType: "number",
      renderFunction: (value, item) => (
        <CancellationRequestStateChip cancellationRequest={item} />
      ),
    },
    {
      label: t("requestedBy"),
      key: "requestedByUser.name",
      sortType: "string",
    },
    {
      label: t("resolvedAt"),
      key: "resolutionDate",
      sortType: "string",
    },
    {
      label: t("resolutionComments"),
      key: "resolutionComments",
      sortType: "string",
    },
    {
      label: t("resolvedBy"),
      key: "resolvedByUser.name",
      sortType: "string",
    },
  ];

  const CSV_HEADERS = [
    { key: "Contract.publicId", label: t("contract") },
    { key: "Contract.Box.Center.name", label: t("center") },
    { key: "Contract.Box.name", label: t("box") },
    { key: "Contract.Customer.name", label: t("customer") },
    { key: "CancellationType.name", label: t("cancellationType") },
    { key: "Contract.startDate", label: t("startDate") },
    { key: "cancellationDate", label: t("cancellationDate") },
    { key: "state", label: t("state") },
    { key: "Contract.PaymentMethod.name", label: t("paymentMethod") },
    { key: "comments", label: t("comments") },
    { key: "requestedByUser.name", label: t("requestedBy") },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("cancellationRequestsPage");

    getCustomers();
    getBoxes();
    getPaymentDays();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch === true) getCancellationRequests();
  }, [state.filters.autoSearch]);

  useEffect(() => {
    state.filters.centers.length && getFilteredBoxes();
  }, [state.filters.centers, state.boxes]);

  const getCancellationRequests = () => {
    // Change URL parameters
    const url = generateURL("/app/cancellation-requests", state.filters);
    history.replace(url);

    dispatch({ type: "SET_LOADING", payload: true });
    dispatch({ type: "SET_CANCELLATION_REQUESTS", payload: [] });

    let params = {};

    state.filters.centers.length > 0 &&
      (params.centers = state.filters.centers);
    state.filters.state.length > 0 && (params.state = state.filters.state);
    state.filters.dateOfPayment &&
      (params.dateOfPayment = state.filters.dateOfPayment);
    state.filters.paymentMethod !== "" &&
      (params.paymentMethod = state.filters.paymentMethod);
    state.filters.customerId !== "" &&
      (params.customerId = state.filters.customerId);
    state.filters.boxId !== "" && (params.boxId = state.filters.boxId);

    api
      .get("/contracts/cancellation-requests", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADING", payload: false });
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noCancellationRequests"), { variant: "info" });
          dispatch({
            type: "SET_CANCELLATION_REQUESTS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentDays = () => {
    api
      .get("/payment-days/get")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const days = response.data.map((day) => day.day);
          dispatch({ type: "SET_PAYMENT_DAYS", payload: days });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCustomers = () => {
    api
      .get("/customers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (state.filters.customerId) {
            const customer = response.data.find(
              (customer) => customer.id === Number(state.filters.customerId)
            );
            dispatch({ type: "SET_CUSTOMER_FILTER", payload: customer });
          }
          dispatch({ type: "SET_CUSTOMERS", payload: response.data });
        }
      })
      .catch((error) => console.log(error));
  };

  const getBoxes = () => {
    api
      .get("/boxes")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length > 0) {
            dispatch({
              type: "SET_BOXES",
              payload: response.data,
            });
          } else {
            enqueueSnackbar(t("noBoxes"), { variant: "info" });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getFilteredBoxes = () => {
    let res = [];
    state.filters.centers.length &&
      (res = state.boxes
        .filter((box) => state.filters.centers.includes(box.centerId))
        .map((box) => ({
          value: box.id,
          label: box.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)));
    dispatch({ type: "SET_FILTERED_BOXES", payload: res });
  };

  const handleFilterChange = (event) => {
    dispatch({
      type: "SET_FILTER",
      payload: { inputname: event.target.name, value: event.target.value },
    });
  };

  const resetFilters = () => {
    dispatch({
      type: "RESET_FILTERS",
    });
  };

  const search = () => {
    if (state.filters.autoSearch === true) getCancellationRequests();
    else {
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: true },
      });
    }
  };

  return (
    <Container sx={{ marginY: 3 }} maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{t("cancellationRequests")}</Typography>
        </Grid>

        <Grid item container xs={12} spacing={1}>
          <Grid item sx={12} sm="auto">
            <CustomSelect
              name="state"
              label={t("state")}
              value={state.filters.state}
              onChange={handleFilterChange}
              options={[
                { value: 0, label: t("pending") },
                { value: 1, label: t("approved") },
                { value: 2, label: t("denied") },
              ]}
              multiple
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <Autocomplete
              size="large"
              sx={{ minWidth: "200px" }}
              options={state.customers}
              getOptionLabel={(customer) => customer.fullName || ""}
              filterOptions={createFilterOptions({ limit: 10 })}
              value={state.customer || null}
              onChange={(e, customer) => {
                handleFilterChange({
                  target: {
                    value: customer ? customer.id : "",
                    name: "customerId",
                  },
                });
                dispatch({ type: "SET_CUSTOMER_FILTER", payload: customer });
              }}
              name="customer"
              renderInput={(params) => (
                <TextInput {...params} size="small" label={t("customer")} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <CenterSelect
              name="centers"
              value={state.filters.centers}
              onChange={handleFilterChange}
              multiple
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <CustomSelect
              name="boxId"
              onChange={handleFilterChange}
              label={t("box")}
              value={state.filters.boxId}
              options={state.filteredBoxes}
              disabled={!state.filters.centers.length}
            />
          </Grid>

          <Grid item sx={12} sm="auto">
            <CustomSelect
              name="dateOfPayment"
              onChange={handleFilterChange}
              label={t("dateOfPayment")}
              value={state.filters.dateOfPayment}
              options={[
                { value: "", label: t("none") },
                ...state.paymentDays?.map((day) => ({
                  value: day,
                  label: day,
                })),
              ]}
            />
          </Grid>

          <Grid item sx={12} sm="auto">
            <CustomSelect
              name="paymentMethod"
              onChange={handleFilterChange}
              label={t("formOfPayment")}
              value={state.filters.paymentMethod}
              options={state.paymentMethods.map((method) => ({
                label: t(method.name),
                value: method.id,
              }))}
            />
          </Grid>

          <Grid item>
            <ButtonGroup variant="contained" color="primary">
              <Button onClick={resetFilters}>{t("reset")}</Button>
              <ButtonCSV
                data={state.cancellationRequests}
                headers={CSV_HEADERS}
                filename={t("cancellationRequests")}
              />
              <SearchButton
                onClick={search}
                loading={!state.tableOptions.loaded}
              />
            </ButtonGroup>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            columns={CANCELLATION_REQUEST_COLUMNS}
            data={state.cancellationRequests}
            options={state.tableOptions}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
