import {
  Grid,
  Button,
  IconButton,
  Popover,
  CircularProgress,
} from "@mui/material";
import { Rect, Group } from "react-konva";
import { Html } from "react-konva-utils";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import DoorFrontIcon from "@mui/icons-material/DoorFront";
import TextInput from "../../../Inputs/TextInput";

const DoorTile = (props) => {
  const [t] = useTranslation("floorPlans");

  const [anchorEl, setAnchorEl] = useState(null);
  const [interval, setInterval] = useState(5);
  const [loading, setLoading] = useState(false);

  const { openDoor, tile, ...otherProps } = props;
  const editing = otherProps.draggable;

  const handleOpenDoor = () => {
    setLoading(true);
    openDoor(tile.doorId, interval).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Group draggable={editing} {...tile} {...otherProps}>
      {editing ? <Rect width={50} height={50} shadowBlur={10} /> : <></>}
      <Html
        divProps={editing ? { style: { pointerEvents: "none" } } : undefined}
      >
        <div>
          {!loading ? (
            <IconButton
              disabled={openDoor === undefined}
              onClick={(e) => {
                setAnchorEl(e.target);
              }}
            >
              <DoorFrontIcon />
            </IconButton>
          ) : (
            <div
              style={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(50%, 50%)",
              }}
            >
              <CircularProgress size={15} />
            </div>
          )}
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Grid container spacing={2} padding={2} alignItems={"center"}>
              <Grid item>
                <TextInput
                  label={t("interval")}
                  name="interval"
                  type="number"
                  value={interval}
                  onChange={(e) => {
                    setInterval(e.target.value);
                  }}
                  sx={{ width: "75px" }}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    setAnchorEl(null);
                    handleOpenDoor();
                  }}
                >
                  {t("openDoor")}
                </Button>
              </Grid>
            </Grid>
          </Popover>
        </div>
      </Html>
    </Group>
  );
};

export default DoorTile;
