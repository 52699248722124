import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import AppContext from "../../../context/AppContext";
import CenterSelect from "../../Inputs/CenterSelect";
import TextInput from "../../Inputs/TextInput";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  centers: [],
  centerBoxes: [],
  form: {
    name: "",
    centerId: "",
    meters: "",
    royalty: "",
    pricePerMeter: "",
    isLocker: false,
    floor: null,
  },
  inputError: {
    name: false,
    centerId: false,
    meters: false,
    royalty: false,
    pricePerMeter: false,
  },
  id: null,
  loading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_CENTER_BOXES":
      return { ...state, centerBoxes: action.payload };
    case "SET_LOADING":
      return { ...state, loading: !state.loading };
    default:
      throw new Error();
  }
}

export default function CreateBoxPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("boxes");
  const [tErrors] = useTranslation("errors");
  const query = useQuery();
  const centerId = query.get("centerId");

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("createBox");

    getCenters();
  }, []);

  useEffect(() => {
    findNamePattern();
  }, [state.centerBoxes]);

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTERS",
            payload: response.data,
          });
          if (centerId) {
            dispatch({
              type: "SET_INPUT",
              payload: { inputname: "centerId", value: centerId },
            });
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCenterBoxes = (id) => {
    api
      .get("/centers/" + id + "/boxes")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTER_BOXES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const findNamePattern = () => {
    let newName = "";
    if (state.form.centerId !== "") newName = t("box") + " 00";
    if (state.centerBoxes.length > 0) {
      const lastBoxName = state.centerBoxes.pop().name;
      if (/\d+/g.test(lastBoxName)) {
        const numFind = lastBoxName.match(/\d+/g).pop();
        const newNum = parseInt(numFind) + 1;
        newName =
          // Same first part as last name, example:               |Trastero 01|, there goes |Trastero |
          lastBoxName.slice(0, lastBoxName.lastIndexOf(numFind)) +
          // There we check if we need to fill with 0, example:   |Trastero 01|, there goes |0| (we need one "0")
          (numFind.length <= newNum.toString().length
            ? ""
            : "0".repeat(numFind.length - newNum.toString().length)) +
          // And the new number, example:                         |Trastero 01|, there goes |1|
          newNum.toString();
      }
    }
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "name",
        value: newName,
      },
    });
  };

  const submitForm = () => {
    if (validateForm()) {
      dispatch({ type: "SET_LOADING" });
      api
        .post("/boxes/create", state.form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("boxCreateSuccess"), { variant: "success" });
            history.goBack();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
        })
        .finally(() => dispatch({ type: "SET_LOADING" }));
    }
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const validateForm = () => {
    let isValid = true;
    let fields = ["name", "meters", "pricePerMeter", "royalty", "centerId"];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        isValid = false;
      }
    });

    return isValid;
  };

  const handleIsLockerChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "isLocker", value: e.target.checked },
    });
  };

  return (
    <Container maxWidth="md" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createBox")}</Typography>
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <CenterSelect
                autoWidth
                error={state.inputError.centerId}
                helperText={
                  state.inputError.centerId
                    ? t("center") + " " + t("mustNotBeBlank")
                    : ""
                }
                name="centerId"
                value={state.form.centerId}
                onChange={(e) => {
                  handleInputChange(e);
                  dispatch({
                    type: "SET_INPUT",
                    payload: {
                      inputname: "royalty",
                      value: e.target.value
                        ? state.centers.find(
                            (center) => center.id == e.target.value
                          )?.defaultRoyalty
                        : "",
                    },
                  });
                  e.target.value
                    ? getCenterBoxes(e.target.value)
                    : dispatch({
                        type: "SET_CENTER_BOXES",
                        payload: [],
                      });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput
                sx={{ minWidth: 200 }}
                error={state.inputError.name}
                helperText={
                  state.inputError.name
                    ? t("name") + " " + t("mustNotBeBlank")
                    : ""
                }
                label={t("name")}
                name="name"
                value={state.form.name}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextInput
                sx={{ minWidth: 200 }}
                error={state.inputError.meters}
                helperText={
                  state.inputError.meters
                    ? t("meters") + " " + t("mustNotBeBlank")
                    : ""
                }
                label={t("meters") + " (㎡)"}
                type="number"
                name="meters"
                value={state.form.meters}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextInput
                sx={{ minWidth: 200 }}
                error={state.inputError.pricePerMeter}
                helperText={
                  state.inputError.pricePerMeter
                    ? t("pricePerMeter") + " " + t("mustNotBeBlank")
                    : ""
                }
                label={t("pricePerMeter") + " (€)"}
                type="number"
                name="pricePerMeter"
                value={state.form.pricePerMeter}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextInput
                sx={{ minWidth: 200 }}
                error={state.inputError.royalty}
                helperText={
                  state.inputError.royalty
                    ? t("royalty") + " " + t("mustNotBeBlank")
                    : ""
                }
                label={t("royalty") + " (%)"}
                type="number"
                name="royalty"
                value={state.form.royalty}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextInput
                sx={{ minWidth: 200 }}
                label={t("floor")}
                type="number"
                name="floor"
                value={state.form.floor}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControlLabel
                label={t("isLocker")}
                control={
                  <Checkbox
                    name="isLocker"
                    checked={state.form.isLocker}
                    onChange={handleIsLockerChange}
                  />
                }
              />
            </Grid>

            <Grid container item spacing={1} xs={12} justifyContent="flex-end">
              <Grid item>
                <Button onClick={() => history.goBack()}>{t("back")}</Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  disabled={state.loading}
                >
                  {t("create")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
