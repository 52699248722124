import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import TextInput from "./Inputs/TextInput";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomButton from "./Inputs/CustomButton";
import { useState } from "react";

export default function LoginForm({ onLoginClick, loginLoading }) {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("login");

  const [values, setValues] = useState({
    name: "",
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      submit(e);
    }
  };
  const submit = (e) => {
    e.preventDefault();
    if (values.name && values.password) {
      onLoginClick(values.name, values.password);
    } else {
      enqueueSnackbar(t("pleaseWrite"), { variant: "warning" });
    }
  };

  return (
    <Paper sx={{ padding: 3 }}>
      <Grid container spacing={2} direction="column">
        <Grid item container xs={12} justifyContent="center">
          <Grid
            component="img"
            src="/img/NUT_LOGO_3_black_low.png"
            width="125px"
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label={t("username")}
            variant="outlined"
            value={values.name}
            onChange={handleChange("name")}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            label={t("password")}
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            onKeyDown={handleEnter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} textAlign="center">
          <CustomButton color="primary" onClick={submit} loading={loginLoading}>
            {t("login")}
          </CustomButton>
        </Grid>

        <Grid item xs={12} textAlign="end">
          <Typography variant="caption">
            <Link to="reset-password">{t("forgotPassword")}</Link>
          </Typography>
        </Grid>
        {/* <Grid item xs={12} textAlign="end">
          <Typography variant="caption">
            {t("noAccount")} <Link to="register">{t("register")}</Link>
          </Typography>
        </Grid> */}
      </Grid>
    </Paper>
  );
}
