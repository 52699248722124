import { Box, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useContext } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContext";
import CustomButton from "./Inputs/CustomButton";
import { API_ROUTE } from "../utils/API";

const CustomErrorFallback = ({ error, resetErrorBoundary }) => {
  const context = useContext(AppContext);
  const [t] = useTranslation("app");

  useEffect(() => {
    sendError();

    // Print error in console
    console.log(error);
  }, []);

  const sendError = () => {
    if (process.env.NODE_ENV === "production") {
      if (context) {
        context.api
          .post("/error-message", {
            error: `\nPathname: ${
              window.location.pathname + window.location.search
            }\nError stack: ${error.stack}\n`,
            user: context.user,
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axios
          .post(API_ROUTE + "/error-message", {
            error: `\nPathname: ${
              window.location.pathname + window.location.search
            }\nError stack: ${error.stack}\n`,
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const getRandomProgrammer = () => {
    const programmers = ["Joan", "Anna", "Oscar", "Bruno"];
    return programmers[Math.floor(Math.random() * programmers.length)];
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        paddingTop: "64px", // navbar height
        overflow: "auto",
        background:
          "linear-gradient(135deg, rgba(245, 246, 247, 1) 50%, rgba(0,159,255,1) 100%)",
      }}
    >
      <Container maxWidth="lg" sx={{ marginY: 3 }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h4">{t("uncaughtErrorMessage")}</Typography>
          </Grid>
          <Grid item xs={12}>
            {/*<Typography variant="body">
              {t("programmerFaultMessage") +
                " " +
                getRandomProgrammer() +
                "..."}
    </Typography>*/}
          </Grid>
          <Grid item xs={12}>
            <CustomButton onClick={resetErrorBoundary}>
              {t("tryAgain")}
            </CustomButton>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CustomErrorFallback;
