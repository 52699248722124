import { localeFormat } from "../../../utils/format";
import { useContext, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// mui
import { ButtonGroup, Grid, Typography, Tooltip } from "@mui/material";

// Icons
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

// components & utils
import { BILLING_ERROR_STATE_ID } from "../../../data/constants";
import { CustomTable } from "../../CustomTable";
import { formatDate } from "../../../utils/date";
import { getParams, generateURL } from "../../../utils/url";
import AppContext from "../../../context/AppContext";
import Button from "../../Inputs/CustomButton";
import ButtonCSV from "../../Inputs/ButtonCSV";
import ButtonLink from "../../Inputs/ButtonLink";
import CenterSelect from "../../Inputs/CenterSelect";
import CustomSelect from "../../Inputs/CustomSelect";
import ItemsSummary from "../../ItemsSummary";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";

import Filters from "../../global/structure/Filters";
import Page from "../../global/structure/Page";
import TabPanel from "../../global/structure/TabPanel";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  currentTab: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_TAB":
      return { ...state, currentTab: action.payload };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

export default function NonPaymentsPage() {
  const query = useQuery();
  const history = useHistory();
  const { user } = useContext(AppContext);
  const [t] = useTranslation("nonPayments");

  const INVOICE_COLUMNS = [
    {
      key: "Customer.fullName",
      label: t("customerName"),
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/customer/" + item.customerId}>{value}</ButtonLink>
      ),
    },
    {
      key: "number",
      label: t("invoice"),
      sortType: "number",
      renderFunction: (value, item) => item.InvoiceSerie.name + value,
    },
    {
      key: "baseAmount",
      label: t("base"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "totalAmount",
      label: t("total"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "issueDate",
      label: t("issueDate"),
      sortType: "date",
    },
    {
      key: "nonPaymentDate",
      label: t("nonPaymentDate"),
      sortType: "date",
    },
  ];

  const CSV_HEADERS = [
    { label: t("customerName"), key: "name" },
    { label: t("lastCommentDate"), key: "lastCommentDate" },
    { label: t("totalDebt"), key: "totalDebt" },
    { label: t("pendingInvoices"), key: "Invoices.length" },
  ];

  const CSV_INVOICE_HEADERS = [
    { label: t("customerName"), key: "customerName" },
    { label: t("number"), key: "number" },
    { label: t("base"), key: "baseAmount" },
    { label: t("total"), key: "total" },
    { label: t("issueDate"), key: "issueDate" },
    { label: t("nonPaymentDate"), key: "nonPaymentDate" },
    { label: t("recuperationDate"), key: "recuperationDate" },
  ];

  const FILTERS = [
    { name: "autoSearch", type: "string" },
    { name: "customerName", type: "string" },
    { name: "invoiceNumber", type: "string" },
    { name: "centerIds", type: "array" },
    { name: "hasNonPayments", type: "boolean" },
    { name: "number", type: "number" },
    { name: "dateUntil", type: "string" },
    { name: "dateFrom", type: "string" },
    { name: "orderBy", type: "string" },
    { name: "order", type: "string" },
    { name: "recuperateUntil", type: "string" },
    { name: "recuperateFrom", type: "string" },
  ];

  const initState = (state) => ({
    ...state,
    currentTab: parseInt(query.get("tab") ?? state.currentTab),
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const handleTabChange = (event, value) => {
    dispatch({
      type: "SET_CURRENT_TAB",
      payload: value,
    });
  };

  useEffect(() => {
    updateQuery();
  }, [state.currentTab]);

  const updateQuery = () => {
    let params = {
      tab: state.currentTab,
    };
    const url = generateURL("/app/non-payments/", params);
    history.push(url);
  };

  let tabs = [
    {
      label: t("nonPayments"),
      children: (
        <NonPaymentsTab
          state={state}
          history={history}
          CSV_INVOICE_HEADERS={CSV_INVOICE_HEADERS}
          INVOICE_COLUMNS={INVOICE_COLUMNS}
          FILTERS={FILTERS}
        />
      ),
    },
    {
      label: t("recuperations"),
      children: (
        <RecuperationsTab
          state={state}
          history={history}
          CSV_INVOICE_HEADERS={CSV_INVOICE_HEADERS}
          INVOICE_COLUMNS={INVOICE_COLUMNS}
          FILTERS={FILTERS}
        />
      ),
    },
  ];
  if (user.hasAction("VIEW_NONPAYMENT_MANAGEMENT")) {
    tabs.unshift({
      label: t("gestion"),
      children: (
        <GestionTab
          state={state}
          history={history}
          CSV_HEADERS={CSV_HEADERS}
          FILTERS={FILTERS}
        />
      ),
    });
  }

  return (
    <Page browserTitle={t("nonPayments")} title={t("nonPayments")} paper={true}>
      <TabPanel
        selectedTab={state.currentTab}
        onChange={handleTabChange}
        tabs={tabs}
      />
    </Page>
  );
}

function GestionTab(props) {
  const [t] = useTranslation("nonPayments");
  const [tErrors] = useTranslation("errors");
  const query = useQuery();
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { state, history, CSV_HEADERS, FILTERS } = props;

  const gestionReducer = (gestionState, action) => {
    switch (action.type) {
      case "SET_FILTER":
        return {
          ...gestionState,
          filters: {
            ...gestionState.filters,
            [action.payload.inputname]: action.payload.value,
          },
        };
      case "SET_CUSTOMERS":
        return { ...gestionState, customers: action.payload };
      case "SET_LOADED_TRUE":
        return {
          ...gestionState,
          options: { ...gestionState.options, loaded: true },
        };
      case "SET_LOADED_FALSE":
        return {
          ...gestionState,
          options: { ...gestionState.options, loaded: false },
        };
      case "SET_ORDER":
        return {
          ...gestionState,
          filters: {
            ...gestionState.filters,
            orderBy: action.payload.orderBy,
            order: action.payload.order,
          },
        };
      case "RESET_FILTERS":
        return { ...gestionState, filters: initialGestionState.filters };
      default:
        throw new Error("Action type unknown in reducer");
    }
  };

  const initialGestionState = {
    customers: [],
    filters: {
      autoSearch: "false",
      customerName: "",
      invoiceNumber: "",
      centerIds: [],
      hasNonPayments: true,

      orderBy: "",
      order: "asc",

      number: "",
      dateFrom: "",
      dateUntil: "",
    },
    options: {
      loaded: true,
      rowlink: "non-payment",
    },
  };

  const initGestionState = (gestionState) => ({
    ...gestionState,
    filters: { ...gestionState.filters, ...getParams(query, FILTERS) },
  });

  const [gestionState, dispatch] = useReducer(
    gestionReducer,
    initialGestionState,
    initGestionState
  );

  const NON_PAYMENT_COLUMNS = [
    { key: "fullName", label: t("customerName"), sortType: "string" },
    {
      key: "lastCommentDate",
      label: t("lastCommentDate"),
      sortType: "date",
    },
    {
      key: "totalDebt",
      label: t("totalDebt"),
      sortType: "number",
      renderFunction: (totalDebt) => localeFormat(totalDebt) + "€",
    },
    {
      key: "pendingInvoices",
      label: t("pendingInvoices"),
      sortType: "number",
    },
  ];

  useEffect(() => {
    if (gestionState.filters.autoSearch === "true") {
      getCustomers();
    }
  }, [gestionState.filters.autoSearch]);

  const getCustomers = () => {
    updateQuery();
    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      include: ["Invoice", "CustomerComment"],
    };

    // TODO: Add filters

    gestionState.filters.customerName !== "" &&
      (params.name = gestionState.filters.customerName);
    gestionState.filters.invoiceNumber !== "" &&
      (params.invoiceNumber = gestionState.filters.invoiceNumber);
    gestionState.filters.hasNonPayments !== "" &&
      (params.hasNonPayments = gestionState.filters.hasNonPayments);
    gestionState.filters.centerIds.length > 0 &&
      (params.centerIds = gestionState.filters.centerIds);

    api
      .get("/customers-non-payments", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const customers = response.data.map((customer) => ({
            ...customer,
            totalDebt: getTotalDebt(customer.Invoices),
            lastCommentDate: getLastCommentDate(customer.CustomerComments),
            pendingInvoices:
              customer.Invoices?.length > 0
                ? customer.Invoices.filter(
                    (invoice) =>
                      invoice.uncollectible === false &&
                      invoice.state !== BILLING_ERROR_STATE_ID
                  ).length
                : 0,
          }));
          dispatch({ type: "SET_CUSTOMERS", payload: customers });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getMaxDate = (dates) => {
    return new Date(
      Math.max.apply(
        null,
        dates.map((date) => Date.parse(date))
      )
    );
  };

  const getLastCommentDate = (comments) => {
    if (!comments) return "";
    const dates = comments
      .map((comment) => comment.createdAt)
      .sort((a, b) => b - a);
    if (dates.length > 0) {
      const maxDate = getMaxDate(dates);
      return formatDate(maxDate);
    } else {
      return "";
    }
  };

  const getTotalDebt = (invoices) => {
    const filtredInvoices = invoices.filter(
      (invoice) =>
        invoice.uncollectible === false &&
        invoice.state !== BILLING_ERROR_STATE_ID
    );
    return filtredInvoices.reduce((sum, invoice) => {
      return sum + invoice.totalAmount;
    }, 0);
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  const updateQuery = () => {
    let params = {
      tab: state.currentTab,
      ...gestionState.filters,
    };
    const url = generateURL("/app/non-payments/", params);
    history.push(url);
  };

  const search = () => {
    if (gestionState.filters.autoSearch === "true") {
      getCustomers();
    } else {
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
    }
  };

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item>
        <Filters
          filters={[
            <TextInput
              name="customerName"
              onChange={handleChangeFilter}
              value={gestionState.filters.customerName}
              label={t("customer")}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
            />,
            <TextInput
              name="invoiceNumber"
              onChange={handleChangeFilter}
              value={gestionState.filters.invoiceNumber}
              label={t("invoiceNumber")}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
            />,
            <CenterSelect
              multiple
              name="centerIds"
              onChange={handleChangeFilter}
              value={gestionState.filters.centerIds}
            />,
            <CustomSelect
              label={t("hasNonPayments")}
              name="hasNonPayments"
              onChange={handleChangeFilter}
              value={gestionState.filters.hasNonPayments}
              options={[
                { value: "", label: t("all") },
                { value: true, label: t("yes") },
                { value: false, label: t("no") },
              ]}
            />,
          ]}
        />
      </Grid>

      <Grid item xs={12} sm="auto">
        <ButtonGroup variant="contained">
          <Button onClick={resetFilters}>{t("reset")}</Button>
          <ButtonCSV
            data={gestionState.customers}
            headers={CSV_HEADERS}
            filename={t("nonPayments")}
          />
          <SearchButton
            onClick={search}
            loading={!gestionState.options.loaded}
          />
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          columns={NON_PAYMENT_COLUMNS}
          data={gestionState.customers}
          options={gestionState.options}
          sortBy={gestionState.filters.orderBy}
          sort={gestionState.filters.order}
          onSortCallback={(orderBy, order) => {
            handleOrderChange(orderBy, order);
            const url = generateURL("/app/non-payments", {
              ...gestionState.filters,
              orderBy,
              order,
            });
            history.push(url);
          }}
        />
      </Grid>
    </Grid>
  );
}

function NonPaymentsTab(props) {
  const [t] = useTranslation("nonPayments");
  const [tErrors] = useTranslation("errors");
  const query = useQuery();
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { state, history, CSV_INVOICE_HEADERS, INVOICE_COLUMNS, FILTERS } =
    props;

  const INVOICE_NON_PAYMENTS_COLUMNS = [
    ...INVOICE_COLUMNS,
    {
      key: "uncollectible",
      label: t("collectible/uncollectibleInvoice"),
      sortType: "other",
      renderFunction: (value) =>
        value ? (
          <Typography color={"error"}>{t("uncollectible")}</Typography>
        ) : (
          <Typography color={"success"}>{t("collectible")}</Typography>
        ),
    },
    {
      key: "billingError",
      label: t("incorrectlyIssued"),
      sortType: "other",
      renderFunction: (value, item) =>
        value ? (
          <Tooltip title={t("incorrectlyIssued")} placement="top">
            <ReportGmailerrorredIcon />
          </Tooltip>
        ) : (
          <></>
        ),
    },
  ];

  const nonPaymentsReducer = (nonPaymentsState, action) => {
    switch (action.type) {
      case "SET_FILTER":
        return {
          ...nonPaymentsState,
          filters: {
            ...nonPaymentsState.filters,
            [action.payload.inputname]: action.payload.value,
          },
        };
      case "SET_NONPAYMENT_INVOICES":
        return { ...nonPaymentsState, nonPaymentInvoices: action.payload };
      case "SET_LOADED_TRUE":
        return {
          ...nonPaymentsState,
          options: { ...nonPaymentsState.options, loaded: true },
        };
      case "SET_LOADED_FALSE":
        return {
          ...nonPaymentsState,
          options: { ...nonPaymentsState.options, loaded: false },
        };
      case "SET_ORDER":
        return {
          ...nonPaymentsState,
          filters: {
            ...nonPaymentsState.filters,
            orderBy: action.payload.orderBy,
            order: action.payload.order,
          },
        };
      case "RESET_FILTERS":
        return {
          ...nonPaymentsState,
          filters: initialNonPaymentsState.filters,
        };
      default:
        throw new Error("Action type unknown in reducer");
    }
  };

  const initialNonPaymentsState = {
    nonPaymentInvoices: [],
    filters: {
      autoSearch: "false",
      billingError: "",
      centerIds: [],
      customerName: "",
      hasNonPayments: true,
      invoiceNumber: "",
      uncollectible: "",

      orderBy: "",
      order: "",

      dateFrom: "",
      dateUntil: "",
      number: "",
    },
    options: {
      loaded: true,
      rowlink: "non-payment",
    },
  };

  const initNonPaymentsState = (nonPaymentsState) => ({
    ...nonPaymentsState,
    filters: { ...nonPaymentsState.filters, ...getParams(query, FILTERS) },
  });

  const [nonPaymentsState, dispatch] = useReducer(
    nonPaymentsReducer,
    initialNonPaymentsState,
    initNonPaymentsState
  );

  const NON_PAYMENTS_SUMMARY = [
    {
      translatedText: t("invoiceNumber"),
      value: nonPaymentsState.nonPaymentInvoices?.length,
    },
    {
      translatedText: t("total"),
      value:
        localeFormat(
          nonPaymentsState.nonPaymentInvoices?.reduce(
            (value, element) => value + element.totalAmount,
            0
          )
        ).toString() + " €",
    },
    {
      translatedText: t("totalCollectable"),
      value:
        localeFormat(
          nonPaymentsState.nonPaymentInvoices?.reduce(
            (value, element) =>
              value + (!element.uncollectible ? element.totalAmount : 0),
            0
          )
        ).toString() + " €",
    },
    {
      translatedText: t("totalUncollectable"),
      value:
        localeFormat(
          nonPaymentsState.nonPaymentInvoices?.reduce(
            (value, element) =>
              value + (element.uncollectible ? element.totalAmount : 0),
            0
          )
        ).toString() + " €",
    },
  ];

  useEffect(() => {
    if (nonPaymentsState.filters.autoSearch === "true") {
      getInvoices();
    }
  }, [nonPaymentsState.filters.autoSearch]);

  const getInvoices = () => {
    updateQuery();
    dispatch({ type: "SET_LOADED_FALSE" });

    let params = { include: ["NonPayment"] };

    nonPaymentsState.filters.number !== "" &&
      (params.number = nonPaymentsState.filters.number);
    nonPaymentsState.filters.dateUntil !== "" &&
      (params.dateUntil = nonPaymentsState.filters.dateUntil);
    nonPaymentsState.filters.dateFrom !== "" &&
      (params.dateFrom = nonPaymentsState.filters.dateFrom);
    nonPaymentsState.filters.centerIds.length !== 0 &&
      (params.centerIds = nonPaymentsState.filters.centerIds);
    nonPaymentsState.filters.billingError !== "" &&
      (params.billingError =
        nonPaymentsState.filters.billingError === "yes" ? 1 : 0);
    nonPaymentsState.filters.uncollectible !== "" &&
      (params.uncollectible =
        nonPaymentsState.filters.uncollectible === "uncollectible" ? 1 : 0);

    api
      .get("/invoices-non-payments", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          let nonPaymentsInvoices = response.data.filter(
            (data) =>
              data.NonPayments.length !== 0 &&
              data.NonPayments[data.NonPayments.length - 1].recuperationDate ===
                null
          );

          nonPaymentsInvoices.forEach(
            (invoice) =>
              (invoice.nonPaymentDate =
                invoice.NonPayments[
                  invoice.NonPayments.length - 1
                ].createdAt.split("T")[0])
          );

          dispatch({
            type: "SET_NONPAYMENT_INVOICES",
            payload: nonPaymentsInvoices,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  const updateQuery = () => {
    let params = {
      tab: state.currentTab,
      ...nonPaymentsState.filters,
    };
    const url = generateURL("/app/non-payments/", params);
    history.push(url);
  };

  const search = () => {
    if (nonPaymentsState.filters.autoSearch === "true") {
      getInvoices();
    } else {
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
    }
  };

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item>
        <Filters
          filters={[
            <TextInput
              label={t("number/name")}
              value={nonPaymentsState.filters.number}
              name="number"
              onChange={handleChangeFilter}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
            />,
            <CenterSelect
              multiple
              name="centerIds"
              onChange={handleChangeFilter}
              value={nonPaymentsState.filters.centerIds}
            />,
            <TextInput
              label={t("dateFrom")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={nonPaymentsState.filters.dateFrom}
              onChange={handleChangeFilter}
              name="dateFrom"
            />,
            <TextInput
              label={t("dateUntil")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={nonPaymentsState.filters.dateUntil}
              onChange={handleChangeFilter}
              name="dateUntil"
            />,
            <CustomSelect
              name="uncollectible"
              label={t("collectible/uncollectibleInvoice")}
              value={nonPaymentsState.filters.uncollectible}
              onChange={handleChangeFilter}
              options={[
                {
                  label: t("all"),
                  value: "",
                },
                {
                  label: t("uncollectible"),
                  value: "uncollectible",
                },
                {
                  label: t("collectible"),
                  value: "collectible",
                },
              ]}
            />,
            <CustomSelect
              name="billingError"
              label={t("incorrectlyIssued")}
              value={nonPaymentsState.filters.billingError}
              onChange={handleChangeFilter}
              options={[
                {
                  label: t("all"),
                  value: "",
                },
                {
                  label: t("yes"),
                  value: "yes",
                },
                {
                  label: t("no"),
                  value: "no",
                },
              ]}
            />,
          ]}
        />
      </Grid>

      <Grid item xs={12} sm="auto">
        <ButtonGroup variant="contained">
          <Button onClick={resetFilters}>{t("reset")}</Button>
          <ButtonCSV
            data={nonPaymentsState.nonPaymentInvoices}
            headers={CSV_INVOICE_HEADERS}
            filename={t("nonPaymentInvoices")}
          />
          <SearchButton
            onClick={search}
            loading={!nonPaymentsState.options.loaded}
          />
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <ItemsSummary gridItems={NON_PAYMENTS_SUMMARY} />
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          columns={INVOICE_NON_PAYMENTS_COLUMNS}
          data={nonPaymentsState.nonPaymentInvoices}
          sortBy={nonPaymentsState.filters.orderBy}
          sort={nonPaymentsState.filters.order}
          options={{
            rowlink: user.hasPage("INVOICES") ? "invoice" : null,
            loaded: nonPaymentsState.options.loaded,
          }}
          onSortCallback={(orderBy, order) => {
            handleOrderChange(orderBy, order);
            const url = generateURL("/app/non-payments", {
              ...nonPaymentsState.filters,
              orderBy,
              order,
            });
            history.push(url);
          }}
        />
      </Grid>
    </Grid>
  );
}

function RecuperationsTab(props) {
  const [t] = useTranslation("nonPayments");
  const [tErrors] = useTranslation("errors");
  const query = useQuery();
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { state, history, CSV_INVOICE_HEADERS, INVOICE_COLUMNS, FILTERS } =
    props;

  const INVOICE_RECUPERATION_COLUMNS = [...INVOICE_COLUMNS];

  user.hasAction("VIEW_NONPAYMENT_MANAGEMENT") &&
    INVOICE_RECUPERATION_COLUMNS.push({
      key: "NonPayments",
      label: t("recuperatedBy"),
      sortType: "other",
      renderFunction: (value, item) => value[0].recuperatorName,
    });

  INVOICE_RECUPERATION_COLUMNS.push({
    key: "recuperationDate",
    label: t("recuperationDate"),
    sortType: "string",
  });

  const recuperationsReducer = (recuperationsState, action) => {
    switch (action.type) {
      case "SET_FILTER":
        return {
          ...recuperationsState,
          filters: {
            ...recuperationsState.filters,
            [action.payload.inputname]: action.payload.value,
          },
        };
      case "SET_RECUPERATED_INVOICES":
        return { ...recuperationsState, recuperatedInvoices: action.payload };
      case "SET_LOADED_TRUE":
        return {
          ...recuperationsState,
          options: { ...recuperationsState.options, loaded: true },
        };
      case "SET_LOADED_FALSE":
        return {
          ...recuperationsState,
          options: { ...recuperationsState.options, loaded: false },
        };
      case "SET_ORDER":
        return {
          ...recuperationsState,
          filters: {
            ...recuperationsState.filters,
            orderBy: action.payload.orderBy,
            order: action.payload.order,
          },
        };
      case "RESET_FILTERS":
        return {
          ...recuperationsState,
          filters: initialRecuperationsState.filters,
        };
      default:
        throw new Error("Action type unknown in reducer");
    }
  };

  const initialRecuperationsState = {
    recuperatedInvoices: [],
    filters: {
      autoSearch: "false",
      customerName: "",
      invoiceNumber: "",
      centerIds: [],
      hasNonPayments: true,

      orderBy: "",
      order: "",

      number: "",
      dateFrom: "",
      dateUntil: "",
      recuperatorName: "",
      recuperateFrom: "",
      recuperateUntil: "",
    },
    options: {
      loaded: true,
      rowlink: "non-payment",
    },
  };

  const initNonPaymentsState = (recuperationsState) => ({
    ...recuperationsState,
    filters: { ...recuperationsState.filters, ...getParams(query, FILTERS) },
  });

  const [recuperationsState, dispatch] = useReducer(
    recuperationsReducer,
    initialRecuperationsState,
    initNonPaymentsState
  );

  useEffect(() => {
    if (recuperationsState.filters.autoSearch === "true") {
      getInvoices();
    }
  }, [recuperationsState.filters.autoSearch]);

  const RECUPERATIONS_SUMMARY = [
    {
      translatedText: t("invoiceNumber"),
      value: recuperationsState.recuperatedInvoices?.length,
    },
    {
      translatedText: t("totalAmount"),
      value:
        localeFormat(
          recuperationsState.recuperatedInvoices?.reduce(
            (value, element) => value + element.totalAmount,
            0
          )
        ).toString() + " €",
    },
  ];

  const getInvoices = () => {
    updateQuery();
    dispatch({ type: "SET_LOADED_FALSE" });

    let params = { include: ["NonPayment"] };

    recuperationsState.filters.number !== "" &&
      (params.number = recuperationsState.filters.number);
    recuperationsState.filters.dateUntil !== "" &&
      (params.dateUntil = recuperationsState.filters.dateUntil);
    recuperationsState.filters.dateFrom !== "" &&
      (params.dateFrom = recuperationsState.filters.dateFrom);
    recuperationsState.filters.centerIds.length !== 0 &&
      (params.centerIds = recuperationsState.filters.centerIds);
    recuperationsState.filters.recuperatorName !== "" &&
      (params.recuperatorName = recuperationsState.filters.recuperatorName);
    recuperationsState.filters.recuperateUntil !== "" &&
      (params.recuperateUntil = recuperationsState.filters.recuperateUntil);
    recuperationsState.filters.recuperateFrom !== "" &&
      (params.recuperateFrom = recuperationsState.filters.recuperateFrom);

    api
      .get("/invoices-non-payments", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          let recuperatedInvoices = response.data.filter(
            (data) =>
              data.NonPayments?.length !== 0 &&
              data.NonPayments[data.NonPayments.length - 1].recuperationDate !==
                null
          );
          recuperatedInvoices.forEach(
            (invoice) =>
              (invoice.nonPaymentDate =
                invoice.NonPayments[
                  invoice.NonPayments.length - 1
                ].createdAt.split("T")[0])
          );
          recuperatedInvoices.forEach(
            (invoice) =>
              (invoice.recuperationDate =
                invoice.NonPayments[invoice.NonPayments.length - 1]
                  .recuperationDate || "--")
          );
          dispatch({
            type: "SET_RECUPERATED_INVOICES",
            payload: recuperatedInvoices,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleOrderChange = (orderBy, order) => {
    dispatch({
      type: "SET_ORDER",
      payload: {
        orderBy: orderBy,
        order: order,
      },
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  const updateQuery = () => {
    let params = {
      tab: state.currentTab,
      ...recuperationsState.filters,
    };
    const url = generateURL("/app/non-payments/", params);
    history.push(url);
  };

  const search = () => {
    if (recuperationsState.filters.autoSearch === "true") {
      getInvoices();
    } else {
      dispatch({
        type: "SET_FILTER",
        payload: { inputname: "autoSearch", value: "true" },
      });
    }
  };

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item>
        <Filters
          filters={[
            <TextInput
              label={t("number/name")}
              value={recuperationsState.filters.number}
              name="number"
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  search();
                }
              }}
            />,
            <CenterSelect
              multiple
              name="centerIds"
              onChange={handleChangeFilter}
              value={recuperationsState.filters.centerIds}
            />,
            <TextInput
              label={t("dateFrom")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={recuperationsState.filters.dateFrom}
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              name="dateFrom"
            />,
            <TextInput
              label={t("dateUntil")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={recuperationsState.filters.dateUntil}
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              name="dateUntil"
            />,
            user.hasAction("VIEW_NONPAYMENT_MANAGEMENT") && (
              <Grid item>
                <TextInput
                  label={t("recuperatedBy")}
                  value={recuperationsState.filters.recuperatorName}
                  name="recuperatorName"
                  onChange={(e) => {
                    handleChangeFilter(e);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      search();
                    }
                  }}
                />
              </Grid>
            ),
            <TextInput
              label={t("recuperateFrom")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={recuperationsState.filters.recuperateFrom}
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              name="recuperateFrom"
            />,
            <TextInput
              label={t("recuperateUntil")}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={recuperationsState.filters.recuperateUntil}
              onChange={(e) => {
                handleChangeFilter(e);
              }}
              name="recuperateUntil"
            />,
          ]}
        />
      </Grid>

      <Grid item xs={12} sm="auto">
        <ButtonGroup variant="contained">
          <Button onClick={resetFilters}>{t("reset")}</Button>
          <ButtonCSV
            data={recuperationsState.recuperatedInvoices}
            headers={CSV_INVOICE_HEADERS}
            filename={t("recuperatedInvoices")}
          />
          <SearchButton
            onClick={search}
            loading={!recuperationsState.options.loaded}
          />
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <ItemsSummary gridItems={RECUPERATIONS_SUMMARY} />
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          columns={INVOICE_RECUPERATION_COLUMNS}
          data={recuperationsState.recuperatedInvoices}
          sortBy={recuperationsState.filters.orderBy}
          sort={recuperationsState.filters.order}
          options={{
            rowlink: user.hasPage("INVOICES") ? "invoice" : null,
            loaded: recuperationsState.options.loaded,
          }}
          onSortCallback={(orderBy, order) => {
            handleOrderChange(orderBy, order);
            const url = generateURL("/app/non-payments", {
              ...recuperationsState.filters,
              orderBy,
              order,
            });
            history.push(url);
          }}
        />
      </Grid>
    </Grid>
  );
}
