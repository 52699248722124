import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import DescriptionIcon from "@mui/icons-material/Description";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import ButtonGroup from "@mui/material/ButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LayersIcon from "@mui/icons-material/Layers";
import MailIcon from "@mui/icons-material/Mail";
import MeetingRoom from "@mui/icons-material/MeetingRoom";
import Phone from "@mui/icons-material/Phone";
import PublishIcon from "@mui/icons-material/Publish";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewInArIcon from "@mui/icons-material/ViewInAr";

import { CustomTable } from "../../CustomTable";
import { localeFormat } from "../../../utils/format";
import { SERVER } from "../../../utils/API";
import AppContext from "../../../context/AppContext";
import BoxStateChip from "../../BoxStateChip";
import CenterMap from "../../CenterMap";
import CenterTypeSelect from "../../Inputs/CenterTypeSelect";
import CityInput from "../../Inputs/CityInput";
import ConfirmDialog from "../../ConfirmDialog";
import CustomSelect from "../../Inputs/CustomSelect";
import PhoneInput from "../../Inputs/PhoneInput";
import PostalCodeInput from "../../Inputs/PostalCodeInput";
import React, { useContext, useEffect, useReducer } from "react";
import TextInput from "../../Inputs/TextInput";
import Dialog from "../../global/Dialog";

import FloorPlanRender from "../FloorPlans/FloorPlanRender/FloorPlanRender";
import buildRenderData from "../FloorPlans/FloorPlanRender/BuildRenderData";

import mapToken from "../../../data/mapboxToken";
import axios from "axios";
import {
  FRANCHISE_CENTER_TYPE_ID,
  FREE_BOX_STATE_ID,
  TILE_DEFAULT_STROKE_WIDTH,
  TILE_DEFAULT_EDGE_COLOR,
  NUT_CENTER_TYPE_ID,
  TILE_BLUE_COLOR,
  OCCUPIED_BOX_STATE_ID,
  OTHER_CENTER_TYPE_ID,
  UNAVAILABLE_BOX_STATE_ID,
} from "../../../data/constants";
import BoxClusterChip from "../../BoxClusterChip";

const whiteColor = "rgba(250, 250, 250, 0.72)";
const blueColor = "rgba(25, 118, 210, 0.72)";
const redColor = "rgba(255, 42, 32, 0.72)";
const BOX_COLORS_STATES = [blueColor, redColor, whiteColor];

// Import Mapbox token
const mapboxglToken = mapToken;

const initialState = {
  centerOptions: {
    accessControlLevels: [],
    businessName: "",
    callsEmails: [],
    cif: "",
    contractTypes: [],
    defaultContractType: "",
    defaultIssuer: "",
    generalNotificationsEmails: [],
    issuers: [],
    settlementEmails: [],
  },
  form: {
    acquisitionAssetId: "",
    acquisitionDate: "",
    active: "",
    address: "",
    canon: "",
    canonPaid: "",
    city: "",
    closeDate: "",
    comments: "",
    contractSignedAt: "",
    defaultRoyalty: "",
    emails: [],
    longitude: "",
    latitude: "",
    name: "",
    provider: "",
    settlementNonPayments: false,
    type: "",
    hasSettlements: false,
    postalCode: "",
    openingDate: "",
    postalCodeId: "",
    phones: [],
  },
  accessControlLevels: [],
  acquisitionAssets: [],
  floorPlan: null,
  center: {},
  emailModal: { isOpen: false },
  newEmail: { email: "", contact: "" },
  phoneModal: { isOpen: false },
  providers: [],
  newPhone: { number: "", contact: "" },
  inputError: {
    name: false,
    address: false,
    postalCode: false,
    contractSignedAt: false,
    openingDate: false,
    postalCodeId: false,
    cityId: false,
    longitude: false,
    latitude: false,
    defaultRoyalty: false,
    canon: false,
    canonPaid: false,
  },
  anchorEl: null,
  floorPlanAnchorEl: null,
  boxesStateFilter: "",
  boxes: [],
  filter: "",
  filteredBoxes: [],
  cities: [],
  contractTypes: [],
  dialogs: {
    accessControlIsOpen: false,
    confIsOpen: false,
    contractsIsOpen: false,
    invoicingIsOpen: false,
    settlementsIsOpen: false,
    taxDataIsOpen: false,
  },
  isARDialogOpen: false,
  currentImageIndex: 0,
  images: [],
  uploadedImages: [],
  issuers: [],
  confirmDialog: {
    title: "",
    confirmText: "",
    cancelText: "",
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
  options: { rowlink: "box", loaded: false },
  loading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_COORDINATES":
      return {
        ...state,
        form: {
          ...state.form,
          longitude: action.payload[0],
          latitude: action.payload[1],
        },
      };
    case "SET_EMAIL_INPUT":
      return {
        ...state,
        newEmail: {
          ...state.newEmail,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_PHONE_INPUT":
      return {
        ...state,
        newPhone: {
          ...state.newPhone,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_CENTER_OPTIONS_INPUT":
      return {
        ...state,
        centerOptions: {
          ...state.centerOptions,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_CENTER":
      return {
        ...state,
        center: action.payload.center,
        form: action.payload.center,
        centerOptions: action.payload.centerOptions,
      };
    case "SET_CITIES":
      return {
        ...state,
        cities: action.payload.cities,
      };
    case "SET_FLOOR_PLAN_DATA":
      return {
        ...state,
        floorPlan: action.payload,
      };
    case "SET_BOXES_STATE_FILTER":
      return { ...state, boxesStateFilter: action.payload };
    case "OPEN_SNACKBAR":
      return { ...state, snackBar: { ...state.snackBar, isOpen: true } };
    case "CLOSE_SNACKBAR":
      return { ...state, snackBar: { ...state.snackBar, isOpen: false } };
    case "SET_SNACKBAR_MESSAGE":
      return {
        ...state,
        snackBar: { ...state.snackBar, message: action.payload },
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_MODAL":
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          isOpen: action.payload.value,
        },
      };
    case "SET_CONTRACT_TYPES":
      return { ...state, contractTypes: action.payload };
    case "SET_ISSUERS":
      return { ...state, issuers: action.payload };
    case "SET_ACCESS_CONTROL_LEVELS":
      return { ...state, accessControlLevels: action.payload };
    case "SET_ACQUISITION_ASSETS":
      return { ...state, acquisitionAssets: action.payload };
    case "OPEN_MENU":
      return { ...state, anchorEl: action.payload };
    case "CLOSE_MENU":
      return { ...state, anchorEl: null };
    case "OPEN_FLOOR_PLAN":
      return { ...state, floorPlanAnchorEl: action.payload };
    case "CLOSE_FLOOR_PLAN":
      return { ...state, floorPlanAnchorEl: null };
    case "OPEN_DIALOG":
      return {
        ...state,
        dialogs: { ...state.dialogs, [action.payload]: true },
      };
    case "CLOSE_DIALOG":
      return {
        ...state,
        dialogs: { ...state.dialogs, [action.payload]: false },
      };
    case "SET_IMAGES":
      return { ...state, images: action.payload };
    case "SET_UPLOADED_IMAGES":
      return { ...state, uploadedImages: action.payload };
    case "SET_CURRENT_IMAGE_INDEX":
      return { ...state, currentImageIndex: action.payload };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_AR_DIALOG_OPEN":
      return { ...state, isARDialogOpen: action.payload };
    case "RESET_EMAIL_INPUTS":
      return {
        ...state,
        newEmail: initialState.newEmail,
      };
    case "RESET_PHONE_INPUTS":
      return {
        ...state,
        newPhone: initialState.newPhone,
      };
    case "SET_LOADING":
      return { ...state, loading: !state.loading };
    case "SET_BOXES":
      return { ...state, boxes: action.payload };
    case "SET_PROVIDERS":
      return { ...state, providers: action.payload };
    default:
      throw new Error("Action not found in reducer");
  }
}

const CarouselItem = ({ deletePhoto, id, image }) => {
  return (
    <Grid item container xs={12} sx={{ height: 600 }} textAlign="center">
      <Grid item xs={11}>
        <img
          src={SERVER + "/centerImages/" + id + "/" + image}
          alt="Center"
          style={{ height: 600 }}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={() => {
            deletePhoto(image);
          }}
          color="error"
        >
          <DeleteForeverIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const ActionsCells = (contracts, row) => {
  const filtered_contracts = contracts.filter(
    (contract) => contract.state == 1
  );
  return (
    <ButtonGroup>
      {filtered_contracts.length > 0 ? (
        <IconButton
          to={"/app/contract/" + filtered_contracts[0].id}
          size="small"
          color="warning"
          component={Link}
        >
          <EditIcon />
        </IconButton>
      ) : (
        <IconButton
          to={"/app/contract/create?boxId=" + row.id}
          size="small"
          color="success"
          component={Link}
          disabled={row.unavailable}
        >
          <DescriptionIcon />
        </IconButton>
      )}
    </ButtonGroup>
  );
};

export default function CenterPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  let { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("centers");
  const [tErrors] = useTranslation("errors");

  const CENTER_TYPES = [
    {
      label: t("none"),
      value: null,
    },
    {
      label: t("NUT"),
      value: NUT_CENTER_TYPE_ID,
    },
    {
      label: t("franchise"),
      value: FRANCHISE_CENTER_TYPE_ID,
    },
    {
      label: t("other"),
      value: OTHER_CENTER_TYPE_ID,
    },
  ];

  const CENTER_BOXES_COLUMNS = [
    {
      key: "name",
      label: t("name"),
      sortType: "string",
    },
    {
      key: "meters",
      label: t("meters"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "㎡",
    },
    {
      key: "pricePerMeter",
      label: t("pricePerMeter"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "cluster.price",
      label: t("clusterPriceWithoutVAT"),
      sortType: "number",
      renderFunction: (value) => (value ? localeFormat(value) + "€" : "-"),
    },
    {
      key: "cluster",
      label: t("type"),
      sortType: "other",
      renderFunction: (value) => {
        if (!value) return "-";
        return <BoxClusterChip name={String(value.name)} />;
      },
    },
    {
      key: "state",
      label: t("state"),
      sortType: "other",
      renderFunction: (value) => <BoxStateChip state={value} />,
    },
    {
      key: "Contracts",
      label: t("actions"),
      sortType: "other",
      renderFunction: ActionsCells,
    },
  ];

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleCenterOptionsChange = (e) => {
    dispatch({
      type: "SET_CENTER_OPTIONS_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("center");

    getCenter();
    getCities();
    getContractTypes();
    getIssuers();
    getImages();
    getFloorPlan();
    user.hasAction("VIEW_ACQUISITION_ASSETS") && getAcquisitionAssets();
    user.hasAction("MANAGE_ACCESS_CONTROL") && getAccessControlLevels();
  }, []);

  const getImages = () => {
    api
      .get("/centers/" + id + "/images")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_IMAGES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCenter = () => {
    dispatch({ type: "SET_LOADED_FALSE" });

    const params = {
      include: ["Box", "Contract", "BoxCluster", "AccessControlLevelCenter"],
    };

    api
      .get("/centers/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const boxes = response.data.Boxes;
          response.data.emails = response.data.CenterEmails;
          response.data.phones = response.data.CenterPhoneNumbers;
          if (response.data.PostalCode)
            response.data.postalCode = response.data.PostalCode.number;
          response.data.city = response.data.City;

          response.data.centerOptions = {
            businessName: response.data.businessName,
            cif: response.data.cif,
            fiscalAddress: response.data.fiscalAddress,
            contractTypes: response.data.ContractTypes?.map((type) => type.id),
            defaultContractType: response.data.defaultContractType,
            defaultIssuer: response.data.defaultIssuer,
            issuers: response.data.Issuers?.map((issuer) => issuer.id),
            accessControlLevels: response.data.AccessControlLevelCenters?.map(
              (a) => a.accessLevelId
            ),

            //emails
            settlementEmails: response.data.CenterEmails?.filter(
              (email) => email.settlementEmail
            ).map((email) => email.id),

            callsEmails: response.data.CenterEmails?.filter(
              (email) => email.callsEmail
            ).map((email) => email.id),

            generalNotificationsEmails: response.data.CenterEmails?.filter(
              (email) => email.generalNotificationsEmail
            ).map((email) => email.id),
          };
          dispatch({
            type: "SET_CENTER",
            payload: {
              center: response.data,
              centerOptions: response.data.centerOptions,
            },
          });
          dispatch({
            type: "SET_BOXES",
            payload: boxes,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_LOADED_TRUE" }));
  };

  const getCities = () => {
    api
      .get("/cities")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_CITIES",
            payload: { cities: response.data },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getContractTypes = () => {
    api
      .get("/contracts/types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_CONTRACT_TYPES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getIssuers = () => {
    api
      .get("/issuers")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_ISSUERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getAccessControlLevels = () => {
    api
      .get("/access-control/levels")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const availableLevels = response.data.filter(
            (level) =>
              level.centerId === null || Number(level.centerId) === Number(id)
          );

          dispatch({
            type: "SET_ACCESS_CONTROL_LEVELS",
            payload: availableLevels,
          });
          const centerAccessControlLevelIds = response.data
            .filter((level) => Number(level.centerId) === Number(id))
            .map((level) => level.id);
          handleCenterOptionsChange({
            target: {
              name: "accessControlLevels",
              value: centerAccessControlLevelIds,
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getAcquisitionAssets = () => {
    let params = { include: ["AcquisitionTracker", "Center"] };

    api
      .get("/acquisitions", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          response.data.sort((a, b) =>
            a.name.toUpperCase() !== b.name.toUpperCase()
              ? a.name.toUpperCase() > b.name.toUpperCase()
                ? 1
                : -1
              : 0
          );
          dispatch({
            type: "SET_ACQUISITION_ASSETS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getFloorPlan = () => {
    const params = {
      include: ["FloorPlanTile", "FloorPlanLayer", "Box"],
    };
    api
      .get("/floor-plans/" + id, { params })
      .then((response) => {
        console.log("response", response.data);
        if (response.data.error) {
          console.error(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const floorPlan = response.data[0];
          floorPlan?.FloorPlanLayers.forEach((layer) => {
            layer.FloorPlanTiles.forEach((tile) => {
              if (tile.color === null && tile.Boxes.length) {
                tile.color =
                  tile.Boxes[0].state > -1
                    ? BOX_COLORS_STATES[tile.Boxes[0].state]
                    : BOX_COLORS_STATES[2];
              }
            });
          });
          dispatch({ type: "SET_FLOOR_PLAN_DATA", payload: floorPlan ?? null });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const submitForm = () => {
    if (validateForm()) {
      let data = {};

      data.name = state.form.name;
      data.cityId = state.form.city?.id || null;
      data.postalCode = state.form.postalCode;
      data.address = state.form.address;
      data.defaultRoyalty = state.form.defaultRoyalty;
      data.canon = state.form.canon;
      data.canonPaid = state.form.canonPaid;
      data.contractSignedAt = state.form.contractSignedAt;
      data.openingDate = state.form.openingDate;
      data.longitude = state.form.longitude;
      data.latitude = state.form.latitude;
      data.phones = state.form.phones;
      data.comments = state.form.comments;
      data.active = state.form.active;
      data.type = state.form.type === "" ? null : state.form.type;
      data.acquisitionAssetId = state.form.acquisitionAssetId || null;
      if (data.type === 0) {
        data.acquisitionDate = state.form.acquisitionDate;
      } else {
        data.acquisitionDate = null;
      }

      state.form.closeDate != "" && (data.closeDate = state.form.closeDate);

      dispatch({ type: "SET_LOADING" });
      api
        .post("/centers/edit/" + id, data)
        .then((response) => {
          if (response.data.error) {
            console.error(response.data.msg);
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("centerEditSuccess"), { variant: "success" });
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        })
        .finally(() => dispatch({ type: "SET_LOADING" }));
    }
  };

  const handleOpenEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: true,
      },
    });
  };

  const handleCloseEmailModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "emailModal",
        value: false,
      },
    });
    dispatch({ type: "RESET_EMAIL_INPUTS" });
  };

  const handleOpenPhoneModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "phoneModal",
        value: true,
      },
    });
  };

  const handleClosePhoneModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "phoneModal",
        value: false,
      },
    });
    dispatch({ type: "RESET_PHONE_INPUTS" });
  };

  const handleChangeCoordinates = (l, ll) => {
    dispatch({
      type: "SET_COORDINATES",
      payload: [l, ll],
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const closeARDialog = () => {
    dispatch({ type: "SET_AR_DIALOG_OPEN", payload: false });
  };

  const openARDialog = () => {
    dispatch({ type: "SET_AR_DIALOG_OPEN", payload: true });
  };

  const openConfirmCenter = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteCenterQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: state.form.name,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteCenter();
        },
      },
    });
  };

  const openConfirmEmail = (email) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteEmailQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText:
          email.email + (email.contact !== null ? " - " + email.contact : ""),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteEmail(email);
          resetConfirmDialog();
        },
      },
    });
  };

  const openConfirmPhone = (phone) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deletePhoneQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText:
          phone.number + (phone.contact !== null ? " - " + phone.contact : ""),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deletePhone(phone);
          resetConfirmDialog();
        },
      },
    });
  };

  const openFloorPlanMenu = () => {
    dispatch({
      type: "OPEN_FLOOR_PLAN",
      payload: true,
    });
  };

  const closeFloorPlanMenu = () => {
    dispatch({
      type: "CLOSE_FLOOR_PLAN",
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: { inputname: name },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: { inputname: name },
    });
  };

  const validateForm = () => {
    let isValid = true;
    if (state.form.name === "") {
      setInputErrorTrue("name");
      enqueueSnackbar(t("name") + " " + t("isRequired"), { variant: "error" });
      isValid = false;
    }

    return isValid;
  };

  const validateEmail = (emailVal) => {
    let isValid = true;
    const re = /\S+@\S+\.\S+/; //Regex for testing email format

    if (emailVal !== null && emailVal !== "") {
      if (!emailVal || emailVal === "" || !re.test(emailVal)) {
        isValid = false;
      }
      state.form.emails.forEach((email) => {
        if (email.email.localeCompare(emailVal) === 0) {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }

    return isValid;
  };

  const validateNumber = (phoneNumber) => {
    let isValid = true;

    if (phoneNumber !== null && phoneNumber !== "") {
      state.form.phones.forEach((phone) => {
        if (phone.number.localeCompare(phoneNumber) === 0) {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }

    return isValid;
  };

  const validatePhone = (phone) => {
    let isValid = true;
    if (!phone || phone === "") {
      isValid = false;
    }
    return isValid;
  };

  const validateContact = (value) => {
    let isValid = true;
    if (!value || value === "") {
      isValid = false;
    }
    return isValid;
  };

  const handleCommentChange = (e) => {
    handleInputChange({ target: { name: "comments", value: e.target.value } });
  };

  const addEmail = () => {
    if (validateEmail(state.newEmail.email)) {
      let form = {
        email: state.newEmail.email,
        centerId: id,
        contact: state.newEmail.contact,
      };

      api
        .post("/centers/" + id + "/email", form)
        .then((response) => {
          if (response.data.error) {
            console.error(response.data.msg);
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("emailAdded"), { variant: "success" });
            handleCloseEmailModal();
            dispatch({
              type: "SET_INPUT",
              payload: {
                inputname: "emails",
                value: [...state.form.emails, response.data],
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else enqueueSnackbar(t("invalidEmail"), { variant: "warning" });
  };

  const addPhone = () => {
    if (validateNumber(state.newPhone.number)) {
      let form = {
        number: state.newPhone.number,
        centerId: id,
        contact: state.newPhone.contact,
      };

      api
        .post("/centers/" + id + "/phone", form)
        .then((response) => {
          if (response.data.error) {
            console.error(response.data.msg);
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("phoneAdded"), { variant: "success" });
            handleClosePhoneModal();
            dispatch({
              type: "SET_INPUT",
              payload: {
                inputname: "phones",
                value: [...state.form.phones, response.data],
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const deleteEmail = (email) => {
    api
      .delete("/centers/" + email.id + "/email")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("emailDeleted"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "emails",
              value: state.form.emails.filter((e) => email.id != e.id),
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deletePhone = (phone) => {
    api
      .delete("/centers/" + phone.id + "/phone")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("phoneDeleted"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "phones",
              value: state.form.phones.filter((p) => phone.id != p.id),
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const openMenu = (e) => {
    dispatch({
      type: "OPEN_MENU",
      payload: e.currentTarget,
    });
  };

  const closeMenu = () => {
    dispatch({ type: "CLOSE_MENU" });
  };

  const openDialog = (name) => {
    dispatch({ type: "OPEN_DIALOG", payload: name });
  };

  const closeDialog = (name) => {
    dispatch({ type: "CLOSE_DIALOG", payload: name });
  };

  const updateCenterAccessControlLevels = () => {
    api
      .post("/centers/" + id + "/access-control-levels", {
        accessControlLevels: state.centerOptions.accessControlLevels,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("centerAccessControlLevelsUpdatedSuccessfully"), {
            variant: "success",
          });
          closeDialog("accessControlIsOpen");
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const updateNotifications = () => {
    let form = {
      generalNotificationsEmails:
        state.centerOptions.generalNotificationsEmails,
      settlementEmails: state.centerOptions.settlementEmails,
      callsEmails: state.centerOptions.callsEmails,
    };

    api
      .post("/centers/" + id + "/emails", form)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("notificationEmailsEditedSuccessfully"), {
            variant: "success",
          });
          closeDialog("confIsOpen");
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const updateContractTypes = () => {
    api
      .post("/centers/" + id + "/contracts", {
        defaultContractType: state.centerOptions.defaultContractType,
        contractTypes: state.centerOptions.contractTypes,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("centerContractTypesUpdatedSuccessfully"), {
            variant: "success",
          });
          closeDialog("contractsIsOpen");
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const updateIssuers = () => {
    api
      .post("/centers/" + id + "/invoicing", {
        issuers: state.centerOptions.issuers,
        defaultIssuer: state.centerOptions.defaultIssuer,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("centerIssuersUpdatedSuccessfully"), {
            variant: "success",
          });
          closeDialog("invoicingIsOpen");
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const updateTaxData = () => {
    api
      .post("/centers/" + id + "/tax-data", {
        businessName: state.centerOptions.businessName,
        cif: state.centerOptions.cif,
        fiscalAddress: state.centerOptions.fiscalAddress,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("centerTaxDataUpdatedSuccessfully"), {
            variant: "success",
          });
          closeDialog("taxDataIsOpen");
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const updateSettlementsData = () => {
    api
      .post("/centers/" + id + "/settlements-data", {
        hasSettlements: state.form.hasSettlements,
        settlementNonPayments: state.form.settlementNonPayments,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("settlementDataUpdated"), { variant: "success" });
          closeDialog("settlementsIsOpen");
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const setPhotos = (e) => {
    let photos = [];
    for (let i = 0; i < e.target.files.length; i++) {
      photos.push(e.target.files[i]);
    }
    dispatch({ type: "SET_UPLOADED_IMAGES", payload: photos });
  };

  const uploadPhotos = () => {
    let formData = new FormData();

    if (state.uploadedImages.length === 0) {
      enqueueSnackbar(t("noImagesSelected"), { variant: "warning" });
      return;
    }
    state.uploadedImages.forEach((image) => {
      formData.append("centerImage", new File([image], "centerImage"));
    });
    dispatch({ type: "SET_LOADING" });
    api
      .post("/centers/" + id + "/images/upload", formData)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          getImages();
          dispatch({ type: "SET_UPLOADED_IMAGES", payload: [] });
          enqueueSnackbar(t("photosUploadedSuccessfully"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_LOADING" }));
  };

  const deletePhoto = (image) => {
    api
      .delete("/centers/" + id + "/images/" + image)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          getImages();
          enqueueSnackbar(t("photoDeletedSuccessfully"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const deleteCenter = () => {
    api
      .delete("/centers/" + id)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("centerDeletedSuccessfully"), {
            variant: "success",
          });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleEmailInputChange = (e) => {
    dispatch({
      type: "SET_EMAIL_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value.trim(),
      },
    });
  };

  const handlePhoneInputChange = (e) => {
    dispatch({
      type: "SET_PHONE_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const getCoordinatesFromAddress = () => {
    if (
      !state.form.address &&
      !state.form.city?.name &&
      !state.form.postalCode
    ) {
      return;
    }

    let a = [];
    let l, ll;
    state.form.address && a.push(state.form.address);
    state.form.postalCode && a.push(state.form.postalCode);
    state.form.city?.name && a.push(state.form.city.name);

    const route =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      encodeURIComponent(a.join(", ")) +
      ".json" +
      "?access_token=" +
      mapToken +
      "&autocomplete=true&country=es&language=es";

    axios
      .get(route)
      .then((response) => {
        [l, ll] = response.data?.features[0]?.center || [0, 0];
        handleChangeCoordinates(l, ll);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  const openConfirmImage = (image, i) => {
    dispatch({ type: "SET_CURRENT_IMAGE_INDEX", payload: i });
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteImageQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deletePhoto(image);
        },
      },
    });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item container xs={12} spacing={1}>
            <Grid item>
              <Typography variant="h4">
                {t("editCenter")} - {state.center.name}
              </Typography>
            </Grid>
            <Grid item flex={1} justifyContent="flex-end" display="flex">
              {state.center.ARViewLink && (
                <Tooltip title={t("3DView")} placement="top">
                  <IconButton color="primary" onClick={openARDialog}>
                    <ViewInArIcon />
                  </IconButton>
                </Tooltip>
              )}

              {user.hasAction("CREATE_CONTRACTS") && (
                <Tooltip title={t("createContract")} placement="top">
                  <IconButton
                    color="success"
                    component={Link}
                    to={"/app/contract/create?centerId=" + id}
                  >
                    <DescriptionIcon />
                  </IconButton>
                </Tooltip>
              )}

              {user.hasAction("CREATE_BOXES") && (
                <Tooltip title={t("createBox")} placement="top">
                  <IconButton
                    color="primary"
                    component={Link}
                    to={"/app/box/create?centerId=" + id}
                  >
                    <MeetingRoom />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title={t("viewOccupancy")} placement="top">
                <IconButton
                  color="primary"
                  component={Link}
                  to={`/app/occupancy?centerId=${id}`}
                >
                  <EqualizerIcon />
                </IconButton>
              </Tooltip>

              {state.floorPlan && (
                <Tooltip title={t("floorPlan")} placement="top">
                  <IconButton color="primary" onClick={openFloorPlanMenu}>
                    <LayersIcon />
                  </IconButton>
                </Tooltip>
              )}

              {user.hasAction("EDIT_CENTERS") && (
                <Tooltip title={t("centerOptions")} placement="top">
                  <IconButton
                    color="primary"
                    aria-controls="menu"
                    aria-haspopup="true"
                    onClick={openMenu}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              )}

              <Menu
                id="menu"
                anchorEl={state.anchorEl}
                keepMounted
                open={Boolean(state.anchorEl)}
                onClose={closeMenu}
              >
                {user.hasAction("MANAGE_ACCESS_CONTROL") && (
                  <MenuItem
                    onClick={() => {
                      openDialog("accessControlIsOpen");
                      closeMenu();
                    }}
                  >
                    {t("accessControl")}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    openDialog("contractsIsOpen");
                    closeMenu();
                  }}
                >
                  {t("contracts")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    openDialog("invoicingIsOpen");
                    closeMenu();
                  }}
                >
                  {t("invoicing")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    openDialog("confIsOpen");
                    closeMenu();
                  }}
                >
                  {t("notifications")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    openDialog("settlementsIsOpen");
                    closeMenu();
                  }}
                >
                  {t("settlements")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    openDialog("taxDataIsOpen");
                    closeMenu();
                  }}
                >
                  {t("taxData")}
                </MenuItem>
              </Menu>

              {user.hasAction("DELETE_CENTERS") && (
                <Tooltip title={t("deleteCenter")} placement="top">
                  <IconButton
                    color="error"
                    onClick={() => {
                      openConfirmCenter();
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} md={6}>
              {user.hasAction("EDIT_CENTERS") ? (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextInput
                        error={state.inputError.name}
                        helperText={
                          state.inputError.name
                            ? t("name") + " " + t("mustNotBeBlank")
                            : ""
                        }
                        label={t("name")}
                        value={state.form.name}
                        onChange={handleInputChange}
                        name="name"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <CityInput
                        label={t("city")}
                        value={state.form.city}
                        onChange={handleInputChange}
                        name="city"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PostalCodeInput
                        label={t("postalCode")}
                        value={state.form.postalCode}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        label={t("address")}
                        value={state.form.address}
                        onChange={handleInputChange}
                        name="address"
                      />
                    </Grid>
                    {user.hasAction("VIEW_ACQUISITION_ASSETS") &&
                      state.form.type === 0 && (
                        <>
                          <Grid item xs={12} md={6}>
                            <CustomSelect
                              label={t("acquisitionAsset")}
                              value={state.form.acquisitionAssetId}
                              onChange={handleInputChange}
                              name="acquisitionAssetId"
                              options={[
                                { value: "", label: t("none") },
                                ...state.acquisitionAssets?.map((asset) => ({
                                  value: asset.id,
                                  label: asset.name + " | " + asset.address,
                                  disabled:
                                    asset.Center === null ? false : true,
                                })),
                              ]}
                            />
                          </Grid>
                        </>
                      )}
                    {state.form.type === 0 && (
                      <Grid item xs={12} md={6}>
                        <TextInput
                          name="acquisitionDate"
                          label={t("acquisitionDate")}
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={state.form.acquisitionDate}
                          onChange={handleInputChange}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                      <TextInput
                        name="contractSignedAt"
                        label={t("contractSigningDate")}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={state.form.contractSignedAt}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextInput
                        name="openingDate"
                        label={t("openingDate")}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={state.form.openingDate}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    {user.hasAction("EDIT_CENTERS") && (
                      <Grid item xs={12} md={4}>
                        <TextInput
                          name="closeDate"
                          label={t("closingDate")}
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={state.form.closeDate}
                          onChange={handleInputChange}
                        />
                      </Grid>
                    )}

                    {state.form.type === 1 && (
                      <Grid item xs={6}>
                        <TextInput
                          error={state.inputError.defaultRoyalty}
                          helperText={
                            state.inputError.defaultRoyalty
                              ? t("defaultRoyalty") + " " + t("mustNotBeBlank")
                              : ""
                          }
                          label={t("defaultRoyalty")}
                          value={state.form.defaultRoyalty}
                          name="defaultRoyalty"
                          onChange={handleInputChange}
                          type="number"
                          inputProps={{ min: 0 }}
                        />
                      </Grid>
                    )}
                    {false &&
                      state.form.type === 1 && ( // TMP: false added to hide canon field
                        <Grid item xs={6}>
                          <TextInput
                            error={state.inputError.canon}
                            helperText={
                              state.inputError.canon
                                ? t("canon") + " " + t("mustNotBeBlank")
                                : ""
                            }
                            label={t("canon")}
                            value={state.form.canon}
                            name="canon"
                            onChange={handleInputChange}
                            type="number"
                            inputProps={{ min: 0 }}
                          />
                        </Grid>
                      )}

                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(state.form.active)}
                            onChange={(e) => {
                              handleInputChange({
                                target: {
                                  value: e.target.checked,
                                  name: e.target.name,
                                },
                              });
                            }}
                            name="active"
                          />
                        }
                        label={t("active")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CenterTypeSelect
                        name="type"
                        onChange={handleInputChange}
                        value={state.form.type}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body" sx={{ fontWeight: "bold" }}>
                        {t("name") + ": "}
                      </Typography>
                      <Typography variant="body">
                        {" "}
                        {state.form.name}{" "}
                      </Typography>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={12}>
                        <Typography variant="body" sx={{ fontWeight: "bold" }}>
                          {t("city") + ": "}
                        </Typography>
                        <Typography variant="body">
                          {state.form.City?.name ? state.form.City.name : "---"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body" sx={{ fontWeight: "bold" }}>
                          {t("address") + ": "}
                        </Typography>
                        <Typography variant="body">
                          {" "}
                          {state.form.address}{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body" sx={{ fontWeight: "bold" }}>
                          {t("postalCode") + ": "}
                        </Typography>
                        <Typography variant="body">
                          {state.form.postalCode
                            ? state.form.postalCode
                            : "---"}
                        </Typography>
                      </Grid>
                    </Grid>
                    {state.form.type === 0 && (
                      <Grid item container>
                        <Grid item xs={12}>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("acquisitionAsset") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {state.form.acquisitionAssetId
                              ? state.acquisitionAssets.find(
                                  (asset) =>
                                    (asset.id = state.form.acquisitionAssetId)
                                )?.name
                              : " ---"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("acquisitionDate") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {state.form.acquisitionDate
                              ? state.form.acquisitionDate
                              : "---"}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item container>
                      <Grid item xs={12}>
                        <Typography variant="body" sx={{ fontWeight: "bold" }}>
                          {t("contractSigningDate") + ": "}
                        </Typography>
                        <Typography variant="body">
                          {state.form.contractSignedAt
                            ? state.form.contractSignedAt
                            : "---"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body" sx={{ fontWeight: "bold" }}>
                          {t("openingDate") + ": "}
                        </Typography>
                        <Typography variant="body">
                          {state.form.openingDate
                            ? state.form.openingDate
                            : "---"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body" sx={{ fontWeight: "bold" }}>
                        {t("active") + ": "}
                      </Typography>
                      <Typography variant="body">
                        {state.form.active ? t("yes") : t("no")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body" sx={{ fontWeight: "bold" }}>
                        {t("type") + ": "}
                      </Typography>
                      <Typography variant="body">
                        {state.form.type !== ""
                          ? CENTER_TYPES[state.form.type]?.label
                          : "---"}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2} item xs={12} md={6}>
              <Grid item xs={12}>
                <CenterMap
                  lat={state.form.latitude}
                  lng={state.form.longitude}
                />
              </Grid>
              <Grid item xs={12} sm>
                <Button
                  sx={{ minWidth: 100 }}
                  fullWidth
                  variant="contained"
                  onClick={getCoordinatesFromAddress}
                >
                  {t("searchLocation")}
                </Button>
              </Grid>
              <Grid item xs={12} sm>
                <TextInput
                  sx={{ minWidth: 100 }}
                  label={t("latitude")}
                  value={state.form.latitude}
                  name="latitude"
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm>
                <TextInput
                  sx={{ minWidth: 100 }}
                  label={t("longitude")}
                  value={state.form.longitude}
                  name="longitude"
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Emails */}
            <Grid container item xs={12}>
              <Grid item>
                <Typography
                  variant="h5"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <MailIcon />
                  {t("emails")}
                </Typography>
              </Grid>
              {user.hasAction("EDIT_CENTERS") && (
                <Grid item flex={1} justifyContent="flex-end" display="flex">
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleOpenEmailModal}
                  >
                    {t("addEmail")}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {state.form.emails?.map((email) => (
                <List key={email.email}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid item container xs={12}>
                        <Grid item>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("address") + ": "}
                          </Typography>
                          <Typography variant="body">{email?.email}</Typography>
                          <Grid item xs={12}></Grid>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("contact") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {email.contact ? email.contact : "---"}
                          </Typography>
                        </Grid>
                        {user.hasAction("EDIT_CENTERS") && (
                          <Grid
                            item
                            flex={1}
                            alignItems="flex-start"
                            justifyContent={"flex-end"}
                            display="flex"
                          >
                            <IconButton
                              onClick={() => {
                                openConfirmEmail(email);
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </List>
              ))}
              {state.form.emails.length == 0 && t("noEmails")}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Phones */}
            <Grid item container xs={12}>
              <Grid item>
                <Typography
                  variant="h6"
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <Phone />
                  {t("phoneNumber")}
                </Typography>
              </Grid>
              {user.hasAction("EDIT_CENTERS") && (
                <Grid item flex={1} justifyContent="flex-end" display="flex">
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleOpenPhoneModal}
                  >
                    {t("addPhone")}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {state.form.phones?.map((phone) => (
                <List key={phone.number}>
                  <Card variant="outlined" xs={6}>
                    <CardContent>
                      <Grid item container xs={12}>
                        <Grid item>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("number") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {phone?.number}
                          </Typography>
                          <Grid item xs={12}></Grid>
                          <Typography
                            variant="body"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("contact") + ": "}
                          </Typography>
                          <Typography variant="body">
                            {phone.contact ? phone.contact : "---"}
                          </Typography>
                        </Grid>
                        {user.hasAction("EDIT_CENTERS") && (
                          <Grid
                            item
                            flex={1}
                            alignItems="flex-start"
                            justifyContent="flex-end"
                            display="flex"
                          >
                            <IconButton
                              onClick={() => {
                                openConfirmPhone(phone);
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </List>
              ))}
              {state.form.phones.length == 0 && t("noPhones")}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">{t("comments")}</Typography>
            </Grid>
            <Grid item xs={12}>
              {user.hasAction("EDIT_CENTERS") ? (
                <TextInput
                  label="Comments"
                  multiline
                  minRows={4}
                  value={state.form.comments}
                  onChange={handleCommentChange}
                />
              ) : (
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: state.form.comments }}
                />
              )}
            </Grid>

            {user.hasAction("VIEW_BOXES") && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h5">{t("boxes")}</Typography>
                  <CustomSelect
                    label={t("state")}
                    value={state.filter}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_BOXES_STATE_FILTER",
                        payload: e.target.value,
                      });
                    }}
                    options={[
                      { value: "", label: t("all") },
                      { value: FREE_BOX_STATE_ID, label: t("free") },
                      { value: OCCUPIED_BOX_STATE_ID, label: t("occupied") },
                      {
                        value: UNAVAILABLE_BOX_STATE_ID,
                        label: t("unavailable"),
                      },
                    ]}
                    name="state"
                  />
                </Grid>

                <Grid item xs={12}>
                  <CustomTable
                    columns={CENTER_BOXES_COLUMNS}
                    data={state.boxes?.filter(
                      (box) =>
                        state.boxesStateFilter === "" ||
                        box?.state === state.boxesStateFilter
                    )}
                    options={state.options}
                  />
                </Grid>
              </>
            )}

            {state.images?.length > 0 && (
              <Grid item xs={12}>
                <Carousel
                  navButtonsAlwaysVisible
                  indicators={false} //error in displaying indicators
                  fullHeightHover={false}
                  autoPlay={false}
                  index={state.currentImageIndex}
                >
                  {state.images?.map((image, i) => (
                    <CarouselItem
                      key={i}
                      id={id}
                      image={image}
                      deletePhoto={() => openConfirmImage(image, i)}
                    />
                  ))}
                </Carousel>
              </Grid>
            )}

            <Grid item container spacing={1}>
              <Grid item>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<PublishIcon />}
                >
                  {t("chooseImages")}
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(e) => {
                      setPhotos(e);
                    }}
                    multiple
                  />
                </Button>
              </Grid>

              <Grid item>
                <List>
                  {state.uploadedImages.length > 0 &&
                    state.uploadedImages.map((image) => (
                      <ListItem>{image.name}</ListItem>
                    ))}
                </List>
              </Grid>

              {/*TODO: ADD CANCEL BUTTON */}
              <Grid item>
                <Button
                  variant="contained"
                  onClick={uploadPhotos}
                  disabled={state.loading}
                >
                  {t("upload")}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {user.hasAction("EDIT_CENTERS") && (
            <Grid container item xs={12} spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button onClick={() => history.goBack()}>{t("back")}</Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  disabled={state.loading}
                >
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>

      {/* Access Control Dialog */}
      <Dialog
        open={state.dialogs.accessControlIsOpen}
        onClose={() => {
          closeDialog("accessControlIsOpen");
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogActions>
          <Typography variant="h6">{t("accessControl")}</Typography>
          <IconButton
            onClick={() => {
              closeDialog("accessControlIsOpen");
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <CustomSelect
                  label={t("accessControlLevels")}
                  value={state.centerOptions.accessControlLevels || []}
                  name="accessControlLevels"
                  onChange={handleCenterOptionsChange}
                  options={
                    state.accessControlLevels?.map((level) => ({
                      value: level.id,
                      label: level.name,
                    })) || []
                  }
                  multiple
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={updateCenterAccessControlLevels}
              >
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Notifications Dialog */}
      <Dialog
        open={state.dialogs.confIsOpen}
        onClose={() => {
          closeDialog("confIsOpen");
        }}
        maxWidth="md"
      >
        <DialogActions>
          <Typography variant="h6">{t("notifications")}</Typography>
          <IconButton
            onClick={() => {
              closeDialog("confIsOpen");
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <CustomSelect
                  label={t("generalNotifications")}
                  value={state.centerOptions.generalNotificationsEmails}
                  name="generalNotificationsEmails"
                  onChange={handleCenterOptionsChange}
                  options={state.form.emails.map((email) => ({
                    value: email.id,
                    label: email.email,
                  }))}
                  multiple
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  label={t("settlementEmail")}
                  value={state.centerOptions.settlementEmails}
                  name="settlementEmails"
                  onChange={handleCenterOptionsChange}
                  options={state.form.emails.map((email) => ({
                    value: email.id,
                    label: email.email,
                  }))}
                  multiple
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  label={t("callNotifications")}
                  value={state.centerOptions.callsEmails}
                  name="callsEmails"
                  onChange={handleCenterOptionsChange}
                  options={state.form.emails.map((email) => ({
                    value: email.id,
                    label: email.email,
                  }))}
                  multiple
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" onClick={updateNotifications}>
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Contract Types Dialog */}
      <Dialog
        open={state.dialogs.contractsIsOpen}
        onClose={() => {
          closeDialog("contractsIsOpen");
        }}
      >
        <DialogActions>
          <Typography variant="h6">{t("contractTypes")}</Typography>
          <IconButton
            onClick={() => {
              closeDialog("contractsIsOpen");
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomSelect
                multiple
                options={state.contractTypes.map((contractType) => ({
                  value: contractType.id,
                  label: contractType.name,
                }))}
                label={t("contractTypes")}
                onChange={(e) => {
                  if (state.centerOptions.defaultContractType !== null)
                    if (
                      !e.target.value.includes(
                        Number(state.centerOptions.defaultContractType)
                      )
                    ) {
                      enqueueSnackbar(t("thisContractTypeIsTheDefault"), {
                        variant: "error",
                      });
                      return;
                    }
                  handleCenterOptionsChange(e);
                }}
                name="contractTypes"
                value={state.centerOptions.contractTypes}
              />
            </Grid>

            <Grid item>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {t("defaultContractType")}
                </FormLabel>
                <RadioGroup
                  name="defaultContractType"
                  value={Number(state.centerOptions.defaultContractType)}
                  onChange={(e) => {
                    e.target.value = Number(e.target.value);
                    handleCenterOptionsChange(e);
                  }}
                >
                  {state.contractTypes?.map((type) => (
                    <FormControlLabel
                      key={type.id}
                      control={
                        <Radio
                          disabled={
                            !state.centerOptions.contractTypes.includes(type.id)
                          }
                        />
                      }
                      value={type.id}
                      label={type.name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Button variant="contained" onClick={updateContractTypes}>
              {t("save")}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Invoicing Dialog */}
      <Dialog
        open={state.dialogs.invoicingIsOpen}
        onClose={() => {
          closeDialog("invoicingIsOpen");
        }}
      >
        <DialogActions>
          <Typography variant="h6">{t("invoicing")}</Typography>
          <IconButton
            onClick={() => {
              closeDialog("invoicingIsOpen");
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item>
              <CustomSelect
                multiple
                options={state.issuers.map((issuer) => ({
                  label: issuer.name,
                  value: issuer.id,
                }))}
                label={t("issuers")}
                onChange={(e) => {
                  if (state.centerOptions.defaultIssuer !== null)
                    if (
                      !e.target.value.includes(
                        Number(state.centerOptions.defaultIssuer)
                      )
                    ) {
                      enqueueSnackbar(t("thisIssuerIsTheDefault"), {
                        variant: "error",
                      });
                      return;
                    }
                  handleCenterOptionsChange(e);
                }}
                name="issuers"
                value={state.centerOptions.issuers}
              />
            </Grid>
            <Grid item>
              <CustomSelect
                label={t("defaultIssuer")}
                name="defaultIssuer"
                value={state.centerOptions.defaultIssuer}
                onChange={handleCenterOptionsChange}
                options={state.centerOptions.issuers?.map((issuerId) => {
                  let issuer = state.issuers.filter(
                    (item) => item.id === issuerId
                  )[0];

                  return {
                    value: issuer?.id,
                    label: issuer?.name,
                  };
                })}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" onClick={updateIssuers}>
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Tax Data Dialog */}
      <Dialog
        open={state.dialogs.taxDataIsOpen}
        onClose={() => {
          closeDialog("taxDataIsOpen");
        }}
      >
        <DialogActions>
          <Typography variant="h6">{t("taxData")}</Typography>
          <IconButton
            onClick={() => {
              closeDialog("taxDataIsOpen");
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item>
              <TextInput
                label={t("businessName")}
                value={state.centerOptions.businessName}
                name="businessName"
                onChange={handleCenterOptionsChange}
              />
            </Grid>
            <Grid item>
              <TextInput
                label={t("cif")}
                value={state.centerOptions.cif}
                name="cif"
                onChange={handleCenterOptionsChange}
              />
            </Grid>
            <Grid item>
              <TextInput
                label={t("fiscalAddress")}
                value={state.centerOptions.fiscalAddress}
                name="fiscalAddress"
                onChange={handleCenterOptionsChange}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid>
                <Button variant="contained" onClick={updateTaxData}>
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Settlements Dialog */}
      <Dialog
        open={state.dialogs.settlementsIsOpen}
        onClose={() => {
          closeDialog("settlementsIsOpen");
        }}
      >
        <DialogActions>
          <Typography variant="h6">{t("settlements")}</Typography>
          <IconButton
            onClick={() => {
              closeDialog("settlementsIsOpen");
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.form.hasSettlements}
                    onChange={(e) => {
                      handleInputChange({
                        target: {
                          value: e.target.checked,
                          name: e.target.name,
                        },
                      });

                      if (!e.target.checked) {
                        handleInputChange({
                          target: { value: null, name: "provider" },
                        });
                        handleInputChange({
                          target: {
                            value: false,
                            name: "settlementNonPayments",
                          },
                        });
                      }
                    }}
                    name="hasSettlements"
                  />
                }
                label={t("hasSettlements")}
              />
            </Grid>
            {state.form.hasSettlements && (
              <Grid item container>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.form.settlementNonPayments}
                        onChange={(e) => {
                          handleInputChange({
                            target: {
                              name: e.target.name,
                              value: e.target.checked,
                            },
                          });
                        }}
                        name="settlementNonPayments"
                      />
                    }
                    label={t("settlementNonPayments")}
                  />
                </Grid>
                {/* <Grid item>
                  <Autocomplete
                    size="large"
                    sx={{ minWidth: "200px" }}
                    options={state.providers}
                    getOptionLabel={(provider) =>
                      provider.businessName + " - " + provider?.brand || ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value
                    }
                    filterOptions={createFilterOptions({
                      limit: 10, //limit of options to be displayed
                    })}
                    value={state.form.provider}
                    onChange={(e, provider) => {
                      handleInputChange({
                        target: { value: provider, name: "provider" },
                      });
                    }}
                    renderInput={(params) => (
                      <TextInput
                        {...params}
                        size="small"
                        label={t("provider")}
                      />
                    )}
                  />
                </Grid> */}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={updateSettlementsData}
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add email Modal*/}

      <Dialog
        open={state.emailModal.isOpen}
        onClose={handleCloseEmailModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle textAlign="right">
          {t("addEmail")}
          <IconButton onClick={handleCloseEmailModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextInput
                label={t("email")}
                name="email"
                variant="outlined"
                onChange={(e) => {
                  handleEmailInputChange(e);
                }}
                value={state.newEmail.email}
              />
            </Grid>

            <Grid item xs={10}>
              <TextInput
                label={t("contact")}
                name="contact"
                variant="outlined"
                onChange={(e) => {
                  handleEmailInputChange(e);
                }}
                value={state.newEmail.contact}
              />
            </Grid>

            <Grid item xs={2}>
              <Button variant="contained" color="primary" onClick={addEmail}>
                <AddCircleOutlineOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Add phone Modal*/}

      <Dialog
        open={state.phoneModal.isOpen}
        onClose={handleClosePhoneModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle textAlign="right">
          {t("addPhoneNumber")}
          <IconButton onClick={handleClosePhoneModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <PhoneInput
                inputProps={{
                  label: t("phoneNumber"),
                  name: "number",
                  onChange: (e) => {
                    handlePhoneInputChange(e);
                  },
                }}
                value={state.newPhone.number}
              />
            </Grid>

            <Grid item xs={5}>
              <TextInput
                label={t("contact")}
                name="contact"
                onChange={(e) => {
                  handlePhoneInputChange(e);
                }}
                value={state.newPhone.contact}
              />
            </Grid>

            <Grid item xs={2}>
              <Button variant="contained" color="primary" onClick={addPhone}>
                <AddCircleOutlineOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        confirmText={state.confirmDialog.confirmText}
        cancelText={state.confirmDialog.cancelText}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>

      {state.center.ARViewLink && (
        <Dialog
          open={state.isARDialogOpen}
          onClose={closeARDialog}
          maxWidth={false}
        >
          <iframe
            style={{ width: "80vw", height: "80vh" }}
            src={state.center.ARViewLink}
          />
        </Dialog>
      )}

      {/* Floor Plan Menu*/}
      <Dialog
        open={Boolean(state.floorPlanAnchorEl)}
        onClose={closeFloorPlanMenu}
        title={t("floorPlan")}
        maxWidth="xl"
      >
        {state.floorPlan && (
          <Grid container>
            <Grid item xs={12}>
              <FloorPlanRender data={buildRenderData(state.floorPlan)} />
            </Grid>
          </Grid>
        )}
      </Dialog>

      {state.redirect && <Redirect to="/app/centers" />}
    </Container>
  );
}
