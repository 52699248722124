import {
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import DeleteIcon from "@mui/icons-material/Delete";

import AppContext from "../../../context/AppContext";
import ButtonLink from "../../Inputs/ButtonLink";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import TextInput from "../../Inputs/TextInput";
import ItemsSummary from "../../ItemsSummary";
import { CustomTable } from "../../CustomTable";
import { localeFormat } from "../../../utils/format";
import CenterSelect from "../../Inputs/CenterSelect";
import ConfirmDialog from "../../ConfirmDialog";
import { round } from "../../../utils/math";

import { PROMOTION_MERCHANTABLE_TYPE_ID } from "../../../data/constants";

const initialState = {
  confirmDialog: {
    title: "",
    isOpen: false,
    childrenText: "",
    callback: () => {},
  },
  form: {
    id: "",
    centerId: "",
    contractId: "",
    startDate: "",
    endDate: "",
    pricePerUnit: "",
    concept: "",
    comments: "",
    vatPercentage: "",
    merchantableTypeId: "",
    invoices: [],
    units: 1,
    royaltyAmount: null,
    royaltyPercentage: null,
  },
  inputError: {
    contractId: false,
    startDate: false,
    endDate: false,
    pricePerUnit: false,
    concept: false,
    comments: false,
    vatPercentage: false,
    merchantableTypeId: false,
    units: false,
  },
  centerName: "",
  contracts: [],
  id: null,
  invoiceItemsIds: [],
  invoices: [],
  merchantableTypes: [],
  settlementItems: [],
  invoicesTableOptions: { rowlink: "invoice", loaded: true },
  settlementsTableOptions: { loaded: true },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_CENTER_NAME":
      return { ...state, centerName: action.payload };
    case "SET_MERCHANTABLE":
      return { ...state, form: action.payload };
    case "SET_INVOICE_ITEMS_IDS":
      return { ...state, invoiceItemsIds: action.payload };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_SETTLEMENT_ITEMS":
      return { ...state, settlementItems: action.payload };
    case "SET_TYPES":
      return { ...state, merchantableTypes: action.payload };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "INVOICES_TABLE_LOADED_TRUE":
      return {
        ...state,
        invoicesTableOptions: { ...state.invoicesTableOptions, loaded: true },
      };
    case "INVOICES_TABLE_LOADED_FALSE":
      return {
        ...state,
        invoicesTableOptions: { ...state.invoicesTableOptions, loaded: false },
      };
    case "SETTLEMENTS_TABLE_LOADED_TRUE":
      return {
        ...state,
        settlementsTableOptions: {
          ...state.settlementsTableOptions,
          loaded: true,
        },
      };
    case "SETTLEMENTS_TABLE_LOADED_FALSE":
      return {
        ...state,
        settlementsTableOptions: {
          ...state.settlementsTableOptions,
          loaded: false,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          isOpen: action.payload.isOpen,
          childrenText: action.payload.childrenText,
          callback: action.payload.callback,
        },
      };
    default:
      throw new Error();
  }
}

export default function MerchantablePage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("merchantables");
  const [tErrors] = useTranslation("errors");

  const baseImport = state.form.units * state.form.pricePerUnit;
  const totalImport =
    baseImport + baseImport * (state.form.vatPercentage / 100);
  const royaltyPercentage = round(
    (state.form.royaltyAmount / baseImport) * 100
  );
  const royaltyAmount = round(
    (state.form.royaltyPercentage / 100) * baseImport
  );

  const INVOICE_COLUMNS = [
    { key: "InvoiceSerie.name", label: t("serie"), sortType: "string" },
    { key: "number", label: t("number"), sortType: "number" },
    { key: "issueDate", label: t("issueDate"), sortType: "string" },
    { key: "customerName", label: t("customerName"), sortType: "string" },
    {
      key: "totalAmount",
      label: t("import"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "PaymentMethod.name",
      label: t("paymentMethod"),
      sortType: "string",
    },
  ];

  const SETTLEMENT_COLUMNS = [
    {
      key: "name",
      label: t("settlementName"),
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink
          to={"/app/settlement/" + item.settlementId}
          size="small"
          sx={{ padding: 0 }}
        >
          {value}
        </ButtonLink>
      ),
    },
    {
      key: "baseAmount",
      label: t("baseAmount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "royalty",
      label: t("royalty"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "%",
    },
    {
      key: "totalAmount",
      label: t("total"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "type",
      label: t("type"),
      sortType: "string",
    },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("merchantable");

    getMerchantable();
    getMerchantableTypes();
    getInvoiceItems();
  }, []);

  useEffect(() => {
    if (
      state.form.centerId !== "" &&
      state.form.centerId !== null &&
      !user.hasAction("EDIT_MERCHANTABLES")
    )
      getCenter();
  }, [state.form.centerId]);

  useEffect(() => {
    if (state.invoiceItemsIds.length > 0 && user.hasAction("VIEW_SETTLEMENTS"))
      getSettlements();
  }, [state.invoiceItemsIds]);

  const getMerchantable = () => {
    let params = {
      include: [
        "InvoiceItem",
        "Invoice",
        "PaymentMethod",
        "InvoiceSerie",
        "Total",
        "Box",
        "Center",
        "Contract",
        "Customer",
      ],
    };

    api
      .get("/merchantables/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          response.data.invoices = response.data.InvoiceItems.map((item) => {
            return item.Invoice;
          });
          response.data.royaltyPercentage =
            (response.data.royaltyAmount / response.data.baseAmount) * 100;

          dispatch({
            type: "SET_MERCHANTABLE",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getMerchantableTypes = () => {
    api
      .get("/merchantables/types")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_TYPES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCenter = () => {
    api
      .get("/centers/" + state.form.centerId)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_CENTER_NAME", payload: response.data.name });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoiceItems = () => {
    let params = {
      merchantableId: id,
    };

    api
      .get("/invoice-items", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const invoiceItemsIds = response.data.map((item) => item.id);
          dispatch({ type: "SET_INVOICE_ITEMS_IDS", payload: invoiceItemsIds });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getSettlements = () => {
    dispatch({ type: "SETTLEMENTS_TABLE_LOADED_FALSE" });

    let params = {
      invoiceItemsIds: state.invoiceItemsIds,
    };

    api
      .get("/settlements", { params })
      .then((response) => {
        dispatch({ type: "SETTLEMENTS_TABLE_LOADED_TRUE" });
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const typeMap = {
            1: t("invoice"),
            2: t("nonPayment"),
            3: t("recuperation"),
          };
          const settlementItems = response.data.flatMap((settlement) =>
            settlement.SettlementItems.map((item) => ({
              ...item,
              name: settlement.name,
              type: typeMap[item.type] || "",
            }))
          );

          dispatch({ type: "SET_SETTLEMENT_ITEMS", payload: settlementItems });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const submitForm = () => {
    if (validateForm()) {
      let data = {};

      state.form.centerId !== "" && (data.centerId = state.form.centerId);
      state.form.contractId !== "" && (data.contractId = state.form.contractId);
      state.form.startDate !== "" && (data.startDate = state.form.startDate);
      state.form.endDate !== "" && (data.endDate = state.form.endDate);
      state.form.pricePerUnit !== "" &&
        (data.pricePerUnit = state.form.pricePerUnit);
      state.form.concept !== "" && (data.concept = state.form.concept);
      state.form.comments !== "" && (data.comments = state.form.comments);
      state.form.vatPercentage !== "" &&
        (data.vatPercentage = state.form.vatPercentage);
      state.form.merchantableTypeId !== "" &&
        (data.merchantableTypeId = state.form.merchantableTypeId);
      state.form.units !== "" && (data.units = state.form.units);
      state.form.merchantableTypeId === 1
        ? (data.royaltyAmount = royaltyAmount)
        : (data.royaltyAmount = state.form.royaltyAmount);

      api
        .post("/merchantables/edit/" + id, data)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("merchantableEditSuccess"), {
              variant: "success",
            });
            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const deleteMerchantable = () => {
    api
      .delete("/merchantables/" + id)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("merchantableDeleteSuccess"), {
            variant: "success",
          });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const validateForm = () => {
    let isValid = true;

    let fields = [
      "concept",
      "pricePerUnit",
      "vatPercentage",
      "contractId",
      "startDate",
      "merchantableTypeId",
    ];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    if (state.form.units <= 0) {
      setInputErrorTrue("units");
      enqueueSnackbar(t("units") + " " + t("greaterThan"), {
        variant: "error",
      });
      isValid = false;
    }

    return isValid;
  };

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleDeleteMerchantable = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteMerchantable"),
        isOpen: true,
        childrenText: (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">
                {t("deleteMerchantableConfirmationQuestion")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight="medium">
                {state.form.concept}
              </Typography>
            </Grid>
          </Grid>
        ),
        callback: (confirmed) => {
          confirmed && deleteMerchantable();
          resetConfirmDialog();
        },
      },
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  return (
    <Container maxWidth="md" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid item>
              <Typography variant="h4">{t("editMerchantable")}</Typography>
            </Grid>
            <Grid item flex={1} justifyContent="flex-end" display="flex">
              {user.hasAction("DELETE_MERCHANTABLES") &&
                state.form.InvoiceItems?.length === 0 && (
                  <Tooltip title={t("deleteMerchantable")} placement="top">
                    <IconButton
                      color="error"
                      onClick={handleDeleteMerchantable}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
            </Grid>
          </Grid>

          <Grid item container alignItems="center" spacing={2} xs={12}>
            <Grid item xs={12}>
              {user.hasAction("EDIT_MERCHANTABLES") ? (
                <CustomSelect
                  label={t("merchantableType")}
                  value={state.form.merchantableTypeId}
                  name="merchantableTypeId"
                  onChange={handleInputChange}
                  options={[
                    { value: "0", label: t("none") },
                    ...state.merchantableTypes.map((type) => ({
                      value: type.id,
                      label: type.name,
                      disabled:
                        type.id === PROMOTION_MERCHANTABLE_TYPE_ID
                          ? true
                          : false,
                    })),
                  ]}
                />
              ) : (
                <Typography variant="body2" fontWeight="medium">
                  {t("merchantableType") + ": "}
                  <Typography component="span" variant="body2">
                    {state.form.merchantableTypeId
                      ? state.merchantableTypes.find(
                          (type) => type.id === state.form.merchantableTypeId
                        )?.name
                      : ""}
                  </Typography>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              {user.hasAction("EDIT_MERCHANTABLES") ? (
                <TextInput
                  error={state.inputError.concept}
                  helperText={
                    state.inputError.concept
                      ? t("concept") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("concept")}
                  value={state.form.concept}
                  onChange={handleInputChange}
                  name="concept"
                />
              ) : (
                <Typography variant="body2" fontWeight="medium">
                  {t("concept") + ": "}
                  <Typography component="span" variant="body2">
                    {state.form.concept}
                  </Typography>
                </Typography>
              )}
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                {user.hasAction("EDIT_MERCHANTABLES") ? (
                  <CustomDate
                    error={state.inputError.startDate}
                    helperText={
                      state.inputError.startDate
                        ? t(
                            state.form.merchantableTypeId === 1
                              ? "startDate"
                              : "date"
                          ) +
                          " " +
                          t("mustNotBeBlank")
                        : ""
                    }
                    label={t(
                      state.form.merchantableTypeId === 1 ? "startDate" : "date"
                    )}
                    value={state.form.startDate}
                    onChange={handleInputChange}
                    name="startDate"
                  />
                ) : (
                  <Typography variant="body2" fontWeight="medium">
                    {t(
                      state.form.merchantableTypeId !== 1 ? "startDate" : "date"
                    ) + ": "}
                    <Typography component="span" variant="body2">
                      {state.form.startDate}
                    </Typography>
                  </Typography>
                )}
              </Grid>
              {state.form.merchantableTypeId === 1 && (
                <Grid item xs={12} sm={6} md={4}>
                  {user.hasAction("EDIT_MERCHANTABLES") ? (
                    <CustomDate
                      error={state.inputError.endDate}
                      helperText={
                        state.inputError.endDate
                          ? t("endDate") + " " + t("mustNotBeBlank")
                          : ""
                      }
                      label={t("endDate")}
                      value={state.form.endDate}
                      onChange={handleInputChange}
                      name="endDate"
                      inputProps={{
                        min: state.form.startDate,
                      }}
                    />
                  ) : (
                    <Typography variant="body2" fontWeight="medium">
                      {t("endDate") + ": "}
                      <Typography component="span" variant="body2">
                        {state.form.endDate}
                      </Typography>
                    </Typography>
                  )}
                </Grid>
              )}
              {state.form.merchantableTypeId !== 1 && (
                <Grid item xs={12} sm={6} md={4}>
                  {user.hasAction("EDIT_MERCHANTABLES") ? (
                    <CenterSelect
                      value={state.form.centerId}
                      name="centerId"
                      onChange={handleInputChange}
                    />
                  ) : (
                    <Typography variant="body2" fontWeight="medium">
                      {t("center") + ": "}
                      <Typography component="span" variant="body2">
                        {state.centerName}
                      </Typography>
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {user.hasAction("EDIT_MERCHANTABLES") ? (
                <TextInput
                  error={state.inputError.units}
                  helperText={
                    state.inputError.units
                      ? t("units") + " " + t("greaterThan")
                      : ""
                  }
                  type="number"
                  label={t("units")}
                  value={state.form.units}
                  onChange={handleInputChange}
                  name="units"
                />
              ) : (
                <Typography variant="body2" fontWeight="medium">
                  {t("units") + ": "}
                  <Typography component="span" variant="body2">
                    {state.form.units}
                  </Typography>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {user.hasAction("EDIT_MERCHANTABLES") ? (
                <TextInput
                  error={state.inputError.pricePerUnit}
                  helperText={
                    state.inputError.pricePerUnit
                      ? t("pricePerUnit") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  type="number"
                  label={t("pricePerUnit")}
                  value={state.form.pricePerUnit}
                  onChange={handleInputChange}
                  name="pricePerUnit"
                />
              ) : (
                <Typography variant="body2" fontWeight="medium">
                  {t("pricePerUnit") + ": "}
                  <Typography component="span" variant="body2">
                    {localeFormat(state.form.pricePerUnit) + "€"}
                  </Typography>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {user.hasAction("EDIT_MERCHANTABLES") ? (
                <TextInput
                  error={state.inputError.vatPercentage}
                  helperText={
                    state.inputError.vatPercentage
                      ? t("vatPercentage") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  type="number"
                  label={t("vatPercentage")}
                  value={state.form.vatPercentage}
                  onChange={handleInputChange}
                  name="vatPercentage"
                />
              ) : (
                <Typography variant="body2" fontWeight="medium">
                  {t("vatPercentage") + ": "}
                  <Typography component="span" variant="body2">
                    {localeFormat(state.form.vatPercentage)}
                  </Typography>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <ItemsSummary
                gridItems={[
                  {
                    translatedText: t("baseAmount"),
                    value: localeFormat(baseImport) + "€",
                  },
                  {
                    translatedText: t("totalAmount"),
                    value: localeFormat(totalImport) + "€",
                  },
                ]}
              />
            </Grid>
            <Grid container item xs={12} spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                {user.hasAction("EDIT_MERCHANTABLES") ? (
                  <TextInput
                    type="number"
                    label={t("royaltyAmount")}
                    value={
                      state.form.merchantableTypeId === 1
                        ? royaltyAmount
                        : state.form.royaltyAmount
                    }
                    onChange={handleInputChange}
                    name="royaltyAmount"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={state.form.merchantableTypeId === 1}
                  />
                ) : (
                  <Typography variant="body2" fontWeight="medium">
                    {t("royaltyAmount") + ": "}
                    <Typography component="span" variant="body2">
                      {localeFormat(
                        state.form.merchantableTypeId === 1
                          ? royaltyAmount
                          : state.form.royaltyAmount || 0
                      ) + "€"}
                    </Typography>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body2" fontWeight="medium">
                  {t("royaltyPercentage") + ": "}
                  <Typography component="span" variant="body2">
                    {localeFormat(
                      state.form.merchantableTypeId === 1
                        ? state.form.royaltyPercentage
                        : royaltyPercentage || 0
                    ) + "%"}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>

            {state.form.Contract && (
              <Grid item container spacing={2} xs={12}>
                <Grid item xs={12} sm={6} md={4}>
                  {user.hasPage("CUSTOMERS") ? (
                    <ButtonLink
                      to={"/app/customer/" + state.form.Contract?.customerId}
                      size="medium"
                      sx={{ padding: 0 }}
                    >
                      {t("customer") +
                        ": " +
                        state.form.Contract?.Customer?.fullName}
                    </ButtonLink>
                  ) : (
                    <Typography variant="body2" fontWeight="medium">
                      {t("customer") + ": "}
                      <Typography component="span" variant="body2">
                        {state.form.Contract?.Customer?.fullName}
                      </Typography>
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  {user.hasPage("CONTRACTS") ? (
                    <ButtonLink
                      to={"/app/contract/" + state.form.Contract?.id}
                      size="medium"
                      sx={{ padding: 0 }}
                    >
                      {t("contract") + ": " + state.form.Contract?.publicId}
                    </ButtonLink>
                  ) : (
                    <Typography variant="body2" fontWeight="medium">
                      {t("contract") + ": "}
                      <Typography component="span" variant="body2">
                        {(state.form.Contract?.publicId
                          ? state.form.Contract.publicId
                          : "") +
                          " | " +
                          (state.form.Contract?.Box?.Center?.name
                            ? state.form.Contract.Box.Center.name
                            : "") +
                          " | " +
                          (state.form.Contract?.Box?.name
                            ? state.form.Contract.Box.name
                            : "")}
                      </Typography>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              {user.hasAction("EDIT_MERCHANTABLES") ? (
                <TextInput
                  error={state.inputError.comments}
                  helperText={
                    state.inputError.comments
                      ? t("comments") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("comments")}
                  multiline
                  rows={4}
                  value={state.form.comments}
                  onChange={handleInputChange}
                  name="comments"
                />
              ) : (
                <Typography variant="body2" fontWeight="medium">
                  {t("comments") + ": "}
                  <Typography component="span" variant="body2">
                    {state.form.comments ? state.form.comments : " "}
                  </Typography>
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">{t("invoices")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              columns={INVOICE_COLUMNS}
              data={state.form.invoices}
              options={state.invoicesTableOptions}
            />
          </Grid>

          {user.hasAction("VIEW_SETTLEMENTS") && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">{t("settlements")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTable
                  columns={SETTLEMENT_COLUMNS}
                  data={state.settlementItems}
                  options={state.settlementsTableOptions}
                />
              </Grid>
            </>
          )}

          <Grid item container xs={12} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            {user.hasAction("EDIT_MERCHANTABLES") && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>

      {/* Confirm Dialog */}
      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
}
