import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  RadioGroup,
  Radio,
} from "@mui/material";
import CityInput from "../../Inputs/CityInput";
import CountryInput from "../../Inputs/CountryInput";
import CustomDate from "../../Inputs/CustomDate";
import React, { useContext, useState } from "react";
import TextInput from "../../Inputs/TextInput";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../Inputs/CustomSelect";
import PostalCodeInput from "../../Inputs/PostalCodeInput";
import {
  COMPANY_CUSTOMER_TYPE_ID,
  FEMALE_GENDER_ID,
  INDIVIDUAL_CUSTOMER_TYPE_ID,
  MALE_GENDER_ID,
  PASSPORT_ID_TYPE,
  SPAIN_COUNTRY_ID,
} from "../../../data/constants";
import { useEffect } from "react";
import _ from "lodash";
import LabeledText from "../../global/LabeledText";
import AppContext from "../../../context/AppContext";
import IdTypeSelect from "../../Inputs/IdTypeSelect";

const formatYmd = (date) => date.toISOString().slice(0, 10);

export default function CustomerForm({
  customer,
  handleInputChange,
  helperText,
  hideNif = false,
  canEdit = true,
}) {
  const [t] = useTranslation("customers");

  useEffect(() => {
    !customer.tinCountry &&
      handleInputChange({
        target: {
          name: "tinCountry",
          value: { id: SPAIN_COUNTRY_ID, name: t("spain"), alpha2: "ES" },
        },
      });
    !customer.residenceCountry &&
      handleInputChange({
        target: {
          name: "residenceCountry",
          value: { id: SPAIN_COUNTRY_ID, name: t("spain") },
        },
      });
  }, []);

  useEffect(() => {
    !customer.tinCountry &&
      handleInputChange({
        target: {
          name: "nif",
          value: "",
        },
      });
    handleInputChange({
      target: {
        name: "tinCountryId",
        value: _.get(customer.tinCountry, "id", null),
      },
    });
  }, [customer.tinCountry]);

  useEffect(() => {
    if (customer.residenceCountry?.id !== SPAIN_COUNTRY_ID) {
      customer.postalCode &&
        handleInputChange({
          target: {
            name: "postalCode",
            value: "",
          },
        });
      customer.city &&
        handleInputChange({
          target: {
            name: "city",
            value: "",
          },
        });
    }
    handleInputChange({
      target: {
        name: "residenceCountryId",
        value: _.get(customer.residenceCountry, "id", null),
      },
    });
  }, [customer.residenceCountry]);

  useEffect(() => {
    handleInputChange({
      target: {
        name: "cityId",
        value: _.get(customer.city, "id", null),
      },
    });
  }, [customer.city]);

  const getIdTypeLabel = (idType) => {
    return idType?.toUpperCase() === PASSPORT_ID_TYPE
      ? t(idType)
      : idType?.toUpperCase();
  };

  const getCustomerTypeText = () => {
    return customer.customerTypeId === INDIVIDUAL_CUSTOMER_TYPE_ID
      ? t("individual")
      : t("company");
  };

  const getCustomerBirthdateText = () => {
    return (
      customer.birthdate +
      " (" +
      (formatYmd(new Date())?.split("-")[0] -
        customer.birthdate?.split("-")[0]) +
      " " +
      t("yearsOld") +
      ")"
    );
  };

  const getCustomerGenderText = () => {
    return customer.gender === MALE_GENDER_ID ? t("male") : t("female");
  };

  return (
    <Grid container spacing={3}>
      <Grid item container spacing={2} xs={12}>
        <Grid item xs={12}>
          {canEdit ? (
            <FormControl component="fieldset">
              <FormLabel component="legend">{t("type")}</FormLabel>
              <RadioGroup
                row
                aria-label="customerTypeId"
                name="customerTypeId"
                value={Number(customer.customerTypeId)}
                onChange={(e) => {
                  e.target.value = Number(e.target.value);
                  handleInputChange(e);
                }}
              >
                <FormControlLabel
                  value={INDIVIDUAL_CUSTOMER_TYPE_ID}
                  control={<Radio />}
                  label={t("individual")}
                />
                <FormControlLabel
                  value={COMPANY_CUSTOMER_TYPE_ID}
                  control={<Radio />}
                  label={t("company")}
                />
              </RadioGroup>
            </FormControl>
          ) : (
            <Grid item>
              <LabeledText label={t("type")} value={getCustomerTypeText()} />
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Individual */}
      {Number(customer.customerTypeId) === INDIVIDUAL_CUSTOMER_TYPE_ID && (
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6}>
            {canEdit ? (
              <TextInput
                error={helperText?.name}
                helperText={
                  helperText?.name ? t("name") + " " + t("mustNotBeBlank") : ""
                }
                label={t("name")}
                name="name"
                value={customer.name}
                onChange={handleInputChange}
                required
              />
            ) : (
              <Grid item>
                <LabeledText label={t("name")} value={customer.name} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {canEdit ? (
              <TextInput
                error={helperText?.surnames}
                helperText={
                  helperText?.surnames
                    ? t("surnames") + " " + t("mustNotBeBlank")
                    : ""
                }
                label={t("surnames")}
                name="surnames"
                value={customer.surnames}
                onChange={handleInputChange}
                required
              />
            ) : (
              <Grid item>
                <LabeledText label={t("surnames")} value={customer.surnames} />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {canEdit ? (
              <CountryInput
                helperText={helperText?.tinCountry}
                label={t("countryIssuance")}
                name="tinCountry"
                onChange={handleInputChange}
                value={customer.tinCountry}
                required
              />
            ) : (
              customer.tinCountry && (
                <Grid item>
                  <LabeledText
                    label={t("countryIssuance")}
                    value={customer.tinCountry?.name}
                  />
                </Grid>
              )
            )}
          </Grid>
          {!hideNif && (
            <Grid item container columnSpacing={2} xs={12} sm={6}>
              {canEdit ? (
                <Grid item container columnSpacing={2}>
                  <Grid item xs={4}>
                    <IdTypeSelect
                      idType={customer.idType}
                      countryAlpha2={customer.tinCountry?.alpha2}
                      handleInputChange={handleInputChange}
                      disabled={!customer.tinCountry}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextInput
                      error={helperText?.nif}
                      helperText={
                        helperText?.nif
                          ? t("nif") + " " + t("mustNotBeBlank")
                          : ""
                      }
                      label={getIdTypeLabel(customer.idType)}
                      name="nif"
                      value={customer.nif}
                      onChange={handleInputChange}
                      required
                      disabled={!customer.tinCountry}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <LabeledText label={t("nif")} value={customer.nif} />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}

      {/* Company */}
      {Number(customer.customerTypeId) === COMPANY_CUSTOMER_TYPE_ID && (
        <Grid item container spacing={2}>
          <Grid item container xs={12} columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              {canEdit ? (
                <TextInput
                  error={helperText?.name}
                  helperText={
                    helperText?.name
                      ? t("name") + " " + t("mustNotBeBlank")
                      : ""
                  }
                  label={t("company")}
                  name="name"
                  value={customer.name}
                  onChange={handleInputChange}
                  required
                />
              ) : (
                <Grid item>
                  <LabeledText label={t("company")} value={customer.name} />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            {canEdit ? (
              <CountryInput
                helperText={helperText?.tinCountry}
                label={t("countryIssuance")}
                name="tinCountry"
                onChange={handleInputChange}
                value={customer.tinCountry}
                required
              />
            ) : (
              customer.tinCountry && (
                <Grid item>
                  <LabeledText
                    label={t("countryIssuance")}
                    value={customer.tinCountry?.name}
                  />
                </Grid>
              )
            )}
          </Grid>

          {!hideNif && (
            <Grid item xs={12} sm={6}>
              {canEdit ? (
                <Grid item container columnSpacing={2}>
                  <Grid item xs={4}>
                    <IdTypeSelect
                      idType={customer.idType}
                      countryAlpha2={customer.tinCountry?.alpha2}
                      handleInputChange={handleInputChange}
                      disabled={!customer.tinCountry}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextInput
                      error={helperText?.nif}
                      helperText={
                        helperText?.nif
                          ? t("nif") + " " + t("mustNotBeBlank")
                          : ""
                      }
                      label={getIdTypeLabel(customer.idType)}
                      name="nif"
                      value={customer.nif}
                      onChange={handleInputChange}
                      required
                      disabled={!customer.tinCountry}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <LabeledText label={t("nif")} value={customer.nif} />
                </Grid>
              )}
            </Grid>
          )}

          <Grid item xs={12} sm={6} lg={2}>
            {canEdit ? (
              <CountryInput
                helperText={helperText?.residenceCountry}
                label={t("countryResidence")}
                name="residenceCountry"
                onChange={handleInputChange}
                value={customer.residenceCountry}
                required
              />
            ) : (
              customer.residenceCountry && (
                <Grid item>
                  <LabeledText
                    label={t("countryResidence")}
                    value={customer.residenceCountry?.name}
                  />
                </Grid>
              )
            )}
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            {canEdit ? (
              <TextInput
                error={helperText?.address}
                helperText={
                  helperText?.address
                    ? t("address") + " " + t("mustNotBeBlank")
                    : ""
                }
                label={t("address")}
                name="address"
                value={customer.address}
                onChange={handleInputChange}
                required
              />
            ) : (
              <Grid item>
                <LabeledText label={t("address")} value={customer.address} />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sm={6} lg={2}>
            {canEdit ? (
              <PostalCodeInput
                label={t("postalCode")}
                name="postalCode"
                onChange={handleInputChange}
                value={customer.postalCode}
                disabled={customer.residenceCountry?.id !== SPAIN_COUNTRY_ID}
              />
            ) : (
              customer.postalCode && (
                <Grid item>
                  <LabeledText
                    label={t("postalCode")}
                    value={customer.postalCode}
                  />
                </Grid>
              )
            )}
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            {canEdit ? (
              <CityInput
                helperText={helperText?.city}
                label={t("city")}
                name="city"
                onChange={handleInputChange}
                value={customer.city}
                disabled={customer.residenceCountry?.id !== SPAIN_COUNTRY_ID}
              />
            ) : (
              customer.city && (
                <Grid item>
                  <LabeledText label={t("city")} value={customer.city?.name} />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      )}

      {/* Individual */}
      {Number(customer.customerTypeId) === INDIVIDUAL_CUSTOMER_TYPE_ID && (
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6} lg={2}>
            {canEdit ? (
              <CountryInput
                helperText={helperText?.residenceCountry}
                label={t("countryResidence")}
                name="residenceCountry"
                onChange={handleInputChange}
                value={customer.residenceCountry}
                required
              />
            ) : (
              customer.residenceCountry && (
                <Grid item>
                  <LabeledText
                    label={t("countryResidence")}
                    value={customer.residenceCountry?.name}
                  />
                </Grid>
              )
            )}
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            {canEdit ? (
              <TextInput
                error={helperText?.address}
                helperText={
                  helperText?.address
                    ? t("address") + " " + t("mustNotBeBlank")
                    : ""
                }
                label={t("address")}
                name="address"
                value={customer.address}
                onChange={handleInputChange}
                required
              />
            ) : (
              <Grid item>
                <LabeledText label={t("address")} value={customer.address} />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sm={6} lg={2}>
            {canEdit ? (
              <PostalCodeInput
                label={t("postalCode")}
                name="postalCode"
                onChange={handleInputChange}
                value={customer.postalCode}
                disabled={customer.residenceCountry?.id !== SPAIN_COUNTRY_ID}
              />
            ) : (
              customer.postalCode && (
                <Grid item>
                  <LabeledText
                    label={t("postalCode")}
                    value={customer.postalCode}
                  />
                </Grid>
              )
            )}
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            {canEdit ? (
              <CityInput
                helperText={helperText?.city}
                label={t("city")}
                name="city"
                onChange={handleInputChange}
                value={customer.city}
                disabled={customer.residenceCountry?.id !== SPAIN_COUNTRY_ID}
              />
            ) : (
              customer.city && (
                <Grid item>
                  <LabeledText label={t("city")} value={customer.city?.name} />
                </Grid>
              )
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {canEdit ? (
              <CustomDate
                error={helperText?.birthdate}
                helperText={
                  helperText?.birthdate
                    ? t("dateOfBirth") + " " + t("mustNotBeBlank")
                    : ""
                }
                id="date"
                label={t("dateOfBirth")}
                InputLabelProps={{
                  shrink: true,
                }}
                name="birthdate"
                value={customer.birthdate}
                onChange={handleInputChange}
              />
            ) : (
              customer.birthdate && (
                <Grid item>
                  <LabeledText
                    label={t("dateOfBirth")}
                    value={getCustomerBirthdateText()}
                  />
                </Grid>
              )
            )}
          </Grid>
          <Grid item xs={12}>
            {canEdit ? (
              <FormControl component="fieldset" error={helperText?.gender}>
                <FormLabel component="legend">{t("gender")}</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender"
                  value={Number(customer.gender)}
                  onChange={handleInputChange}
                  row
                >
                  <FormControlLabel
                    value={FEMALE_GENDER_ID}
                    control={<Radio />}
                    label={t("female")}
                  />
                  <FormControlLabel
                    value={MALE_GENDER_ID}
                    control={<Radio />}
                    label={t("male")}
                  />
                </RadioGroup>
                <FormHelperText>
                  {helperText?.gender
                    ? t("gender") + " " + t("mustNotBeBlank")
                    : ""}
                </FormHelperText>
              </FormControl>
            ) : (
              customer.gender && (
                <Grid>
                  <LabeledText
                    label={t("gender")}
                    value={getCustomerGenderText()}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
