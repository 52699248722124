import React, { useState, useContext, useCallback, memo } from "react";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import TreeItem from "@mui/lab/TreeItem";
import AppContext from "../../../context/AppContext";
import PropTypes from "prop-types";

import FileTreeItem from "./FileTreeItem";

const DirectoryTreeItem = (props) => {
  const { user } = useContext(AppContext);

  const {
    directory,
    nodeId,
    onNewDirectory,
    onNewFile,
    onEditDirectory,
    onDeleteDirectory,
  } = props;

  const [iconsVisible, setIconsVisible] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIconsVisible(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIconsVisible(false);
  }, []);

  const handleNewDirectory = useCallback(
    (e) => {
      e.stopPropagation();
      onNewDirectory(directory.id);
    },
    [onNewDirectory, directory.id]
  );

  const handleNewFile = useCallback(
    (e) => {
      e.stopPropagation();
      onNewFile(directory.id);
    },
    [onNewFile, directory.id]
  );

  const handleEditDirectory = useCallback(
    (e) => {
      e.stopPropagation();
      onEditDirectory(directory.id);
    },
    [onEditDirectory, directory.id]
  );

  const handleDeleteDirectory = useCallback(
    (e) => {
      e.stopPropagation();
      onDeleteDirectory(directory.id, directory.name);
    },
    [onDeleteDirectory, directory.id, directory.name]
  );

  return (
    <Paper sx={{ margin: 1 }}>
      <TreeItem
        {...props}
        nodeId={nodeId}
        label={
          <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography sx={{ marginY: 1 }}>{directory?.name}</Typography>
              </Grid>
              {iconsVisible && (
                <Grid item>
                  {user.hasAction("CREATE_DOCUMENTS") && (
                    <IconButton onClick={handleNewDirectory} size="small">
                      <CreateNewFolderIcon />
                    </IconButton>
                  )}
                  {user.hasAction("CREATE_DOCUMENTS") && (
                    <IconButton onClick={handleNewFile} size="small">
                      <NoteAddIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleEditDirectory} size="small">
                    <EditIcon />
                  </IconButton>
                  {directory.Files.length == 0 &&
                    directory.children == 0 &&
                    directory.createdBy === user.id && (
                      <IconButton onClick={handleDeleteDirectory} size="small">
                        <DeleteForeverIcon />
                      </IconButton>
                    )}
                </Grid>
              )}
            </Grid>
          </Box>
        }
      >
        {directory?.children?.map((child) => (
          <DirectoryTreeItem
            {...props}
            directory={child}
            key={child.id}
            parentId={directory.id}
            nodeId={"directory" + child.id}
          />
        ))}

        {directory?.Files?.map((file) => (
          <FileTreeItem
            {...props}
            file={file}
            key={file.id}
            parentId={directory.id}
            nodeId={"file" + file.id}
          />
        ))}
      </TreeItem>
    </Paper>
  );
};

DirectoryTreeItem.propTypes = {
  directory: PropTypes.object.isRequired,
  nodeId: PropTypes.string.isRequired,
  onNewDirectory: PropTypes.func,
  onNewFile: PropTypes.func,
  onEditDirectory: PropTypes.func,
  onDeleteDirectory: PropTypes.func,
  onEditFile: PropTypes.func,
  onDownloadFile: PropTypes.func,
  onDeleteFile: PropTypes.func,
  onViewFile: PropTypes.func,
};

export default memo(DirectoryTreeItem);
