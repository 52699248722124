import { Close } from "@mui/icons-material";
import {
  Chip,
  List,
  ListItem,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const PaycardListComponent = ({ paycards, defaultPaycardId, onDelete }) => {
  const [t] = useTranslation("customers");
  return (
    <Grid item>
      <List>
        {paycards.map((paycard, index) => (
          <PaycardItem
            paycard={paycard}
            isDefault={paycard.id === defaultPaycardId}
            onDelete={() => onDelete(paycard)}
            key={index}
          />
        ))}
      </List>
      {paycards.length == 0 && (
        <Typography variant="body1">{t("noCards")}</Typography>
      )}
    </Grid>
  );
};

const PaycardItem = ({ paycard, isDefault, onDelete }) => {
  const [t] = useTranslation("customers");

  let expiresSoon = false;

  const expiryDate = new Date(
    `20${paycard.expirationYear}`,
    Number(paycard.expirationMonth) - 1
  );

  const currentDate = new Date();
  let nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);

  if (
    (currentDate.getFullYear() === expiryDate.getFullYear() &&
      currentDate.getMonth() === expiryDate.getMonth()) ||
    (nextMonth.getFullYear() === expiryDate.getFullYear() &&
      nextMonth.getMonth() === expiryDate.getMonth())
  )
    expiresSoon = true;

  return (
    <ListItem>
      <Grid container columnSpacing={1} alignItems="center">
        <Grid item>
          <Typography
            variant="body1"
            color={paycard.isExpired ? "red" : "inherit"}
          >
            {paycard.number}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">-</Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            color={paycard.isExpired ? "red" : "inherit"}
          >
            {paycard.expirationMonth}/{paycard.expirationYear}
          </Typography>
        </Grid>
        {expiresSoon && (
          <Grid item>
            <Chip size="small" color="warning" label={t("expiresSoon")} />
          </Grid>
        )}
        {paycard.isExpired && (
          <Grid item>
            <Chip size="small" color="error" label={t("expired")} />
          </Grid>
        )}
        {isDefault && (
          <Grid item>
            <Chip size="small" color="primary" label={t("default")} />
          </Grid>
        )}
        {onDelete && (
          <Grid item>
            <IconButton onClick={onDelete} size="small">
              <Close />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};
export default PaycardListComponent;
