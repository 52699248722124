import { useContext, useEffect, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// mui
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  Chip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// icons
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import ErrorIcon from "@mui/icons-material/Error";
import PhoneIcon from "@mui/icons-material/Phone";

// components & utils
import AppContext from "../../../context/AppContext";
import ButtonLink from "../../Inputs/ButtonLink";
import ContractStateChip from "../Contracts/ContractStateChip";
import CreateNonPaymentForm from "./CreateNonPaymentForm";
import CreditCardForm from "../../CreditCardForm";
import CustomButton from "../../Inputs/CustomButton";
import CustomSelect from "../../Inputs/CustomSelect";
import EmailTextEditor from "../Settings/EmailTextEditor";
import InvoiceStateChip from "../../InvoiceStateChip";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import { CustomTable } from "../../CustomTable";
import { formatDate } from "../../../utils/date";
import { localeFormat } from "../../../utils/format";

import {
  ACTIVE_CONTRACT_STATE_ID,
  ENDED_CONTRACT_STATE_ID,
  EXPIRED_INVOICE_STATE_ID,
  ISSUED_INVOICE_STATE_ID,
  PAID_INVOICE_STATE_ID,
  PAYCARD_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  UNPAID_INVOICE_STATE_ID,
  VIRTUAL_PAYCARD_ID,
} from "../../../data/constants";

import ConfirmDialog from "../../ConfirmDialog";
import Dialog from "../../global/Dialog";
import Filters from "../../global/structure/Filters";
import Page from "../../global/structure/Page";

const initialState = {
  addIbanLoading: false,
  cardModalIsOpen: false,
  comments: [],
  contracts: [],
  customer: {},
  emailDialog: {
    isOpen: false,
    reloadForm: false,
    waitingEmailResponse: false,
  },
  emailForm: {
    cc: "impagos@necesitountrastero.es;aurora.h@necesitountrastero.es",
    emailTemplateId: "",
    from: "",
    html: "",
    subject: "",
    text: "",
    to: "",
  },
  emailTemplates: [],
  filters: {
    dateFrom: "",
    dateUntil: "",
    invoiceNumber: "",
    state: "",
  },
  form: {
    newComment: "",
  },
  ibans: [],
  invoices: [],
  nonPaymentDialog: {
    isOpen: false,
    createLoading: false,
  },
  newIban: "",
  nonPaymentReasons: [],
  options: {
    loaded: true,
  },
  paycards: [],
  paymentMethods: [],
  receipts: [],
  recuperateDialog: {
    isOpen: false,
    recuperationLoading: false,
  },
  recuperate: {
    ibanId: "",
    paycardId: "",
    recuperationMethod: "",
    recuperationComment: "",
    paymentManagement: "",
  },
  selectedInvoice: [],
  confirmDialog: {
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "SET_CUSTOMER":
      return {
        ...state,
        customer: action.payload.customer,
        comments: action.payload.comments,
        contracts: action.payload.contracts,
        emailForm: {
          ...state.emailForm,
          ["to"]: action.payload.email,
        },
        box: action.payload.box,
        center: action.payload.center,
      };
    case "SET_CONTRACTS":
      return { ...state, contracts: action.payload };
    case "SET_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_COMMENTS":
      return { ...state, comments: action.payload };
    case "SET_RECEIPTS":
      return { ...state, receipts: action.payload };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_SEND_EMAIL":
      return {
        ...state,
        emailForm: {
          ...state.emailForm,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "OPEN_EMAIL_DIALOG":
      return { ...state, emailDialog: { ...state.emailDialog, isOpen: true } };
    case "CLOSE_EMAIL_DIALOG":
      return { ...state, emailDialog: { ...state.emailDialog, isOpen: false } };
    case "SET_WAITING_EMAIL_RESPONSE":
      return {
        ...state,
        emailDialog: {
          ...state.emailDialog,
          waitingEmailResponse: action.payload,
        },
      };
    case "OPEN_PAYCARD_MODAL":
      return { ...state, cardModalIsOpen: true };
    case "CLOSE_PAYCARD_MODAL":
      return { ...state, cardModalIsOpen: false };
    case "SET_NONPAYMENT_DIALOG_CREATE_LOADING":
      return {
        ...state,
        nonPaymentDialog: {
          ...state.nonPaymentDialog,
          createLoading: action.payload,
        },
      };
    case "SET_RECUPERATE_DIALOG_RECUPERATION_LOADING":
      return {
        ...state,
        recuperateDialog: {
          ...state.recuperateDialog,
          recuperationLoading: action.payload,
        },
      };
    case "OPEN_NONPAYMENT_DIALOG":
      return {
        ...state,
        nonPaymentDialog: { ...state.nonPaymentDialog, isOpen: true },
      };
    case "CLOSE_NONPAYMENT_DIALOG":
      return {
        ...state,
        nonPaymentDialog: { ...state.nonPaymentDialog, isOpen: false },
      };
    case "OPEN_RECUPERATE_DIALOG":
      return {
        ...state,
        recuperateDialog: { ...state.recuperateDialog, isOpen: true },
      };
    case "CLOSE_RECUPERATE_DIALOG":
      return {
        ...state,
        recuperateDialog: { ...state.recuperateDialog, isOpen: false },
      };
    case "SET_EMAIL_TEMPLATES":
      return { ...state, emailTemplates: action.payload };
    case "SET_INPUT_RECUPERATE":
      return {
        ...state,
        recuperate: {
          ...state.recuperate,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_SELECTED_INVOICE":
      return { ...state, selectedInvoice: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "SET_PAYMENT_METHOD_INFO":
      return {
        ...state,
        paycards: action.payload.paycards,
        ibans: action.payload.ibans,
      };
    case "SET_ADD_IBAN_LOADING_TRUE":
      return { ...state, addIbanLoading: true };
    case "SET_ADD_IBAN_LOADING_FALSE":
      return { ...state, addIbanLoading: false };
    case "SET_NEW_IBAN":
      return { ...state, newIban: action.payload };
    case "SET_NONPAYMENT_REASONS":
      return { ...state, nonPaymentReasons: action.payload };
    case "SET_EMAIL_FORM_CC":
      return {
        ...state,
        emailForm: {
          ...state.emailForm,
          cc: action.payload,
        },
      };
    case "SET_SEND_EMAIL_FORM":
      return {
        ...state,
        emailForm: {
          ...state.emailForm,
          ...action.payload,
        },
      };
    case "SET_RELOAD_EMAIL_FORM":
      return {
        ...state,
        emailDialog: {
          ...state.emailDialog,
          reloadForm: action.payload,
        },
      };
    case "RESET_RECUPERATE_MODAL":
      return { ...state, recuperate: initialState.recuperate };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    default:
      throw new Error("Action not found in reducer");
  }
};

export default function NonPaymentPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const [t] = useTranslation("nonPayments");
  const [tErrors] = useTranslation("errors");

  const [state, dispatch] = useReducer(reducer, initialState);

  const CONTRACT_COLUMNS = [
    {
      field: "id",
      renderCell: (params) => (
        <Tooltip title={t("seeContract")} placement="top">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/app/contract/${params.row.id}`);
            }}
            color="primary"
          >
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "center-box",
      headerName: t("centerBox"),
      width: 200,
      valueGetter: ({ row }) => row.Box?.Center?.name + " - " + row.Box?.name,
      renderCell: ({ value }) => (
        <Tooltip title={value ? value : ""} placement="top">
          <Typography variant="body2" noWrap>
            {value}
          </Typography>
        </Tooltip>
      ),
    },
    { headerName: t("startDate"), field: "startDate", width: 120 },
    {
      field: "endDate",
      headerName: t("endDate"),
      renderCell: ({ value }) => (
        <Tooltip title={value ? value : ""} placement="top">
          <Typography variant="body2" noWrap>
            {value ? value : t("-")}
          </Typography>
        </Tooltip>
      ),
      width: 120,
    },
    {
      field: "state",
      headerName: t("state"),
      valueGetter: ({ row }) => row,
      renderCell: ({ row }) =>
        row.isFrozen ? (
          <Chip
            icon={<AcUnitRoundedIcon style={{ color: "#ffffff" }} />}
            style={{
              backgroundColor: "#4DB9E8",
              color: "#ffffff",
            }}
            size="small"
            label={t("frozen")}
          />
        ) : (
          <ContractStateChip
            size="small"
            state={
              row.endDate ? ENDED_CONTRACT_STATE_ID : ACTIVE_CONTRACT_STATE_ID
            }
          />
        ),
    },
    {
      field: "dateOfPayment",
      headerName: t("dateOfPayment"),
      renderCell: (value) => {
        if (!value?.value) return 0;
        else return value.value;
      },
    },
    {
      field: "Periodicity",
      headerName: t("periodicity"),
      valueGetter: ({ row }) => t(row.Periodicity?.name.toLowerCase()),
    },
    {
      field: "PaymentMethod",
      headerName: t("paymentMethod"),
      valueGetter: ({ row }) =>
        getPaymentMethodTranslation(row.PaymentMethod?.name),
    },
    {
      field: "import",
      headerName: t("import"),
      valueGetter: ({ row }) => {
        // Periodicity id to number of months
        const periodicityMap = { 1: 1, 2: 3, 3: 6, 4: 12 };
        return (
          localeFormat(
            row.meters *
              row.pricePerMeter *
              (1 + row.taxes / 100) *
              periodicityMap[row.periodicity]
          ) + "€"
        );
      },
    },
  ];

  const INVOICE_COLUMNS = [
    {
      key: "invoiceNum",
      label: t("invoiceNumber"),
      sortType: "string",
      renderFunction: (value, item) => (
        <ButtonLink to={"/app/invoice/" + item.id} size="small">
          {value}
        </ButtonLink>
      ),
    },
    {
      key: "totalAmount",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + "€",
    },
    {
      key: "paymentMethodName",
      label: t("paymentMethod"),
      sortType: "string",
    },
    { key: "issueDate", label: t("issueDate"), sortType: "string" },
    {
      key: "state",
      label: t("state"),
      sortType: "number",
      renderFunction: (value) => <InvoiceStateChip state={value} />,
    },
    { key: "action", label: t("action"), sortType: "other" },
    {
      key: "uncollectible",
      label: t("uncollectibleInvoice"),
      sortType: "other",
      renderFunction: (value, item) => (
        <Grid item style={{ display: "flex", justifyContent: "center" }}>
          {value ? (
            <Tooltip title={t("uncollectibleInvoice")}>
              <IconButton
                color="error"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <ErrorIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </Grid>
      ),
    },
  ];

  //Initial useEffect
  useEffect(() => {
    getCustomer();
    getEmailTemplates();
    getInvoices();
    getPaymentMethods();
    getNonPaymentReasons();
    getReceipts();
  }, []);

  /* BACKEND CALLS */

  const getCustomer = () => {
    let params = {
      include: [
        "Box",
        "Center",
        "CenterEmail",
        "Contract",
        "ContractType",
        "CustomerComment",
        "CustomerEmail",
        "CustomerPhoneNumber",
        "PaymentMethod",
        "Periodicity",
        "IBAN",
        "PayCard",
      ],
    };

    api
      .get("/customers/" + id, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_CUSTOMER",
            payload: {
              customer: response.data,
              comments: response.data.CustomerComments
                ? response.data.CustomerComments
                : [],
              contracts: response.data.Contracts ? response.data.Contracts : [],
              email: response.data.CustomerEmails
                ? response.data.CustomerEmails.map((email) => email.email).join(
                    ";"
                  )
                : [],
              box: response.data.Box ? response.data.Box : [],
              center: response.data.Center ? response.data.Center : [],
            },
          });

          const centerEmails = response.data.Contracts.filter(
            (contract) => contract.state === ACTIVE_CONTRACT_STATE_ID
          ).flatMap((activeContract) =>
            activeContract.Box?.Center?.CenterEmails?.map(
              (centerEmail) => centerEmail?.email
            )
          );

          if (centerEmails.length > 0) {
            const uniqueCenterEmails = [...new Set(centerEmails)].join(";");
            dispatch({
              type: "SET_EMAIL_FORM_CC",
              payload: state.emailForm.cc + ";" + uniqueCenterEmails,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getEmailTemplates = () => {
    let params = {
      include: ["EmailAccount"], // To get sender email
      typeId: 6, // 6 -> Non Payment
    };

    api
      .get("/email-template-types", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const emailTemplates = response.data[0]?.EmailTemplates;
          if (emailTemplates) {
            dispatch({
              type: "SET_EMAIL_TEMPLATES",
              payload: emailTemplates,
            });
            const defaultEmailTemplate = emailTemplates.find(
              (template) => template.id === response.data[0]?.defaultTemplateId
            );
            dispatch({
              type: "SET_SEND_EMAIL_FORM",
              payload: {
                emailTemplateId: defaultEmailTemplate?.id,
                from: response.data[0].EmailAccount?.user,
                html: defaultEmailTemplate?.html,
                text: defaultEmailTemplate?.text,
                subject: defaultEmailTemplate?.subject,
              },
            });
          } else {
            enqueueSnackbar(t("noEmailTemplates"), { variant: "error" });
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoices = () => {
    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      customerId: id,
      include: [
        "PaymentMethod",
        "PayCard",
        "IBAN",
        "NonPayment",
        "Receipt",
        "Contract",
      ],
      attributes: [
        {
          model: "Invoice",
          attributes: [
            "actions",
            "baseAmount",
            "billingError",
            "customerId",
            "customerName",
            "dueDate",
            "emailSent",
            "id",
            "issueDate",
            "number",
            "state",
            "totalAmount",
            "uncollectible",
          ],
        },
        {
          model: "PaymentMethod",
          attributes: ["id", "name"],
        },
        {
          model: "PayCard",
          attributes: ["id", "number"],
        },
        {
          model: "IBAN",
          attributes: ["id", "number"],
        },
      ],
    };

    state.filters.dateFrom !== "" && (params.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil !== "" &&
      (params.dateUntil = state.filters.dateUntil);
    state.filters.invoiceNumber !== "" &&
      (params.number = state.filters.invoiceNumber);
    state.filters.state !== "" && (params.state = state.filters.state);

    api
      .get("/invoices", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noInvoices"), { variant: "info" });
          }
          dispatch({
            type: "SET_PAYMENT_METHOD_INFO",
            payload: {
              paycards: response.data[0]?.Customer?.PayCards,
              ibans: response.data[0]?.Customer?.IBANs,
            },
          });

          const invoices = response.data.map((invoice) => ({
            ...invoice,
            paymentMethodName: getPaymentMethodTranslation(
              invoice.PaymentMethod?.name
            ),
          }));
          dispatch({ type: "SET_INVOICES", payload: invoices });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getNonPaymentReasons = () => {
    api
      .get("/non-payments/reasons")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_NONPAYMENT_REASONS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getReceipts = () => {
    let params = {
      include: ["Remittance"],
      customerId: id,
    };
    api
      .get("/receipts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_RECEIPTS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const addComment = (comment = null) => {
    const newComment = comment || state.form.newComment;
    if (newComment !== "") {
      let form = {
        text: newComment,
        customerId: id,
        authorId: comment ? null : user.id,
        authorName: comment ? null : user.name,
      };
      api
        .post("/customers/" + id + "/comment", form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("commentAddedSuccessfully"), {
              variant: "success",
            });
            dispatch({
              type: "SET_COMMENTS",
              payload: [...state.comments, response.data],
            });
            !comment &&
              dispatch({
                type: "SET_INPUT",
                payload: {
                  inputname: "newComment",
                  value: "",
                },
              });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else {
      enqueueSnackbar(t("commentCannotBeEmpty"), { variant: "warning" });
    }
  };

  const createIban = (number) => {
    const ibanRepeated = state.ibans.some((iban) => iban.number === number);

    if (!ibanRepeated) {
      dispatch({ type: "SET_ADD_IBAN_LOADING_TRUE" });
      let data = {};
      data.number = number;
      data.customerId = state.customer.id;

      api
        .post("/ibans/create", data)
        .then((response) => {
          dispatch({ type: "SET_ADD_IBAN_LOADING_FALSE" });
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("ibanCreatedSuccessfully"), {
              variant: "success",
            });
            dispatch({
              type: "SET_PAYMENT_METHOD_INFO",
              payload: {
                paycards: state.paycards,
                ibans: [...state.ibans, response.data.iban],
              },
            });
            dispatch({ type: "SET_NEW_IBAN", payload: "" });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else {
      enqueueSnackbar(t("ibanRepeated"), { variant: "warning" });
    }
  };

  const createNonPayment = (form, receipts) => {
    if (!validateCreateNonPayment(form)) {
      return;
    }
    dispatch({ type: "SET_NONPAYMENT_DIALOG_CREATE_LOADING", payload: true });
    let data = {
      nonPayment: {
        nonPaymentDate: form.date,
        reason: form.reason,
        comments: form.comment,
        invoiceId: state.selectedInvoice.id,
      },
      receipts: receipts,
    };

    api
      .post("/non-payments/create", data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("nonPaymentCreatedSuccessfully"), {
            variant: "success",
          });
          closeNonPaymentDialog();
          addComment(
            `${t("theUser")} ${user.name} ${t(
              "createdANonPayment"
            ).toLowerCase()} ${t("forTheInvoice").toLowerCase()} ${
              state.selectedInvoice.invoiceNum
            }.
            ${form.comment !== "" ? t("comment") + ": " + form.comment : ""}`
          );
          getInvoices();
        }
        dispatch({
          type: "SET_NONPAYMENT_DIALOG_CREATE_LOADING",
          payload: false,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const recuperate = () => {
    dispatch({
      type: "SET_RECUPERATE_DIALOG_RECUPERATION_LOADING",
      payload: true,
    });
    let data = {};

    Number(state.recuperate.recuperationMethod) === RECEIPT_PAYMENT_METHOD_ID &&
      state.recuperate.ibanId !== "" &&
      (data.ibanId = state.recuperate.ibanId);
    Number(state.recuperate.recuperationMethod) === PAYCARD_PAYMENT_METHOD_ID &&
      state.recuperate.paycardId !== "" &&
      (data.paycardId = state.recuperate.paycardId);
    state.recuperate.recuperationComment !== "" &&
      (data.recuperationComment = state.recuperate.recuperationComment);
    state.recuperate.recuperationMethod !== "" &&
      (data.recuperationMethod = state.recuperate.recuperationMethod);
    state.recuperate.paymentManagement !== "" &&
      (data.paymentManagement = state.recuperate.paymentManagement);

    api
      .post(
        "/non-payments/" +
          state.selectedInvoice.NonPayments.find(
            (nonPayment) => !nonPayment.recuperationDate
          ).id +
          "/edit",
        data
      )
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("nonPaymentRecuperatedSuccessfully"), {
            variant: "success",
          });
          closeRecuperationDialog();
          addComment(
            `${t("theUser")} ${user.name} ${t(
              "hasRecuperatedANonPayment"
            ).toLowerCase()} ${t("fromTheInvoice").toLowerCase()} ${
              state.selectedInvoice.invoiceNum
            }.
            ${
              state.recuperate.recuperationComment !== ""
                ? t("comment") + ": " + state.recuperate.recuperationComment
                : ""
            }`
          );
          getInvoices();
          dispatch({ type: "RESET_RECUPERATE_MODAL" });
        }
        dispatch({
          type: "SET_RECUPERATE_DIALOG_RECUPERATION_LOADING",
          payload: false,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const sendEmail = () => {
    dispatch({ type: "SET_WAITING_EMAIL_RESPONSE", payload: true });
    const data = {
      from: state.emailForm.from,
      to: state.emailForm.to,
      cc: state.emailForm.cc,
      subject: state.emailForm.subject,
      html: state.emailForm.html,
      text: state.emailForm.text,
    };

    api
      .post("/non-payments/send-email", data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          // const isAccepted = checkEmail(response.data.accepted);
          // if (isAccepted) {
          enqueueSnackbar(t("emailSentSuccessfully"), { variant: "success" });
          closeEmailDialog();
          addComment(`${t("theUser")} ${user.name} ${t(
            "sentNonPaymentEmail"
          ).toLowerCase()}.
              ${t("to")}: ${data.to}
              ${t("subject")}: ${data.subject}
              ${t("body")}: ${data.text}`);
          // } else {
          // enqueueSnackbar(t("emailRejected"), { variant: "warning" });
          // }
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_WAITING_EMAIL_RESPONSE", payload: false });
      });
  };

  const setAsUncollectible = (invoice) => {
    api
      .post(`/invoices/mark-uncollectible/${invoice.id}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else getInvoices();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const submitCardForm = (card) => {
    let form = {
      name: card.name,
      number: card.number,
      cvv: card.cvc,
      expirationMonth: card.expiry.substring(0, 2),
      expirationYear: card.expiry.substring(3),
      customerId: state.customer.id,
    };

    api
      .post("/pay-cards/create", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("cardCreatedSuccessfully"), { variant: "success" });

          dispatch({
            type: "SET_PAYMENT_METHOD_INFO",
            payload: {
              paycards: [...state.paycards, response.data],
              ibans: state.ibans,
            },
          });

          handleCloseCardModal();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  /* HANDLERS */

  const closeEmailDialog = () => {
    dispatch({ type: "CLOSE_EMAIL_DIALOG" });
  };

  const closeNonPaymentDialog = () => {
    dispatch({ type: "CLOSE_NONPAYMENT_DIALOG" });
  };

  const closeRecuperationDialog = () => {
    dispatch({ type: "CLOSE_RECUPERATE_DIALOG" });
    dispatch({ type: "RESET_RECUPERATE_MODAL" });
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleInputChangeIban = (e) => {
    dispatch({ type: "SET_NEW_IBAN", payload: e.target.value });
  };

  const handleOpenCardModal = () => {
    dispatch({ type: "OPEN_PAYCARD_MODAL" });
  };

  const handleCloseCardModal = () => {
    dispatch({ type: "CLOSE_PAYCARD_MODAL" });
  };

  const handleRecuperateChange = (e) => {
    dispatch({
      type: "SET_INPUT_RECUPERATE",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleSendEmailFormChange = (e) => {
    if (e.target.name !== "emailTemplateId") {
      dispatch({
        type: "SET_INPUT_SEND_EMAIL",
        payload: {
          inputname: e.target.name,
          value: e.target.value,
        },
      });
    } else {
      const emailTemplate = state.emailTemplates.find(
        (template) => template.id === e.target.value
      );
      const { id, html, text, subject } = emailTemplate;
      //Setting the opposite value to reload the html template
      dispatch({
        type: "SET_RELOAD_EMAIL_FORM",
        payload: !state.emailDialog.reloadForm,
      });
      dispatch({
        type: "SET_SEND_EMAIL_FORM",
        payload: {
          emailTemplateId: id,
          html,
          text,
          subject,
        },
      });
    }
  };

  const handleSendEmailTemplateChange = (html, text) => {
    dispatch({
      type: "SET_SEND_EMAIL_FORM",
      payload: {
        html,
        text,
      },
    });
  };

  const openEmailDialog = () => {
    dispatch({ type: "OPEN_EMAIL_DIALOG" });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  const showNonPaymentDialog = (invoice) => {
    dispatch({ type: "SET_SELECTED_INVOICE", payload: invoice });
    dispatch({ type: "OPEN_NONPAYMENT_DIALOG" });
  };

  const showRecuperationDialog = (invoice) => {
    // Get last non payment
    const lastNonPayment = invoice.NonPayments.find(
      (nonPayment) => !nonPayment.recuperationDate
    );

    invoice.reason = state.nonPaymentReasons.find(
      (reason) => reason.id === lastNonPayment.reason
    ).name;
    invoice.nonPaymentDate = lastNonPayment.nonPaymentDate;
    invoice.comments = lastNonPayment.comments;

    dispatch({ type: "SET_SELECTED_INVOICE", payload: invoice });
    dispatch({ type: "OPEN_RECUPERATE_DIALOG" });
  };

  /* HELPERS */

  const checkEmail = (acceptedEmails) => {
    const emailList = state.emailForm.to.split(";");
    return emailList.some((email) => acceptedEmails.includes(email));
  };

  const getFormattedDateTime = (commentDate) => {
    const date = new Date(commentDate);
    return (
      formatDate(date) +
      " " +
      String(date.getHours()).padStart(2, "0") +
      ":" +
      String(date.getMinutes()).padStart(2, "0")
    );
  };

  const getPaymentMethodTranslation = (paymentMethodName) => {
    const paymentMethodMap = {
      CONTADO: t("cash"),
      RECIBO: t("receipt"),
      TARJETA: t("card"),
      TRANSFERENCIA: t("transfer"),
      CONSOLIDACION: t("consolidation"),
    };
    return paymentMethodMap[paymentMethodName]
      ? paymentMethodMap[paymentMethodName]
      : "PaymentMethod not found";
  };

  /* VALIDATORS */
  const validateCreateNonPayment = (form) => {
    const mandatoryFields = ["date", "reason"];
    let isValid = true;

    mandatoryFields.forEach((field) => {
      if (!form[field]) {
        enqueueSnackbar(t(field) + " " + t("cannotBeEmpty"), {
          variant: "error",
        });
        isValid = false;
      }
    });
    return isValid;
  };

  const openConfirmUncollectibeDialog = (invoice) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        childrenText:
          t("invoice") + ": " + invoice.InvoiceSerie.name + invoice.number,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && setAsUncollectible(invoice);
          resetConfirmDialog();
        },
      },
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  return (
    <Page browserTitle={t("nonPayment")} paper={true}>
      <Grid container spacing={3}>
        <Grid item container alignItems="center" xs={12}>
          <Grid item flexGrow={1}>
            <Typography variant="h4">{t("nonPayment")}</Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Tooltip title={t("sendEmail")} placement="top">
              <IconButton color="primary" onClick={openEmailDialog}>
                <EmailIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid item container spacing={4} alignItems="flex-start">
          <Grid item container xs={12} md={6} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("customerInfo")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container spacing={1} alignItems="center">
              <Grid item>
                <AccountCircleIcon color="disabled" />
              </Grid>
              <Grid item>
                <ButtonLink
                  to={"/app/customer/" + state.customer.id}
                  sx={{ padding: 0 }}
                >
                  <Typography variant="body1">
                    {state.customer.fullName}
                  </Typography>
                </ButtonLink>
                {state.customer?.fullName && (
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(state.customer?.fullName);
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>

            <Grid item container spacing={1} alignItems="center">
              <Grid item>
                <EmailIcon color="disabled" />
              </Grid>
              <Grid item>
                {state.customer.CustomerEmails?.length > 0 ? (
                  <Grid
                    container
                    alignItems="center"
                    style={{ display: "flex" }}
                  >
                    {state.customer.CustomerEmails.map((cEmail, i) => (
                      <Typography key={i} variant="body1">
                        {i > 0 ? "; " + cEmail.email : cEmail.email}
                      </Typography>
                    ))}
                  </Grid>
                ) : (
                  "---"
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={1} alignItems="center">
              <Grid item>
                <PhoneIcon color="disabled" />
              </Grid>
              <Grid item>
                {state.customer.CustomerPhoneNumbers?.length > 0 ? (
                  <Grid
                    container
                    alignItems="center"
                    style={{ display: "flex" }}
                  >
                    {state.customer.CustomerPhoneNumbers.map((cPhone, i) => (
                      <Typography key={i} variant="body1">
                        {i > 0 ? "; " + cPhone.number : cPhone.number}
                      </Typography>
                    ))}
                  </Grid>
                ) : (
                  "---"
                )}
              </Grid>
            </Grid>

            <Grid item container spacing={1} alignItems="center">
              <Grid item>
                <AccountBalanceIcon color="disabled" />
              </Grid>
              <Grid item>
                {state.customer.IBANs?.length > 0 ? (
                  <Grid
                    container
                    alignItems="center"
                    style={{ display: "flex" }}
                  >
                    {state.customer.IBANs.map((iban, i) => (
                      <Typography key={i} variant="body1">
                        {i > 0 ? "; " + iban.number : iban.number}
                      </Typography>
                    ))}
                  </Grid>
                ) : (
                  "---"
                )}
              </Grid>
            </Grid>

            <Grid item container spacing={1} alignItems="center">
              <Grid item>
                <CreditCardIcon color="disabled" />
              </Grid>
              <Grid item>
                {state.customer.PayCards?.length > 0 ? (
                  <Grid
                    container
                    alignItems="center"
                    style={{ display: "flex" }}
                  >
                    {state.customer.PayCards.map((card, i) => (
                      <Typography key={i} variant="body1">
                        {i > 0
                          ? "; " +
                            card.number +
                            " (" +
                            card.expirationMonth +
                            "/" +
                            card.expirationYear +
                            ")"
                          : card.number +
                            " (" +
                            card.expirationMonth +
                            "/" +
                            card.expirationYear +
                            ")"}
                      </Typography>
                    ))}
                  </Grid>
                ) : (
                  "---"
                )}
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Box style={{ height: 300, width: "100%" }}>
                <DataGrid columns={CONTRACT_COLUMNS} rows={state.contracts} />
              </Box>
            </Grid>
          </Grid>

          {user.hasAction("VIEW_CUSTOMER_COMMENTS") && (
            <Grid container item spacing={1} xs={12} md={6}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("comments")}</Typography>
              </Grid>
              {state.comments.length > 0 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
              <Grid item xs={12}>
                <Box maxHeight="397px" overflow="auto">
                  <Grid container spacing={2}>
                    <Grid item container spacing={1} xs={12}>
                      {Array.isArray(state.comments) &&
                        state.comments
                          .sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          )
                          .map((comment, index) => (
                            <Grid item xs={12} key={index}>
                              <Box bgcolor="#f1f2f2" borderRadius={2} p={2}>
                                <Grid container spacing={1}>
                                  <Grid item>
                                    <Typography
                                      variant="body1"
                                      fontWeight="bold"
                                      style={{ wordWrap: "break-word" }}
                                    >
                                      {getFormattedDateTime(comment.createdAt)}{" "}
                                      - {comment.authorName || t("system")}:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        wordWrap: "break-word",
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      {comment.text}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          ))}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {user.hasAction("EDIT_CUSTOMER_COMMENTS") && (
                <>
                  {state.comments.length > 0 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12} md={9}>
                        <TextField
                          multiline
                          variant="outlined"
                          label={t("newComment")}
                          name={"newComment"}
                          value={state.form.newComment}
                          onChange={handleInputChange}
                          fullWidth
                          rows={2}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addComment()}
                          fullWidth
                          disabled={state.form.newComment === ""}
                        >
                          {t("addComment")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item>
          <Filters
            filters={[
              <TextInput
                name="invoiceNumber"
                onChange={handleFilterChange}
                value={state.filters.invoiceNumber}
                label={t("invoiceNumber")}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    getInvoices();
                  }
                }}
              />,
              <CustomSelect
                label={t("state")}
                value={state.filters.state}
                onChange={handleFilterChange}
                options={[
                  { value: "", label: t("all") },
                  { value: ISSUED_INVOICE_STATE_ID, label: t("issued") },
                  { value: PAID_INVOICE_STATE_ID, label: t("paid") },
                  { value: EXPIRED_INVOICE_STATE_ID, label: t("expired") },
                  { value: UNPAID_INVOICE_STATE_ID, label: t("unpaid") },
                ]}
                name="state"
              />,
              <TextInput
                label={t("dateFrom")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.filters.dateFrom}
                onChange={handleFilterChange}
                name="dateFrom"
              />,
              <TextInput
                label={t("dateUntil")}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={state.filters.dateUntil}
                onChange={handleFilterChange}
                name="dateUntil"
              />,
            ]}
          />
        </Grid>

        <Grid item xs={12} sm="auto">
          <ButtonGroup variant="contained">
            <Button onClick={resetFilters}>{t("reset")}</Button>
            <SearchButton onClick={getInvoices} loading={!state.options.loaded}>
              {t("search")}
            </SearchButton>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12}>
          <CustomTable
            columns={INVOICE_COLUMNS}
            data={
              Array.isArray(state.invoices) &&
              state.invoices.map((invoice) => {
                invoice.invoiceNum = invoice.InvoiceSerie.name + invoice.number;
                if (
                  invoice.NonPayments.length !== 0 &&
                  invoice.NonPayments.some(
                    (nonPayment) => !nonPayment.recuperationDate
                  )
                ) {
                  invoice.action = (
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={(e) => {
                            showRecuperationDialog(invoice);
                          }}
                        >
                          {t("showNonPayment")}
                        </Button>
                      </Grid>
                      {!invoice.uncollectible && (
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              openConfirmUncollectibeDialog(invoice);
                            }}
                          >
                            {t("markAsUncollectible")}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  );
                  return invoice;
                } else {
                  !invoice.uncollectible &&
                    (invoice.action = (
                      <Grid>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            showNonPaymentDialog(invoice);
                          }}
                        >
                          {t("nonPayment")}
                        </Button>
                      </Grid>
                    ));
                  return invoice;
                }
              })
            }
            options={state.options}
          />
        </Grid>

        <Grid item container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            {t("back")}
          </Button>
        </Grid>
      </Grid>

      {/* Send email dialog */}
      <Dialog
        open={state.emailDialog.isOpen}
        onClose={closeEmailDialog}
        title={t("sendNonPaymentEmail")}
        maxWidth="md"
        children={
          <Grid item container spacing={1} marginTop={1}>
            <Grid item xs={12}>
              <CustomSelect
                label={t("emailTemplate")}
                name="emailTemplateId"
                onChange={handleSendEmailFormChange}
                value={state.emailForm.emailTemplateId}
                options={state.emailTemplates.map((template) => ({
                  value: template.id,
                  label: template.name,
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label={t("to")}
                name="to"
                onChange={handleSendEmailFormChange}
                value={state.emailForm.to}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput
                label={t("from")}
                name="from"
                onChange={handleSendEmailFormChange}
                value={state.emailForm.from}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextInput
                label={t("copyTo")}
                name="cc"
                onChange={handleSendEmailFormChange}
                value={state.emailForm.cc}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label={t("subject")}
                name="subject"
                onChange={handleSendEmailFormChange}
                value={state.emailForm.subject}
              />
            </Grid>
            <Grid item xs={12}>
              <Box component={Paper} padding={1}>
                <EmailTextEditor
                  contractTypeId={state.emailForm.emailTemplateId}
                  onChange={handleSendEmailTemplateChange}
                  html={state.emailForm.html}
                  forceUpdate={state.emailDialog.reloadForm}
                />
              </Box>
            </Grid>
          </Grid>
        }
        actions={
          <CustomButton
            color="primary"
            onClick={sendEmail}
            loading={state.emailDialog.waitingEmailResponse}
            disabled={!state.emailForm.to}
          >
            {t("sendEmail")}
          </CustomButton>
        }
      />

      {/*Create Non Payment dialog */}
      <CreateNonPaymentForm
        open={state.nonPaymentDialog.isOpen}
        onClose={closeNonPaymentDialog}
        onSubmit={createNonPayment}
        receipts={state.receipts}
        invoicePaymentMethodId={state.selectedInvoice?.PaymentMethod?.id}
        loading={state.nonPaymentDialog.createLoading}
      />

      {/* Non Payment info dialog */}
      <Dialog
        open={state.recuperateDialog.isOpen}
        onClose={closeRecuperationDialog}
        maxWidth="md"
        title={t("nonPaymentInformation")}
        children={
          <Grid item container spacing={3} columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("invoiceNumber")}:
              </Typography>
              <Typography variant="body">
                {" "}
                {state.selectedInvoice.number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("amount")}:
              </Typography>
              <Typography variant="body">
                {" "}
                {state.selectedInvoice.totalAmount} €
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("reason")}:
              </Typography>
              <Typography variant="body">
                {" "}
                {state.selectedInvoice.reason}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("nonPaymentDate")}:
              </Typography>
              <Typography variant="body">
                {" "}
                {state.selectedInvoice.nonPaymentDate}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("comments")}:
              </Typography>
              <Typography variant="body" style={{ wordWrap: "break-word" }}>
                {" "}
                {state.selectedInvoice.comments}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{t("recuperation")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="recuperationMethod"
                  value={Number(state.recuperate.recuperationMethod)}
                  onChange={handleRecuperateChange}
                >
                  {state.paymentMethods.map((method, index) => (
                    <FormControlLabel
                      key={index}
                      value={method.id}
                      control={<Radio />}
                      label={getPaymentMethodTranslation(method.name)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            {Number(state.recuperate.recuperationMethod) ===
              RECEIPT_PAYMENT_METHOD_ID && (
              <Grid container spacing={1} item xs={12}>
                <Grid item xs={12} sm={5}>
                  <CustomSelect
                    value={state.recuperate.ibanId}
                    name="ibanId"
                    onChange={handleRecuperateChange}
                    label={t("ibans")}
                    options={[
                      { value: "", label: t("none") },
                      ...state.ibans.map((iban) => ({
                        value: iban.id,
                        label: iban.number,
                      })),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextInput
                    label={t("newIban")}
                    name="newIban"
                    value={state.newIban}
                    onChange={handleInputChangeIban}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <CustomButton
                    loading={state.addIbanLoading}
                    loadingText=""
                    disabled={state.newIban === ""}
                    onClick={() => {
                      createIban(state.newIban);
                    }}
                  >
                    {t("add")}
                  </CustomButton>
                </Grid>
              </Grid>
            )}

            {Number(state.recuperate.recuperationMethod) ===
              PAYCARD_PAYMENT_METHOD_ID && (
              <Grid container item spacing={1} xs={12}>
                <Grid item xs={12} sm={5}>
                  <CustomSelect
                    value={state.recuperate.paycardId}
                    name="paycardId"
                    onChange={handleRecuperateChange}
                    label={t("card")}
                    options={[
                      { value: "", label: t("none") },
                      { value: VIRTUAL_PAYCARD_ID, label: t("paymentByLink") },
                      ...state.paycards.map((card) => ({
                        value: card.id,
                        label: card.number,
                      })),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Button variant="contained" onClick={handleOpenCardModal}>
                    {t("addCreditCard")}
                  </Button>
                </Grid>

                <Dialog
                  open={state.cardModalIsOpen}
                  onClose={handleCloseCardModal}
                  title={t("addCreditCard")}
                  children={<CreditCardForm onSubmit={submitCardForm} />}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Typography variant="body1">
                {t("recuperationDate") + ": " + String(new Date()).slice(4, 15)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                variant="outlined"
                label={t("recuperationComment")}
                name={"recuperationComment"}
                value={state.recuperate.recuperationComment}
                onChange={handleRecuperateChange}
                fullWidth
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                value={state.recuperate.paymentManagement}
                label={t("addPaymentManagement")}
                onChange={(e) =>
                  handleRecuperateChange({
                    target: {
                      name: "paymentManagement",
                      value: e.target.value,
                    },
                  })
                }
                options={[
                  { value: 10, label: "10€" },
                  { value: 20, label: "20€" },
                  { value: 40, label: "40€" },
                ]}
              />
            </Grid>
          </Grid>
        }
        actions={
          <CustomButton
            onClick={recuperate}
            disabled={
              state.recuperate.recuperationMethod === "" ||
              state.recuperate.recuperationDate === "" ||
              (Number(state.recuperate.recuperationMethod) ===
                RECEIPT_PAYMENT_METHOD_ID &&
                state.recuperate.ibanId === "") ||
              (Number(state.recuperate.recuperationMethod) ===
                PAYCARD_PAYMENT_METHOD_ID &&
                state.recuperate.paycardId === "")
            }
            loading={state.recuperateDialog.recuperationLoading}
          >
            {t("recuperate")}
          </CustomButton>
        }
      />

      <ConfirmDialog
        title={t("markUncollectibleConfirmation")}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Page>
  );
}
