import { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Material UI
import {
  Alert,
  Box,
  Button,
  Collapse,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Components & utils
import AppContext from "../../../context/AppContext";
import CustomButton from "../../Inputs/CustomButton";
import CustomDate from "../../Inputs/CustomDate";
import CenterSelect from "../../Inputs/CenterSelect";
import { localeFormat, localeFormatInteger } from "../../../utils/format";
import TextInput from "../../Inputs/TextInput";
import ItemsSummary from "../../ItemsSummary";
import SearchButton from "../../Inputs/SearchButton";

// Constants
import { CASH_PAYMENT_METHOD_ID } from "../../../data/constants";

const initialState = {
  center: "",
  comments: "",
  defaultStartDate: "",
  filters: {
    startDate: "",
    endDate: "",
    centerId: "",
  },
  invoiceItems: [],
  invoiceItemsLoading: false,
  name: "",
  nonPayments: [],
  nonPaymentsLoading: false,
  recuperations: [],
  recuperationsLoading: false,
  billingErrors: [],
  billingErrorsLoading: false,
  settlements: [],
  submitLoading: false,
  summary: {
    base: {
      invoiceItems: 0,
      nonPayments: 0,
      recuperations: 0,
      billingErrors: 0,
    },
    baseWithRoyalty: {
      invoiceItems: 0,
      nonPayments: 0,
      recuperations: 0,
      billingErrors: 0,
    },
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CENTER":
      return { ...state, center: action.payload };
    case "SET_COMMENTS":
      return { ...state, comments: action.payload };
    case "SET_DEFAULT_START_DATE":
      return { ...state, defaultStartDate: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputName]: action.payload.value,
        },
      };
    case "SET_INVOICE_ITEMS":
      return { ...state, invoiceItems: action.payload };
    case "SET_INVOICE_ITEMS_LOADING":
      return { ...state, invoiceItemsLoading: action.payload };
    case "SET_NAME":
      return { ...state, name: action.payload };
    case "SET_NON_PAYMENTS":
      return { ...state, nonPayments: action.payload };
    case "SET_NON_PAYMENTS_LOADING":
      return { ...state, nonPaymentsLoading: action.payload };
    case "SET_RECUPERATIONS":
      return { ...state, recuperations: action.payload };
    case "SET_RECUPERATIONS_LOADING":
      return { ...state, recuperationsLoading: action.payload };
    case "SET_BILLING_ERRORS":
      return { ...state, billingErrors: action.payload };
    case "SET_BILLING_ERRORS_LOADING":
      return { ...state, billingErrorsLoading: action.payload };
    case "SET_SETTLEMENTS":
      return { ...state, settlements: action.payload };
    case "SET_SUBMIT_LOADING":
      return { ...state, submitLoading: action.payload };
    case "SET_SUMMARY":
      return { ...state, summary: action.payload };
    default:
      throw new Error("Action not found in reducer");
  }
};

const CreateSettlementPage = () => {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("settlements");
  const [tErrors] = useTranslation("errors");

  // Get yesterday's date as string in YYYY-MM-DD format for date inputs (max)
  let YESTERDAY = new Date();
  YESTERDAY.setDate(YESTERDAY.getDate() - 1);
  YESTERDAY = YESTERDAY.toISOString().split("T")[0];

  // Columns
  const INVOICE_ITEMS_COLUMNS = [
    { field: "concept", headerName: t("concept"), flex: 2, minWidth: 75 },
    {
      field: "customer",
      headerName: t("customer"),
      flex: 1,
      minWidth: 75,
      valueGetter: ({ row }) => row.Invoice?.customerName,
    },
    {
      field: "Invoice",
      headerName: t("invoice"),
      width: 100,
      valueGetter: ({ row }) =>
        row.Invoice?.InvoiceSerie?.name + row.Invoice?.number,
    },
    {
      field: "issueDate",
      headerName: t("issueDate"),
      width: 100,
      valueGetter: ({ row }) => row.Invoice?.issueDate,
    },
    {
      field: "paymentMethod",
      headerName: t("paymentMethod"),
      width: 130,
      valueGetter: ({ row }) => row.Invoice?.PaymentMethod?.name,
    },
    {
      field: "base",
      headerName: t("baseAmount"),
      width: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "royalty",
      headerName: t("royalty"),
      width: 80,
      valueGetter: ({ row }) =>
        (row.Merchantable?.royaltyAmount /
          (row.Merchantable?.pricePerUnit * row.Merchantable?.units)) *
        100,
      valueFormatter: ({ value }) => localeFormatInteger(value) + "%",
    },
    {
      field: "baseWithRoyalty",
      headerName: t("total"),
      width: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
  ];

  const NON_PAYMENTS_COLUMNS = [
    {
      field: "concept",
      headerName: t("concept"),
      flex: 2,
      minWidth: 75,
    },
    {
      field: "customer",
      headerName: t("customer"),
      flex: 1,
      minWidth: 75,
      valueGetter: ({ row }) => row.Invoice?.customerName,
    },
    {
      field: "Invoice",
      headerName: t("invoice"),
      width: 100,
      valueGetter: ({ row }) =>
        row.Invoice?.InvoiceSerie?.name + row.Invoice?.number,
    },
    {
      field: "Invoice.date",
      headerName: t("invoiceDate"),
      width: 100,
      valueGetter: ({ row }) => row.Invoice?.issueDate,
    },
    {
      field: "nonPaymentDate",
      headerName: t("nonPaymentDate"),
      width: 100,
      valueGetter: ({ row }) => row.Invoice?.NonPayments[0]?.nonPaymentDate,
    },
    {
      field: "paymentMethod",
      headerName: t("paymentMethod"),
      width: 130,
      valueGetter: ({ row }) => row.Invoice?.PaymentMethod?.name,
    },
    {
      field: "base",
      headerName: t("baseAmount"),
      width: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "Merchantable",
      headerName: t("royalty"),
      width: 60,
      valueGetter: ({ row }) =>
        (row.Merchantable?.royaltyAmount /
          (row.Merchantable?.pricePerUnit * row.Merchantable?.units)) *
        100,
      valueFormatter: ({ value }) => localeFormatInteger(value) + "%",
    },
    {
      field: "baseWithRoyalty",
      headerName: t("total"),
      width: 100,
      valueFormatter: ({ value }) => localeFormat(-value) + "€",
    },
  ];

  const RECUPERATIONS_COLUMNS = [
    {
      field: "concept",
      headerName: t("concept"),
      flex: 2,
      minWidth: 75,
    },
    {
      field: "customer",
      headerName: t("customer"),
      flex: 1,
      minWidth: 75,
      valueGetter: ({ row }) => row.Invoice?.customerName,
    },
    {
      field: "Invoice",
      headerName: t("invoice"),
      width: 100,
      valueGetter: ({ row }) =>
        row.Invoice?.InvoiceSerie?.name + row.Invoice?.number,
    },
    {
      field: "Invoice.date",
      headerName: t("invoiceDate"),
      width: 100,
      valueGetter: ({ row }) => row.Invoice?.issueDate,
    },
    {
      field: "recuperationDate",
      headerName: t("recuperationDate"),
      width: 100,
      valueGetter: ({ row }) => row.Invoice?.NonPayments[0]?.recuperationDate,
    },
    {
      field: "paymentMethod",
      headerName: t("paymentMethod"),
      width: 130,
      valueGetter: ({ row }) =>
        row.Invoice?.NonPayments[0]?.PaymentMethod?.name,
    },
    {
      field: "base",
      headerName: t("baseAmount"),
      width: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "Merchantable",
      headerName: t("royalty"),
      width: 60,
      valueGetter: ({ row }) =>
        (row.Merchantable?.royaltyAmount /
          (row.Merchantable?.pricePerUnit * row.Merchantable?.units)) *
        100,
      valueFormatter: ({ value }) => localeFormatInteger(value) + "%",
    },
    {
      field: "baseWithRoyalty",
      headerName: t("total"),
      width: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
  ];

  const BILLING_ERRORS_COLUMNS = [
    { field: "concept", headerName: t("concept"), flex: 2, minWidth: 75 },
    {
      field: "customer",
      headerName: t("customer"),
      flex: 1,
      minWidth: 75,
      valueGetter: ({ row }) => row.Invoice?.customerName,
    },
    {
      field: "Invoice",
      headerName: t("invoice"),
      width: 100,
      valueGetter: ({ row }) =>
        row.Invoice?.InvoiceSerie?.name + row.Invoice?.number,
    },
    {
      field: "issueDate",
      headerName: t("issueDate"),
      width: 100,
      valueGetter: ({ row }) => row.Invoice?.issueDate,
    },
    {
      field: "paymentMethod",
      headerName: t("paymentMethod"),
      width: 130,
      valueGetter: ({ row }) => row.Invoice?.PaymentMethod?.name,
    },
    {
      field: "base",
      headerName: t("baseAmount"),
      width: 130,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "royalty",
      headerName: t("royalty"),
      width: 80,
      valueGetter: ({ row }) =>
        (row.Merchantable?.royaltyAmount /
          (row.Merchantable?.pricePerUnit * row.Merchantable?.units)) *
        100,
      valueFormatter: ({ value }) => localeFormatInteger(value) + "%",
    },
    {
      field: "baseWithRoyalty",
      headerName: t("total"),
      width: 130,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
  ];

  //Items summary
  const INVOICE_ITEMS_BASE_SUMMARY = [
    {
      translatedText: t("baseCenter"),
      value:
        localeFormat(
          state.invoiceItems.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id === CASH_PAYMENT_METHOD_ID
                ? item.base
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("baseCentral"),
      value:
        localeFormat(
          state.invoiceItems.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id !== CASH_PAYMENT_METHOD_ID
                ? item.base
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("base"),
      value: localeFormat(state.summary.base.invoiceItems) + "€",
    },
  ];

  const INVOICE_ITEMS_TOTAL_SUMMARY = [
    {
      translatedText: t("totalCenter"),
      value:
        localeFormat(
          state.invoiceItems.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id === CASH_PAYMENT_METHOD_ID
                ? item.baseWithRoyalty
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalCentral"),
      value:
        localeFormat(
          state.invoiceItems.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id !== CASH_PAYMENT_METHOD_ID
                ? item.baseWithRoyalty
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("total"),
      value: localeFormat(state.summary.baseWithRoyalty.invoiceItems) + "€",
    },
  ];

  const NON_PAYMENTS_BASE_SUMMARY = [
    {
      translatedText: t("baseCenter"),
      value:
        localeFormat(
          state.nonPayments.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id === CASH_PAYMENT_METHOD_ID
                ? -item.base
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("baseCentral"),
      value:
        localeFormat(
          state.nonPayments.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id !== CASH_PAYMENT_METHOD_ID
                ? -item.base
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("base"),
      value: localeFormat(state.summary.base.nonPayments) + "€",
    },
  ];

  const NON_PAYMENTS_TOTAL_SUMMARY = [
    {
      translatedText: t("totalCenter"),
      value:
        localeFormat(
          state.nonPayments.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id === CASH_PAYMENT_METHOD_ID
                ? -item.baseWithRoyalty
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalCentral"),
      value:
        localeFormat(
          state.nonPayments.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id !== CASH_PAYMENT_METHOD_ID
                ? -item.baseWithRoyalty
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("total"),
      value: localeFormat(state.summary.baseWithRoyalty.nonPayments) + "€",
    },
  ];

  const RECUPERATIONS_BASE_SUMMARY = [
    {
      translatedText: t("baseCenter"),
      value:
        localeFormat(
          state.recuperations.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id === CASH_PAYMENT_METHOD_ID
                ? item.base
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("baseCentral"),
      value:
        localeFormat(
          state.recuperations.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id !== CASH_PAYMENT_METHOD_ID
                ? item.base
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("base"),
      value: localeFormat(state.summary.base.recuperations) + "€",
    },
  ];

  const RECUPERATIONS_TOTAL_SUMMARY = [
    {
      translatedText: t("totalCenter"),
      value:
        localeFormat(
          state.recuperations.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id === CASH_PAYMENT_METHOD_ID
                ? item.baseWithRoyalty
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalCentral"),
      value:
        localeFormat(
          state.recuperations.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id !== CASH_PAYMENT_METHOD_ID
                ? item.baseWithRoyalty
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("total"),
      value: localeFormat(state.summary.baseWithRoyalty.recuperations) + "€",
    },
  ];

  //Billing errors summary
  const BILLING_ERRORS_BASE_SUMMARY = [
    {
      translatedText: t("baseCenter"),
      value:
        localeFormat(
          state.billingErrors.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id === CASH_PAYMENT_METHOD_ID
                ? item.base
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("baseCentral"),
      value:
        localeFormat(
          state.billingErrors.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id !== CASH_PAYMENT_METHOD_ID
                ? item.base
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("base"),
      value: localeFormat(state.summary.base.billingErrors) + "€",
    },
  ];

  const BILLING_ERRORS_TOTAL_SUMMARY = [
    {
      translatedText: t("totalCenter"),
      value:
        localeFormat(
          state.billingErrors.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id === CASH_PAYMENT_METHOD_ID
                ? item.baseWithRoyalty
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalCentral"),
      value:
        localeFormat(
          state.billingErrors.reduce(
            (acc, item) =>
              acc +
              (item.Invoice?.PaymentMethod?.id !== CASH_PAYMENT_METHOD_ID
                ? item.baseWithRoyalty
                : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("total"),
      value: localeFormat(state.summary.baseWithRoyalty.billingErrors) + "€",
    },
  ];

  // Initial useEffect
  useEffect(() => {
    document.title = t("createSettlementPage");
  }, []);

  // Summary amounts useEffect: nonPayments and recuperations info is only shown if settlementNonPayments is false
  useEffect(() => {
    const summary = {
      base: {
        invoiceItems: state.invoiceItems.reduce(
          (acc, item) => acc + item.base,
          0
        ),
        nonPayments: !state.center.settlementNonPayments
          ? state.nonPayments.reduce((acc, item) => acc + -item.base, 0)
          : 0,
        recuperations: !state.center.settlementNonPayments
          ? state.recuperations.reduce((acc, item) => acc + item.base, 0)
          : 0,
        billingErrors: state.billingErrors.reduce(
          (acc, item) => acc + item.base,
          0
        ),
      },
      baseWithRoyalty: {
        invoiceItems: state.invoiceItems.reduce(
          (acc, item) => acc + item.baseWithRoyalty,
          0
        ),
        nonPayments: !state.center.settlementNonPayments
          ? state.nonPayments.reduce(
              (acc, item) => acc + -item.baseWithRoyalty,
              0
            )
          : 0,
        recuperations: !state.center.settlementNonPayments
          ? state.recuperations.reduce(
              (acc, item) => acc + item.baseWithRoyalty,
              0
            )
          : 0,
        billingErrors: state.billingErrors.reduce(
          (acc, item) => acc + item.baseWithRoyalty,
          0
        ),
      },
    };

    dispatch({ type: "SET_SUMMARY", payload: summary });
  }, [
    state.invoiceItems,
    state.nonPayments,
    state.recuperations,
    state.billingErrors,
  ]);

  // Settlement name useEffect
  useEffect(() => {
    if (
      state.filters.centerId !== "" &&
      state.filters.startDate !== "" &&
      state.filters.endDate !== ""
    ) {
      const name = `Liquidación ${state.center?.name} ${getMonth(
        state.filters.endDate
      )} ${new Date(state.filters.endDate).getFullYear()}`;
      dispatch({ type: "SET_NAME", payload: name });
    }
  }, [state.filters]);

  useEffect(() => {
    if (state.settlements.length !== 0) {
      setDefaultDates();
    } else {
      handleFilterChange({
        target: {
          name: "startDate",
          value: "",
        },
      });
      dispatch({ type: "SET_DEFAULT_START_DATE", payload: "" });
    }
  }, [state.settlements]);

  /* API CALLS */

  const createSettlement = () => {
    let data = { name: state.name, ...state.filters };
    if (state.comments !== "") data.comments = state.comments;

    dispatch({ type: "SET_SUBMIT_LOADING", payload: true });

    api
      .post("/settlements/create", data)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("settlementCreated"), { variant: "success" });
          history.goBack();
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_SUBMIT_LOADING", payload: false }));
  };

  const getCenter = (centerId) => {
    api
      .get(`/centers/${centerId}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTER",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoiceItems = () => {
    dispatch({ type: "SET_INVOICE_ITEMS", payload: [] });

    const filters = getFilters();
    const params = { include: ["InvoiceItem"], ...filters };

    dispatch({ type: "SET_INVOICE_ITEMS_LOADING", payload: true });

    api
      .get("/settlements/invoiced-merchantables", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const invoiceItems = response.data.map((item) => ({
            ...item,
            baseWithRoyalty: applyRoyalty(item),
          }));
          dispatch({ type: "SET_INVOICE_ITEMS", payload: invoiceItems });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() =>
        dispatch({ type: "SET_INVOICE_ITEMS_LOADING", payload: false })
      );
  };

  const getNonPayments = () => {
    dispatch({ type: "SET_NON_PAYMENTS", payload: [] });

    const filters = getFilters();
    const params = { include: ["InvoiceItem"], ...filters };

    dispatch({ type: "SET_NON_PAYMENTS_LOADING", payload: true });

    api
      .get("/settlements/non-payments", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          let nonPayments = [];

          // Format data
          response.data.forEach((invoiceItem) => {
            invoiceItem.Invoice.NonPayments.forEach((nonPayment) => {
              let item = JSON.parse(JSON.stringify(invoiceItem));
              item.Invoice.NonPayments = item.Invoice.NonPayments.filter(
                (n) => JSON.stringify(n) === JSON.stringify(nonPayment)
              );

              // Set ids for table
              item.itemId = item.id;
              item.id = Number(
                item.id.toString() + item.Invoice.NonPayments[0].id
              );

              item.baseWithRoyalty = applyRoyalty(item);

              nonPayments.push(item);
            });
          });
          dispatch({ type: "SET_NON_PAYMENTS", payload: nonPayments });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() =>
        dispatch({ type: "SET_NON_PAYMENTS_LOADING", payload: false })
      );
  };

  const getRecuperations = () => {
    dispatch({ type: "SET_RECUPERATIONS", payload: [] });

    let filters = getFilters();
    let params = { include: ["InvoiceItem"], ...filters };

    dispatch({ type: "SET_RECUPERATIONS_LOADING", payload: true });

    api
      .get("/settlements/recuperations", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          let recuperations = [];
          // Format data
          response.data.forEach((invoiceItem) => {
            invoiceItem.Invoice.NonPayments.forEach((nonPayment) => {
              let item = JSON.parse(JSON.stringify(invoiceItem));
              item.Invoice.NonPayments = item.Invoice.NonPayments.filter(
                (n) => JSON.stringify(n) === JSON.stringify(nonPayment)
              );
              // Set ids for table
              item.itemId = item.id;
              item.id = Number(
                item.id.toString() + item.Invoice.NonPayments[0].id
              );

              item.baseWithRoyalty = applyRoyalty(item);
              recuperations.push(item);
            });
          });
          dispatch({ type: "SET_RECUPERATIONS", payload: recuperations });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() =>
        dispatch({ type: "SET_RECUPERATIONS_LOADING", payload: false })
      );
  };

  const getBillingErrors = () => {
    dispatch({ type: "SET_BILLING_ERRORS", payload: [] });

    let filters = getFilters();
    let params = { include: ["InvoiceItem"], ...filters };

    dispatch({ type: "SET_BILLING_ERRORS_LOADING", payload: true });

    api
      .get("/settlements/billing-errors", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          let billingErrors = response.data.map((item) => ({
            ...item,
            baseWithRoyalty: applyRoyalty(item),
          }));
          if (state.center?.settlementNonPayments === false) {
            billingErrors = billingErrors.filter(
              (invoiceItem) =>
                !invoiceItem.Merchantable.InvoiceItems.some(
                  (item) => item.Invoice.NonPayments.length > 0
                )
            );
          }
          dispatch({ type: "SET_BILLING_ERRORS", payload: billingErrors });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() =>
        dispatch({ type: "SET_BILLING_ERRORS_LOADING", payload: false })
      );
  };

  const getSettlements = (centerId) => {
    if (centerId !== "") {
      let params = {};
      params.centerIds = [centerId];

      api
        .get("/settlements", { params })
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            dispatch({ type: "SET_SETTLEMENTS", payload: response.data });
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  /* HANDLERS */

  const applyRoyalty = (invoiceItem) => {
    const royalty =
      (invoiceItem?.Merchantable?.royaltyAmount /
        (invoiceItem?.Merchantable?.pricePerUnit *
          invoiceItem?.Merchantable?.units)) *
      100;
    const baseAmount = invoiceItem?.base;
    const paymentMethod = invoiceItem?.Invoice?.paymentMethodId;

    if (royalty == null || baseAmount == null || paymentMethod == null) {
      enqueueSnackbar(t("royaltyCalculationError"), { variant: "error" });
      return;
    }
    const isNegative = paymentMethod === CASH_PAYMENT_METHOD_ID;

    const total = isNegative
      ? baseAmount * (1 - royalty / 100)
      : baseAmount * (royalty / 100);

    return isNegative ? total * -1 : total;
  };

  const getFilters = () => {
    let filters = {};

    state.filters.startDate !== "" &&
      (filters.startDate = state.filters.startDate);
    state.filters.endDate !== "" && (filters.endDate = state.filters.endDate);
    state.filters.centerId !== null &&
      (filters.centerId = state.filters.centerId);

    return filters;
  };

  const getMonth = (date) => {
    const month = new Date(date).toLocaleString("es-ES", {
      month: "long",
    });
    return month.charAt(0).toUpperCase() + month.slice(1);
  };

  const handleCommentsChange = (e) => {
    dispatch({
      type: "SET_COMMENTS",
      payload: e.target.value,
    });
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputName: e.target.name,
        value: e.target.value,
      },
    });
    if (e.target.name === "centerId") {
      getCenter(e.target.value);
      getSettlements(e.target.value);
    }
  };

  const setDefaultDates = () => {
    if (state.filters.centerId === "") return;
    const centerSettlements = state.settlements;
    if (centerSettlements.length === 0) {
      handleFilterChange({
        target: {
          name: "startDate",
          value: "",
        },
      });
      dispatch({ type: "SET_DEFAULT_START_DATE", payload: "" });
      return;
    }
    const endDates = centerSettlements.map((settlement) => {
      return new Date(settlement.endDate);
    });
    let defaultStartDate = new Date(Math.max(...endDates));

    defaultStartDate.setDate(defaultStartDate.getDate() + 1);

    handleFilterChange({
      target: {
        name: "startDate",
        value: defaultStartDate.toISOString().split("T")[0],
      },
    });

    dispatch({
      type: "SET_DEFAULT_START_DATE",
      payload: defaultStartDate.toISOString().split("T")[0],
    });
  };

  return (
    <Container sx={{ marginY: 3 }} maxWidth={false}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createSettlement")}</Typography>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Collapse in={state.filters.startDate !== state.defaultStartDate}>
                <Alert severity="warning" sx={{ overflowWrap: "anywhere" }}>
                  {t("startDateIsNotConsecutiveToLastEndDate")}
                </Alert>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Collapse
                in={
                  new Date(state.filters.startDate) >
                  new Date(state.filters.endDate)
                }
              >
                <Alert severity="error" sx={{ overflowWrap: "anywhere" }}>
                  {t("startDateIsLargerThanEndDate")}
                </Alert>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Collapse
                in={
                  state.filters.startDate ===
                  new Date().toISOString().split("T")[0]
                }
              >
                <Alert severity="warning" sx={{ overflowWrap: "anywhere" }}>
                  {t("startDateIsToday")}
                </Alert>
              </Collapse>
            </Grid>
            <Grid item xs={12} sm="auto">
              <CenterSelect
                name="centerId"
                value={state.filters.centerId}
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <CustomDate
                name="startDate"
                label={t("from")}
                value={state.filters.startDate}
                onChange={handleFilterChange}
                sx={{ minWidth: 200 }}
                inputProps={{
                  min: state.defaultStartDate,
                  max: YESTERDAY,
                }}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <CustomDate
                name="endDate"
                label={t("to")}
                value={state.filters.endDate}
                onChange={handleFilterChange}
                sx={{ minWidth: 200 }}
                inputProps={{
                  min: state.filters.startDate,
                  max: YESTERDAY,
                }}
              />
            </Grid>
            <Grid item>
              <SearchButton
                onClick={() => {
                  getInvoiceItems();
                  getNonPayments();
                  getRecuperations();
                  getBillingErrors();
                }}
                loading={
                  state.invoiceItemsLoading ||
                  state.nonPaymentsLoading ||
                  state.recuperationsLoading
                }
                disabled={
                  state.filters.centerId == "" ||
                  state.filters.endDate == "" ||
                  state.filters.startDate == ""
                }
              />
            </Grid>
          </Grid>
          {state.filters.centerId !== "" &&
            state.filters.startDate !== "" &&
            state.filters.endDate !== "" && (
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight="fontWeightMedium">
                  {`${t("settlementName")}:`}
                  <Typography
                    variant="body1"
                    fontWeight="fontWeightRegular"
                    marginLeft={1}
                    component="span"
                  >
                    {state.name}
                  </Typography>
                </Typography>
              </Grid>
            )}
          {/* Invoice items */}
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6">{t("invoiced")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box height={400} width="100%">
                <DataGrid
                  loading={state.invoiceItemsLoading}
                  columns={INVOICE_ITEMS_COLUMNS}
                  rows={state.invoiceItems}
                  rowsPerPageOptions={[]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={INVOICE_ITEMS_BASE_SUMMARY} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={INVOICE_ITEMS_TOTAL_SUMMARY} />
            </Grid>
          </Grid>

          {/* Non payments */}
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6">{t("nonPayments")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box height={400} width="100%">
                <DataGrid
                  loading={state.nonPaymentsLoading}
                  columns={NON_PAYMENTS_COLUMNS}
                  rows={state.nonPayments}
                  rowsPerPageOptions={[]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={NON_PAYMENTS_BASE_SUMMARY} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={NON_PAYMENTS_TOTAL_SUMMARY} />
            </Grid>
          </Grid>

          {/* Recuperations */}
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6">{t("recuperations")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box height={400} width="100%">
                <DataGrid
                  loading={state.recuperationsLoading}
                  columns={RECUPERATIONS_COLUMNS}
                  rows={state.recuperations}
                  rowsPerPageOptions={[]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={RECUPERATIONS_BASE_SUMMARY} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={RECUPERATIONS_TOTAL_SUMMARY} />
            </Grid>
          </Grid>

          {/* Rectifying invoices (billing errors) */}
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6">{t("rectifyingInvoices")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box height={400} width="100%">
                <DataGrid
                  loading={state.billingErrorsLoading}
                  columns={BILLING_ERRORS_COLUMNS}
                  rows={state.billingErrors}
                  rowsPerPageOptions={[]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={BILLING_ERRORS_BASE_SUMMARY} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={BILLING_ERRORS_TOTAL_SUMMARY} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextInput
              label={t("comments")}
              multiline
              rows={3}
              value={state.comments}
              onChange={handleCommentsChange}
              name="comments"
            />
          </Grid>

          <Grid
            item
            container
            xs={12}
            spacing={1}
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <Paper sx={{ padding: 2 }}>
                <Grid container item alignItems="center" spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight="fontWeightMedium">
                      {t("baseSummary")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("invoiced")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        color="black"
                      >
                        {localeFormat(state.summary.base.invoiceItems)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("nonPayments")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        color="black"
                      >
                        {localeFormat(state.summary.base.nonPayments)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("recuperations")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        color="black"
                      >
                        {localeFormat(state.summary.base.recuperations)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("rectifyingInvoices")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        color="black"
                      >
                        {localeFormat(state.summary.base.billingErrors)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      fontWeight="fontWeightMedium"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("baseAmount")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                      >
                        {localeFormat(
                          state.summary.base.invoiceItems +
                            state.summary.base.nonPayments +
                            state.summary.base.recuperations +
                            state.summary.base.billingErrors
                        )}
                        €
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={4}>
              <Paper sx={{ padding: 2 }}>
                <Grid container item alignItems="center" spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight="fontWeightMedium">
                      {t("totalSummary")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("invoiced")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                        color={
                          state.summary.baseWithRoyalty.invoiceItems < 0
                            ? "red"
                            : state.summary.baseWithRoyalty.invoiceItems > 0
                            ? "green"
                            : "black"
                        }
                      >
                        {localeFormat(
                          state.summary.baseWithRoyalty.invoiceItems
                        )}
                        €
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("nonPayments")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                        color={
                          state.summary.baseWithRoyalty.nonPayments < 0
                            ? "red"
                            : state.summary.baseWithRoyalty.nonPayments > 0
                            ? "green"
                            : "black"
                        }
                      >
                        {localeFormat(
                          state.summary.baseWithRoyalty.nonPayments
                        )}
                        €
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("recuperations")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                        color={
                          state.summary.baseWithRoyalty.recuperations < 0
                            ? "red"
                            : state.summary.baseWithRoyalty.recuperations > 0
                            ? "green"
                            : "black"
                        }
                      >
                        {localeFormat(
                          state.summary.baseWithRoyalty.recuperations
                        )}
                        €
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("rectifyingInvoices")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                        color={
                          state.summary.baseWithRoyalty.billingErrors < 0
                            ? "red"
                            : state.summary.baseWithRoyalty.billingErrors > 0
                            ? "green"
                            : "black"
                        }
                      >
                        {localeFormat(
                          state.summary.baseWithRoyalty.billingErrors
                        )}
                        €
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      fontWeight="fontWeightMedium"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("totalAmount")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                        color={
                          state.summary.baseWithRoyalty.invoiceItems +
                            state.summary.baseWithRoyalty.nonPayments +
                            state.summary.baseWithRoyalty.recuperations +
                            state.summary.baseWithRoyalty.billingErrors <
                          0
                            ? "red"
                            : state.summary.baseWithRoyalty.invoiceItems +
                                state.summary.baseWithRoyalty.nonPayments +
                                state.summary.baseWithRoyalty.recuperations +
                                state.summary.baseWithRoyalty.billingErrors >
                              0
                            ? "green"
                            : "black"
                        }
                      >
                        {localeFormat(
                          state.summary.baseWithRoyalty.invoiceItems +
                            state.summary.baseWithRoyalty.nonPayments +
                            state.summary.baseWithRoyalty.recuperations +
                            state.summary.baseWithRoyalty.billingErrors
                        )}
                        €
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container item spacing={1} xs={12} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            <Grid item>
              <CustomButton
                loading={state.submitLoading}
                color="success"
                onClick={createSettlement}
                disabled={
                  state.filters.centerId === "" ||
                  state.filters.startDate === "" ||
                  state.filters.endDate === ""
                }
              >
                {t("create")}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default CreateSettlementPage;
