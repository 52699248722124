import { Grid, Checkbox } from "@mui/material";
import AppContext from "../../../context/AppContext";
import { useEffect, useContext, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { localeFormat } from "../../../utils/format";
import { LIQUIDATION_EXPENSE_TYPE_ID } from "../../../data/constants";
import Dialog from "../../Inputs/Dialog";
import { CustomTable } from "../../CustomTable";

const AssignInvoiceDialog = (props) => {
  const { open = false, onClose, settlement } = props;
  const { api } = useContext(AppContext);
  const [selected, setSelected] = useState(
    settlement.providerInvoiceId ? [settlement.providerInvoiceId] : []
  );
  const [providerInvoices, setProviderInvoices] = useState([]);
  const [t] = useTranslation("settlements");

  useEffect(() => {
    if (settlement) {
      getCenterProviderInvoices();
      if (settlement.providerInvoiceId)
        setSelected([settlement.providerInvoiceId]);
    }
  }, [settlement]);

  const PROVIDER_INVOICE_ITEMS = [
    {
      key: "id",
      label: t("selected"),
      renderFunction: (value) => {
        return (
          <Checkbox
            checked={selected.includes(value)}
            disabled={selected.length > 0 && selected[0] !== value}
            onChange={(e) => {
              if (e.target.checked) {
                setSelected([value]);
              } else {
                setSelected([]);
              }
            }}
          />
        );
      },
    },
    {
      key: "title",
      label: t("title"),
      flex: 2,
    },
    {
      key: "invoiceNumber",
      label: t("invoiceNumber"),
    },
    {
      key: "date",
      label: t("date"),
    },
    {
      key: "dueDate",
      label: t("dueDate"),
    },
    {
      key: "providerId",
      label: t("provider"),
      renderFunction: (value, item) => item.Provider?.brand,
    },
    {
      key: "amount",
      label: t("base"),
      renderFunction: (value) => localeFormat(value) + "€",
    },
  ];

  // Backend calls
  const getCenterProviderInvoices = () => {
    let params = {
      include: ["Center", "Provider", "Settlement"],
      centerId: [settlement?.centerId],
      expenseType: [LIQUIDATION_EXPENSE_TYPE_ID],
    };

    api
      .get("/provider-invoices", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          response.data.forEach((invoice) => {
            if (invoice.id === settlement.providerInvoiceId)
              invoice.selected = true;
            else invoice.selected = false;
          });

          const filteredInvoices = response.data.filter(
            (invoice) =>
              (invoice.selected == false && invoice.Settlement === null) ||
              invoice.selected == true
          );
          setProviderInvoices(filteredInvoices);
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <Dialog
      open={open}
      maxWidth={false}
      onClose={() => {
        onClose && onClose(selected[0]);
        setSelected([]);
      }}
      title={t("providerInvoices")}
    >
      <Grid container maxHeight="60vh" minWidth="60vw">
        <CustomTable
          columns={PROVIDER_INVOICE_ITEMS}
          data={providerInvoices}
          linesPerPage={100}
        />
      </Grid>
    </Dialog>
  );
};

export default AssignInvoiceDialog;
