import {
  AppBar,
  Box,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  DialogTitle,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import AppContext from "../../../context/AppContext";
import TextInput from "../../Inputs/TextInput";
import { CustomTable } from "../../CustomTable";
import CustomButton from "../../Inputs/CustomButton";
import SearchButton from "../../Inputs/SearchButton";
import { getParams, generateURL } from "../../../utils/url";
import Page from "../../global/structure/Page";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_ROLES":
      return { ...state, roles: action.payload };
    case "SET_PAGES":
      return { ...state, pages: action.payload };
    case "SET_ACTIONS":
      return { ...state, actions: action.payload };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "ADD_ITEM":
      return {
        ...state,
        roles: [...state.roles, action.payload],
        form: initialState.form,
      };
    case "DELETE_ITEM":
      return {
        ...state,
        roles: [...state.roles.filter((item) => item !== action.payload)],
      };
    case "ADD_PAGE_ROLE":
      return { ...state, roles: action.payload };
    case "REMOVE_PAGE_ROLE":
      return { ...state, roles: action.payload };
    case "ADD_ACTION_ROLE":
      return { ...state, roles: action.payload };
    case "REMOVE_ACTION_ROLE":
      return { ...state, roles: action.payload };
    case "CHANGE_FILTERS_ACTION":
      return {
        ...state,
        filters: { ...state.filters, action: action.payload },
      };
    case "OPEN_DUPE_MODAL":
      return { ...state, dupeModalIsOpen: true };
    case "CLOSE_DUPE_MODAL":
      return { ...state, dupeModalIsOpen: false };
    case "SET_DUPE_ID":
      return { ...state, dupeId: action.payload };
    case "SET_CREATE_ROL_DIALOG":
      return { ...state, createRolDialog: !state.createRolDialog };
    case "RESET_FORM":
      return { ...state, form: initialState.form };
    case "SET_LOADED":
      return {
        ...state,
        options: { ...state.options, loaded: action.payload },
      };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_USERS":
      return { ...state, users: action.payload };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      component={Paper}
      square
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialState = {
  form: {
    name: "",
    description: "",
    color: "#FFFFFF",
  },
  inputError: {
    name: false,
    description: false,
  },
  actions: [],
  pages: [],
  users: [],
  roles: [],
  filters: {
    autoSearch: false,
    action: "",
    role: "",
  },
  dupeId: "",
  dupeModalIsOpen: false,
  createRolDialog: false,
  options: {
    loaded: true,
  },
};

export default function RolesPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();
  const history = useHistory();
  const [t] = useTranslation("roles");

  const FILTERS = [
    { name: "role", type: "string" },
    { name: "autoSearch", type: "boolean" },
  ];

  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const ROLE_COLUMNS = [
    { label: t("name"), key: "name", sortType: "string" },
    { label: t("description"), key: "description", sortType: "string" },
    {
      label: t("userNumbers"),
      key: "Users",
      sortType: "other",
      renderFunction: (value) => (value?.length ? value.length : 0),
    },
    {
      label: t("actions"),
      key: "id",
      sortType: "other",
      renderFunction: (value) => (
        <Tooltip title={t("duplicate")}>
          <IconButton
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              openDupeModal(value);
            }}
          >
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  //Add new role
  const addItem = (id) => {
    if (state.form.name && state.form.description) {
      dispatch({
        type: "ADD_ITEM",
        payload: {
          name: state.form.name,
          description: state.form.description,
          id: id,
        },
      });
    }
  };

  //Tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  //Initial useEffect
  useEffect(() => {
    getPages();
    getActions();
  }, []);

  useEffect(() => {
    if (state.filters.autoSearch) getRoles();
  }, [state.filters.autoSearch]);

  const getRoles = () => {
    dispatch({ type: "SET_LOADED", payload: false });

    //Change url parameters
    const url = generateURL("/app/roles", state.filters);
    history.push(url);

    let params = {
      include: ["Action", "Page", "User"],
    };

    state.filters.role != "" && (params.name = state.filters.role);

    api
      .get("/roles", { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED", payload: true });
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, { variant: "error" });
        } else {
          if (response.data.length > 0) {
            dispatch({
              type: "SET_ROLES",
              payload: response.data,
            });
          } else {
            enqueueSnackbar(t("noRoles"), {
              variant: "error",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getPages = () => {
    api
      .get("/pages")
      .then((response) => {
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, { variant: "error" });
        } else {
          if (response.data.length > 0) {
            dispatch({
              type: "SET_PAGES",
              payload: response.data,
            });
          } else {
            enqueueSnackbar(t("noPages"), { variant: "error" });
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const getActions = () => {
    api
      .get("/actions")
      .then((response) => {
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, { variant: "error" });
        } else {
          if (response.data.length > 0) {
            dispatch({
              type: "SET_ACTIONS",
              payload: response.data,
            });
          } else {
            enqueueSnackbar(t("noActions"), { variant: "error" });
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const getUsers = () => {
    api
      .get("/users")
      .then((response) => {
        response.data.error &&
          enqueueSnackbar(response.data.error, {
            variant: "error",
          });
        if (response.data.length > 0) {
          dispatch({
            type: "SET_USERS",
            payload: response.data,
          });
        } else {
          enqueueSnackbar(t("noUsers"), {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitForm = () => {
    if (validateForm()) {
      let data = {};
      state.form.name !== "" && (data.name = state.form.name);
      state.form.description !== "" &&
        (data.description = state.form.description);
      state.form.color !== "" && (data.color = state.form.color);

      api
        .post("/roles/create", data)
        .then((response) => {
          if (response.data.error) {
            const msg = response.data.error.errors
              ? response.data.error.errors[0].message
              : response.data.error;
            enqueueSnackbar(msg, { variant: "error" });
          } else {
            enqueueSnackbar(t("roleCreateSuccess"), { variant: "success" });
            addItem(response.data.id);
            handleCreateRolDialog();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
          console.log(error);
        });
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!state.form.name) {
      setInputErrorTrue("name");
      isValid = false;
    }

    if (!state.form.description) {
      setInputErrorTrue("description");
      isValid = false;
    }

    return isValid;
  };

  const handleChangeCheck = (value, role, page) => {
    let data = {
      id: page.id,
    };
    if (value === true) {
      api
        .post("/roles/" + role.id + "/page", data)
        .then((response) => {
          if (response.data.error) {
            const msg = response.data.error.errors
              ? response.data.error.errors[0].message
              : response.data.error;
            enqueueSnackbar(msg, { variant: "error" });
          } else {
            let roles = state.roles;
            roles.forEach((item, i) => {
              if (item.id === role.id) {
                item.Pages.push(page);
              }
            });
            dispatch({
              type: "ADD_PAGE_ROLE",
              payload: roles,
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
          console.log(error);
        });
    } else {
      api
        .delete("/roles/" + role.id + "/page", {
          data,
        })
        .then((response) => {
          if (response.data.error) {
            const msg = response.data.error.errors
              ? response.data.error.errors[0].message
              : response.data.error;
            enqueueSnackbar(msg, { variant: "error" });
          } else {
            let roles = state.roles;
            roles.forEach((item) => {
              if (item.id === role.id) {
                item.Pages = item.Pages.filter(
                  (arrPage) => arrPage.id !== page.id
                );
              }
            });

            dispatch({
              type: "REMOVE_PAGE_ROLE",
              payload: roles,
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
          console.log(error);
        });
    }
  };

  const roleHasPage = (role, pageId) => {
    let hasPage = false;
    role.Pages?.forEach((page) => {
      if (page.id === pageId) hasPage = true;
    });

    return hasPage;
  };

  const roleHasAction = (role, actionId) => {
    let hasAction = false;
    role.Actions?.forEach((action) => {
      if (action.id === actionId) hasAction = true;
    });
    return hasAction;
  };

  const handleChangeCheckAction = (value, role, action) => {
    let data = {
      id: action.id,
    };
    if (value === true) {
      api
        .post("/roles/" + role.id + "/action", data)
        .then((response) => {
          if (response.data.error) {
            const msg = response.data.error.errors
              ? response.data.error.errors[0].message
              : response.data.error;
            enqueueSnackbar(msg, { variant: "error" });
          } else {
            let roles = state.roles;
            roles.forEach((item, i) => {
              if (item.id === role.id) {
                item.Actions.push(action);
              }
            });
            dispatch({
              type: "ADD_ACTION_ROLE",
              payload: roles,
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
          console.log(error);
        });
    } else {
      api
        .delete("/roles/" + role.id + "/action", { data })
        .then((response) => {
          if (response.data.error) {
            const msg = response.data.error.errors
              ? response.data.error.errors[0].message
              : response.data.error;
            enqueueSnackbar(msg, { variant: "error" });
          } else {
            let roles = state.roles;

            roles.forEach((item) => {
              if (item.id === role.id) {
                item.Actions = item.Actions.filter(
                  (item) => item.id !== action.id
                );
                dispatch({
                  type: "REMOVE_ACTION_ROLE",
                  payload: roles,
                });
              }
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
          console.log(error);
        });
    }
  };

  const duplicate = () => {
    api
      .post("/roles/duplicate/" + state.dupeId, {
        name: state.form.name,
        description: state.form.description,
      })
      .then((response) => {
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, { variant: "error" });
        } else {
          enqueueSnackbar(t("roleDuplicatedSuccess"), {
            variant: "success",
          });
          addItem(response.data.id);
          handleCloseDupeModal();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const hasAction = (action) => {
    let isFound = false;
    user?.Role.Actions.forEach((item) => {
      if (item.id === action) {
        isFound = true;
      }
    });

    return isFound;
  };

  const handleFilterAction = (e) => {
    dispatch({
      type: "CHANGE_FILTERS_ACTION",
      payload: e.target.value,
    });
  };

  const handleCloseDupeModal = () => {
    dispatch({ type: "CLOSE_DUPE_MODAL" });
    dispatch({ type: "RESET_FORM" });
  };

  const openDupeModal = (id) => {
    dispatch({ type: "OPEN_DUPE_MODAL" });
    dispatch({ type: "SET_DUPE_ID", payload: id });
  };

  const handleCreateRolDialog = () => {
    dispatch({ type: "SET_CREATE_ROL_DIALOG" });
    dispatch({ type: "RESET_FORM" });
  };

  const search = () => {
    if (state.filters.autoSearch === true) getRoles();
    else
      dispatch({
        type: "SET_FILTER",
        payload: {
          inputname: "autoSearch",
          value: true,
        },
      });
  };

  const changeInputFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  return (
    <Page browserTitle={t("rolesPage")}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="secondary"
        >
          <Tab label={t("roles")} {...a11yProps(0)} />
          <Tab label={t("pages")} {...a11yProps(1)} />
          <Tab label={t("actions")} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {/* Roles page */}
      <TabPanel value={value} index={0}>
        <Container maxWidth={false}>
          <Grid item container spacing={2}>
            <Grid item>
              <TextInput
                label={t("name")}
                name="role"
                value={state.filters.role}
                onChange={(e) => {
                  changeInputFilter(e);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
              />
            </Grid>

            <Grid item>
              <SearchButton onClick={search} loading={!state.options.loaded} />
            </Grid>

            {hasAction("CREATE_ROLES") && (
              <Grid item>
                <CustomButton
                  onClick={handleCreateRolDialog}
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                >
                  {t("createRol")}
                </CustomButton>
              </Grid>
            )}

            <Grid item xs={12}>
              <CustomTable
                data={state.roles}
                columns={ROLE_COLUMNS}
                options={{ rowlink: "role" }}
              ></CustomTable>
            </Grid>
          </Grid>

          {/* CREATE ROL DIALOG */}
          <Dialog open={state.createRolDialog} onClose={handleCreateRolDialog}>
            <DialogTitle>
              <Grid container justifyContent="space-between">
                <Grid item>{t("createRol")}</Grid>
                <Grid item>
                  <IconButton onClick={handleCreateRolDialog}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <TextInput
                    name="name"
                    label={t("name")}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    value={state.form.name}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl
                    variant="outlined"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {/* <label for="color">Color </label> */}
                    <input
                      type="color"
                      id="color"
                      name="color"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      value={state.form.color}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("description")}
                    multiline
                    rows={4}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    value={state.form.description}
                    name="description"
                    error={state.inputError.description}
                    helperText={
                      state.inputError.description
                        ? t("descriptionNotBlankHelper")
                        : ""
                    }
                  />
                </Grid>
                <Grid item container justifyContent="flex-end">
                  <Grid item>
                    <CustomButton
                      onClick={submitForm}
                      disabled={state.form.name === ""}
                    >
                      {t("add")}
                    </CustomButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

          {/* DUPLICATE ROL MODAL*/}
          <Dialog open={state.dupeModalIsOpen} onClose={handleCloseDupeModal}>
            <DialogTitle>
              <Grid container justifyContent="space-between">
                <Grid item>{t("duplicateRole")}</Grid>
                <Grid item>
                  <IconButton onClick={handleCloseDupeModal}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid item container spacing={2}>
                <Grid item xs={10}>
                  <TextInput
                    label={t("name")}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    value={state.form.name}
                    name="name"
                    error={state.inputError.name}
                    helperText={
                      state.inputError.name ? t("nameNotBlankHelper") : ""
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl variant="outlined">
                    <input
                      type="color"
                      id="color"
                      name="color"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      value={state.form.color}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={t("description")}
                    multiline
                    rows={4}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    value={state.form.description}
                    name="description"
                    error={state.inputError.description}
                    helperText={
                      state.inputError.description
                        ? t("descriptionNotBlankHelper")
                        : ""
                    }
                  />
                </Grid>
                <Grid item container justifyContent="flex-end">
                  <Grid item>
                    <CustomButton
                      onClick={() => {
                        duplicate();
                      }}
                      disabled={state.form.name === ""}
                    >
                      {t("add")}
                    </CustomButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Container>
      </TabPanel>
      {/* Pages page */}
      <TabPanel value={value} index={1}>
        <Container maxWidth={false}>
          <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      position: "sticky",
                      left: 0,
                      zIndex: 4,
                      backgroundColor: "white",
                    }}
                  ></TableCell>
                  {state.roles?.map((role) => (
                    <TableCell
                      align="left"
                      sx={{
                        position: "sticky",
                        left: 0,
                        zIndex: 3,
                        backgroundColor: "white",
                      }}
                    >
                      {role.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {state.pages?.map((page, i) => (
                  <TableRow key={page.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        position: "sticky",
                        left: 0,
                        zIndex: 2,
                        backgroundColor: "white",
                      }}
                    >
                      {page.name}
                    </TableCell>
                    {state.roles.map((role, j) => (
                      <TableCell align="left" key={role.id + page.id}>
                        {hasAction("EDIT_PAGES") ? (
                          <Checkbox
                            id={"check" + i + j}
                            name={"check" + role.id + page.id}
                            checked={roleHasPage(role, page.id)}
                            onChange={(e) => {
                              handleChangeCheck(e.target.checked, role, page);
                            }}
                            inputProps={{
                              "aria-label": "primary checkbox" + i + j,
                            }}
                            sx={{
                              left: "auto",
                              zIndex: 1,
                              backgroundColor: "white",
                            }}
                          />
                        ) : roleHasPage(role, page.id) ? (
                          <CheckBoxIcon />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </TabPanel>
      {/* Action page */}
      <TabPanel value={value} index={2}>
        <Container maxWidth={false}>
          <TableContainer component={Paper} sx={{ maxHeight: 800 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      position: "sticky",
                      left: 0,
                      zIndex: 3,
                      backgroundColor: "white",
                    }}
                  >
                    <TextInput
                      name="actionFilter"
                      onChange={handleFilterAction}
                      value={state.filters.action}
                      sx={{ minWidth: 200 }}
                    />
                  </TableCell>
                  {state.roles?.map((role) => (
                    <TableCell align="left">{role.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {state.actions?.map((action, i) => (
                  <>
                    {(state.filters.action === "" ||
                      action.id
                        .toUpperCase()
                        .includes(state.filters.action.toUpperCase())) && (
                      <TableRow key={action.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            position: "sticky",
                            left: 0,
                            zIndex: 2,
                            backgroundColor: "white",
                          }}
                        >
                          {action.id}
                        </TableCell>
                        {state.roles.map((role, j) => (
                          <TableCell
                            align="left"
                            key={role.id + action.id}
                            sx={{
                              left: "auto",
                              zIndex: 1,
                              backgroundColor: "white",
                            }}
                          >
                            {hasAction("EDIT_ACTIONS") ? (
                              <Checkbox
                                id={"check" + i + j}
                                name={"check" + role.id + action.id}
                                checked={roleHasAction(role, action.id)}
                                onChange={(e) => {
                                  handleChangeCheckAction(
                                    e.target.checked,
                                    role,
                                    action
                                  );
                                }}
                                inputProps={{
                                  "aria-label": "primary checkbox" + i + j,
                                }}
                              />
                            ) : roleHasAction(role, action.id) ? (
                              <CheckBoxIcon />
                            ) : (
                              <CheckBoxOutlineBlankIcon />
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </TabPanel>
    </Page>
  );
}
