import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

// Material UI
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

// Icons
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import GetAppIcon from "@mui/icons-material/GetApp";
import HelpIcon from "@mui/icons-material/Help";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

// Components & Utils
import AppContext from "../../../context/AppContext";
import { useReducer } from "react";
import { useSnackbar } from "notistack";
import { DataGrid } from "@mui/x-data-grid";
import { localeFormat } from "../../../utils/format";
import ItemsSummary from "../../ItemsSummary";
import TextInput from "../../Inputs/TextInput";
import CustomButton from "../../Inputs/CustomButton";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import i18next from "../../../utils/i18n";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LIQUIDATION_EXPENSE_TYPE_ID } from "../../../data/constants";
import Dialog from "../../Inputs/Dialog";
import { CustomTable } from "../../CustomTable";
import ConfirmDialog from "../../ConfirmDialog";

const initialState = {
  centerSettlements: [],
  comments: "",
  downloadPdfLoading: false,
  emailSettlementLoading: false,
  deleteSettlementLoading: false,
  loading: false,
  settlement: {},
  deleteSettlementDialog: false,
  settlementItems: {
    invoiceItems: [],
    nonPayments: [],
    recuperations: [],
    billingErrors: [],
    items: [],
  },
  summary: {
    base: {
      invoiceItems: 0,
      nonPayments: 0,
      recuperations: 0,
      billingErrors: 0,
    },
    total: {
      invoiceItems: 0,
      nonPayments: 0,
      recuperations: 0,
      billingErrors: 0,
    },
  },
  invoiceDialog: {
    isOpen: false,
    providerInvoices: [],
    selectedInvoice: "",
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CENTER_SETTLEMENTS":
      return {
        ...state,
        centerSettlements: action.payload,
      };
    case "SET_COMMENTS":
      return {
        ...state,
        comments: action.payload,
      };
    case "SET_DOWNLOAD_PDF_LOADING":
      return {
        ...state,
        downloadPdfLoading: action.payload,
      };
    case "SET_EMAIL_SETTLEMENT_LOADING":
      return {
        ...state,
        emailSettlementLoading: action.payload,
      };
    case "SET_DELETE_SETTLEMENT_LOADING":
      return {
        ...state,
        deleteSettlementLoading: action.payload,
      };
    case "SET_DELETE_SETTLEMENT_DIALOG":
      return {
        ...state,
        deleteSettlementDialog: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_SETTLEMENT":
      return {
        ...state,
        settlement: action.payload,
        comments: action.payload.comments,
      };
    case "SET_SETTLEMENT_ITEMS":
      return {
        ...state,
        settlementItems: action.payload,
      };
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.payload,
      };
    case "SET_INVOICE_DIALOG_INPUT":
      return {
        ...state,
        invoiceDialog: {
          ...state.invoiceDialog,
          [action.payload.inputName]: action.payload.value,
        },
      };
    case "SET_SETLEMENT_INPUT":
      return {
        ...state,
        settlement: {
          ...state.settlement,
          [action.payload.inputName]: action.payload.value,
        },
      };
    default:
      throw new Error("Action not found in reducer");
  }
}

const SettlementPage = () => {
  const { api, user } = useContext(AppContext);
  const history = useHistory();
  const [t] = useTranslation("settlements");
  const [tErrors] = useTranslation("errors");
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState([]);

  const downloadCSVRefAll = useRef();
  const downloadCSVRefInvoiceItems = useRef();
  const downloadCSVRefNonPayments = useRef();
  const downloadCSVRefRecuperations = useRef();
  const downloadCSVRefBillingErrors = useRef();

  const [state, dispatch] = useReducer(reducer, initialState);

  // Get settlement id from URL
  const { id: settlementId } = useParams();

  // CSV headers
  const CSV_SETTLEMENT_ITEMS_HEADERS = [
    { label: t("concept"), key: "concept" },
    { label: t("customer"), key: "customerName" },
    { label: t("issueDate"), key: "issueDate" },
    { label: t("paymentMethod"), key: "paymentMethod" },
    { label: t("baseAmount"), key: "baseAmount" },
    { label: t("royalty"), key: "royalty" },
    { label: t("totalAmount"), key: "totalAmount" },
    { label: t("type"), key: "settlementType" },
  ];

  // Columns
  const SETTLEMENT_ITEMS_COLUMNS = [
    {
      field: "concept",
      headerName: t("concept"),
      flex: 2,
      minWidth: 75,
    },
    {
      field: "customer",
      headerName: t("customer"),
      flex: 1,
      minWidth: 75,
      valueGetter: ({ row }) => row.InvoiceItem?.Invoice?.customerName,
    },
    {
      field: "issueDate",
      headerName: t("issueDate"),
      width: 100,
      valueGetter: ({ row }) => row.InvoiceItem?.Invoice?.issueDate,
    },
    {
      field: "paymentMethod",
      headerName: t("paymentMethod"),
      width: 130,
      valueGetter: ({ row }) => row.PaymentMethod?.name,
    },
    {
      field: "baseAmount",
      headerName: t("baseAmount"),
      width: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "royalty",
      headerName: t("royalty"),
      width: 80,
      valueFormatter: ({ value }) => localeFormat(value) + "%",
    },
    {
      field: "totalAmount",
      headerName: t("totalAmount"),
      width: 100,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "actions",
      headerName: "",
      width: 20,
      renderCell: (params) =>
        user.hasPage("INVOICES") && (
          <ButtonGroup>
            <Tooltip title={t("edit")} placement="top">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(
                    `/app/invoice/${params.row.InvoiceItem.invoiceId}`
                  );
                }}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        ),
    },
  ];

  // Items summary
  const INVOICE_ITEMS_BASE_SUMMARY = [
    {
      translatedText: t("baseCenter"),
      value:
        localeFormat(
          state.settlementItems.invoiceItems.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id === 1 ? item.baseAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("baseCentral"),
      value:
        localeFormat(
          state.settlementItems.invoiceItems.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id !== 1 ? item.baseAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("base"),
      value: localeFormat(state.summary.base.invoiceItems) + "€",
    },
  ];

  const INVOICE_ITEMS_TOTAL_SUMMARY = [
    {
      translatedText: t("totalCenter"),
      value:
        localeFormat(
          state.settlementItems.invoiceItems.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id === 1 ? item.totalAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalCentral"),
      value:
        localeFormat(
          state.settlementItems.invoiceItems.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id !== 1 ? item.totalAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("total"),
      value: localeFormat(state.summary.total.invoiceItems) + "€",
    },
  ];

  const NON_PAYMENTS_BASE_SUMMARY = [
    {
      translatedText: t("baseCenter"),
      value:
        localeFormat(
          state.settlementItems.nonPayments.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id === 1 ? -item.baseAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("baseCentral"),
      value:
        localeFormat(
          state.settlementItems.nonPayments.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id !== 1 ? -item.baseAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("base"),
      value: localeFormat(state.summary.base.nonPayments) + "€",
    },
  ];

  const NON_PAYMENTS_TOTAL_SUMMARY = [
    {
      translatedText: t("totalCenter"),
      value:
        localeFormat(
          state.settlementItems.nonPayments.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id === 1 ? item.totalAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalCentral"),
      value:
        localeFormat(
          state.settlementItems.nonPayments.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id !== 1 ? item.totalAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("total"),
      value: localeFormat(state.summary.total.nonPayments) + "€",
    },
  ];

  const RECUPERATIONS_BASE_SUMMARY = [
    {
      translatedText: t("baseCenter"),
      value:
        localeFormat(
          state.settlementItems.recuperations.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id === 1 ? -item.baseAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("baseCentral"),
      value:
        localeFormat(
          state.settlementItems.recuperations.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id !== 1 ? -item.baseAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("base"),
      value: localeFormat(state.summary.base.recuperations) + "€",
    },
  ];

  const RECUPERATIONS_TOTAL_SUMMARY = [
    {
      translatedText: t("totalCenter"),
      value:
        localeFormat(
          state.settlementItems.recuperations.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id === 1 ? item.totalAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalCentral"),
      value:
        localeFormat(
          state.settlementItems.recuperations.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id !== 1 ? item.totalAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("total"),
      value: localeFormat(state.summary.total.recuperations) + "€",
    },
  ];

  // Billing errors summary
  const BILLING_ERRORS_BASE_SUMMARY = [
    {
      translatedText: t("baseCenter"),
      value:
        localeFormat(
          state.settlementItems.billingErrors.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id === 1 ? item.baseAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("baseCentral"),
      value:
        localeFormat(
          state.settlementItems.billingErrors.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id !== 1 ? item.baseAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("base"),
      value: localeFormat(state.summary.base.billingErrors) + "€",
    },
  ];

  const BILLING_ERRORS_TOTAL_SUMMARY = [
    {
      translatedText: t("totalCenter"),
      value:
        localeFormat(
          state.settlementItems.billingErrors.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id === 1 ? item.totalAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("totalCentral"),
      value:
        localeFormat(
          state.settlementItems.billingErrors.reduce(
            (acc, item) =>
              acc + (item.PaymentMethod?.id !== 1 ? item.totalAmount : 0),
            0
          )
        ) + "€",
    },
    {
      translatedText: t("total"),
      value: localeFormat(state.summary.total.billingErrors) + "€",
    },
  ];

  const PROVIDER_INVOICE_ITEMS = [
    {
      key: "id",
      label: t("selected"),
      renderFunction: (value) => {
        return (
          <Checkbox
            checked={selected.includes(value)}
            disabled={selected.length > 0 && selected[0] !== value}
            onChange={(e) => {
              if (e.target.checked) {
                setSelected([value]);
              } else {
                setSelected([]);
              }
            }}
          />
        );
      },
    },
    {
      key: "title",
      label: t("title"),
    },
    {
      key: "invoiceNumber",
      label: t("invoiceNumber"),
    },
    {
      key: "date",
      label: t("date"),
    },
    {
      key: "dueDate",
      label: t("dueDate"),
    },
    {
      key: "providerId",
      label: t("provider"),
      renderFunction: (value, item) => item.Provider?.brand,
    },
    {
      key: "amount",
      label: t("base"),
      renderFunction: (value) => localeFormat(value) + "€",
    },
  ];

  // Initial useEffect
  useEffect(() => {
    document.title = t("settlementPage");

    getSettlement();
  }, []);

  useEffect(() => {
    if (state.settlement.centerId) {
      getCenterSettlements(state.settlement.centerId);
      getCenterProviderInvoices();
    }
    if (
      state.settlement.providerInvoiceId !== undefined &&
      state.settlement.providerInvoiceId !== null
    )
      setSelected([state.settlement.providerInvoiceId]);
  }, [state.settlement]);

  /* BACKEND CALLS */
  const editSettlement = (selectedProviderInvoiceId) => {
    let data = {};
    state.comments != null && (data.comments = state.comments);
    selectedProviderInvoiceId &&
      (data.providerInvoiceId = selectedProviderInvoiceId[0] || null);
    !selectedProviderInvoiceId && (data.providerInvoiceId = null);
    api
      .post("/settlements/edit/" + settlementId, data)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("settlementEditedSuccessfully"), {
            variant: "success",
          });
          getSettlement();

          state.comments != null && history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCenterSettlements = (centerId) => {
    let params = {
      centerIds: [centerId],
    };

    api
      .get("/settlements", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTER_SETTLEMENTS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getSettlement = () => {
    dispatch({ type: "SET_LOADING", payload: true });
    let params = {
      include: ["Center"],
    };
    api
      .get("/settlements/" + settlementId, { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          const settlementItems = response.data.SettlementItems.map((item) => ({
            ...item,
            customerName: item.InvoiceItem?.Invoice?.customerName,
            issueDate: item.InvoiceItem?.Invoice?.issueDate,
            paymentMethod: item.PaymentMethod.name,
            settlementType:
              item.type === 1
                ? t("invoice")
                : item.type === 2
                ? t("nonPayment")
                : t("recuperation"),
          }));
          const invoiceItems = settlementItems.filter(
            (item) => item.type === 1
          );
          const nonPayments = settlementItems.filter((item) => item.type === 2);
          const recuperations = settlementItems.filter(
            (item) => item.type === 3
          );
          const billingErrors = settlementItems.filter(
            (item) => item.type === 4
          );
          dispatch({
            type: "SET_SETTLEMENT_ITEMS",
            payload: {
              invoiceItems,
              nonPayments,
              recuperations,
              billingErrors,
              items: settlementItems,
            },
          });
          dispatch({ type: "SET_SETTLEMENT", payload: response.data });
          dispatch({
            type: "SET_INVOICE_DIALOG_INPUT",
            payload: {
              inputName: "selectedInvoice",
              value: response.data.providerInvoiceId,
            },
          });
          setSummary(invoiceItems, nonPayments, recuperations, billingErrors);
        }
        dispatch({ type: "SET_LOADING", payload: false });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCenterProviderInvoices = () => {
    let params = {
      include: ["Center", "Provider", "Settlement"],
      centerId: [state.settlement.centerId],
      expenseType: [LIQUIDATION_EXPENSE_TYPE_ID],
    };

    api
      .get("/provider-invoices", { params })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          response.data.forEach((invoice) => {
            if (invoice.id === state.settlement.providerInvoiceId)
              invoice.selected = true;
            else invoice.selected = false;
          });

          const filteredInvoices = response.data.filter(
            (invoice) =>
              (invoice.selected == false && invoice.Settlement === null) ||
              invoice.selected == true
          );

          dispatch({
            type: "SET_INVOICE_DIALOG_INPUT",
            payload: {
              inputName: "providerInvoices",
              value: filteredInvoices,
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const downloadPdf = (settlementId) => {
    dispatch({ type: "SET_DOWNLOAD_PDF_LOADING", payload: true });
    api
      .get("/settlements/" + settlementId + "/pdf", {
        responseType: "blob",
      })
      .then((response) => {
        downloadFile(response.data);
        dispatch({ type: "SET_DOWNLOAD_PDF_LOADING", payload: false });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
        dispatch({ type: "SET_DOWNLOAD_PDF_LOADING", payload: false });
      });
  };

  const emailSettlement = (settlementId) => {
    dispatch({ type: "SET_EMAIL_SETTLEMENT_LOADING", payload: true });
    api
      .get("/settlements/" + settlementId + "/email")
      .then((response) => {
        dispatch({ type: "SET_EMAIL_SETTLEMENT_LOADING", payload: false });
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            t("emailSentTo") + ": " + response.data.acceptedEmails,
            {
              variant: "success",
            }
          );
          if (response.data.rejectedEmails) {
            enqueueSnackbar(
              t("rejectedEmails") + ": " + response.data.rejectedEmails,
              {
                variant: "warning",
              }
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
        dispatch({ type: "SET_EMAIL_SETTLEMENT_LOADING", payload: false });
      });
  };

  const deleteSettlement = (value) => {
    if (!value) return;
    dispatch({ type: "SET_DELETE_SETTLEMENT_LOADING", payload: true });
    api
      .delete("/settlements/" + settlementId)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(tErrors(res.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("settlementDeletedSuccessfully"), {
            variant: "success",
          });
          history.push(`/app/settlements`);
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() =>
        dispatch({ type: "SET_DELETE_SETTLEMENT_LOADING", payload: false })
      );
  };

  /* HANDLERS */

  const handleCommentsChange = (e) => {
    dispatch({
      type: "SET_COMMENTS",
      payload: e.target.value,
    });
  };

  const downloadFile = (file, fileType = "pdf") => {
    // Create a Blob from the file
    const fileBlob = new Blob([file], {
      type: "application/" + fileType,
    });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(fileBlob);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = state.settlement.name + "." + fileType;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const setSummary = (
    invoiceItems,
    nonPayments,
    recuperations,
    billingErrors
  ) => {
    const summary = {
      base: {
        invoiceItems: invoiceItems.reduce(
          (acc, item) => acc + item.baseAmount,
          0
        ),
        nonPayments: nonPayments.reduce(
          (acc, item) => acc + -item.baseAmount,
          0
        ),
        recuperations: recuperations.reduce(
          (acc, item) => acc + item.baseAmount,
          0
        ),
        billingErrors: billingErrors.reduce(
          (acc, item) => acc + item.baseAmount,
          0
        ),
      },
      total: {
        invoiceItems: invoiceItems.reduce(
          (acc, item) => acc + item.totalAmount,
          0
        ),
        nonPayments: nonPayments.reduce(
          (acc, item) => acc + item.totalAmount,
          0
        ),
        recuperations: recuperations.reduce(
          (acc, item) => acc + item.totalAmount,
          0
        ),
        billingErrors: billingErrors.reduce(
          (acc, item) => acc + item.totalAmount,
          0
        ),
      },
    };

    dispatch({ type: "SET_SUMMARY", payload: summary });
  };

  const getFormattedDateRange = () => {
    if (!state.settlement.startDate || !state.settlement.endDate) return;
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const startDate = new Date(state.settlement.startDate);
    const endDate = new Date(state.settlement.endDate);
    const formatter =
      i18next.language === "es"
        ? new Intl.DateTimeFormat("es-ES", options)
        : new Intl.DateTimeFormat("en-US", options);
    const startDateString = formatter.format(startDate);
    const endDateString = formatter.format(endDate);
    const dateRangeString = `${startDateString} - ${endDateString}`;
    return dateRangeString;
  };

  const handleInvoiceDialogInput = (e) => {
    dispatch({
      type: "SET_INVOICE_DIALOG_INPUT",
      payload: {
        inputName: e.target.name,
        value: e.target.value,
      },
    });
  };

  const selectProviderInvoice = (selectedId) => {
    if (state.settlement.providerInvoiceId !== (selectedId[0] || null))
      editSettlement(selectedId);

    let newInvoices = state.invoiceDialog.providerInvoices;

    newInvoices?.forEach((invoice) => {
      if (selectedId.length === 0) invoice.selected = true;
      else if (invoice.id !== selectedId[0]) invoice.selected = false;
    });

    dispatch({
      type: "SET_INVOICE_DIALOG_INPUT",
      payload: {
        inputName: "providerInvoices",
        value: newInvoices,
      },
    });
    dispatch({
      type: "SET_INVOICE_DIALOG_INPUT",
      payload: {
        inputName: "selectedInvoice",
        value: selectedId,
      },
    });
  };

  const openDeleteDialog = () => {
    dispatch({ type: "SET_DELETE_SETTLEMENT_DIALOG", payload: true });
  };

  const closeDeleteDialog = () => {
    dispatch({ type: "SET_DELETE_SETTLEMENT_DIALOG", payload: false });
  };

  const getLastMonth = () => {
    const lastMonth = new Date().getMonth();
    return lastMonth === 0 ? 12 : lastMonth;
  };

  const isCurrentSettlement = () => {
    const lastMonth = getLastMonth();
    const settlementMonth = new Date(state.settlement.startDate).getMonth() + 1;
    return lastMonth === settlementMonth;
  };

  return (
    <Container maxWidth={false} sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item container xs={12} alignItems="center">
            <Grid item flexGrow={1}>
              <Typography variant="h4">{t("editSettlement")}</Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Tooltip
                title={t("downloadCsvAllSettlementItems")}
                placement="top"
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    downloadCSVRefAll.current.link.click();
                  }}
                >
                  <ArticleIcon />
                  <CSVLink
                    data={
                      state.settlementItems?.items.map((item) => ({
                        ...item,
                        baseAmount: localeFormat(item.baseAmount),
                        totalAmount: localeFormat(item.totalAmount),
                      })) || []
                    }
                    headers={CSV_SETTLEMENT_ITEMS_HEADERS}
                    filename={state.settlement?.name + ".csv"}
                    ref={downloadCSVRefAll}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("downloadPdf")} placement="top">
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    downloadPdf(settlementId);
                  }}
                  disabled={state.downloadPdfLoading}
                >
                  {state.downloadPdfLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <GetAppIcon />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={t("emailSettlement")} placement="top">
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    emailSettlement(settlementId);
                  }}
                  disabled={state.emailSettlementLoading}
                >
                  {state.emailSettlementLoading ? (
                    <CircularProgress size={20} />
                  ) : state.settlement.sentAt ? (
                    <MarkEmailReadIcon color="success" />
                  ) : (
                    <EmailIcon />
                  )}
                </IconButton>
              </Tooltip>
              {user.hasAction("EDIT_SETTLEMENTS") && (
                <Tooltip title={t("providerInvoice")} placement="top">
                  <IconButton
                    color={
                      state.settlement?.providerInvoiceId
                        ? "success"
                        : "primary"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.target = { name: "isOpen", value: true };
                      handleInvoiceDialogInput(e);
                    }}
                  >
                    <Box sx={{ fontSize: 20 }}>
                      <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    </Box>
                  </IconButton>
                </Tooltip>
              )}
              {user.hasAction("DELETE_SETTLEMENTS") &&
                state.settlement.providerInvoiceId === null &&
                isCurrentSettlement() &&
                (!state.deleteSettlementLoading ? (
                  <Tooltip title={t("deleteSettlement")} placement="top">
                    <IconButton color="error" onClick={openDeleteDialog}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <CircularProgress size={20} />
                ))}
              <Tooltip title={t("editSettlementHelp")}>
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="fontWeightMedium">
              {`${t("settlementName")}:`}
              <Typography
                variant="body1"
                fontWeight="fontWeightRegular"
                marginLeft={1}
                component="span"
              >
                {state.settlement.name}
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="fontWeightMedium">
              {`${t("dates")}:`}
              <Typography
                variant="body1"
                fontWeight="fontWeightRegular"
                marginLeft={1}
                component="span"
              >
                {getFormattedDateRange(
                  state.settlement.startDate,
                  state.settlement.endDate
                )}
              </Typography>
            </Typography>
          </Grid>

          {/* Summary */}
          <Grid
            item
            container
            xs={12}
            spacing={1}
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <Paper sx={{ padding: 2 }}>
                <Grid container item alignItems="center" spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight="fontWeightMedium">
                      {t("baseSummary")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("invoiced")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        color="black"
                      >
                        {localeFormat(state.summary.base.invoiceItems)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("nonPayments")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        color="black"
                      >
                        {localeFormat(state.summary.base.nonPayments)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("recuperations")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        color="black"
                      >
                        {localeFormat(state.summary.base.recuperations)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("rectifyingInvoices")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        color="black"
                      >
                        {localeFormat(state.summary.base.billingErrors)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      fontWeight="fontWeightMedium"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("baseAmount")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                      >
                        {localeFormat(
                          state.summary.base.invoiceItems +
                            state.summary.base.nonPayments +
                            state.summary.base.recuperations +
                            state.summary.base.billingErrors
                        )}
                        €
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={4}>
              <Paper sx={{ padding: 2 }}>
                <Grid container item alignItems="center" spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight="fontWeightMedium">
                      {t("totalSummary")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("invoiced")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                        color={
                          state.summary.total.invoiceItems < 0
                            ? "red"
                            : state.summary.total.invoiceItems > 0
                            ? "green"
                            : "black"
                        }
                      >
                        {localeFormat(state.summary.total.invoiceItems)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("nonPayments")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                        color={
                          state.summary.total.nonPayments < 0
                            ? "red"
                            : state.summary.total.nonPayments > 0
                            ? "green"
                            : "black"
                        }
                      >
                        {localeFormat(state.summary.total.nonPayments)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("recuperations")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                        color={
                          state.summary.total.recuperations < 0
                            ? "red"
                            : state.summary.total.recuperations > 0
                            ? "green"
                            : "black"
                        }
                      >
                        {localeFormat(state.summary.total.recuperations)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("rectifyingInvoices")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                        color={
                          state.summary.total.recuperations < 0
                            ? "red"
                            : state.summary.total.recuperations > 0
                            ? "green"
                            : "black"
                        }
                      >
                        {localeFormat(state.summary.total.billingErrors)}€
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      fontWeight="fontWeightMedium"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {t("totalAmount")}:
                      <Typography
                        marginLeft={1}
                        variant="body1"
                        component="span"
                        fontWeight="fontWeightMedium"
                        color={
                          state.summary.total.invoiceItems +
                            state.summary.total.nonPayments +
                            state.summary.total.recuperations +
                            state.summary.total.billingErrors <
                          0
                            ? "red"
                            : state.summary.total.invoiceItems +
                                state.summary.total.nonPayments +
                                state.summary.total.recuperations +
                                state.summary.total.billingErrors >
                              0
                            ? "green"
                            : "black"
                        }
                      >
                        {localeFormat(
                          state.summary.total.invoiceItems +
                            state.summary.total.nonPayments +
                            state.summary.total.recuperations +
                            state.summary.total.billingErrors
                        )}
                        €
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          {/* Comments */}
          {user.hasAction("EDIT_SETTLEMENTS") ? (
            <Grid item xs={12}>
              <TextInput
                label={t("comments")}
                multiline
                rows={3}
                value={state.comments}
                onChange={handleCommentsChange}
                name="comments"
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                {t("comments") + ": "}
              </Typography>
              <Typography variant="body">{state.comments}</Typography>
            </Grid>
          )}

          {/* Invoice items */}
          <Grid item container spacing={1}>
            <Grid item xs={12} container alignItems="center">
              <Grid item flexGrow={1}>
                <Typography variant="h6">{t("invoiced")}</Typography>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Tooltip title={t("downloadCsvInvoiceItems")} placement="top">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      downloadCSVRefInvoiceItems.current.link.click();
                    }}
                  >
                    <ArticleIcon />
                    <CSVLink
                      data={
                        state.settlementItems?.invoiceItems.map(
                          (invoiceItem) => ({
                            ...invoiceItem,
                            baseAmount: localeFormat(invoiceItem.baseAmount),
                            totalAmount: localeFormat(invoiceItem.totalAmount),
                          })
                        ) || []
                      }
                      headers={CSV_SETTLEMENT_ITEMS_HEADERS}
                      filename={
                        state.settlement?.name +
                        "_" +
                        t("invoiceItems") +
                        ".csv"
                      }
                      ref={downloadCSVRefInvoiceItems}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box height={400} width="100%">
                <DataGrid
                  loading={state.loading}
                  columns={SETTLEMENT_ITEMS_COLUMNS}
                  rows={state.settlementItems.invoiceItems}
                  rowsPerPageOptions={[]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={INVOICE_ITEMS_BASE_SUMMARY} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={INVOICE_ITEMS_TOTAL_SUMMARY} />
            </Grid>
          </Grid>

          {/* Non payments */}
          <Grid item container spacing={1}>
            <Grid item xs={12} container alignItems="center">
              <Grid item flexGrow={1}>
                <Typography variant="h6">{t("nonPayments")}</Typography>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Tooltip title={t("downloadCsvNonPayments")} placement="top">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      downloadCSVRefNonPayments.current.link.click();
                    }}
                  >
                    <ArticleIcon />
                    <CSVLink
                      data={
                        state.settlementItems?.nonPayments.map(
                          (nonPayment) => ({
                            ...nonPayment,
                            baseAmount: localeFormat(nonPayment.baseAmount),
                            totalAmount: localeFormat(nonPayment.totalAmount),
                          })
                        ) || []
                      }
                      headers={CSV_SETTLEMENT_ITEMS_HEADERS}
                      filename={
                        state.settlement?.name + "_" + t("nonPayments") + ".csv"
                      }
                      ref={downloadCSVRefNonPayments}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box height={400} width="100%">
                <DataGrid
                  loading={state.loading}
                  columns={SETTLEMENT_ITEMS_COLUMNS}
                  rows={state.settlementItems.nonPayments}
                  rowsPerPageOptions={[]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={NON_PAYMENTS_BASE_SUMMARY} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={NON_PAYMENTS_TOTAL_SUMMARY} />
            </Grid>
          </Grid>

          {/* Recuperations */}
          <Grid item container spacing={1}>
            <Grid item xs={12} container alignItems="center">
              <Grid item flexGrow={1}>
                <Typography variant="h6">{t("recuperations")}</Typography>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Tooltip title={t("downloadCsvRecuperations")} placement="top">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      downloadCSVRefRecuperations.current.link.click();
                    }}
                  >
                    <ArticleIcon />
                    <CSVLink
                      data={
                        state.settlementItems?.recuperations.map(
                          (recuperation) => ({
                            ...recuperation,
                            baseAmount: localeFormat(recuperation.baseAmount),
                            totalAmount: localeFormat(recuperation.totalAmount),
                          })
                        ) || []
                      }
                      headers={CSV_SETTLEMENT_ITEMS_HEADERS}
                      filename={
                        state.settlement?.name +
                        "_" +
                        t("recuperations") +
                        ".csv"
                      }
                      ref={downloadCSVRefRecuperations}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box height={400} width="100%">
                <DataGrid
                  loading={state.loading}
                  columns={SETTLEMENT_ITEMS_COLUMNS}
                  rows={state.settlementItems.recuperations}
                  rowsPerPageOptions={[]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={RECUPERATIONS_BASE_SUMMARY} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={RECUPERATIONS_TOTAL_SUMMARY} />
            </Grid>
          </Grid>

          {/* Rectifying invoices (billing errors) */}
          <Grid item container spacing={1}>
            <Grid item xs={12} container alignItems="center">
              <Grid item flexGrow={1}>
                <Typography variant="h6">{t("rectifyingInvoices")}</Typography>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Tooltip title={t("downloadCsv")} placement="top">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      downloadCSVRefBillingErrors.current.link.click();
                    }}
                  >
                    <ArticleIcon />
                    <CSVLink
                      data={state.settlementItems?.billingErrors || []}
                      headers={CSV_SETTLEMENT_ITEMS_HEADERS}
                      filename={
                        state.settlement?.name +
                        "_" +
                        t("billing_errors") +
                        ".csv"
                      }
                      ref={downloadCSVRefBillingErrors}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box height={400} width="100%">
                <DataGrid
                  loading={state.loading}
                  columns={SETTLEMENT_ITEMS_COLUMNS}
                  rows={state.settlementItems.billingErrors}
                  rowsPerPageOptions={[]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={BILLING_ERRORS_BASE_SUMMARY} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ItemsSummary gridItems={BILLING_ERRORS_TOTAL_SUMMARY} />
            </Grid>
          </Grid>

          <Grid container item spacing={1} xs={12} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            {user.hasAction("EDIT_SETTLEMENTS") && (
              <Grid item>
                <CustomButton onClick={editSettlement}>
                  {t("edit")}
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        maxWidth={false}
        open={state.invoiceDialog.isOpen}
        onClose={() => {
          selectProviderInvoice(selected);
          handleInvoiceDialogInput({
            target: { name: "isOpen", value: false },
          });
        }}
        title={t("providerInvoices")}
      >
        <Grid container>
          <CustomTable
            loading={state.loading}
            columns={PROVIDER_INVOICE_ITEMS}
            data={state.invoiceDialog?.providerInvoices}
            linesPerPage={100}
          />
        </Grid>
      </Dialog>

      <ConfirmDialog
        open={state.deleteSettlementDialog}
        setOpen={closeDeleteDialog}
        title={t("deleteSettlement")}
        onConfirm={deleteSettlement}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t("deleteSettlementConfirmation")}
            </Typography>
          </Grid>
        </Grid>
      </ConfirmDialog>
    </Container>
  );
};

export default SettlementPage;
