import { Divider, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ItemsSummary from "../../ItemsSummary";
import { localeFormat } from "../../../utils/format";

const BoxesSelectedTotals = ({
  selectedRows = [],
  newPrice,
  newPricePercentage,
}) => {
  const [t] = useTranslation("boxes");

  const currentTotal = Number(
    selectedRows.reduce((sum, row) => sum + row.meters * row.pricePerMeter, 0)
  );

  const newTotal =
    selectedRows.length != 0 &&
    selectedRows.reduce((sum, row) => {
      return newPrice
        ? sum + row.meters * newPrice
        : sum + row.meters * row.pricePerMeter * (1 + newPricePercentage / 100);
    }, 0);
  const percentage = ((newTotal - currentTotal) / currentTotal) * 100;
  const difference =
    localeFormat(newTotal - currentTotal) +
    "€ " +
    (currentTotal ? "(" + localeFormat(percentage) + "%)" : "");

  return (
    <ItemsSummary
      gridItems={[
        {
          translatedText: t("selected"),
          value: selectedRows?.length,
        },
        {
          translatedText: t("currentTotal"),
          value: localeFormat(currentTotal) + "€",
        },
        {
          translatedText: t("newTotal"),
          value: localeFormat(newTotal) + "€",
        },
        {
          translatedText: t("difference"),
          value: difference,
        },
      ]}
    />
  );
};

export default BoxesSelectedTotals;
