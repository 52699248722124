import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

/**
 * Iterates and adds formatted grid element for each key-value pair (item)
 * @param {*} props - Array of key-value pairs
 * @returns - Horizontal grid with each key-value separated by a |
 */

const ItemsSummary = (props) => {
  return (
    <Paper sx={{ padding: 2 }}>
      <Grid container spacing={1} justify-content="space-around">
        {props.gridItems?.map((gridItem, i) => {
          return (
            <React.Fragment key={i}>
              <Grid item display="flex" alignItems="center">
                <Typography variant="body1">
                  {gridItem.translatedText}:
                </Typography>
                {typeof gridItem.value === "string" ? (
                  <Typography
                    marginLeft={1}
                    variant="body1"
                    fontWeight={500}
                    component="span"
                  >
                    {gridItem.value}
                  </Typography>
                ) : (
                  <Box marginLeft={1}>{gridItem.value}</Box>
                )}
              </Grid>
              {props.gridItems.length - 1 !== i && (
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </Paper>
  );
};

ItemsSummary.propTypes = {
  gridItems: PropTypes.array.isRequired,
};

export default ItemsSummary;
