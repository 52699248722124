import {
  Card,
  CardContent,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LabeledText from "../../global/LabeledText";
import CancellationRequestStateChip from "./CancellationRequestStateChip";
import { formatDate } from "../../../utils/chartUtils";
import { FeedbackTwoTone } from "@mui/icons-material";

const CancellationRequestCard = ({ request }) => {
  const [t] = useTranslation("contracts");

  const requestDate = formatDate(new Date(request.createdAt));

  return (
    <Card sx={{ minWidth: 300, flex: 1 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item marginBottom={1} textAlign="right" xs={12}>
            <CancellationRequestStateChip cancellationRequest={request} />
          </Grid>
          <Grid item container xs={12} alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                {`${t("requestedThe")} ${requestDate} ${t("by")} ${
                  request.requestedByUser.name
                }`}
              </Typography>
            </Grid>
            {request.comments && (
              <Grid item>
                <Tooltip title={request.comments} placement="right">
                  <Icon fontSize="small">
                    <FeedbackTwoTone fontSize="small" />
                  </Icon>
                </Tooltip>
              </Grid>
            )}
          </Grid>
          <Grid item container xs={12} alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="body1">
                {request.resolutionDate && request.resolvedByUser
                  ? `${t("resolvedThe", {
                      resolution: request.approved
                        ? t("approved")
                        : t("denied"),
                    })} ${request.resolutionDate} ${t("by")} ${
                      request.resolvedByUser.name
                    }`
                  : t("pendingResolution")}
              </Typography>
            </Grid>
            {request.resolutionComments && (
              <Grid item>
                <Tooltip title={request.resolutionComments} placement="right">
                  <Icon fontSize="small">
                    <FeedbackTwoTone fontSize="small" />
                  </Icon>
                </Tooltip>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <LabeledText
              label={t("type")}
              value={request.CancellationType.name}
              variant="body2"
            />
          </Grid>
          {request.cancellationDate && (
            <Grid item xs={12}>
              <LabeledText
                label={
                  request.approved === true
                    ? t("cancellationDate")
                    : t("requestedCancellationDate")
                }
                value={request.cancellationDate}
                variant="body2"
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CancellationRequestCard;
