import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CustomPhoneInput = (props) => {
  return (
    <PhoneInput
      country={"es"}
      inputStyle={{ width: "100%", height: 40 }}
      {...props}
    />
  );
};

export default CustomPhoneInput;
