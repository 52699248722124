import {
    Collapse,
    Box,
    Grid,
    IconButton,
    Table,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    TableContainer,
    Paper,
    Typography,
} from "@mui/material";
import React, { useEffect, useState, useReducer } from "react";
import { useTranslation } from "react-i18next";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import TrackerStatusChip from "./TrackerStatusChip";

function Row(props) {
    const { row } = props;
    const tracker = [
        {name: "nbo", phases: [
            row.AcquisitionTrackers[0],
            row.AcquisitionTrackers[1]
        ]},
        {name: "comercialDD", phases: [
            row.AcquisitionTrackers[2],
            row.AcquisitionTrackers[3]
        ]},
        {name: "technicalDD", phases: [
            row.AcquisitionTrackers[4],
            row.AcquisitionTrackers[5],
            row.AcquisitionTrackers[6]
        ]},
        {name: "legalDD", phases: [
            row.AcquisitionTrackers[7],
            row.AcquisitionTrackers[8],
        ]},
        {name: "approvals", phases: [
            row.AcquisitionTrackers[9],
        ]},
        {name: "spa", phases: [
            row.AcquisitionTrackers[10],
        ]},
        {name: "notarialReview", phases: [
            row.AcquisitionTrackers[11],
        ]},
        {name: "capitalCall", phases: [
            row.AcquisitionTrackers[12],
            row.AcquisitionTrackers[13],
        ]},
        {name: "closing", phases: [
            row.AcquisitionTrackers[14],
        ]},
    ];

    const [open, setOpen] = React.useState(false);
    const [t] = useTranslation("acquisitions");

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{row?.name}</TableCell>
                <TableCell component="th" scope="row">{row?.priority}</TableCell>
                <TableCell component="th" scope="row">{row?.City?.name}</TableCell>
                <TableCell component="th" scope="row">{row?.address}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {tracker.map((row) => (
                            <Box key={row.name} sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {t(row.name)}
                                </Typography>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t("phase")}</TableCell>
                                            <TableCell>{t("lead")}</TableCell>
                                            <TableCell>{t("advisor")}</TableCell>
                                            <TableCell>{t("status")}</TableCell>
                                            <TableCell>{t("startDate")}</TableCell>
                                            <TableCell>{t("endDate")}</TableCell>
                                            <TableCell>{t("comments")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.phases.map((phase) => (
                                            <TableRow key={phase?.id}>
                                                <TableCell component="th" scope="row">{t(phase?.type)}</TableCell>
                                                <TableCell>{phase?.lead}</TableCell>
                                                <TableCell>{phase?.advisior}</TableCell>
                                                <TableCell><TrackerStatusChip status={phase?.status} /></TableCell>
                                                <TableCell>{phase?.startDate}</TableCell>
                                                <TableCell>{phase?.endDate}</TableCell>
                                                <TableCell>{phase?.comments}</TableCell>
                                            </TableRow>   
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        ))}
                        
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const AcquisitionsMap = (props) => {
    const { assets } = props;
    const [t] = useTranslation("acquisitions");


    return (
    <>
        <Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>{t("name")}</TableCell>
                            <TableCell >{t("priority")}</TableCell>
                            <TableCell >{t("city")}</TableCell>
                            <TableCell >{t("address")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assets.map((row) => (
                            <Row key={row.id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </>
    );
};

export default AcquisitionsMap;
