import { ChartContainer } from "./ChartContainer";
import CustomSelect from "../../../Inputs/CustomSelect";
import { CustomTable } from "../../../CustomTable";
import { Bar, Line } from "react-chartjs-2";
import { formatDate } from "../../../../utils/chartUtils";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { colors } from "../ColorGenerator";

export const CashFlowChart = ({
  transactions,
  startDate,
  endDate,
  loading,
}) => {
  const [t] = useTranslation("dashboard");
  const [period, setPeriod] = useState("month");

  const getDates = (start, end, period) => {
    let startDate = new Date(start);
    let endDate = new Date(end);

    let dates = [];
    let date = startDate;

    while (date <= endDate) {
      dates.push(formatDate(date));

      switch (period) {
        case "day":
          date.setDate(date.getDate() + 1);
          break;
        case "month":
          date.setMonth(date.getMonth() + 1);
          break;
        default:
      }
    }

    return dates;
  };

  const labels = getDates(startDate, endDate, period);
  let incomes = [];
  let expenses = [];
  let differences = [];

  labels.forEach((label) => {
    let labelDate = new Date(label);

    let income = 0;
    let incomeTotal = 0;
    let incomeSum = 0;

    let cost = 0;
    let costTotal = 0;
    let expensesSum = 0;

    transactions.forEach((transaction) => {
      let transactionDate = new Date(transaction.transactionDate);
      if (
        transactionDate.getFullYear() === labelDate.getFullYear() &&
        transactionDate.getMonth() === labelDate.getMonth() &&
        (period !== "day" || transactionDate.getDate() === labelDate.getDate())
      ) {
        if (transaction.import > 0) {
          incomeTotal++;
          incomeSum += transaction.import;
        }
        if (transaction.import < 0) {
          costTotal++;
          expensesSum += transaction.import;
        }
      }
    });

    if (incomeTotal !== 0) income = incomeSum;
    else income = 0;
    incomes.push({ x: label, y: income });

    if (costTotal !== 0) cost = expensesSum;
    else cost = 0;

    // Negative cost to be positive
    expenses.push({ x: label, y: -cost });

    differences.push({ x: label, y: income + cost });
  });

  let chartData = {
    labels: labels,
    datasets: [
      {
        type: "line",
        label: t("difference"),
        data: differences,
        borderColor: "green",
        tension: 0.3,
      },
      {
        label: t("income"),
        data: incomes,
        backgroundColor: colors.secondary,
      },
      {
        label: t("expenses"),
        data: expenses,
        backgroundColor: colors.primary,
      },
    ],
  };

  const options = {
    elements: { point: { radius: 0 } },
    animation: false,
    parsing: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    plugins: {
      decimation: {
        enabled: true,
        samples: 50,
        algorithm: "lttb",
      },
    },
    /*  scales: {
              x: {
                  type: 'time',
                  ticks: {
                      source: 'auto',
                      // Disabled rotation for performance
                      maxRotation: 0,
                      autoSkip: true,
                  }
              }
          } */
  };

  const columns = [
    { key: "transactionDate", label: "Transaction date", sortType: "string" },
    { key: "concept", label: "Concept" },
    { key: "valueDate", label: "Fecha valor", sortType: "string" },
    { key: "import", label: "Import" },
    { key: "comments", label: "Comments" },
    { key: "balance", label: "Balance" },
  ];
  const colData = transactions;

  return (
    <ChartContainer
      title={t("cashFlow")}
      chart={<Bar data={chartData} options={options} />}
      table={<CustomTable columns={columns} data={colData} />}
      loading={loading}
    >
      <CustomSelect
        label="Group by"
        value={period}
        onChange={(e) => {
          setPeriod(e.target.value);
        }}
        options={[
          { value: "day", label: "Day" },
          { value: "month", label: "Month" },
        ]}
      />
    </ChartContainer>
  );
};

export const BalanceChart = ({ transactions }) => {
  const balances = transactions.map((transaction) => transaction.balance);
  const labels = transactions.map((transaction) => transaction.transactionDate);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Saldo",
        data: balances,
        borderColor: "#CCCC00",
        backgroundColor: "#CCCC00",
      },
    ],
  };

  const options = {
    elements: { point: { radius: 0 } },
    animation: false,
    parsing: false,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    plugins: {
      decimation: {
        enabled: true,
        samples: 50,
        algorithm: "lttb",
      },
    },
  };

  return (
    <ChartContainer
      title="Saldo"
      chart={<Line data={chartData} options={options} />}
    ></ChartContainer>
  );
};
