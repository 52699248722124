import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomTable } from "../../../../CustomTable";
import ButtonCSV from "../../../../Inputs/ButtonCSV";
import CustomDate from "../../../../Inputs/CustomDate";
import Filters from "../../../../global/structure/Filters";

import CenterReportContext from "../CenterReportContext";
import { HistoricalExpenses, SupplyExpenses } from "../../charts/CenterReport";
import Select from "../../../../global/inputs/Select";

const initialFilters = {
  expenseType: [],
  dateFrom: "",
  dateUntil: "",
};

function ExpensesTab() {
  const { providerInvoices } = useContext(CenterReportContext);
  const [t] = useTranslation("dashboard");
  const [filters, setFilters] = useState(initialFilters);

  const PROVIDER_INVOICES_COLUMNS = [
    {
      key: "title",
      label: t("title"),
      sortType: "string",
    },
    {
      key: "invoiceNumber",
      label: t("number"),
      sortType: "string",
    },
    {
      key: "date",
      label: t("date"),
      sortType: "string",
    },
    {
      key: "centerAmount",
      label: t("centerAmount"),
      sortType: "number",
    },
    {
      key: "Provider.brand",
      label: t("provider"),
      sortType: "string",
    },
    {
      key: "ExpenseType.name",
      label: t("category"),
      sortType: "string",
    },
  ];

  const CSV_HEADERS = [
    { label: t("title"), key: "title" },
    { label: t("number"), key: "invoiceNumber" },
    { label: t("date"), key: "date" },
    { label: t("centerAmount"), key: "centerAmount" },
    { label: t("provider"), key: "Provider.brand" },
    { label: t("category"), key: "ExpenseType.name" },
  ];

  const getExpenseTypes = () => {
    let expenseTypes = [];
    providerInvoices.forEach((invoice) => {
      if (!invoice.ExpenseType) return;
      if (!expenseTypes.some((type) => type.value === invoice.ExpenseType.id)) {
        expenseTypes.push({
          label: invoice.ExpenseType.name,
          value: invoice.ExpenseType.id,
        });
      }
    });
    expenseTypes = expenseTypes.sort((a, b) => (b.label < a.label ? 1 : -1));
    return expenseTypes;
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const filterByDate = () => {
    return providerInvoices.filter((invoice) => {
      if (filters.dateFrom) {
        if (new Date(invoice.date) < new Date(filters.dateFrom)) return false;
      }
      if (filters.dateUntil) {
        if (new Date(invoice.date) > new Date(filters.dateUntil)) return false;
      }
      return true;
    });
  };

  const filerInvoices = () => {
    return filterByDate().filter((invoice) => {
      if (filters.expenseType.length > 0) {
        if (!invoice.ExpenseType) return false;
        if (!filters.expenseType.includes(invoice.ExpenseType.id)) return false;
      }
      return true;
    });
  };

  const filteredInvoices = filerInvoices();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} marginTop={1} marginBottom={1}>
        <Filters
          filters={[
            <Select
              label={t("expenseTypeasdasd")}
              name="expenseType"
              multiple
              options={getExpenseTypes()}
              value={filters.expenseType}
              onChange={handleFilterChange}
              searchable
            />,
            <CustomDate
              label={t("from")}
              value={filters.dateFrom}
              onChange={handleFilterChange}
              name="dateFrom"
            />,
            <CustomDate
              label={t("until")}
              value={filters.dateUntil}
              onChange={handleFilterChange}
              name="dateUntil"
            />,
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <HistoricalExpenses invoices={filteredInvoices} />
      </Grid>
      <Grid item xs={6}>
        <SupplyExpenses invoices={filterByDate()} />
      </Grid>
      <Grid item container justifyContent="flex-end">
        <ButtonCSV
          data={filteredInvoices}
          headers={CSV_HEADERS}
          filename={t("expensesReport")}
        />
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <CustomTable
          columns={PROVIDER_INVOICES_COLUMNS}
          data={filteredInvoices}
        />
      </Grid>
    </Grid>
  );
}

export default ExpensesTab;
