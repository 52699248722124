import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import ContractStateChip from "./pages/Contracts/ContractStateChip";
import Link from "./Inputs/Link";

const ContractCard = ({ contract }) => {
  const [t] = useTranslation("contracts");

  return (
    <Container
      component={Link}
      to={"/app/contract/" + contract.id}
      sx={{ width: 300 }}
    >
      <Card variant="outlined">
        <CardContent>
          <Grid container item spacing={2}>
            <Grid item>
              <Typography variant="h6">
                {contract.Box?.name} - {contract.Box?.Center?.name}
              </Typography>
            </Grid>
            <Grid item>
              <ContractStateChip state={contract.state} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="overline">
                ID: {contract.publicId}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" fontWeight="bold">
                {t("customer") + ": "}
                <Typography
                  variant="body2"
                  fontWeight="normal"
                  component="span"
                >
                  {contract.Customer?.name}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight="bold">
                {t("startDate") + ": "}
                <Typography
                  variant="body2"
                  fontWeight="normal"
                  component="span"
                >
                  {contract.startDate}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight="bold">
                {t("meters") + ": "}
                <Typography
                  variant="body2"
                  fontWeight="normal"
                  component="span"
                >
                  {contract.meters}m²
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight="bold">
                {t("pricePerMeter") + ": "}
                <Typography
                  variant="body2"
                  fontWeight="normal"
                  component="span"
                >
                  {contract.pricePerMeter}€
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ContractCard;
