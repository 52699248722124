import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const ProviderInvoiceStateChip = (props) => {
  const { isNull, paidAt } = props;
  const [t] = useTranslation("providerInvoices");

  let text, color;
  
  if (isNull && !paidAt) {
    text = t("invoiceReceived");
    color = "primary"
  }
  else if (isNull && paidAt) {
    text = t("paid");
    color = "success"
  }
  else {
    text = t("withoutInvoice");
    color = "default"
  }
  return <Chip color={color} label={text} size="small" {...props} />;
};

export default ProviderInvoiceStateChip;
