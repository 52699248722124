import {
  Button,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useTranslation } from "react-i18next";

import CustomSelect from "../../Inputs/CustomSelect";
import MapMarkForm from "./MapMarkForm";
import TextInput from "../../Inputs/TextInput";

export default function MapGroupForm({
  mapGroup,
  mapMark,
  onChange,
  onChangeMapMark,
  submitForm,
}) {
  const [t] = useTranslation("maps");

  const addAttribute = () => {
    let attribute = { name: "", type: "" };
    onChange({
      target: {
        name: "attributes",
        value: [...mapGroup.attributes, attribute],
      },
    });
  };

  const changeAttribute = (e, i) => {
    mapGroup.attributes[i][e.target.name] = e.target.value;
    onChange({ target: { name: "attributes", value: mapGroup.attributes } });
  };

  const deleteAttribute = (i) => {
    mapGroup.attributes.splice(i, 1);
    onChange({ target: { name: "attributes", value: mapGroup.attributes } });
  };

  const addMark = () => {
    let mark = mapMark;
    resetMapMark();
    mapGroup.mapMarks.push(mark);
    onChange({ target: { name: "mapMarks", value: mapGroup.mapMarks } });
  };

  const removeMark = () => {};

  const resetMapMark = () => {
    onChangeMapMark({ target: { name: "name", value: "" } });

    let data = {};
    mapGroup.attributes.forEach((attr) => {
      switch (attr.type) {
        case "string":
        case "number":
          data[attr.name] = "";
          break;
        case "boolean":
          data[attr.name] = false;
          break;
        default:
      }
    });

    console.log(data);

    onChangeMapMark({ target: { name: "data", value: data } });
  };

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="h6" >
            {t("mapGroup")}:
          </Typography>
        </Grid>
        <Grid item>
          <TextInput
            name="name"
            label={t("name")}
            value={mapGroup.name}
            onChange={(e) => {
              onChange(e);
            }}
          />
        </Grid>
        <Grid item>
          <FormControl variant="outlined">
            <label for="color">{t("color")}</label>
            <input
              type="color"
              id="color"
              name="color"
              value={mapGroup.color}
              onChange={(e) => {
                onChange(e);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="h6" >
          {t("attributes")}:
        </Typography>
      </Grid>

      <Grid item>
        <List>
          {mapGroup.attributes?.map((attribute, i) => (
            <ListItem key={i}>
              <Grid container spacing={1}>
                <Grid item>
                  <CustomSelect
                    name="type"
                    label={t("type")}
                    value={attribute.type}
                    onChange={(e) => {
                      changeAttribute(e, i);
                    }}
                    options={[
                      { value: "string", label: t("string") },
                      { value: "boolean", label: t("boolean") },
                      { value: "number", label: t("number") },
                    ]}
                  />
                </Grid>
                <Grid item>
                  <TextInput
                    name="name"
                    label={t("name")}
                    value={attribute.name}
                    onChange={(e) => {
                      changeAttribute(e, i);
                    }}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => {
                      deleteAttribute(i);
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Grid>

      <Grid item>
        <Button
          variant="contained"
          onClick={addAttribute}
          startIcon={<ControlPointIcon />}
        >
          {t("attribute")}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <MapMarkForm
          addMark={addMark}
          removeMark={removeMark}
          mapGroup={mapGroup}
          mapMark={mapMark}
          onChange={onChangeMapMark}
        />
      </Grid>

      <Grid item>
        <Button onClick={submitForm} variant="contained" color="primary">
          {t("save")}
        </Button>
      </Grid>
    </Grid>
  );
}
