import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useLocation, Redirect } from "react-router-dom";

import i18n from "../../utils/i18n";
import LanguageSelect from "../Inputs/LanguageSelect";
import LoginForm from "../LoginForm";
import { useReducer } from "react";
import SetupMFA from "./SetupMFA";
import TOTPForm from "./TOTPForm";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useTranslation } from "react-i18next";
import { API_ROUTE } from "../../utils/API";
import { enqueueSnackbar } from "notistack";
import SecureLocationForm from "./SecureLocationForm";

const initialState = {
  mfa: {
    setupRequired: false,
    showTOTPForm: false,
    secretBase32: "",
    qrCode: "",
    username: "",
    password: "",
    userDevice: null,
    userIP: null,
  },
  loginLoading: false,
  redirectAuthorized: false,
  showSecureLocationForm: false,
  token: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_MFA": {
      return {
        ...state,
        mfa: {
          ...state.mfa,
          ...action.payload,
        },
      };
    }
    case "SET_LOGIN_LOADING": {
      return {
        ...state,
        loginLoading: action.payload,
      };
    }
    case "SET_REDIRECT_AUTHORIZED": {
      return {
        ...state,
        redirectAuthorized: action.payload,
      };
    }
    case "SET_SHOW_SECURE_LOCATION_FORM": {
      return {
        ...state,
        showSecureLocationForm: action.payload,
      };
    }
    case "SET_TOKEN": {
      return {
        ...state,
        token: action.payload,
      };
    }
    default:
      throw new Error("Invalid action type");
  }
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function LoginPage() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setToken, token } = useToken();

  const [t] = useTranslation("login");
  const [tErrors] = useTranslation("errors");

  const query = useQuery();
  const redirect = query.get("redirect");

  const handleSetMFA = (value) => {
    dispatch({ type: "SET_MFA", payload: value });
  };

  const verifyMFAToken = (token) => {
    login(state.mfa.username, state.mfa.password, token);
  };

  const handleSetLoginLoading = (value) => {
    dispatch({
      type: "SET_LOGIN_LOADING",
      payload: value,
    });
  };

  const handleSetRedirectAuthorized = (value) => {
    dispatch({
      type: "SET_REDIRECT_AUTHORIZED",
      payload: value,
    });
  };

  const handleSetShowSecureLocationForm = (value) => {
    dispatch({
      type: "SET_SHOW_SECURE_LOCATION_FORM",
      payload: value,
    });
  };

  const handleLoginMFAResponse = (name, password, responseData) => {
    const mfa = { username: name, password };
    if (!responseData.isActive) {
      handleSetMFA({
        setupRequired: true,
        secretBase32: responseData.secret?.base32,
        qrCode: responseData.qrCode,
        ...mfa,
      });
    } else {
      handleSetMFA({
        showTOTPForm: true,
        userDevice: responseData.userDevice,
        userIP: responseData.userIP,
        ...mfa,
      });
    }
  };

  const handleTrustClick = async (
    isTrusted,
    deviceLabel = undefined,
    locationLabel = undefined
  ) => {
    if (isTrusted) {
      const promises = [];
      deviceLabel &&
        !state.mfa.userDevice &&
        promises.push(addTrustedDevice(deviceLabel));
      locationLabel &&
        !state.mfa.userIP &&
        promises.push(addTrustedLocation(locationLabel));

      try {
        const results = await Promise.all(promises);
        const hasError = results.some((result) => result.data.error);
        if (hasError) {
          results.forEach((result) => {
            if (result.data.error) {
              enqueueSnackbar(tErrors(result.data.error), { variant: "error" });
            }
          });
          return;
        } else {
          enqueueSnackbar(t("trustedDevicesUpdatedSuccessfully"), {
            variant: "success",
          });
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      }
    }
    handleSetShowSecureLocationForm(false);
    handleSetRedirectAuthorized(true);
  };

  const login = (name, password, token) => {
    handleSetLoginLoading(true);

    const body = {
      name,
      password,
    };

    if (token) {
      body.token = token;
    }

    axios
      .post(API_ROUTE + "/auth/login", body)
      .then((res) => {
        if (!res.data.error) {
          if (res.data.loginSuccess === true && !res.data.isCustomer) {
            setToken(res.data.token);
            if (state.mfa.setupRequired || state.mfa.showTOTPForm) {
              handleSetMFA({ setupRequired: false, showTOTPForm: false });
              handleSetShowSecureLocationForm(true);
            } else {
              handleSetRedirectAuthorized(true);
            }
          } else {
            if (res.data?.isCustomer === true)
              enqueueSnackbar(t("usersAccessDenied"), { variant: "error" });
            else enqueueSnackbar(t("accessDenied"), { variant: "error" });
          }
        } else {
          const errorCode =
            typeof res.data.error === "string"
              ? res.data.error
              : res.data.error?.error;
          if (errorCode === "auth-007") {
            handleLoginMFAResponse(name, password, res.data);
          } else {
            enqueueSnackbar(tErrors(errorCode), {
              variant: "error",
            });
          }
        }
      })
      .catch((error) => enqueueSnackbar(error, { variant: "error" }))
      .finally(() => handleSetLoginLoading(false));
  };

  const addTrustedDevice = async (deviceLabel) => {
    return await axios.post(
      API_ROUTE + "/users/devices/add",
      {
        deviceLabel,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  };

  const addTrustedLocation = async (locationLabel) => {
    return await axios.post(
      API_ROUTE + "/users/IPs/add",
      { locationLabel },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  };

  const showLoginForm =
    !state.mfa.setupRequired &&
    !state.mfa.showTOTPForm &&
    !state.showSecureLocationForm;

  return state.redirectAuthorized && !state.showSecureLocationForm ? (
    <Redirect to={redirect?.replaceAll("~", "&") || "/app"} />
  ) : (
    <Container>
      <Grid container sx={{ height: "100vh" }} padding={2}>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item>
            <Link to="/">
              <Box
                component="img"
                src="/img/NUT_LOGO_1_black_low.png"
                width="175px"
                sx={{ objectFit: "cover", objectPosition: "50% 43%" }}
              />
            </Link>
          </Grid>
          <Grid item>
            <LanguageSelect defaultLang={i18n.language} />
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            {showLoginForm && (
              <LoginForm
                onLoginClick={login}
                loginLoading={state.loginLoading}
              />
            )}
            {state.mfa.setupRequired && (
              <SetupMFA
                secretBase32={state.mfa.secretBase32}
                qrCode={state.mfa.qrCode}
                onVerifyToken={verifyMFAToken}
                verifyLoading={state.loginLoading}
              />
            )}
            {state.mfa.showTOTPForm && (
              <TOTPForm
                onVerifyToken={verifyMFAToken}
                verifyLoading={state.loginLoading}
              />
            )}
            {state.showSecureLocationForm && (
              <SecureLocationForm
                onTrustClick={handleTrustClick}
                userDeviceLabel={state.mfa.userDevice?.label}
                userLocationLabel={state.mfa.userIP?.label}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
