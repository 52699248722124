// Context
import AppContext from "../../context/AppContext";

// MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";

// React
import { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Custom
import { CustomTable } from "../CustomTable";
import CustomDate from "./CustomDate";
import CustomSelect from "./CustomSelect";
import SearchButton from "./SearchButton";
import TextInput from "./TextInput";

const initialState = {
  boxes: [],
  centers: [],
  contracts: [],
  filteredBoxes: [],
  loading: false,
  merchantables: [],
  merchantablesTypes: [],
  filters: {
    boxes: [],
    centers: [],
    concept: "",
    contract: "",
    customer: "",
    fromDate: "",
    invoiced: "",
    type: "",
    untilDate: "",
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        [action.payload.dataName]: action.payload.value,
      };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.value,
        },
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      throw new Error("Action not found in reducer");
  }
}

function AddMerchantableDialog({
  closeDialog,
  customerId,
  invoiceItems = [],
  openDialog,
  tableColumns = [],
  defaultFilters,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("invoices");
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  // Initial useEffect, will run the code only the first render
  useEffect(() => {
    getMerchantableTypes();
    setDefaultFilters();
  }, []);

  // Get contracts of customer
  useEffect(() => {
    customerId && getContracts();
  }, [customerId]);

  // Unselect boxes when centers change
  useEffect(() => {
    handleFilterChange({
      target: { name: "boxes", value: [] },
    });
  }, [state.filters.centers]);

  // Get all the merchantable types for the select
  const getMerchantableTypes = () => {
    api
      .get("/merchantables/types")
      .then((response) => {
        const payload = {
          dataName: "merchantablesTypes",
          value: response.data,
        };
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else dispatch({ type: "SET_DATA", payload: payload });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  // Get all contracts and centers
  const getContracts = () => {
    let params = {
      customerId: customerId,
      include: ["Box", "Center"],
    };

    api
      .get("/contracts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_DATA",
            payload: { dataName: "contracts", value: response.data },
          });

          //Get distinct centers
          const allCenters = response.data.map(
            (contract) => contract.Box.Center
          );
          let centers = [];

          let isDuplicate;
          allCenters.forEach((center) => {
            isDuplicate = false;
            centers.forEach((item) => {
              if (item.id === center.id) {
                isDuplicate = true;
              }
            });
            if (!isDuplicate) {
              centers.push(center);
            }
          });
          dispatch({
            type: "SET_DATA",
            payload: { dataName: "centers", value: centers },
          });

          //Get distinct boxes
          const allBoxes = response.data.map((contract) => contract.Box);
          let boxes = [];

          // Check duplicates
          let boxIsDuplicate;
          allBoxes.forEach((box) => {
            boxes.forEach((item) => {
              if (item.id === box.id) {
                boxIsDuplicate = true;
              }
            });
            if (!boxIsDuplicate) {
              boxes.push(box);
            }
          });
          dispatch({
            type: "SET_DATA",
            payload: { dataName: "boxes", value: boxes },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  // Get all the merchantables based on the customer and filters
  const getMerchantables = () => {
    // If no customer is selected display warning
    if (!customerId) {
      enqueueSnackbar("noCustomerSelected", { variant: "warning" });
      return;
    }
    dispatch({ type: "SET_LOADING", payload: true });

    let filters = {
      customer: customerId,
      include: ["Box", "Customer", "Contract", "InvoiceItem"],
    };

    state.filters.boxes?.length > 0 && (filters.box = state.filters.boxes);
    state.filters.centers?.length > 0 &&
      (filters.center = state.filters.centers);
    state.filters.concept !== "" && (filters.concept = state.filters.concept);
    state.filters.contract !== "" &&
      (filters.contract = state.filters.contract);
    state.filters.customer !== "" &&
      (filters.customer = state.filters.customer);
    state.filters.fromDate !== "" &&
      (filters.dateFrom = state.filters.fromDate);
    state.filters.type !== "" && (filters.merchType = state.filters.type);
    state.filters.untilDate !== "" &&
      (filters.dateUntil = state.filters.untilDate);

    api
      .get("/merchantables", { params: filters })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_DATA",
            payload: { dataName: "merchantables", value: response.data },
          });
          if (response.data.length === 0)
            enqueueSnackbar(t("noMerchantables"), { variant: "warning" });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", payload: false });
      });
  };

  // Parse the default filters for the dialog
  const setDefaultFilters = () => {
    if (!defaultFilters) return;
    defaultFilters.forEach((filter) => {
      dispatch({ type: "SET_FILTER", payload: filter });
    });
  };

  // Handler function to set the value of each filter when it changes
  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        filterName: e.target.name,
        value: e.target.value,
      },
    });
  };

  // Function to filter all the merchantables that will be displayed in the table
  const filterMerchantablesFunction = (merchantable) => {
    return (
      !invoiceItems.some((item) => item.merchantableId === merchantable.id) &&
      ((state.filters.invoiced === "yes" &&
        merchantable.InvoiceItems.length > 0) ||
        (state.filters.invoiced === "no" &&
          merchantable.InvoiceItems.length === 0) ||
        (state.filters.invoiced === "" &&
          merchantable.InvoiceItems.length >= 0))
    );
  };

  return (
    <Dialog onClose={closeDialog} open={openDialog} maxWidth="xl">
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>{t("addMerchantable")}</Grid>
          <Grid item>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container paddingTop={1} spacing={1}>
          <Grid item container spacing={1}>
            <Grid item xs={12} md="auto">
              <TextInput
                label={t("concept")}
                value={state.filters.concept}
                name="concept"
                onChange={handleFilterChange}
              />
            </Grid>

            {["fromDate", "untilDate"].map((element, index) => (
              <Grid item xs={6} md="auto" key={index}>
                <CustomDate
                  name={element}
                  value={state.filters[element]}
                  onChange={handleFilterChange}
                  label={t(element)}
                />
              </Grid>
            ))}

            <Grid item xs={12} md="auto">
              <CustomSelect
                options={[
                  { value: "", label: t("all") },
                  ...state.merchantablesTypes.map((type) => ({
                    value: type.id,
                    label: type.name,
                  })),
                ]}
                name="type"
                value={state.filters.type}
                onChange={handleFilterChange}
                label={t("merchType")}
              />
            </Grid>

            <Grid item xs={12} sm="auto">
              <CustomSelect
                options={state.centers
                  .map((center) => ({
                    value: center.id,
                    label: center.name,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                value={state.filters.centers}
                onChange={handleFilterChange}
                name="centers"
                label={t("center")}
                multiple
                sx={{ maxWidth: 230 }}
              />
            </Grid>

            <Grid item xs={12} sm="auto">
              <CustomSelect
                options={state.boxes
                  .map((box) => ({
                    value: box.id,
                    label: box.name,
                    centerId: box.centerId,
                  }))
                  .filter((box) => state.filters.centers.includes(box.centerId))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                value={state.filters.boxes}
                onChange={handleFilterChange}
                name="boxes"
                label={t("box")}
                multiple
                disabled={state.filters.centers.length === 0}
              />
            </Grid>

            <Grid item xs={12} sm="auto">
              <CustomSelect
                options={[
                  { value: "", label: t("all") },
                  ...state.contracts.map((contract) => ({
                    value: contract.id,
                    label: contract.publicId,
                  })),
                ]}
                value={state.filters.contract}
                onChange={handleFilterChange}
                name="contract"
                label={t("contract")}
              />
            </Grid>

            <Grid item xs={12} sm="auto">
              <CustomSelect
                options={[
                  { value: "", label: t("all") },
                  { value: "yes", label: t("yes") },
                  { value: "no", label: t("no") },
                ]}
                value={state.filters.invoiced}
                onChange={handleFilterChange}
                name="invoiced"
                label={t("invoiced")}
              />
            </Grid>

            <Grid item xs={12} sm="auto">
              <SearchButton
                onClick={getMerchantables}
                loading={state.loading}
                fullWidth
              >
                {t("search")}
              </SearchButton>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <CustomTable
                columns={tableColumns}
                data={state.merchantables.filter(filterMerchantablesFunction)}
                options={state.loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddMerchantableDialog;
