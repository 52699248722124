import { Container, Paper, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect } from "react";

const Page = (props) => {
  const { browserTitle, title, maxWidth, paper, children } = props;

  useEffect(() => {
    if (browserTitle) document.title = browserTitle;
  }, []);

  const titleRender = title ? (
    <Typography variant="h4" marginBottom={1}>
      {title}
    </Typography>
  ) : (
    <></>
  );

  return (
    <Container maxWidth={maxWidth || false} sx={{ marginY: 3 }}>
      {paper ? (
        <Paper sx={{ padding: 3 }}>
          {titleRender}
          {children}
        </Paper>
      ) : (
        <>
          {titleRender}
          {children}
        </>
      )}
    </Container>
  );
};

Page.propTypes = {
  browserTitle: PropTypes.string,
  title: PropTypes.string,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  paper: PropTypes.bool,
};

export default Page;
