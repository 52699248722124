import {
  Alert,
  AlertTitle,
  Checkbox,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import ConfirmDialog from "../../ConfirmDialog";
import ContractsList from "../../ContractsList";
import TextInput from "../../Inputs/TextInput";
import BoxStateChip from "../../BoxStateChip";

import DescriptionIcon from "@mui/icons-material/Description";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MoreTimeIcon from "@mui/icons-material/MoreTime";

import {
  BLOCKED_BOX_STATE_ID,
  BOOKED_BOX_STATE_ID,
  FREE_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,
} from "../../../data/constants";

import Page from "../../global/structure/Page";
import LabeledText from "../../global/LabeledText";
import CustomButton from "../../Inputs/CustomButton";
import ActionIconButtons from "../../global/ActionIconButtons";
import Dialog from "../../global/Dialog";
import CustomSelect from "../../Inputs/CustomSelect";

const editableStates = [
  FREE_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,
  BLOCKED_BOX_STATE_ID,
];

const initialState = {
  centers: [],
  contracts: [],
  form: {
    name: "",
    centerId: "",
    meters: "",
    royalty: "",
    pricePerMeter: "",
    state: "",
    isLocker: false,
    floor: "",
  },
  inputError: {
    name: false,
    centerId: false,
    meters: false,
    royalty: false,
    pricePerMeter: false,
  },
  loading: {
    submitForm: false,
    endBooking: false,
    createBooking: false,
  },
  bookingDialog: {
    comments: "",
    duration: 15,
    open: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_CONTRACTS":
      return { ...state, contracts: action.payload };
    case "SET_BOX":
      return { ...state, form: action.payload };
    case "SET_LOADING":
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.loadingName]: action.payload.value,
        },
      };
    case "SET_BOOKING_DIALOG":
      return {
        ...state,
        bookingDialog: {
          ...state.bookingDialog,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      throw new Error();
  }
}

export default function BoxPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("boxes");
  const [tErrors] = useTranslation("errors");

  //Initial useEffect
  useEffect(() => {
    getBox();
    user.hasAction("VIEW_CONTRACTS") && getContracts();
    getCenters();
  }, []);

  const getBox = () => {
    api
      .get("/boxes/" + id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_BOX",
            payload: response.data,
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const getContracts = () => {
    let params = {
      include: ["Box", "Center", "Customer"],
      boxId: id,
    };

    api
      .get("/contracts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_CONTRACTS", payload: response.data });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTERS",
            payload: response.data,
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const getCurrentBoxContract = (box) => {
    if (!box || !box.Contracts || !box.Contracts.length) return null;
    for (let contract of box.Contracts) {
      if (!contract.endDate) return contract;
    }
    const today = new Date();
    for (let contract of box.Contracts) {
      if (
        new Date(contract.startDate) <= today &&
        new Date(contract.endDate) >= today
      )
        return contract;
    }
    return null;
  };

  const submitForm = (form) => {
    if (!validateForm(form)) return;

    let data = {};
    form.name !== "" && (data.name = form.name);
    form.centerId !== "" && (data.centerId = form.centerId);
    form.meters !== "" && (data.meters = form.meters);
    form.pricePerMeter !== "" && (data.pricePerMeter = form.pricePerMeter);
    form.royalty !== "" && (data.royalty = form.royalty);
    form.comments !== "" && (data.comments = form.comments);
    form.floor !== "" && (data.floor = form.floor);
    data.isLocker = form.isLocker;

    handleSetLoading("submitForm", true);
    api
      .post("/boxes/edit/" + id, data)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("boxEditSuccess"), { variant: "success" });
          history.goBack();
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() => handleSetLoading("submitForm", false));
  };

  const modifyBoxState = (newState) => {
    api
      .post("/boxes/" + id + "/modify-state", { state: newState })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("boxStateEditSuccess"), { variant: "success" });
          dispatch({
            type: "SET_INPUT",
            payload: {
              inputname: "state",
              value: newState,
            },
          });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const activeBooking = state.form.BoxBookings?.find(
    (booking) => booking.remainingMinutes > 0
  );

  const createOrExtendBooking = () => {
    handleSetLoading("createBooking", true);

    const body = {
      boxId: id,
      durationInMinutes: state.bookingDialog.duration,
    };

    state.bookingDialog.comments != "" &&
      (body.comments = state.bookingDialog.comments);

    const doCreateBooking = state.form.state === FREE_BOX_STATE_ID;

    const apiRoute = doCreateBooking
      ? "/box-bookings/create"
      : `/box-bookings/${activeBooking.id}/extend`;

    api
      .post(apiRoute, body)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(
            doCreateBooking
              ? t("bookingCreatedSuccessfully")
              : t("bookingExtendedSuccessfully"),
            {
              variant: "success",
            }
          );
          getBox();
          handleSetBookingDialog("open", false);
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() => handleSetLoading("createBooking", false));
  };

  const endBooking = (bookingId) => {
    handleSetLoading("endBooking", true);

    api
      .post(`/box-bookings/end/${bookingId}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("bookingEndedSuccessfully"), {
            variant: "success",
          });
          getBox();
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() => handleSetLoading("endBooking", false));
  };

  const handleInputChange = (e) => {
    setInputError(e.target.name, false);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleSetLoading = (loadingName, value) => {
    dispatch({
      type: "SET_LOADING",
      payload: {
        loadingName,
        value,
      },
    });
  };

  const handleSetBookingDialog = (key, value) => {
    dispatch({
      type: "SET_BOOKING_DIALOG",
      payload: {
        key,
        value,
      },
    });
  };

  const handleBookingDialogInputChange = (e) => {
    handleSetBookingDialog(e.target.name, e.target.value);
  };

  const handleBoxStateChange = (e) => {
    const checked = e.target.checked;
    const currentState = Number(state.form.state);

    const getNewState = (checked, currentState) => {
      if (
        [FREE_BOX_STATE_ID, UNAVAILABLE_BOX_STATE_ID].includes(currentState)
      ) {
        return checked ? UNAVAILABLE_BOX_STATE_ID : FREE_BOX_STATE_ID;
      } else if (
        [OCCUPIED_BOX_STATE_ID, BLOCKED_BOX_STATE_ID].includes(currentState)
      ) {
        return checked ? BLOCKED_BOX_STATE_ID : OCCUPIED_BOX_STATE_ID;
      }
      return currentState;
    };

    const newState = getNewState(checked, currentState);
    modifyBoxState(newState);
  };

  const handleIsLockerChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "isLocker", value: e.target.checked },
    });
  };

  const setInputError = (name, value) => {
    dispatch({
      type: "SET_INPUT_ERROR",
      payload: {
        inputname: name,
        value,
      },
    });
  };

  const validateForm = (form) => {
    let isValid = true;
    let fields = ["name", "meters", "pricePerMeter", "royalty", "centerId"];

    fields.forEach((field) => {
      if (form[field] === "") {
        setInputError(field, true);
        enqueueSnackbar(t(field) + " " + t("isRequired"), {
          variant: "error",
        });
        isValid = false;
      }
    });

    return isValid;
  };

  return (
    <Page browserTitle={t("box")}>
      <Container maxWidth="md" sx={{ marginY: 3 }}>
        <Paper sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid
              item
              container
              alignItems="center"
              xs={12}
              spacing={1}
              paddingBottom={2}
            >
              <Grid item>
                <Typography variant="h4">
                  {state.form.name + " - "}
                  {user.hasAction("VIEW_CENTERS") ? (
                    <Link
                      to={"/app/center/" + state.form.Center?.id}
                      style={{ textDecoration: "none" }}
                    >
                      {state.form.Center?.name}
                    </Link>
                  ) : (
                    state.form.Center?.name
                  )}
                </Typography>
              </Grid>

              <Grid item paddingBottom={1} marginLeft={1}>
                <BoxStateChip
                  state={state.form.state}
                  endDate={
                    state.form.state === OCCUPIED_BOX_STATE_ID
                      ? getCurrentBoxContract({ Contracts: state.contracts })
                          ?.endDate
                      : null
                  }
                />
              </Grid>

              <Grid item flex={1} justifyContent="flex-end" display="flex">
                <ActionIconButtons
                  actions={[
                    {
                      display:
                        user.hasAction("CREATE_BOX_BOOKINGS") &&
                        state.form?.state === FREE_BOX_STATE_ID,
                      icon: <ScheduleIcon />,
                      title: t("createBooking"),
                      onClick: () => handleSetBookingDialog("open", true),
                    },
                    {
                      display:
                        (user.hasAction("END_ANY_BOX_BOOKINGS") ||
                          user?.id === activeBooking?.userId) &&
                        state.form?.state === BOOKED_BOX_STATE_ID,
                      icon: <MoreTimeIcon />,
                      title: t("extendBooking"),
                      onClick: () => handleSetBookingDialog("open", true),
                    },
                    {
                      display: user.hasAction("CREATE_CONTRACTS"),
                      icon: <DescriptionIcon />,
                      title: t("createContract"),
                      link: "/app/contract/create?boxId=" + id,
                      color: "success",
                    },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              {state.form?.state === BOOKED_BOX_STATE_ID && (
                <BoxBookingInfo
                  box={state.form}
                  user={user}
                  endBooking={endBooking}
                  loading={state.loading}
                />
              )}
              <Grid item xs={12} sm={6} md={3}>
                {user.hasAction("EDIT_BOXES") ? (
                  <TextInput
                    error={state.inputError.name}
                    helperText={
                      state.inputError.name
                        ? t("name") + " " + t("mustNotBeBlank")
                        : ""
                    }
                    label={t("name")}
                    name="name"
                    value={state.form.name}
                    onChange={handleInputChange}
                  />
                ) : (
                  <LabeledText label={t("name")} value={state.form.name} />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                {user.hasAction("EDIT_BOXES") ? (
                  <TextInput
                    error={state.inputError.meters}
                    helperText={
                      state.inputError.meters
                        ? t("meters") + " " + t("mustNotBeBlank")
                        : ""
                    }
                    label={t("meters") + " (㎡)"}
                    type="number"
                    name="meters"
                    value={state.form.meters}
                    onChange={handleInputChange}
                  />
                ) : (
                  <LabeledText
                    label={t("meters")}
                    value={state.form.meters + "㎡"}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {user.hasAction("EDIT_BOXES") ? (
                  <TextInput
                    error={state.inputError.pricePerMeter}
                    helperText={
                      state.inputError.pricePerMeter
                        ? t("pricePerMeter") + " " + t("mustNotBeBlank")
                        : ""
                    }
                    label={t("pricePerMeter") + " (€)"}
                    type="number"
                    name="pricePerMeter"
                    value={state.form.pricePerMeter}
                    onChange={handleInputChange}
                  />
                ) : (
                  <LabeledText
                    label={t("pricePerMeter")}
                    value={state.form.pricePerMeter + "€"}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {user.hasAction("EDIT_BOXES") ? (
                  <TextInput
                    error={state.inputError.royalty}
                    helperText={
                      state.inputError.royalty
                        ? t("royalty") + " " + t("mustNotBeBlank")
                        : ""
                    }
                    label={t("royalty") + " (%)"}
                    type="number"
                    name="royalty"
                    value={state.form.royalty}
                    onChange={handleInputChange}
                  />
                ) : (
                  <LabeledText
                    label={t("royalty")}
                    value={state.form.royalty + "%"}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {user.hasAction("EDIT_BOXES") ? (
                  <TextInput
                    label={t("floor")}
                    type="number"
                    name="floor"
                    value={state.form.floor}
                    onChange={handleInputChange}
                  />
                ) : (
                  <LabeledText
                    label={t("floor")}
                    value={state.form.floor || ""}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {user.hasAction("EDIT_BOXES") ? (
                  <FormControlLabel
                    label={t("isLocker")}
                    control={
                      <Checkbox
                        name="isLocker"
                        checked={state.form.isLocker}
                        onChange={handleIsLockerChange}
                      />
                    }
                  />
                ) : (
                  <LabeledText
                    label={t("isLocker")}
                    value={state.form.isLocker ? t("yes") : t("no")}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {user.hasAction("EDIT_BOX_COMMENTS") ? (
                <TextInput
                  label={t("comments")}
                  multiline
                  rows={3}
                  onChange={handleInputChange}
                  value={state.form.comments}
                  name="comments"
                  InputLabelProps={{ shrink: state.form?.comments !== "" }}
                />
              ) : (
                <LabeledText
                  label={t("comments")}
                  value={state.form.comments || t("noComments")}
                />
              )}
            </Grid>

            {user.hasAction("EDIT_BOX_STATE") &&
              editableStates.includes(Number(state.form.state)) && (
                <Grid item xs={12}>
                  <BoxStateSwitch
                    state={state.form.state}
                    handleBoxStateChange={handleBoxStateChange}
                  />
                </Grid>
              )}

            {/* Contracts of the box */}
            {user.hasAction("VIEW_CONTRACTS") && (
              <Grid container item marginTop={2} xs={12}>
                <Grid item>
                  <Typography variant="h6">{t("contracts")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  {state.contracts.length > 0 ? (
                    <ContractsList contracts={state.contracts} />
                  ) : (
                    <Typography variant="body1">{t("noContracts")}</Typography>
                  )}
                </Grid>
              </Grid>
            )}

            <Grid container item spacing={1} xs={12} justifyContent="flex-end">
              <Grid item>
                <CustomButton onClick={() => history.goBack()} variant="text">
                  {t("back")}
                </CustomButton>
              </Grid>
              {(user.hasAction("EDIT_BOXES") ||
                user.hasAction("EDIT_BOX_COMMENTS")) && (
                <Grid item>
                  <CustomButton
                    onClick={() => submitForm(state.form)}
                    loading={state.loading.submitForm}
                  >
                    {t("save")}
                  </CustomButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Dialog
          open={state.bookingDialog.open}
          onClose={() => handleSetBookingDialog("open", false)}
          title={
            state.form.state === FREE_BOX_STATE_ID
              ? t("createBooking")
              : t("extendBooking")
          }
          actions={
            <CustomButton
              onClick={createOrExtendBooking}
              loading={state.loading.createBooking}
              color="success"
            >
              {state.form.state === FREE_BOX_STATE_ID
                ? t("createBooking")
                : t("extendBooking")}
            </CustomButton>
          }
        >
          <Grid item marginTop={1}>
            <CustomSelect
              options={[
                { label: "15 " + t("minutes"), value: 15 },
                { label: "30 " + t("minutes"), value: 30 },
                { label: "1 " + t("hour"), value: 60 },
                { label: "6 " + t("hours"), value: 360 },
                { label: "12 " + t("hours"), value: 720 },
                { label: "1 " + t("day"), value: 1440 },
                { label: "2 " + t("days"), value: 2880 },
              ]}
              label={t("time")}
              name="duration"
              value={state.bookingDialog.duration}
              onChange={handleBookingDialogInputChange}
            />
            <TextInput
              label={t("comments")}
              multiline
              rows={4}
              name="comments"
              onChange={handleBookingDialogInputChange}
              value={state.bookingDialog.comments}
            />
          </Grid>
        </Dialog>
      </Container>
    </Page>
  );
}

const BoxStateSwitch = ({ state, handleBoxStateChange }) => {
  const [t] = useTranslation("boxes");

  const getFormControlLabel = () => {
    if ([FREE_BOX_STATE_ID, UNAVAILABLE_BOX_STATE_ID].includes(Number(state)))
      return t("unavailable");
    else if (
      [OCCUPIED_BOX_STATE_ID, BLOCKED_BOX_STATE_ID].includes(Number(state))
    )
      return t("blocked");
    else return "?";
  };

  return (
    <FormControlLabel
      label={getFormControlLabel()}
      control={
        <Checkbox
          checked={
            [UNAVAILABLE_BOX_STATE_ID, BLOCKED_BOX_STATE_ID].includes(
              Number(state)
            ) || false
          }
          onChange={handleBoxStateChange}
        />
      }
    />
  );
};

const BoxBookingInfo = ({ box, user, endBooking, loading }) => {
  const [t] = useTranslation("boxes");
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const activeBooking = box.BoxBookings?.find(
    (booking) => booking.remainingMinutes > 0
  );

  const userCanEndBoxBooking =
    user?.id === activeBooking?.userId ||
    user.hasAction("END_ANY_BOX_BOOKINGS");

  return (
    <Grid item xs={12}>
      <Collapse in={isVisible}>
        <Alert
          severity="info"
          action={
            userCanEndBoxBooking && (
              <CustomButton
                variant="text"
                onClick={() => setIsConfirmOpen(true)}
                loading={loading.endBooking}
              >
                {t("endBooking")}
              </CustomButton>
            )
          }
        >
          <AlertTitle>{`${t("bookingId")}: ${activeBooking?.id}`}</AlertTitle>
          {`${t("bookedBy")} ${activeBooking.User?.name} ${t("with")} ${
            activeBooking?.remainingMinutes
          } ${t("minutesLeft")}`}
        </Alert>
      </Collapse>

      <ConfirmDialog
        title={t("confirmEnding")}
        open={isConfirmOpen}
        setOpen={() => setIsConfirmOpen()}
        confirmText={t("yes")}
        cancelText={t("no")}
        onConfirm={(confirmed) => {
          confirmed && endBooking(activeBooking?.id);
        }}
      >
        {`${t("bookedBy")} ${activeBooking.User?.name} ${t("with")} ${
          activeBooking?.remainingMinutes
        } ${t("minutesLeft")}`}
      </ConfirmDialog>
    </Grid>
  );
};
