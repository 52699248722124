function buildRenderData(data, tileRender) {
  if (!data || !data.FloorPlanLayers) return null;
  const layers = data.FloorPlanLayers.map((layer) => ({
    id: layer.id,
    name: layer.name,
    floorPlanId: data.id,
    z: layer.z,
    tiles: layer.FloorPlanTiles.sort((tile1, tile2) => tile1.z - tile2.z).map(
      (tile) => {
        return { ...tile, floorPlanLayerId: layer.id, render: tileRender };
      }
    ),
    ...layer.props,
  }));
  return {
    width: data.width,
    height: data.height,
    backgroundColor: data.backgroundColor,
    layers: layers,
    ...data.props,
  };
}

export default buildRenderData;
