import { Button } from "@mui/material";
import PropTypes from "prop-types";
import Link from "./Link";

const ButtonLink = (props) => {
  return <Button LinkComponent={Link} {...props}></Button>;
};

ButtonLink.propTypes = {
  to: PropTypes.string
}
export default ButtonLink;
