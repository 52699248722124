import {
  Box
} from "@mui/material";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { useEffect, useReducer, useRef } from "react";

function reducer(state, action) {
  switch (action.type) {
    case "SET_MAP":
      let markers = [];

      action.payload.markers.forEach((mark) => {
        const marker = new mapboxgl.Marker()
          .setLngLat([mark.longitude, mark.latitude])
          .addTo(action.payload.map);

        markers.push(marker);
      });

      return {
        ...state,
        map: { ...state.map, current: action.payload.map },
        markers,
      };
    case "SET_MARKERS":
      return { ...state, markers: action.payload };
    case "SET_MOUSE_COORDS":
      return { ...state, mouseCoords: action.payload };
    default:
      throw new Error("Action type not found in reducer");
  }
}

export default function Map(props) {
  const { markers = [], onMouseMove } = props;

  const initialState = {
    map: useRef(null),
    mapContainer: useRef(null),
    markers: [],
    mouseCoords: [],
    lat: 41.3874,
    lng: 2.1686,
    zoom: 9,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setMap();
  }, [markers]);

  const setMap = () => {
    if (!state.map?.current) {
      let map = new mapboxgl.Map({
        container: state.mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [state.lng, state.lat],
        zoom: state.zoom,
      });

      // Search bar
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        marker: {
          color: "blue",
        },
        mapboxgl: mapboxgl,
      });
      map.addControl(geocoder);

      // Show coordinates of cursor
      map.on("mousemove", (e) => {
        dispatch({ type: "SET_MOUSE_COORDS", payload: e.lngLat });
        if (onMouseMove) onMouseMove(e.lngLat);
      });

      dispatch({ type: "SET_MAP", payload: { map: map, markers: markers } });
    } else {
      removeAllMarks();
      markers.forEach((marker) => {
        addMark(marker.longitude, marker.latitude);
      });
    }
  }

  const addMark = (lng, lat) => {
    let mark = new mapboxgl.Marker()
      .setLngLat([lng, lat])
      .addTo(state.map.current);

    let markers = state.markers;
    markers.push(mark);
    dispatch({ type: "SET_MARKERS", payload: markers });
  };

  const removeAllMarks = () => {
    if (state.markers != null) {
      for (let currentMarker of state.markers) {
        currentMarker.remove();
      }
    }
  };

  const setMapCenter = (lng, lat) => {
    state.map.current.setCenter([lng, lat]);
  };

  return <Box {...props} ref={state.mapContainer}></Box>;
}
