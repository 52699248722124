import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";

import { SketchPicker } from "react-color";

// Icons
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RemoveIcon from "@mui/icons-material/Remove";

import { useState } from "react";

import useEditIcons from "../../../../hooks/useEditIcons";
import TextInput from "../../../Inputs/TextInput";
import CustomSelect from "../../../Inputs/CustomSelect";

import { useTranslation } from "react-i18next";
import { TileIcons } from "../TileShapes/Tile";

const TileMenuItem = ({
  boxes,
  cameras,
  doors,
  functions,
  key,
  selectedTileData,
  tileData,
}) => {
  const [t] = useTranslation("floorPlans");
  const [anchorEl, setAnchorEl] = useState(null);
  const [optionsMenuAnchor, setOptionsMenuAnchor] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const isOptionsMenuOpen = Boolean(optionsMenuAnchor);

  const editProps = {
    title: t("editLayer"),
    placement: "top",
    disabled: selectedTileData !== null,
  };
  const saveProps = {
    title: t("saveChanges"),
    placement: "top",
  };
  const cancelProps = {
    title: t("cancelChanges"),
    placement: "top",
  };

  const actionCallBack = async (actionName) => {
    if (actionName === "edit") {
      functions.setSelectedTile({
        ...tileData,
        boxId: tileData.Boxes?.length > 0 ? tileData.Boxes[0].id : "",
      });
    }
    if (actionName === "save") {
      const color = selectedTileData.shape === "Rect" || "Polygon" ? null : selectedTileData.color;
      const tileBoxes = (tileData.Boxes && tileData.Boxes.length > 0) === true;
      const newBox =
        (selectedTileData.boxId !== "" &&
          Number(selectedTileData.boxId) !== NaN) === true;

      if (!tileBoxes && newBox)
        functions.createBoxFloorPlanTile({
          BoxId: selectedTileData.boxId,
          FloorPlanTileId: selectedTileData.id,
        });
      if (tileBoxes && !newBox) {
        functions.deleteBoxFloorPlanTile({
          BoxId: tileData.Boxes[0].id,
          FloorPlanTileId: selectedTileData.id,
        });
      }
      functions.editTile({...selectedTileData, color: color}).then(() => {
        functions.setSelectedTile(null);
      });
    }
    if (actionName === "cancel") {
      functions.setSelectedTile(null);
    }
  };

  const { edit, setEdit, iconsRender } = useEditIcons(
    false,
    editProps,
    saveProps,
    cancelProps,
    actionCallBack
  );

  const openDeleteDialog = () => setDeleteDialogOpen(true);
  const closeDeleteDialog = () => setDeleteDialogOpen(false);

  const boxSelect = boxes.map((box) => ({ value: box.id, label: box.name }));

  const handleDuplicate = () => {
    const NEW_TILE_OFFSET_POSITION = 10;

    let newTile = { ...tileData };
    delete newTile.id;

    newTile.x += NEW_TILE_OFFSET_POSITION;
    newTile.y += NEW_TILE_OFFSET_POSITION;

    functions.createTile(newTile);
  };

  return (
    <Card variant="outlined" square>
      <Grid container alignItems="center" padding={1} spacing={2}>
        {!edit && (
          <Grid item xs={1} container justifyContent="center">
            {TileIcons[tileData.shape]}
          </Grid>
        )}
        <Grid item xs={edit ? 3 : 2}>
          {edit ? (
            <TextInput
              name="name"
              label={t("name")}
              value={selectedTileData.name}
              variant="outlined"
              onChange={(e) => {
                functions.setSelectedTile({
                  ...selectedTileData,
                  name: e.target.value,
                });
              }}
            />
          ) : (
            <Typography variant="body1">
              {tileData.Boxes?.length > 0
                ? tileData.Boxes[0].name
                : tileData.name}
            </Typography>
          )}
        </Grid>

        <Grid item container xs={edit ? 6 : 7} spacing={2}>
          {/* Rectangle or Image data render */}
          {(tileData.shape === "Image" || tileData.shape === "Rect") && (
            <>
              <Grid item container xs={10} spacing={2}>
                {/*x and width*/}
                <Grid item xs={6}>
                  {edit && selectedTileData ? (
                    <TextInput
                      name="x"
                      label={t("x")}
                      type="number"
                      value={selectedTileData.x}
                      variant="outlined"
                      onChange={(e) => {
                        functions.setSelectedTile({
                          ...selectedTileData,
                          x: Number(e.target.value),
                        });
                      }}
                    />
                  ) : (
                    <Typography variant="body">
                      {t("x") + ": " + tileData.x + " px"}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {edit && selectedTileData ? (
                    <TextInput
                      name="width"
                      label={t("width")}
                      type="number"
                      value={selectedTileData.width}
                      variant="outlined"
                      onChange={(e) => {
                        functions.setSelectedTile({
                          ...selectedTileData,
                          width: Number(e.target.value),
                        });
                      }}
                    />
                  ) : (
                    <Typography variant="body">
                      {t("width") + ": " + tileData.width + " px"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item container xs={10} spacing={2}>
                {/*y and height*/}
                <Grid item xs={6}>
                  {edit && selectedTileData ? (
                    <TextInput
                      name="y"
                      label={t("y")}
                      type="number"
                      value={selectedTileData.y}
                      variant="outlined"
                      onChange={(e) => {
                        functions.setSelectedTile({
                          ...selectedTileData,
                          y: Number(e.target.value),
                        });
                      }}
                    />
                  ) : (
                    <Typography variant="body">
                      {t("y") + ": " + tileData.y + " px"}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {edit && selectedTileData ? (
                    <TextInput
                      name="height"
                      label={t("height")}
                      type="number"
                      value={selectedTileData.height}
                      variant="outlined"
                      onChange={(e) => {
                        functions.setSelectedTile({
                          ...selectedTileData,
                          height: Number(e.target.value),
                        });
                      }}
                    />
                  ) : (
                    <Typography variant="body">
                      {t("height") + ": " + tileData.height + " px"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          )}

          {/* Polygon data render */}
          {tileData.shape === "Polygon" && (
            <TileMenuPolygonRender
              edit={edit}
              t={t}
              selectedTileData={selectedTileData}
              setSelectedTile={functions.setSelectedTile}
              points={tileData.points}
            />
          )}

          {/* Text data render */}
          {tileData.shape === "Text" && (
            <TileMenuTextRender
              edit={edit}
              t={t}
              tileData={tileData}
              selectedTileData={selectedTileData}
              setSelectedTile={functions.setSelectedTile}
            />
          )}

          {/* Door data render */}
          {tileData.shape === "Door" && (
            <TileMenuDoorRender
              edit={edit}
              t={t}
              tileData={tileData}
              selectedTileData={selectedTileData}
              setSelectedTile={functions.setSelectedTile}
              doors={doors}
            />
          )}

          {/* Camera data render */}
          {tileData.shape === "Camera" && (
            <TileMenuCameraRender
              edit={edit}
              t={t}
              tileData={tileData}
              selectedTileData={selectedTileData}
              setSelectedTile={functions.setSelectedTile}
              cameras={cameras}
            />
          )}
        </Grid>

        <Grid item xs={edit ? 3 : 2} container justifyContent="flex-end">
          {(tileData.shape === "Rect" || tileData.shape === "Polygon") && (
            <Grid item>
              {!edit &&
                tileData.Boxes !== undefined &&
                tileData.Boxes.length > 0 && (
                  <Tooltip title={t("box") + ": " + tileData.Boxes[0].name}>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <MeetingRoomIcon />
                    </IconButton>
                  </Tooltip>
                )}
              {/* If is not associated to any box */}
              {edit &&
                selectedTileData &&
                !(
                  tileData.Boxes !== undefined && tileData.Boxes.length > 0
                ) && (
                  <>
                    <Tooltip title={t("associateWithBox")}>
                      <IconButton
                        color="success"
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        <MeetingRoomIcon />
                      </IconButton>
                    </Tooltip>
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={() => {
                        setAnchorEl(null);
                      }}
                      anchorPosition={{ top: 0, left: -100 }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <Grid item padding={1}>
                        <CustomSelect
                          value={selectedTileData.boxId}
                          onChange={(e) => {
                            functions.setSelectedTile({
                              ...selectedTileData,
                              boxId: e.target.value,
                            });
                          }}
                          options={boxSelect}
                        />
                      </Grid>
                    </Popover>
                  </>
                )}
              {/* If is associated to a box */}
              {edit &&
                selectedTileData &&
                tileData.Boxes !== undefined &&
                tileData.Boxes.length > 0 &&
                selectedTileData.boxId && (
                  <>
                    <Tooltip title={t("removeAssociationWithBox")}>
                      <IconButton
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          functions.setSelectedTile({
                            ...selectedTileData,
                            boxId: "",
                          });
                        }}
                      >
                        <MeetingRoomIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
            </Grid>
          )}
          <Grid item>{edit && iconsRender}</Grid>
          {!edit && (
            <Grid item>
              <IconButton
                disabled={selectedTileData !== null}
                onClick={(e) => {
                  setOptionsMenuAnchor(e.currentTarget);
                }}
              >
                <MoreHorizIcon />
              </IconButton>
              <TileOptionsMenu
                handleClose={() => setOptionsMenuAnchor(null)}
                anchor={optionsMenuAnchor}
                open={isOptionsMenuOpen}
                handleDuplicate={handleDuplicate}
                handleEdit={() => {
                  setEdit(true);
                  actionCallBack("edit");
                }}
                handleDelete={() => {
                  openDeleteDialog();
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>{t("deleteTile")}</DialogTitle>
        <DialogContent>{t("deleteTileText")}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              closeDeleteDialog();
              setAnchorEl(null);
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              closeDeleteDialog();
              functions.deleteTile(tileData.id);
              setAnchorEl(null);
            }}
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

const TileOptionMenuItem = ({ Icon, text, action }) => {
  return (
    <MenuItem onClick={action}>
      <ListItemIcon>
        <Icon fontSize="small" />
      </ListItemIcon>
      {text}
    </MenuItem>
  );
};

const TileOptionsMenu = ({
  open,
  anchor,
  handleClose,
  handleEdit,
  handleDuplicate,
  handleDelete,
}) => {
  const [t] = useTranslation("floorPlans");

  const handleClick = (action) => {
    action === "edit" && handleEdit && handleEdit();
    action === "duplicate" && handleDuplicate && handleDuplicate();
    action === "delete" && handleDelete && handleDelete();

    handleClose();
  };

  return (
    <Menu open={open} anchorEl={anchor} onClose={handleClose}>
      <MenuList dense>
        <TileOptionMenuItem
          Icon={EditIcon}
          text={t("edit")}
          action={() => handleClick("edit")}
        />
        <TileOptionMenuItem
          Icon={ContentCopyIcon}
          text={t("duplicate")}
          action={() => handleClick("duplicate")}
        />
        <Divider />
        <TileOptionMenuItem
          Icon={DeleteIcon}
          text={t("delete")}
          action={() => handleClick("delete")}
        />
      </MenuList>
    </Menu>
  );
};

const TileMenuPolygonRender = ({
  selectedTileData,
  setSelectedTile,
  points,
  t,
  edit,
}) => {
  const pointsToRender = edit ? selectedTileData.points : points;

  const deletePoint = (index) => {
    let newPoints = [...selectedTileData.points];
    newPoints.splice(index - 1, 2);
    setSelectedTile({
      ...selectedTileData,
      points: newPoints,
    });
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        spacing={1}
        maxHeight={edit ? undefined : 100}
        overflow="auto"
      >
        {edit && (
          <Grid
            item
            container
            xs={12}
            display={"flex"}
            justifyContent="flex-end"
          >
            <Tooltip title={t("addPoint")}>
              <IconButton
                size="small"
                onClick={() => {
                  setSelectedTile({
                    ...selectedTileData,
                    points: [
                      ...selectedTileData.points,
                      Number(100),
                      Number(100),
                    ],
                  });
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {pointsToRender &&
          pointsToRender.map((point, index) => {
            if (edit)
              return (
                <>
                  <Grid item xs={5}>
                    <TextInput
                      name={index % 2 === 0 ? "x" : "y"}
                      label={index % 2 === 0 ? t("x") : t("y")}
                      type="number"
                      value={selectedTileData.points[index]}
                      variant="outlined"
                      onChange={(e) => {
                        setSelectedTile({
                          ...selectedTileData,
                          points: selectedTileData.points.map((p, i) => {
                            if (i === index) return Number(e.target.value);
                            return p;
                          }),
                        });
                      }}
                    />
                  </Grid>
                  {index % 2 !== 0 && (
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          deletePoint(index);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                  )}
                </>
              );
            return (
              <>
                {index % 2 === 0 && (
                  <Grid item xs={3}>
                    <Typography variant="body">
                      {t("point") + " " + (index / 2 + 1) + ": "}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={index % 2 === 0 ? 3 : 4}>
                  <Typography variant="body">
                    {index % 2 === 0
                      ? t("x") + ": " + point + " px"
                      : t("y") + ": " + point + " px"}
                  </Typography>
                </Grid>
              </>
            );
          })}
      </Grid>
    </>
  );
};

const TileMenuTextRender = ({
  selectedTileData,
  setSelectedTile,
  t,
  edit,
  tileData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Grid item container xs={10} spacing={2}>
        <Grid item xs={6}>
          {edit && selectedTileData ? (
            <TextInput
              name="x"
              label={t("x")}
              type="number"
              value={selectedTileData.x}
              variant="outlined"
              onChange={(e) => {
                setSelectedTile({
                  ...selectedTileData,
                  x: Number(e.target.value),
                });
              }}
            />
          ) : (
            <Typography variant="body">
              {t("x") + ": " + tileData.x + " px"}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          {edit && selectedTileData ? (
            <TextInput
              name="y"
              label={t("y")}
              type="number"
              value={selectedTileData.y}
              variant="outlined"
              onChange={(e) => {
                setSelectedTile({
                  ...selectedTileData,
                  y: Number(e.target.value),
                });
              }}
            />
          ) : (
            <Typography variant="body">
              {t("y") + ": " + tileData.y + " px"}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={10} spacing={2}>
        <Grid item xs={6}>
          {edit && selectedTileData ? (
            <TextInput
              name="text"
              label={t("text")}
              value={selectedTileData.text}
              variant="outlined"
              onChange={(e) => {
                setSelectedTile({
                  ...selectedTileData,
                  text: e.target.value,
                });
              }}
            />
          ) : (
            <Typography variant="body">
              {t("text") + ": " + tileData.text}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          {edit && selectedTileData ? (
            <TextInput
              name="fontSize"
              label={t("fontSize")}
              type="number"
              value={selectedTileData.fontSize}
              variant="outlined"
              onChange={(e) => {
                setSelectedTile({
                  ...selectedTileData,
                  fontSize: Number(e.target.value),
                });
              }}
            />
          ) : (
            <Typography variant="body">
              {t("fontSize") + ": " + tileData.fontSize + " px"}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        {edit ? (
          <Grid item>
            <Chip
              icon={<EditIcon />}
              label={t("color")}
              style={{
                backgroundColor: tileData.color,
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            />
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => {
                setAnchorEl(null);
              }}
              anchorPosition={{ top: 0, left: -100 }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <SketchPicker
                color={selectedTileData?.color || "#000000"}
                onChange={(color) => {
                  setSelectedTile({
                    ...selectedTileData,
                    color: color.hex,
                  });
                }}
              />
            </Popover>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Typography variant="body">{t("color") + ":"}</Typography>
            </Grid>
            <Grid item>
              <Chip
                style={{
                  backgroundColor: tileData.color,
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

const TileMenuDoorRender = ({
  selectedTileData,
  setSelectedTile,
  t,
  edit,
  tileData,
  doors,
}) => {
  const doorName = doors
    ? doors.find((d) => d.id === tileData.doorId)?.name
    : "";

  return (
    <>
      <Grid item container xs={6} spacing={2}>
        <Grid item xs={12}>
          {edit && selectedTileData ? (
            <TextInput
              name="x"
              label={t("x")}
              type="number"
              value={selectedTileData.x}
              variant="outlined"
              onChange={(e) => {
                setSelectedTile({
                  ...selectedTileData,
                  x: Number(e.target.value),
                });
              }}
            />
          ) : (
            <Typography variant="body">
              {t("x") + ": " + tileData.x + " px"}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {edit && selectedTileData ? (
            <TextInput
              name="y"
              label={t("y")}
              type="number"
              value={selectedTileData.y}
              variant="outlined"
              onChange={(e) => {
                setSelectedTile({
                  ...selectedTileData,
                  y: Number(e.target.value),
                });
              }}
            />
          ) : (
            <Typography variant="body">
              {t("y") + ": " + tileData.y + " px"}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={6} spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          {edit && selectedTileData ? (
            <CustomSelect
              value={selectedTileData.doorId}
              options={
                doors
                  ? doors.map((door) => ({
                      value: door.id,
                      label: door.name,
                    }))
                  : []
              }
              label={t("door")}
              name="doorId"
              onChange={(e) => {
                setSelectedTile({
                  ...selectedTileData,
                  doorId: e.target.value,
                });
              }}
            />
          ) : (
            doorName && (
              <Typography variant="body">
                {t("doorId") + ": " + doorName}
              </Typography>
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

const TileMenuCameraRender = ({
  selectedTileData,
  setSelectedTile,
  t,
  edit,
  tileData,
  cameras,
}) => {
  const cameraName =
    cameras && tileData.cameraId
      ? cameras.find((c) => c.id === tileData.cameraId)?.name
      : "";

  return (
    <>
      <Grid item container xs={6} spacing={2}>
        <Grid item xs={12}>
          {edit && selectedTileData ? (
            <TextInput
              name="x"
              label={t("x")}
              type="number"
              value={selectedTileData.x}
              variant="outlined"
              onChange={(e) => {
                setSelectedTile({
                  ...selectedTileData,
                  x: Number(e.target.value),
                });
              }}
            />
          ) : (
            <Typography variant="body">
              {t("x") + ": " + tileData.x + " px"}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {edit && selectedTileData ? (
            <TextInput
              name="y"
              label={t("y")}
              type="number"
              value={selectedTileData.y}
              variant="outlined"
              onChange={(e) => {
                setSelectedTile({
                  ...selectedTileData,
                  y: Number(e.target.value),
                });
              }}
            />
          ) : (
            <Typography variant="body">
              {t("y") + ": " + tileData.y + " px"}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={6} spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          {edit && selectedTileData ? (
            <CustomSelect
              value={selectedTileData.cameraId}
              options={
                cameras
                  ? cameras.map((camera) => ({
                      value: camera.id,
                      label: camera.name,
                    }))
                  : []
              }
              label={t("camera")}
              name="cameraId"
              onChange={(e) => {
                setSelectedTile({
                  ...selectedTileData,
                  cameraId: e.target.value,
                });
              }}
            />
          ) : (
            cameraName && (
              <Typography variant="body">
                {t("camera") + ": " + cameraName}
              </Typography>
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TileMenuItem;
