import { Image, Line, Rect, Text, Group } from "react-konva";
import { useImage } from "react-konva-utils";

import DoorFrontIcon from "@mui/icons-material/DoorFront";
import PanoramaOutlinedIcon from "@mui/icons-material/PanoramaOutlined";
import RectangleOutlinedIcon from "@mui/icons-material/RectangleOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import TitleIcon from "@mui/icons-material/Title";
import VideocamIcon from '@mui/icons-material/Videocam';

import DoorTile from "./DoorTile";
import CameraTile from "./CameraTile";

const SHAPES = {
  Image: Image,
  Polygon: Line,
  Rect: Rect,
  Text: Text,
  Door: DoorTile,
  Camera: CameraTile,
};

export const TileIcons = {
  Image: <PanoramaOutlinedIcon />,
  Rect: <RectangleOutlinedIcon />,
  Polygon: <TimelineIcon />,
  Text: <TitleIcon />,
  Door: <DoorFrontIcon />,
  Camera: <VideocamIcon />,
};

const Tile = (props) => {
  const {
    closed,
    fill,
    onTransformEnd,
    points,
    stroke,
    strokeWidth,
    tile,
    tileRef,
    openDoor,
  } = props;

  let imageURL = tile.image;

  let [image] = useImage(imageURL);

  const Shape = SHAPES[tile.shape];

  // Convert id to string to mute Konva warning -_-'
  tile.id = tile.id.toString();

  return (
    <Shape
      ref={tileRef}
      key={tile.id}
      {...tile}
      {...props}
      stroke={stroke}
      strokeWidth={strokeWidth}
      fill={fill}
      image={image}
      closed={closed}
      points={points}
      openDoor={openDoor}
    />
  );
};

export default Tile;
