import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function TransferAccountList({
  transferAccounts,
  deleteTransferAccount,
}) {
  const [t] = useTranslation("settings");

  return (
    <Grid container spacing={3}>
      <Grid item>
        <List component="div">
          {transferAccounts?.map((transferAccount) => (
            <ListItem>
              <Card variant="outlined">
                <CardContent>
                  <Grid container item spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body" sx={{ fontWeight: "bold" }}>
                        Account name:
                      </Typography>
                      <Typography variant="body">
                        {" "}
                        {transferAccount?.accountName}
                      </Typography>
                      <Grid item xs={12}></Grid>
                      <Typography variant="body" sx={{ fontWeight: "bold" }}>
                        Debtor name:
                      </Typography>
                      <Typography variant="body">
                        {" "}
                        {transferAccount?.debtorName}
                      </Typography>
                      <Grid item xs={12}></Grid>
                      <Typography variant="body" sx={{ fontWeight: "bold" }}>
                        NIF/CIF:
                      </Typography>
                      <Typography variant="body">
                        {" "}
                        {transferAccount?.NIF}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body" sx={{ fontWeight: "bold" }}>
                        IBAN:
                      </Typography>
                      <Typography variant="body">
                        {" "}
                        {transferAccount?.IBAN}
                      </Typography>
                      <Grid item xs={12}></Grid>
                      <Typography variant="body" sx={{ fontWeight: "bold" }}>
                        BIC:
                      </Typography>
                      <Typography variant="body">
                        {" "}
                        {transferAccount?.BIC}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="flex-end">
                    <IconButton
                      onClick={() => deleteTransferAccount(transferAccount) }
                    >
                      <DeleteForeverIcon color="error" />
                    </IconButton>
                  </Grid>
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
