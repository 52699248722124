import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  ListSubheader,
  Select as MUISelect,
  MenuItem,
  Popper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import TextInput from "../../Inputs/TextInput";
import { Fragment, useRef } from "react";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

const SELECT_ALL_ID = "selectAll";

const Select = (props) => {
  const {
    autoWidth = false,
    disabled = false,
    error = false,
    grouping = false,
    helperText,
    label,
    loading = false,
    multiple = false,
    name,
    onChange,
    options,
    required = false,
    searchable = false,
    selectAllGroup = false,
    value,
  } = props;

  if (multiple && !Array.isArray(value)) {
    throw new Error("Value must be an array when multiple is true");
  }

  if (selectAllGroup && (!grouping || !multiple)) {
    throw new Error(
      "Select all group can only be used with grouping and multiple"
    );
  }

  const [t] = useTranslation("others");

  const selectAllGroupClicked = useRef(false);

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"));

  let searchableOptions = [];
  let searchableValue = null;
  if (searchable) {
    if (multiple)
      searchableOptions = [{ label: t("selectAll"), id: SELECT_ALL_ID }];
    searchableOptions = searchableOptions.concat(
      options.flatMap((optionOrGroup) =>
        grouping
          ? optionOrGroup.options.map((option) => ({
              label: option.label,
              id: option.value,
              disabled: option.disabled || false,
              group: optionOrGroup.label,
            }))
          : {
              label: optionOrGroup.label,
              id: optionOrGroup.value,
              disabled: optionOrGroup.disabled || false,
            }
      )
    );
    searchableValue = multiple
      ? searchableOptions.filter((option) => value.includes(option.id))
      : searchableOptions.find((option) => option.id === value);
  }

  const getSelectWidth = () =>
    matchesXS || autoWidth ? (searchable ? "auto" : "100%") : 240;

  const handleAutocompleteChange = (e, newValue) => {
    onChange({
      target: {
        name,
        value: newValue
          ? multiple
            ? newValue.map((option) => option.id)
            : newValue.id
          : multiple
          ? []
          : "",
      },
    });
  };

  const handleGroupClick = (event, groupOptions) => {
    selectAllGroupClicked.current = true;
    const currentValues = [...value];
    const groupValues = groupOptions.options
      .filter((option) => !option.disabled)
      .map((option) => option.value);
    const allSelected = groupValues.every((val) => currentValues.includes(val));

    onChange({
      target: {
        name,
        value: allSelected
          ? currentValues.filter((val) => !groupValues.includes(val))
          : Array.from(new Set([...currentValues, ...groupValues])),
      },
    });
  };

  const filterAutocompleteOptions = (options, { inputValue }) =>
    options.filter(
      (option) =>
        option.label?.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.group?.toLowerCase().includes(inputValue.toLowerCase())
    );

  const renderTags = (value, getTagProps) => {
    let limitTags = 1;
    const tagWidth = 90;
    return value.map(
      (option, index) =>
        index < limitTags && (
          <Fragment key={index}>
            <Chip
              label={option.label}
              {...getTagProps({ index })}
              size="small"
              sx={{
                width: tagWidth,
              }}
            />
            {value.length > limitTags && index === limitTags - 1 && (
              <Typography variant="body1">{`+${
                value.length - limitTags
              }`}</Typography>
            )}
          </Fragment>
        )
    );
  };

  const renderSelectGroup = (groupOptions) => {
    const items = groupOptions.options.map((option) => renderMenuItem(option));
    const groupValues = groupOptions.options
      .filter((option) => !option.disabled)
      .map((option) => option.value);
    const allSelected = groupValues.every((val) => value?.includes(val));

    return [
      <GroupMenuItem
        groupOptions={groupOptions}
        handleGroupClick={handleGroupClick}
        allSelected={allSelected}
        groupLabel={groupOptions.label}
        selectable={selectAllGroup}
      />,
      items,
    ];
  };

  const renderMenuItem = (option) => (
    <MenuItem
      key={option.value}
      value={option.value}
      disabled={option.disabled}
      sx={{
        marginLeft: grouping && !multiple ? 1 : 0,
      }}
    >
      {multiple && <Checkbox checked={value.indexOf(option.value) > -1} />}
      {option.label}
    </MenuItem>
  );

  const renderPopper = (props, children) => (
    <Popper
      {...props}
      open
      style={{ minWidth: getSelectWidth(), width: "fit-content" }}
    >
      {children}
    </Popper>
  );

  const renderLabelMultipleSelect = (selected) =>
    options
      .flatMap((optionOrGroup) =>
        grouping ? optionOrGroup.options : [optionOrGroup]
      )
      .filter((option) => selected.indexOf(option.value) > -1)
      .map((option) => option.label)
      .join(", ");

  const renderSelectOptions = () => {
    if (loading) return <MenuItem disabled>{t("loading") + "..."}</MenuItem>;

    const SelectAllMenuItem = () => {
      const flatOptions = options.flatMap((optionOrGroup) =>
        grouping ? optionOrGroup.options : [optionOrGroup]
      );

      const allSelected = flatOptions
        .filter((option) => !option.disabled)
        .every((option) => value?.includes(option.value));

      const handleSelectAllClick = () => {
        onChange({
          target: {
            name,
            value: allSelected
              ? []
              : flatOptions
                  .filter((option) => !option.disabled)
                  .map((option) => option.value),
          },
        });
      };

      return (
        <MenuItem
          key={SELECT_ALL_ID}
          value={SELECT_ALL_ID}
          onClick={handleSelectAllClick}
          divider
          disableGutters={grouping}
        >
          <Checkbox checked={allSelected} />
          {t("selectAll")}
        </MenuItem>
      );
    };

    if (grouping)
      return [
        multiple ? <SelectAllMenuItem key={SELECT_ALL_ID} /> : undefined,
        ...options.map((groupOptions) => renderSelectGroup(groupOptions)),
      ];

    return [
      multiple ? <SelectAllMenuItem key={SELECT_ALL_ID} /> : undefined,
      ...options.map((option) => renderMenuItem(option)),
    ];
  };

  const renderAutocompleteTextInput = (params) => (
    <TextInput
      {...params}
      label={label}
      error={error}
      helperText={helperText}
      required={required}
      InputLabelProps={{
        sx: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          paddingRight: 4,
        },
      }}
    />
  );

  const renderAutocompleteOption = (props, option, { selected }) => {
    const isSelectAllOption = option.id === SELECT_ALL_ID;
    const allSelected = searchableOptions
      ?.filter((option) => !option.disabled && option.id !== SELECT_ALL_ID)
      ?.every((option) => value?.includes(option.id));

    const handleSelectAllClick = (e) => {
      e.stopPropagation();
      onChange({
        target: {
          name,
          value: allSelected
            ? []
            : searchableOptions
                .filter(
                  (option) => !option.disabled && option.id !== SELECT_ALL_ID
                )
                .map((option) => option.id),
        },
      });
    };

    return (
      <MenuItem
        disabled={option.disabled}
        sx={{
          marginLeft: grouping && !multiple ? 1 : 0,
        }}
        {...props}
        divider={isSelectAllOption}
        onClick={
          isSelectAllOption ? (e) => handleSelectAllClick(e) : props.onClick
        }
      >
        {multiple && (
          <Checkbox checked={selected || (isSelectAllOption && allSelected)} />
        )}
        {option.label}
      </MenuItem>
    );
  };

  const renderAutocompleteGroup = (params) => {
    const groupOptionsFlat = searchableOptions.filter(
      (option) => option.group === params.group && option.id !== SELECT_ALL_ID
    );
    const groupOptions = {
      label: params.group,
      options: groupOptionsFlat.map((option) => ({
        label: option.label,
        value: option.id,
        disabled: option.disabled,
      })),
    };
    const allInGroupSelected = groupOptions.options
      .filter((option) => !option.disabled)
      .every((option) => value?.includes(option.value));

    return (
      <li key={params.key}>
        <GroupMenuItem
          groupOptions={groupOptions}
          handleGroupClick={handleGroupClick}
          allSelected={allInGroupSelected}
          groupLabel={params.group}
          selectable={selectAllGroup}
        />
        {params.children}
      </li>
    );
  };

  const handleOnChange = (e) => {
    // When the group Menu Item is clicked, it fires an event to the parent
    // that is not avoidable with preventDefault or stopPropagation, this
    // is a hacky solution to avoid the onChange being called twice
    if (selectAllGroupClicked.current) {
      selectAllGroupClicked.current = false;
      return;
    }
    onChange(e);
  };

  const selectStyle = {
    width: getSelectWidth(),
    minWidth: 200,
  };

  return searchable ? (
    <Autocomplete
      disablePortal
      size="small"
      multiple={multiple}
      disableCloseOnSelect={multiple}
      options={searchableOptions}
      sx={selectStyle}
      PopperComponent={({ children, ...props }) =>
        renderPopper(props, children)
      }
      groupBy={grouping ? (option) => option.group : undefined}
      renderGroup={grouping ? renderAutocompleteGroup : undefined}
      filterOptions={grouping ? filterAutocompleteOptions : undefined}
      openOnFocus={true}
      renderTags={renderTags}
      renderInput={renderAutocompleteTextInput}
      renderOption={renderAutocompleteOption}
      value={searchableValue || null}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      onChange={handleAutocompleteChange}
      loading={loading}
      loadingText={t("loading") + "..."}
      disabled={disabled}
    />
  ) : (
    <FormControl
      size="small"
      disabled={disabled}
      error={error}
      required={required}
      sx={selectStyle}
    >
      <InputLabel>{label}</InputLabel>
      <MUISelect
        label={label}
        multiple={multiple}
        onChange={handleOnChange}
        value={value}
        name={name}
        renderValue={multiple ? renderLabelMultipleSelect : undefined}
      >
        {renderSelectOptions()}
      </MUISelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

const GroupMenuItem = ({
  groupOptions,
  handleGroupClick,
  allSelected,
  groupLabel,
  selectable,
}) => {
  return selectable && groupLabel ? (
    <MenuItem
      disableGutters
      key={groupLabel}
      onClick={(e) => handleGroupClick(e, groupOptions)}
    >
      <Checkbox checked={allSelected} />
      <ListItemText
        primary={groupLabel}
        primaryTypographyProps={{
          variant: "body2",
          sx: {
            fontStyle: "italic",
          },
        }}
      />
    </MenuItem>
  ) : (
    <ListSubheader
      sx={{
        fontStyle: "italic",
      }}
    >
      {groupLabel}
    </ListSubheader>
  );
};

Select.propTypes = {
  autoWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  grouping: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  searchable: PropTypes.bool,
  selectAllGroup: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Select;
