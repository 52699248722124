import React, { useContext, useEffect, useReducer } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import AppContext from "../../../context/AppContext";
import Button from "../../Inputs/CustomButton";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import SearchButton from "../../Inputs/SearchButton";
import { useSnackbar } from "notistack";
import { localeFormat } from "../../../utils/format";
import ExpenseTypeSelect from "../../Inputs/ExpenseTypeSelect";

const initialState = {
  providerInvoices: [],
  providers: [],
  expenseTypes: [],
  transferAccounts: [],
  filters: {
    isPaid: "pending",
    isAuthorized: true,
    dateFrom: "",
    dateUntil: "",
    provider: [],
    expenseType: [],
    paymentMethodId: 3,
  },
  options: {
    rowlink: "provider-invoice",
    loaded: true,
  },
  selectedInvoices: [],
  selectedAccount: null,
  transferPaymentGroupId: null,
  paymentMethods: [],
  creating: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_PROVIDER_INVOICES":
      return { ...state, providerInvoices: action.payload };
    case "SET_PROVIDERS":
      return { ...state, providers: action.payload };
    case "SET_TRANSFER_ACCOUNTS":
      return { ...state, transferAccounts: action.payload };
    case "SET_EXPENSE_TYPES":
      return { ...state, expenseTypes: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_SELECTED_INVOICES":
      return { ...state, selectedInvoices: action.payload };
    case "SET_SELECTED_ACCOUNT":
      return { ...state, selectedAccount: action.payload };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "SET_CREATING_TRUE":
      return { ...state, creating: true };
    case "SET_CREATING_FALSE":
      return { ...state, creating: false };
    default:
      throw new Error("Action not found in reducer");
  }
}

const TransferencesDialog = (props) => {
  const { api } = useContext(AppContext);
  const { isOpen, onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("providerInvoices");
  const [tErrors] = useTranslation("errors");

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getProviders();
    getTransferAccounts();
    getExpenseTypes();
    getPaymentMethods();
  }, []);

  const getProviders = () => {
    api
      .get("/providers")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length == 0)
            enqueueSnackbar(t("noProviders"), { variant: "warning" });
          dispatch({
            type: "SET_PROVIDERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  const getTransferAccounts = () => {
    api
      .get("/transfer-accounts")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_TRANSFER_ACCOUNTS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getExpenseTypes = () => {
    api
      .get("/expense-types")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_EXPENSE_TYPES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/providers/payment-methods")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length == 0)
            enqueueSnackbar(t("noPaymentMethods"), { variant: "warning" });
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getProviderInvoices = () => {
    dispatch({ type: "SET_LOADED_FALSE" });
    dispatch({ type: "SET_PROVIDER_INVOICES", payload: [] });

    let filters = {
      include: ["Provider"],
    };

    state.filters.dateFrom !== "" &&
      (filters.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil !== "" &&
      (filters.dateUntil = state.filters.dateUntil);
    state.filters.isPaid !== "" && (filters.isPaid = state.filters.isPaid);
    state.filters.isAuthorized !== "" &&
      (filters.isAuthorized = state.filters.isAuthorized);
    state.filters.provider !== "" &&
      (filters.provider = state.filters.provider);
    state.filters.expenseType.length > 0 &&
      (filters.expenseType = state.filters.expenseType);
    state.filters.paymentMethodId !== "" &&
      (filters.paymentMethodId = state.filters.paymentMethodId);

    api
      .get("/provider-invoices", {
        params: filters,
      })
      .then((response) => {
        if (response.data.error) {
        } else {
          if (response.data.length == 0)
            enqueueSnackbar(t("noProviderInvoices"), { variant: "warning" });
          response.data.forEach((providerInvoice) => {
            providerInvoice.expenseTypeName = providerInvoice.ExpenseType
              ? providerInvoice.ExpenseType.name
              : "";
          });
          dispatch({ type: "SET_PROVIDER_INVOICES", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  const validateTransfer = (invoices, account) => {
    const allAuthorized = invoices.every(
      (invoice) => invoice.isAuthorized === true
    );
    if (!allAuthorized) {
      enqueueSnackbar(t("invoicesNotAuthorized"), { variant: "warning" });
      return false;
    }

    const allHaveProvider = invoices.every(
      (invoice) => invoice.Provider !== null
    );

    if (!allHaveProvider) {
      enqueueSnackbar(t("invoicesNoProvider"), { variant: "warning" });
      return false;
    } else {
      const allHaveIBAN = invoices.every(
        (invoice) => invoice.Provider.defaultIBAN !== null
      );
      if (!allHaveIBAN) {
        enqueueSnackbar(t("invoicesNoIban"), { variant: "warning" });
        return false;
      }
    }

    const allTotalsArePositive = invoices.every(
      (invoice) => invoice.total >= 0
    );
    if (!allTotalsArePositive) {
      enqueueSnackbar(t("negativeInvoicesWarning"), { variant: "warning" });
      return false;
    }

    if (!account) {
      enqueueSnackbar(t("selectDebtor"), { variant: "warning" });
      return false;
    }

    const allInvoicesAreUnblocked = invoices.every(
      (invoice) => invoice.Provider.blocked === false
    );
    if (!allInvoicesAreUnblocked) {
      enqueueSnackbar(t("invoiceProviderBlockedWarning"), {
        variant: "warning",
      });
      return false;
    }

    return true;
  };

  const createTransfer = () => {
    // Get selected invoices
    const invoices = state.providerInvoices.filter((invoice) =>
      state.selectedInvoices.includes(invoice.id)
    );

    // Get transfer account
    const account = state.transferAccounts.find(
      (value) => state.selectedAccount === value.id
    );

    // Validate that all the data is set correctly
    if (validateTransfer(invoices, account)) {
      // Convert each invoice into Transfer format
      const transferPayments = invoices.map((invoice) => {
        const transfer = {
          providerInvoiceId: invoice.id,
          providerBlocked: invoice.Provider.blocked,
          creditorName: invoice.Provider.businessName,
          creditorIBAN: invoice.Provider.defaultIBAN.number,
          signatureDate: new Date(),
          amount: invoice.total,
          concept: invoice.invoiceNumber + " - " + invoice.title,
        };
        return transfer;
      });

      // Format data to send to back-end
      const data = {
        date: new Date(),
        debtorId: account.debtorId,
        debtorNIF: account.NIF,
        debtorName: account.debtorName,
        debtorIBAN: account.IBAN,
        invoices: transferPayments,
      };

      dispatch({ type: "SET_CREATING_TRUE" });
      api
        .post("/transfer-payments/create", data)
        .then((response) => {
          if (response.data.error) {
            console.error(response.data.msg);
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("transferenceCreateSuccess"), {
              variant: "success",
            });
            closeDialogHandler();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, { variant: "error" });
          console.log(error);
        })
        .finally(() => {
          dispatch({ type: "SET_CREATING_FALSE" });
        });
    }
  };

  const TABLE_COLUMNS = [
    { field: "title", headerName: t("title"), flex: 1 },
    { field: "date", headerName: t("date") },
    { field: "paidAt", headerName: t("paidAt") },
    { field: "invoiceNumber", headerName: t("invoiceNumber") },
    {
      field: "amount",
      headerName: t("amount"),
      valueGetter: ({ row }) => row.amount + row.vat - row.personalIncomeTax,
      valueFormatter: ({ value }) => localeFormat(value) + "€",
    },
    {
      field: "expenseTypeName",
      headerName: t("expenseType"),
    },
    {
      field: "Provider",
      headerName: t("provider"),
      flex: 0.5,
      valueGetter: ({ row }) => row.Provider?.businessName,
    },
    {
      field: "iban",
      headerName: t("iban"),
      valueGetter: ({ row }) => row.Provider?.defaultIBAN?.number,
    },
    {
      field: "paymentMethodId",
      headerName: t("paymentMethod"),
      valueFormatter: ({ value }) =>
        t(state.paymentMethods.filter((method) => method.id == value)[0]?.name),
    },
    {
      field: "isAuthorized",
      headerName: t("authorized"),
      valueFormatter: ({ value }) => (value ? t("yes") : t("no")),
    },
  ];

  const selectedInvoices = (ids) => {
    dispatch({ type: "SET_SELECTED_INVOICES", payload: ids });
  };

  const handlerFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const closeDialogHandler = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_PROVIDER_INVOICES", payload: [] });
    dispatch({ type: "SET_SELECTED_ACCOUNT", payload: [] });

    // If onClose callback for the dialog is passed, execute it
    onClose && onClose(state.selectedInvoices);
  };

  return (
    <Dialog open={isOpen} onClose={closeDialogHandler} maxWidth="xl">
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>{t("transferences")}</Grid>
          <Grid item>
            <IconButton onClick={closeDialogHandler}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent style={{ paddingTop: "10px" }}>
        <Grid container spacing={1}>
          <Grid item container spacing={1}>
            <Grid item>
              <CustomSelect
                name="isPaid"
                label={t("isPaid")}
                value={state.filters.isPaid}
                onChange={handlerFilterChange}
                options={[
                  { label: t("all"), value: "" },
                  { label: t("paid"), value: "payed" },
                  { label: t("pending"), value: "pending" },
                ]}
              />
            </Grid>

            <Grid item>
              <CustomSelect
                name="isAuthorized"
                label={t("authorized")}
                value={state.filters.isAuthorized}
                onChange={handlerFilterChange}
                options={[
                  { label: t("all"), value: "" },
                  { label: t("authorized"), value: true },
                  { label: t("nonAuthorized"), value: false },
                ]}
              />
            </Grid>

            <Grid item xs>
              <CustomSelect
                multiple
                options={state.providers
                  .sort((a, b) => a.brand.localeCompare(b.brand))
                  .map((provider) => ({
                    label: provider.brand,
                    value: provider.id,
                  }))}
                label={t("providers")}
                onChange={handlerFilterChange}
                name="provider"
                value={state.filters.provider}
              />
            </Grid>

            <Grid item xs>
              <ExpenseTypeSelect
                label={t("expenseTypes")}
                onChange={handlerFilterChange}
                name="expenseType"
                value={state.filters.expenseType}
                multiple
                sx={{ maxWidth: "300px" }}
              />
            </Grid>

            <Grid item>
              <CustomSelect
                name="paymentMethodId"
                label={t("paymentMethod")}
                value={state.filters.paymentMethodId}
                onChange={handlerFilterChange}
                options={[
                  { value: "", label: t("all") },
                  ...state.paymentMethods.map((method) => ({
                    label: t(method.name),
                    value: method.id,
                  })),
                ]}
              />
            </Grid>

            <Grid item>
              <CustomDate
                value={state.filters.dateFrom}
                name="dateFrom"
                onChange={handlerFilterChange}
                label={t("dateFrom")}
              />
            </Grid>

            <Grid item>
              <CustomDate
                value={state.filters.dateUntil}
                name="dateUntil"
                onChange={handlerFilterChange}
                label={t("dateUntil")}
              />
            </Grid>

            <Grid item>
              <SearchButton
                onClick={getProviderInvoices}
                loading={!state.options.loaded}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <DataGrid
              sx={{ minHeight: "400px" }}
              columns={TABLE_COLUMNS}
              rows={state.providerInvoices}
              checkboxSelection
              selectionModel={state.selectedInvoices}
              loading={!state.options.loaded}
              onSelectionModelChange={(ids) => {
                selectedInvoices(ids);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            paddingLeft: "20px",
          }}
        >
          <Grid item>
            <Typography variant="h6">
              {t("total") + ": "}
              {Number(
                state.providerInvoices
                  .filter((invoice) =>
                    state.selectedInvoices.includes(invoice.id)
                  )
                  .reduce(
                    (sum, invoice) =>
                      sum +
                      (invoice.amount +
                        invoice.vat -
                        invoice.personalIncomeTax),
                    0
                  )
                  .toFixed(2)
              ).toLocaleString()}
              {" €"}
            </Typography>
          </Grid>
          <Grid item>
            <Grid item display="flex" container spacing={1}>
              <Grid item>
                <CustomSelect
                  label={t("debtor")}
                  value={state.selectedAccount}
                  onChange={(e) => {
                    dispatch({
                      type: "SET_SELECTED_ACCOUNT",
                      payload: e.target.value,
                    });
                  }}
                  name="debtor"
                  options={state.transferAccounts.map((account) => ({
                    value: account.id,
                    label: account.accountName,
                  }))}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={createTransfer}
                  disabled={state.selectedInvoices.length === 0}
                  loading={state.creating}
                >
                  {t("create")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default TransferencesDialog;
