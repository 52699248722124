import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const palette = {
  Taquilla: {
    backgroundColor: "#FFFFFF",
    color: "black",
  },
  "Trastero Pequeño": {
    backgroundColor: "#E8CA4D",
    color: "black",
  },
  "Trastero Mediano": {
    backgroundColor: "#CC6335",
    color: "white",
  },
  "Trastero Grande": {
    backgroundColor: "#5ABF5D",
    color: "black",
  },
  "Trastero XL": {
    backgroundColor: "#6750BF",
    color: "white",
  },
  Almacén: {
    backgroundColor: "#454545",
    color: "white",
  },
  Default: {
    backgroundColor: "#D3D3D3",
    color: "black",
  },
};

const BoxClusterChip = (props) => {
  const { name, ...rest } = props;
  const [t] = useTranslation("boxes");
  let text, backgroundColor, textColor;

  const colorPalette = palette[name] || palette.Default;

  text = t(name);
  backgroundColor = colorPalette.backgroundColor;
  textColor = colorPalette.color;

  return (
    <Chip
      color="default"
      label={text}
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
      }}
      size="small"
      {...rest}
    />
  );
};

export default BoxClusterChip;
