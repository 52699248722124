import { useSnackbar } from "notistack";
import { useReducer, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Material UI
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { TreeView } from "@mui/lab";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { faFolder, faFolderOpen } from "@fortawesome/free-regular-svg-icons";

// Components & Utils
import AppContext from "../../../context/AppContext";
import ExpenseTypeTreeItem from "./ExpenseTypeTreeItem";
import ConfirmDialog from "../../ConfirmDialog";
import TextInput from "../../Inputs/TextInput";
import CustomSelect from "../../Inputs/CustomSelect";

const initialState = {
  confirmDialog: {
    title: "",
    confirmText: "",
    cancelText: "",
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
  createExpenseTypeDialogIsOpen: false,
  createExpenseTypeForm: {
    name: "",
    parentId: null,
    description: "",
  },
  expenseTypesDirectories: [],
  expenseTypesTree: [],
  loaded: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CLOSE_CREATE_EXPENSE_TYPE_DIALOG":
      return {
        ...state,
        createExpenseTypeDialogIsOpen: false,
      };
    case "OPEN_CREATE_EXPENSE_TYPE_DIALOG":
      return {
        ...state,
        createExpenseTypeDialogIsOpen: true,
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "RESET_CREATE_EXPENSE_TYPE_FORM":
      return {
        ...state,
        createExpenseTypeForm: initialState.createExpenseTypeForm,
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "SET_INPUT":
      return {
        ...state,
        createExpenseTypeForm: {
          ...state.createExpenseTypeForm,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_EXPENSE_TYPES_DIRECTORIES":
      return { ...state, expenseTypesDirectories: action.payload };
    case "SET_EXPENSE_TYPES_TREE":
      return { ...state, expenseTypesTree: action.payload };
    case "SET_LOADED":
      return { ...state, loaded: action.payload };
    default:
      throw new Error("Action not found in reducer");
  }
};

const ExpenseTypesPage = () => {
  const { api, user } = useContext(AppContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("expenseTypes");

  const [state, dispatch] = useReducer(reducer, initialState);

  //Initial useEffect
  useEffect(() => {
    document.title = t("expenseTypes");

    getExpenseTypesStructure();
  }, []);

  useEffect(() => {
    handleSetExpenseTypesDirectories();
  }, [state.expenseTypesTree]);

  const hasAction = (action) => {
    let isFound = false;
    user?.Role.Actions.forEach((item) => {
      if (item.id === action) {
        isFound = true;
      }
    });
    return isFound;
  };

  /* BACKEND CALLS */

  const createExpenseType = () => {
    let form = state.createExpenseTypeForm;
    if (form.parentId === "") form.parentId = null;

    if (form.name === "") {
      enqueueSnackbar(`${t("name")} ${t("mustNotBeEmpty").toLowerCase()}`, {
        variant: "warning",
      });
      return;
    }
    api
      .post("/expense-types/create", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            `${t("expenseType")} ${form.name} ${t(
              "createdSuccessfully"
            ).toLowerCase()}`,
            {
              variant: "success",
            }
          );
          closeCreateExpenseTypeDialog();
          getExpenseTypesStructure();
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const deleteExpenseType = (expenseType) => {
    api
      .delete("/expense-types/" + expenseType.id)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(
            `${t("expenseType")} ${response.data.name} ${t(
              "deletedSuccessfully"
            ).toLowerCase()}`,
            {
              variant: "success",
            }
          );
          getExpenseTypesStructure();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const getExpenseTypesStructure = () => {
    dispatch({ type: "SET_LOADED", payload: false });
    api
      .get("/expense-types-structure", {
        params: { include: ["ProviderInvoice"] },
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_EXPENSE_TYPES_TREE",
            payload: response.data,
          });
        }
        dispatch({ type: "SET_LOADED", payload: true });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  /* HANDLERS */

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: e.target.name, value: e.target.value },
    });
  };

  const handleEditExpenseType = (expenseTypeId) => {
    history.push("/app/expense-type/" + expenseTypeId);
  };

  const handleSetExpenseTypesDirectories = () => {
    let directories = [];
    state.expenseTypesTree?.length !== 0 &&
      (directories = getExpenseTypeDirectories(state.expenseTypesTree));
    dispatch({
      type: "SET_EXPENSE_TYPES_DIRECTORIES",
      payload: directories,
    });
  };

  const getExpenseTypeDirectories = (expenseTypes) => {
    let directories = [];
    expenseTypes.forEach((expenseType) => {
      if (expenseType.children.length !== 0) {
        directories.push({
          id: expenseType.id,
          name: expenseType.name,
        });
        directories = directories.concat(
          getExpenseTypeDirectories(expenseType.children)
        );
      }
    });
    return directories;
  };

  // Confirm dialog
  const openConfirmDeleteExpenseType = (expenseType) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteExpenseTypeQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: expenseType.name,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteExpenseType(expenseType);
          resetConfirmDialog();
        },
      },
    });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  // Expense type dialog
  const openCreateExpenseTypeDialog = (expenseTypeId) => {
    !isNaN(expenseTypeId) &&
      dispatch({
        type: "SET_INPUT",
        payload: { inputname: "parentId", value: expenseTypeId },
      });
    dispatch({ type: "OPEN_CREATE_EXPENSE_TYPE_DIALOG" });
  };

  const closeCreateExpenseTypeDialog = () => {
    dispatch({ type: "RESET_CREATE_EXPENSE_TYPE_FORM" });
    dispatch({ type: "CLOSE_CREATE_EXPENSE_TYPE_DIALOG" });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">{t("expenseTypes")}</Typography>
        </Grid>
        {hasAction("CREATE_EXPENSE_TYPES") && (
          <Grid item container>
            <Grid item>
              <Button
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={openCreateExpenseTypeDialog}
              >
                {t("create") + " " + t("expenseType")}
              </Button>
            </Grid>
          </Grid>
        )}
        {state.loaded ? (
          <Grid item xs={12}>
            {hasAction("VIEW_EXPENSE_TYPES") &&
              state.expenseTypesTree.map((expenseType) => (
                <TreeView
                  defaultCollapseIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                  defaultExpandIcon={<FontAwesomeIcon icon={faFolder} />}
                >
                  <ExpenseTypeTreeItem
                    expenseType={expenseType}
                    key={expenseType.id}
                    nodeId={"expense-type" + expenseType.id}
                    onNewExpenseType={openCreateExpenseTypeDialog}
                    onDeleteExpenseType={openConfirmDeleteExpenseType}
                    onEditExpenseType={handleEditExpenseType}
                  />
                </TreeView>
              ))}
          </Grid>
        ) : (
          <Grid item container spacing={1}>
            {[...Array(15)].map((_) => (
              <Grid item xs={12}>
                <Skeleton variant="rounded" width="100%" height={40} />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      {/* Create expense type dialog */}
      <Dialog
        open={state.createExpenseTypeDialogIsOpen}
        onClose={closeCreateExpenseTypeDialog}
      >
        <DialogTitle>
          {t("create") + " " + t("expenseType").toLowerCase()}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs>
                <TextInput
                  label={t("name")}
                  value={state.createExpenseTypeForm.name}
                  onChange={handleInputChange}
                  name="name"
                  sx={{ minWidth: 200 }}
                />
              </Grid>
              {state.createExpenseTypeForm.parentId === null && (
                <Grid item xs>
                  <CustomSelect
                    label={t("expenseType") + " " + t("parent").toLowerCase()}
                    value={state.createExpenseTypeForm.parentId}
                    onChange={handleInputChange}
                    name="parentId"
                    options={[
                      { value: null, label: t("none") },
                      ...state.expenseTypesDirectories?.map((expenseType) => ({
                        value: expenseType.id,
                        label: expenseType.name,
                      })),
                    ]}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextInput
                  label={t("description")}
                  value={state.createExpenseTypeForm.description}
                  onChange={handleInputChange}
                  name="description"
                  sx={{ minWidth: 200 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateExpenseTypeDialog}>{t("cancel")}</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={createExpenseType}
          >
            {t("create")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Dialog */}
      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        confirmText={state.confirmDialog.confirmText}
        cancelText={state.confirmDialog.cancelText}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
};

export default ExpenseTypesPage;
