import {
  Card,
  CardContent,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import AppContext from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';

export default function InvoiceSeriesList({
  invoiceSeries,
  deleter,
}) {
  const { user } = useContext(AppContext);
  const [t] = useTranslation("settings");
  const hasAction = (action) => {
    return user?.Role.Actions.some((item) => item.id === action) || false;
  };

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item>
          <List>
            {invoiceSeries.map((serie) => (
              <ListItem>
                <Grid item container spacing={1}>
                  <ListItemText primary={serie.name} />
                  <Grid item xs={1}>
                    {/* {hasAction("EDIT_INVOICE_SERIES") && (
                      <IconButton
                        style={{
                          position: "absolute",
                          top: "-3px",
                        }}
                        title={t("delete")}
                        onClick={() => {
                          deleter(serie.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )} */}
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
}
