import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import AppContext from "../../../context/AppContext";
import ConfirmDialog from "../../ConfirmDialog";
import Link from "../../Inputs/Link";
import TextInput from "../../Inputs/TextInput";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { CustomTable } from "../../CustomTable";

const initialState = {
  form: {
    Actions: [],
    Pages: [],
    color: "#ffffff",
    description: "",
    name: "",
  },
  confirmDialog: {
    title: "",
    confirmText: "",
    cancelText: "",
    childrenText: "",
    isOpen: false,
    callback: () => {},
  },
  users: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          childrenText: action.payload.childrenText,
          isOpen: action.payload.isOpen,
          callback: action.payload.callback,
        },
      };
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_ROLE":
      return { ...state, form: action.payload };
    case "SET_USERS":
      return { ...state, users: action.payload.users };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    default:
      throw new Error();
  }
}

export default function RolePage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("roles");
  const { id } = useParams();

  const userColumns = [
    { label: "Name", key: "name" },
    {
      label: t("state"),
      key: "active",
      sortType: "number",
      renderFunction: (value) =>
        value ? (
          <Chip label={t("active")} color="success" size="small" />
        ) : (
          <Chip label={t("inactive")} color="error" size="small" />
        ),
    },
    {
      label: "Imagen",
      key: "image",
      renderFunction: (value, item) => (
        <Avatar src={"http://localhost:8080/profileImages/" + item.id} />
      ),
    },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("rolePage");

    getRoles();
    getUsers();
  }, []);

  const getRoles = () => {
    let params = {
      include: ["Action", "Page"],
    };
    api
      .get("/roles/", { params })
      .then((response) => {
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, { variant: "error" });
        } else {
          dispatch({
            type: "SET_ROLE",
            payload: response.data.find((role) => role.id == id),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUsers = () => {
    api
      .get("/users")
      .then((response) => {
        response.data.error &&
          enqueueSnackbar(response.data.error, {
            variant: "error",
          });
        if (response.data.length > 0) {
          let users = response.data.filter(
            (user) => Number(user.roleId) === Number(id)
          );
          dispatch({
            type: "SET_USERS",
            payload: {
              users: users,
            },
          });
        } else {
          enqueueSnackbar(t("noUsers"), {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitForm = () => {
    api
      .post("/roles/edit/" + id, state.form)
      .then((response) => {
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(t("roleEditSuccess"), { variant: "success" });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const deleteRole = () => {
      api
      .delete("/roles/" + id)
      .then((response) => {
        if(response.data.error)
          enqueueSnackbar(response.data.error, {variant: "error",});
        else {
          enqueueSnackbar("Rol deleted", {variant: "success",});
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error, {variant: "error",});
      });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const openConfirmDelete = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        title: t("deleteRoleQuestion"),
        confirmText: t("confirm"),
        cancelText: t("cancel"),
        childrenText: t("role") + ": " +state.form.name,
        isOpen: true,
        callback: (confirmed) => {
          confirmed && deleteRole();
          resetConfirmDialog();
        },
      },
    });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item container xs={12} spacing={1}>
            <Grid item>
              <Typography variant="h4">
                {t("role") + "-" + state.form.name}
              </Typography>
            </Grid>
            <Grid item flex={1} justifyContent="flex-end" display="flex">
              {state.users.length === 0 && (
                <Tooltip title={t("delete")} placement="top">
                  <IconButton
                    variant="contained"
                    onClick={(e) => {openConfirmDelete();}}
                  >
                    <DeleteForeverIcon color="error"/>
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={t("createUser")} placement="top">
                <IconButton
                  color="primary"
                  component={Link}
                  to={"/app/user/create?roleId=" + id}
                >
                  <SupervisedUserCircleIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextInput
                name="name"
                label={t("name")}
                value={state.form.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="outlined">
                <input
                  type="color"
                  id="color"
                  name="color"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={state.form.color}
                />
              </FormControl>
            </Grid>
            <Grid item xs={7}>
              <TextInput
                multiline
                rows={4}
                name="description"
                label={t("desc")}
                value={state.form.description}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item container>
            <Grid item xs={12}>
              <Typography variant="h6">{t("users")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomTable columns={userColumns} data={state.users} options={{ rowlink: "user" }}/>
            </Grid>
          </Grid>

          <Grid item container spacing={2} justifyContent="space-between">
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{t("pages")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <List style={{ maxHeight: 500, overflow: "auto" }}>
                        {state.form.Pages.map((page) => (
                          <ListItem key={page.id}>{page.name}</ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{t("actions")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <List style={{ maxHeight: 500, overflow: "auto" }}>
                        {state.form.Actions.map((action) => (
                          <ListItem key={action.id}>{action.id}</ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={submitForm}>
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        confirmText={state.confirmDialog.confirmText}
        cancelText={state.confirmDialog.cancelText}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
}
