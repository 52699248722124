export const colors = {
  primary: "#b3d1ff",
  secondary: "#003d99",
  green: "#66ff66",
  red: "#ff8566",
};

export const randomColor = () =>
  "#" + Math.floor(Math.random() * 16777215).toString(16);

export const ColorGenerator = (color1, color2, numColors) => {
  let colorArr = [];

  //Check for hash
  if (color1[0] === "#") {
    color1 = color1.substring(1);
  }
  if (color2[0] === "#") {
    color2 = color2.substring(1);
  }

  //Convert hex color into decimal separated by red, green, blue
  let color1Arr = [
    parseInt(color1.substring(0, 2), 16),
    parseInt(color1.substring(2, 4), 16),
    parseInt(color1.substring(4), 16),
  ];

  let color2Arr = [
    parseInt(color2.substring(0, 2), 16),
    parseInt(color2.substring(2, 4), 16),
    parseInt(color2.substring(4), 16),
  ];

  //Get difference between colors with absolute value
  let diffArr = [];

  for (let i in color1Arr) {
    diffArr.push(color2Arr[i] - color1Arr[i]);
  }

  //Divide difference by number of colors
  let factors = [];

  for (let i in diffArr) {
    factors.push(diffArr[i] / numColors);
  }

  for (let i = 0; i < numColors; i++) {
    color1Arr = color1Arr.map((color, index) => {
      color += Math.round(factors[index]);
      if (color > 255) color = 255;
      if (color < 0) color = 0;
      return color;
    });

    let hexColors = color1Arr.map((color) => color.toString(16));

    hexColors = hexColors.map((color) =>
      color.length === 1 ? "0" + color : color
    );

    const color = "#" + hexColors[0] + hexColors[1] + hexColors[2];

    colorArr.push(color);
  }

  return colorArr;
};
