import axios from "axios";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import GetAppIcon from "@mui/icons-material/GetApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";

import ContractSignature from "./ContractSignature";
import { API_ROUTE } from "../../../utils/API";
import { openFile } from "../../../utils/file";
import {
  ADMIN_EMAIL,
  CALL_CENTER_PHONE_NUMBER,
  COMPANY_NAME,
  COMPANY_WEBSITE,
  NUT_ADDRESS,
} from "../../../data/constants";
import CustomButton from "../../Inputs/CustomButton";

function reducer(state, action) {
  switch (action.type) {
    case "SET_CONTRACT":
      return { ...state, contract: action.payload };
    case "SET_IS_LOADED":
      return { ...state, isLoaded: action.payload };
    case "SET_LOADING_CONTRACT":
      return { ...state, loadingContract: action.payload };
    default:
      throw new Error("Action not found in reducer");
  }
}

const initialState = {
  contract: null,
  isLoaded: false,
  loadingContract: false,
};

export default function ContractSignaturePage() {
  const { token } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("contracts");
  const [tErrors] = useTranslation("errors");

  useEffect(() => {
    getContract();
  }, []);

  const getContract = () => {
    dispatch({ type: "SET_IS_LOADED", payload: false });

    axios
      .get(API_ROUTE + "/sign-contract/" + token)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({ type: "SET_CONTRACT", payload: response.data });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_IS_LOADED", payload: true }));
  };

  const seeContractPdf = () => {
    if (!state.contract.id) return;
    dispatch({ type: "SET_LOADING_CONTRACT", payload: true });
    const params = { token: token };
    axios
      .get(API_ROUTE + "/sign-contract/contract/" + state.contract.id, {
        params,
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          openFile(response.data, "pdf");
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        console.log(error);
      })
      .finally(() =>
        dispatch({ type: "SET_LOADING_CONTRACT", payload: false })
      );
  };

  const uploadSignature = (uri) => {
    // Convert Konva uri to datablob
    var mime = uri.split(",")[0].split(":")[1].split(";")[0];
    var binary = atob(uri.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    let blob = new Blob([new Uint8Array(array)], { type: mime });

    let formData = new FormData();
    formData.append("digitalSignature", blob);

    axios
      .post(API_ROUTE + "/sign-contract/" + token, formData)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("contractSignedSuccessfully"), {
            variant: "success",
          });
          const updatedContract = {
            ...state.contract,
            hasDigitalSignature: true,
          };
          dispatch({ type: "SET_CONTRACT", payload: updatedContract });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          minHeight: "100vh",
          padding: 3,
        }}
      >
        <Link href={COMPANY_WEBSITE} target="_blank">
          <img
            src="/img/logo-negro-nut.png"
            alt="NUT"
            style={{ marginRight: "16px", height: "40px" }}
          />
        </Link>
        {state.isLoaded ? (
          <Grid container spacing={3} marginTop={2}>
            {!state.contract?.hasDigitalSignature && (
              <Grid item container justifyContent="center" spacing={2} xs={12}>
                <Grid item>
                  <Typography variant="h4">{t("digitalSignature")}</Typography>
                </Grid>
                {state.contract ? (
                  <>
                    <ContractInfoDisplay contract={state.contract} />
                    <CustomButton
                      startIcon={<GetAppIcon />}
                      onClick={seeContractPdf}
                      loading={state.loadingContract}
                    >
                      {t("downloadContract")}
                    </CustomButton>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                      {t("contractNotFound")}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            {state.contract?.hasDigitalSignature ? (
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  {t("contractSignedSuccessfully") +
                    ". " +
                    t("youCanCloseThisPage") +
                    "."}
                </Typography>
              </Grid>
            ) : (
              state.contract && (
                <Grid item xs={12}>
                  <ContractSignature
                    onSave={uploadSignature}
                    contractId={state.contract?.id}
                    token={state.contract?.digitalSignatureToken}
                  />
                </Grid>
              )
            )}
          </Grid>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="80vh"
          >
            <CircularProgress />
          </Box>
        )}
        <Footer />
      </Paper>
    </Container>
  );
}

function Footer() {
  return (
    <Grid container marginTop={10}>
      <Grid container spacing={1} marginTop={1} alignItems="center">
        <Grid item xs={12} md={6}>
          <IconButton>
            <EmailIcon />
          </IconButton>
          <Link href={"mailto:" + ADMIN_EMAIL} color="textSecondary">
            {ADMIN_EMAIL}
          </Link>
        </Grid>

        <Grid item xs={12} md={6}>
          <IconButton>
            <PhoneIcon />
          </IconButton>
          <Box style={{ display: "inline-block", marginLeft: "8px" }}>
            <Typography variant="body2" color="textSecondary">
              {CALL_CENTER_PHONE_NUMBER}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <IconButton>
            <LocationOnIcon />
          </IconButton>
          <Box style={{ display: "inline-block", marginLeft: "8px" }}>
            <Typography variant="body2" color="textSecondary">
              {NUT_ADDRESS}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="body2" color="textSecondary">
            © {new Date().getFullYear() + " " + COMPANY_NAME}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function ContractInfoDisplay({ contract }) {
  const [t] = useTranslation("contracts");
  return (
    <Grid item container marginTop={4} spacing={1}>
      <Grid item xs={12} md={6}>
        <Grid container item xs={12} alignItems="center" marginBottom={1}>
          <PlaceIcon color="primary" />
          <Box style={{ display: "inline-block", marginLeft: "8px" }}>
            <Typography variant="h5" fontWeight="bold">
              {t("location")}
            </Typography>
          </Box>
        </Grid>
        <Grid item container spacing={2} marginBottom={4}>
          {contract?.Box?.Center?.City?.name !== undefined && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                fontWeight="bold"
                // align="center"
              >
                {t("city") + ": "}
                <Typography
                  variant="body1"
                  component="span"
                  fontWeight="normal"
                >
                  {contract?.Box?.Center?.City?.name}
                </Typography>
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              // align="center"
            >
              {t("center") + ": "}
              <Typography variant="body1" component="span" fontWeight="normal">
                {contract?.Box?.Center?.name}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container item xs={12} alignItems="center" marginBottom={1}>
          <MeetingRoomIcon color="primary" />
          <Box style={{ display: "inline-block", marginLeft: "8px" }}>
            <Typography variant="h5" fontWeight="bold">
              {t("box")}
            </Typography>
          </Box>
        </Grid>
        <Grid item container spacing={2} marginBottom={4}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              // align="center"
            >
              {t("box") + ": "}
              <Typography variant="body1" component="span" fontWeight="normal">
                {contract?.Box?.name}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              // align="center"
            >
              {t("approximateMeters") + ": "}
              <Typography variant="body1" component="span" fontWeight="normal">
                {contract?.Box?.meters}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container item xs={12} alignItems="center" marginBottom={1}>
          <PersonIcon color="primary" />
          <Box style={{ display: "inline-block", marginLeft: "8px" }}>
            <Typography variant="h5" fontWeight="bold">
              {t("customer")}
            </Typography>
          </Box>
        </Grid>

        <Grid item container spacing={2} marginBottom={4}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              // align="center"
            >
              {t("name") + ": "}
              <Typography variant="body1" component="span" fontWeight="normal">
                {contract?.Customer?.name + " " + contract?.Customer?.surnames}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              // align="center"
            >
              {t("email") + ": "}
              <Typography variant="body1" component="span" fontWeight="normal">
                {contract?.Customer?.CustomerEmails?.length > 0
                  ? contract?.Customer?.CustomerEmails[0].email
                  : ""}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container item xs={12} alignItems="center" marginBottom={1}>
          <DescriptionIcon color="primary" />
          <Box style={{ display: "inline-block", marginLeft: "8px" }}>
            <Typography variant="h5" fontWeight="bold">
              {t("contractDetails")}
            </Typography>
          </Box>
        </Grid>

        <Grid item container spacing={2} marginBottom={4}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              // align="center"
            >
              {t("startDate") + ": "}
              <Typography variant="body1" component="span" fontWeight="normal">
                {contract?.startDate}
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              // align="center"
            >
              {t("periodicity") + ": "}
              <Typography variant="body1" component="span" fontWeight="normal">
                {t(contract?.Periodicity?.name)}
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              // align="center"
            >
              {t("paymentMethod") + ": "}
              <Typography variant="body1" component="span" fontWeight="normal">
                {t(contract?.PaymentMethod?.name)}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
