import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import SearchIcon from "@mui/icons-material/Search";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

import Button from "./CustomButton";

const SearchButton = (props) => {
  const [t] = useTranslation("others");

  const { text, loadingText, onClick, disabled = false } = props;

  return (
    <Button
      icon={<SearchIcon />}
      loadingIcon={<HourglassEmptyIcon />}
      onClick={onClick}
      disabled={disabled}
      {...props}
      text={text || t("search")}
      loadingText={loadingText || t("searching")}
    ></Button>
  );
};

SearchButton.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string,
  loadingText: PropTypes.string,
  onClick: PropTypes.func,
};

export default SearchButton;
