import { Tab, Tabs as MUITabs, Box } from "@mui/material";
import { useState } from "react";

const TabBar = ({ onChange, currentTab, tabs }) => {
  const handleChange = (event, index) => {
    onChange && onChange(event, index);
  };

  return (
    <MUITabs value={currentTab} onChange={handleChange} variant="scrollable">
      {tabs.map((tab, index) => (
        <Tab key={index} label={tab.label} />
      ))}
    </MUITabs>
  );
};

const Tabs = ({ onTabChange, currentTab, tabs, tabBottomMargin }) => {
  const [currTab, setCurrTab] = useState(Number(currentTab) || 0);

  const handleChange = (event, index) => {
    setCurrTab(index);
    onTabChange && onTabChange(event, index);
  };
  return (
    <Box>
      <TabBar currentTab={currTab} onChange={handleChange} tabs={tabs} />
      <Box marginTop={tabBottomMargin}>
        {tabs.filter((_, index) => index === currTab).map((tab) => tab.content)}
      </Box>
    </Box>
  );
};

export default Tabs;
