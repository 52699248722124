import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SPAIN_COUNTRY_ID } from "../../../data/constants";
import { useContext } from "react";
import AppContext from "../../../context/AppContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import StarIcon from "@mui/icons-material/Star";

export default function BillingAddressCard({
  billingAddress,
  onVisibilityChange = undefined,
  setAsDefault = undefined,
  setAsSelected = undefined,
  isSelected = undefined,
}) {
  const [t] = useTranslation("customers");
  const [tCountry] = useTranslation("country");
  const { user } = useContext(AppContext);

  const {
    name,
    idType,
    nif,
    residenceCountry,
    address,
    isDefault,
    PostalCode: postalCode,
    City: city,
    active,
  } = billingAddress;

  const getCardBorder = () => {
    if (isSelected || (isSelected === undefined && isDefault))
      return "1px solid";
    return undefined;
  };

  const isClickable = () => {
    return !isSelected && setAsSelected && active;
  };

  return (
    <Card
      variant="outlined"
      sx={{
        border: getCardBorder(),
        borderColor:
          (isSelected || (isSelected === undefined && isDefault)) &&
          "primary.main",
        cursor: isClickable() && "pointer",
        opacity: active ? 1 : 0.5,
        position: "relative",
      }}
      onClick={() => isClickable() && setAsSelected(billingAddress.id)}
    >
      {isDefault && (
        <StarIcon
          sx={{
            position: "absolute",
            top: 4,
            right: 4,
            color: "primary.main",
            fontSize: "small",
          }}
        />
      )}
      <CardContent>
        <Grid item container spacing={2}>
          <Grid item>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                {t("name") + ": "}
                <Typography component="span" fontWeight="regular">
                  {name || "---"}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                {t(idType?.toLowerCase()) + ": "}
                <Typography component="span" fontWeight="regular">
                  {nif || "---"}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                {t("residenceCountry") + ": "}
                <Typography component="span" fontWeight="regular">
                  {tCountry(residenceCountry?.name) || "---"}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                {t("address") + ": "}
                <Typography component="span" fontWeight="regular">
                  {address || "---"}
                </Typography>
              </Typography>
            </Grid>
            {residenceCountry?.id === SPAIN_COUNTRY_ID && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body" fontWeight="bold">
                    {t("postalCode") + ": "}
                    <Typography component="span" fontWeight="regular">
                      {postalCode?.number || "---"}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" fontWeight="bold">
                    {t("city") + ": "}
                    <Typography component="span" fontWeight="regular">
                      {city?.name || "---"}
                    </Typography>
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          {onVisibilityChange &&
            user.hasAction("EDIT_CUSTOMERS") &&
            !billingAddress.isDefault && (
              <Grid
                item
                flex={1}
                alignItems="flex-start"
                justifyContent="flex-end"
                display="flex"
              >
                <Tooltip title={active ? t("setAsInactive") : t("setAsActive")}>
                  <IconButton
                    onClick={() => onVisibilityChange(billingAddress)}
                  >
                    {active ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
        </Grid>
      </CardContent>
      {!isDefault && setAsDefault && active && (
        <CardActions>
          <Button onClick={() => setAsDefault(billingAddress.id)}>
            {t("setAsDefault")}
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
