import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { toPng } from "html-to-image";

import MoreVertIcon from "@mui/icons-material/MoreVert";

export const ChartContainer = (props) => {
  const { title, subtitle, chart, table, csv, children } = props;
  const anchorMenuEl = useRef(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isRawView, setIsRawView] = useState(false);
  const csvRef = useRef(null);
  const containerRef = useRef(null);

  const openMenu = () => {
    setIsMenuActive(true);
  };

  const closeMenu = () => {
    setIsMenuActive(false);
  };

  const printImage = () => {
    if (containerRef.current === null) {
      return;
    }

    toPng(containerRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "chart.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card ref={containerRef} sx={{ p: 3 }}>
      {props.loading ? (
        <Container>
          <CircularProgress />
        </Container>
      ) : (
        <>
          <CardHeader
            title={title}
            subheader={subtitle}
            action={
              <Box>
                <IconButton
                  ref={anchorMenuEl}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={openMenu}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorMenuEl.current}
                  keepMounted
                  open={isMenuActive}
                  onClose={closeMenu}
                >
                  {isRawView ? (
                    <MenuItem
                      onClick={() => {
                        setIsRawView(false);
                      }}
                      key="chart"
                    >
                      Mostrar gráfico
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        setIsRawView(true);
                      }}
                      key="table"
                    >
                      Mostrar datos
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      printImage();
                      closeMenu();
                    }}
                    key="img"
                  >
                    Descargar Imagen
                  </MenuItem>
                  {csv && (
                    <MenuItem
                      onClick={() => {
                        csvRef.current.link.click();
                        closeMenu();
                      }}
                      key="csv"
                    >
                      Exportar CSV
                      <CSVLink
                        ref={csvRef}
                        data={csv}
                        filename="datos.csv"
                        style={{ display: "none" }}
                      ></CSVLink>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            }
          />
          {children}
          {isRawView ? table : chart}
        </>
      )}
    </Card>
  );
};
