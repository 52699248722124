import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import AddCircleOutlined from "@mui/icons-material/AddCircleOutlined";
import Close from "@mui/icons-material/Close";
import CloseIcon from "@mui/icons-material/Close";
import { Create, Delete } from "@mui/icons-material";

import AppContext from "../../../context/AppContext";
import CustomSelect from "../../Inputs/CustomSelect";
import Button from "../../Inputs/CustomButton";
import HTMLEditor from "../../HTMLEditor";
import TextInput from "../../Inputs/TextInput";

const initialState = {
  contractTypes: [],
  dialogCreate: {
    isOpen: false,
    title: "",
    html: "",
  },
  dialog: {
    isOpen: false,
    title: "",
    html: "",
  },
  inputError: {},
  form: {
    description: "",
    id: "",
    name: "",
    template: "",
    centers: [],
  },
  centers: [],
  loaded: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "OPEN_DIALOG":
      return {
        ...state,
        dialog: { ...state.dialog, isOpen: true },
        form: action.payload,
      };
    case "CLOSE_DIALOG":
      return { ...state, dialog: { ...state.dialog, isOpen: false } };
    case "SET_TITLE":
      return { ...state, dialog: { ...state.dialog, title: action.payload } };
    case "SET_HTML":
      return { ...state, dialog: { ...state.dialog, html: action.payload } };
    case "OPEN_DIALOG_CREATE":
      return {
        ...state,
        dialogCreate: { ...state.dialogCreate, isOpen: true },
        form: initialState.form,
      };
    case "CLOSE_DIALOG_CREATE":
      return {
        ...state,
        dialogCreate: { ...state.dialogCreate, isOpen: false },
      };
    case "SET_TITLE_CREATE":
      return {
        ...state,
        dialogCreate: { ...state.dialogCreate, title: action.payload },
      };
    case "SET_HTML_CREATE":
      return {
        ...state,
        dialogCreate: { ...state.dialogCreate, html: action.payload },
      };
    case "SET_CONTRACT_TYPES":
      return { ...state, contractTypes: action.payload };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_LOADED_TRUE":
      return { ...state, loaded: true };
    case "SET_LOADED_FALSE":
      return { ...state, loaded: false };
    case "RESET_FORM":
      return { ...state, form: initialState.form };
    default:
      throw new Error();
  }
}

function AddButton(props) {
  const [t] = useTranslation("contracts");

  return (
    <Button
      onClick={props.onClick}
      variant="contained"
      color="primary"
      position="fixed"
      startIcon={<AddCircleOutlined />}
    >
      {t("createContractType")}
    </Button>
  );
}

const ContractLabel = ({ contract, onDelete, onEdit }) => {
  return (
    <Box>
      <Typography variant="body1">{contract.name}</Typography>
      <Typography variant="body1">{contract.description}</Typography>
      <IconButton onClick={onEdit}>
        <Create />
      </IconButton>
      <IconButton onClick={onDelete}>
        <Delete />
      </IconButton>
    </Box>
  );
};

export default function ContractTypesPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("contracts");
  const [tErrors] = useTranslation("errors");
  const history = useHistory();

  const handleInputChange = (e) => {
    setInputErrorFalse();
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const openDialog = (contract) => {
    contract.centers = contract.Centers.map((center) => {
      return center.id;
    });
    dispatch({ type: "OPEN_DIALOG", payload: contract });
    setTitle(contract?.name);
    setHTML(contract?.template);
  };

  const closeDialog = () => {
    dispatch({ type: "CLOSE_DIALOG" });
  };

  const setTitle = (title) => {
    dispatch({ type: "SET_TITLE", payload: title });
  };

  const setHTML = (html) => {
    dispatch({ type: "SET_HTML", payload: html });
  };

  const updateContract = () => {
    if (validateForm()) {
      api
        .post("/contracts/types/edit/" + state.form.id, state.form)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, {
              variant: "error",
            });
          } else {
            enqueueSnackbar(t("contractTypeUpdateSuccess"), {
              variant: "success",
            });

            /* let contractTypes = state.contractTypes;
            contractTypes.forEach((contractType) => {
              if (contractType.id === response.data.id) {
                contractType.id = response.data.id;
                contractType.name = response.data.name;
                contractType.template = response.data.template;
                contractType.description = response.data.description;
              }
            }); */

            api
              .get("/contracts/types")
              .then((response) => {
                if (response.data.error) {
                  console.log(response.data.error);
                  enqueueSnackbar(response.data.error, {
                    variant: "error",
                  });
                } else {
                  response.data.forEach((type) => {
                    type.centers = type.Centers;
                    return type;
                  });
                  dispatch({
                    type: "SET_CONTRACT_TYPES",
                    payload: response.data,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                enqueueSnackbar(error.toString(), { variant: "error" });
              });

            closeDialog();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const openDialogCreate = (contract) => {
    dispatch({ type: "OPEN_DIALOG_CREATE" });
    setTitleCreate(contract?.name);
    setHTMLCreate(contract?.template);
  };

  const closeDialogCreate = () => {
    dispatch({ type: "CLOSE_DIALOG_CREATE" });
  };

  const setTitleCreate = (title) => {
    dispatch({ type: "SET_TITLE_CREATE", payload: title });
  };

  const setHTMLCreate = (html) => {
    dispatch({ type: "SET_HTML_CREATE", payload: html });
  };

  const createContract = () => {
    if (validateForm()) {
      api
        .post("/contracts/types/create", state.form)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("contractTypeCreateSuccess"), {
              variant: "success",
            });

            response.data.centers = response.data.Centers;

            let contractTypes = state.contractTypes;
            dispatch({ type: "SET_CONTRACT_TYPES", payload: contractTypes });

            closeDialogCreate();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let fields = ["name"];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(field + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    return isValid;
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("contractTypesPage");

    getContractTypes();
    getCenters();
  }, []);

  const getContractTypes = () => {
    dispatch({ type: "SET_LOADED_FALSE" });

    api
      .get("/contracts/types")
      .then((response) => {
        response.data.forEach((type) => {
          type.centers = type.Centers;
          return type;
        });
        if (response.data.length > 0) {
          dispatch({
            type: "SET_CONTRACT_TYPES",
            payload: response.data,
          });
          dispatch({ type: "SET_LOADED_TRUE" });
        } else {
          enqueueSnackbar(t("noContractTypes"), { variant: "error" });
        }
      })
      .catch((error) => console.log(error));
  };

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length > 0) {
            dispatch({
              type: "SET_CENTERS",
              payload: response.data,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const handleTemplateChange = (text) => {
    handleInputChange({ target: { name: "template", value: text } });
  };

  const deleteContract = (contract) => {
    api
      .delete("/contracts/types/" + contract.id)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(t("contractDeleteSuccess"), {
            variant: "success",
          });

          let contractTypes = state.contractTypes.filter(
            (type) => type.id !== contract.id
          );

          dispatch({ type: "SET_CONTRACT_TYPES", payload: contractTypes });

          closeDialogCreate();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <Container maxWidth="lg" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid
            item
            container
            xs={12}
            spacing={3}
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="h4">{t("contractTypes")}</Typography>
            </Grid>
            <Grid item>
              <AddButton onClick={openDialogCreate} />
            </Grid>
          </Grid>

          {state.loaded ? (
            state.contractTypes.map((contract) => {
              return (
                <Grid item xs={12} key={contract.id}>
                  <ContractLabel
                    key={contract.id}
                    contract={contract}
                    onEdit={() => {
                      openDialog(contract);
                    }}
                    onDelete={() => {
                      deleteContract(contract);
                    }}
                  />
                </Grid>
              );
            })
          ) : (
            <Grid item>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={300}
              >
                <CircularProgress />
              </Box>
            </Grid>
          )}
          <Grid item container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button variant="text" onClick={() => history.goBack()}>
                {t("back")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        open={state.dialogCreate.isOpen}
        onClose={closeDialogCreate}
        maxWidth="xl"
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">{t("createContractType")}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={closeDialogCreate}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item container xs={6}>
              <Grid item xs={12}>
                <TextInput
                  error={state.inputError.name}
                  helperText={
                    state.inputError.name ? "Name must not be blank" : ""
                  }
                  variant="outlined"
                  label={t("name")}
                  value={state.form.name}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  name="name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  multiple
                  options={state.centers.map((center) => ({
                    label: center.name,
                    value: center.id,
                  }))}
                  label={t("centers")}
                  onChange={handleInputChange}
                  name="centers"
                  value={state.form.centers}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextInput
                error={state.inputError.description}
                helperText={
                  state.inputError.description
                    ? "Description must not be blank"
                    : ""
                }
                variant="outlined"
                label={t("description")}
                multiline
                rows={4}
                value={state.form.description}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="description"
              />
            </Grid>
            <Grid item>
              <HTMLEditor
                onChange={handleTemplateChange}
                html={state.dialogCreate.html ? state.dialogCreate.html : ""}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={createContract}>{t("save")}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={state.dialog.isOpen} onClose={closeDialog} maxWidth="xl">
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">Edit contract type</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={closeDialog}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput
                error={state.inputError.name}
                helperText={
                  state.inputError.name ? "Name must not be blank" : ""
                }
                label={t("name")}
                value={state.form.name}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                error={state.inputError.description}
                helperText={
                  state.inputError.description
                    ? "Description must not be blank"
                    : ""
                }
                label={t("description")}
                multiline
                rows={4}
                value={state.form.description}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                name="description"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                multiple
                options={state.centers.map((center) => ({
                  label: center.name,
                  value: center.id,
                }))}
                label={t("centers")}
                onChange={handleInputChange}
                name="centers"
                value={state.form.centers}
              />
            </Grid>

            <Grid item>
              <HTMLEditor
                onChange={handleTemplateChange}
                html={state.dialog.html ? state.dialog.html : ""}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={updateContract}>{t("save")}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
