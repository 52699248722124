import { Button, CircularProgress } from "@mui/material";

const CustomButton = (props) => {
  let {
    loading,
    icon,
    loadingIcon,
    disabled,
    text,
    children,
    loadingText,
    startIcon,
    variant = "contained",
    ...rest
  } = props;

  if (!loadingIcon) {
    loadingIcon = <CircularProgress size={15} />;
  }

  return (
    <Button
      startIcon={(loading ? loadingIcon : icon) || startIcon}
      variant={variant}
      {...rest}
      disabled={loading || disabled}
    >
      {loading ? loadingText || children || text : children || text}
    </Button>
  );
};

export default CustomButton;
