/* 

    Drag test 2

    Works with parent ids

*/

import {
    Box,
    Container,
    Typography
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useReducer } from "react";

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_FILE_PARENT_ID":
            const file = state.files.filter(file => file.id === action.payload.fileId)[0];
            return {
                ...state, files:
                    [...state.files.filter(file => file.id !== action.payload.fileId),
                    { ...file, parentId: action.payload.parentId }
                    ]
            };

        case "SET_DIR_PARENT_ID":
            const dir = state.dirs.filter(dirItem => dirItem.id === action.payload.dirId)[0];
            return {
                ...state, dirs:
                    [...state.dirs.filter(dirItem => dirItem.id !== action.payload.dirId),
                    { ...dir, parentId: action.payload.parentId }]
            };
        case "SET_DRAGGING_ID":
            return { ...state, draggingId: action.payload };

        default:
            throw new Error("Action not found in reducer");
    }
}

const initialState = {
    files: [
         { id: "1", name: "fileOne", parentId: "root" },
         { id: "2", name: "fileTwo", parentId: "root" },
         { id: "3", name: "fileThree", parentId: "root" },
         { id: "4", name: "fileFour", parentId: "root" },
         { id: "5", name: "fileFive", parentId: "root" },
         { id: "6", name: "fileSix", parentId: "root" },
    ],
    dirs: [
        { id: "root", index: 0, parentId: "" },
        { id: "dir2", index: 1, parentId: "" },
        { id: "dir3", index: 2, parentId: "" },
        { id: "dir4", index: 3, parentId: "" },
        { id: "dir5", index: 4, parentId: "" },
        { id: "dir6", index: 5, parentId: "" },
        { id: "dir7", index: 6, parentId: "" },
        { id: "dir8", index: 7, parentId: "" },
    ],
    draggingId: null
};

const Directory = ({
    id,
    dirs,
    files,
    index,
}) => {

    return (
        <Draggable
            draggableId={id}
            index={index}
        >
            {(provided, snapshot) => {
                return (
                    <Box
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        sx={{
                            border: "solid black",
                            margin: 1,
                            maxWidth: snapshot.isDragging ? 300 : "auto",
                            minHeight: 100,
                            maxHeight: snapshot.isDragging ? 100 : "auto",
                            backgroundColor: snapshot.combineTargetFor === null ? "white" : "gray",
                        }}
                        {...provided.dragHandleProps}
                    >
                        <Box
                            sx={{
                                border: "solid black"
                            }}
                        >
                            <Typography variant="subtitle1">{id}</Typography>
                        </Box>

                        <Box
                            sx={{ display: snapshot.isDragging ? "none" : "default" }}
                        >
                            {dirs
                                .filter(dirItem => dirItem.parentId === id)
                                .map((dirItem) => {
                                    return (
                                        <Directory
                                            id={dirItem.id}
                                            files={files}
                                            dirs={dirs}
                                            key={dirItem.id}
                                            index={dirItem.index}
                                        />
                                    )
                                })}
                        </Box>




                        <Droppable droppableId={id} direction="horizontal" type="file">
                            {(provided) => (
                                <Container
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    sx={{
                                        padding: 1,
                                        display: "flex",
                                        // backgroundColor: "red"
                                    }}
                                >
                                    {files.filter(file => file.parentId === id).map((file, i) => (
                                        <File
                                            file={file}
                                            index={i}
                                            key={file.id}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </Container>
                            )}
                        </Droppable>
                    </Box>
                );
            }}

        </Draggable>



    );
}

const File = ({ index, file }) => {

    return (
        <Draggable draggableId={file.id} index={index} >
            {(provided) => (
                <Box
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    sx={{
                        width: 85,
                        height: 110,
                        border: "solid black",
                        margin: 3
                    }}>
                    {file.name}
                </Box>
            )}
        </Draggable>

    );
}


export const DragTestPage2 = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const moveFile = (source, destination, draggableId) => {
        const startDir = source.droppableId;
        const endDir = destination.droppableId;

        if (startDir !== endDir) { // move files between dirs
            dispatch({
                type: "SET_FILE_PARENT_ID", payload: {
                    fileId: draggableId, parentId: endDir
                }
            });
        }
    }

    const moveDir = (draggableId) => {
        dispatch({
            type: "SET_DIR_PARENT_ID", payload: {
                dirId: draggableId, parentId: ""
            }
        });
    };

    const moveDirInDir = (source, combine, draggableId) => {
        const startDir = source.droppableId;
        const endDir = combine.draggableId;

        //add draggable dir to destination dir
        if (startDir !== endDir) {
            dispatch({
                type: "SET_DIR_PARENT_ID", payload: {
                    dirId: draggableId, parentId: combine.draggableId
                }
            });
        }


    };

    const onStartDrag = (start) => {
        dispatch({ type: "SET_DRAGGING_ID", payload: start.source.droppableId });
    };

    const onDragEnd = (result) => {
        dispatch({ type: "SET_DRAGGING_ID", payload: null });

        const { destination, source, draggableId, type, combine } = result;
        // console.log(result);

        if (destination && !(destination.droppableId === source.droppableId && destination.index === source.index)) {
            if (type === 'file') {
                moveFile(source, destination, draggableId);
            } else {
                moveDir(draggableId);
            }

        } else {

            // Movement of dir in dirs
            if (combine) {
                // console.log("combine", result);
                moveDirInDir(source, combine, draggableId);
            }
        }

    };

    const isDragging = (id) => {
        console.log(state.draggingId === id)
        return state.draggingId === id;
    }

    return (
        <Container sx={{ padding: 1 }}>

            <DragDropContext onDragEnd={onDragEnd} >
                <Droppable
                    droppableId="all"
                    type="dir"
                    isCombineEnabled={true}
                >
                    {(provided) => (
                        <Container
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {state.dirs
                                .filter(dir => dir.parentId === "")
                                .map((dir) => {

                                    return (
                                        <Directory
                                            id={dir.id}
                                            files={state.files}
                                            dirs={state.dirs}
                                            key={dir.id}
                                            index={dir.index}
                                        />

                                    )
                                })}
                            {provided.placeholder}
                        </Container>
                    )}
                </Droppable>
            </DragDropContext>

        </Container>


    );
}