import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Map from "./Map";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import AppContext from "../../../context/AppContext";
import MapGroupForm from "./MapGroupForm";
// import MapMarkForm from "./MapMarkForm";
// import MapSearchBar from "./MapSearchBar";

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT_MAP_GROUP":
      return {
        ...state,
        mapGroup: {
          ...state.mapGroup,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_MAP_MARK":
      return {
        ...state,
        mapMark: {
          ...state.mapMark,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_MAP_GROUP":
      return { ...state, mapGroup: initialState.mapGroup };
    case "RESET_MAP_MARK":
      return { ...state, mapMark: initialState.mapMark };
    case "SET_MAP_GROUP":
      return { ...state, mapGroup: action.payload };
    case "SET_MAP_GROUPS":
      return { ...state, mapGroups: action.payload };
    case "SET_MARKERS":
      return { ...state, markers: action.payload };
    case "SET_MAP":
      new mapboxgl.Marker()
        .setLngLat(action.payload.coords)
        .addTo(action.payload.map);
      return { ...state, map: { ...state.map, current: action.payload.map } };
    case "SET_MOUSE_COORDS":
      return { ...state, mouseCoords: action.payload };
    case "OPEN_MAPGROUP_MODAL":
      return {
        ...state,
        mapGroupModalIsOpen: true,
        mapGroupModalMode: action.payload,
      };
    case "CLOSE_MAPGROUP_MODAL":
      return { ...state, mapGroupModalIsOpen: false };
    case "SHOW_FORM":
      return { ...state, showForm: !state.showForm };
    default:
      throw new Error("Action type not found in reducer");
  }
}

const initialState = {
  mapGroup: { name: "", color: "", attributes: [], mapMarks: [] },
  mapGroups: [],
  mapGroupModalIsOpen: false,
  mapGroupModalMode: "create",
  mapMark: { name: "", data: {} },
  markers: [],
  mouseCoords: {
    lat: 41.3874,
    lng: 2.1686,
  },
  showMapGroups: [],
  showForm: false,
};

export default function MapsPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("settings");

  //Initial useEffect
  useEffect(() => {
    document.title = t("mapsPage");

    getMapGroups();
  }, []);

  const handleChangeMapGroup = (e) => {
    dispatch({
      type: "SET_INPUT_MAP_GROUP",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const createMapGroup = () => {
    let form = state.mapGroup;

    if (validateMapGroup(form)) {
      form.MapGroupAttributes = form.attributes;
      form.MapMarks = form.mapMarks;
      form.MapMarks.forEach((mark) => {
        mark.data = JSON.stringify(mark.data);
      });

      api
        .post("/maps/map-groups/create", form)
        .then((response) => {

          if (!response.data.error) {
            dispatch({ type: "RESET_MAP_GROUP" });

            let mapGroup = response.data.mapGroup;
            mapGroup.mapMarks = response.data.MapMarks.map((mark) => {
              mark.data = JSON.parse(mark.data);

              return mark;
            });
            mapGroup.attributes = response.data.MapGroupAttributes;

            let groups = [...state.mapGroups, mapGroup];
            dispatch({ type: "SET_MAP_GROUPS", payload: groups });
          } else {
            enqueueSnackbar(response.data.error.toString(), {
              variant: "error",
            });
          }
        })
        .catch((error) => {});
    } else {
      enqueueSnackbar("Duplicate attribute names", { variant: "error" });
    }
  };

  const validateMapGroup = (form) => {
    let isValid = true;
    let isUnique = true;

    form.attributes.forEach((attr) => {
      let duplicates = form.attributes.filter(
        (item) => item.name === attr.name
      );
      if (duplicates.length > 1) isUnique = false;
    });

    if (!isUnique) isValid = false;

    return isValid;
  };

  const handleChangeMapMark = (e) => {
    dispatch({
      type: "SET_INPUT_MAP_MARK",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const createMapMark = () => {
    let form = state.mapMark;

    form.data = JSON.stringify(form.data);

    api
      .post("/maps/map-marks/create", form)
      .then((response) => {
        dispatch({ type: "RESET_MAP_MARK" });

        let mapGroup = state.mapGroups.filter(
          (group) => group.id === response.data.mapMark.groupId
        );
        let groupIndex = state.mapGroups.indexOf(mapGroup[0]);
        mapGroup[0].MapMarks.push(response.data.mapMark);

        let groups = state.mapGroups;
        groups[groupIndex] = mapGroup[0];

        dispatch({ type: "SET_MAP_GROUPS", payload: groups });
        // getMapGroups();
      })
      .catch((error) => {});
  };

  const getMapGroups = () => {
    api
      .get("/maps/map-groups")
      .then((response) => {
        response.data.forEach((group) => {
          group.show = false;
          group.mapMarks = group.MapMarks.map((mark) => {
            mark.data = JSON.parse(mark.data);
            return mark;
          });
          group.attributes = response.data.MapGroupAttributes;
        });
        dispatch({ type: "SET_MAP_GROUPS", payload: response.data });

        updateMarkers(response.data);
      })
      .catch((error) => {});
  };

  const updateMarkers = (mapGroups) => {
    let markers = [];
    mapGroups.forEach((group) => {
      if (group.show) {
        group.MapMarks?.forEach((mark) => {
          markers.push(mark);
        });
      }
    });
    dispatch({ type: "SET_MARKERS", payload: markers });
  };

  const toggleShowMapGroup = (e, groupIndex) => {
    let mapGroups = state.mapGroups;
    mapGroups[groupIndex].show = e.target.checked;
    dispatch({
      type: "SET_MAP_GROUPS",
      payload: mapGroups,
    });
    updateMarkers(mapGroups);
  };

  const toggleMapGroupVisible = (groupIndex) => {
    let mapGroups = state.mapGroups;
    mapGroups[groupIndex].show = !mapGroups[groupIndex].show;
    dispatch({
      type: "SET_MAP_GROUPS",
      payload: mapGroups,
    });
    updateMarkers(mapGroups);
  };

  const toggleForm = () => {
    dispatch({ type: "SHOW_FORM" });
  };

  //test callback for searchbar component
  const addMarker = (info) => {
    let marker = {
      longitude: info.features[0].center[0],
      latitude: info.features[0].center[1],
    };

    dispatch({ type: "SET_MARKERS", payload: [...state.markers, marker] });
  };

  const onMouseMove = (lngLat) => {
    dispatch({ type: "SET_MOUSE_COORDS", payload: lngLat });
  };

  const openCreateMapGroupModal = () => {
    dispatch({ type: "OPEN_MAPGROUP_MODAL", payload: "create" });
  };

  const openEditMapGroupModal = (mapgroup) => {
    dispatch({ type: "OPEN_MAPGROUP_MODAL", payload: "edit" });

    mapgroup.attributes = mapgroup.MapGroupAttributes;

    dispatch({ type: "SET_MAP_GROUP", payload: mapgroup });
  };

  const closeMapGroupModal = () => {
    dispatch({ type: "CLOSE_MAPGROUP_MODAL" });
  };

  const modifyMapGroup = () => {
    let form = state.mapGroup;
    if (validateMapGroup(form)) {
      form.mapMarks.forEach((mark) => {
        mark.data = JSON.stringify(mark.data);
      });

      api
        .post("/maps/map-groups/edit/" + form.id, form)
        .then((response) => {
          closeMapGroupModal();
          dispatch({ type: "RESET_MAP_GROUP" });
          getMapGroups();
        })
        .catch((error) => {});
    } else {
      enqueueSnackbar("Duplicate attribute names", { variant: "error" });
    }
  };

  return (
    <Box
      // flexGrow={1}
      height="100%"
      display="flex"
    >
      <Map
        markers={state.markers}
        onMouseMove={onMouseMove}
        sx={{ flexGrow: 1, width: "100%" }}
      />

      <Card
        sx={{
          position: "absolute",
          top: 60,
          padding: "2%",
          height: 100,
        }}
      >
        <Grid container spacing={3}>
          <Grid item>
            <Button variant="contained" onClick={openCreateMapGroupModal}>
              {t("createMapGroup")}
            </Button>
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={toggleForm}>
              {t("mapGroups")}
            </Button>
          </Grid>

          <Grid item>
            <Typography variant="body1">
              {state.mouseCoords?.lng?.toFixed(2)},{" "}
              {state.mouseCoords?.lat?.toFixed(2)}
            </Typography>
          </Grid>

          {state.showForm && (
            <Grid item xs={12}>
              <List>
                {state.mapGroups.map((group, i) => (
                  <ListItem key={i}>
                    <Typography variant="body1">{group.name}</Typography>

                    <IconButton
                      color={group.show ? "success" : "error"}
                      onClick={() => {
                        toggleMapGroupVisible(i);
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        openEditMapGroupModal(group);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>
      </Card>

      <Dialog open={state.mapGroupModalIsOpen} onClose={closeMapGroupModal}>
        <DialogActions>
          <IconButton onClick={closeMapGroupModal}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item>
              <MapGroupForm
                mapGroup={state.mapGroup}
                mapMark={state.mapMark}
                onChange={handleChangeMapGroup}
                onChangeMapMark={handleChangeMapMark}
                submitForm={() => {
                  if (state.mapGroupModalMode === "create") createMapGroup();
                  else if (state.mapGroupModalMode === "edit") modifyMapGroup();
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
