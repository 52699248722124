import Dialog from "../../global/Dialog";
import Button from "../../Inputs/CustomButton";
import CustomDate from "../../Inputs/CustomDate";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const MaxDateDialog = (props) => {
  const { isOpen, onClose } = props;
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("providerInvoices");
  const [tErrors] = useTranslation("errors");

  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    getMaxProviderDate();
  }, []);

  const getMaxProviderDate = () => {
    const params = { key: "MAX_PROVIDER_DATE" };
    api
      .get("/miscellaneous", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else setMaxDate(response.data.value);
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const setMaxProviderDate = () => {
    const params = { date: maxDate };
    api
      .post("/miscellaneous/max-provider-invoice-date", params)
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else onClose();
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      title={t("changeMaxDate")}
      actions={<Button onClick={setMaxProviderDate}>{t("save")}</Button>}
    >
      <CustomDate
        value={maxDate}
        name="providerInvoiceMaxDate"
        onChange={(e) => setMaxDate(e.target.value)}
        label={t("providerInvoiceMaxDate")}
      />
    </Dialog>
  );
};

export default MaxDateDialog;
