import { useContext, useReducer, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// MUI
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  Tooltip,
} from "@mui/material";

// Icons
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import StoreIcon from "@mui/icons-material/Store";

// Components & utils
import { getParams, generateURL } from "../../../utils/url";
import AppContext from "../../../context/AppContext";
import CreateButton from "../../Inputs/CreateButton";
import CustomSelect from "../../Inputs/CustomSelect";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import { CustomTable } from "../../CustomTable";
import {
  ABSOLUTE_DISCOUNT_TYPE,
  PERCENTAGE_DISCOUNT_TYPE,
  PERMANENT_TEMPORALITY,
  TEMPORAL_TEMPORALITY,
} from "../../../data/constants";
import Page from "../../global/structure/Page";
import Filters from "../../global/structure/Filters";

const initialState = {
  promotions: [],
  promotionsLoading: false,
  filters: {
    // Filters
    name: "",
    temporality: "",
    discountType: "",

    // Order and sort params
    orderBy: "",
    order: "asc",
  },
};

const toExclude = ["orderBy", "order"];

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, [action.payload.dataName]: action.payload.value };
    case "SET_PROMOTIONS_LOADING":
      return { ...state, promotionsLoading: action.payload };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.value,
        },
      };
    default:
      throw new Error("Action not found in reducer");
  }
};

const FILTERS = [
  { name: "name", type: "name" },
  { name: "temporality", type: "temporality" },
  { name: "discountType", type: "discountType" },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Parse page filters for get promotions request
function getReqParams(promotionFilters) {
  let params = { include: ["Periodicity", "Center"] };

  const filtersKeys = Object.keys(promotionFilters).filter(
    (filt) => !toExclude.some((elem) => elem === filt)
  );
  filtersKeys.forEach((filter) => {
    promotionFilters[filter] !== "" &&
      (params[filter] = promotionFilters[filter]);
  });
  return params;
}

function PromotionsPage() {
  // Hooks
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();

  // Translations
  const [t] = useTranslation("promotion");
  const [tErrors] = useTranslation("errors");

  // State
  const initState = (state) => ({
    ...state,
    filters: { ...state.filters, ...getParams(query, FILTERS) },
  });
  const [state, dispatch] = useReducer(reducer, initialState, initState);

  // AnchorEl
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const getPromotions = () => {
    const params = getReqParams(state.filters);

    dispatch({ type: "SET_PROMOTIONS_LOADING", payload: true });

    api
      .get("/promotions", { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noPromotionsFound"), { variant: "warning" });
          }
          dispatch({
            type: "SET_DATA",
            payload: { dataName: "promotions", value: response.data },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() =>
        dispatch({ type: "SET_PROMOTIONS_LOADING", payload: false })
      );
  };

  const setActive = (promotion) => {
    api
      .post("/promotions/" + promotion.id + "/set-active", {
        active: !promotion.active,
      })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        else {
          const newData = state.promotions.map((item) => {
            item.id === promotion.id && (item.active = !item.active);
            return item;
          });
          dispatch({
            type: "SET_DATA",
            payload: { dataName: "promotions", value: newData },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const handleFilterChange = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: { filterName: e.target.name, value: e.target.value },
    });
  };

  const handleResetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  const handleSortCallback = (orderBy, order) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        filterName: "orderBy",
        value: orderBy,
      },
    });
    dispatch({
      type: "SET_FILTER",
      payload: {
        filterName: "order",
        value: order,
      },
    });
    const url = generateURL("/app/promotions", {
      ...state.filters,
      orderBy: orderBy,
      order: order,
    });
    history.push(url);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") getPromotions();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const PROMOTIONS_COLUMNS = [
    {
      key: "name",
      label: t("name"),
      sortType: "string",
    },
    {
      key: "code",
      label: t("code"),
      sortType: "number",
    },
    {
      key: "description",
      label: t("description"),
      sortType: "string",
    },
    {
      key: "temporality",
      label: t("temporality"),
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    {
      key: "discountType",
      label: t("discountType"),
      sortType: "string",
      renderFunction: (value) => t(value),
    },
    {
      key: "numberOfPeriods",
      label: t("numberOfPeriods"),
      sortType: "number",
      renderFunction: (value) => {
        if (!value) return "---";
        return value;
      },
    },
    {
      key: "value",
      label: t("value"),
      sortType: "number",
      renderFunction: (value, item) => {
        if (item.discountType === "percentage") return value + "%";
        else return value + "€";
      },
    },
    {
      key: "restrictedByPeriodicity",
      label: t("restrictedByPeriodicity"),
      sortType: "other",
      renderFunction: (value, item) => (
        <Tooltip
          title={
            value
              ? t("clickToSeeThePeriodicity")
              : t("notRestrictedByPeriodicity")
          }
        >
          <IconButton
            disabled={!value}
            onClick={(e) => {
              e.preventDefault();
              handleClick(e);
              setSelectedMenu(item.id + "periodicity");
              e.stopPropagation();
            }}
          >
            <EventRepeatIcon color={value ? "primary" : undefined} />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      key: "restrictedByCenters",
      label: t("restrictedByCenters"),
      sortType: "other",
      renderFunction: (value, item) => (
        <Tooltip
          title={
            value ? t("clickToSeeTheCenters") : t("notRestrictedByCenters")
          }
        >
          <IconButton
            disabled={!value}
            onClick={(e) => {
              e.preventDefault();
              handleClick(e);
              setSelectedMenu(item.id + "centers");
              e.stopPropagation();
            }}
          >
            <StoreIcon color={value ? "primary" : undefined} />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      key: "id",
      label: t("actions"),
      sortType: "other",
      renderFunction: (value, item) => (
        <Tooltip
          title={
            user.hasAction("EDIT_PROMOTIONS")
              ? item.active
                ? t("setNoActive")
                : t("setActive")
              : item.active
              ? t("promotionActive")
              : t("promotionNoActive")
          }
          placement="top"
        >
          <Box>
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                if (!user.hasAction("EDIT_PROMOTIONS")) return;
                setActive(item);
                e.stopPropagation();
              }}
            >
              {item.active ? (
                <CheckCircleIcon color="success" />
              ) : (
                <CircleIcon color="error" />
              )}
            </IconButton>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const renderMenu = () => {
    if (!state.promotions.length) return <></>;
    const menuType =
      selectedMenu?.split("periodicity")[1] !== undefined
        ? "periodicity"
        : "centers";
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(e) => {
          handleClose(e);
          setSelectedMenu(null);
        }}
      >
        <List sx={{ minWidth: 150 }} dense>
          {menuType === "periodicity"
            ? state.promotions
                .find(
                  (p) => p.id === Number(selectedMenu?.split("periodicity")[0])
                )
                ?.Periodicities?.map((periodicity) => (
                  <ListItem>
                    <ListItemText>{periodicity.name}</ListItemText>
                  </ListItem>
                ))
            : state.promotions
                .find((p) => p.id === Number(selectedMenu?.split("centers")[0]))
                ?.Centers?.map((periodicity) => (
                  <ListItem>
                    <ListItemText>{periodicity.name}</ListItemText>
                  </ListItem>
                ))}
        </List>
      </Menu>
    );
  };

  return (
    <Page maxWidth="xl" title={t("promotions")} browserTitle={t("promotions")}>
      <Grid container rowSpacing={3}>
        {/* BUTTONS AND FILTERS */}
        <Grid item xs={12} marginTop={1}>
          <Filters
            filters={[
              <TextInput
                type="string"
                label={t("name")}
                name="name"
                value={state.filters.name}
                onChange={handleFilterChange}
                onKeyPress={handleKeyPress}
              />,
              <CustomSelect
                options={[
                  { label: t("all"), value: "" },
                  { label: t("temporal"), value: TEMPORAL_TEMPORALITY },
                  { label: t("permanent"), value: PERMANENT_TEMPORALITY },
                ]}
                value={state.filters.temporality}
                name="temporality"
                label={t("temporality")}
                onChange={handleFilterChange}
              />,
              <CustomSelect
                options={[
                  { label: t("all"), value: "" },
                  { label: t("percentage"), value: PERCENTAGE_DISCOUNT_TYPE },
                  { label: t("amount"), value: ABSOLUTE_DISCOUNT_TYPE },
                ]}
                value={state.filters.discountType}
                name="discountType"
                label={t("discountType")}
                onChange={handleFilterChange}
              />,
              <ButtonGroup variant="contained">
                <Button onClick={handleResetFilters}>{t("reset")}</Button>
                <SearchButton
                  loading={state.promotionsLoading}
                  onClick={getPromotions}
                />
              </ButtonGroup>,
              <CreateButton
                action={"CREATE_PROMOTIONS"}
                label={t("createPromotion")}
                link={"/promotion/create"}
              />,
            ]}
          />
        </Grid>

        {/* ITEM SUMMARY */}
        <Grid item xs={12}>
          {/* <ItemsSummary gridItems={ITEMS_SUMMARY} /> */}
        </Grid>

        {/* PROMOTIONS TABLE */}
        <Grid item xs={12}>
          <CustomTable
            columns={PROMOTIONS_COLUMNS}
            data={state.promotions}
            sortBy={state.filters.orderBy}
            sort={state.filters.order}
            onSortCallback={handleSortCallback}
            options={{ rowlink: "promotion" }}
          />
        </Grid>
      </Grid>
      {renderMenu()}
    </Page>
  );
}

export default PromotionsPage;
