import CustomButton from "./Inputs/CustomButton";
import { useTranslation } from "react-i18next";
import Dialog from "./global/Dialog";
import PropTypes from "prop-types";

const ConfirmDialog = (props) => {
  const [t] = useTranslation("others");

  const { title, confirmText, cancelText, children, open, setOpen, onConfirm } =
    props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xs"
      title={title}
      actions={
        <>
          <CustomButton
            onClick={() => {
              setOpen(false);
              onConfirm(false);
            }}
            color="error"
          >
            {cancelText || t("cancel")}
          </CustomButton>
          <CustomButton
            onClick={() => {
              setOpen(false);
              onConfirm(true);
            }}
          >
            {confirmText || t("confirm")}
          </CustomButton>
        </>
      }
    >
      {children}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmDialog;
