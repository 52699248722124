/* ASSET MANAGEMENT SUPPLY TYPES */
export const SUPPLY_TYPES = {
  WATER: "Water",
  ELECTRICITY: "Electricity",
};

/* BOX STATES */
export const FREE_BOX_STATE_ID = 0;
export const OCCUPIED_BOX_STATE_ID = 1;
export const UNAVAILABLE_BOX_STATE_ID = 2;
export const BLOCKED_BOX_STATE_ID = 3;
export const BOOKED_BOX_STATE_ID = 4;

/* BOX CLUSTER NAMES */
export const CLUSTER_NAMES_ARRAY = [
  "Taquilla",
  "Trastero Pequeño",
  "Trastero Mediano",
  "Trastero Grande",
  "Trastero XL",
  "Almacén",
];

/* CANCELLATION TYPES */
export const CANCEL_CANCELLATION_TYPE_ID = 1;
export const CHANGE_BOX_CANCELLATION_TYPE_ID = 2;
export const NONPAYMENT_CANCELLATION_TYPE_ID = 3;
export const NO_ENTER_CANCELLATION_TYPE_ID = 4;
export const CHANGE_DATA_CANCELLATION_TYPE_ID = 5;

/* CENTER TYPES */
export const NUT_CENTER_TYPE_ID = 0;
export const FRANCHISE_CENTER_TYPE_ID = 1;
export const OTHER_CENTER_TYPE_ID = 2;

/* CONTRACT STATES */
export const PENDING_CONTRACT_STATE_ID = 0;
export const ACTIVE_CONTRACT_STATE_ID = 1;
export const ENDED_CONTRACT_STATE_ID = 2;

/* COUNTRIES */
export const SPAIN_COUNTRY_ID = 198;
export const SPAIN_POSTAL_CODE_LENGTH = 5;
export const SPAIN_PHONE_EXTENSION = "+34";
export const SPAIN_PHONE_NUMBER_LENGTH = 9;
export const SPAIN_ALPHA2 = "ES";

/* CUSTOMER TYPES */
export const INDIVIDUAL_CUSTOMER_TYPE_ID = 1;
export const COMPANY_CUSTOMER_TYPE_ID = 2;

/* GENDER */
export const MALE_GENDER_ID = 1;
export const FEMALE_GENDER_ID = 2;
export const OTHER_GENDER_ID = 3;

/* ID_TYPES */
export const DNI_ID_TYPE = "DNI";
export const NIF_ID_TYPE = "NIF";
export const NIE_ID_TYPE = "NIE";
export const PASSPORT_ID_TYPE = "PASSPORT";
export const VAT_ID_TYPE = "VAT";

/* INVOICE SERIES */
export const F_INVOICE_SERIES_ID = 1;
export const A_INVOICE_SERIES_ID = 2;
export const S_INVOICE_SERIES_ID = 3;
export const C_INVOICE_SERIES_ID = 4;
export const H_INVOICE_SERIES_ID = 5;
export const R_INVOICE_SERIES_ID = 6;

/* INVOICE_STATES */
export const ISSUED_INVOICE_STATE_ID = 0;
export const PAID_INVOICE_STATE_ID = 1;
export const EXPIRED_INVOICE_STATE_ID = 2;
export const UNPAID_INVOICE_STATE_ID = 3;
export const BILLING_ERROR_STATE_ID = 4;

/* INVOICING TYPES */
export const CONTRACT_INVOICING_TYPE_ID = 1;
export const CUSTOMER_INVOICING_TYPE_ID = 2;

/* NUT INFO */
export const ADMIN_EMAIL = "administracion@necesitountrastero.es";
export const CALL_CENTER_PHONE_NUMBER = "900 811 646";
export const NUT_ADDRESS =
  "Av. de la Via Augusta, 15, 08174, Sant Cugat del Vallès";
export const COMPANY_NAME = "Necesito un Trastero SL";
export const COMPANY_WEBSITE = "https://necesitountrastero.es/";

/* PAYCARDS */
export const VIRTUAL_PAYCARD_ID = 0;

/* PAYMENT METHODS */
export const CASH_PAYMENT_METHOD_ID = 1;
export const RECEIPT_PAYMENT_METHOD_ID = 2;
export const PAYCARD_PAYMENT_METHOD_ID = 3;
export const TRANSFER_PAYMENT_METHOD_ID = 4;
export const CONSOLIDATION_PAYMENT_METHOD_ID = 5;

/* PERIODICITY */
export const MONTHLY_PERIODICITY_ID = 1;
export const QUARTERLY_PERIODICITY_ID = 2;
export const BIANNUAL_PERIODICITY_ID = 3;
export const ANNUAL_PERIODICITY_ID = 4;

/* PROMOTIONS */
export const TEMPORAL_TEMPORALITY = "temporal";
export const PERMANENT_TEMPORALITY = "permanent";

export const PERCENTAGE_DISCOUNT_TYPE = "percentage";
export const ABSOLUTE_DISCOUNT_TYPE = "amount";

/* RECEIPT STATES */
export const CREATED_RECEIPT_STATE_ID = 0;
export const ISSUED_RECEIPT_STATE_ID = 1;
export const PAID_RECEIPT_STATE_ID = 2;
export const RETURNED_RECEIPT_STATE_ID = 3;

/* ROLES */
export const CUSTOMER_ROLE_ID = 1;
export const SUPER_ADMIN_ROLE_ID = 11;
export const CENTER_ROLE_ID = 16;
export const CALL_CENTER_ROLE_ID = 20;
export const TALUS_ROLE_ID = 21;
export const PLANES_ROLE_ID = 22;
export const AUDIT_ROLE_ID = 24;
export const ADMINISTRATION_ROLE_ID = 26;
export const CONTROLLER_ROLE_ID = 28;
export const BOVE_ROLE_ID = 29;
export const BIG_ACCOUNTS_ROLE_ID = 31;
export const MARKETING_ROLE_ID = 32;
export const COMMERCIAL_ROLE_ID = 33;
export const ADMIN_ROLE_ID = 34;
export const NUT_ROLE_ID = 37;
export const COLLECTION_ROLE_ID = 38;
export const GHOST_ROLE_ID = 39;
export const BIG_ACCOUNTS_PLUS_ROLE_ID = 40;
export const LIQUIDATION_ROLE_ID = 41;
export const IVAN_ROLE_ID = 42;
export const WORKS_ROLE_ID = 43;
export const NUT_DELEGATION_ROLE_ID = 44;

/* EXPENSE TYPES */
export const LIQUIDATION_EXPENSE_TYPE_ID = 166;
export const SUPPLY_EXPENSE_TYPE_ID = 127;
export const PHONE_EXPENSE_TYPE_ID = 128;
export const ELECTRICITY_EXPENSE_TYPE_ID = 129;
export const WATER_EXPENSE_TYPE_ID = 130;

/* TAXES */
export const GENERAL_VAT_ES = 21;
export const REDUCED_VAT_ES = 10;
export const SUPER_REDUCED_VAT_ES = 4;
export const FREE_VAT_ES = 0;

/* MFA */
export const TOTP_TOKEN_LENGTH = 6;

/* FLOOR PLAN */
// All database measurements are set with this value REAL_SCALE
export const REAL_SCALE = 50;
export const TILE_DEFAULT_STROKE_WIDTH = 0;
export const TILE_DEFAULT_EDGE_COLOR = "gray";
export const EDGES_STROKE_WIDTH = 5;
export const TILE_BLUE_COLOR = "rgba(25, 118, 210, 0.72)";
export const TILE_GREEN_COLOR = "rgba(0, 255, 0, 0.72)";
export const TILE_RED_COLOR = "rgba(255, 42, 32, 0.72)";
export const TILE_WHITE_COLOR = "rgba(250, 250, 250, 0.72)";

/* MERCHANTABLE TYPES */
export const RENT_MERCHANTABLE_TYPE_ID = 1;
export const DEPOSIT_MERCHANTABLE_TYPE_ID = 2;
export const IKEA_MERCHANTABLE_TYPE_ID = 3;
export const OTHER_MERCHANTABLE_TYPE_ID = 4;
export const COMMISSION_MERCHANTABLE_TYPE_ID = 5;
export const SHELF_MERCHANTABLE_TYPE_ID = 6;
export const PADLOCK_MERCHANTABLE_TYPE_ID = 7;
export const IKEA_DELIVERY_MERCHANTABLE_TYPE_ID = 8;
export const PROMOTION_MERCHANTABLE_TYPE_ID = 9;

/* EMAIL TEMPLATE VARIABLES & TYPES */
export const EMAIL_TEMPLATE_VARIABLES = [
  {
    id: 1,
    variables: [
      { value: "{{publicId}}", label: "Identificador del contrato" },
      { value: "{{boxName}}", label: "Nombre del trastero" },
      { value: "{{customerName}}", label: "Nombre del cliente" },
      { value: "{{endDate}}", label: "Fecha de fin del contrato" },
    ],
  },
  { id: 2, variables: [] },
  {
    id: 3,
    variables: [
      { value: "{{InvoiceSerie.name}}", label: "Serie de la factura" },
      { value: "{{number}}", label: "Número de la factura" },
      { value: "{{issueDate}}", label: "Fecha de emisión de la factura" },
    ],
  },
  {
    id: 4,
    variables: [{ value: "{{link}}", label: "Enlace para realizar la firma" }],
  },
  {
    id: 5,
    variables: [],
  },
  {
    id: 6,
    variables: [
      { value: "{{reason}}", label: "Razón del impago" },
      { value: "{{Invoice.Customer.name}}", label: "Nombre de cliente" },
    ],
  },
  {
    id: 7,
    variables: [
      { value: "{{name}}", label: "Nombre de la liquidación" },
      { value: "{{Center.name}}", label: "Nombre de la delegación" },
    ],
  },
  {
    id: 8,
    variables: [
      { value: "{{publicId}}", label: "ID público del contrato" },
      { value: "{{customerName}}", label: "Nombre del cliente" },
      { value: "{{boxName}}", label: "Nombre del trastero" },
      { value: "{{resolutionComments}}", label: "Observaciones de resolución" },
      { value: "{{endDate}}", label: "Fecha de fin del contrato" },
    ],
  },
  {
    id: 9,
    variables: [
      { value: "{{publicId}}", label: "ID público del contrato" },
      { value: "{{customerName}}", label: "Nombre del cliente" },
      { value: "{{boxName}}", label: "Nombre del trastero" },
    ],
  },
  {
    id: 10,
    variables: [
      { value: "{{invoiceNumber}}", label: "Número de la factura" },
      { value: "{{title}}", label: "Título de la factura" },
      { value: "{{description}}", label: "Descripción de la factura" },
    ],
  },
  {
    id: 11,
    variables: [
      { value: "{{code}}", label: "Código" },
      { value: "{{name}}", label: "Nombre del cliente" },
    ],
  },
  {
    id: 12,
    variables: [
      { value: "{{accessCode}}", label: "Código de acceso" },
      { value: "{{name}}", label: "Nombre del cliente" },
    ],
  },
  {
    id: 13,
    variables: [
      { value: "{{code}}", label: "Código" },
      { value: "{{name}}", label: "Nombre del cliente" },
    ],
  },
  {
    id: 14,
    variables: [
      { value: "{{invoiceNumber}}", label: "Número de la factura" },
      { value: "{{title}}", label: "Título de la factura" },
      { value: "{{description}}", label: "Descripción de la factura" },
      { value: "{{Center.name}}", label: "Nombre del centro" },
    ],
  },
];
