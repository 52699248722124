import { useTranslation } from "react-i18next";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { useState } from "react";

import TextInput from "../../../Inputs/TextInput";
import Button from "../../../Inputs/CustomButton";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const CreatePolygonForm = ({
  onCreateTile,
  closeAddTileDialog,
  layerTiles,
}) => {
  const [t] = useTranslation("floorPlans");
  const [name, setName] = useState("Tile " + (layerTiles.length + 1));
  const [points, setPoints] = useState([
    [100, 100],
    [200, 100],
    [200, 200],
    [100, 200],
  ]);

  const addPoint = () => {
    setPoints([...points, [100, 100]]);
  };

  const removePoint = (index) => {
    let newPoints = [...points];
    newPoints.splice(index, 1);
    setPoints(newPoints);
  };

  const validateForm = () => {
    if (!name || !points.length || points.length < 2) {
      return false;
    }
    return true;
  };

  const handleCreate = () => {
    let tile = {
      name: name,
      shape: "Polygon",
      points: points.flat(),
    };

    onCreateTile(tile);
  };

  const inputs = [
    { label: "x", value: 0 },
    { label: "y", value: 1 },
  ];

  return (
    <Box width="100%">
      <Grid container spacing={3}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" spacing={2}>
            <Grid item xs={1}>
              <Typography align="left">{t("name") + ":"}</Typography>
            </Grid>
            <Grid item>
              <TextInput
                label={t("name")}
                type={"text"}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid
              container
              xs={2.5}
              alignItems="center"
              display={"flex"}
              justifyContent={"end"}
            >
              <Grid item>
                <IconButton onClick={addPoint}>
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          marginTop={1}
          spacing={1.5}
          maxHeight={300}
          overflow="auto"
        >
          {points.map((point, index) => (
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={1}>
                <Typography variant="body1">
                  {t("point") + " " + (index + 1)}
                </Typography>
              </Grid>
              {inputs.map((input) => (
                <Grid item>
                  <TextInput
                    label={t(input.label)}
                    value={point[input.value]}
                    onChange={(e) => {
                      let newPoints = [...points];
                      newPoints[index][input.value] = e.target.value;
                      setPoints(newPoints);
                    }}
                  />
                </Grid>
              ))}
              <Grid item>
                <IconButton onClick={() => removePoint(index)}>
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} justifyContent="end" spacing={1}>
          <Grid item>
            <Button onClick={closeAddTileDialog} variant="text">
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="success"
              disabled={!validateForm()}
              onClick={handleCreate}
            >
              {t("add")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreatePolygonForm;
