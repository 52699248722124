import axios from "axios";
import { useSnackbar } from "notistack";

export const SERVER =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_SERVER_LOCAL
    : process.env.REACT_APP_SERVER_PROD;
export const API_ROUTE = SERVER + "/api/v1";

const API = (getToken, unsetToken) => {
  const { enqueueSnackbar } = useSnackbar();

  const instance = axios.create();

  instance.SERVER = API_ROUTE;

  // Request error interceptor
  instance.interceptors.request.use(
    (request) => {
      // Set authorization token for all requests
      request.headers["Authorization"] = getToken();

      request.url = API_ROUTE + request.url;
      return request;
    },
    (error) => {
      // error
      enqueueSnackbar(error.toString(), { variant: "error" });
      return Promise.reject(error);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (
        response.data &&
        response.data.error &&
        (response.data.error === "jwt expired" ||
          response.data.error === "jwt malformed")
      ) {
        unsetToken();
      }
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      enqueueSnackbar(error.message, { variant: "error" });
      return Promise.reject(error);
    }
  );

  return instance;
};

export default API;
