import { Box } from "@mui/material";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useRef, useEffect, useReducer } from "react";
import GeoJSON from "geojson";
import { useHistory } from "react-router-dom";
import mapToken from "../../../data/mapboxToken";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

// Import Mapbox token
mapboxgl.accessToken = mapToken;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_MAP":
      return {
        ...state,
        map: { ...state.map, current: action.payload.map },
      };
    default:
      throw new Error("Action type unknown in reducer");
  }
};

const CentersMap = (props) => {
  const history = useHistory();

  const initialState = {
    id: null,
    map: useRef(null),
    mapContainer: useRef(null),
    redirect: false,
    zoom: 5.3, // Zoom level to view whole iberian peninsula
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { data = [] } = props;

  useEffect(() => {
    setMap();
  }, [data, state.map]);

  const setMap = () => {
    if (!state.map.current) {
      let map = new mapboxgl.Map({
        container: state.mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [-3.68, 40.3], // Center of spain
        zoom: state.zoom,
      });

      // Search bar
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        marker: {
          color: "blue",
        },
        mapboxgl: mapboxgl,
      });
      map.addControl(geocoder);

      dispatch({
        type: "SET_MAP",
        payload: {
          map: map,
        },
      });
    } else if (data.length !== 0) {
      let map = state.map.current;

      // Transform to geoJSON data
      const geoJSONData = GeoJSON.parse(data, {
        Point: ["lat", "lng"],
      });

      if (map.getSource("centers"))
        map.getSource("centers").setData(geoJSONData);
      else {
        // Add +/- controls
        if (
          !map._controls.some(
            (cont) => cont instanceof mapboxgl.NavigationControl
          )
        ) {
          map.addControl(new mapboxgl.NavigationControl());
        }

        // Add fullscreen toggle
        if (
          !map._controls.some(
            (cont) => cont instanceof mapboxgl.FullscreenControl
          )
        )
          map.addControl(new mapboxgl.FullscreenControl());

        map.on("load", () => {
          //Add data
          map.addSource("centers", {
            type: "geojson",
            data: geoJSONData,
            cluster: true,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 50,
          });

          map.addLayer({
            id: "clusters",
            type: "circle",
            source: "centers",
            filter: ["has", "point_count"],
            paint: {
              // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
              // with three steps to implement three types of circles:
              //   * Blue, 20px circles when point count is less than 100
              //   * Yellow, 30px circles when point count is between 100 and 750
              //   * Pink, 40px circles when point count is greater than or equal to 750
              "circle-color": [
                "step",
                ["get", "point_count"],
                "#51bbd6",
                100,
                "#f1f075",
                750,
                "#f28cb1",
              ],
              "circle-radius": [
                "step",
                ["get", "point_count"],
                20,
                100,
                30,
                750,
                40,
              ],
              "circle-stroke-width": 1,
              "circle-stroke-color": "#ffffff",
            },
          });

          map.addLayer({
            id: "cluster-count",
            type: "symbol",
            source: "centers",
            filter: ["has", "point_count"],
            layout: {
              "text-field": ["get", "point_count_abbreviated"],
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              "text-size": 12,
            },
          });

          map.addLayer({
            id: "unclustered-point",
            type: "circle",
            source: "centers",
            filter: ["!", ["has", "point_count"]],
            paint: {
              "circle-color": "#4264fb",
              "circle-radius": 8,
              "circle-stroke-width": 1,
              "circle-stroke-color": "#ffffff",
            },
          });

          // Change mouse to pointer on hover cluster
          map.on("mouseenter", "clusters", () => {
            map.getCanvas().style.cursor = "pointer";
          });

          // Reset mouse when leaving cluster
          map.on("mouseleave", "clusters", () => {
            map.getCanvas().style.cursor = "";
          });

          // Inspect a cluster on click
          map.on("click", "clusters", (e) => {
            const features = map.queryRenderedFeatures(e.point, {
              layers: ["clusters"],
            });
            const clusterId = features[0].properties.cluster_id;
            map
              .getSource("centers")
              .getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;

                map.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom,
                });
              });
          });

          // Create a popup, without adding it to the map yet
          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
          });

          // Change mouse to pointer, show popup
          map.on("mouseenter", "unclustered-point", (e) => {
            map.getCanvas().style.cursor = "pointer";
            // Copy coordinates array.
            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.description;

            // Populate the popup and set its coordinates
            // based on the feature found.
            popup.setLngLat(coordinates).setHTML(description).addTo(map);
          });

          // Change it back to a pointer when it leaves, remove popup
          map.on("mouseleave", "unclustered-point", () => {
            map.getCanvas().style.cursor = "";
            popup.remove();
          });

          // Redirect to center on click
          map.on("click", "unclustered-point", (e) => {
            map.getCanvas().style.cursor = "pointer";
            // Get id from center
            const id = e.features[0].properties.id;
            history.push("/app/center/" + id);
          });
        });
      }
    } else {
      // Case where data is empty and map is rendered
      let map = state.map.current;

      // Transform empty array to geoJSON data
      const geoJSONData = GeoJSON.parse([], {
        Point: ["lat", "lng"],
      });
      map.getSource("centers")?.setData(geoJSONData);
    }
  };

  return <Box height={"80vh"} width={"100vw%"} ref={state.mapContainer}></Box>;
};

export default CentersMap;
