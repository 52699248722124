// MUI
import { Grid } from "@mui/material";
import { useState } from "react";

// Custom components
import CenterSelect from "../../Inputs/CenterSelect";
import FloorPlanEditor from "./FloorPlanEdit/FloorPlanEditor";
import Page from "../../global/structure/Page";

// Others
import { useTranslation } from "react-i18next";

const FloorPlansPage = () => {
  const [t] = useTranslation("floorPlans");
  const [centerId, setCenterId] = useState(null);

  return (
    <Page title={t("floorPlans")}>
      <Grid item>
        <CenterSelect
          onChange={(e) => {
            setCenterId(e.target.value);
          }}
        />
      </Grid>

      <Grid item xs={12} marginTop={2}>
        {centerId && <FloorPlanEditor centerId={centerId} />}
      </Grid>
    </Page>
  );
};

export default FloorPlansPage;
