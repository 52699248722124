import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

import { useContext, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import AppContext from "../../../../context/AppContext";

import PlanEdit from "./PlanEdit";
import LayerEdit from "./LayerEdit";
import FloorPlanRender from "../FloorPlanRender/FloorPlanRender";
import buildRenderData from "../FloorPlanRender/BuildRenderData";

import Button from "../../../Inputs/CustomButton";
import TextInput from "../../../Inputs/TextInput";
import Dialog from "../../../global/Dialog";
import TileEdit from "./TileEdit";

import { today as getToday, formatDate } from "../../../../utils/date";

import {
  TILE_DEFAULT_STROKE_WIDTH,
  TILE_DEFAULT_EDGE_COLOR,
  TILE_BLUE_COLOR,
  TILE_RED_COLOR,
  TILE_WHITE_COLOR,
  FREE_BOX_STATE_ID,
  OCCUPIED_BOX_STATE_ID,
  UNAVAILABLE_BOX_STATE_ID,
  BLOCKED_BOX_STATE_ID,
  BOOKED_BOX_STATE_ID,
} from "../../../../data/constants";

const BOX_COLORS_STATES = [
  TILE_BLUE_COLOR,
  TILE_RED_COLOR,
  TILE_WHITE_COLOR,
  TILE_WHITE_COLOR,
  TILE_WHITE_COLOR,
];

const initialState = {
  loading: false,
  data: null,
  doors: [],
  cameras: [],
  newPlanDialog: {
    open: false,
    loading: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CLOSE_NEW_PLAN_DIALOG":
      return { ...state, newPlanDialog: { open: false } };
    case "OPEN_NEW_PLAN_DIALOG":
      return { ...state, newPlanDialog: { open: true } };
    case "SET_CAMERAS":
      return { ...state, cameras: action.payload };
    case "SET_DOORS":
      return { ...state, doors: action.payload };
    case "SET_FLOOR_PLAN_DATA":
      return { ...state, data: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_NEW_PLAN_DIALOG_VALUE":
      return {
        ...state,
        newPlanDialog: {
          ...state.newPlanDialog,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_SELECTED_CENTER_ID":
      return { ...state, selectedCenterId: action.payload };
  }
};

function FloorPlanEditor(props) {
  const { centerId } = props;
  const { api } = useContext(AppContext);
  const [t] = useTranslation("floorPlans");
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedTile, setSelectedTile] = useState(null);
  let editFunctions = {};
  let renderOptions = {};

  useEffect(() => {
    getDoors();
  }, []);

  useEffect(() => {
    centerId && getData();
    centerId && getCameras();
  }, [centerId]);

  /* Get data functions */
  const getData = () => {
    dispatch({ type: "SET_LOADING", payload: true });
    const params = {
      include: ["FloorPlanTile", "FloorPlanLayer", "Box"],
    };
    api
      .get("/floor-plans/" + centerId, { params })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else {
          const floorPlan = response.data[0];
          floorPlan?.FloorPlanLayers.forEach((layer) => {
            layer.FloorPlanTiles.forEach((tile) => {
              if (tile.color === "") tile.color = null;
              if (tile.color === null && tile.Boxes.length) {
                tile.color = BOX_COLORS_STATES[getBoxState(tile.Boxes[0])];
              }
            });
          });
          dispatch({ type: "SET_FLOOR_PLAN_DATA", payload: floorPlan ?? null });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
        setSelectedTile(null);
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", payload: false });
      });
  };

  const getLayer = (layerId) => {
    return new Promise((resolve, reject) => {
      const params = {
        include: ["FloorPlanTile", "Box"],
      };
      api
        .get(`/floor-plans/layer/${layerId}`, { params })
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
            resolve(response.data.error);
            return;
          } else {
            const newArray = state.data.FloorPlanLayers.map((layer) => {
              if (layer.id === layerId) {
                return {
                  ...response.data,
                  FloorPlanTiles: response.data.FloorPlanTiles.map((tile) => {
                    if (tile.color === null && tile.Boxes.length) {
                      tile.color =
                        tile.Boxes[0].state > -1
                          ? BOX_COLORS_STATES[tile.Boxes[0].state]
                          : BOX_COLORS_STATES[2];
                    }
                    return tile;
                  }),
                };
              }
              return layer;
            });
            dispatch({
              type: "SET_FLOOR_PLAN_DATA",
              payload: { ...state.data, FloorPlanLayers: newArray },
            });
            resolve(response.data);
            return;
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
          setSelectedTile(null);
          reject(error);
          return;
        });
    });
  };

  const getDoors = () => {
    api
      .get("/access-control/doors")
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
          return;
        } else {
          dispatch({ type: "SET_DOORS", payload: res.data });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const getCameras = () => {
    if (!centerId) return;
    const params = { centerId: centerId };
    api
      .get(`/surveillance/cameras`, { params })
      .then((res) => {
        if (res.data.error) {
          enqueueSnackbar(res.data.error, { variant: "error" });
          return;
        } else {
          dispatch({ type: "SET_CAMERAS", payload: res.data });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  /* Create functions */
  const createPlan = () => {
    if (!state.newPlanDialog.width || !state.newPlanDialog.height) {
      enqueueSnackbar(t("widthAndHeightRequired"), { variant: "error" });
      return;
    }
    dispatch({
      type: "SET_NEW_PLAN_DIALOG_VALUE",
      payload: { name: "loading", value: true },
    });
    api
      .post("/floor-plans/create", {
        width: state.newPlanDialog.width,
        height: state.newPlanDialog.height,
        centerId: centerId,
      })
      .then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else {
          dispatch({ type: "CLOSE_NEW_PLAN_DIALOG" });
          getData();
          enqueueSnackbar(t("planCreatedSuccessfully"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
        setSelectedTile(null);
      })
      .finally(() => {
        dispatch({
          type: "SET_NEW_PLAN_DIALOG_VALUE",
          payload: { name: "loading", value: false },
        });
      });
  };

  const createLayer = (layer) => {
    try {
      if (!layer || !layer.name || !layer.floorPlanId) {
        enqueueSnackbar(t("layerNameAndFloorPlanIdRequired"), {
          variant: "error",
        });
        return;
      }
      api.post("/floor-plans/layer/create", layer).then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else {
          dispatch({
            type: "SET_FLOOR_PLAN_DATA",
            payload: {
              ...state.data,
              FloorPlanLayers: [
                ...state.data.FloorPlanLayers,
                { ...response.data, FloorPlanTiles: [] },
              ],
            },
          });
        }
      });
    } catch (error) {
      enqueueSnackbar(error.toString(), { variant: "error" });
      setSelectedTile(null);
    }
  };

  const createTile = (tile) => {
    let data = tile;
    if (tile.shape === "Image") {
      data = new FormData();
      Object.entries(tile).forEach((entry) => data.append(entry[0], entry[1]));
    }

    try {
      api.post("/floor-plans/tile/create", data).then((response) => {
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else {
          const newArray = state.data.FloorPlanLayers.map((layer) => {
            if (layer.id === tile.floorPlanLayerId) {
              return {
                ...layer,
                FloorPlanTiles: [...layer.FloorPlanTiles, response.data],
              };
            }
            return layer;
          });
          dispatch({
            type: "SET_FLOOR_PLAN_DATA",
            payload: { ...state.data, FloorPlanLayers: newArray },
          });
          enqueueSnackbar(t("tileCreatedSuccessfully"), { variant: "success" });
        }
      });
    } catch (error) {
      enqueueSnackbar(error.toString(), { variant: "error" });
      setSelectedTile(null);
    }
  };

  const createBoxFloorPlanTile = (params) => {
    if (!params || !params.BoxId || !params.FloorPlanTileId) {
      enqueueSnackbar(t("somethingWentWrong"), { variant: "error" });
      return;
    }
    api
      .post("/floor-plans/box-tile/create", params)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else getData();
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  /* Delete functions */
  const deleteBoxFloorPlanTile = (params) => {
    if (!params || !params.BoxId || !params.FloorPlanTileId) {
      enqueueSnackbar(t("somethingWentWrong"), { variant: "error" });
      return;
    }
    api
      .post("/floor-plans/box-tile/delete", params)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else getData();
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
  };

  const deleteLayer = (layerId) => {
    api
      .post(`/floor-plans/layer/delete/${layerId}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
          return;
        }
        getData();
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
        setSelectedTile(null);
      });
  };

  const deleteTile = (tileId) => {
    api
      .post(`/floor-plans/tile/delete/${tileId}`)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
          return;
        }
        const newArray = state.data.FloorPlanLayers.map((layer) => {
          layer.FloorPlanTiles = layer.FloorPlanTiles.filter(
            (tile) => tile.id !== tileId
          );
          return layer;
        });
        dispatch({
          type: "SET_FLOOR_PLAN_DATA",
          payload: { ...state.data, FloorPlanLayers: newArray },
        });
        enqueueSnackbar(t("tileDeletedSuccessfully"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
        setSelectedTile(null);
      });
  };

  /* Edit functions */
  const editPlan = (plan) => {
    if (!plan.id || !plan.width || !plan.height) {
      enqueueSnackbar(t("somethingWentWrong", { variant: "error" }));
      return true;
    }
    api
      .post(`/floor-plans/${plan.id}`, plan)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_FLOOR_PLAN_DATA",
            payload: {
              ...state.data,
              height: response.data.height,
              width: response.data.width,
              backgroundColor: response.data.backgroundColor,
            },
          });
          enqueueSnackbar(t("planEditedSuccessfully"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
        setSelectedTile(null);
      });
  };

  const editLayer = (layer) => {
    if (!layer.id || !layer.name || !layer.floorPlanId) {
      enqueueSnackbar(t("somethingWentWrong"), { variant: "error" });
      return;
    }
    if (layer.z > state.data.FloorPlanLayers.length) {
      enqueueSnackbar(t("zValueIsGreaterThanTheNumberOfLayers"), {
        variant: "error",
      });
      return;
    }
    api
      .post(`/floor-plans/layer/${layer.id}`, layer)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          getData();
          enqueueSnackbar(t("layerEditedSuccessfully"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
        setSelectedTile(null);
      });
  };

  const editTile = (tile) => {
    return new Promise((resolve, reject) => {
      if (tile === null || tile === undefined) {
        enqueueSnackbar(t("somethingWentWrong"), { variant: "error" });
        reject(t("somethingWentWrong"));
        return;
      }
      api
        .post(`/floor-plans/tile/${tile.id}`, tile)
        .then(async (response) => {
          if (response.data.error) {
            setSelectedTile(null);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            let layerIndex = null;
            state.data.FloorPlanLayers.forEach((layer, index) => {
              layer.FloorPlanTiles.forEach((t) => {
                if (Number(t.id) === Number(tile.id)) layerIndex = index;
              });
            });
            await getLayer(state.data.FloorPlanLayers[layerIndex].id);
            enqueueSnackbar(t("tileEditedSuccessfully"), {
              variant: "success",
            });
          }
          resolve(response.data);
          return;
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
          setSelectedTile(null);
          reject(error);
          return;
        });
    });
  };

  /* Action functions */
  const openDoor = (doorId, interval) => {
    return new Promise((resolve, reject) => {
      if (!doorId) {
        enqueueSnackbar(t("doorIdNotDefined"), { variant: "error" });
        reject(t("doorIdNotDefined"));
        return;
      }
      api
        .post(`/access-control/door/${doorId}/open`, { interval })
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(response.data.error, { variant: "error" });
            reject(response.data.error);
            return;
          } else if (response.data === "El dispositivo no existe") {
            enqueueSnackbar(response.data, { variant: "error" });
            reject(response.data);
            return;
          } else {
            enqueueSnackbar(t("doorOpenedSuccessfully"), {
              variant: "success",
            });
            resolve(response.data);
            return;
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
          setSelectedTile(null);
          reject(error);
          return;
        });
    });
  };

  /* Process data functions */
  const tileRender = (tile) => {
    const isEditing = Number(selectedTile?.id) === Number(tile.id);
    return (
      <TileEdit
        key={tile.id}
        tile={isEditing ? selectedTile : tile}
        stroke={TILE_DEFAULT_EDGE_COLOR}
        strokeWidth={TILE_DEFAULT_STROKE_WIDTH}
        fill={tile.color ?? TILE_BLUE_COLOR}
        isEditing={isEditing}
        onEditTile={setSelectedTile}
        closed={true}
        points={tile.points ?? undefined}
        openDoor={openDoor}
      />
    );
  };

  const updateValues = () => {
    // Edit functions
    editFunctions = {
      createBoxFloorPlanTile,
      createTile,
      deleteBoxFloorPlanTile,
      deleteLayer,
      deleteTile,
      editTile,
      editLayer,
      editPlan,
      setSelectedTile,
    };

    // Render options
    const isEditing = selectedTile !== null;
    renderOptions = {
      blockDragging: isEditing,
      cursorStyle: isEditing ? "move" : undefined,
    };
  };

  const getBoxState = (box) => {
    let state = box.state > -1 ? box.state : UNAVAILABLE_BOX_STATE_ID;
    const today = getToday();
    const lastContract = box.Contracts.length
      ? box.Contracts[box.Contracts.length - 1]
      : null;
    if (!lastContract || state > 2) return state;
    const lastContractEnd = lastContract.endDate
      ? formatDate(new Date(lastContract.endDate))
      : null;
    if (lastContractEnd && lastContractEnd < today) return FREE_BOX_STATE_ID;
    return OCCUPIED_BOX_STATE_ID;
  };

  /* Handlers functions */
  const addLayerHandler = () => {
    createLayer({
      name: "Layer " + (state.data.FloorPlanLayers.length + 1),
      floorPlanId: state.data.id,
    });
  };

  const handleOpenDialog = () => {
    dispatch({ type: "OPEN_NEW_PLAN_DIALOG" });
  };

  const handleDialogInputChange = (e) => {
    dispatch({
      type: "SET_NEW_PLAN_DIALOG_VALUE",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  updateValues();

  return state.loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : state.data ? (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FloorPlanRender
          data={buildRenderData(state.data, tileRender)}
          menu={true}
          options={renderOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <EditorLayout
          t={t}
          centerId={centerId}
          state={state}
          addLayerHandler={addLayerHandler}
          functions={editFunctions}
          selectedTileData={selectedTile}
        />
      </Grid>
    </Grid>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Button onClick={handleOpenDialog}>{t("createNewPlan")}</Button>
      <Dialog
        title={t("createNewPlan")}
        open={state.newPlanDialog.open}
        onClose={() => {
          dispatch({ type: "CLOSE_NEW_PLAN_DIALOG" });
        }}
        maxWidth="lg"
        actions={
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="text"
                onClick={() => dispatch({ type: "CLOSE_NEW_PLAN_DIALOG" })}
              >
                {t("cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="success"
                disabled={
                  !state.newPlanDialog.width ||
                  !state.newPlanDialog.height ||
                  state.newPlanDialog.loading
                }
                onClick={createPlan}
              >
                {t("create")}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t("enterTheValuesToCreateANewPlan")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item md={6} xs={12}>
              <TextInput
                label={t("width")}
                name="width"
                type="number"
                value={state.newPlanDialog.width}
                onChange={handleDialogInputChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextInput
                label={t("height")}
                name="height"
                type="number"
                value={state.newPlanDialog.height}
                onChange={handleDialogInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

function EditorLayout(props) {
  const { t, centerId, state, addLayerHandler, functions, selectedTileData } =
    props;
  return (
    <Paper square>
      <Container sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid
            xs={12}
            container
            item
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5">{t("floorPlan")}</Typography>
            </Grid>
            <Grid item>
              <PlanEdit data={state.data} editPlan={functions.editPlan} />
            </Grid>
          </Grid>

          <Grid container item alignItems="center">
            <Grid item>
              <Typography variant="h5">{t("layers")}</Typography>
            </Grid>
            <Grid item flexGrow={1}></Grid>
            <Grid item>
              <Button
                color="primary"
                title={t("addLayer")}
                onClick={addLayerHandler}
              >
                {t("addLayer")}
              </Button>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={1}>
            {state.data.FloorPlanLayers.sort((a, b) => a.z - b.z).map(
              (layer) => (
                <Grid item xs={12}>
                  {
                    <LayerEdit
                      data={{
                        ...layer,
                        centerId: centerId,
                        numberOfLayers: state.data.FloorPlanLayers.length,
                      }}
                      functions={functions}
                      doors={state.doors}
                      cameras={state.cameras}
                      selectedTileData={selectedTileData}
                    />
                  }
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}

export default FloorPlanEditor;
