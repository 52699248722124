// MUI COMPONENTS
import {
  InputAdornment,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

// CUSTOM COMPONENTS
import BoxStateChip from "../../BoxStateChip";
import BoxClusterChip from "../../BoxClusterChip";

// OTHERS
import { localeFormat } from "../../../utils/format";
import { useTranslation } from "react-i18next";
import {
  GENERAL_VAT_ES,
  UNAVAILABLE_BOX_STATE_ID,
} from "../../../data/constants";
import TextInput from "../../Inputs/TextInput";
import { round } from "../../../utils/math";
import { useEffect, useMemo, useReducer, useState } from "react";

const lightGreyBorder = "1px solid #ccc";
const darkGreyBorder = "1px solid #999";
const thickDarkGreyBorder = "2px solid #999";
const cellWidth = "30px";
const cellPadding = 1;

const SPANISH_VAT_MULTIPLIER = (100 + GENERAL_VAT_ES) / 100;

const CELL_STYLE = {
  border: lightGreyBorder,
  padding: cellPadding,
  width: cellWidth,
};
const HEADER_CELL_STYLE = {
  ...CELL_STYLE,
  border: darkGreyBorder,
};
const TOTALS_CELL_STYLE = {
  ...CELL_STYLE,
  borderBottom: darkGreyBorder,
  borderTop: thickDarkGreyBorder,
  fontWeight: "bold",
};

const initialState = {
  boxes: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_BOXES":
      return {
        ...state,
        boxes: action.payload,
      };
    case "UPDATE_BOX":
      return {
        ...state,
        boxes: state.boxes.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
      };
    default:
      return "Action type not found";
  }
}

const DiscountedPriceCell = ({ value, item, onSubmit }) => {
  const [price, setPrice] = useState(round(value));

  useEffect(() => {
    setPrice(round(value));
  }, [item.price]);

  const handleOnChange = (e) => {
    e.stopPropagation();
    setPrice(e.target.value);
  };

  const handleOnSubmit = (e) => {
    e.stopPropagation();
    setPrice(round(e.target.value));
    const box = {
      ...item,
      price: round(e.target.value),
      pricePerMeter: round(e.target.value / item.meters),
    };
    onSubmit(box);
  };

  return (
    <TextInput
      type="number"
      value={price}
      variant="standard"
      onChange={handleOnChange}
      onBlur={handleOnSubmit}
      onKeyPress={(e) => {
        e.key === "Enter" && handleOnSubmit(e);
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
      }}
    />
  );
};

const DiscountPercentageCell = ({ value, item, onSubmit }) => {
  const [percentage, setPercentage] = useState(
    round(100 - (value * 100) / item.cluster?.price)
  );

  useEffect(() => {
    setPercentage(round(100 - (value * 100) / item.cluster?.price));
  }, [item.price]);

  const handleOnChange = (e) => {
    e.stopPropagation();
    setPercentage(e.target.value);
  };

  const handleOnSubmit = (e) => {
    e.stopPropagation();
    setPercentage(round(e.target.value));
    const newPrice = round((item.cluster.price * (100 - e.target.value)) / 100);
    const box = {
      ...item,
      price: newPrice,
      pricePerMeter: round(newPrice / item.meters),
    };
    onSubmit(box);
  };

  return item.cluster ? (
    <TextInput
      type="number"
      value={percentage}
      variant="standard"
      onChange={handleOnChange}
      onBlur={handleOnSubmit}
      onKeyPress={(e) => {
        e.key === "Enter" && handleOnSubmit(e);
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  ) : (
    <Typography variant="body2">-</Typography>
  );
};

function BoxClustersTable({
  acquisitionAsset,
  boxes,
  onChangeBoxes,
  loading,
  editable = true,
  resetKey = 0,
}) {
  const [t] = useTranslation("revenueManagement");
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "SET_BOXES", payload: boxes });
  }, [boxes, resetKey]);

  // Acquisition calculations
  const GIA =
    Number(acquisitionAsset?.currentArea) +
    Number(acquisitionAsset?.aditionalConstructability);
  const lettableArea =
    GIA * (Number(acquisitionAsset?.lettableAreaRatio) / 100);
  const objectiveAveragePrice = Number(acquisitionAsset?.sqmPriceMonth);
  const objectiveOccupancy = Number(acquisitionAsset?.occupancy);
  const objectiveMonthlyInvoicing =
    (lettableArea * objectiveAveragePrice * objectiveOccupancy) / 100;

  const totals = useMemo(() => {
    const {
      totalMeters,
      totalMonthlyInvoicingWithDiscount,
      totalMonthlyInvoicingMaximum,
      occupiedMeters,
      totalMonthlyInvoicingActual,
    } = state.boxes.reduce(
      (acc, box) => {
        if (box.state !== UNAVAILABLE_BOX_STATE_ID) {
          acc.totalMeters += box.meters;
          acc.totalMonthlyInvoicingWithDiscount += box.price;
          acc.totalMonthlyInvoicingMaximum += box.cluster?.price || 0;
          if (box.contract) {
            acc.occupiedMeters += box.contract.meters;
            acc.totalMonthlyInvoicingActual += box.contract.price;
          }
        }
        return acc;
      },
      {
        totalMeters: 0,
        totalMonthlyInvoicingWithDiscount: 0,
        totalMonthlyInvoicingMaximum: 0,
        occupiedMeters: 0,
        totalMonthlyInvoicingActual: 0,
      }
    );

    const averagePriceMaximum = totalMonthlyInvoicingMaximum / totalMeters;
    const averagePriceWithDiscount =
      totalMonthlyInvoicingWithDiscount / totalMeters;
    const averagePriceActual = totalMonthlyInvoicingActual / occupiedMeters;

    return {
      totalMeters,
      averagePriceMaximum,
      totalMonthlyInvoicingMaximum,
      averagePriceWithDiscount,
      totalMonthlyInvoicingWithDiscount,
      averagePriceActual,
      totalMonthlyInvoicingActual,
      objectiveMonthlyInvoicing,
      lettableArea,
      objectiveAveragePrice,
    };
  }, [state.boxes]);

  const getColor = (actual, objective) => {
    if (actual > objective) return "success.light";
    else if (actual < objective) return "error.light";
    else return undefined;
  };

  const CLUSTER_COLUMNS = [
    {
      align: "center",
      key: "name",
      label: t("name"),
      style: { ...CELL_STYLE, borderLeft: lightGreyBorder },
    },
    {
      key: "meters",
      label: t("meters"),
      render: (value) => localeFormat(value) + " m²",
    },
    {
      key: "cluster",
      label: t("cluster"),
      render: (value, item) =>
        value ? <BoxClusterChip name={value.name} /> : "-",
    },
    {
      key: "state",
      label: t("state"),
      render: (value) => <BoxStateChip state={value} />,
      style: { ...CELL_STYLE, borderRight: lightGreyBorder },
    },
    {
      key: "cluster",
      label: t("perMeter"),
      render: (value, item) =>
        value ? localeFormat(value.price / item.meters) + " €/m²" : "-",
      style: { ...CELL_STYLE, borderLeft: lightGreyBorder },
    },
    {
      label: t("withoutIVA"),
      key: "cluster",
      render: (value) => (value ? localeFormat(value.price) + " €" : "-"),
    },
    {
      label: t("withIVA"),
      key: "cluster",
      render: (value) =>
        value ? localeFormat(value.price * SPANISH_VAT_MULTIPLIER) + " €" : "-",
      style: { ...CELL_STYLE, borderRight: lightGreyBorder },
    },
    {
      label: t("perMeter"),
      key: "pricePerMeter",
      render: (value) => localeFormat(value) + " €/m²",
    },
    {
      label: t("discount"),
      key: "price",
      renderCell: (value, item) =>
        editable ? (
          <DiscountPercentageCell
            value={value}
            item={item}
            onSubmit={onSubmit}
          />
        ) : (
          <Typography variant="body2">
            {value
              ? localeFormat(100 - (value * 100) / item.cluster?.price) + " %"
              : "-"}
          </Typography>
        ),
      style: { ...CELL_STYLE, borderLeft: lightGreyBorder },
    },
    {
      label: t("withoutIVA"),
      key: "price",
      renderCell: (value, item) =>
        editable ? (
          <DiscountedPriceCell value={value} item={item} onSubmit={onSubmit} />
        ) : (
          <Typography variant="body2">
            {value ? localeFormat(value) + " €" : "-"}
          </Typography>
        ),
    },
    {
      label: t("withIVA"),
      key: "meters",
      render: (value, item) =>
        localeFormat(value * item.pricePerMeter * SPANISH_VAT_MULTIPLIER) +
        " €",
      style: { ...CELL_STYLE, borderRight: lightGreyBorder },
    },
    {
      label: t("perMeter"),
      key: "contract",
      render: (value, item) =>
        value ? localeFormat(item.contract.pricePerMeter) + " €/m²" : "-",
      style: { ...CELL_STYLE, borderLeft: lightGreyBorder },
    },
    {
      label: t("withoutIVA"),
      key: "contract",
      render: (value, item) =>
        value
          ? localeFormat(item.contract.meters * item.contract.pricePerMeter) +
            " €"
          : "-",
    },
    {
      label: t("withIVA"),
      key: "contract",
      render: (value, item) =>
        value
          ? localeFormat(
              item.contract.meters *
                item.contract.pricePerMeter *
                SPANISH_VAT_MULTIPLIER
            ) + " €"
          : "-",
      style: { ...CELL_STYLE, borderRight: lightGreyBorder },
    },
  ];

  const TOTALS = [
    {
      value: t("totals"),
      style: { ...TOTALS_CELL_STYLE, borderLeft: lightGreyBorder },
    },
    {
      value: localeFormat(totals.totalMeters) + " m²",
      style: {
        ...TOTALS_CELL_STYLE,
        color: getColor(totals.totalMeters, totals.lettableArea),
      },
    },
    { style: TOTALS_CELL_STYLE },
    { style: { ...TOTALS_CELL_STYLE, borderRight: lightGreyBorder } },
    {
      value: localeFormat(totals.averagePriceMaximum || 0) + " €/m²",
      style: {
        ...TOTALS_CELL_STYLE,
        borderLeft: lightGreyBorder,
        color: getColor(
          totals.averagePriceMaximum,
          totals.objectiveAveragePrice
        ),
      },
    },
    {
      value: localeFormat(totals.totalMonthlyInvoicingMaximum || 0) + " €",
      style: {
        ...TOTALS_CELL_STYLE,
        color: getColor(
          totals.totalMonthlyInvoicingMaximum,
          totals.objectiveMonthlyInvoicing
        ),
      },
    },
    {
      value:
        localeFormat(
          totals.totalMonthlyInvoicingMaximum * SPANISH_VAT_MULTIPLIER || 0
        ) + " €",
      style: {
        ...TOTALS_CELL_STYLE,
        borderRight: lightGreyBorder,
        color: getColor(
          totals.totalMonthlyInvoicingMaximum,
          totals.objectiveMonthlyInvoicing
        ),
      },
    },
    {
      value: localeFormat(totals.averagePriceWithDiscount) + " €/m²",
      style: {
        ...TOTALS_CELL_STYLE,
        borderLeft: lightGreyBorder,
        color: getColor(
          totals.averagePriceWithDiscount,
          totals.objectiveAveragePrice
        ),
      },
    },
    { style: { ...TOTALS_CELL_STYLE, borderLeft: lightGreyBorder } },
    {
      value: localeFormat(totals.totalMonthlyInvoicingWithDiscount) + " €",
      style: {
        ...TOTALS_CELL_STYLE,
        color: getColor(
          totals.totalMonthlyInvoicingWithDiscount,
          totals.objectiveMonthlyInvoicing
        ),
      },
    },
    {
      value:
        localeFormat(
          totals.totalMonthlyInvoicingWithDiscount * SPANISH_VAT_MULTIPLIER
        ) + " €",
      style: {
        ...TOTALS_CELL_STYLE,
        borderRight: lightGreyBorder,
        color: getColor(
          totals.totalMonthlyInvoicingWithDiscount,
          totals.objectiveMonthlyInvoicing
        ),
      },
    },
    {
      value: localeFormat(totals.averagePriceActual) + " €/m²",
      style: {
        ...TOTALS_CELL_STYLE,
        borderLeft: lightGreyBorder,
        color: getColor(
          totals.averagePriceActual,
          totals.objectiveAveragePrice
        ),
      },
    },
    {
      value: localeFormat(totals.totalMonthlyInvoicingActual) + " €",
      style: {
        ...TOTALS_CELL_STYLE,
        color: getColor(
          totals.totalMonthlyInvoicingActual,
          totals.objectiveMonthlyInvoicing
        ),
      },
    },
    {
      value:
        localeFormat(
          totals.totalMonthlyInvoicingActual * SPANISH_VAT_MULTIPLIER
        ) + " €",
      style: {
        ...TOTALS_CELL_STYLE,
        borderRight: lightGreyBorder,
        color: getColor(
          totals.totalMonthlyInvoicingActual,
          totals.objectiveMonthlyInvoicing
        ),
      },
    },
  ];

  const handleOnChangeBox = (modifiedBox) => {
    if (!onChangeBoxes) throw new Error("onChangeBoxes is not defined");
    const newBoxes = state.boxes.map((box) =>
      box.id === modifiedBox.id ? modifiedBox : box
    );
    onChangeBoxes(newBoxes);
  };

  const onSubmit = (box) => {
    const boxToUpdate = state.boxes.find((b) => b.id === box.id);

    // Check if the property of the box has changed
    if (boxToUpdate.price === box.price) return;

    dispatch({
      type: "UPDATE_BOX",
      payload: box,
    });
    handleOnChangeBox(box);
  };

  return !state.boxes?.length ? (
    <></>
  ) : loading ? (
    <Skeleton variant="rectangular" width="100%" height={400} />
  ) : (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={4} style={HEADER_CELL_STYLE}>
                {t("boxInformation")}
              </TableCell>
              <TableCell align="center" colSpan={3} style={HEADER_CELL_STYLE}>
                {t("maxPrice")}
              </TableCell>
              <TableCell align="center" colSpan={4} style={HEADER_CELL_STYLE}>
                {t("discountedPrice")}
              </TableCell>
              <TableCell align="center" colSpan={3} style={HEADER_CELL_STYLE}>
                {t("priceOfContract")}
              </TableCell>
            </TableRow>
            <TableRow>
              {CLUSTER_COLUMNS.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align ?? "center"}
                  sx={HEADER_CELL_STYLE}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {TOTALS.map((total, index) => (
                <TableCell
                  key={state.boxes.length + index}
                  align={total.align ?? "center"}
                  sx={total.style}
                >
                  {total.render ? total.render(total) : total.value}
                </TableCell>
              ))}
            </TableRow>
            {state.boxes.map((box) => (
              <TableRow hover key={box.id}>
                {CLUSTER_COLUMNS.map((column, index2) => {
                  const value = box[column.key];
                  let render = column.render
                    ? column.render(value, box)
                    : value;
                  if (column.renderCell) render = column.renderCell(value, box);
                  return (
                    <TableCell
                      key={index2}
                      align={column.align ?? "center"}
                      sx={column.style ? column.style : CELL_STYLE}
                    >
                      {render}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default BoxClustersTable;
