import {
  Dialog,
  DialogActions,
  DialogContent,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  FormGroup,
  Grid,
  List,
  ListItem,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";

import AppContext from "../../../context/AppContext";
import BoxStateChip from "../../BoxStateChip";
import Button from "../../Inputs/CustomButton";
import CustomSelect from "../../Inputs/CustomSelect";
import PhoneInput from "../../Inputs/PhoneInput";
import PostalCodeInput from "../../Inputs/PostalCodeInput";
import TextInput from "../../Inputs/TextInput";
import { CustomTable } from "../../CustomTable";

const initialState = {
  form: {
    phoneNumber: "",
    customerName: "",
    postalCode: "",
    customerEmail: "",
    wantCallBack: false,
    centerId: "",
    comments: "",
    departmentId: "",
    callReasonTypes: [],
  },
  inputError: {
    phoneNumber: false,
    customerName: false,
    postalCode: false,
    customerEmail: false,
    wantCallBack: false,
    centerId: false,
    comments: false,
    departmentId: false,
  },
  callReasons: [],
  calls: [],
  centers: [],
  departments: [],
  id: null,
  callBackDialog: {
    isOpen: false,
    sendEmailCenter: "no",
    sendEmailDep: "no",
  },
  options: { rowlink: "box" },
  loading: false,
  filter: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_CENTER":
      return { ...state, form: { ...state.form, Center: action.payload } };
    case "SET_CALLS":
      return { ...state, calls: action.payload };
    case "SET_DEPARTMENTS":
      return { ...state, departments: action.payload };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_REASONS":
      return { ...state, callReasons: action.payload };
    case "SET_CALLBACK_DIALOG":
      return {
        ...state,
        callBackDialog: {
          ...state.callBackDialog,
          isOpen: !state.callBackDialog.isOpen,
        },
      };
    case "SET_INPUT_CALLBACK_DIALOG":
      return {
        ...state,
        callBackDialog: {
          ...state.callBackDialog,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_FILTER":
      return { ...state, filter: action.payload };
    default:
      throw new Error();
  }
}

export default function CreateCallPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("calls");

  //Dinamic size page
  const [maxWidth, setMaxWidth] = useState("md");
  const [xs, setXs] = useState(0);
  const [lg, setLg] = useState(0);

  // Columns for table of boxes
  const columns = [
    {
      key: "name",
      label: t("name"),
      sortType: "string",
    },
    {
      key: "meters",
      label: t("meters"),
      sortType: "number",
      renderFunction: (value) => value + "㎡",
    },
    {
      key: "pricePerMeter",
      label: t("pricePerMeter"),
      sortType: "number",
      renderFunction: (value) => value + "€",
    },
    {
      key: "state",
      label: t("state"),
      sortType: "number",
      renderFunction: (state) => <BoxStateChip state={state} />,
    },
  ];

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleInputChangeCheckbox = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.checked,
      },
    });
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("createCallPage");

    getCenters();
    getCallReasons();
    getDepartments();
  }, []);

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, { variant: "error" });
        } else {
          dispatch({
            type: "SET_CENTERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const getCallReasons = () => {
    api
      .get("/calls/reasons")
      .then((response) => {
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, { variant: "error" });
        } else {
          dispatch({ type: "SET_REASONS", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const getDepartments = () => {
    api
      .get("/calls/departments")
      .then((response) => {
        if (response.data.error) {
          const msg = response.data.error.errors
            ? response.data.error.errors[0].message
            : response.data.error;
          enqueueSnackbar(msg, { variant: "error" });
        } else {
          dispatch({ type: "SET_DEPARTMENTS", payload: response.data });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const submitForm = () => {
    if (validateForm()) {
      dispatch({ type: "SET_LOADING", payload: true });
      //Obligatory fields:
      let data = {
        phoneNumber: state.form.phoneNumber,
        customerName: state.form.customerName,
        wantCallBack: state.form.wantCallBack,
        createdBy: user.id,
        comments: state.form.comments,
        centerComments: state.form.centerComments,
        callReasonTypes: state.form.callReasonTypes,
      };
      //Optional fields:
      state.form.postalCode !== "" && (data.postalCode = state.form.postalCode);
      state.form.customerEmail !== "" &&
        (data.customerEmail = state.form.customerEmail);
      state.form.callBackAt !== "" && (data.callBackAt = state.form.callBackAt);
      state.form.centerId !== "" && (data.centerId = state.form.centerId);
      state.form.departmentId !== "" &&
        (data.departmentId = state.form.departmentId);

      api
        .post("/calls/create", data)
        .then((response) => {
          dispatch({ type: "SET_LOADING", payload: false });
          if (response.data.error) {
            const msg = response.data.error.errors
              ? response.data.error.errors[0].message
              : response.data.error;
            enqueueSnackbar(msg, { variant: "error" });
          } else {
            enqueueSnackbar(t("callCreateSuccess"), { variant: "success" });
            if (state.form.wantCallBack) handleCallbackDialog();
            else history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error, { variant: "error" });
        });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let fields = ["customerName", "phoneNumber"];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(field + " is required.", { variant: "error" });
        isValid = false;
      }
    });

    const re = /\S+@\S+\.\S+/; //Regex for testing email format

    if (state.form.customerEmail !== null && state.form.customerEmail !== "") {
      if (
        !state.form.customerEmail ||
        state.form.customerEmail === "" ||
        !re.test(state.form.customerEmail)
      ) {
        setInputErrorTrue("customerEmail");
        enqueueSnackbar("Email must be valid", { variant: "error" });
        isValid = false;
      }
    }

    return isValid;
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: { inputname: name },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: { inputname: name },
    });
  };

  const setCenter = (e) => {
    const filtered = state.centers.filter((item) => item.id === e.target.value);
    let center = filtered[0];

    api
      .get("/centers/" + center.id + "/boxes")
      .then((response) => {
        center.boxes = response.data;
        dispatch({ type: "SET_CENTER", payload: center });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCallReasonCheck = (e, callReason) => {
    if (e.target.checked) {
      let reasons =
        state.form.callReasonTypes?.length > 0
          ? [...state.form.callReasonTypes, callReason]
          : [callReason];

      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "callReasonTypes",
          value: reasons,
        },
      });
    } else {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "callReasonTypes",
          value: state.form.callReasonTypes.filter(
            (reason) => callReason !== reason
          ),
        },
      });
    }
  };

  const handleChangeFilter = (e) => {
    dispatch({ type: "SET_FILTER", payload: e.target.value });

    const filtered = state.centers.filter(
      (item) => item.id === state.form.centerId
    );
    let center = filtered[0];

    let params = {};
    params.state = e.target.value;

    api
      .get("/centers/" + center.id + "/boxes", { params })
      .then((response) => {
        center.boxes = response.data;
        dispatch({ type: "SET_CENTER", payload: center });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCallbackDialog = () => {
    dispatch({ type: "SET_CALLBACK_DIALOG" });
  };

  const handleInputCallbackChange = (input, e) => {
    dispatch({
      type: "SET_INPUT_CALLBACK_DIALOG",
      payload: {
        inputname: input,
        value: e.target.value,
      },
    });
  };

  const sendEmail = () => {
    let form = {
      sendEmailCenter: state.sendEmailCenter,
      sendEmailDep: state.sendEmailDep,
    };

    api
      .post("/calls/:id/send-email", form)
      .then((response) => {
        if (!response.data.error) {
          enqueueSnackbar(response.data, { variant: "success" });
          handleCallbackDialog();
          history.goBack();
        } else {
          enqueueSnackbar(response.data.error, { variant: "error" });
        }
      })
      .catch((error) => console.log(error));
  };

  const getCalls = () => {
    if (state.form.phoneNumber != "") {
      let params = { text: state.form.phoneNumber };
      api
        .get("/calls", { params })
        .then((response) => {
          if (response.data.error) {
            let msg;
            response.data.error.errors
              ? (msg = response.data.error?.errors[0]?.message)
              : (msg = response.data.error);
            enqueueSnackbar(msg.toString(), { variant: "error" });
          } else {
            response.data.length > 0 &&
              dispatch({ type: "SET_CALLS", payload: response.data });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Container sx={{ marginY: 3 }} maxWidth={maxWidth}>
      <Grid container spacing={3}>
        <Grid item xs={xs} lg={lg}>
          <Paper sx={{ padding: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">{t("createCall")}</Typography>
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} sm={6} md={3} lg={6}>
                  <TextInput
                    error={state.inputError.customerName}
                    helperText={
                      state.inputError.customerName
                        ? "Name must not be blank"
                        : ""
                    }
                    label={t("name")}
                    value={state.form.customerName}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    name="customerName"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={6}>
                  <PhoneInput
                    country={"es"}
                    InputProps={{
                      error: state.inputError.phoneNumber,
                      label: t("phoneNumber"),
                      name: "phoneNumber",
                      onChange: (e) => {
                        handleInputChange(e);
                      },
                      onBlur: () => {
                        getCalls();
                      },
                    }}
                    onChange ={(e) => handleInputChange({ target: { name: "phoneNumber", value: e } })}
                    value={state.form.phoneNumber}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <TextInput
                    error={state.inputError.customerEmail}
                    helperText={
                      state.inputError.customerEmail
                        ? "Email must be valid."
                        : ""
                    }
                    variant="outlined"
                    label={t("email")}
                    value={state.form.customerEmail}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    name="customerEmail"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    label={t("center")}
                    value={state.form.centerId}
                    onChange={(e) => {
                      handleInputChange(e);
                      setCenter(e);
                      setMaxWidth("xl");
                      setXs(12);
                      setLg(6);
                    }}
                    name="centerId"
                    options={state.centers?.map((center) => ({
                      value: center.id,
                      label: center.name,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomSelect
                    label={t("department")}
                    value={state.form.departmentId}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    name="departmentId"
                    options={[
                      { value: "", label: t("none") },
                      ...state.departments.map((department) => ({
                        value: department.id,
                        label: department.name + " - " + department.email,
                      })),
                    ]}
                  />
                </Grid>
                <Grid item xs={6} md={3} lg={6}>
                  <PostalCodeInput
                    error={state.inputError.postalCode}
                    helperText={
                      state.inputError.postalCode
                        ? "Postal code must not be blank"
                        : ""
                    }
                    value={state.form.postalCode}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    label={t("postalCode")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <FormControlLabel
                      label={t("wantCallBack")}
                      control={
                        <Checkbox
                          checked={state.form.wantCallBack}
                          name="wantCallBack"
                          onChange={(e) => {
                            handleInputChangeCheckbox(e);
                          }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                      labelPlacement="start"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    error={state.inputError.comments}
                    helperText={
                      state.inputError.comments
                        ? "Comments must not be blank"
                        : ""
                    }
                    label={t("comments")}
                    multiline
                    rows={4}
                    value={state.form.comments}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    name="comments"
                  />
                </Grid>

                <Grid item container>
                  <Grid item xs={12}>
                    <Typography variant="body1" fontWeight={500}>
                      {t("callReasons")}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <FormGroup>
                      {state.callReasons.map((reason) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.form.callReasonTypes?.includes(
                                reason.id
                              )}
                              onChange={(e) => {
                                handleCallReasonCheck(e, reason.id);
                              }}
                            />
                          }
                          label={reason.name}
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                spacing={1}
                xs={12}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button onClick={() => history.goBack()} variant="text">
                    {t("back")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={submitForm} loading={state.loading}>
                    {t("create")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          {state.calls.length > 0 && state.form.phoneNumber && (
            <Grid item xs={xs} sx={{ marginTop: "10px" }}>
              <Paper sx={{ padding: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{t("calls")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <List component="div">
                      {state.calls.map((call) => (
                        <ListItem>
                          <Card variant="outlined">
                            <CardContent>
                              <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Phone number:
                                  </Typography>
                                  <Typography variant="body">
                                    {" "}
                                    {call?.phoneNumber}
                                  </Typography>
                                  <Grid item xs={12}></Grid>
                                  <Typography
                                    variant="body"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Name:
                                  </Typography>
                                  <Typography variant="body">
                                    {" "}
                                    {call?.customerName}
                                  </Typography>
                                  <Grid item xs={12}></Grid>
                                  <Typography
                                    variant="body"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Email:
                                  </Typography>
                                  <Typography variant="body">
                                    {" "}
                                    {call?.customerEmail}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Comments:
                                  </Typography>
                                  <Typography variant="body">
                                    {" "}
                                    {call?.comments}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>

        {state.form.centerId && (
          <Grid item xs={12} lg={6}>
            <Paper sx={{ padding: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t("centerInfo")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: state.form.Center?.comments,
                    }}
                  />
                </Grid>
                {state.form.Center && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6">{t("boxes")}</Typography>
                      <CustomSelect
                        label={t("state")}
                        value={state.filter}
                        onChange={handleChangeFilter}
                        options={[
                          { value: "", label: t("all") },
                          { value: 0, label: t("free") },
                          { value: 1, label: t("occupied") },
                          { value: 2, label: t("unavailable") },
                        ]}
                        name="state"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTable
                        columns={columns}
                        data={state.form.Center?.boxes}
                        options={state.options}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>

      {/* Callback dialog */}
      <Dialog open={state.callBackDialog.isOpen} onClose={handleCallbackDialog}>
        <DialogActions>
          <IconButton onClick={handleCallbackDialog}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Grid container rowSpacing={2}>
            {state.form.departmentId !== "" && (
              <Grid item container>
                <Grid item xs={9}>
                  <Typography variant="body1">
                    {t("sendEmailToDepartment")}
                    {
                      state.departments.filter(
                        (value) => value.id === state.form.departmentId
                      )[0].email
                    }
                    {" (" +
                      state.departments.filter(
                        (value) => value.id === state.form.departmentId
                      )[0].name +
                      ")"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <ToggleButtonGroup
                    value={state.callBackDialog.sendEmailDep}
                    onChange={(e) =>
                      handleInputCallbackChange("sendEmailDep", e)
                    }
                    exclusive
                  >
                    <ToggleButton
                      value="yes"
                      color={
                        state.callBackDialog.sendEmailDep === "yes" && "success"
                      }
                    >
                      {t("yes")}
                    </ToggleButton>
                    <ToggleButton
                      value="no"
                      color={
                        state.callBackDialog.sendEmailDep === "no" && "error"
                      }
                    >
                      {t("no")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            )}
            {state.form.centerId !== "" && (
              <Grid item container xs={12}>
                <Grid item xs={9}>
                  <Typography variant="body1">
                    {t("sendEmailToCenter")}
                  </Typography>
                  <Typography variant="body1">
                    {
                      state.centers.filter(
                        (value) => value.id === state.form.centerId
                      )[0].name
                    }
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <ToggleButtonGroup
                    value={state.callBackDialog.sendEmailCenter}
                    onChange={(e) =>
                      handleInputCallbackChange("sendEmailCenter", e)
                    }
                    exclusive
                  >
                    <ToggleButton
                      value="yes"
                      color={
                        state.callBackDialog.sendEmailCenter === "yes" &&
                        "success"
                      }
                    >
                      {t("yes")}
                    </ToggleButton>
                    <ToggleButton
                      value="no"
                      color={
                        state.callBackDialog.sendEmailCenter === "no" && "error"
                      }
                    >
                      {t("no")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={sendEmail}
            disabled={
              state.callBackDialog.sendEmailDep === "no" &&
              state.callBackDialog.sendEmailCenter === "no"
            }
          >
            {t("sendEmail")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
