import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

// Material UI
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Components and utils
import AppContext from "../../../context/AppContext";
import CustomDate from "../../Inputs/CustomDate";
import TextInput from "../../Inputs/TextInput";
import ConfirmDialog from "../../ConfirmDialog";
import { localeFormat } from "../../../utils/format";
import CustomButton from "../../Inputs/CustomButton";

const initialState = {
  confirmDialog: {
    title: "",
    isOpen: false,
    childrenText: "",
    callback: () => {},
  },
  form: {
    name: "",
    comments: "",
    dueDate: "",
  },
  receipts: [],
  selectedReceiptsIds: [],
  submitLoading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "RESET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: initialState.confirmDialog,
      };
    case "SET_CONFIRM_DIALOG":
      return {
        ...state,
        confirmDialog: {
          title: action.payload.title,
          isOpen: action.payload.isOpen,
          childrenText: action.payload.childrenText,
          callback: action.payload.callback,
        },
      };
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_RECEIPTS":
      return { ...state, receipts: action.payload };
    case "SET_SELECTED_RECEIPTS_IDS":
      return { ...state, selectedReceiptsIds: action.payload };
    case "SET_SUBMIT_LOADING":
      return { ...state, submitLoading: action.payload };
    default:
      throw new Error("Action not found in reducer.");
  }
}

export default function CreateRemittancePage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("remittances");

  const CREATE_REMITTANCE_COLUMNS = [
    { field: "concept", headerName: t("concept"), width: 150 },
    { field: "customerName", headerName: t("customerName"), width: 200 },
    {
      field: "amount",
      headerName: t("amount"),
      valueFormatter: ({ value }) => `${localeFormat(value)}€`,
      width: 150,
    },
    { field: "iban", headerName: t("iban"), width: 220 },
    { field: "dueDate", headerName: t("dueDate"), width: 150 },
    { field: "signatureDate", headerName: t("signatureDate"), width: 150 },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("createRemittancePage");

    getReceipts();
  }, []);

  // Backend calls

  const getReceipts = () => {
    let params = {
      isNotInRemittance: true,
    };
    api
      .get("/receipts", { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_RECEIPTS", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const submitForm = (selectedReceipts, changeReceipts = false) => {
    dispatch({ type: "SET_SUBMIT_LOADING", payload: true });
    let receipts = selectedReceipts;
    if (changeReceipts) {
      receipts = selectedReceipts.map((receipt) => ({
        ...receipt,
        dueDate: state.form.dueDate,
      }));
    }
    api
      .post("/remittances/create", {
        form: state.form,
        receipts,
      })
      .then((response) => {
        dispatch({ type: "SET_SUBMIT_LOADING", payload: false });
        if (response.data.error) {
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar(t("remittanceCreatedSuccessfully"), {
            variant: "success",
          });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
        dispatch({ type: "SET_SUBMIT_LOADING", payload: false });
      });
  };

  // Handlers

  const handleCreateRemittance = () => {
    if (state.selectedReceiptsIds.length === 0) {
      enqueueSnackbar(t("noSelectedReceipts"), { variant: "warning" });
      return;
    }
    const selectedReceipts = state.selectedReceiptsIds.map((id) =>
      state.receipts.find((receipt) => receipt.id === id)
    );
    if (selectedReceipts.length === 0) {
      enqueueSnackbar(t("noSelectedReceiptsMatchAnyReceipt"), {
        variant: "error",
      });
    } else {
      const changeReceiptsDueDate = !selectedReceipts.every(
        (receipt) => receipt.dueDate === state.form.dueDate
      );
      dispatch({
        type: "SET_CONFIRM_DIALOG",
        payload: {
          title: t("confirmCreateRemittance"),
          isOpen: true,
          childrenText: changeReceiptsDueDate
            ? t("changeReceiptsDueDateAndCreateRemittanceQuestion")
            : t("confirmCreateRemittanceQuestion"),
          callback: (confirmed) => {
            confirmed && submitForm(selectedReceipts, changeReceiptsDueDate);
            resetConfirmDialog();
          },
        },
      });
    }
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const resetConfirmDialog = () => {
    dispatch({
      type: "RESET_CONFIRM_DIALOG",
    });
  };

  const selectReceipts = (ids) => {
    dispatch({ type: "SET_SELECTED_RECEIPTS_IDS", payload: ids });
  };

  const setConfirmDialogState = (state) => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: state,
    });
  };

  return (
    <Container maxWidth="lg" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createRemittance")}</Typography>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <TextInput
                label={t("name")}
                value={state.form.name}
                onChange={handleInputChange}
                name="name"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <CustomDate
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                }}
                value={state.form.dueDate}
                onChange={handleInputChange}
                name="dueDate"
                label={t("dueDate")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label={t("comments")}
                value={state.form.comments}
                name="comments"
                multiline
                rows={4}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div style={{ height: 300, width: "100%" }}>
              <DataGrid
                columns={CREATE_REMITTANCE_COLUMNS}
                rows={state.receipts}
                checkboxSelection
                selectionModel={state.selectedReceiptsIds}
                onSelectionModelChange={(ids) => {
                  selectReceipts(ids);
                }}
              />
            </div>
          </Grid>

          <Grid item container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            <Grid item>
              <CustomButton
                loading={state.submitLoading}
                color="success"
                disabled={
                  state.form.name === "" ||
                  state.form.dueDate === "" ||
                  state.selectedReceiptsIds.length === 0
                }
                onClick={handleCreateRemittance}
              >
                {t("create")}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* Confirm Dialog */}
      <ConfirmDialog
        title={state.confirmDialog.title}
        open={state.confirmDialog.isOpen}
        setOpen={setConfirmDialogState}
        onConfirm={state.confirmDialog.callback}
      >
        <Typography variant="body2" color="initial">
          {state.confirmDialog.childrenText}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
}
