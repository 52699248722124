import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Box,
  ButtonGroup,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../../context/AppContext";
import Button from "../../Inputs/CustomButton";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import SearchButton from "../../Inputs/SearchButton";
import TextInput from "../../Inputs/TextInput";
import InvoiceStateChip from "../../InvoiceStateChip";
import { CustomTable } from "../../CustomTable";
import { localeFormat } from "../../../utils/format";
import { today } from "../../../utils/date";
import CustomButton from "../../Inputs/CustomButton";
import {
  EXPIRED_INVOICE_STATE_ID,
  ISSUED_INVOICE_STATE_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  UNPAID_INVOICE_STATE_ID,
} from "../../../data/constants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  customers: [],
  form: {
    amount: 0,
    comments: "",
    concept: "",
    customer: null,
    dueDate: "",
    iban: "",
    remittance: "",
    selectedInvoices: [],
    signatureDate: today(),
  },
  filters: {
    amountFrom: "",
    amountTo: "",
    dateFrom: "",
    dateUntil: "",
    number: "",
    state: [
      ISSUED_INVOICE_STATE_ID,
      EXPIRED_INVOICE_STATE_ID,
      UNPAID_INVOICE_STATE_ID,
    ],
  },
  inputError: {
    amount: false,
    comments: false,
    concept: false,
    customer: false,
    dueDate: false,
    iban: false,
    remittance: false,
    signatureDate: false,
  },
  invoice: {
    dueDate: "",
    number: "",
    total: "",
  },
  ibans: [],
  invoices: [],
  paymentMethods: [],
  options: {
    loaded: true,
  },
  remittances: [],
  customerLoaded: true,
  submitLoading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "SET_RECEIPT":
      return {
        ...state,
        receipt: action.payload.receipt,
        form: action.payload.receipt,
      };
    case "SET_INVOICE":
      return { ...state, invoice: action.payload.invoice };
    case "SET_CUSTOMER_INVOICES":
      return { ...state, invoices: action.payload };
    case "SET_IBANS":
      return { ...state, ibans: action.payload };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "SET_CUSTOMER":
      return {
        ...state,
        form: { ...initialState.form, customer: action.payload },
      };
    case "SET_AMOUNT":
      return {
        ...state,
        form: { ...state.form, amount: action.payload.amount },
      };
    case "SET_REMITTANCES":
      return { ...state, remittances: action.payload };
    case "SET_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };

    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_CUSTOMER_LOADED_STATE":
      return { ...state, customerLoaded: action.payload };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    case "SET_SUBMIT_LOADING":
      return { ...state, submitLoading: action.payload };
    case "SET_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_FILTERS":
      return { ...state, filters: initialState.filters };
    case "RESET_RECEIPT_INFO":
      return {
        ...state,
        form: {
          ...initialState.form,
          customer: state.form.customer,
        },
      };
    default:
      throw new Error();
  }
}

export default function CreateReceiptPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [t] = useTranslation("receipts");
  const query = useQuery();
  const customerId = query.get("customerId");

  const INVOICE_COLUMNS = [
    { key: "serie", label: t("serie"), sortType: "string" },
    { key: "number", label: t("number"), sortType: "number" },
    { key: "issueDate", label: t("issueDate"), sortType: "string" },
    {
      key: "total",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => localeFormat(value) + " €",
    },
    {
      key: "paymentMethodId",
      label: t("paymentMethod"),
      sortType: "number",
      renderFunction: (value) =>
        state.paymentMethods.find((method) => method.id === value)?.name,
    },
    {
      key: "state",
      label: t("state"),
      sortType: "number",
      renderFunction: (value) => <InvoiceStateChip state={value} />,
    },
    { key: "actions", label: t("actions"), sortType: "other" },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("createReceipt");

    getCustomers();
    getRemittances();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    state.form.customer && getInvoices();
    dispatch({ type: "RESET_RECEIPT_INFO" });
    dispatch({ type: "RESET_FILTERS" });
  }, [state.form.customer]);

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: e.target.name, value: e.target.value },
    });
  };

  const calculateTotal = (invoices) => {
    let total = 0;
    invoices.forEach((invoice) => {
      total += invoice.totalAmount;
    });
    dispatch({
      type: "SET_AMOUNT",
      payload: {
        amount: total,
      },
    });
  };

  const getCustomers = () => {
    if (customerId)
      dispatch({ type: "SET_CUSTOMER_LOADED_STATE", payload: false });
    let params = { include: ["IBAN"] };
    api
      .get("/customers", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (customerId) {
            const customer = response.data.find(
              (item) => item.id === Number(customerId)
            );
            dispatch({ type: "SET_CUSTOMER", payload: customer });
          }
          dispatch({ type: "SET_CUSTOMERS", payload: response.data });
          dispatch({ type: "SET_CUSTOMER_LOADED_STATE", payload: true });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
        console.log(error);
      });
  };

  const getRemittances = () => {
    let params = {};

    //Only remittances which haven't been remitted yet
    params.remitted = true;

    api
      .get("/remittances/", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({ type: "SET_REMITTANCES", payload: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentMethods = () => {
    api
      .get("/payment-methods")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          dispatch({
            type: "SET_PAYMENT_METHODS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
        console.log(error);
      });
  };

  const getInvoices = () => {
    dispatch({ type: "SET_LOADED_FALSE" });

    let params = {
      paymentMethodId: RECEIPT_PAYMENT_METHOD_ID,
      attributes: [
        {
          model: "Invoice",
          attributes: [
            "actions",
            "baseAmount",
            "customerId",
            "customerName",
            "dueDate",
            "emailSent",
            "id",
            "issueDate",
            "number",
            "state",
            "totalAmount",
          ],
        },
      ],
    };

    state.form.customer && (params.customerId = state.form.customer.id);
    state.filters.state.length !== 0 && (params.state = state.filters.state);
    state.filters.number !== "" && (params.number = state.filters.number);
    state.filters.amountFrom !== "" && (params.min = state.filters.amountFrom);
    state.filters.amountTo !== "" && (params.max = state.filters.amountTo);
    state.filters.dateFrom !== "" && (params.dateFrom = state.filters.dateFrom);
    state.filters.dateUntil !== "" &&
      (params.dateUntil = state.filters.dateUntil);

    api
      .get("/invoices", { params })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0)
            enqueueSnackbar(t("noInvoices"), { variant: "warning" });

          // Add numberSerie to invoices where numberSerie: InvoiceSerie.name + number
          const invoices = response.data.map((invoice) => {
            invoice.numberSerie = invoice.InvoiceSerie.name + invoice.number;
            return invoice;
          });

          dispatch({
            type: "SET_CUSTOMER_INVOICES",
            payload: invoices,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: "SET_LOADED_TRUE" });
      });
  };

  const submitForm = () => {
    if (validateForm() && state.form.selectedInvoices.length > 0) {
      dispatch({ type: "SET_SUBMIT_LOADING", payload: true });
      let form = { ...state.form };
      if (form.customer) {
        form.customerName = form.customer.fullName;
        form.customerId = form.customer.id;
      }
      form.createdBy = user.id;
      form.remittanceId = form.remittance === "" ? null : form.remittance;
      form.comments = form.comments === "" ? null : form.comments;
      api
        .post("/receipts/create", form)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("receiptCreateSuccess"), { variant: "success" });
            history.goBack();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
          console.log(error);
        })
        .finally(() => {
          dispatch({ type: "SET_SUBMIT_LOADING", payload: false });
        });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let fields = ["iban", "customer", "concept", "dueDate", "signatureDate"];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    return isValid;
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: { inputname: name },
    });
  };

  const addInvoice = (invoice) => {
    let invoices = state.form.selectedInvoices;
    if (invoices.filter((item) => item.id === invoice.id).length === 0) {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "selectedInvoices",
          value: [...invoices, invoice],
        },
      });
      calculateTotal([...invoices, invoice]);

      // Default concept if there is only one invoice
      if (invoices.length === 0)
        //after adding invoice, there is only one
        dispatch({
          type: "SET_INPUT",
          payload: { inputname: "concept", value: invoice.numberSerie },
        });
    }
  };

  const removeInvoice = (invoiceId) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "selectedInvoices",
        value: state.form.selectedInvoices.filter(
          (invoice) => invoice.id !== invoiceId
        ),
      },
    });
    calculateTotal(
      state.form.selectedInvoices.filter((invoice) => invoice.id !== invoiceId)
    );

    // Default concept if there is only one invoice
    if (state.form.selectedInvoices.length === 2) {
      //after removing invoice, there is only one
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "concept",
          value: state.form.selectedInvoices.find(
            (invoice) => invoice.id !== invoiceId
          )?.numberSerie,
        },
      });
    } else if (state.form.selectedInvoices.length === 1) {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "concept",
          value: "",
        },
      });
    }
  };

  const filterOptions = createFilterOptions({ limit: 5 });

  const handleChangeFilter = (e) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleChangeCustomer = (customer) => {
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "customer", value: customer },
    });
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "selectedInvoices",
        value: [],
      },
    });

    dispatch({
      type: "SET_IBANS",
      payload: customer?.IBANs || [],
    });
  };

  const resetFilters = () => {
    dispatch({ type: "RESET_FILTERS" });
    dispatch({ type: "SET_CUSTOMER_INVOICES", payload: [] });
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createReceipt")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Stepper orientation="vertical">
              {/* Step 0: Select customer */}
              <Step active={true}>
                <StepLabel>
                  <Typography variant="h5">{t("selectCustomer")}</Typography>
                </StepLabel>
                <StepContent>
                  <Grid container spacing={1} rowSpacing={2}>
                    <Grid item xs={4} container alignItems="center">
                      <Grid item xs>
                        <Autocomplete
                          options={state.customers}
                          getOptionLabel={(option) => option?.fullName}
                          renderInput={(params) => (
                            <TextInput
                              {...params}
                              error={state.inputError.customer}
                              helperText={
                                state.inputError.customer
                                  ? t("customer") + " " + t("mustNotBeBlank")
                                  : ""
                              }
                              label={t("customer")}
                            />
                          )}
                          value={state.form.customer}
                          onChange={(e, customer) => {
                            handleChangeCustomer(customer);
                          }}
                          name="customer"
                          filterOptions={filterOptions}
                          size="small"
                          sx={{ minWidth: 200 }}
                        />
                      </Grid>
                      {!state.customerLoaded && (
                        <Grid item>
                          <Box sx={{ width: 24, height: 24, marginLeft: 2 }}>
                            <CircularProgress size={24} />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>

              {/* Step 1: Get invoices */}
              <Step active={true}>
                <StepLabel>
                  <Typography variant="h5">{t("selectInvoices")}</Typography>
                </StepLabel>
                <StepContent>
                  <Grid container spacing={1} rowSpacing={2}>
                    <Grid item>
                      <TextInput
                        label={t("number")}
                        value={state.filters.number}
                        name="number"
                        onChange={handleChangeFilter}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            getInvoices();
                          }
                        }}
                        sx={{ minWidth: 200 }}
                      />
                    </Grid>
                    <Grid item>
                      <TextInput
                        label={t("dateFrom")}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={state.filters.dateFrom}
                        onChange={handleChangeFilter}
                        name="dateFrom"
                      />
                    </Grid>
                    <Grid item>
                      <TextInput
                        label={t("dateUntil")}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={state.filters.dateUntil}
                        onChange={handleChangeFilter}
                        name="dateUntil"
                      />
                    </Grid>
                    <Grid item>
                      <TextInput
                        type="number"
                        sx={{ width: "105px" }}
                        label={t("amountFrom")}
                        name="amountFrom"
                        value={state.filters.amountFrom}
                        onChange={handleChangeFilter}
                      />
                    </Grid>

                    <Grid item>
                      <TextInput
                        type="number"
                        sx={{ width: "105px" }}
                        label={t("amountTo")}
                        name="amountTo"
                        value={state.filters.amountTo}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item>
                      <CustomSelect
                        label={t("state")}
                        value={state.filters.state}
                        onChange={handleChangeFilter}
                        options={[
                          { value: 0, label: t("issued") },
                          { value: 1, label: t("paid") },
                          { value: 2, label: t("expired") },
                          { value: 3, label: t("unpaid") },
                        ]}
                        multiple
                        name="state"
                      />
                    </Grid>
                    <Grid item>
                      <ButtonGroup variant="contained" color="primary">
                        <Button onClick={resetFilters}>{t("reset")}</Button>
                        <SearchButton
                          onClick={getInvoices}
                          disabled={state.form.customer != null ? false : true}
                          loading={!state.options.loaded}
                        />
                      </ButtonGroup>
                    </Grid>

                    <Grid item xs={12}>
                      <CustomTable
                        columns={INVOICE_COLUMNS}
                        data={state.invoices?.map((invoice) => {
                          let row = {
                            id: invoice.id,
                            serie: invoice.InvoiceSerie.name,
                            number: invoice.number,
                            issueDate: invoice.issueDate,
                            total: invoice.totalAmount,
                            paymentMethodId: invoice.paymentMethodId,
                            state: invoice.state,
                          };
                          // If payment method is "RECIBO", add action button
                          if (invoice.paymentMethodId === 2) {
                            if (
                              state.form.selectedInvoices.filter(
                                (item) => item.id === invoice.id
                              ).length === 0
                            ) {
                              row.actions = (
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    addInvoice(invoice);
                                  }}
                                  color="success"
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              );
                            } else {
                              row.actions = (
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    removeInvoice(invoice.id);
                                  }}
                                  color="error"
                                >
                                  <RemoveCircleIcon />
                                </IconButton>
                              );
                            }
                          }
                          return row;
                        })}
                        options={state.options}
                      />
                    </Grid>

                    <Grid item>
                      <Paper>
                        <List>
                          {state.form.selectedInvoices?.map((invoice) => (
                            <ListItem>
                              {invoice.numberSerie} - {invoice.totalAmount}€
                              <IconButton
                                onClick={() => {
                                  removeInvoice(invoice.id);
                                }}
                                color="error"
                              >
                                <RemoveCircleIcon />
                              </IconButton>
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>

              {/* Step 2: Receipt info */}
              <Step active={true}>
                <StepLabel>
                  <Typography variant="h5">{t("receiptInfo")}</Typography>
                </StepLabel>
                <StepContent>
                  <Grid container spacing={1} rowSpacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        fontWeight="fontWeightMedium"
                        display="inline"
                      >
                        {t("amount") + ": "}
                      </Typography>
                      <Typography variant="body1" display="inline">
                        {localeFormat(state.form.amount) + "€"}
                      </Typography>
                    </Grid>

                    <Grid item container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <CustomDate
                          error={state.inputError.dueDate}
                          helperText={
                            state.inputError.dueDate
                              ? t("dueDate") + " " + t("mustNotBeBlank")
                              : ""
                          }
                          label={t("dueDate")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: new Date().toISOString().split("T")[0],
                          }}
                          value={state.form.dueDate}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          name="dueDate"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <CustomDate
                          error={state.inputError.signatureDate}
                          helperText={
                            state.inputError.signatureDate
                              ? t("signatureDate") + " " + t("mustNotBeBlank")
                              : ""
                          }
                          label={t("signatureDate")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            max: new Date().toISOString().split("T")[0],
                          }}
                          value={state.form.signatureDate}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          name="signatureDate"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <CustomSelect
                          error={state.inputError.iban}
                          helperText={
                            state.inputError.iban
                              ? t("iban") + " " + t("mustNotBeBlank")
                              : ""
                          }
                          value={state.form.iban}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          name="iban"
                          label={t("iban")}
                          options={[
                            { value: "", label: t("none") },
                            ...state.ibans.map((iban) => ({
                              label: iban.number,
                              value: iban.number,
                            })),
                          ]}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <CustomSelect
                          error={state.inputError.remittance}
                          helperText={
                            state.inputError.remittance
                              ? t("remittance") + " " + t("mustNotBeBlank")
                              : ""
                          }
                          value={state.form.remittance}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          name="remittance"
                          label={t("remittance")}
                          options={[
                            { label: t("none"), value: "" },
                            ...state.remittances.map((remittance) => ({
                              value: remittance.id,
                              label:
                                remittance.name + " - " + remittance.dueDate,
                            })),
                          ]}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextInput
                          error={state.inputError.concept}
                          helperText={
                            state.inputError.concept
                              ? t("concept") + " " + t("mustNotBeBlank")
                              : ""
                          }
                          label={t("concept")}
                          value={state.form.concept}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          name="concept"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextInput
                          error={state.inputError.comments}
                          helperText={
                            state.inputError.comments
                              ? t("comments") + " " + t("mustNotBeBlank")
                              : ""
                          }
                          label={t("comments")}
                          value={state.form.comments}
                          name="comments"
                          multiline
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={1} justifyContent="flex-end">
                      <Grid item>
                        <Button onClick={() => history.goBack()}>
                          {t("cancel")}
                        </Button>
                      </Grid>
                      <Grid item>
                        <CustomButton
                          loading={state.submitLoading}
                          color="success"
                          onClick={submitForm}
                          disabled={state.form.selectedInvoices?.length === 0}
                        >
                          {t("create")}
                        </CustomButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
            </Stepper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
