import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { API_ROUTE } from "../utils/API";
import axios from "axios";

import { Grid, Paper, Typography, Button } from "@mui/material";

import TextInput from "./Inputs/TextInput";

export default function SendResetPasswordCodeForm({ setEmailValue, onSubmit }) {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("login");
  const [tErrors] = useTranslation("errors");
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);

  const submit = () => {
    setDisabled(true);
    axios
      .post(API_ROUTE + "/auth/forgot-password/send-code", { email: email, codeType: 'link' })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("textForResetPassword") + " " + email, {
            variant: "success",
          });
          setEmailValue(email);
          onSubmit();
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.message, { variant: "error" });
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      submit(e);
    }
  };

  return (
    <Paper sx={{ padding: 3, maxWidth: 500 }} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">{t("resetPassword")}</Typography>
      </Grid>
      <Grid container spacing={2} direction="column" marginTop={2}>
        <Grid item>
          <Typography>{t("resetPasswordInstructions")}</Typography>
        </Grid>
        <Grid item xs={12} marginTop={1}>
          <TextInput
            label={t("email/username")}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onKeyDown={handleEnter}
          />
        </Grid>
        <Grid item container xs={12} marginTop={2}>
          <Grid item xs={6}>
            <Typography variant="caption" display="block">
              <Link to="/login">{t("backToLogin")}</Link>
            </Typography>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={submit}
              disabled={disabled || email === ""}
            >
              {t("send")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
