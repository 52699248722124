import { Typography } from "@mui/material";
import PropTypes from "prop-types";

const LabeledText = (props) => {
  const { label, value, variant = "body1" } = props;

  return (
    <Typography variant={variant} fontWeight="bold">
      {label + ": "}
      <Typography variant={variant} component="span">
        {value}
      </Typography>
    </Typography>
  );
};

LabeledText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["body1", "body2"]),
};

export default LabeledText;
