import {
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

import DownloadIcon from "@mui/icons-material/Download";
import ArticleIcon from "@mui/icons-material/Article";

import AppContext from "../../../context/AppContext";
import ButtonLink from "../../Inputs/ButtonLink";
import { CustomTable } from "../../CustomTable";
import { localeFormat } from "../../../utils/format";
import { useRef } from "react";
import { useSnackbar } from "notistack";

const initialState = {
  transferGroup: {},
  options: {
    loaded: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_TRANSFER_GROUP":
      return { ...state, transferGroup: action.payload };
    case "SET_LOADED_TRUE":
      return { ...state, options: { ...state.options, loaded: true } };
    case "SET_LOADED_FALSE":
      return { ...state, options: { ...state.options, loaded: false } };
    default:
      throw new Error();
  }
}

export default function TransferPaymentPage() {
  const { api, user } = useContext(AppContext);
  const [t] = useTranslation("transferPayments");
  const history = useHistory();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialState);
  const downloadCSVRef = useRef();

  const csvColumns = [
    { label: t("title"), key: "ProviderInvoice.title" },
    { label: t("invoiceNumber"), key: "ProviderInvoice.invoiceNumber" },
    { label: t("businessName"), key: "creditorName" },
    { label: t("creditorIBAN"), key: "creditorIBAN" },
    { label: t("concept"), key: "concept" },
    { label: t("amount"), key: "amount" },
    { label: t("accountingAccount"), key: "ProviderInvoice.Provider.accountingAccount" },
  ];

  const transferColumns = [
    { key: "concept", label: t("concept"), sortType: "string" },
    {
      key: "amount",
      label: t("amount"),
      sortType: "number",
      renderFunction: (value) => value.toFixed(2) + "€",
    },
    { key: "signatureDate", label: t("signatureDate"), sortType: "string" },
    { key: "creditorIBAN", label: t("creditorIBAN"), sortType: "string" },
    { key: "creditorName", label: t("businessName"), sortType: "string" },
    {
      key: "ProviderInvoice.title",
      label: t("providerInvoice"),
      sortType: "string",
      renderFunction: (title, transfer) => (
        <ButtonLink
          to={"/app/provider-invoice/" + transfer.ProviderInvoice?.id}
          size="small"
        >
          {title}
        </ButtonLink>
      ),
    },
    {
      key: "ProviderInvoice.invoiceNumber",
      label: t("invoiceNumber"),
      sortType: "string",
    },
    {
      key: "ProviderInvoice.Provider.accountingAccount",
      label: t("accountingAccount"),
      sortType: "string",
    },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("transferPayments");

    getTransferPaymentGroup();
  }, []);

  const getTransferPaymentGroup = () => {
    dispatch({ type: "SET_LOADED_FALSE" });
    let params = {
      include: ["Provider", "ProviderInvoice", "TransferPayment", "User"],
    };
    api
      .get("/transfer-payment-group/" + id, { params })
      .then((response) => {
        dispatch({ type: "SET_LOADED_TRUE" });
        if (response.data.error)
          enqueueSnackbar(response.data.error, { variant: "error" });
        else dispatch({ type: "SET_TRANSFER_GROUP", payload: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  const downloadXml = () => {
    api
      .get(`/transfer-payment-groups/${state.transferGroup.id}/xml`)
      .then((response) => {
        if (typeof response.data === "object") {
          //Error response
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          //Valid XML
          //Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: "application/xml",
          });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          const a = document.createElement("a");
          a.href = fileURL;
          a.download = fileURL.split("/").pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        console.log(error);
      });
  };

  return (
    <Container maxWidth="lg" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item container xs={12}>
            <Grid item flexGrow={1}>
              <Typography variant="h4">{t("transferPaymentGroup")}</Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Tooltip title={t("downloadCSV")} placement="top">
                <IconButton
                  color="primary"
                  onClick={() => {
                    downloadCSVRef.current.link.click();
                  }}
                >
                  <ArticleIcon />
                  <CSVLink
                    data={state.transferGroup?.TransferPayments || []}
                    headers={csvColumns}
                    filename={"transfer.csv"}
                    ref={downloadCSVRef}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("downloadXML")} placement="top">
                <IconButton color="primary" onClick={downloadXml}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {t("date")}:
            </Typography>
            <Typography variant="body"> {state.transferGroup.date}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {t("createdBy")}:
            </Typography>
            <Typography variant="body">
              {" "}
              {state.transferGroup.User?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{t("debtor")}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {t("name")}:
            </Typography>
            <Typography variant="body">
              {" "}
              {state.transferGroup.debtorName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {t("nif")}:
            </Typography>
            <Typography variant="body">
              {" "}
              {state.transferGroup.debtorNIF}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {t("IBAN")}:
            </Typography>
            <Typography variant="body">
              {" "}
              {state.transferGroup.debtorIBAN}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {t("BIC")}:
            </Typography>
            <Typography variant="body">
              {" "}
              {state.transferGroup.debtorBIC}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{t("transferPayments")}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {t("totalAmount")}:
            </Typography>
            <Typography variant="body">
              {" "}
              {localeFormat(
                state.transferGroup.TransferPayments?.reduce(
                  (total, transfer) => total + transfer.amount,
                  0
                )
              )}
              €
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {t("totalTransfers")}:
            </Typography>
            <Typography variant="body">
              {" "}
              {state.transferGroup.TransferPayments?.length}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTable
              columns={transferColumns}
              data={state.transferGroup.TransferPayments}
              options={state.options}
            ></CustomTable>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.goBack();
              }}
            >
              {t("back")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
