import axios from "axios";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { API_ROUTE } from "../../utils/API";
import TextInput from "./TextInput";

const CityInput = (props) => {
  const { label, name, onChange, value, helperText, disabled = false } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [cities, setCities] = useState([]);
  const [t] = useTranslation("customers");

  const getCities = (text) => {
    let params = {};
    text && (params.name = text);
    text.length > 1 &&
      axios
        .get(API_ROUTE + "/cities", { params })
        .then((response) => {
          if (response.data.error)
            enqueueSnackbar(response.data.error.toString(), {
              variant: "error",
            });
          else setCities(response.data);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        });
  };

  const filterOptions = createFilterOptions({
    limit: 10, //limit of options to be displayed
  });

  return (
    <Autocomplete
      name={name}
      options={cities} //only render cities if input length is greater than 2
      getOptionLabel={(city) => city.name || ""}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextInput
          error={helperText}
          helperText={helperText ? t("city") + " " + t("mustNotBeBlank") : ""}
          {...params}
          size="small"
          label={label}
          onChange={(e) => {
            getCities(e.target.value);
          }}
        />
      )}
      value={value}
      onChange={(event, newValue) => {
        onChange({ target: { name: name, value: newValue } });
      }}
      fullWidth
      disabled={disabled}
    />
  );
};

export default CityInput;
