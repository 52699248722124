import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  Paper,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import StoreIcon from "@mui/icons-material/Store";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

import AppContext from "../../../context/AppContext";
import CustomSelect from "../../Inputs/CustomSelect";
import PasswordInput from "../../Inputs/PasswordInput";
import PhotoInput from "../../Inputs/PhotoInput";
import TextInput from "../../Inputs/TextInput";
import { generateURL, getParams } from "../../../utils/url";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const initialState = {
  form: {
    name: "",
    password: "",
    roleId: "",
    center: null,
    customer: null,
    centers: [],
    customers: [],
    hasFile: false,
  },
  inputError: {
    name: false,
    password: false,
    roleId: false,
  },
  roles: [],
  id: null,
  centers: [],
  customers: [],
  photo: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_USER":
      return { ...state, user: action.payload.user, form: action.payload.user };
    case "SET_ROLES":
      return { ...state, roles: action.payload };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_CUSTOMERS":
      return { ...state, customers: action.payload };
    case "ADD_CENTER":
      return {
        ...state,
        form: {
          ...state.form,
          centers: [...state.form.centers, state.form.center],
          center: null,
        },
      };
    case "ADD_CUSTOMER":
      return {
        ...state,
        form: {
          ...state.form,
          customers: [...state.form.customers, state.form.customer],
          customer: null,
        },
      };
    case "REMOVE_CENTER":
      return {
        ...state,
        form: {
          ...state.form,
          centers: state.form.centers.filter((item) => item !== action.payload),
        },
      };
    case "REMOVE_CUSTOMER":
      return {
        ...state,
        form: {
          ...state.form,
          customers: state.form.customers.filter(
            (item) => item !== action.payload
          ),
        },
      };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: { ...state.inputError, [action.payload.inputname]: true },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: { ...state.inputError, [action.payload.inputname]: false },
      };
    case "SET_PHOTO":
      return { ...state, photo: action.payload };
    default:
      throw new Error();
  }
}

export default function CreateUserPage() {
  const { api } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("users");
  const [tErrors] = useTranslation("errors");
  const history = useHistory();
  const query = useQuery();

  const initState = (state) => ({
    ...state,
    form: {
      ...state.form,
      ...getParams(query, [{ name: "roleId", type: "number" }]),
    },
  });

  const [state, dispatch] = useReducer(reducer, initialState, initState);

  const handleInputChange = (e) => {
    setInputErrorFalse(e.target.name);
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: { inputname: name },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  //Initial useEffect
  useEffect(() => {
    document.title = t("createUserPage");

    getRoles();
    getCenters();
    getCustomers();
  }, []);

  const getRoles = () => {
    api
      .get("/roles")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noRoles"), { variant: "warning" });
          }
          dispatch({
            type: "SET_ROLES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCenters = () => {
    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.msg);
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noCenters"), { variant: "warning" });
          }
          dispatch({
            type: "SET_CENTERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCustomers = () => {
    api
      .get("/customers")
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          enqueueSnackbar(response.data.error, { variant: "error" });
        } else {
          if (response.data.length === 0) {
            enqueueSnackbar(t("noCustomers"), { variant: "warning" });
          }
          dispatch({
            type: "SET_CUSTOMERS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const submitForm = () => {
    if (validateForm()) {
      api
        .post("/users/create", state.form)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            enqueueSnackbar(response.data.error, { variant: "error" });
          } else {
            enqueueSnackbar(t("userCreateSuccess"), { variant: "success" });

            if (state.photo) {
              let formData = new FormData();

              let file = new File(state.photo, "profilePhoto");

              formData.append("profilePhoto", file);

              api
                .post("/users/" + response.data.id + "/profile-image", formData)
                .then((response) => {
                  if (response.data.error) {
                    console.log(response.data.error);
                    enqueueSnackbar(response.data.error, { variant: "error" });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  enqueueSnackbar(error.toString(), { variant: "error" });
                });
            }

            if (state.form.centers.length > 0) {
              state.form.centers.forEach((center) => {
                api
                  .post("/users/" + response.data.id + "/centers/add", {
                    id: center.id,
                  })
                  .then((response) => {
                    if (response.data.error) {
                      console.log(response.data.error);
                      enqueueSnackbar(response.data.error, {
                        variant: "error",
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(error.toString(), { variant: "error" });
                  });
              });
            }

            if (state.form.customers.length > 0) {
              state.form.customers.forEach((customer) => {
                api
                  .post("/users/" + response.data.id + "/customers/add", {
                    id: customer.id,
                  })
                  .then((response) => {
                    if (response.data.error) {
                      console.log(response.data.error);
                      enqueueSnackbar(response.data.error, {
                        variant: "error",
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    enqueueSnackbar(error.toString(), { variant: "error" });
                  });
              });
            }

            history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const validateForm = () => {
    let isValid = true;

    let fields = ["name", "password", "roleId"];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("isRequired"), { variant: "error" });
        isValid = false;
      }
    });

    if (state.form.password.length < 8 || state.form.password.length > 32) {
      setInputErrorTrue("password");
      enqueueSnackbar(t("passwordMustBeBetween8And32Characters"), {
        variant: "error",
      });
      isValid = false;
    }

    if (Number(state.form.roleId) === 0) {
      setInputErrorTrue("roleId");
      enqueueSnackbar(t("selectValidRole"), { variant: "error" });
      isValid = false;
    }

    return isValid;
  };

  const filterOptions = createFilterOptions({
    limit: 10, //limit of options to be displayed
  });

  const addCenter = () => {
    let found = false;
    if (state.form.center?.id) {
      state.form.centers.forEach((center) => {
        if (center.id === state.form.center.id) {
          found = true;
        }
      });
      if (!found) dispatch({ type: "ADD_CENTER" });
      else enqueueSnackbar(t("centerAlreadyAdded"), { variant: "error" });
    } else enqueueSnackbar(t("invalidCenter"), { variant: "error" });
  };

  const addCustomer = () => {
    let found = false;
    if (state.form.customer?.id) {
      state.form.customers.forEach((customer) => {
        if (customer.id === state.form.customer.id) {
          found = true;
        }
      });
      if (!found) dispatch({ type: "ADD_CUSTOMER" });
      else enqueueSnackbar(t("customerAlreadyAdded"), { variant: "error" });
    } else enqueueSnackbar(t("invalidCustomer"), { variant: "error" });
  };

  const deleteCenter = (center) => {
    dispatch({ type: "REMOVE_CENTER", payload: center });
  };

  const deleteCustomer = (customer) => {
    dispatch({ type: "REMOVE_CUSTOMER", payload: customer });
  };

  const setPhoto = (photo) => {
    dispatch({ type: "SET_PHOTO", payload: photo });
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "hasFile", value: true },
    });
  };

  const deletePhoto = () => {
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "hasFile", value: false },
    });
  };

  return (
    <Container maxWidth="lg" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createUser")}</Typography>
          </Grid>

          <Grid item container xs={12} md={4} columnSpacing={3}>
            <PhotoInput
              savePhoto={setPhoto}
              source={""}
              hasPhoto={state.form.hasFile}
              deletePhoto={deletePhoto}
            />
          </Grid>
          <Grid item container md={8} spacing={1}>
            <Grid item md={9}>
              <TextInput
                error={state.inputError.name}
                helperText={
                  state.inputError.name
                    ? t("name") + " " + t("mustNotBeBlank")
                    : ""
                }
                variant="outlined"
                label={t("name")}
                value={state.form.name}
                onChange={handleInputChange}
                name="name"
              />
            </Grid>
            <Grid item md={9}>
              <CustomSelect
                error={state.inputError.roleId}
                helperText={
                  state.inputError.roleId
                    ? t("role") + " " + t("mustNotBeBlank")
                    : ""
                }
                value={state.form.roleId}
                name="roleId"
                onChange={handleInputChange}
                label={t("role")}
                options={state.roles
                  .map((role) => ({
                    label: role.name,
                    value: role.id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                name={"password"}
                text={"password"}
                value={state.form.password}
                onChange={handleInputChange}
                error={state.inputError.password}
                m={0}
              ></PasswordInput>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Centers */}
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <StoreIcon />
                {t("centers")}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={10}>
                <Autocomplete
                  fullWidth
                  size="small"
                  options={state.centers}
                  getOptionLabel={(center) => center.name}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <TextInput {...params} label={t("center")} />
                  )}
                  value={state.form.center}
                  onChange={(e, center) => {
                    handleInputChange({
                      target: { value: center, name: "center" },
                    });
                  }}
                />
              </Grid>
              <Grid item>
                <Button startIcon={<AddIcon />} onClick={addCenter}>
                  {t("addCenter")}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {state.form.centers.map((center) => (
              <List>
                <Card variant="outlined" xs={6}>
                  <CardContent>
                    <Grid item container xs={12}>
                      <Grid item>
                        <Typography variant="body">{center?.name}</Typography>
                      </Grid>
                      <Grid
                        item
                        flex={1}
                        alignItems="flex-start"
                        justifyContent="flex-end"
                        display="flex"
                      >
                        <IconButton onClick={() => deleteCenter(center)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </List>
            ))}
            {state.form.centers.length == 0 && t("noCenters")}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* Customers */}
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
              >
                <EmojiPeopleIcon />
                {t("customers")}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={10}>
                <Autocomplete
                  size="small"
                  options={state.customers}
                  getOptionLabel={(customer) => customer.name}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <TextInput {...params} label={t("customer")} />
                  )}
                  value={state.form.customer}
                  onChange={(e, customer) => {
                    handleInputChange({
                      target: { value: customer, name: "customer" },
                    });
                  }}
                />
              </Grid>
              <Grid item>
                <Button startIcon={<AddIcon />} onClick={addCustomer}>
                  {t("addCustomer")}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {state.form.customers?.map((customer) => (
              <List>
                <Card variant="outlined" xs={6}>
                  <CardContent>
                    <Grid item container xs={12}>
                      <Grid item>
                        <Typography variant="body">{customer?.name}</Typography>
                      </Grid>
                      <Grid
                        item
                        flex={1}
                        alignItems="flex-start"
                        justifyContent="flex-end"
                        display="flex"
                      >
                        <IconButton onClick={() => deleteCustomer(customer)}>
                          <HighlightOffIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </List>
            ))}
            {state.form.customers.length == 0 && t("noCustomers")}
          </Grid>

          {/* Lo que está comentado es el diseño antiguo. */}
          {/* Borrar si gusta el nuevo diseño. */}
          {/*<Grid item spacing={3} sm={12} md={6} lg={4}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{t("centers")}</Typography>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      options={state.centers}
                      getOptionLabel={(center) => center.name}
                      filterOptions={filterOptions}
                      renderInput={(params) => (
                        <TextInput {...params} label={t("center")} />
                      )}
                      value={state.form.center}
                      onChange={(e, center) => {
                        handleInputChange({
                          target: { value: center, name: "center" },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        addCenter();
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon />
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    {state.form.centers.map((center) => (
                      <Typography variant="body2">
                        {center.name}
                        <IconButton
                          onClick={() => {
                            deleteCenter(center);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={12} md={6} lg={4}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{t("customers")}</Typography>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <Autocomplete
                      sx={{ minWidth: "210px" }}
                      size="small"
                      options={state.customers}
                      getOptionLabel={(customer) => customer.name}
                      filterOptions={filterOptions}
                      renderInput={(params) => (
                        <TextInput {...params} label={t("customer")} />
                      )}
                      value={state.form.customer}
                      onChange={(e, customer) => {
                        handleInputChange({
                          target: { value: customer, name: "customer" },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        addCustomer();
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    {state.form.customers.map((customer) => (
                      <Typography variant="body2">
                        {customer.name}
                        <IconButton
                          onClick={() => {
                            deleteCustomer(customer);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item spacing={3} sm={12} md={6} lg={4}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{t("issuers")}</Typography>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <CustomSelect
                      label={t("issuer")}
                      name="issuer"
                      value={state.form.issuer}
                      onChange={handleInputChange}
                      options={state.issuers.map((issuer) => ({
                        label: issuer.name,
                        value: issuer.id,
                      }))}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        addIssuer();
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    {state.form.issuers.map((issuer) => (
                      <Typography variant="body2">
                        {issuer.name}
                        <IconButton
                          onClick={() => {
                            deleteIssuer(issuer);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>*/}

          <Grid container item xs={12} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()}>{t("back")}</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={submitForm}>
                {t("create")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
