import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const CancellationRequestStateChip = ({ cancellationRequest }) => {
  const [t] = useTranslation("contracts");

  let label = "?";
  let color = "default";
  switch (cancellationRequest?.state) {
    case 0: // Pending
      label = t("pending");
      color = "warning";
      break;
    case 1: // Approved
      label = t("approved");
      color = "success";
      break;
    case 2: // Denied
      label = t("denied");
      color = "error";
      break;
    default:
      break;
  }
  return <Chip color={color} label={label} />;
};

export default CancellationRequestStateChip;
