import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    error: {
      contrastText: "#fff",
      dark: "#c62828",
      light: "#ef5350",
      main: "#d32f2f",
    },
    info: {
      contrastText: "#fff",
      dark: "#01579b",
      light: "#03a9f4",
      main: "#0288d1",
    },
    mode: "dark",
    primary: {
      main: '#e8ca4d',
    },
    secondary: {
      main: '#f50057',
    },
    success: {
      contrastText: "#fff",
      dark: "#1b5e20",
      light: "#4caf50",
      main: "#2e7d32",
    },
    warning: {
      contrastText: "#fff",
      dark: "#e65100",
      light: "#ff9800",
      main: "#ed6c02",
    },
    black: {
      contrastText: "#fff",
      dark: "#000",
      light: "#000",
      main: "#000",
    },
    yellow: {
      contrastText: "#000",
      dark: "#E8CA4D",
      light: "#E8CA4D",
      main: "#E8CA4D",
    },
    white: {
      contrastText: "#000",
      dark: "#fff",
      light: "#fff",
      main: "#fff",
    },
   
  },
});

export default theme;
