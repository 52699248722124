import { useLocation, useHistory } from "react-router-dom";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import PromotionSelect from "../../Inputs/PromotionSelect";

// Material UI
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";

// Components & Utils
import AppContext from "../../../context/AppContext";
import BoxClusterChip from "../../BoxClusterChip";
import CreditCardForm from "../../CreditCardForm";
import ConfirmDialog from "../../ConfirmDialog";
import CustomDate from "../../Inputs/CustomDate";
import CustomSelect from "../../Inputs/CustomSelect";
import TextInput from "../../Inputs/TextInput";
import CenterSelect from "../../Inputs/CenterSelect";
import Button from "../../Inputs/CustomButton";
import { localeFormat } from "../../../utils/format";
import theme from "../../../theme/theme";
import CityInput from "../../Inputs/CityInput";
import CountryInput from "../../Inputs/CountryInput";
import SearchButton from "../../Inputs/SearchButton";
import PhoneInput from "../../Inputs/PhoneInput";
import PostalCodeInput from "../../Inputs/PostalCodeInput";
import {
  FREE_BOX_STATE_ID,
  GENERAL_VAT_ES,
  INDIVIDUAL_CUSTOMER_TYPE_ID,
  MONTHLY_PERIODICITY_ID,
  NIF_ID_TYPE,
  OCCUPIED_BOX_STATE_ID,
  PASSPORT_ID_TYPE,
  PAYCARD_PAYMENT_METHOD_ID,
  RECEIPT_PAYMENT_METHOD_ID,
  SPAIN_ALPHA2,
  SPAIN_COUNTRY_ID,
  UNAVAILABLE_BOX_STATE_ID,
} from "../../../data/constants";
import BillingAddressCard from "../Customers/BillingAddressCard";
import GlobalDialog from "../../global/Dialog";
import BillingAddressForm from "../Customers/BillingAddressForm";
import IdTypeSelect from "../../Inputs/IdTypeSelect";
import Select from "../../global/inputs/Select";

const initialState = {
  form: {
    autoInvoicing: false,
    // Step 1: Center
    centerId: "",
    // Step 2: Box
    box: null,
    // Step 3: Contract
    startDate: "",
    pricePerMeter: 0,
    finalPrice: 0,
    discount: 0,
    discountPercentage: 0,
    periodicity: MONTHLY_PERIODICITY_ID,
    comments: "",
    contractTypeId: "",
    // Step 4: Customer
    // SearchNif: input to find a customer by nif
    searchNif: "",
    newCustomer: {
      address: "",
      birthdate: "",
      city: null,
      customerTypeId: INDIVIDUAL_CUSTOMER_TYPE_ID,
      email: "",
      gender: "",
      idType: NIF_ID_TYPE,
      name: "",
      nif: "",
      phone: "",
      postalCode: "",
      residenceCountry: {
        id: SPAIN_COUNTRY_ID,
        name: "Spain",
      },
      surnames: "",
      tinCountry: {
        id: SPAIN_COUNTRY_ID,
        name: "Spain",
        alpha2: SPAIN_ALPHA2,
      },
    },
    customer: null,
    center: null,
    // Step 5: Payment
    dateOfPayment: "",
    ibanId: "",
    ibanNumber: "",
    // promotion
    promotionCode: "",
    // invoiceIssuerId: 1, //Default issuer: NUT
    paycard: null,
    paycardId: "",
    paymentMethod: "",
    billingAddressId: null,
  },
  inputError: {
    centerId: false,
    comments: false,
    customer: {
      id: false,
      customerTypeId: false,
      name: false,
      nif: false,
      surnames: false,
      email: false,
      gender: false,
      address: false,
      phone: false,
      emails: false,
      phones: false,
      periodicity: false,
      contractTypeId: false,
    },
    finalPrice: false,
    paycardId: false,
    paymentMethod: false,
    iban: false,
    pricePerMeter: false,
    startDate: false,
  },
  activeStep: 0,
  addIbanLoading: false,
  boxes: [],
  cardModal: { isOpen: false },
  cards: [],
  centers: [],
  clusters: [],
  contractTypes: [],
  ibans: [],
  id: "",
  invoicingTypes: [],
  isNewCustomer: true,
  issuers: [],
  loading: false,
  loadingCustomer: false,
  newEmail: { email: "", contact: "" },
  newIban: "",
  newPhone: { number: "", contact: "" },
  nif: null,
  paymentDays: [],
  promotions: [],
  useClusters: "",
  emailConfirmDialog: {
    isOpen: false,
  },
  addBillingAddressDialog: {
    isOpen: false,
    loading: false,
  },
  newBillingAddress: {
    address: "",
    city: "",
    idType: "",
    name: "",
    nif: "",
    postalCode: "",
    residenceCountry: "",
    tinCountry: "",
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_INPUT":
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INPUT_CUSTOMER":
      return {
        ...state,
        form: {
          ...state.form,
          newCustomer: {
            ...state.form.newCustomer,
            [action.payload.inputname]: action.payload.value,
          },
        },
      };
    case "SET_CENTERS":
      return { ...state, centers: action.payload };
    case "SET_CLUSTERS":
      return { ...state, clusters: action.payload };
    case "SET_CENTER_DATA":
      return {
        ...state,
        contractTypes: action.payload.contractTypes,
        issuers: action.payload.issuers,
        useClusters: action.payload.useClusters,
        form: {
          ...state.form,
          contractTypeId: action.payload.defaultContractTypeId,
          // invoiceIssuerId: action.payload.defaultIssuerId,
        },
      };
    case "SET_CUSTOMER_DATA":
      return {
        ...state,
        form: {
          ...state.form,
          customer: action.payload,
          searchNif: action.payload.nif || "",
          ibanId: action.payload.defaultIBANId || "",
          paycardId: action.payload.defaultPayCardId || "",
        },
        cards: action.payload.PayCards || [],
        ibans: action.payload.IBANs || [],
      };
    case "SET_BOXES":
      return { ...state, boxes: action.payload.boxes };
    case "SET_NIF":
      return { ...state, nif: action.payload.nif };
    case "SET_BOX":
      return {
        ...state,
        form: {
          ...state.form,
          box: action.payload,
        },
      };
    case "SET_CENTER":
      return {
        ...state,
        center: action.payload,
      };
    case "SET_FORMS_OF_PAY":
      return {
        ...state,
        ibans: action.payload.ibans,
        cards: action.payload.cards,
      };
    case "SET_PROMOTIONS":
      return { ...state, promotions: action.payload };
    case "SET_ADD_IBAN_LOADING_TRUE":
      return { ...state, addIbanLoading: true };
    case "SET_ADD_IBAN_LOADING_FALSE":
      return { ...state, addIbanLoading: false };
    case "SET_NEW_IBAN":
      return { ...state, newIban: action.payload };
    case "SET_IS_NEW_CUSTOMER":
      return { ...state, isNewCustomer: action.payload };
    case "SET_ISSUERS":
      return { ...state, issuers: action.payload };
    case "SET_MODAL":
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          isOpen: action.payload.value,
        },
      };
    case "SET_CONTRACT_TYPES":
      return { ...state, contractTypes: action.payload };
    case "SET_INPUT_ERROR_TRUE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: true,
        },
      };
    case "SET_INPUT_ERROR_FALSE":
      return {
        ...state,
        inputError: {
          ...state.inputError,
          [action.payload.inputname]: false,
        },
      };
    case "RESET_CUSTOMER":
      return {
        ...state,
        form: {
          ...state.form,
          customer: {
            ...initialState.form.customer,
          },
          newCustomer: {
            ...initialState.form.newCustomer,
          },
          ibanId: "",
          paycardId: "",
          searchNif: "",
        },
        ibans: [],
        cards: [],
      };
    case "RESET_CENTER_DATA":
      return {
        ...state,
        boxes: [],
        contractTypes: [],
        issuers: initialState.issuers,
        form: {
          ...state.form,
          box: null,
          pricePerMeter: "",
          contractTypeId: "",
          // invoiceIssuerId: "",
        },
      };
    case "SET_PHONE_INPUT":
      return {
        ...state,
        newPhone: {
          ...state.newPhone,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_INVOICING_TYPES":
      return { ...state, invoicingTypes: action.payload };
    case "SET_PAYMENT_DAY":
      return { ...state, paymentDays: action.payload };
    case "RESET_FORM":
      return { ...state, form: initialState.form };
    case "SET_ACTIVE_STEP":
      return { ...state, activeStep: action.payload };
    case "SET_LOADING_CUSTOMER":
      return { ...state, loadingCustomer: action.payload };
    case "SET_LOADING":
      return { ...state, loading: !state.loading };
    case "SET_EMAIL_DIALOG_STATE":
      return {
        ...state,
        emailConfirmDialog: {
          ...state.emailConfirmDialog,
          isOpen: action.payload,
        },
      };
    case "SET_ADD_BILLING_ADDRESS_DIALOG_STATE":
      return {
        ...state,
        addBillingAddressDialog: {
          ...state.addBillingAddressDialog,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_NEW_BILLING_ADDRESS_INPUT":
      return {
        ...state,
        newBillingAddress: {
          ...state.newBillingAddress,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_NEW_BILLING_ADDRESS":
      return {
        ...state,
        newBillingAddress: initialState.newBillingAddress,
      };
    default:
      throw new Error("Action not found in reducer");
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CreateContractPage() {
  const { api, user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("contracts");
  const [tErrors] = useTranslation("errors");

  const [state, dispatch] = useReducer(reducer, initialState);

  // Default vat percentage
  const VAT_MULTIPLIER = 1 + GENERAL_VAT_ES / 100;

  const query = useQuery();
  const boxId = Number(query.get("boxId"));
  const customerId = Number(query.get("customerId"));
  const centerId = Number(query.get("centerId"));
  const history = useHistory();

  // TODO: Get from backend?
  const PERIODS = [
    {
      id: 1,
      name: t("monthly"),
      months: 1,
    },
    {
      id: 2,
      name: t("quarterly"),
      months: 3,
    },
    {
      id: 3,
      name: t("biannual"),
      months: 6,
    },
    {
      id: 4,
      name: t("annual"),
      months: 12,
    },
  ];

  //Initial useEffect
  useEffect(() => {
    document.title = t("createContractPage");

    getCenters();
    getInvoicingTypes();
    getPaymentDays();

    if (customerId) {
      getCustomer(customerId);
    }
  }, []);

  // On center change
  useEffect(() => {
    // Get boxes when center is selected
    getBoxes();
  }, [state.form.centerId]);

  useEffect(() => {
    getPromotions();
  }, [state.form.centerId, state.form.periodicity]);

  /* BACKEND CALLS */

  const getCenters = () => {
    let centers = [];

    api
      .get("/centers")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          centers = response.data;
          dispatch({
            type: "SET_CENTERS",
            payload: centers,
          });
        }
        if (centerId) handleSetCenter(centerId, 1);

        if (boxId) {
          let params = {
            include: ["BoxCluster"],
          };
          api
            .get("/boxes/" + boxId, { params })
            .then((response) => {
              if (response.data.error) {
                enqueueSnackbar(tErrors(response.data.error), {
                  variant: "error",
                });
              } else {
                //Set center & box
                handleSetCenter(response.data.centerId, 2);
                dispatch({
                  type: "SET_INPUT",
                  payload: { inputname: "box", value: response.data },
                });

                const cluster = response.data.cluster;

                //If is cluster:
                if (cluster !== null) {
                  response.data.minimumPrice = round(
                    response.data.pricePerMeter * response.data.meters
                  );

                  // If cluster price is null, undefined or 0, set minimum price (this is always the case for almacen boxes)
                  response.data.clusterPrice = cluster.price
                    ? cluster.price
                    : response.data.minimumPrice;

                  let price = cluster.price;

                  if (price === 0 || price === null) {
                    changeDefaultInputs(
                      round(response.data.pricePerMeter * response.data.meters),
                      response.ddata.meters,
                      false
                    );
                  } else {
                    changeDefaultInputs(
                      cluster.price,
                      response.data.meters,
                      true
                    );
                  }
                } else {
                  //If not cluster:
                  changeDefaultInputs(
                    round(response.data.pricePerMeter * response.data.meters),
                    response.data.meters,
                    false
                  );
                }
              }
            })
            .catch((error) => {
              enqueueSnackbar(error.toString(), { variant: "error" });
            });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getCustomer = () => {
    let params = {
      include: ["BillingAddress", "CustomerEmail", "IBAN", "PayCard"],
    };

    api
      .get("/customers/" + customerId, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_IS_NEW_CUSTOMER",
            payload: false,
          });
          dispatch({
            type: "SET_CUSTOMER_DATA",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getInvoicingTypes = () => {
    api
      .get("/invoicing-types")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_INVOICING_TYPES",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getPaymentDays = () => {
    api
      .get("/payment-days/get")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const days = response.data.map((day) => day.day);
          dispatch({ type: "SET_PAYMENT_DAY", payload: days });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const getBoxes = () => {
    if (state.form.centerId !== "") {
      api
        .get("/centers/" + state.form.centerId + "/boxes")
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            dispatch({
              type: "SET_BOXES",
              payload: {
                boxes: response.data,
              },
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const getPromotions = () => {
    if (state.form.centerId === "" || state.form.periodicity === "") return;
    api
      .get("/contracts/promotions", {
        params: {
          contractInfo: {
            centerId: state.form.centerId,
            periodicity: state.form.periodicity,
          },
        },
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          dispatch({
            type: "SET_PROMOTIONS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      });
  };

  const setCenterData = (centerId) => {
    dispatch({ type: "RESET_CENTER_DATA" });

    if (centerId !== "") {
      api
        .get("/centers/" + centerId)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            dispatch({
              type: "SET_CENTER_DATA",
              payload: {
                contractTypes: response.data.ContractTypes,
                issuers: response.data.Issuers,
                defaultContractTypeId: response.data.defaultContractType,
                defaultIssuerId: response.data.defaultIssuer,
                useClusters: response.data.useClusters,
              },
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const setCenterClusters = (centerId) => {
    if (centerId !== "") {
      api
        .get("/centers/" + centerId + "/box-clusters")
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            const clusters = response.data.map((cluster) => ({
              ...cluster,
              priceWithVAT: round(Number(cluster.price) * VAT_MULTIPLIER),
              boxes: cluster.boxes.sort((a, b) => a.meters - b.meters),
            }));
            dispatch({
              type: "SET_CLUSTERS",
              payload: clusters,
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const submitCardForm = (card) => {
    if (state.isNewCustomer) {
      dispatch({
        type: "SET_INPUT",
        payload: {
          inputname: "paycard",
          value: {
            name: card.name,
            number: card.number,
            cvv: card.cvc,
            expirationMonth: card.expiry.substring(0, 2),
            expirationYear: card.expiry.substring(3),
          },
        },
      });
      handleCloseCardModal();
    } else {
      let form = {
        name: card.name,
        number: card.number,
        cvv: card.cvc,
        expirationMonth: card.expiry.substring(0, 2),
        expirationYear: card.expiry.substring(3),
        customerId: state.form.customer.id,
      };

      api
        .post("/pay-cards/create", form)
        .then((response) => {
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
          } else {
            enqueueSnackbar(t("cardCreated"), { variant: "success" });
            dispatch({
              type: "SET_FORMS_OF_PAY",
              payload: {
                cards: [...state.cards, response.data],
                ibans: state.ibans,
              },
            });
            dispatch({
              type: "SET_INPUT",
              payload: {
                inputname: "paycardId",
                value: response.data.id,
              },
            });
            handleCloseCardModal();
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    }
  };

  const createIban = (number) => {
    const ibanRepeated = state.ibans.some((iban) => iban.number === number);

    dispatch({ type: "SET_ADD_IBAN_LOADING_TRUE" });
    if (state.isNewCustomer) {
      api
        .get("/iban/" + number + "/validate")
        .then((response) => {
          dispatch({ type: "SET_ADD_IBAN_LOADING_FALSE" });
          if (response.data.error) {
            enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
            dispatch({ type: "SET_NEW_IBAN", payload: "" });
          } else {
            if (response.data.isValid) {
              dispatch({
                type: "SET_INPUT",
                payload: { inputname: "ibanNumber", value: number },
              });
              dispatch({
                type: "SET_NEW_IBAN",
                payload: "",
              });
              enqueueSnackbar(t("ibanValid"), { variant: "success" });
            } else {
              enqueueSnackbar(t("ibanNotValid"), { variant: "error" });
            }
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
        });
    } else {
      if (!ibanRepeated) {
        api
          .post("/ibans/create", {
            number,
            customerId: state.form.customer.id,
          })
          .then((response) => {
            dispatch({ type: "SET_ADD_IBAN_LOADING_FALSE" });
            if (response.data.error) {
              enqueueSnackbar(tErrors(response.data.error), {
                variant: "error",
              });
              dispatch({ type: "SET_NEW_IBAN", payload: "" });
            } else {
              enqueueSnackbar(t("ibanCreated"), { variant: "success" });
              dispatch({
                type: "SET_FORMS_OF_PAY",
                payload: {
                  cards: state.cards,
                  ibans: [...state.ibans, response.data.iban],
                },
              });
              setDefaultIban(response.data.iban.id);
              dispatch({ type: "SET_NEW_IBAN", payload: "" });
            }
          })
          .catch((error) => {
            enqueueSnackbar(error.toString(), { variant: "error" });
          });
      } else {
        enqueueSnackbar(t("ibanRepeated"), { variant: "warning" });
      }
    }
  };

  const createContract = () => {
    if (!validateForm())
      enqueueSnackbar(t("invalidContractData"), { variant: "error" });
    else if (!state.isNewCustomer && state.form.customer.personaNonGrata)
      enqueueSnackbar(t("customerMarkedAsPersonaNonGrata"), {
        variant: "error",
      });
    else {
      if (
        (state.isNewCustomer && state.form.newCustomer.email) ||
        (!state.isNewCustomer &&
          state.form.customer?.CustomerEmails &&
          state.form.customer?.CustomerEmails.length)
      )
        handleEmailConfirmDialogStatus(true);
      else sendCreateContract(false);
    }
  };

  const sendCreateContract = (sendEmail) => {
    let data = {};

    // Box
    data.boxId = state.form.box.id;

    // Contract info
    data.startDate = state.form.startDate;
    data.contractTypeId = state.form.contractTypeId;
    data.pricePerMeter = state.form.pricePerMeter;
    data.periodicity = Number(state.form.periodicity);
    data.comments = state.form.comments;

    // Customer info
    data.isNewCustomer = state.isNewCustomer;
    if (state.isNewCustomer === true) {
      data.Customer = {
        ...state.form.newCustomer,
        cityId: state.form.newCustomer.city?.id || null,
        residenceCountryId: state.form.newCustomer.residenceCountry?.id,
        tinCountryId: state.form.newCustomer.tinCountry?.id,
      };
    } else {
      data.customerId = state.form.customer.id;
      data.billingAddressId = state.form.billingAddressId;
    }

    // Payment info
    data.paymentMethod = Number(state.form.paymentMethod);
    data.dateOfPayment = state.form.dateOfPayment || null;
    data.invoiceIssuerId = 1; //state.form.invoiceIssuerId;

    // If receipt
    if (Number(state.form.paymentMethod) === RECEIPT_PAYMENT_METHOD_ID) {
      if (state.isNewCustomer) {
        data.ibanNumber = state.form.ibanNumber;
      } else {
        data.ibanId = state.form.ibanId;
      }
    }

    // If paycard
    if (Number(state.form.paymentMethod) === PAYCARD_PAYMENT_METHOD_ID) {
      if (state.isNewCustomer) {
        data.paycard = state.form.paycard;
      } else {
        data.paycardId = state.form.paycardId;
      }
    }

    // If promotion
    if (state.form.promotionCode !== "")
      data.promotionCode = state.form.promotionCode;

    data.autoInvoicing = state.form.autoInvoicing;
    data.sendEmail = sendEmail;

    dispatch({ type: "SET_LOADING" });
    api
      .post("/contracts/create", data)
      .then((response) => {
        if (
          response.data.error &&
          response.data.error.includes("Can't send mail")
        ) {
          enqueueSnackbar(t("contractCreateSuccess"), { variant: "success" });
          enqueueSnackbar(response.data.error, { variant: "warning" });
          if (user.hasAction("VIEW_CONTRACTS"))
            history.push(
              `/app/contracts?autoSearch=true&text=${
                state.isNewCustomer
                  ? state.form.newCustomer.name
                  : state.form.customer.name
              }&state=0,1&orderBy=createdAt&order=desc`
            );
          else history.push("/app/contracts");
        } else if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("contractCreateSuccess"), { variant: "success" });
          history.push(`/app/contract/${response.data.id}`);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() => dispatch({ type: "SET_LOADING" }));
  };

  const getCustomerByNIF = () => {
    dispatch({ type: "SET_LOADING_CUSTOMER", payload: true });
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "customer",
        value: null,
      },
    });
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "billingAddressId",
        value: null,
      },
    });

    let params = {
      include: ["BillingAddress", "IBAN", "PayCard", "CustomerEmail"],
    };

    api
      .get("/customers/find-by-nif/" + state.form.searchNif, { params })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          // If the customer has a default billing address, set it
          if (response.data.BillingAddresses?.length) {
            const defaultBillingAddress = response.data.BillingAddresses.find(
              (address) => address.isDefault
            );
            if (defaultBillingAddress)
              setBillingAddressSelected(defaultBillingAddress.id);
            else
              setBillingAddressSelected(response.data.BillingAddresses[0].id);
          }
          dispatch({
            type: "SET_CUSTOMER_DATA",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() =>
        dispatch({ type: "SET_LOADING_CUSTOMER", payload: false })
      );
  };

  const validateNIF = async (nif, countryId, idType) => {
    try {
      const validateResponse = await api.get("/validate-nif", {
        params: { nif, countryId, idType },
      });

      if (validateResponse.data.error) {
        const errorMessage = validateResponse.data.error;
        enqueueSnackbar(errorMessage, { variant: "error" });
        return { isValid: false, error: true, customerRepeated: false };
      }

      if (validateResponse.data.isValid) {
        const findByNIFResponse = await api.get(
          "/customers/find-by-nif/" + state.form.newCustomer.nif
        );

        if (findByNIFResponse.data.error) {
          if (findByNIFResponse.data.error === "customer-001")
            return { isValid: true, error: false, customerRepeated: false };
          else return { isValid: false, error: true, customerRepeated: false };
        }

        if (findByNIFResponse.data.id) {
          enqueueSnackbar(t("customerAlreadyExists"), { variant: "warning" });
          return { isValid: true, error: false, customerRepeated: true };
        } else {
          return { isValid: true, error: false, customerRepeated: false };
        }
      } else {
        return { isValid: false, error: false, customerRepeated: false };
      }
    } catch (error) {
      enqueueSnackbar(error.toString(), { variant: "error" });
      return { isValid: false, error: true, customerRepeated: false };
    }
  };

  const addBillingAddress = () => {
    if (!state.form.customer) return;

    const customerId = state.form.customer.id;
    const form = state.newBillingAddress;

    form.residenceCountryId = state.newBillingAddress.residenceCountry?.id;
    form.tinCountryId = state.newBillingAddress.tinCountry?.id;

    if (form.residenceCountryId === SPAIN_COUNTRY_ID) {
      form.cityId = state.newBillingAddress.city?.id;
    } else {
      delete form.postalCode;
    }

    dispatch({
      type: "SET_ADD_BILLING_ADDRESS_DIALOG_STATE",
      payload: { inputname: "loading", value: true },
    });

    api
      .post("/customers/" + customerId + "/address", form)
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          const billingAddress = response.data;

          // Update customer data with new billing address
          dispatch({
            type: "SET_CUSTOMER_DATA",
            payload: {
              ...state.form.customer,
              BillingAddresses: [
                billingAddress,
                ...(state.form.customer.BillingAddresses || []),
              ],
            },
          });

          // Set it as the selected billing address
          setBillingAddressSelected(billingAddress.id);

          // Show success message, close dialog and reset form
          enqueueSnackbar(t("billingAddressAddedSuccessfully"), {
            variant: "success",
          });
          handleCloseAddBillingAddressDialog();
          dispatch({ type: "RESET_NEW_BILLING_ADDRESS" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.toString(), { variant: "error" });
      })
      .finally(() =>
        dispatch({
          type: "SET_ADD_BILLING_ADDRESS_DIALOG_STATE",
          payload: { inputname: "loading", value: false },
        })
      );
  };

  /* HANDLERS */

  const handleSetCenter = (centerId, step) => {
    dispatch({
      type: "SET_INPUT",
      payload: { inputname: "centerId", value: centerId },
    });
    setCenterData(centerId);
    dispatch({
      type: "SET_ACTIVE_STEP",
      payload: step,
    });
  };

  const handleOpenCardModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "cardModal",
        value: true,
      },
    });
  };

  const handleCloseCardModal = () => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        modal: "cardModal",
        value: false,
      },
    });
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleTotalNoTaxChange = (e) => {
    handleInputChange(e);

    const pricePerMeter = round(e.target.value / state.form.box?.meters);
    const finalPrice = round(e.target.value * VAT_MULTIPLIER);
    const discount = round(state.form.box.clusterPrice - e.target.value);
    const discountPercentage = round(
      (discount / state.form.box.clusterPrice) * 100
    );

    if (state.form.box.clusterPrice) {
      handleInputChange({
        target: {
          name: "discount",
          value: discount,
        },
      });

      handleInputChange({
        target: {
          name: "discountPercentage",
          value: discountPercentage,
        },
      });
    }

    handleInputChange({
      target: {
        name: "finalPrice",
        value: finalPrice,
      },
    });
    handleInputChange({
      target: {
        name: "pricePerMeter",
        value: pricePerMeter,
      },
    });
  };

  const handleFinalPrice = (e) => {
    handleInputChange(e);

    const totalNoTax = round(e.target.value / VAT_MULTIPLIER);
    const pricePerMeter = round(totalNoTax / state.form.box?.meters);
    const discount = round(state.form.box.clusterPrice - totalNoTax);
    const discountPercentage = round(
      (discount / state.form.box.clusterPrice) * 100
    );

    if (state.form.box.clusterPrice) {
      handleInputChange({
        target: {
          name: "discount",
          value: discount,
        },
      });

      handleInputChange({
        target: {
          name: "discountPercentage",
          value: discountPercentage,
        },
      });
    }
    handleInputChange({
      target: {
        name: "pricePerMeter",
        value: pricePerMeter,
      },
    });
    handleInputChange({
      target: {
        name: "totalNoTax",
        value: totalNoTax,
      },
    });
  };

  const handleDiscountPercentage = (e) => {
    handleInputChange(e);

    const totalNoTax = round(
      state.form.box.clusterPrice * (1 - e.target.value / 100)
    );
    const finalPrice = round(totalNoTax * VAT_MULTIPLIER);
    const discount = round(state.form.box.clusterPrice - totalNoTax);
    const pricePerMeter = round(totalNoTax / state.form.box?.meters);

    handleInputChange({
      target: {
        name: "totalNoTax",
        value: totalNoTax,
      },
    });

    handleInputChange({
      target: {
        name: "finalPrice",
        value: finalPrice,
      },
    });

    handleInputChange({
      target: {
        name: "discount",
        value: discount,
      },
    });

    handleInputChange({
      target: {
        name: "pricePerMeter",
        value: pricePerMeter,
      },
    });
  };

  const handleInputChangeIban = (e) => {
    dispatch({
      type: "SET_NEW_IBAN",
      payload: e.target.value,
    });
  };

  const handleInputChangeCustomer = (e) => {
    dispatch({
      type: "SET_INPUT_CUSTOMER",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const handleEmailConfirmDialogStatus = (state) => {
    dispatch({ type: "SET_EMAIL_DIALOG_STATE", payload: state });
  };

  const handleOpenAddBillingAddressDialog = () => {
    dispatch({
      type: "SET_ADD_BILLING_ADDRESS_DIALOG_STATE",
      payload: { inputname: "isOpen", value: true },
    });
  };

  const handleCloseAddBillingAddressDialog = () => {
    dispatch({
      type: "SET_ADD_BILLING_ADDRESS_DIALOG_STATE",
      payload: { inputname: "isOpen", value: false },
    });
  };

  const handleAddressInputChange = (e) => {
    dispatch({
      type: "SET_NEW_BILLING_ADDRESS_INPUT",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const resetCustomer = () => {
    dispatch({
      type: "RESET_CUSTOMER",
    });
  };

  const setBox = (e, isCluster) => {
    let box = state.boxes.find((box) => box.id == e.target.value);

    if (isCluster) {
      const cluster = state.clusters.find((cluster) =>
        cluster.boxes.find((box) => box.id === e.target.value)
      );

      box.minimumPrice = round(box.pricePerMeter * box.meters);

      // If cluster price is null, undefined or 0, set minimum price (this is always the case for almacen boxes)
      box.clusterPrice = cluster.price ? cluster.price : box.minimumPrice;

      let price = cluster.price;

      if (price === 0 || price === null) {
        changeDefaultInputs(
          round(box.pricePerMeter * box.meters),
          box.meters,
          false
        );
      } else {
        changeDefaultInputs(cluster.price, box.meters, true);
      }
    } else
      changeDefaultInputs(
        round(box.pricePerMeter * box.meters),
        box.meters,
        false
      );

    dispatch({
      type: "SET_BOX",
      payload: box,
    });
  };

  const changeDefaultInputs = (price, meters, isCluster) => {
    if (isCluster) {
      const totalNoTax = price ? round(price) : 0;
      const finalPrice = price ? round(price * VAT_MULTIPLIER) : 0;
      const pricePerMeter = price ? round(totalNoTax / meters) : 0;

      handleInputChange({
        target: {
          name: "finalPrice",
          value: finalPrice,
        },
      });
      handleInputChange({
        target: {
          name: "totalNoTax",
          value: totalNoTax,
        },
      });
      handleInputChange({
        target: {
          name: "pricePerMeter",
          value: pricePerMeter,
        },
      });

      return;
    }

    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "finalPrice",
        value: price ? round(price * VAT_MULTIPLIER) : 0,
      },
    });

    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "totalNoTax",
        value: price || 0,
      },
    });

    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "pricePerMeter",
        value: price && Number(meters) !== 0 ? round(price / meters) : 0,
      },
    });
  };

  const round = (value, decimals = 2) => {
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  };

  const setInputErrorTrue = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_TRUE",
      payload: {
        inputname: name,
      },
    });
  };

  const setInputErrorFalse = (name) => {
    dispatch({
      type: "SET_INPUT_ERROR_FALSE",
      payload: {
        inputname: name,
      },
    });
  };

  const setDefaultIban = (ibanId) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "ibanId",
        value: ibanId,
      },
    });
  };

  const setBillingAddressSelected = (billingAddressId) => {
    dispatch({
      type: "SET_INPUT",
      payload: {
        inputname: "billingAddressId",
        value: billingAddressId,
      },
    });
  };

  const nextStep = (step) =>
    dispatch({ type: "SET_ACTIVE_STEP", payload: step });

  const previousStep = (step) =>
    state.activeStep > 0 &&
    dispatch({ type: "SET_ACTIVE_STEP", payload: step });

  /* VALIDATORS */

  // Center info
  const validateCenterInfo = () => {
    if (state.form.centerId !== "") return true;
    enqueueSnackbar(t("youMustSelectCenter"), { variant: "error" });
    return false;
  };

  // Box info
  const validateBoxInfo = () => {
    if (state.form.box !== null && state.form.box.id) return true;
    enqueueSnackbar(t("youMustSelectBox"), { variant: "error" });
    return false;
  };

  // Contract info
  const validateContractInfo = () => {
    let isValid = true;
    const fields = ["startDate", "pricePerMeter", "finalPrice"];

    fields.forEach((field) => {
      if (state.form[field] === "") {
        enqueueSnackbar(t(field) + " " + t("cannotBeEmpty"), {
          variant: "error",
        });
        isValid = false;
      }
    });

    return isValid;
  };

  // Customer info
  const validateNewCustomerForm = () => {
    const form = state.form.newCustomer;

    let isValid = true;

    const fields = [
      "name",
      "nif",
      "email",
      "phone",
      "address",
      "tinCountry",
      "residenceCountry",
    ];

    fields.forEach((field) => {
      if (field === "phone") {
        if (form[field].length < 6) {
          enqueueSnackbar(t("invalidPhone"), { variant: "error" });
          isValid = false;
        }
      } else if (!form[field]) {
        enqueueSnackbar(t(field) + " " + t("cannotBeEmpty"), {
          variant: "error",
        });
        isValid = false;
      }
    });

    if (Number(form.customerTypeId) === INDIVIDUAL_CUSTOMER_TYPE_ID) {
      if (!form.surnames) {
        enqueueSnackbar(t("surnames") + " " + t("cannotBeEmpty"), {
          variant: "error",
        });
        isValid = false;
      }
    }

    if (form.email !== "") {
      if (!validateEmail(form.email)) {
        isValid = false;
      }
    }

    return new Promise((resolve, reject) => {
      validateNIF(form.nif, form.tinCountry?.id, form.idType)
        .then((response) => {
          if (response.customerRepeated || response.error) {
            isValid = false;
          } else if (
            response.customerRepeated === false &&
            response.isValid === false
          ) {
            isValid = false;
            enqueueSnackbar(
              t("idNotValid", { idType: t(form.idType.toLowerCase()) }),
              { variant: "error" }
            );
          }
          resolve(isValid);
        })
        .catch((error) => {
          enqueueSnackbar(error.toString(), { variant: "error" });
          resolve(false);
        });
    });
  };

  const validateEmail = (emailVal) => {
    const re = /\S+@\S+\.\S+/; //Regex for testing email format

    if (!emailVal || emailVal === "" || !re.test(emailVal)) {
      enqueueSnackbar(t("emailNotValid"), { variant: "error" });
      return false;
    }
    return true;
  };

  const validateForm = () => {
    let isValid = true;

    // Validate mandatory fields exist
    let fields = [
      "startDate",
      "box",
      "contractTypeId",
      "pricePerMeter",
      "periodicity",
      "paymentMethod",
    ];

    fields.forEach((field) => {
      if (!state.form[field] || state.form[field] === "") {
        // setInputErrorTrue(field);
        enqueueSnackbar(t(field) + " " + t("cannotBeEmpty"), {
          variant: "error",
        });
        isValid = false;
      }
    });

    if (Number(state.form.paymentMethod) === RECEIPT_PAYMENT_METHOD_ID) {
      if (
        (state.isNewCustomer && state.form.ibanNumber === "") ||
        (!state.isNewCustomer && state.form.ibanId === "")
      ) {
        enqueueSnackbar(t("youMustSelectIBAN"), { variant: "error" });
        isValid = false;
      }
    }

    if (Number(state.form.paymentMethod) === PAYCARD_PAYMENT_METHOD_ID) {
      if (
        (state.isNewCustomer && state.form.paycard === "") ||
        (!state.isNewCustomer && state.form.paycardId === "")
      ) {
        enqueueSnackbar(t("youMustSelectPaycard"), { variant: "error" });
        isValid = false;
      }
    }
    return isValid;
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{t("createContract")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stepper orientation="vertical" activeStep={state.activeStep}>
              {/* Step 1: Center */}
              <Step>
                <StepLabel>
                  {t("center")}
                  {state.form.centerId !== "" &&
                    " - " +
                      state.centers.find(
                        (center) => center.id === Number(state.form.centerId)
                      )?.name}
                </StepLabel>
                <StepContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CenterSelect
                        value={state.form.centerId.toString()}
                        onChange={(e) => {
                          handleInputChange(e);
                          setCenterData(e.target.value);
                          setCenterClusters(e.target.value);
                        }}
                        name="centerId"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        disabled={state.form.centerId === ""}
                        onClick={() => validateCenterInfo() && nextStep(1)}
                      >
                        {t("next")}
                      </Button>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>

              {/* Step 2: Box */}
              <Step>
                <StepLabel>
                  {t("box")}
                  {state.form.box &&
                    " - " +
                      state.form.box?.name +
                      " | " +
                      state.form.box?.meters +
                      " m2"}
                </StepLabel>
                <StepContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {state.useClusters ? (
                        <ClusterSelector
                          clusters={state?.clusters}
                          value={state.form.box?.id}
                          onChange={setBox}
                        />
                      ) : state.boxes.length > 0 ? (
                        <BoxSelector
                          boxes={state.boxes}
                          value={state.form.box?.id}
                          onChange={setBox}
                        />
                      ) : (
                        <Box display="flex" alignItems="center" gap={1}>
                          <CircularProgress size={20} />
                          <Typography variant="caption">
                            {t("loadingBoxes") + "..."}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button onClick={() => previousStep(0)} variant="text">
                        {t("back")}
                      </Button>
                      <Button
                        disabled={!state.form.box}
                        onClick={() => validateBoxInfo() && nextStep(2)}
                      >
                        {t("next")}
                      </Button>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>

              {/* Step 3 : Contract*/}
              <Step>
                <StepLabel>
                  {t("contractInformation")}
                  {state.form.startDate !== "" &&
                    " - " +
                      state.form.startDate +
                      " | " +
                      PERIODS[state.form.periodicity - 1].name +
                      " | " +
                      localeFormat(state.form.finalPrice) +
                      " €"}
                </StepLabel>
                <StepContent>
                  <Grid container spacing={2}>
                    <>
                      <Grid
                        item
                        container
                        spacing={2}
                        alignItems="center"
                        xs={12}
                        md={6}
                      >
                        <Grid container item spacing={2}>
                          <Grid item xs={6}>
                            <CustomDate
                              label={t("startDate")}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              name="startDate"
                              value={state.form.startDate}
                              onChange={handleInputChange}
                              inputProps={{
                                min:
                                  state.form.box?.Contracts.length === 0
                                    ? null
                                    : state.form.box?.Contracts.reduce(
                                        (prev, current) => {
                                          return Date(prev.endDate) >
                                            Date(current.endDate)
                                            ? prev
                                            : current;
                                        }
                                      ).endDate,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomSelect
                              value={state.form.contractTypeId}
                              name="contractTypeId"
                              onChange={handleInputChange}
                              label={t("contractType")}
                              options={state.contractTypes?.map(
                                (contractType) => ({
                                  value: contractType.id,
                                  label: contractType.name,
                                })
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} textAlign="center">
                          <TextInput
                            label={t("totalNoTax")}
                            type="number"
                            name="totalNoTax"
                            value={state.form.totalNoTax}
                            onChange={(e) => handleTotalNoTaxChange(e)}
                          ></TextInput>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextInput
                            label={`${t("finalPrice")} (${t("total")} + ${t(
                              "vatTax"
                            )})`}
                            type="number"
                            name="finalPrice"
                            value={state.form.finalPrice}
                            onChange={(e) => handleFinalPrice(e)}
                          />
                        </Grid>
                        {state.useClusters && (
                          <Grid item xs={12} sm={4} textAlign="center">
                            <TextInput
                              label={t("discountPercentage")}
                              type="number"
                              name="discountPercentage"
                              value={state.form.discountPercentage}
                              onChange={(e) => handleDiscountPercentage(e)}
                            ></TextInput>
                          </Grid>
                        )}
                      </Grid>
                      {state.useClusters && (
                        <>
                          <Grid item container spacing={3}>
                            <Grid item>
                              <Typography variant="body" fontWeight="bold">
                                {t("boxMinimumPrice") +
                                  " (" +
                                  t("noVat") +
                                  ")" +
                                  ": "}
                              </Typography>
                              <Typography variant="body">
                                {localeFormat(state.form.box?.minimumPrice) +
                                  "€"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body" fontWeight="bold">
                                {t("discount") + ": "}
                              </Typography>
                              <Typography
                                variant="body"
                                color={
                                  state.form.totalNoTax >=
                                  state.form.box?.minimumPrice
                                    ? "green"
                                    : "error"
                                }
                              >
                                {localeFormat(state.form.discount) + "€"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container spacing={3}>
                            <Grid item>
                              <Typography variant="body" fontWeight="bold">
                                {t("clusterPrice") +
                                  " (" +
                                  t("noVat") +
                                  ")" +
                                  ": "}
                              </Typography>
                              <Typography variant="body">
                                {localeFormat(state.form.box?.clusterPrice) +
                                  "€"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body" fontWeight="bold">
                                {t("maximumDiscount") + ": "}
                              </Typography>
                              <Typography variant="body">
                                {localeFormat(
                                  state.form.box?.clusterPrice -
                                    state.form.box?.minimumPrice
                                ) + "€ ("}
                              </Typography>
                              <Typography variant="body">
                                {localeFormat(
                                  (1 -
                                    state.form.box?.minimumPrice /
                                      state.form.box?.clusterPrice) *
                                    100
                                ) + "%)"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} display="flex">
                        <FormControl>
                          <RadioGroup
                            name="periodicity"
                            row
                            onChange={handleInputChange}
                            value={state.form.periodicity}
                          >
                            {PERIODS.map((period) => (
                              <FormControlLabel
                                key={period.id}
                                labelPlacement="top"
                                value={period.id}
                                control={<Radio />}
                                label={
                                  <Card
                                    sx={{
                                      borderColor:
                                        state.form.periodicity === period.id
                                          ? theme.palette.info.main
                                          : undefined,
                                      width: 200,
                                    }}
                                    variant="outlined"
                                  >
                                    <Container sx={{ paddingY: 3 }}>
                                      <Grid
                                        container
                                        direction="column"
                                        alignItems="center"
                                        spacing={1}
                                      >
                                        <Grid item>
                                          <Typography
                                            variant="h5"
                                            fontWeight="bold"
                                          >
                                            {period.name}
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography variant="h4">
                                            {localeFormat(
                                              Number(state.form.finalPrice) *
                                                period.months || 0
                                            )}
                                            €
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          /
                                          {period.months +
                                            " " +
                                            (period.months === 1
                                              ? t("month")
                                              : t("months"))}
                                        </Grid>
                                      </Grid>
                                    </Container>
                                  </Card>
                                }
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextInput
                          label={t("comments")}
                          multiline
                          rows={4}
                          name="comments"
                          value={state.form.comments}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          onClick={() => {
                            return previousStep(1);
                          }}
                          variant="text"
                        >
                          {t("back")}
                        </Button>
                        <Button
                          disabled={
                            !state.form.startDate ||
                            !state.form.contractTypeId ||
                            !state.form.periodicity
                          }
                          onClick={() => validateContractInfo() && nextStep(3)}
                        >
                          {t("next")}
                        </Button>
                      </Grid>
                    </>
                  </Grid>
                </StepContent>
              </Step>

              {/* Step 4 : Customer*/}
              <Step>
                <StepLabel>
                  {t("customerInfo")}
                  {state.isNewCustomer && state.form.newCustomer?.name
                    ? " - " +
                      state.form.newCustomer?.name +
                      " | " +
                      state.form.newCustomer?.nif
                    : state.form.customer?.name &&
                      " - " +
                        state.form.customer?.name +
                        " | " +
                        state.form.customer?.nif}
                </StepLabel>
                <StepContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Container maxWidth="sm">
                        <Box
                          marginTop={2}
                          padding={5}
                          component={Paper}
                          variant="outlined"
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              justifyContent="center"
                              display="flex"
                              xs={12}
                            >
                              <ToggleButtonGroup
                                exclusive
                                value={state.isNewCustomer}
                                onChange={(e, value) => {
                                  dispatch({
                                    type: "SET_IS_NEW_CUSTOMER",
                                    payload: value,
                                  });
                                }}
                              >
                                <ToggleButton value={true}>
                                  {t("newCustomer")}
                                </ToggleButton>
                                <ToggleButton value={false}>
                                  {t("existingCustomer")}
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Grid>
                            {/* New customer Form */}
                            {state.isNewCustomer === true && (
                              <>
                                <Grid item xs={12}>
                                  <NewCustomerForm
                                    form={state.form.newCustomer}
                                    inputHandler={handleInputChangeCustomer}
                                  />
                                </Grid>
                              </>
                            )}

                            {/* Search customer */}
                            {state.isNewCustomer === false && (
                              <Grid
                                container
                                item
                                spacing={2}
                                alignItems="center"
                                xs={12}
                              >
                                <Grid item xs={8}>
                                  <TextInput
                                    name="searchNif"
                                    value={state.form.searchNif}
                                    onChange={handleInputChange}
                                    label={t("nif")}
                                    onKeyPress={(event) => {
                                      if (event.key === "Enter") {
                                        getCustomerByNIF();
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <SearchButton
                                    loading={state.loadingCustomer}
                                    onClick={getCustomerByNIF}
                                    disabled={state.form.searchNif === ""}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body1"
                                    fontWeight="medium"
                                  >
                                    {t("name")}:
                                    <Typography
                                      component="span"
                                      fontWeight="regular"
                                      marginLeft={1}
                                    >
                                      {state.form.customer?.fullName}
                                    </Typography>
                                  </Typography>
                                </Grid>

                                {state.form.customer?.name && (
                                  <>
                                    <Grid
                                      item
                                      flex={1}
                                      display="flex"
                                      alignItems="center" // Align items vertically
                                      justifyContent="space-between"
                                    >
                                      <Typography
                                        variant="body1"
                                        fontWeight="medium"
                                      >
                                        {t("billingAddress")}
                                      </Typography>
                                      {user.hasAction(
                                        "CREATE_BILLING_ADDRESSES"
                                      ) && (
                                        <Tooltip title={t("addBillingAddress")}>
                                          <IconButton
                                            size="small"
                                            color="primary"
                                            onClick={
                                              handleOpenAddBillingAddressDialog
                                            }
                                          >
                                            <AddBusinessIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </Grid>
                                    <Grid
                                      container
                                      item
                                      direction="column"
                                      xs={12}
                                      columnSpacing={2}
                                      marginLeft={2}
                                      sx={{
                                        overflowX: "auto",
                                        maxHeight: 300,
                                        paddingBottom: 2,
                                      }}
                                    >
                                      {state.form.customer?.BillingAddresses?.map(
                                        (billingAddress) => (
                                          <Grid item key={billingAddress.id}>
                                            <BillingAddressCard
                                              billingAddress={billingAddress}
                                              setAsSelected={
                                                setBillingAddressSelected
                                              }
                                              isSelected={
                                                billingAddress.id ===
                                                state.form.billingAddressId
                                              }
                                            />
                                          </Grid>
                                        )
                                      )}
                                    </Grid>
                                    <GlobalDialog
                                      open={
                                        state.addBillingAddressDialog.isOpen
                                      }
                                      onClose={
                                        handleCloseAddBillingAddressDialog
                                      }
                                      title={t("addBillingAddress")}
                                    >
                                      <BillingAddressForm
                                        address={state.newBillingAddress}
                                        handleInputChange={
                                          handleAddressInputChange
                                        }
                                        handleSave={addBillingAddress}
                                        loading={
                                          state.addBillingAddressDialog.loading
                                        }
                                      />
                                    </GlobalDialog>
                                  </>
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Container>
                    </Grid>

                    <Grid item xs={12}>
                      <Button onClick={() => previousStep(2)} variant="text">
                        {t("back")}
                      </Button>
                      <Button
                        disabled={
                          (state.isNewCustomer === false &&
                            !state.form.customer) ||
                          (state.isNewCustomer === false &&
                            state.form.customer &&
                            !state.form.billingAddressId) ||
                          (state.isNewCustomer === true &&
                            !state.form.newCustomer?.nif)
                        }
                        onClick={() => {
                          if (
                            !state.isNewCustomer &&
                            state.form.customer?.id !== ""
                          ) {
                            nextStep(4);
                          } else if (state.isNewCustomer) {
                            validateNewCustomerForm().then((isValid) => {
                              isValid && nextStep(4);
                            });
                          }
                        }}
                      >
                        {t("next")}
                      </Button>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>

              {/* Step 5 : Payment*/}
              <Step>
                <StepLabel>{t("paymentInfo")}</StepLabel>
                <StepContent>
                  <Grid container spacing={2}>
                    <Grid container item justify="center" spacing={2} xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          {t("formOfPayment")}
                        </Typography>
                      </Grid>

                      <Grid item justify="center" xs={12}>
                        <RadioGroup
                          row
                          aria-label="payment"
                          name="paymentMethod"
                          value={state.form.paymentMethod}
                          onChange={handleInputChange}
                        >
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label={t("card")}
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label={t("receipt")}
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={t("counted")}
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label={t("transfer")}
                          />
                        </RadioGroup>
                      </Grid>

                      {Number(state.form.paymentMethod) ===
                        PAYCARD_PAYMENT_METHOD_ID && (
                        <Grid
                          item
                          container
                          spacing={2}
                          xs={12}
                          justify="center"
                        >
                          {state.isNewCustomer ? (
                            <Grid item xs={12} md={4} lg={3}>
                              <Typography fontWeight="medium">
                                {t("card")}:
                                <Typography component="span" marginLeft={1}>
                                  {state.form.paycard?.number
                                    ? state.form.paycard?.number
                                    : t("addCard")}
                                  {state.form.paycard && (
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        dispatch({
                                          type: "SET_INPUT",
                                          payload: {
                                            inputname: "paycard",
                                            value: null,
                                          },
                                        });
                                      }}
                                    >
                                      <CloseIcon
                                        fontSize="small"
                                        color="error"
                                      />
                                    </IconButton>
                                  )}
                                </Typography>
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={12} md={4} lg={3}>
                              <CustomSelect
                                onChange={handleInputChange}
                                label={t("card")}
                                name="paycardId"
                                value={state.form.paycardId}
                                options={[
                                  { value: "", label: t("none") },
                                  ...state.cards?.map((card) => ({
                                    value: card.id,
                                    label: card.number,
                                  })),
                                ]}
                              />
                            </Grid>
                          )}
                          <Grid item>
                            <Button
                              variant="contained"
                              onClick={handleOpenCardModal}
                            >
                              {t("addCreditCard")}
                            </Button>
                          </Grid>

                          {/* Credit card modal */}
                          <Dialog
                            open={state.cardModal.isOpen}
                            onClose={handleCloseCardModal}
                          >
                            <Button
                              onClick={handleCloseCardModal}
                              color="primary"
                            >
                              <CloseIcon />
                            </Button>
                            <DialogContent>
                              <CreditCardForm onSubmit={submitCardForm} />
                            </DialogContent>
                          </Dialog>
                        </Grid>
                      )}

                      {Number(state.form.paymentMethod) ===
                        RECEIPT_PAYMENT_METHOD_ID && (
                        <Grid
                          item
                          container
                          spacing={2}
                          xs={12}
                          justify="center"
                        >
                          {!state.isNewCustomer && (
                            <Grid item xs={12} md={4} lg={3}>
                              <CustomSelect
                                onChange={handleInputChange}
                                label={t("iban")}
                                name={"ibanId"}
                                value={state.form.ibanId}
                                options={[
                                  { value: "", label: t("none") },
                                  ...state.ibans?.map((iban) => ({
                                    value: iban.id,
                                    label: iban.partialNumber,
                                  })),
                                ]}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} sm={9} md={4} lg={3}>
                            <TextInput
                              label={t("newIban")}
                              name="newIban"
                              value={state.newIban}
                              onChange={handleInputChangeIban}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3} md="auto">
                            <Button
                              loading={state.addIbanLoading}
                              loadingText=""
                              onClick={() => {
                                createIban(state.newIban);
                              }}
                              disabled={state.newIban === ""}
                              fullWidth
                            >
                              {state.isNewCustomer ? t("validate") : t("add")}
                            </Button>
                          </Grid>
                          {state.isNewCustomer && (
                            <Grid item xs={12}>
                              <Typography fontWeight="medium">
                                {t("iban")}:
                                <Typography
                                  component="span"
                                  fontWeight="regular"
                                  marginLeft={1}
                                >
                                  {state.form.ibanNumber !== ""
                                    ? state.form.ibanNumber
                                    : t("validateIban")}
                                  {state.form.ibanNumber !== "" && (
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        dispatch({
                                          type: "SET_INPUT",
                                          payload: {
                                            inputname: "ibanNumber",
                                            value: "",
                                          },
                                        });
                                      }}
                                    >
                                      <CloseIcon
                                        fontSize="small"
                                        color="error"
                                      />
                                    </IconButton>
                                  )}
                                </Typography>
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      )}

                      <Grid item container spacing={2} xs={12}>
                        {user.hasAction("EDIT_CONTRACT_PAYMENT_DAY") && (
                          <Grid item xs={12} md={4} lg={3}>
                            <CustomSelect
                              label={t("dateOfPayment")}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              name="dateOfPayment"
                              value={state.form.dateOfPayment}
                              onChange={handleInputChange}
                              options={[
                                { value: "", label: t("none") },
                                ...state.paymentDays?.map((day) => ({
                                  value: day,
                                  label: day,
                                })),
                              ]}
                            />
                          </Grid>
                        )}
                        {/* Issuer: Temporarily hidden 
                        <Grid item xs={12} md={4} lg={3}>
                          <CustomSelect
                            label={t("issuer")}
                            name="invoiceIssuerId"
                            value={state.form.invoiceIssuerId}
                            onChange={handleInputChange}
                            options={state.issuers?.map((issuer) => ({
                              value: issuer.id,
                              label: issuer.name,
                            }))}
                          />
                        </Grid> */}
                      </Grid>

                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          {t("promotion")}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4} lg={3}>
                        <PromotionSelect
                          value={state.form.promotionCode}
                          onChange={handleInputChange}
                          options={state.promotions}
                        />
                      </Grid>
                      {/* Auto invoicing: Temporarily hidden
                       <Grid item container xs={12} alignItems="center">
                         <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                name="autoInvoicing"
                                value={state.form.autoInvoicing}
                                onChange={(e, value) => {
                                  handleInputChange({
                                    target: {
                                      name: "autoInvoicing",
                                      value: value,
                                    },
                                  });
                                }}
                              />
                            }
                            label={t("autoInvoicing")}
                          />
                        </Grid> 
                        <Grid item>
                          <Tooltip
                            title={t("autoInvoicingInfo")}
                            placement="top"
                          >
                            <IconButton size="small">
                              <HelpIcon />
                            </IconButton>
                          </Tooltip>
                          </Grid> 
                      </Grid> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Button onClick={() => previousStep(3)} variant="text">
                        {t("back")}
                      </Button>
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
            </Stepper>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => history.goBack()} variant="text">
                {t("cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="success"
                onClick={createContract}
                loading={state.loading}
                disabled={state.activeStep !== 4}
              >
                {t("create")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <ConfirmDialog
        title={t("sendEmailToCustomer")}
        open={state.emailConfirmDialog.isOpen}
        confirmText={t("yes")}
        cancelText={t("no")}
        setOpen={handleEmailConfirmDialogStatus}
        onConfirm={(confirm) => {
          sendCreateContract(confirm);
        }}
      >
        <Typography>
          {t("youWantToSendTheContractByEmailTo") +
            " " +
            (state.isNewCustomer
              ? state.form.newCustomer.name +
                " " +
                state.form.newCustomer.surnames
              : state.form.customer?.fullName) +
            ", " +
            t("withEmail") +
            " " +
            (state.isNewCustomer
              ? state.form.newCustomer.email
              : state.form.customer?.CustomerEmails[
                  state.form.customer?.CustomerEmails.length - 1
                ]?.email)}
        </Typography>
      </ConfirmDialog>
    </Container>
  );
}

const BoxSelector = (props) => {
  const { boxes, value, onChange } = props;
  const [t] = useTranslation("contracts");

  const [onlyAvailable, setOnlyAvailable] = useState(true);
  const [selectedSize, selectSize] = useState(null);
  const [box, selectBox] = useState(null);
  const [filteredBoxes, setFilteredBoxes] = useState(boxes);

  const groupBoxesBySize = (boxes) => {
    const distinctBoxSizes = [...new Set(boxes.map((box) => box.meters))].sort(
      (a, b) => a - b
    );

    const groupedBoxes = distinctBoxSizes?.map((size) => {
      return {
        size: size,
        boxes: boxes.filter((box) => box.meters === size),
      };
    });

    return groupedBoxes;
  };

  const boxSizes = groupBoxesBySize(boxes);

  // Filter by only free boxes
  let filteredSizes = boxSizes;

  // Update selected box and size card when value changes
  useEffect(() => {
    selectBox(value);

    value &&
      selectSize(
        filteredSizes.findIndex((size) =>
          size.boxes.find((box) => box.id === value)
        )
      );
  }, [value]);

  useEffect(() => {
    selectSize(
      filteredSizes.findIndex((size) =>
        size.boxes.find((box) => box.id === value)
      )
    );
    if (onlyAvailable)
      setFilteredBoxes(
        filteredBoxes.filter((box) => {
          const free = box.state === FREE_BOX_STATE_ID;
          const occupied = box.state === OCCUPIED_BOX_STATE_ID;
          const hasContracts = box.Contracts.length > 0;
          const contractEnd = hasContracts
            ? box.Contracts.slice(-1)[0]?.endDate
            : null;
          if (occupied && contractEnd !== null) return true;
          if (free && !hasContracts) return true;
          if (free && contractEnd !== null) return true;
          return false;
        })
      );
    else setFilteredBoxes(boxes);
  }, [onlyAvailable]);

  onlyAvailable &&
    (filteredSizes = filteredSizes.filter((size) =>
      size.boxes.some((box) =>
        filteredBoxes.some((filteredBox) => filteredBox.id === box.id)
      )
    ));

  return (
    <Box width="100%">
      <FormControlLabel
        control={
          <Switch
            defaultChecked
            value={onlyAvailable}
            onChange={(e) => {
              setOnlyAvailable(e.target.checked);
            }}
          />
        }
        label={t("onlyAvailable")}
      />
      <Stack
        direction="row"
        spacing={1}
        padding={2}
        maxWidth="100%"
        overflow="auto"
      >
        {filteredSizes?.map((size, i) => (
          <Box
            component={Paper}
            variant="outlined"
            padding={2}
            key={i}
            onClick={() => {
              selectSize(i);
              selectedSize !== i && onChange({ target: { value: null } });
            }}
            sx={{ borderColor: selectedSize === i && theme.palette.info.main }}
          >
            <Grid container alignItems="center" direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h4" fontWeight="500" component="span">
                  {size.size}
                </Typography>
                m2
              </Grid>
              <Grid item>
                <Typography variant="h5" component="span">
                  {
                    size.boxes.filter((box) =>
                      filteredBoxes.some(
                        (filteredBox) => filteredBox.id === box.id
                      )
                    ).length
                  }
                </Typography>
                <Typography variant="body1" component="span">
                  {t("availableBoxes")}
                </Typography>
              </Grid>
              <Grid item>
                {[
                  ...new Set(
                    size.boxes
                      .filter(
                        (box) =>
                          !onlyAvailable ||
                          filteredBoxes.some(
                            (filteredBox) => filteredBox.id === box.id
                          )
                      )
                      .map((box) => box.pricePerMeter)
                  ),
                ].map((price) => (
                  <Typography>
                    {localeFormat(size.size * price)}€/{t("month")}
                  </Typography>
                ))}
              </Grid>
              <Grid item>
                <CustomSelect
                  value={box}
                  disabled={selectedSize !== i}
                  options={size.boxes
                    .filter(
                      (box) =>
                        !onlyAvailable ||
                        filteredBoxes.some(
                          (filteredBox) => filteredBox.id === box.id
                        )
                    )
                    .map((box) => ({
                      value: box.id,
                      label:
                        box.name +
                        " - " +
                        localeFormat(box.meters * box.pricePerMeter) +
                        "€" +
                        (box.state === 1 && box.Contracts.slice(-1)[0]?.endDate
                          ? " - " +
                            t("availableFrom") +
                            ": " +
                            box.Contracts.slice(-1)[0]?.endDate
                          : ""),
                      disabled: Boolean(box.state === 2),
                    }))}
                  onChange={(e) => {
                    selectBox(e.target.value);
                    onChange && onChange(e, false);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      </Stack>
      <Grid item xs={12} padding={2}>
        <AllBoxesSelect
          selectedBox={box}
          onChange={(e) => {
            selectBox(e.target.value);
            onChange && onChange(e, false);
          }}
          boxes={filteredBoxes}
        />
      </Grid>
    </Box>
  );
};

const ClusterSelector = (props) => {
  const { clusters, onChange, value } = props;
  const [t] = useTranslation("contracts");

  const allBoxes = useMemo(() => {
    const boxes = [];
    clusters?.forEach((cluster) => {
      cluster?.boxes?.forEach((box) => {
        box.clusterName = cluster.name;
        boxes.push(box);
      });
    });
    return boxes;
  }, [clusters]);

  const [onlyAvailable, setOnlyAvailable] = useState(true);
  const [selectedCluster, selectCluster] = useState(null);
  const [box, selectBox] = useState(null);

  const availableBox = useCallback((box) => {
    const free = box.state === FREE_BOX_STATE_ID;
    const occupied = box.state === OCCUPIED_BOX_STATE_ID;
    const hasContracts = box.Contracts?.length > 0;
    const contractEnd = hasContracts
      ? box.Contracts.slice(-1)[0]?.endDate
      : null;
    if (occupied && contractEnd !== null) return true;
    if (free && !hasContracts) return true;
    if (free && contractEnd !== null) return true;
    return false;
  }, []);

  const filteredBoxes = useMemo(() => {
    return onlyAvailable ? allBoxes.filter(availableBox) : allBoxes;
  }, [onlyAvailable]);

  useEffect(() => {
    selectBox(value);

    value &&
      selectCluster(
        clusters.findIndex((cluster) =>
          cluster.boxes.find((box) => box.id === value)
        )
      );
  }, [value]);

  return (
    <Box width="100%">
      <FormControlLabel
        control={
          <Switch
            defaultChecked
            value={onlyAvailable}
            onChange={(e) => {
              setOnlyAvailable(e.target.checked);
            }}
          />
        }
        label={t("onlyAvailable")}
      />

      <Stack
        direction="row"
        spacing={1}
        padding={2}
        maxWidth="100%"
        overflow="auto"
      >
        {clusters?.map((cluster, i) => {
          const filteredClusterBoxes = cluster.boxes.filter(
            (box) => !onlyAvailable || availableBox(box)
          );
          return (
            <Box
              component={Paper}
              variant="outlined"
              padding={2}
              key={i}
              onClick={() => {
                if (filteredClusterBoxes?.length) {
                  selectCluster(i);
                  selectedCluster !== i &&
                    onChange({ target: { value: null } });
                }
              }}
              sx={{
                borderColor: selectedCluster === i && theme.palette.info.main,
              }}
              position="relative"
            >
              <Box position="absolute" top={0} right={4}>
                <Typography variant="caption" fontWeight="bold" color="primary">
                  {Math.max(
                    ...filteredClusterBoxes.map((box) =>
                      Math.round(box.discount)
                    )
                  )}
                  %
                </Typography>
              </Box>
              <Grid
                container
                alignItems="center"
                direction="column"
                spacing={1}
              >
                <Grid item>
                  <BoxClusterChip name={String(cluster.name)} />
                </Grid>
                <Grid item>
                  <Typography variant="body" sx={{ fontWeight: "bold" }}>
                    {cluster?.minSize + " - " + cluster?.maxSize + " m²"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" component="span">
                    {filteredClusterBoxes.length}
                  </Typography>
                  <Typography variant="body1" component="span">
                    {" " + t("availableBoxes")}
                  </Typography>
                </Grid>
                {cluster.price > 0 ? (
                  <>
                    <Grid item>
                      <Typography
                        variant="h6"
                        fontWeight="500"
                        component="span"
                      >
                        {t("withoutVAT") +
                          " " +
                          localeFormat(cluster.price) +
                          "€/" +
                          t("month")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h6"
                        fontWeight="500"
                        component="span"
                      >
                        {t("withVAT") +
                          " " +
                          localeFormat(Number(cluster.priceWithVAT)) +
                          "€/" +
                          t("month")}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <Grid item>
                    <Typography variant="h6" fontWeight="500" component="span">
                      {t("clusterPriceNotEstablished")}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <CustomSelect
                    value={box}
                    disabled={selectedCluster !== i}
                    options={filteredClusterBoxes.map((box) => ({
                      value: box.id,
                      label:
                        box.name +
                        " - " +
                        localeFormat(box.meters) +
                        "m - " +
                        localeFormat(Math.round(box.discount)) +
                        "% - " +
                        localeFormat(box.price) +
                        "€ - " +
                        localeFormat(box.priceWithVAT) +
                        "€" +
                        (box.state === 1 && box.Contracts.slice(-1)[0]?.endDate
                          ? " - " +
                            t("availableFrom") +
                            ": " +
                            box.Contracts.slice(-1)[0]?.endDate
                          : ""),

                      disabled: Boolean(box.state === UNAVAILABLE_BOX_STATE_ID),
                    }))}
                    onChange={(e) => {
                      selectCluster(e.target.value);
                      onChange && onChange(e, true);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Stack>

      <Grid item xs={12} md={6} xl={3} padding={2}>
        <AllBoxesSelect
          boxes={filteredBoxes}
          selectedBox={box}
          onChange={(e) => {
            selectBox(e.target.value);
            onChange && onChange(e, true);
          }}
          useClusters={true}
        />
      </Grid>
    </Box>
  );
};

const AllBoxesSelect = ({
  selectedBox,
  onChange,
  boxes,
  useClusters = false,
}) => {
  const [t] = useTranslation("contracts");

  const getBoxLabel = (box) => {
    const availableFrom =
      box.state === OCCUPIED_BOX_STATE_ID && box.Contracts.slice(-1)[0]?.endDate
        ? " - " +
          t("availableFrom") +
          ": " +
          box.Contracts.slice(-1)[0]?.endDate
        : "";

    let label = "";
    if (useClusters) {
      label =
        t(box.clusterName) +
        " - " +
        box.name +
        " - " +
        localeFormat(box.price) +
        "€ - " +
        localeFormat(box.priceWithVAT) +
        "€ - " +
        localeFormat(Math.round(box.discount)) +
        "%" +
        availableFrom;
    } else {
      label =
        t(box.name) +
        " - " +
        localeFormat(box.pricePerMeter * box.meters) +
        "€" +
        availableFrom;
    }
    return label;
  };

  const boxesOptions = boxes
    ?.map((box) => ({
      value: box.id,
      label: getBoxLabel(box),
      disabled: box.unavailable,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Select
      label={t("allBoxes")}
      value={selectedBox}
      onChange={onChange}
      searchable
      options={boxesOptions}
    />
  );
};

const NewCustomerForm = (props) => {
  const [t] = useTranslation("contracts");
  const { form, inputHandler } = props;

  const getIdTypeLabel = (idType) => {
    return idType?.toUpperCase() === PASSPORT_ID_TYPE
      ? t(idType)
      : idType?.toUpperCase();
  };

  useEffect(() => {
    form.tinCountry === null &&
      inputHandler({
        target: {
          name: "nif",
          value: "",
        },
      });
  }, [form.tinCountry]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RadioGroup
          value={form.customerTypeId}
          name="customerTypeId"
          row
          onChange={inputHandler}
        >
          <FormControlLabel
            name="customerTypeId"
            value="1"
            control={<Radio />}
            label={t("individual")}
          />
          <FormControlLabel
            name="customerTypeId"
            value="2"
            control={<Radio />}
            label={t("company")}
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <CountryInput
          label={t("countryIssuance")}
          name="tinCountry"
          onChange={inputHandler}
          value={form.tinCountry}
          required
        />
      </Grid>
      <Grid item xs={4}>
        <IdTypeSelect
          idType={form.idType}
          countryAlpha2={form.tinCountry?.alpha2}
          handleInputChange={inputHandler}
          disabled={form.tinCountry === null}
        />
      </Grid>
      <Grid item xs={8}>
        <TextInput
          name="nif"
          label={getIdTypeLabel(form.idType)}
          value={form.nif}
          onChange={inputHandler}
          required
          disabled={form.tinCountry === null}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="name"
          label={t("name")}
          value={form.name}
          onChange={inputHandler}
          required
        />
      </Grid>
      {Number(form.customerTypeId) === INDIVIDUAL_CUSTOMER_TYPE_ID && (
        <Grid item xs={12}>
          <TextInput
            name="surnames"
            label={t("surnames")}
            value={form.surnames}
            onChange={inputHandler}
            required
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextInput
          name="email"
          type="email"
          label={t("email")}
          value={form.email}
          onChange={inputHandler}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneInput
          inputProps={{
            label: t("phone"),
            name: "phone",
            onChange: inputHandler,
          }}
          value={form.phone}
        />
      </Grid>
      <Grid item xs={12}>
        <CountryInput
          label={t("countryResidence")}
          name="residenceCountry"
          onChange={inputHandler}
          value={form.residenceCountry}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="address"
          label={t("address")}
          value={form.address}
          onChange={inputHandler}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <PostalCodeInput
          label={t("postalCode")}
          name="postalCode"
          onChange={inputHandler}
          value={form.postalCode}
          disabled={form.residenceCountry?.id !== SPAIN_COUNTRY_ID}
        />
      </Grid>
      <Grid item xs={12}>
        <CityInput
          name="city"
          label={t("city")}
          value={form.city}
          onChange={inputHandler}
          disabled={form.residenceCountry?.id !== SPAIN_COUNTRY_ID}
        />
      </Grid>
      {Number(form.customerTypeId) === INDIVIDUAL_CUSTOMER_TYPE_ID && (
        <Grid item xs={12}>
          <CustomDate
            name="birthdate"
            label={t("birthdate")}
            value={form.birthdate}
            onChange={inputHandler}
          />
        </Grid>
      )}
      {Number(form.customerTypeId) === INDIVIDUAL_CUSTOMER_TYPE_ID && (
        <Grid item xs={12}>
          <FormControl>
            <FormLabel>{t("gender")}</FormLabel>
            <RadioGroup
              value={form.gender}
              name="gender"
              row
              onChange={inputHandler}
            >
              <FormControlLabel
                name="gender"
                value="1"
                control={<Radio />}
                label={t("male")}
              />
              <FormControlLabel
                name="gender"
                value="2"
                control={<Radio />}
                label={t("female")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
