import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    error: {
      contrastText: "#fff",
      dark: "#c62828",
      light: "#ef5350",
      main: "#d32f2f",
    },
    info: {
      contrastText: "#fff",
      dark: "#01579b",
      light: "#03a9f4",
      main: "#0288d1",
    },
    mode: "light",
    primary: {
      contrastText: "#fff",
      dark: "#1565c0",
      light: "#42a5f5",
      main: "#1976d2",
    },
    secondary: {
      contrastText: "#fff",
      dark: "#7b1fa2",
      light: "#ba68c8",
      main: "#9c27b0",
    },
    success: {
      contrastText: "#fff",
      dark: "#1b5e20",
      light: "#4caf50",
      main: "#2e7d32",
    },
    warning: {
      contrastText: "#fff",
      dark: "#e65100",
      light: "#ff9800",
      main: "#ed6c02",
    },
    black: {
      contrastText: "#fff",
      dark: "#000",
      light: "#000",
      main: "#000",
    },
    yellow: {
      contrastText: "#000",
      dark: "#E8CA4D",
      light: "#E8CA4D",
      main: "#E8CA4D",
    },
    white: {
      contrastText: "#000",
      dark: "#fff",
      light: "#fff",
      main: "#fff",
    },
  },
});

export default theme;
