import AppContext from "../../context/AppContext";
import {
  Button,
  ButtonGroup,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React, { useContext, useEffect, useReducer } from "react";
import PublishIcon from "@mui/icons-material/Publish";
import { faLaptopHouse } from "@fortawesome/free-solid-svg-icons";

function reducer(state, action) {
  switch (action.type) {
    case "SET_PHOTO":
      return { ...state, photo: action.payload };
    case "RESET_PHOTO":
      return { ...state, photo: initialState.photo };
    case "SET_SHOW_SAVE_TRUE":
      return { ...state, showSave: true };
    case "SET_SHOW_SAVE_FALSE":
      return { ...state, showSave: false };
    case "SET_DIALOG_FALSE":
      return { ...state, dialogIsOpen: false };
    case "SET_DIALOG_TRUE":
      return { ...state, dialogIsOpen: true };
    case "SET_SRC":
      return { ...state, src: action.payload };
    default:
      throw new Error("Action not found in reducer.");
  }
}

const initialState = {
  default: "/img/default.jpg",
  dialogIsOpen: false,
  photo: null,
  showSave: false,
  src: "/img/default.jpg",
};

const PhotoInput = ({
  id,
  savePhoto,
  deletePhoto,
  hasPhoto,
  source = "/img/default.jpg",
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useContext(AppContext);

  useEffect(() => {
    dispatch({ type: "SET_SRC", payload: source });
  }, [source]);

  const uploadPhoto = (e) => {
    dispatch({ type: "SET_PHOTO", payload: e.target.files });
    dispatch({ type: "SET_SHOW_SAVE_TRUE" });
  };

  const confirmDelete = () => {
    dispatch({ type: "SET_DIALOG_FALSE" });
    dispatch({ type: "RESET_PHOTO" });
    dispatch({ type: "SET_SRC", payload: state.default });
    deletePhoto();
  };

  const resetPhoto = () => {
    dispatch({ type: "RESET_PHOTO" });
    dispatch({ type: "SET_SHOW_SAVE_FALSE" });
  };

  const handleCloseDialog = () => {
    dispatch({ type: "SET_DIALOG_FALSE" });
  };

  const openDialog = () => {
    dispatch({ type: "SET_DIALOG_TRUE" });
  };

  return (
    <Container>
      <Box
        component="img"
        sx={{
          height: "100%",
          width: "100%",
          maxHeight: 220,
          maxWidth: 220,
          minHeight: 220,
        }}
        src={state.photo ? URL.createObjectURL(state.photo[0]) : state.src}
        onError={(e) => {
          e.target.onerror = null;
          if (e.target.src !== "/img/default.jpg") {
            e.target.src = "/img/default.jpg";
          }
        }}
      />

      {user.hasAction("EDIT_USERS") && (
        <Grid item container xs={12}>
          <ButtonGroup>
            {hasPhoto == false && state.showSave == false && (
              <Tooltip title="uploadPhoto" placement="top">
                <IconButton component="label">
                  <PublishIcon color="primary" />
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(e) => {
                      uploadPhoto(e);
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {hasPhoto == true && state.showSave == false && (
              <Tooltip title="deletePhoto" placement="top">
                <IconButton onClick={openDialog}>
                  <DeleteForeverIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          </ButtonGroup>
          {state.showSave && (
            <ButtonGroup variant="contained">
              <Button color="secondary" onClick={resetPhoto}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  savePhoto(state.photo);
                  dispatch({ type: "SET_SHOW_SAVE_FALSE" });
                }}
              >
                Save
              </Button>
            </ButtonGroup>
          )}
        </Grid>
      )}
      <Dialog open={state.dialogIsOpen} onClose={handleCloseDialog}>
        <DialogTitle>Are you sure you want to delete the photo?</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCloseDialog}
              >
                No
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  confirmDelete();
                }}
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default PhotoInput;
