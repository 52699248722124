import {
  Avatar,
  Chip,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useReducer } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AppContext from "../../context/AppContext";
import PasswordInput from "../Inputs/PasswordInput";
import LabeledText from "../global/LabeledText";
import CustomButton from "../Inputs/CustomButton";
import Page from "../global/structure/Page";
import theme from "../../theme/theme";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PASSWORD":
      return {
        ...state,
        password: {
          ...state.password,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "SET_MFA":
      return {
        ...state,
        mfa: {
          ...state.mfa,
          [action.payload.inputname]: action.payload.value,
        },
      };
    case "RESET_PASSWORD_INPUTS":
      return { ...state, password: initialState.password };
    default:
      throw new Error("Action not found in reducer");
  }
};

const initialState = {
  password: {
    current: "",
    confirm: "",
    new: "",
  },
  mfa: {
    isRequired: false,
    loading: false,
  },
};

export default function ProfilePage() {
  const { api, user } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    mfa: {
      isRequired: user?.UserMFA?.isRequired || false,
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation("profile");
  const [tErrors] = useTranslation("errors");

  const mfaActive = user?.UserMFA?.isActive || false;

  const resetPassword = () => {
    if (state.password.new !== state.password.confirm)
      return enqueueSnackbar(t("passwordsDoNotMatch"), { variant: "error" });

    api
      .post("/users/" + user.id + "/reset-password", {
        newPassword: state.password.new,
        currentPassword: state.password.current,
      })
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("passwordChanged"), { variant: "success" });
          dispatch({ type: "RESET_PASSWORD_INPUTS" });
        }
      })
      .catch((error) =>
        enqueueSnackbar(error.toString(), { variant: "error" })
      );
  };

  const enableMFA = () => {
    toggleMFALoading();

    api
      .post("/users/enable-mfa")
      .then((response) => {
        if (response.data.error) {
          enqueueSnackbar(tErrors(response.data.error), { variant: "error" });
        } else {
          enqueueSnackbar(t("mfaEnabledSuccessfully"), { variant: "success" });
          handleEnableMFA();
        }
      })
      .catch((error) => enqueueSnackbar(error.toString(), { variant: "error" }))
      .finally(() => toggleMFALoading());
  };

  const handleEnableMFA = () => {
    dispatch({
      type: "SET_MFA",
      payload: {
        inputname: "isRequired",
        value: true,
      },
    });
  };

  const toggleMFALoading = () => {
    dispatch({
      type: "SET_MFA",
      payload: {
        inputname: "loading",
        value: !state.mfa.loading,
      },
    });
  };

  const handlePasswordChange = (e) => {
    dispatch({
      type: "SET_PASSWORD",
      payload: {
        inputname: e.target.name,
        value: e.target.value,
      },
    });
  };

  const getMFAChipProps = () => {
    if (mfaDisabled) {
      return { label: t("mfaDisabled"), color: "error" };
    } else if (mfaPending) {
      return { label: t("mfaPending"), color: "warning" };
    } else {
      return { label: t("mfaActive"), color: "success" };
    }
  };

  const mfaDisabled = !state.mfa.isRequired;
  const mfaPending = state.mfa.isRequired && !mfaActive;

  return (
    <Page browserTitle={t("profile")}>
      <Container maxWidth="lg" sx={{ marginY: 3 }}>
        <Paper sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid item container xs={12} spacing={1}>
              <Grid item>
                <Typography variant="h4">{t("profile")}</Typography>
              </Grid>
              <Grid item flex={1} justifyContent="flex-end" display="flex">
                <Avatar sx={{ bgcolor: theme.palette.primary.dark }}>
                  {user?.name[0]?.toUpperCase()}
                </Avatar>
              </Grid>
            </Grid>

            <Grid container item xs={12} columnGap={3}>
              <Grid item>
                <LabeledText label={t("username")} value={user?.name} />
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <LabeledText label={t("role")} value={user?.Role.name} />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">{t("security")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">{t("resetPassword")}</Typography>
            </Grid>

            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} lg={3}>
                <PasswordInput
                  text={t("current")}
                  name="current"
                  value={state.password.current}
                  onChange={handlePasswordChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <PasswordInput
                  text={t("new")}
                  value={state.password.new}
                  name="new"
                  onChange={handlePasswordChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <PasswordInput
                  text={t("confirm")}
                  value={state.password.confirm}
                  name="confirm"
                  onChange={handlePasswordChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <CustomButton onClick={resetPassword} fullWidth>
                  {t("save")}
                </CustomButton>
              </Grid>
            </Grid>

            <Grid item container spacing={2} alignItems="center" xs={12}>
              <Grid item>
                <Typography variant="h6">
                  {t("multiFactorAuthentication")}
                </Typography>
              </Grid>
              <Grid item>
                <Chip
                  label={getMFAChipProps().label}
                  color={getMFAChipProps().color}
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} lg={9}>
                <Typography variant="body1">
                  {mfaDisabled && t("mfaDisabledInfo")}
                  {mfaPending && t("mfaPendingInfo")}
                  {mfaActive && t("mfaActiveInfo")}
                </Typography>
              </Grid>
              {mfaDisabled && (
                <Grid item xs={12} sm={6} lg={3}>
                  <CustomButton
                    onClick={enableMFA}
                    loading={state.mfa.loading}
                    fullWidth
                  >
                    {t("enable")}
                  </CustomButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Page>
  );
}
