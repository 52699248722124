import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import TextInput from "../../../Inputs/TextInput";
import Button from "../../../Inputs/CustomButton";
import { useState } from "react";

export const defaultValues = {
  shape: "Rect",
  x: "100",
  y: "100",
  width: "100",
  height: "100",
};

const CreateRectForm = ({ onCreateTile, closeAddTileDialog, layerTiles }) => {
  const [t] = useTranslation("floorPlans");

  const [name, setName] = useState("Tile " + (layerTiles.length + 1));
  const [x, setX] = useState(defaultValues.x);
  const [y, setY] = useState(defaultValues.y);
  const [width, setWidth] = useState(defaultValues.width);
  const [height, setHeight] = useState(defaultValues.height);

  const validateForm = () => {
    if (!name || !x || !y || !width || !height) {
      return false;
    }
    return true;
  };

  const handleCreate = () => {
    let tile = {
      name,
      shape: "Rect",
      x,
      y,
      width,
      height,
    };

    onCreateTile(tile);
  };

  const inputs = [
    {
      label: "name",
      value: name,
      setter: setName,
      type: "text",
    },
    {
      label: "x",
      value: x,
      setter: setX,
      type: "number",
    },
    {
      label: "width",
      value: width,
      setter: setWidth,
      type: "number",
    },
    {
      label: "y",
      value: y,
      setter: setY,
      type: "number",
    },
    {
      label: "height",
      value: height,
      setter: setHeight,
      type: "number",
    },
  ];

  return (
    <Box width="100%">
      <Grid container spacing={2}>
        {inputs.map((input) => (
          <>
            <Grid container item xs={5} key={input.label}>
              <Grid container item alignItems="center" spacing={2}>
                <Grid item xs={2}>
                  <Typography align="left">{t(input.label) + ":"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label={t(input.label)}
                    type={input.type}
                    value={input.value}
                    onChange={(e) => input.setter(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {input.label === "name" ? (
              <Grid item container xs={6} marginBottom={2}></Grid>
            ) : (
              <></>
            )}
          </>
        ))}
        <Grid container item xs={12} justifyContent="end" spacing={1}>
          <Grid item>
            <Button onClick={closeAddTileDialog} variant="text">
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="success"
              disabled={!validateForm()}
              onClick={handleCreate}
            >
              {t("add")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateRectForm;
